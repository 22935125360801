import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICustomers_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICustomers_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  GetLanguage: string;
  GetUserCurrentId: any[];
  spGetUserById: any[];
  TriggerEvent: string;
  SetValueOf: any;
  ReverseBool: boolean;
  spDeleteCompanies: any[];
  spReactivateCompany: any[];
  GetUsersFirstLetter: any[];
  companiesSP: any[];
  isComp965678Visible: "visible" | "hidden";
  isComp211164Visible: "visible" | "hidden";
  isComp152064Visible: "visible" | "hidden";
  isComp691633Visible: "visible" | "hidden";
  isCompcustomers_436230AuthorizationVisible: "visible" | "hidden";
  isCompcustomers_108169AuthorizationVisible: "visible" | "hidden";
  isCompcustomers_892079AuthorizationVisible: "visible" | "hidden";
  isCompcustomers_351925AuthorizationVisible: "visible" | "hidden";
  isCompcustomers_971564AuthorizationVisible: "visible" | "hidden";
  isCompcustomers_637796AuthorizationVisible: "visible" | "hidden";
}

export class Customers_ScreenBase extends React.PureComponent<ICustomers_ScreenProps, any> {
  customers_828121_value_kuikaTableRef: React.RefObject<any>;
  customers_820232_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 844822, PropertyName: "value", Value: "Müşteriler", TagName: "Label1_value" },
        {
          Id: 828121,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 602935, PropertyName: "title", Value: "Şirket Ünvanı", TagName: "TableColumn1_title" },
        { Id: 54845, PropertyName: "title", Value: "Telefon Numarası", TagName: "TableColumn1_2_title" },
        { Id: 398566, PropertyName: "placeholder", Value: "Ara...", TagName: "search_TextInput0_0_placeholder" },
        { Id: 436230, PropertyName: "label", Value: "Ekle", TagName: "Button0_0_label" },
        { Id: 294353, PropertyName: "value", Value: "Silinen Müşterileri Göster", TagName: "Label3_1_value" },
        { Id: 590641, PropertyName: "title", Value: "Posta Kodu", TagName: "TableColumn6_title" },
        { Id: 254222, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 45327, PropertyName: "label", Value: "Çıkış Yap", TagName: "ContentMenuItem2_2_label" },
        { Id: 627705, PropertyName: "title", Value: "Görüntüle", TagName: "Tooltip1_title" },
        { Id: 457138, PropertyName: "title", Value: "Cariler", TagName: "Tooltip2_title" },
        { Id: 332694, PropertyName: "title", Value: "Kullanıcılar", TagName: "Tooltip3_title" },
        { Id: 790833, PropertyName: "title", Value: "Düzenle", TagName: "Tooltip4_title" },
        { Id: 120783, PropertyName: "title", Value: "Sil", TagName: "Tooltip5_title" },
        { Id: 188644, PropertyName: "title", Value: "Kurtar", TagName: "Tooltip6_title" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "144ea304-0192-418a-b4d8-e6e0f1cdc5ee",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 254222, PropertyName: "label", Value: "Profile" },
        { Id: 45327, PropertyName: "label", Value: "Logout" },
        { Id: 844822, PropertyName: "value", Value: "Customers" },
        { Id: 398566, PropertyName: "placeholder", Value: "Search..." },
        { Id: 294353, PropertyName: "value", Value: "Show Deactivated Customers" },
        { Id: 436230, PropertyName: "label", Value: "New" },
        { Id: 828121, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 602935, PropertyName: "title", Value: "Company Title" },
        { Id: 96105, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 54845, PropertyName: "title", Value: "Phone Number" },
        { Id: 733834, PropertyName: "value", Value: "[datafield:formattedphone]" },
        { Id: 590641, PropertyName: "title", Value: "Posta Code" },
        { Id: 941851, PropertyName: "value", Value: "[datafield:postacode]" },
        { Id: 627705, PropertyName: "title", Value: "View" },
        { Id: 457138, PropertyName: "title", Value: "Currents" },
        { Id: 332694, PropertyName: "title", Value: "Users" },
        { Id: 790833, PropertyName: "title", Value: "Edit" },
        { Id: 120783, PropertyName: "title", Value: "Delete" },
        { Id: 188644, PropertyName: "title", Value: "Recover" },
        { Id: 384952, PropertyName: "label", Value: "!-Trigger Companies-!" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.customers_828121_value_kuikaTableRef = React.createRef();
    this.customers_820232_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      GetLanguage: "",
      GetUserCurrentId: [],
      spGetUserById: [],
      TriggerEvent: "",
      SetValueOf: "",
      ReverseBool: false,
      spDeleteCompanies: [],
      spReactivateCompany: [],
      GetUsersFirstLetter: [],
      companiesSP: [],
      isComp965678Visible: "hidden",
      isComp211164Visible: "hidden",
      isComp152064Visible: "hidden",
      isComp691633Visible: "hidden",
      isCompcustomers_436230AuthorizationVisible: "visible",
      isCompcustomers_108169AuthorizationVisible: "visible",
      isCompcustomers_892079AuthorizationVisible: "visible",
      isCompcustomers_351925AuthorizationVisible: "visible",
      isCompcustomers_971564AuthorizationVisible: "visible",
      isCompcustomers_637796AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("customers", "customers");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CustomersPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcustomers_436230AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcustomers_108169AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcustomers_892079AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcustomers_351925AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcustomers_971564AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcustomers_637796AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("customers", "customers");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Administrator", "Logistic"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("customers", "customers");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CustomersPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      customers_694099_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined,
      customers_469811_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CustomersPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomersPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CustomersPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Customers/CustomersPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.customers_694099_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp965678Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp211164Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomersPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CustomersPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.CustomersComponent_384952_onClick()) return true;

    stateVars.isComp965678Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.customers_694099_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp211164Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp152064Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.companiesSP?.length > 0 ? this.state.companiesSP[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CustomersComponent_98855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customers",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "425918",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_254222_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customers",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "819183",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_45327_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_398566_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "customers_820232_value", 1, "current");
    if (await this.CustomersComponent_384952_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_365368_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.CustomersComponent_384952_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_469811_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "customers_820232_value", 1, "current");
    if (await this.CustomersComponent_384952_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_294353_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      value_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customers_469811_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Customers/CustomersComponent_294353_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ReverseBool = result?.data.reverseBool;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomersComponent_294353_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CustomersComponent_294353_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "customers_469811_value",
      ReactSystemFunctions.value(this, this.state.ReverseBool),
      null
    );
    if (await this.CustomersComponent_384952_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_436230_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CompanyEdit", "companyId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("CompanyEdit", "companyType", 1);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customers",
      "CompanyEdit",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "849059",
      null,
      "right",
      null,
      "40%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_205645_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CompanyView",
      "companyId",
      ReactSystemFunctions.value(this, "customers_828121_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customers",
      "CompanyView",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "162163",
      null,
      "right",
      null,
      "40%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_108169_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CompanyCurrentList", "userType", 3);
    KuikaAppManager.addToPageInputVariables("CompanyCurrentList", "role", "Customer");
    KuikaAppManager.addToPageInputVariables(
      "CompanyCurrentList",
      "companyId",
      ReactSystemFunctions.value(this, "customers_828121_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customers",
      "CompanyCurrentList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_892079_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CompanyUserList", "userType", 3);
    KuikaAppManager.addToPageInputVariables("CompanyUserList", "role", "Customer");
    KuikaAppManager.addToPageInputVariables(
      "CompanyUserList",
      "companyId",
      ReactSystemFunctions.value(this, "customers_828121_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customers",
      "CompanyUserList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_351925_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CompanyEdit",
      "companyId",
      ReactSystemFunctions.value(this, "customers_828121_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("CompanyEdit", "companyType", 1);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Customers",
      "CompanyEdit",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "420169",
      null,
      "right",
      null,
      "40%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_971564_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "customers_828121_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "8a7efe40_5a05_46d3_c108_a4c308ede992_confirmation",
        this.defaultML,
        "Kayıt silinecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Customers/CustomersComponent_971564_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spDeleteCompanies = result?.data.spDeleteCompanies;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.CustomersComponent_971564_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CustomersComponent_971564_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.CustomersComponent_384952_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_637796_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "customers_828121_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "93d81c82_e32f_34a4_c669_b7939e19744f_confirmation",
        this.defaultML,
        "Kayıt tekrar aktif edilecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Customers/CustomersComponent_637796_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spReactivateCompany = result?.data.spReactivateCompany;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.CustomersComponent_637796_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CustomersComponent_637796_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.CustomersComponent_384952_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_820232_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.CustomersComponent_384952_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomersComponent_384952_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      currentPage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customers_820232_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customers_820232_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customers_398566_value", "value", "", "", "")
        ),
        "string"
      ),
      showDeletedCompanies_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customers_469811_value", "value", "", "", "")
        ),
        "boolean"
      ),
      companyType_3: ReactSystemFunctions.convertToTypeByName(1, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Customers/CustomersComponent_384952_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.customers_694099_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp965678Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp211164Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.customers_469884_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.companiesSP = result?.data.companiesSP;

    stateVars.isComp152064Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, stateVars.companiesSP?.length > 0 ? stateVars.companiesSP[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomersComponent_384952_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CustomersComponent_384952_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.customers_694099_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    formVars.customers_469884_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.dataSource_828121 = this.state.companiesSP;
    formVars.customers_820232_total = ReactSystemFunctions.value(
      this,
      this.state.companiesSP?.length > 0 ? this.state.companiesSP[0]?.resultCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [425918, 819183, 849059, 162163, , 420169] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.CustomersPageInit();
    }
  }
}
