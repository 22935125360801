import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";

declare let window: any;

interface IOrderTracking_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOrderTracking_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
}

export class OrderTracking_ScreenBase extends React.PureComponent<IOrderTracking_ScreenProps, any> {
  ordertracking_277239_value_kuikaTableRef: React.RefObject<any>;
  ordertracking_185873_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 679953, PropertyName: "value", Value: "Finans Onayı", TagName: "Label42_value" },
        { Id: 931892, PropertyName: "value", Value: "Araç İsteği", TagName: "Label42_1_value" },
        { Id: 406512, PropertyName: "value", Value: "Araç Atama", TagName: "Label43_1_value" },
        { Id: 204718, PropertyName: "value", Value: "Araç Yükleme", TagName: "Label44_1_value" },
        { Id: 216858, PropertyName: "value", Value: "Taşıma", TagName: "Label45_1_value" },
        { Id: 550559, PropertyName: "value", Value: "Belge Ekleme", TagName: "Label46_1_value" },
        { Id: 168510, PropertyName: "value", Value: "Müşteri Teslimatı", TagName: "Label48_1_value" },
        { Id: 759970, PropertyName: "value", Value: "Anket", TagName: "Label47_1_value" },
        { Id: 554304, PropertyName: "value", Value: "Sipariş Durumu", TagName: "Label19_value" },
        { Id: 513356, PropertyName: "value", Value: "Aktif", TagName: "Label20_value" },
        { Id: 406936, PropertyName: "value", Value: "Sipariş Numarası", TagName: "Label35_value" },
        { Id: 751003, PropertyName: "value", Value: "Sipariş Tarihi", TagName: "Label36_value" },
        { Id: 3947, PropertyName: "value", Value: "Şirket Adı", TagName: "Label3_value" },
        { Id: 230185, PropertyName: "value", Value: "Şirket Numarası", TagName: "Label4_value" },
        { Id: 650834, PropertyName: "value", Value: "Ülke Kodu", TagName: "Label15_value" },
        { Id: 147379, PropertyName: "value", Value: "Telefon Numarası", TagName: "Label17_value" },
        { Id: 463392, PropertyName: "value", Value: "Adres", TagName: "Label13_value" },
        { Id: 742058, PropertyName: "value", Value: "Belge Durumu", TagName: "Label32_0_value" },
        { Id: 714052, PropertyName: "value", Value: "Eklendi", TagName: "Label32_1_value" },
        { Id: 293393, PropertyName: "value", Value: "Eklenme Tarihi", TagName: "Label32_0_value" },
        { Id: 473832, PropertyName: "value", Value: "Tarih", TagName: "Label21_value" },
        { Id: 62471, PropertyName: "value", Value: "Sipariş Bilgisi", TagName: "Label22_value" },
        { Id: 726530, PropertyName: "value", Value: "İşlem Tipi", TagName: "Label23_value" },
        {
          Id: 277239,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 626256, PropertyName: "title", Value: "Başlık", TagName: "TableColumn1_0_title" },
        { Id: 585014, PropertyName: "value", Value: "Aktif", TagName: "Label23_0_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "9c0ab311-bb07-4641-887c-b71c0c056e03",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 931892, PropertyName: "value", Value: "Vehicle Request" },
        { Id: 406512, PropertyName: "value", Value: "Vehicle Assignment" },
        { Id: 550559, PropertyName: "value", Value: "Document Attachment" },
        { Id: 168510, PropertyName: "value", Value: "Customer Delivery" },
        { Id: 759970, PropertyName: "value", Value: "Survey" },
        { Id: 565875, PropertyName: "value", Value: "Completed" },
        { Id: 554304, PropertyName: "value", Value: "Order Status" },
        { Id: 513356, PropertyName: "value", Value: "Active" },
        { Id: 406936, PropertyName: "value", Value: "Order Number" },
        { Id: 728753, PropertyName: "value", Value: "35SD34456" },
        { Id: 751003, PropertyName: "value", Value: "Sipariş Tarihi" },
        { Id: 624565, PropertyName: "value", Value: "22.10.2022" },
        { Id: 3947, PropertyName: "value", Value: "Company Name" },
        { Id: 312999, PropertyName: "value", Value: "Kuika" },
        { Id: 230185, PropertyName: "value", Value: "Card Number" },
        { Id: 476381, PropertyName: "value", Value: "23456543" },
        { Id: 650834, PropertyName: "value", Value: "Prefix" },
        { Id: 585374, PropertyName: "value", Value: "+90" },
        { Id: 147379, PropertyName: "value", Value: "Phone Number" },
        { Id: 926485, PropertyName: "value", Value: "555 666 77 88" },
        { Id: 21351, PropertyName: "value", Value: "E-Mail" },
        { Id: 586458, PropertyName: "value", Value: "kuika@kuika.com" },
        { Id: 463392, PropertyName: "value", Value: "Address" },
        { Id: 353524, PropertyName: "value", Value: "Korutürk Mah. 890 sok. No:14 Ümraniye 0035 İstanbul/Türkiye" },
        { Id: 742058, PropertyName: "value", Value: "Documents Status" },
        { Id: 714052, PropertyName: "value", Value: "Eklendi" },
        { Id: 293393, PropertyName: "value", Value: "Date of upload" },
        { Id: 508417, PropertyName: "value", Value: "23.10.2022" },
        { Id: 473832, PropertyName: "value", Value: "Date" },
        { Id: 62471, PropertyName: "value", Value: "Order Information" },
        { Id: 726530, PropertyName: "value", Value: "Process Type" },
        { Id: 277239, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 626256, PropertyName: "title", Value: "Title" },
        { Id: 441339, PropertyName: "value", Value: "22.10.2022" },
        { Id: 421788, PropertyName: "value", Value: "Lorem ipsum dolor. sit amet." },
        { Id: 585014, PropertyName: "value", Value: "Active" },
        { Id: 381222, PropertyName: "placeholder", Value: "Type here..." }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ordertracking_277239_value_kuikaTableRef = React.createRef();
    this.ordertracking_185873_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ordertracking", "ordertracking");
      return;
    }

    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("ordertracking", "ordertracking");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("ordertracking", "ordertracking");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
