import * as React from "react";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";

interface IKuikaInfoState {}

interface IKuikaInfoProps {}

declare let window: any;

class KuikaInfo extends React.PureComponent<IKuikaInfoProps, IKuikaInfoState> {
  render() {
    const appName = "webV02";
    const workspace = "cfnKimya";
    const webComponentTag = "cfn-kimya-web-v02";
    const frontEndUrl = KuikaAppManager.GetFrontEndSourceUrl();
    const backEndUrl = KuikaAppManager.GetBackendUrl(true);
    const kuikaInfoJson = {
      workspace,
      appName,
      frontEndUrl,
      backEndUrl,
      webComponentTag
    };
    var json = JSON.stringify(kuikaInfoJson);
    var blob = new Blob([json], { type: "application/json" });
    var url = URL.createObjectURL(blob);
    window.location = url;
    return <></>;
  }
}

const kuikaInfo = KuikaInfo;
export { kuikaInfo as KuikaInfo };
