import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICompanyCurrentList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICompanyCurrentList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  GetLanguage: string;
  GetCompanyById: any[];
  TriggerEvent: string;
  GetUserCurrentId: any[];
  spGetUserById: any[];
  GetUsersFirstLetter: any[];
  GoBack: any;
  SqlDeleteCurrent: number;
  SqlGetCurrentCountCompanyFiltered: any[];
  spCurrentsCompanyFiltered: any[];
  isComp162710Visible: "visible" | "hidden";
  isComp597031Visible: "visible" | "hidden";
  isComp676928Visible: "visible" | "hidden";
  isComp94580Visible: "visible" | "hidden";
  isComp12839Visible: "visible" | "hidden";
  isComp930514Visible: "visible" | "hidden";
  isComp776881Visible: "visible" | "hidden";
  isComp139133Visible: "visible" | "hidden";
  isComp364080Visible: "visible" | "hidden";
  isCompcompanycurrentlist_669426AuthorizationVisible: "visible" | "hidden";
  isCompcompanycurrentlist_260334AuthorizationVisible: "visible" | "hidden";
  isCompcompanycurrentlist_322700AuthorizationVisible: "visible" | "hidden";
}

export class CompanyCurrentList_ScreenBase extends React.PureComponent<ICompanyCurrentList_ScreenProps, any> {
  companycurrentlist_256573_value_kuikaTableRef: React.RefObject<any>;
  companycurrentlist_182004_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 776881, PropertyName: "value", Value: "Müşteri", TagName: "Label11_value" },
        { Id: 544338, PropertyName: "placeholder", Value: "Ara...", TagName: "TextInput0_0_placeholder" },
        { Id: 669426, PropertyName: "label", Value: "Cari Ekle", TagName: "Button1_0_label" },
        { Id: 119377, PropertyName: "value", Value: "Cari Numarası", TagName: "Label1_value" },
        { Id: 793692, PropertyName: "value", Value: "Cari Adı", TagName: "Label2_value" },
        {
          Id: 256573,
          PropertyName: "nodatafoundmessage",
          Value: "Veri Bulunamadı",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 12839, PropertyName: "value", Value: "Müşteri", TagName: "Label2_0_value" },
        { Id: 930514, PropertyName: "value", Value: "Tedarikçi", TagName: "Label2_1_value" },
        { Id: 465850, PropertyName: "value", Value: "Bilgileri", TagName: "Label2_2_value" },
        { Id: 51009, PropertyName: "value", Value: "Şirket Adı", TagName: "Label20_value" },
        { Id: 603066, PropertyName: "value", Value: "Telefon", TagName: "Label2_0_value" },
        { Id: 139133, PropertyName: "value", Value: "Tedarikçi", TagName: "Label7_value" },
        { Id: 111520, PropertyName: "value", Value: "Carileri", TagName: "Label6_value" },
        { Id: 934817, PropertyName: "title", Value: "Başlık", TagName: "TableColumn1_3_title" },
        { Id: 644816, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 412128, PropertyName: "label", Value: "Çıkış Yap", TagName: "ContentMenuItem2_2_label" },
        { Id: 391387, PropertyName: "value", Value: "Para Birimi", TagName: "Label3_value" },
        { Id: 692676, PropertyName: "title", Value: "Görüntüle", TagName: "Tooltip1_title" },
        { Id: 85218, PropertyName: "title", Value: "Düzenle", TagName: "Tooltip2_title" },
        { Id: 328497, PropertyName: "title", Value: "Sil", TagName: "Tooltip3_title" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "59ccdcb3-a8e8-4890-8e56-133d0b3b1db0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 644816, PropertyName: "label", Value: "Profile" },
        { Id: 412128, PropertyName: "label", Value: "Logout" },
        { Id: 12839, PropertyName: "value", Value: "Customer" },
        { Id: 930514, PropertyName: "value", Value: "Supplier" },
        { Id: 465850, PropertyName: "value", Value: "Informations" },
        { Id: 51009, PropertyName: "value", Value: "Company Name" },
        { Id: 603066, PropertyName: "value", Value: "Phone" },
        { Id: 776881, PropertyName: "value", Value: "Customer" },
        { Id: 139133, PropertyName: "value", Value: "Supplier" },
        { Id: 111520, PropertyName: "value", Value: "Currents" },
        { Id: 544338, PropertyName: "placeholder", Value: "Search..." },
        { Id: 669426, PropertyName: "label", Value: "New Current" },
        { Id: 119377, PropertyName: "value", Value: "Current No" },
        { Id: 793692, PropertyName: "value", Value: "Current Name" },
        { Id: 391387, PropertyName: "value", Value: "Currency" },
        { Id: 256573, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 194191, PropertyName: "value", Value: "[datafield:currentno]" },
        { Id: 297711, PropertyName: "value", Value: "[datafield:currentname]" },
        { Id: 880153, PropertyName: "value", Value: "[datafield:currency]" },
        { Id: 692676, PropertyName: "title", Value: "View" },
        { Id: 85218, PropertyName: "title", Value: "Edit" },
        { Id: 328497, PropertyName: "title", Value: "Delete" },
        { Id: 203431, PropertyName: "label", Value: "!-Trigger User List-!" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.companycurrentlist_256573_value_kuikaTableRef = React.createRef();
    this.companycurrentlist_182004_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      GetLanguage: "",
      GetCompanyById: [],
      TriggerEvent: "",
      GetUserCurrentId: [],
      spGetUserById: [],
      GetUsersFirstLetter: [],
      GoBack: "",
      SqlDeleteCurrent: "",
      SqlGetCurrentCountCompanyFiltered: [],
      spCurrentsCompanyFiltered: [],
      isComp162710Visible: "hidden",
      isComp597031Visible: "hidden",
      isComp676928Visible: "hidden",
      isComp94580Visible: "hidden",
      isComp12839Visible: "hidden",
      isComp930514Visible: "hidden",
      isComp776881Visible: "hidden",
      isComp139133Visible: "hidden",
      isComp364080Visible: "hidden",
      isCompcompanycurrentlist_669426AuthorizationVisible: "visible",
      isCompcompanycurrentlist_260334AuthorizationVisible: "visible",
      isCompcompanycurrentlist_322700AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("companycurrentlist", "companycurrentlist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CompanyCurrentListPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcompanycurrentlist_669426AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcompanycurrentlist_260334AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcompanycurrentlist_322700AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("companycurrentlist", "companycurrentlist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("companycurrentlist", "companycurrentlist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CompanyCurrentListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      companycurrentlist_597031_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CompanyCurrentListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyCurrentListPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyCurrentListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CompanyCurrentList/CompanyCurrentListPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetCompanyById = result?.data.getCompanyById;
    formVars.companycurrentlist_535514_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.cardName : null
    );
    formVars.companycurrentlist_101148_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.phoneNumber : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyCurrentListPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyCurrentListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.CompanyCurrentListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyCurrentListPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyCurrentListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CompanyCurrentList/CompanyCurrentListPageInit3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.companycurrentlist_597031_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp162710Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp597031Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp676928Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp94580Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.companycurrentlist_94580_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyCurrentListPageInit4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyCurrentListPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp162710Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.companycurrentlist_597031_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp597031Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp676928Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.companycurrentlist_94580_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp94580Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp12839Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Customer") === true
        ? "visible"
        : "hidden";
    stateVars.isComp930514Visible =
      ReactSystemFunctions.isNotEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Customer") ===
      true
        ? "visible"
        : "hidden";
    formVars.companycurrentlist_535514_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.cardName : null
    );

    formVars.companycurrentlist_101148_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.phoneNumber : null
    );

    stateVars.isComp776881Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Customer") === true
        ? "visible"
        : "hidden";
    stateVars.isComp139133Visible =
      ReactSystemFunctions.isNotEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Customer") ===
      true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CompanyCurrentListComponent_762115_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyCurrentList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "489295",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyCurrentListComponent_644816_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyCurrentList",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "159233",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyCurrentListComponent_412128_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyCurrentListComponent_977229_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyCurrentListComponent_544338_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.CompanyCurrentListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyCurrentListComponent_965785_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.CompanyCurrentListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyCurrentListComponent_669426_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CurrentEdit", "CurrentId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "CurrentEdit",
      "CompanyId",
      this.props.screenInputs.companyId ?? this.props.screenInputs.companyid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyCurrentList",
      "CurrentEdit",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "384899",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyCurrentListComponent_190982_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CurrentView",
      "currentId",
      ReactSystemFunctions.value(this, "companycurrentlist_256573_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyCurrentList",
      "CurrentView",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "644855",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyCurrentListComponent_260334_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CurrentEdit",
      "CurrentId",
      ReactSystemFunctions.value(this, "companycurrentlist_256573_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "CurrentEdit",
      "CompanyId",
      this.props.screenInputs.companyId ?? this.props.screenInputs.companyid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyCurrentList",
      "CurrentEdit",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "743016",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyCurrentListComponent_322700_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "companycurrentlist_256573_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "496c0645_2b66_5487_101e_3c1d9c7a925f_confirmation",
        this.defaultML,
        "Cari silinecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "CompanyCurrentList/CompanyCurrentListComponent_322700_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SqlDeleteCurrent = result?.data.sqlDeleteCurrent;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.CompanyCurrentListComponent_322700_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyCurrentListComponent_322700_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.CompanyCurrentListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyCurrentListComponent_203431_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companycurrentlist_544338_value", "value", "", "", "")
        ),
        "string"
      ),
      companyId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companycurrentlist_182004_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companycurrentlist_182004_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companycurrentlist_544338_value", "value", "", "", "")
        ),
        "string"
      ),
      companyId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyCurrentList/CompanyCurrentListComponent_203431_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SqlGetCurrentCountCompanyFiltered = result?.data.sqlGetCurrentCountCompanyFiltered;
    stateVars.spCurrentsCompanyFiltered = result?.data.spCurrentsCompanyFiltered;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyCurrentListComponent_203431_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyCurrentListComponent_203431_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_256573 = this.state.spCurrentsCompanyFiltered;
    formVars.companycurrentlist_182004_total = ReactSystemFunctions.value(
      this,
      this.state.SqlGetCurrentCountCompanyFiltered?.length > 0
        ? this.state.SqlGetCurrentCountCompanyFiltered[0]?.currentCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [489295, 159233, 384899, 644855, 743016] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.CompanyCurrentListPageInit();
    }
  }
}
