import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDocumentWaitingUploadListCA_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDocumentWaitingUploadListCA_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  SetLanguage: string;
  GetLanguage: string;
  Logout: boolean;
  goToSignInActivity: any;
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  spGetCurrentCompanyId: any[];
  TriggerEvent: string;
  getAllCountOfUnCompletedJobsOfCustomsAgent: any[];
  getAllUnCompletedJobsOfCustomsAgent: any[];
  isComp666662Visible: "visible" | "hidden";
  isComp597975Visible: "visible" | "hidden";
  isComp595872Visible: "visible" | "hidden";
  isComp355968Visible: "visible" | "hidden";
  isComp237173Visible: "visible" | "hidden";
  isComp807602Visible: "visible" | "hidden";
  isComp782825Visible: "visible" | "hidden";
  isComp912810Visible: "visible" | "hidden";
  isComp759828Visible: "visible" | "hidden";
}

export class DocumentWaitingUploadListCA_ScreenBase extends React.PureComponent<
  IDocumentWaitingUploadListCA_ScreenProps,
  any
> {
  documentwaitinguploadlistca_116055_value_kuikaDateRef: React.RefObject<any>;
  documentwaitinguploadlistca_646524_value_kuikaTableRef: React.RefObject<any>;
  documentwaitinguploadlistca_426979_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 629988, PropertyName: "value", Value: "Belge Yüklenecek Siparişler", TagName: "Label2_0_value" },
        {
          Id: 646524,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 189067, PropertyName: "title", Value: "Sipariş Numarası", TagName: "TableColumn0_0_title" },
        { Id: 974895, PropertyName: "title", Value: "Sipariş Tarihi", TagName: "TableColumn0_2_title" },
        { Id: 898621, PropertyName: "title", Value: "Durum", TagName: "TableColumn0_3_title" },
        { Id: 836338, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 171447, PropertyName: "label", Value: "Çıkış Yap", TagName: "ContentMenuItem2_2_label" },
        { Id: 442678, PropertyName: "title", Value: "Müşteri", TagName: "TableColumn9_title" },
        { Id: 65179, PropertyName: "title", Value: "Yükleme Tarihi", TagName: "TableColumn8_title" },
        { Id: 681268, PropertyName: "title", Value: "Belge Sayısı", TagName: "TableColumn7_title" },
        { Id: 524725, PropertyName: "value", Value: "Filtre", TagName: "Label7_0_value" },
        { Id: 116055, PropertyName: "placeholder", Value: "Sipariş Tarihi Seçiniz...", TagName: "dtOrder_placeholder" },
        { Id: 389991, PropertyName: "placeholder", Value: "Ara..", TagName: "txtSearch_placeholder" },
        { Id: 768516, PropertyName: "value", Value: "Toplam Kayıt Sayısı :", TagName: "Label7_0_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "56fd07b6-a7bc-49b1-88f8-658f3aa6c6e2",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 666662, PropertyName: "value", Value: "TR" },
        { Id: 597975, PropertyName: "value", Value: "TR" },
        { Id: 953892, PropertyName: "value", Value: "|" },
        { Id: 595872, PropertyName: "value", Value: "EN" },
        { Id: 355968, PropertyName: "value", Value: "EN" },
        { Id: 836338, PropertyName: "label", Value: "Profile" },
        { Id: 171447, PropertyName: "label", Value: "Logout" },
        { Id: 629988, PropertyName: "value", Value: "Orders Waiting For Document Upload" },
        { Id: 759828, PropertyName: "label", Value: "searchTrigger" },
        { Id: 524725, PropertyName: "value", Value: "Filter" },
        { Id: 116055, PropertyName: "placeholder", Value: "Select Order Date..." },
        { Id: 389991, PropertyName: "placeholder", Value: "Search.." },
        { Id: 646524, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 442678, PropertyName: "title", Value: "Customer" },
        { Id: 398184, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 189067, PropertyName: "title", Value: "Order Number" },
        { Id: 860982, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 974895, PropertyName: "title", Value: "Order Date" },
        { Id: 544945, PropertyName: "value", Value: "[datafield:orderdate]" },
        { Id: 65179, PropertyName: "title", Value: "Loading Date" },
        { Id: 913968, PropertyName: "value", Value: "[datafield:loadingdate]" },
        { Id: 681268, PropertyName: "title", Value: "Uploaded Doc Count" },
        { Id: 707124, PropertyName: "value", Value: "[datafield:doccount]" },
        { Id: 768516, PropertyName: "value", Value: "Total Record Count:" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.documentwaitinguploadlistca_116055_value_kuikaDateRef = React.createRef();
    this.documentwaitinguploadlistca_646524_value_kuikaTableRef = React.createRef();
    this.documentwaitinguploadlistca_426979_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      SetLanguage: "",
      GetLanguage: "",
      Logout: false,
      goToSignInActivity: "",
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      spGetCurrentCompanyId: [],
      TriggerEvent: "",
      getAllCountOfUnCompletedJobsOfCustomsAgent: [],
      getAllUnCompletedJobsOfCustomsAgent: [],
      isComp666662Visible: "hidden",
      isComp597975Visible: "hidden",
      isComp595872Visible: "hidden",
      isComp355968Visible: "hidden",
      isComp237173Visible: "hidden",
      isComp807602Visible: "hidden",
      isComp782825Visible: "hidden",
      isComp912810Visible: "hidden",
      isComp759828Visible: "hidden"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("documentwaitinguploadlistca", "documentwaitinguploadlistca");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DocumentWaitingUploadListCAPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("documentwaitinguploadlistca", "documentwaitinguploadlistca");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["CustomsAgent"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("documentwaitinguploadlistca", "documentwaitinguploadlistca");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DocumentWaitingUploadListCAPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      documentwaitinguploadlistca_807602_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DocumentWaitingUploadListCAPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DocumentWaitingUploadListCAPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DocumentWaitingUploadListCAPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DocumentWaitingUploadListCA/DocumentWaitingUploadListCAPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.documentwaitinguploadlistca_912810_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.documentwaitinguploadlistca_807602_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp237173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp807602Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782825Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp912810Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spGetCurrentCompanyId = result?.data.spGetCurrentCompanyId;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DocumentWaitingUploadListCAPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DocumentWaitingUploadListCAPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.DocumentWaitingUploadListCAComponent_759828_onClick()) return true;

    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp237173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.documentwaitinguploadlistca_807602_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp807602Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782825Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.documentwaitinguploadlistca_912810_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp912810Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DocumentWaitingUploadListCAComponent_844448_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DocumentWaitingUploadListCA",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "889963",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DocumentWaitingUploadListCAComponent_666662_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DocumentWaitingUploadListCAComponent_595872_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "en_US");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DocumentWaitingUploadListCAComponent_836338_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DocumentWaitingUploadListCA",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "819369",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DocumentWaitingUploadListCAComponent_171447_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DocumentWaitingUploadListCAComponent_759828_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CustomsAgentId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetCurrentCompanyId?.length > 0 ? this.state.spGetCurrentCompanyId[0]?.id : null
        ),
        "Guid"
      ),
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "documentwaitinguploadlistca_389991_value", "value", "", "", "")
        ),
        "string"
      ),
      orderAtDateFilter_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "documentwaitinguploadlistca_116055_value", "value", "", "", "")
        ),
        "Date"
      ),
      minDocNumber_0: ReactSystemFunctions.convertToTypeByName(2, "number"),
      CustomsAgentId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetCurrentCompanyId?.length > 0 ? this.state.spGetCurrentCompanyId[0]?.id : null
        ),
        "Guid"
      ),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "documentwaitinguploadlistca_426979_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "documentwaitinguploadlistca_426979_value", "current", "", "", "")
        ),
        "number"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "documentwaitinguploadlistca_389991_value", "value", "", "", "")
        ),
        "string"
      ),
      orderAtDateFilter_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "documentwaitinguploadlistca_116055_value", "value", "", "", "")
        ),
        "Date"
      ),
      minDocNumber_1: ReactSystemFunctions.convertToTypeByName(2, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DocumentWaitingUploadListCA/DocumentWaitingUploadListCAComponent_759828_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getAllCountOfUnCompletedJobsOfCustomsAgent = result?.data.getAllCountOfUnCompletedJobsOfCustomsAgent;
    formVars.documentwaitinguploadlistca_982944_value = ReactSystemFunctions.toString(
      this,
      stateVars.getAllCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? stateVars.getAllCountOfUnCompletedJobsOfCustomsAgent[0]?.unfinished
        : null
    );
    stateVars.getAllUnCompletedJobsOfCustomsAgent = result?.data.getAllUnCompletedJobsOfCustomsAgent;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DocumentWaitingUploadListCAComponent_759828_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DocumentWaitingUploadListCAComponent_759828_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_646524 = this.state.getAllUnCompletedJobsOfCustomsAgent;
    formVars.documentwaitinguploadlistca_982944_value = ReactSystemFunctions.toString(
      this,
      this.state.getAllCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? this.state.getAllCountOfUnCompletedJobsOfCustomsAgent[0]?.unfinished
        : null
    );

    formVars.documentwaitinguploadlistca_426979_total = ReactSystemFunctions.value(
      this,
      this.state.getAllCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? this.state.getAllCountOfUnCompletedJobsOfCustomsAgent[0]?.unfinished
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DocumentWaitingUploadListCAComponent_116055_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.DocumentWaitingUploadListCAComponent_759828_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DocumentWaitingUploadListCAComponent_389991_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.DocumentWaitingUploadListCAComponent_759828_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DocumentWaitingUploadListCAComponent_344149_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.DocumentWaitingUploadListCAComponent_759828_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DocumentWaitingUploadListCAComponent_760043_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "documentwaitinguploadlistca_646524_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "documentwaitinguploadlistca_646524_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DocumentWaitingUploadListCA",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DocumentWaitingUploadListCAComponent_426979_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.DocumentWaitingUploadListCAComponent_759828_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [889963, 819369] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DocumentWaitingUploadListCAPageInit();
    }
  }
}
