import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISurveySave_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISurveySave_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetLanguage: string;
  GetUserCurrentId: any[];
  getSurveyAnswersWithQuestions: any[];
  spGetSurveyDetails: any[];
  spGetOrderDetailsById: any[];
  GetOrderList: any[];
  GoBack: any;
  NAVIGATE: any;
  SaveuserSurveyAnswers: any[];
  getSurveyStartEndPageContents: any[];
  ChangeVisibilityOf: string;
  updateSurveyStatus: any[];
  isComp602920Visible: "visible" | "hidden";
  isComp512911Visible: "visible" | "hidden";
  isComp569820Visible: "visible" | "hidden";
  isComp923112Visible: "visible" | "hidden";
  isCompsurveysave_398823AuthorizationVisible: "visible" | "hidden";
  isCompsurveysave_845843AuthorizationEnabled: "enabled" | "disabled";
}

export class SurveySave_ScreenBase extends React.PureComponent<ISurveySave_ScreenProps, any> {
  surveysave_196551_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        {
          Id: 196551,
          PropertyName: "nodatafoundmessage",
          Value: "Veri Bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 142743, PropertyName: "placeholder", Value: "Yazınız...", TagName: "TextArea1_placeholder" },
        { Id: 551084, PropertyName: "value", Value: "Müşteri Memnuniyet Anketi", TagName: "label0_0_value" },
        { Id: 15485, PropertyName: "value", Value: "Referans No", TagName: "label12_0_value" },
        { Id: 577003, PropertyName: "value", Value: "Cari Kart Code", TagName: "label12_0_value" },
        { Id: 784588, PropertyName: "value", Value: "Sipariş No", TagName: "label12_0_value" },
        { Id: 303793, PropertyName: "value", Value: "Sipariş Tarihi", TagName: "label12_0_value" },
        {
          Id: 395047,
          PropertyName: "value",
          Value: "Memnuniyet anketini doldurduğunuz için teşekkürler.",
          TagName: "label11_0_value"
        },
        { Id: 594876, PropertyName: "value", Value: "Sorular ve Cevaplar", TagName: "label0_0_value" },
        { Id: 602920, PropertyName: "label", Value: "Devam Et", TagName: "btnFinish_label" },
        { Id: 512911, PropertyName: "label", Value: "Gönder", TagName: "btnClose_label" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "159c6af1-0ade-4b5c-ac3c-bf25166f485a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 551084, PropertyName: "value", Value: "Customer Satisfaction Survey" },
        { Id: 15485, PropertyName: "value", Value: "Customer Ref No" },
        { Id: 577003, PropertyName: "value", Value: "Card Number" },
        { Id: 784588, PropertyName: "value", Value: "Order Number" },
        { Id: 303793, PropertyName: "value", Value: "Order Date" },
        { Id: 395047, PropertyName: "value", Value: "Thank you for completing the satisfaction survey." },
        { Id: 594876, PropertyName: "value", Value: "Questions and Answers" },
        { Id: 196551, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 590662, PropertyName: "value", Value: "[datafield:numberedquestion]" },
        { Id: 142743, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 602920, PropertyName: "label", Value: "Continue" },
        { Id: 512911, PropertyName: "label", Value: "Send" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.surveysave_196551_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetLanguage: "",
      GetUserCurrentId: [],
      getSurveyAnswersWithQuestions: [],
      spGetSurveyDetails: [],
      spGetOrderDetailsById: [],
      GetOrderList: [],
      GoBack: "",
      NAVIGATE: "",
      SaveuserSurveyAnswers: [],
      getSurveyStartEndPageContents: [],
      ChangeVisibilityOf: "",
      updateSurveyStatus: [],
      isComp602920Visible: "visible",
      isComp512911Visible: "hidden",
      isComp569820Visible: "visible",
      isComp923112Visible: "hidden",
      isCompsurveysave_398823AuthorizationVisible: "visible",
      isCompsurveysave_845843AuthorizationEnabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("surveysave", "surveysave");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SurveySavePageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompsurveysave_398823AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);
    ReactSystemFunctions.disableIsNotAuthorized(this, "isCompsurveysave_845843AuthorizationEnabled", []);

    KuikaAppManager.calculateAndSetBodyHeight("surveysave", "surveysave");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("surveysave", "surveysave");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SurveySavePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      surveysave_276881_value: this.state.getSurveyStartEndPageContents?.at?.(0)?.endPageContents ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SurveySavePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveySavePageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SurveySavePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      surveyId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      ),
      QuestionNo_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      surveyId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      ),
      CustomerCardName_4: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SurveySave/SurveySavePageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.getSurveyAnswersWithQuestions = result?.data.getSurveyAnswersWithQuestions;

    stateVars.spGetSurveyDetails = result?.data.spGetSurveyDetails;
    stateVars.spGetOrderDetailsById = result?.data.spGetOrderDetailsById;
    stateVars.GetOrderList = result?.data.getOrderList;
    formVars.surveysave_650543_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardname : null
    );
    formVars.surveysave_208602_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.custrefno : null
    );
    formVars.surveysave_352865_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardcode : null
    );
    formVars.surveysave_500705_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docnum : null
    );
    formVars.surveysave_608234_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveySavePageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SurveySavePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.surveysave_650543_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
    );

    formVars.surveysave_208602_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
    );

    formVars.surveysave_352865_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardcode : null
    );

    formVars.surveysave_500705_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docnum : null
    );

    formVars.surveysave_608234_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    stateVars.dataSource_196551 = this.state.getSurveyAnswersWithQuestions;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SurveySaveComponent_671628_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SurveySaveComponent_192949_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "rateSurveyQuestion",
      "surveyId",
      this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid
    );
    KuikaAppManager.addToPageInputVariables(
      "rateSurveyQuestion",
      "questionNo",
      ReactSystemFunctions.value(this, "surveysave_196551_value", "questionNo")
    );
    KuikaAppManager.addToPageInputVariables(
      "rateSurveyQuestion",
      "lang",
      ReactSystemFunctions.toString(this, this.state.GetLanguage)
    );
    KuikaAppManager.addToPageInputVariables(
      "rateSurveyQuestion",
      "rowNumber",
      ReactSystemFunctions.value(this, "surveysave_196551_value", "rowNumber")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SurveySave",
      "rateSurveyQuestion",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "719861",
      null,
      null,
      "True",
      "600px",
      "60vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SurveySaveComponent_845843_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      answer_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "surveysave_845843_value", "value", "", "", "")
        ),
        "string"
      ),
      question_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "surveysave_196551_value", "question"),
        "string"
      ),
      questionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "surveysave_196551_value", "questionEN"),
        "string"
      ),
      surveyID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      ),
      userID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "surveysave_196551_value", "userID"),
        "Guid"
      ),
      questionId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "surveysave_196551_value", "surveyQuestionsId"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SurveySave/SurveySaveComponent_845843_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveuserSurveyAnswers = result?.data.saveuserSurveyAnswers;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SurveySaveComponent_602920_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      lang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SurveySave/SurveySaveComponent_602920_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getSurveyStartEndPageContents = result?.data.getSurveyStartEndPageContents;
    formVars.surveysave_276881_value = ReactSystemFunctions.toString(
      this,
      stateVars.getSurveyStartEndPageContents?.length > 0
        ? stateVars.getSurveyStartEndPageContents[0]?.endPageContents
        : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveySaveComponent_602920_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SurveySaveComponent_602920_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp602920Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp512911Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp569820Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp923112Visible",
      "visible"
    );
    formVars.surveysave_276881_value = ReactSystemFunctions.toString(
      this,
      this.state.getSurveyStartEndPageContents?.length > 0
        ? this.state.getSurveyStartEndPageContents[0]?.endPageContents
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SurveySaveComponent_512911_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      surveyId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      ),
      status_0: ReactSystemFunctions.convertToTypeByName(20, "number"),
      isCompleted_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      completionDate_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      userId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SurveySave/SurveySaveComponent_512911_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.updateSurveyStatus = result?.data.updateSurveyStatus;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveySaveComponent_512911_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SurveySaveComponent_512911_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [719861] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SurveySavePageInit();
    }
  }
}
