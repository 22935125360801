import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashboardLogistic_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashboardLogistic_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  SetLanguage: string;
  GetLanguage: string;
  Logout: boolean;
  goToSignInActivity: any;
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  getCurrentUserRole: any[];
  getCountOfOrdersByVehicleStatus: any[];
  getCountOfOrdersInProgress: any[];
  spGetOrdersInProgress: any[];
  getCountOfUnCompletedDocsOfCFNRoles_yi: any[];
  getAllCountOfUnCompletedDocsOfCFNRoles_yd: any[];
  getAllUnCompletedDocsOfCFNRoles_yi: any[];
  getAllUnCompletedDocsOfCFNRoles_yd: any[];
  isComp666662Visible: "visible" | "hidden";
  isComp597975Visible: "visible" | "hidden";
  isComp595872Visible: "visible" | "hidden";
  isComp355968Visible: "visible" | "hidden";
  isComp237173Visible: "visible" | "hidden";
  isComp807602Visible: "visible" | "hidden";
  isComp782825Visible: "visible" | "hidden";
  isComp912810Visible: "visible" | "hidden";
  isComp588417Visible: "visible" | "hidden";
}

export class DashboardLogistic_ScreenBase extends React.PureComponent<IDashboardLogistic_ScreenProps, any> {
  dashboardlogistic_672810_value_kuikaGalleryViewRef: React.RefObject<any>;
  dashboardlogistic_10983_value_kuikaPaginationRef: React.RefObject<any>;
  dashboardlogistic_646524_value_kuikaTableRef: React.RefObject<any>;
  dashboardlogistic_821765_value_kuikaPaginationRef: React.RefObject<any>;
  dashboardlogistic_281029_value_kuikaTableRef: React.RefObject<any>;
  dashboardlogistic_586901_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 360915, PropertyName: "value", Value: "Belge Yüklenmesi Bekleniyor", TagName: "Label2_1_value" },
        { Id: 901026, PropertyName: "value", Value: "Tamamlandı", TagName: "Label2_1_value" },
        { Id: 629988, PropertyName: "value", Value: "Belge Yüklenmesi Bekleniyor", TagName: "Label2_0_value" },
        {
          Id: 646524,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 189067, PropertyName: "title", Value: "Sipariş Numarası", TagName: "TableColumn0_0_title" },
        { Id: 974895, PropertyName: "title", Value: "Sipariş Tarihi", TagName: "TableColumn0_2_title" },
        { Id: 898621, PropertyName: "title", Value: "Durum", TagName: "TableColumn0_3_title" },
        { Id: 836338, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 171447, PropertyName: "label", Value: "Çıkış Yap", TagName: "ContentMenuItem2_2_label" },
        { Id: 60000, PropertyName: "value", Value: "Toplam", TagName: "Label7_1_value" },
        { Id: 442678, PropertyName: "title", Value: "Müşteri", TagName: "TableColumn9_title" },
        { Id: 65179, PropertyName: "title", Value: "Yükleme Tarihi", TagName: "TableColumn8_title" },
        { Id: 681268, PropertyName: "title", Value: "Belge Sayısı", TagName: "TableColumn7_title" },
        { Id: 3236, PropertyName: "value", Value: "Hepsi", TagName: "Label7_1_value" },
        { Id: 212248, PropertyName: "value", Value: "Şimdiye Kadar", TagName: "Label7_0_value" },
        { Id: 586821, PropertyName: "value", Value: "Talep Edilen", TagName: "Label7_1_value" },
        { Id: 148979, PropertyName: "value", Value: "Onay Bekleyen", TagName: "Label7_1_value" },
        { Id: 159666, PropertyName: "value", Value: "Onaylanan", TagName: "Label7_1_value" },
        { Id: 228223, PropertyName: "value", Value: "Yüklenen", TagName: "Label7_1_value" },
        { Id: 590330, PropertyName: "value", Value: "Yola Çıkan", TagName: "Label7_1_value" },
        { Id: 280549, PropertyName: "value", Value: "Teslim Edilen", TagName: "Label7_1_value" },
        { Id: 870116, PropertyName: "value", Value: "Son Siparişler", TagName: "Label7_0_value" },
        { Id: 324758, PropertyName: "value", Value: "Toplam", TagName: "Label7_0_value" },
        { Id: 872280, PropertyName: "value", Value: "Kayıt", TagName: "Label7_2_value" },
        { Id: 536585, PropertyName: "value", Value: "Tümü", TagName: "Label7_1_value" },
        { Id: 857737, PropertyName: "title", Value: "Durum", TagName: "TableColumn19_title" },
        { Id: 743598, PropertyName: "value", Value: "Yurt içi Siparişler", TagName: "Label48_value" },
        { Id: 736771, PropertyName: "value", Value: "Yurt dışı Siparişler", TagName: "Label7_0_value" },
        { Id: 306620, PropertyName: "value", Value: "Belge Yüklenmesi Bekleniyor", TagName: "Label7_1_value" },
        { Id: 895667, PropertyName: "value", Value: "Tamamlanan", TagName: "Label7_1_value" },
        { Id: 88203, PropertyName: "value", Value: "Toplam", TagName: "Label7_1_value" },
        { Id: 166399, PropertyName: "value", Value: "Belge Yüklenmesi Bekleniyor", TagName: "Label7_0_value" },
        {
          Id: 281029,
          PropertyName: "nodatafoundmessage",
          Value: "Veri Bulunamadı.",
          TagName: "Table0_0_nodatafoundmessage"
        },
        { Id: 596449, PropertyName: "title", Value: "Müşteri", TagName: "TableColumn0_0_title" },
        { Id: 598838, PropertyName: "title", Value: "Sipariş No", TagName: "TableColumn0_1_title" },
        { Id: 290898, PropertyName: "title", Value: "Sipariş Tarihi", TagName: "TableColumn0_2_title" },
        { Id: 20578, PropertyName: "title", Value: "Yükleme Tarihi", TagName: "TableColumn0_3_title" },
        { Id: 237524, PropertyName: "title", Value: "Yüklenen Belge Sayısı", TagName: "TableColumn0_4_title" },
        { Id: 271268, PropertyName: "title", Value: "Durum", TagName: "TableColumn0_5_title" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "7444b62b-ac15-4d73-ba04-fb2d8b7673fe",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 666662, PropertyName: "value", Value: "TR" },
        { Id: 597975, PropertyName: "value", Value: "TR" },
        { Id: 953892, PropertyName: "value", Value: "|" },
        { Id: 595872, PropertyName: "value", Value: "EN" },
        { Id: 355968, PropertyName: "value", Value: "EN" },
        { Id: 836338, PropertyName: "label", Value: "Profile" },
        { Id: 171447, PropertyName: "label", Value: "Logout" },
        { Id: 212248, PropertyName: "value", Value: "Up to Now" },
        { Id: 586821, PropertyName: "value", Value: "Requested" },
        { Id: 148979, PropertyName: "value", Value: "Waiting for Approval" },
        { Id: 159666, PropertyName: "value", Value: "Approved" },
        { Id: 228223, PropertyName: "value", Value: "Loaded" },
        { Id: 590330, PropertyName: "value", Value: "Out of Delivery" },
        { Id: 280549, PropertyName: "value", Value: "Delivered" },
        { Id: 870116, PropertyName: "value", Value: "Recent Orders" },
        { Id: 324758, PropertyName: "value", Value: "Total" },
        { Id: 872280, PropertyName: "value", Value: "Records" },
        { Id: 536585, PropertyName: "value", Value: "All" },
        { Id: 672810, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 627826, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 290051, PropertyName: "value", Value: "[datafield:cardcode]" },
        { Id: 362412, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 785307, PropertyName: "value", Value: "[datafield:orderdate]" },
        { Id: 119975, PropertyName: "value", Value: "[datafield:orderstatusdesc]" },
        { Id: 743598, PropertyName: "value", Value: "Domestic Orders" },
        { Id: 360915, PropertyName: "value", Value: "Document Upload Waiting" },
        { Id: 901026, PropertyName: "value", Value: "Completed" },
        { Id: 60000, PropertyName: "value", Value: "Total" },
        { Id: 629988, PropertyName: "value", Value: "Document Upload Waiting" },
        { Id: 3236, PropertyName: "value", Value: "All" },
        { Id: 646524, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 442678, PropertyName: "title", Value: "Customer" },
        { Id: 398184, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 189067, PropertyName: "title", Value: "Order Number" },
        { Id: 860982, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 974895, PropertyName: "title", Value: "Order Date" },
        { Id: 544945, PropertyName: "value", Value: "[datafield:orderdate]" },
        { Id: 65179, PropertyName: "title", Value: "Loading Date" },
        { Id: 913968, PropertyName: "value", Value: "[datafield:loadingdate]" },
        { Id: 681268, PropertyName: "title", Value: "Uploaded Doc Count" },
        { Id: 707124, PropertyName: "value", Value: "[datafield:doccount]" },
        { Id: 857737, PropertyName: "title", Value: "Status" },
        { Id: 283997, PropertyName: "value", Value: "[datafield:orderstatusdesc]" },
        { Id: 736771, PropertyName: "value", Value: "Abroad Orders" },
        { Id: 306620, PropertyName: "value", Value: "Document Upload Waiting" },
        { Id: 895667, PropertyName: "value", Value: "Completed" },
        { Id: 88203, PropertyName: "value", Value: "Total" },
        { Id: 166399, PropertyName: "value", Value: "Document Upload Waiting" },
        { Id: 67941, PropertyName: "value", Value: "All" },
        { Id: 281029, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 596449, PropertyName: "title", Value: "Customer" },
        { Id: 673168, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 598838, PropertyName: "title", Value: "Order Number" },
        { Id: 367289, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 290898, PropertyName: "title", Value: "Order Date" },
        { Id: 417045, PropertyName: "value", Value: "[datafield:orderdate]" },
        { Id: 20578, PropertyName: "title", Value: "Loading Date" },
        { Id: 300707, PropertyName: "value", Value: "[datafield:loadingdate]" },
        { Id: 237524, PropertyName: "title", Value: "Uploaded Doc Count" },
        { Id: 490784, PropertyName: "value", Value: "[datafield:doccount]" },
        { Id: 271268, PropertyName: "title", Value: "Status" },
        { Id: 874383, PropertyName: "value", Value: "[datafield:orderstatusdesc]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardlogistic_672810_value_kuikaGalleryViewRef = React.createRef();
    this.dashboardlogistic_10983_value_kuikaPaginationRef = React.createRef();
    this.dashboardlogistic_646524_value_kuikaTableRef = React.createRef();
    this.dashboardlogistic_821765_value_kuikaPaginationRef = React.createRef();
    this.dashboardlogistic_281029_value_kuikaTableRef = React.createRef();
    this.dashboardlogistic_586901_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      SetLanguage: "",
      GetLanguage: "",
      Logout: false,
      goToSignInActivity: "",
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      getCurrentUserRole: [],
      getCountOfOrdersByVehicleStatus: [],
      getCountOfOrdersInProgress: [],
      spGetOrdersInProgress: [],
      getCountOfUnCompletedDocsOfCFNRoles_yi: [],
      getAllCountOfUnCompletedDocsOfCFNRoles_yd: [],
      getAllUnCompletedDocsOfCFNRoles_yi: [],
      getAllUnCompletedDocsOfCFNRoles_yd: [],
      isComp666662Visible: "hidden",
      isComp597975Visible: "hidden",
      isComp595872Visible: "hidden",
      isComp355968Visible: "hidden",
      isComp237173Visible: "hidden",
      isComp807602Visible: "hidden",
      isComp782825Visible: "hidden",
      isComp912810Visible: "hidden",
      isComp588417Visible: "hidden"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardlogistic", "dashboardlogistic");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashboardLogisticPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardlogistic", "dashboardlogistic");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Logistic"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardlogistic", "dashboardlogistic");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashboardLogisticPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboardlogistic_807602_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DashboardLogisticPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardLogisticPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashboardLogisticPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      username_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      customsAgentId_4: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      logisticSupplierId_4: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      distributorID_4: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      search_5: ReactSystemFunctions.convertToTypeByName("", "string"),
      search_6: ReactSystemFunctions.convertToTypeByName("", "string"),
      pageCount_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_10983_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentPage_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_10983_value", "current", "", "", "")
        ),
        "number"
      ),
      minDocNumber_7: ReactSystemFunctions.convertToTypeByName(1, "number"),
      minDocNumber_8: ReactSystemFunctions.convertToTypeByName(1, "number"),
      pageSize_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_821765_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentPage_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_821765_value", "current", "", "", "")
        ),
        "number"
      ),
      minDocNumber_9: ReactSystemFunctions.convertToTypeByName(1, "number"),
      pageSize_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_586901_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentPage_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_586901_value", "current", "", "", "")
        ),
        "number"
      ),
      minDocNumber_10: ReactSystemFunctions.convertToTypeByName(1, "number")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashboardLogistic/DashboardLogisticPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.dashboardlogistic_912810_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.dashboardlogistic_807602_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp237173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp807602Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782825Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp912810Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getCurrentUserRole = result?.data.getCurrentUserRole;
    stateVars.getCountOfOrdersByVehicleStatus = result?.data.getCountOfOrdersByVehicleStatus;
    formVars.dashboardlogistic_196975_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.requested
        : null
    );
    formVars.dashboardlogistic_685368_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.waitingForApproval
        : null
    );
    formVars.dashboardlogistic_576380_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.approved
        : null
    );
    formVars.dashboardlogistic_781965_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.loaded
        : null
    );
    formVars.dashboardlogistic_429009_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.outOfDelivery
        : null
    );
    formVars.dashboardlogistic_340960_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.delivered
        : null
    );
    stateVars.getCountOfOrdersInProgress = result?.data.getCountOfOrdersInProgress;
    formVars.dashboardlogistic_255633_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersInProgress?.length > 0 ? stateVars.getCountOfOrdersInProgress[0]?.countOfRecords : null
    );
    stateVars.spGetOrdersInProgress = result?.data.spGetOrdersInProgress;

    stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi = result?.data.getCountOfUnCompletedDocsOfCFNRoles_yi;
    formVars.dashboardlogistic_66171_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.unfinished
        : null
    );
    formVars.dashboardlogistic_263586_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.completed
        : null
    );
    formVars.dashboardlogistic_771843_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.total
        : null
    );
    stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd = result?.data.getAllCountOfUnCompletedDocsOfCFNRoles_yd;
    formVars.dashboardlogistic_893795_value = ReactSystemFunctions.toString(
      this,
      stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.unfinished
        : null
    );
    formVars.dashboardlogistic_699228_value = ReactSystemFunctions.toString(
      this,
      stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.completed
        : null
    );
    formVars.dashboardlogistic_410259_value = ReactSystemFunctions.toString(
      this,
      stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.total
        : null
    );
    stateVars.getAllUnCompletedDocsOfCFNRoles_yi = result?.data.getAllUnCompletedDocsOfCFNRoles_yi;

    stateVars.getAllUnCompletedDocsOfCFNRoles_yd = result?.data.getAllUnCompletedDocsOfCFNRoles_yd;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardLogisticPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardLogisticPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp237173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardlogistic_807602_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp807602Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782825Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardlogistic_912810_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp912810Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardlogistic_196975_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.requested
        : null
    );

    formVars.dashboardlogistic_685368_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.waitingForApproval
        : null
    );

    formVars.dashboardlogistic_576380_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.approved
        : null
    );

    formVars.dashboardlogistic_781965_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.loaded
        : null
    );

    formVars.dashboardlogistic_429009_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.outOfDelivery
        : null
    );

    formVars.dashboardlogistic_340960_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.delivered
        : null
    );

    formVars.dashboardlogistic_255633_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersInProgress?.length > 0
        ? this.state.getCountOfOrdersInProgress[0]?.countOfRecords
        : null
    );

    stateVars.dataSource_672810 = this.state.spGetOrdersInProgress;
    formVars.dashboardlogistic_627826_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.cardName : null
    );

    formVars.dashboardlogistic_290051_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.cardCode : null
    );

    formVars.dashboardlogistic_362412_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderNumber : null
    );

    formVars.dashboardlogistic_785307_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderDate : null
    );

    formVars.dashboardlogistic_119975_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderStatusDesc : null
    );

    formVars.dashboardlogistic_10983_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfOrdersInProgress?.length > 0
        ? this.state.getCountOfOrdersInProgress[0]?.countOfRecords
        : null
    );

    formVars.dashboardlogistic_66171_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? this.state.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.unfinished
        : null
    );

    formVars.dashboardlogistic_263586_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? this.state.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.completed
        : null
    );

    formVars.dashboardlogistic_771843_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? this.state.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.total
        : null
    );

    stateVars.dataSource_646524 = this.state.getAllUnCompletedDocsOfCFNRoles_yi;
    formVars.dashboardlogistic_821765_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? this.state.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.unfinished
        : null
    );

    formVars.dashboardlogistic_893795_value = ReactSystemFunctions.toString(
      this,
      this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.unfinished
        : null
    );

    formVars.dashboardlogistic_699228_value = ReactSystemFunctions.toString(
      this,
      this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.completed
        : null
    );

    formVars.dashboardlogistic_410259_value = ReactSystemFunctions.toString(
      this,
      this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.total
        : null
    );

    stateVars.dataSource_281029 = this.state.getAllUnCompletedDocsOfCFNRoles_yd;
    formVars.dashboardlogistic_586901_total = ReactSystemFunctions.value(
      this,
      this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.unfinished
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashboardLogisticComponent_844448_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "889963",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_666662_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_595872_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "en_US");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_836338_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "819369",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_171447_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_737607_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "0");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_166717_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "20");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_25549_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "30");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_138105_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "40");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_373411_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "50");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_102985_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "60");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_738943_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_586026_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "dashboardlogistic_672810_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "dashboardlogistic_672810_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_10983_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      search_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      pageCount_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_10983_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_10983_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardLogistic/DashboardLogisticComponent_10983_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCountOfOrdersInProgress = result?.data.getCountOfOrdersInProgress;
    formVars.dashboardlogistic_255633_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersInProgress?.length > 0 ? stateVars.getCountOfOrdersInProgress[0]?.countOfRecords : null
    );
    stateVars.spGetOrdersInProgress = result?.data.spGetOrdersInProgress;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardLogisticComponent_10983_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardLogisticComponent_10983_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardlogistic_255633_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersInProgress?.length > 0
        ? this.state.getCountOfOrdersInProgress[0]?.countOfRecords
        : null
    );

    stateVars.dataSource_672810 = this.state.spGetOrdersInProgress;
    formVars.dashboardlogistic_627826_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.cardName : null
    );

    formVars.dashboardlogistic_290051_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.cardCode : null
    );

    formVars.dashboardlogistic_362412_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderNumber : null
    );

    formVars.dashboardlogistic_785307_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderDate : null
    );

    formVars.dashboardlogistic_119975_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderStatusDesc : null
    );

    formVars.dashboardlogistic_10983_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfOrdersInProgress?.length > 0
        ? this.state.getCountOfOrdersInProgress[0]?.countOfRecords
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_35770_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_760043_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "dashboardlogistic_646524_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "dashboardlogistic_646524_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_821765_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      minDocNumber_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      roleName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getCurrentUserRole?.length > 0 ? this.state.getCurrentUserRole[0]?.kroleName : null
        ),
        "string"
      ),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_821765_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_821765_value", "current", "", "", "")
        ),
        "number"
      ),
      minDocNumber_1: ReactSystemFunctions.convertToTypeByName(1, "number"),
      roleName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getCurrentUserRole?.length > 0 ? this.state.getCurrentUserRole[0]?.kroleName : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardLogistic/DashboardLogisticComponent_821765_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi = result?.data.getCountOfUnCompletedDocsOfCFNRoles_yi;
    formVars.dashboardlogistic_66171_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.unfinished
        : null
    );
    formVars.dashboardlogistic_263586_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.completed
        : null
    );
    formVars.dashboardlogistic_771843_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? stateVars.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.total
        : null
    );
    stateVars.getAllUnCompletedDocsOfCFNRoles_yi = result?.data.getAllUnCompletedDocsOfCFNRoles_yi;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardLogisticComponent_821765_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardLogisticComponent_821765_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardlogistic_66171_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? this.state.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.unfinished
        : null
    );

    formVars.dashboardlogistic_263586_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? this.state.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.completed
        : null
    );

    formVars.dashboardlogistic_771843_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? this.state.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.total
        : null
    );

    stateVars.dataSource_646524 = this.state.getAllUnCompletedDocsOfCFNRoles_yi;
    formVars.dashboardlogistic_821765_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.length > 0
        ? this.state.getCountOfUnCompletedDocsOfCFNRoles_yi[0]?.unfinished
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_268452_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_596653_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "dashboardlogistic_281029_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "dashboardlogistic_281029_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardLogistic",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardLogisticComponent_586901_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      minDocNumber_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      roleName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getCurrentUserRole?.length > 0 ? this.state.getCurrentUserRole[0]?.kroleName : null
        ),
        "string"
      ),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_586901_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardlogistic_586901_value", "current", "", "", "")
        ),
        "number"
      ),
      minDocNumber_1: ReactSystemFunctions.convertToTypeByName(1, "number"),
      roleName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getCurrentUserRole?.length > 0 ? this.state.getCurrentUserRole[0]?.kroleName : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardLogistic/DashboardLogisticComponent_586901_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd = result?.data.getAllCountOfUnCompletedDocsOfCFNRoles_yd;
    formVars.dashboardlogistic_893795_value = ReactSystemFunctions.toString(
      this,
      stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.unfinished
        : null
    );
    formVars.dashboardlogistic_699228_value = ReactSystemFunctions.toString(
      this,
      stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.completed
        : null
    );
    formVars.dashboardlogistic_410259_value = ReactSystemFunctions.toString(
      this,
      stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? stateVars.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.total
        : null
    );
    stateVars.getAllUnCompletedDocsOfCFNRoles_yd = result?.data.getAllUnCompletedDocsOfCFNRoles_yd;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardLogisticComponent_586901_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardLogisticComponent_586901_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardlogistic_893795_value = ReactSystemFunctions.toString(
      this,
      this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.unfinished
        : null
    );

    formVars.dashboardlogistic_699228_value = ReactSystemFunctions.toString(
      this,
      this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.completed
        : null
    );

    formVars.dashboardlogistic_410259_value = ReactSystemFunctions.toString(
      this,
      this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.total
        : null
    );

    stateVars.dataSource_281029 = this.state.getAllUnCompletedDocsOfCFNRoles_yd;
    formVars.dashboardlogistic_586901_total = ReactSystemFunctions.value(
      this,
      this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.length > 0
        ? this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd[0]?.unfinished
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [889963, 819369, , , , , , , , , , , ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashboardLogisticPageInit();
    }
  }
}
