import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface INewPassword_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface INewPassword_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  PasswordChange: string;
  Notify: boolean;
  IfThenElse: boolean;
}

export class NewPassword_ScreenBase extends React.PureComponent<INewPassword_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 747208, PropertyName: "value", Value: "Yeni Şifre Oluştur", TagName: "Label5_value" },
        { Id: 861512, PropertyName: "value", Value: "Eski Şifre", TagName: "Label4_value" },
        { Id: 963625, PropertyName: "value", Value: "Yeni Şifre", TagName: "Label10_0_value" },
        { Id: 590006, PropertyName: "value", Value: "Yeni Şifre (Tekrar)", TagName: "Label8_value" },
        { Id: 42131, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "11830cd8-cfa0-4b86-8ae0-d1b92bc9266f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 747208, PropertyName: "value", Value: "Change Password" },
        { Id: 861512, PropertyName: "value", Value: "Old Password" },
        { Id: 371177, PropertyName: "placeholder", Value: "***********" },
        { Id: 963625, PropertyName: "value", Value: "New Password" },
        { Id: 345440, PropertyName: "placeholder", Value: "***********" },
        { Id: 590006, PropertyName: "value", Value: "New Password (Again)" },
        { Id: 406064, PropertyName: "placeholder", Value: "***********" },
        { Id: 42131, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      IfThenElse: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("newpassword", "newpassword");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.NewPasswordPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("newpassword", "newpassword");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("newpassword", "newpassword");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.NewPasswordPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  NewPasswordPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  NewPasswordComponent_462237_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  NewPasswordComponent_42131_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "newpassword_371177_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "newpassword_371177_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "newpassword_406064_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "newpassword_406064_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PasswordChange = await ReactSystemFunctions.changePassword(
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "newpassword_371177_value", "value", "", "", "")
      ),
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "newpassword_406064_value", "value", "", "", "")
      ),
      null,
      null,
      undefined
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  NewPasswordComponent_42131_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "2abc17e8_5362_06b8_575c_131f64cffb9e_notify",
        this.defaultML,
        "Eski Şifreniz Yeni Şifrenizden Farklı Olmalıdır!"
      ),
      "danger",
      "bottom-right",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  NewPasswordComponent_42131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "newpassword_345440_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "newpassword_406064_value", "value", "", "", "")
        )
      )
    ) {
      isErrorOccurred = await this.NewPasswordComponent_42131_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.NewPasswordComponent_42131_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
