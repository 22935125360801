import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISurveyParticipants_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISurveyParticipants_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  GetLanguage: string;
  SelectCustomerSurveys: any[];
  sqlGetSurveyCount: any[];
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  SetValueOf: any;
  spGetSurveyReport: any[];
  ExportExcel: any;
  spGetUserSurveyAnswersReport: any[];
  isComp110041Visible: "visible" | "hidden";
  isComp280455Visible: "visible" | "hidden";
  isComp20260Visible: "visible" | "hidden";
  isComp78972Visible: "visible" | "hidden";
  isComp152064Visible: "visible" | "hidden";
}

export class SurveyParticipants_ScreenBase extends React.PureComponent<ISurveyParticipants_ScreenProps, any> {
  surveyparticipants_828121_value_kuikaTableRef: React.RefObject<any>;
  surveyparticipants_820232_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 844822, PropertyName: "value", Value: "Anket Katılımcıları", TagName: "Label1_value" },
        {
          Id: 828121,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 602935, PropertyName: "title", Value: "İsim Soyisim", TagName: "TableColumn1_title" },
        { Id: 410949, PropertyName: "title", Value: "Sipariş No", TagName: "TableColumn8_title" },
        { Id: 950360, PropertyName: "title", Value: "Gönderilme Tarihi", TagName: "TableColumn3_title" },
        { Id: 708014, PropertyName: "title", Value: "Tamamlanma Tarihi", TagName: "TableColumn4_title" },
        { Id: 33733, PropertyName: "title", Value: "Durum", TagName: "TableColumn6_title" },
        { Id: 468391, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 274279, PropertyName: "label", Value: "Çıkış Yap", TagName: "ContentMenuItem2_2_label" },
        { Id: 187658, PropertyName: "placeholder", Value: "Ara...", TagName: "TextInput0_0_placeholder" },
        { Id: 531009, PropertyName: "title", Value: "Görüntüle", TagName: "Tooltip1_title" },
        { Id: 774325, PropertyName: "label", Value: "Cevap Ortalamaları", TagName: "Button1_label" },
        { Id: 195836, PropertyName: "value", Value: "Anket Gönderildi", TagName: "Label10_value" },
        { Id: 953978, PropertyName: "value", Value: "Anket Tamamlanmadı", TagName: "Label12_value" },
        { Id: 608070, PropertyName: "value", Value: "Anket Tamamlandı", TagName: "Label13_value" },
        { Id: 515578, PropertyName: "label", Value: "Tamamlananların Cevaplari", TagName: "Button2_label" },
        { Id: 379700, PropertyName: "title", Value: "Şirket", TagName: "TableColumn7_title" },
        { Id: 175828, PropertyName: "value", Value: "Raporlar", TagName: "Label15_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "8dd8ddd8-5b18-4e70-8069-c845fcf657d9",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 468391, PropertyName: "label", Value: "Profile" },
        { Id: 274279, PropertyName: "label", Value: "Logout" },
        { Id: 844822, PropertyName: "value", Value: "Survey Participants" },
        { Id: 187658, PropertyName: "placeholder", Value: "Search..." },
        { Id: 175828, PropertyName: "value", Value: "Reports" },
        { Id: 774325, PropertyName: "label", Value: "Average of Answers" },
        { Id: 515578, PropertyName: "label", Value: "Answers of Completed" },
        { Id: 828121, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 379700, PropertyName: "title", Value: "Company" },
        { Id: 973303, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 410949, PropertyName: "title", Value: "Order No" },
        { Id: 254590, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 602935, PropertyName: "title", Value: "Name Surname" },
        { Id: 96105, PropertyName: "value", Value: "[datafield:name]" },
        { Id: 313338, PropertyName: "value", Value: "[datafield:surname]" },
        { Id: 950360, PropertyName: "title", Value: "Sent Date" },
        { Id: 627735, PropertyName: "value", Value: "[datafield:sentdate]" },
        { Id: 708014, PropertyName: "title", Value: "Completion Date" },
        { Id: 66753, PropertyName: "value", Value: "[datafield:completiondate]" },
        { Id: 296913, PropertyName: "value", Value: "--" },
        { Id: 33733, PropertyName: "title", Value: "Status" },
        { Id: 195836, PropertyName: "value", Value: "Survey Sent" },
        { Id: 953978, PropertyName: "value", Value: "Survey Incomplete" },
        { Id: 608070, PropertyName: "value", Value: "Survey Completed" },
        { Id: 531009, PropertyName: "title", Value: "View" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.surveyparticipants_828121_value_kuikaTableRef = React.createRef();
    this.surveyparticipants_820232_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      GetLanguage: "",
      SelectCustomerSurveys: [],
      sqlGetSurveyCount: [],
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      SetValueOf: "",
      spGetSurveyReport: [],
      ExportExcel: "",
      spGetUserSurveyAnswersReport: [],
      isComp110041Visible: "hidden",
      isComp280455Visible: "hidden",
      isComp20260Visible: "hidden",
      isComp78972Visible: "hidden",
      isComp152064Visible: "hidden"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("surveyparticipants", "surveyparticipants");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SurveyParticipantsPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("surveyparticipants", "surveyparticipants");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Administrator", "Sale", "Logistic", "Quality", "Editor"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("surveyparticipants", "surveyparticipants");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SurveyParticipantsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      surveyparticipants_280455_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SurveyParticipantsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveyParticipantsPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SurveyParticipantsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_820232_value", "current", "", "", "")
        ),
        "number"
      ),
      pageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_820232_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchValue_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_187658_value", "value", "", "", "")
        ),
        "string"
      ),
      searchValue_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_187658_value", "value", "", "", "")
        ),
        "string"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SurveyParticipants/SurveyParticipantsPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCustomerSurveys = result?.data.selectCustomerSurveys;

    stateVars.isComp152064Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectCustomerSurveys?.length > 0 ? stateVars.SelectCustomerSurveys[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.sqlGetSurveyCount = result?.data.sqlGetSurveyCount;
    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.surveyparticipants_78972_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.surveyparticipants_280455_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp110041Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp280455Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp20260Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78972Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveyParticipantsPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SurveyParticipantsPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp110041Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.surveyparticipants_280455_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp280455Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp20260Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.surveyparticipants_78972_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp78972Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_828121 = this.state.SelectCustomerSurveys;
    stateVars.isComp152064Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SelectCustomerSurveys?.length > 0 ? this.state.SelectCustomerSurveys[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.surveyparticipants_820232_total = ReactSystemFunctions.value(
      this,
      this.state.sqlGetSurveyCount?.length > 0 ? this.state.sqlGetSurveyCount[0]?.surveyCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SurveyParticipantsComponent_98855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SurveyParticipants",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "467762",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SurveyParticipantsComponent_468391_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SurveyParticipants",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "171382",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SurveyParticipantsComponent_274279_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SurveyParticipantsComponent_187658_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "surveyparticipants_820232_value", 1, "current");

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveyParticipantsComponent_187658_onPressEnter1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SurveyParticipantsComponent_187658_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_820232_value", "current", "", "", "")
        ),
        "number"
      ),
      pageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_820232_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchValue_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_187658_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SurveyParticipants/SurveyParticipantsComponent_187658_onPressEnter1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCustomerSurveys = result?.data.selectCustomerSurveys;

    stateVars.isComp152064Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectCustomerSurveys?.length > 0 ? stateVars.SelectCustomerSurveys[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveyParticipantsComponent_187658_onPressEnter2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SurveyParticipantsComponent_187658_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_828121 = this.state.SelectCustomerSurveys;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SurveyParticipantsComponent_187658_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "surveyparticipants_820232_value", 1, "current");

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveyParticipantsComponent_187658_onBlur1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SurveyParticipantsComponent_187658_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_820232_value", "current", "", "", "")
        ),
        "number"
      ),
      pageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_820232_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchValue_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_187658_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SurveyParticipants/SurveyParticipantsComponent_187658_onBlur1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCustomerSurveys = result?.data.selectCustomerSurveys;

    stateVars.isComp152064Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectCustomerSurveys?.length > 0 ? stateVars.SelectCustomerSurveys[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveyParticipantsComponent_187658_onBlur2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SurveyParticipantsComponent_187658_onBlur2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_828121 = this.state.SelectCustomerSurveys;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SurveyParticipantsComponent_774325_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchValue_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_187658_value", "value", "", "", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName(
        "Şimdiye kadar doldurulan anketlerin cevap ortalaması",
        "string"
      ),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SurveyParticipants/SurveyParticipantsComponent_774325_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetSurveyReport = result?.data.spGetSurveyReport;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SurveyParticipantsComponent_515578_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchValue_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_187658_value", "value", "", "", "")
        ),
        "string"
      ),
      header_1: ReactSystemFunctions.convertToTypeByName("CFN Kimya Survey Report", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("CFN Kimya Survey Report", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SurveyParticipants/SurveyParticipantsComponent_515578_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetUserSurveyAnswersReport = result?.data.spGetUserSurveyAnswersReport;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SurveyParticipantsComponent_610201_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "surveyparticipants_828121_value", "status"),
        "20"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "SurveyDetails",
        "surveyId",
        ReactSystemFunctions.value(this, "surveyparticipants_828121_value", "id")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "SurveyParticipants",
        "SurveyDetails",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "706927",
        null,
        "right",
        null,
        "380px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SurveyParticipantsComponent_205645_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SurveyDetails",
      "surveyId",
      ReactSystemFunctions.value(this, "surveyparticipants_828121_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SurveyParticipants",
      "SurveyDetails",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "771360",
      null,
      "right",
      null,
      "60%",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SurveyParticipantsComponent_820232_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_820232_value", "current", "", "", "")
        ),
        "number"
      ),
      pageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_820232_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchValue_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "surveyparticipants_187658_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SurveyParticipants/SurveyParticipantsComponent_820232_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCustomerSurveys = result?.data.selectCustomerSurveys;

    stateVars.isComp152064Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectCustomerSurveys?.length > 0 ? stateVars.SelectCustomerSurveys[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveyParticipantsComponent_820232_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SurveyParticipantsComponent_820232_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_828121 = this.state.SelectCustomerSurveys;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [467762, 171382, 706927] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SurveyParticipantsPageInit();
    }
  }
}
