import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRequestedVehicleView_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRequestedVehicleView_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetOrderList: any[];
  sqlGetVehicleRequestById: any[];
  SqlGetCustomerDetailsByCardNo: any[];
  sqlGetCompanyById: any[];
  spGetUserById: any[];
  GoBack: any;
  GetLanguage: string;
  GetUserCurrentId: any[];
  sqlApproveVehicleRequest: number;
  TriggerEvent: string;
  NAVIGATE: any;
  isComp430740Visible: "visible" | "hidden";
  isComp476567Visible: "visible" | "hidden";
  isComp121390Visible: "visible" | "hidden";
  isComp20374Visible: "visible" | "hidden";
  isComp95805Visible: "visible" | "hidden";
  isComp866109Visible: "visible" | "hidden";
  isComp619512Visible: "visible" | "hidden";
  isComp300692Visible: "visible" | "hidden";
  isComp947900Visible: "visible" | "hidden";
  isComp437226Visible: "visible" | "hidden";
  isComp515901Visible: "visible" | "hidden";
  isComprequestedvehicleview_86876AuthorizationVisible: "visible" | "hidden";
  isComprequestedvehicleview_121390AuthorizationVisible: "visible" | "hidden";
  isComprequestedvehicleview_804283AuthorizationVisible: "visible" | "hidden";
  isComprequestedvehicleview_174932AuthorizationVisible: "visible" | "hidden";
}

export class RequestedVehicleView_ScreenBase extends React.PureComponent<IRequestedVehicleView_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 521089, PropertyName: "value", Value: "Araç Atama Bilgileri", TagName: "Label10_value" },
        { Id: 716243, PropertyName: "value", Value: "Müşteri ", TagName: "Label0_0_value" },
        { Id: 236000, PropertyName: "value", Value: "Teslimat Adresi", TagName: "Label0_0_value" },
        { Id: 130612, PropertyName: "value", Value: "Yükleme Adresi", TagName: "Label0_0_value" },
        { Id: 323324, PropertyName: "value", Value: "Yükleme Tarihi", TagName: "Label0_0_value" },
        { Id: 557055, PropertyName: "value", Value: "Müşteri Referans No", TagName: "Label0_0_value" },
        { Id: 540638, PropertyName: "value", Value: "Sipariş No", TagName: "Label0_0_value" },
        { Id: 84510, PropertyName: "value", Value: "Sipariş Tarihi", TagName: "Label0_0_value" },
        { Id: 596312, PropertyName: "value", Value: "Navlun", TagName: "Label1_value" },
        { Id: 894397, PropertyName: "value", Value: "Çekici Plakası ", TagName: "Label0_0_value" },
        { Id: 502158, PropertyName: "value", Value: "Dorse Plakası / Konteyner Numarası", TagName: "Label0_0_value" },
        { Id: 188066, PropertyName: "value", Value: "Sürücü Bilgileri", TagName: "Label0_0_value" },
        { Id: 805517, PropertyName: "value", Value: "İsim", TagName: "Label0_0_value" },
        { Id: 732071, PropertyName: "value", Value: "Soyisim", TagName: "Label0_0_value" },
        { Id: 181846, PropertyName: "value", Value: "Alan Kodu", TagName: "Label0_0_value" },
        { Id: 968394, PropertyName: "value", Value: "Telefon", TagName: "Label0_0_value" },
        { Id: 841376, PropertyName: "value", Value: "Fatura Numarası", TagName: "Label0_0_value" },
        { Id: 869220, PropertyName: "value", Value: "Tahmini Teslimat Tarihi", TagName: "Label0_0_value" },
        { Id: 613201, PropertyName: "value", Value: "Dorse Plakası / Konteyner Numarası", TagName: "Label0_0_value" },
        { Id: 856687, PropertyName: "value", Value: "Fatura", TagName: "Label0_0_value" },
        { Id: 437226, PropertyName: "value", Value: "Fatura Henüz Yüklenmedi!", TagName: "Label0_1_value" },
        { Id: 515901, PropertyName: "value", Value: "Faturayı Görüntüle", TagName: "Label0_2_value" },
        { Id: 121390, PropertyName: "label", Value: "Onayla", TagName: "Button1_label" },
        { Id: 20374, PropertyName: "value", Value: "Araç Ataması Bekleniyor", TagName: "Label0_0_value" },
        { Id: 691046, PropertyName: "value", Value: "Onaylayan", TagName: "Label0_0_value" },
        { Id: 721773, PropertyName: "value", Value: "Onay Tarihi", TagName: "Label0_0_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "9567d837-a872-484c-baf4-57e1ec7c2fc8",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 430740, PropertyName: "label", Value: "pageinittrigger" },
        { Id: 716243, PropertyName: "value", Value: "Customer" },
        { Id: 557055, PropertyName: "value", Value: "Customer Ref No" },
        { Id: 540638, PropertyName: "value", Value: "Order Number" },
        { Id: 84510, PropertyName: "value", Value: "Order Date" },
        { Id: 236000, PropertyName: "value", Value: "Shipping Address" },
        { Id: 130612, PropertyName: "value", Value: "Loading Address" },
        { Id: 323324, PropertyName: "value", Value: "Loading Date" },
        { Id: 596312, PropertyName: "value", Value: "Freight" },
        { Id: 521089, PropertyName: "value", Value: "Assignment Information" },
        { Id: 121390, PropertyName: "label", Value: "Approve" },
        { Id: 20374, PropertyName: "value", Value: "Waiting for Vehicle Assignment" },
        { Id: 691046, PropertyName: "value", Value: "Approved By" },
        { Id: 721773, PropertyName: "value", Value: "Approval Date" },
        { Id: 613201, PropertyName: "value", Value: "Truck/Container Information" },
        { Id: 894397, PropertyName: "value", Value: "Truck Plate Number" },
        { Id: 502158, PropertyName: "value", Value: "Trailer Plate Number / Container Number" },
        { Id: 188066, PropertyName: "value", Value: "Driver Information" },
        { Id: 805517, PropertyName: "value", Value: "Name" },
        { Id: 732071, PropertyName: "value", Value: "Surname" },
        { Id: 181846, PropertyName: "value", Value: "Prefix" },
        { Id: 968394, PropertyName: "value", Value: "Phone" },
        { Id: 841376, PropertyName: "value", Value: "Invoice Number" },
        { Id: 869220, PropertyName: "value", Value: "Estimated Delivery Date" },
        { Id: 856687, PropertyName: "value", Value: "Invoice" },
        { Id: 437226, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 515901, PropertyName: "value", Value: "View document" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetOrderList: [],
      sqlGetVehicleRequestById: [],
      SqlGetCustomerDetailsByCardNo: [],
      sqlGetCompanyById: [],
      spGetUserById: [],
      GoBack: "",
      GetLanguage: "",
      GetUserCurrentId: [],
      sqlApproveVehicleRequest: 0,
      TriggerEvent: "",
      NAVIGATE: "",
      isComp430740Visible: "hidden",
      isComp476567Visible: "hidden",
      isComp121390Visible: "hidden",
      isComp20374Visible: "hidden",
      isComp95805Visible: "hidden",
      isComp866109Visible: "hidden",
      isComp619512Visible: "hidden",
      isComp300692Visible: "hidden",
      isComp947900Visible: "hidden",
      isComp437226Visible: "hidden",
      isComp515901Visible: "hidden",
      isComprequestedvehicleview_86876AuthorizationVisible: "visible",
      isComprequestedvehicleview_121390AuthorizationVisible: "visible",
      isComprequestedvehicleview_804283AuthorizationVisible: "visible",
      isComprequestedvehicleview_174932AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("requestedvehicleview", "requestedvehicleview");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.RequestedVehicleViewPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComprequestedvehicleview_86876AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Logistic",
      "LogisticSupplier",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComprequestedvehicleview_121390AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComprequestedvehicleview_804283AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComprequestedvehicleview_174932AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Logistic",
      "LogisticSupplier",
      "Finance"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("requestedvehicleview", "requestedvehicleview");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("requestedvehicleview", "requestedvehicleview");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.RequestedVehicleViewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  RequestedVehicleViewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestedVehicleViewPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RequestedVehicleViewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      OrderNumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardCode ?? this.props.screenInputs.cardcode,
        "string"
      ),
      CustomerCardName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentNo_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardCode ?? this.props.screenInputs.cardcode,
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RequestedVehicleView/RequestedVehicleViewPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetOrderList = result?.data.getOrderList;
    formVars.requestedvehicleview_9756_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardname : null
    );
    formVars.requestedvehicleview_788161_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.custrefno : null
    );
    formVars.requestedvehicleview_85377_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    formVars.requestedvehicleview_625727_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.shiptoaddressmultiline : null
    );
    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.requestedvehicleview_56735_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );
    formVars.requestedvehicleview_207811_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
    );
    formVars.requestedvehicleview_34974_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
    );
    formVars.requestedvehicleview_198738_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
    );
    formVars.requestedvehicleview_706998_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approvedAt : null
    );
    formVars.requestedvehicleview_514067_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );
    formVars.requestedvehicleview_973660_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber : null
    );
    formVars.requestedvehicleview_120957_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverName : null
    );
    formVars.requestedvehicleview_559217_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverSurname : null
    );
    formVars.requestedvehicleview_970813_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );
    formVars.requestedvehicleview_382442_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );
    formVars.requestedvehicleview_505876_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );
    formVars.requestedvehicleview_692177_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );
    stateVars.isComp121390Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp20374Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp95805Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866109Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp619512Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp300692Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp947900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp437226Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp515901Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SqlGetCustomerDetailsByCardNo = result?.data.sqlGetCustomerDetailsByCardNo;
    stateVars.sqlGetCompanyById = result?.data.sqlGetCompanyById;
    formVars.requestedvehicleview_633588_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetCompanyById?.length > 0 ? stateVars.sqlGetCompanyById[0]?.cardName : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestedVehicleViewPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestedVehicleViewPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      lang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approvedBy : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "RequestedVehicleView/RequestedVehicleViewPageInit2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spGetUserById = result?.data.spGetUserById;
      formVars.requestedvehicleview_191761_value = ReactSystemFunctions.toString(
        this,
        stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.nameSurname : null
      );
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.RequestedVehicleViewPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.RequestedVehicleViewPageInit3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestedVehicleViewPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.requestedvehicleview_9756_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
    );

    formVars.requestedvehicleview_788161_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
    );

    formVars.requestedvehicleview_85377_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    formVars.requestedvehicleview_625727_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.shiptoaddressmultiline : null
    );

    formVars.requestedvehicleview_56735_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );

    formVars.requestedvehicleview_207811_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
    );

    formVars.requestedvehicleview_34974_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
    );

    formVars.requestedvehicleview_198738_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
    );

    formVars.requestedvehicleview_633588_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetCompanyById?.length > 0 ? this.state.sqlGetCompanyById[0]?.cardName : null
    );

    stateVars.isComp121390Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp20374Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp95805Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestedVehicleViewPageInit4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RequestedVehicleViewPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      )
    ) {
      formVars.requestedvehicleview_191761_value = ReactSystemFunctions.toString(
        this,
        this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.nameSurname : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.RequestedVehicleViewPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.RequestedVehicleViewPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  RequestedVehicleViewPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.requestedvehicleview_706998_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approvedAt : null
    );

    stateVars.isComp866109Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    formVars.requestedvehicleview_514067_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );

    formVars.requestedvehicleview_973660_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
        : null
    );

    formVars.requestedvehicleview_120957_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverName : null
    );

    formVars.requestedvehicleview_559217_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverSurname : null
    );

    formVars.requestedvehicleview_970813_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );

    formVars.requestedvehicleview_382442_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );

    stateVars.isComp619512Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    formVars.requestedvehicleview_505876_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );

    formVars.requestedvehicleview_692177_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );

    stateVars.isComp300692Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp947900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp437226Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp515901Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RequestedVehicleViewComponent_430740_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrderNumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardCode ?? this.props.screenInputs.cardcode,
        "string"
      ),
      CustomerCardName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentNo_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardCode ?? this.props.screenInputs.cardcode,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RequestedVehicleView/RequestedVehicleViewComponent_430740_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetOrderList = result?.data.getOrderList;
    formVars.requestedvehicleview_9756_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardname : null
    );
    formVars.requestedvehicleview_788161_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.custrefno : null
    );
    formVars.requestedvehicleview_85377_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    formVars.requestedvehicleview_625727_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.shiptoaddressmultiline : null
    );
    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.requestedvehicleview_56735_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );
    formVars.requestedvehicleview_207811_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
    );
    formVars.requestedvehicleview_34974_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
    );
    formVars.requestedvehicleview_198738_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
    );
    formVars.requestedvehicleview_706998_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approvedAt : null
    );
    formVars.requestedvehicleview_514067_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );
    formVars.requestedvehicleview_973660_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber : null
    );
    formVars.requestedvehicleview_120957_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverName : null
    );
    formVars.requestedvehicleview_559217_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverSurname : null
    );
    formVars.requestedvehicleview_970813_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );
    formVars.requestedvehicleview_382442_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );
    formVars.requestedvehicleview_505876_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );
    formVars.requestedvehicleview_692177_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );
    stateVars.isComp121390Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp20374Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp95805Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866109Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp619512Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp300692Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp947900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp437226Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp515901Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SqlGetCustomerDetailsByCardNo = result?.data.sqlGetCustomerDetailsByCardNo;
    stateVars.sqlGetCompanyById = result?.data.sqlGetCompanyById;
    formVars.requestedvehicleview_633588_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetCompanyById?.length > 0 ? stateVars.sqlGetCompanyById[0]?.cardName : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestedVehicleViewComponent_430740_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestedVehicleViewComponent_430740_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      lang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approvedBy : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "RequestedVehicleView/RequestedVehicleViewComponent_430740_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spGetUserById = result?.data.spGetUserById;
      formVars.requestedvehicleview_191761_value = ReactSystemFunctions.toString(
        this,
        stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.nameSurname : null
      );
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.RequestedVehicleViewComponent_430740_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.RequestedVehicleViewComponent_430740_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestedVehicleViewComponent_430740_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.requestedvehicleview_9756_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
    );

    formVars.requestedvehicleview_788161_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
    );

    formVars.requestedvehicleview_85377_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    formVars.requestedvehicleview_625727_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.shiptoaddressmultiline : null
    );

    formVars.requestedvehicleview_56735_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );

    formVars.requestedvehicleview_207811_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
    );

    formVars.requestedvehicleview_34974_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
    );

    formVars.requestedvehicleview_198738_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
    );

    formVars.requestedvehicleview_633588_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetCompanyById?.length > 0 ? this.state.sqlGetCompanyById[0]?.cardName : null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestedVehicleViewComponent_430740_onClick3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RequestedVehicleViewComponent_430740_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      )
    ) {
      formVars.requestedvehicleview_191761_value = ReactSystemFunctions.toString(
        this,
        this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.nameSurname : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.RequestedVehicleViewComponent_430740_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.RequestedVehicleViewComponent_430740_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  RequestedVehicleViewComponent_430740_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.requestedvehicleview_706998_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approvedAt : null
    );

    formVars.requestedvehicleview_514067_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );

    formVars.requestedvehicleview_973660_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
        : null
    );

    formVars.requestedvehicleview_120957_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverName : null
    );

    formVars.requestedvehicleview_559217_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverSurname : null
    );

    formVars.requestedvehicleview_970813_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );

    formVars.requestedvehicleview_382442_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );

    formVars.requestedvehicleview_505876_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );

    formVars.requestedvehicleview_692177_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RequestedVehicleViewComponent_621706_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RequestedVehicleViewComponent_121390_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RequestedVehicleView/RequestedVehicleViewComponent_121390_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestedVehicleViewComponent_121390_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestedVehicleViewComponent_121390_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      approvedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      approvedBy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "Guid"
      )
    };

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "caa5c9f8_3250_ce15_6b48_6ca9fd5985cf_confirmation",
        this.defaultML,
        "Atanan araç bilgilerini onaylamak istiyor musunuz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "RequestedVehicleView/RequestedVehicleViewComponent_121390_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.sqlApproveVehicleRequest = result?.data.sqlApproveVehicleRequest;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.RequestedVehicleViewComponent_121390_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestedVehicleViewComponent_121390_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.RequestedVehicleViewComponent_430740_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RequestedVehicleViewComponent_515901_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewInvoice",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "RequestedVehicleView",
      "PreviewInvoice",
      "",
      "newTab",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
