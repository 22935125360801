import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUserEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUserEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  sql_GetUserTypes: any[];
  sql_GetCompanies: any[];
  spGetUserById: any[];
  getKuserByEmail: any[];
  GetRolesSelectbox: any[];
  sql_GetUserRoles: any[];
  SetValueOf: any;
  PhotoFromGallery: string;
  IfThenElse: boolean;
  spGetCustomersId: any[];
  SaveAppUsers: any[];
  UpdateKUserEmail: any[];
  RemoveAllRoles: any;
  AddUserToRole: boolean;
  updateUserLanguage: any[];
  Notify: boolean;
  CheckUserExists: any[];
  CheckAppUserExists: any[];
  TriggerEvent: string;
  eightCompValidation: string;
  CreateUser: string;
  tenCompValidation: string;
  isComp322498Visible: "visible" | "hidden";
  isComp245412Visible: "visible" | "hidden";
  isComp882215Visible: "visible" | "hidden";
  isComp132321Visible: "visible" | "hidden";
  isCompuseredit_42131AuthorizationVisible: "visible" | "hidden";
}

export class UserEdit_ScreenBase extends React.PureComponent<IUserEdit_ScreenProps, any> {
  useredit_171122_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 861512, PropertyName: "value", Value: "İsim", TagName: "Label4_value" },
        { Id: 963625, PropertyName: "value", Value: "Soyisim", TagName: "Label10_0_value" },
        { Id: 700365, PropertyName: "value", Value: "Alan Kodu", TagName: "Label10_0_value" },
        { Id: 372226, PropertyName: "value", Value: "Telefon Numarası", TagName: "Label10_0_value" },
        { Id: 69046, PropertyName: "value", Value: "Rol", TagName: "Label11_0_value" },
        { Id: 668005, PropertyName: "value", Value: "Adres", TagName: "Label15_0_value" },
        { Id: 806039, PropertyName: "value", Value: "Ülke", TagName: "Label12_value" },
        { Id: 344639, PropertyName: "value", Value: "Şehir", TagName: "Label14_value" },
        { Id: 34680, PropertyName: "value", Value: "Posta Kodu", TagName: "Label11_0_value" },
        { Id: 42131, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 245412, PropertyName: "value", Value: "Yeni Kullanıcı", TagName: "Label1_value" },
        { Id: 322498, PropertyName: "value", Value: "Kullanıcı Düzenle", TagName: "Label13_value" },
        { Id: 590006, PropertyName: "value", Value: "Eposta", TagName: "Label8_value" },
        { Id: 163954, PropertyName: "value", Value: "Dil Tercihi", TagName: "Label0_0_value" },
        { Id: 629530, PropertyName: "value", Value: "Türkçe", TagName: "Label15_value" },
        { Id: 827330, PropertyName: "value", Value: "İngilizce", TagName: "Label0_1_value" },
        { Id: 171122, PropertyName: "placeholder", Value: "Seçiniz...", TagName: "roleSelectbox_placeholder" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "a33ec7b1-70cd-4b19-bc76-8356b1d8aaad",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 322498, PropertyName: "value", Value: "User Edit" },
        { Id: 245412, PropertyName: "value", Value: "Create User" },
        { Id: 861512, PropertyName: "value", Value: "Name" },
        { Id: 963625, PropertyName: "value", Value: "Surname" },
        { Id: 590006, PropertyName: "value", Value: "E-mail" },
        { Id: 69046, PropertyName: "value", Value: "Role" },
        { Id: 171122, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 163954, PropertyName: "value", Value: "Preferred Language" },
        { Id: 700365, PropertyName: "value", Value: "Prefix" },
        { Id: 929090, PropertyName: "placeholder", Value: "+90" },
        { Id: 372226, PropertyName: "value", Value: "Phone Number" },
        { Id: 668005, PropertyName: "value", Value: "Address" },
        { Id: 34680, PropertyName: "value", Value: "Postal Code" },
        { Id: 344639, PropertyName: "value", Value: "City" },
        { Id: 806039, PropertyName: "value", Value: "Country" },
        { Id: 132321, PropertyName: "label", Value: "!- Save User -!" },
        { Id: 42131, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.useredit_171122_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      sql_GetUserTypes: [],
      sql_GetCompanies: [],
      spGetUserById: [],
      getKuserByEmail: [],
      GetRolesSelectbox: [],
      sql_GetUserRoles: [],
      SetValueOf: "",
      PhotoFromGallery: "",
      IfThenElse: false,
      spGetCustomersId: [],
      SaveAppUsers: [],
      UpdateKUserEmail: [],
      RemoveAllRoles: "",
      AddUserToRole: false,
      updateUserLanguage: [],
      Notify: false,
      CheckUserExists: [],
      CheckAppUserExists: [],
      isComp322498Visible: "hidden",
      isComp245412Visible: "hidden",
      isComp882215Visible: "hidden",
      isComp132321Visible: "hidden",
      isCompuseredit_42131AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("useredit", "useredit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UserEditPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompuseredit_42131AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("useredit", "useredit");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("useredit", "useredit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UserEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      useredit_342604_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined,
      useredit_274541_value: this.state.spGetUserById?.at?.(0)?.name ?? undefined,
      useredit_131060_value: this.state.spGetUserById?.at?.(0)?.surname ?? undefined,
      useredit_737823_value: this.state.spGetUserById?.at?.(0)?.email ?? undefined,
      useredit_171122_value: this.state.sql_GetUserRoles?.at?.(0)?.allassigndeRoles ?? undefined,
      useredit_929090_value: this.state.spGetUserById?.at?.(0)?.phoneprefix ?? undefined,
      useredit_728237_value: this.state.spGetUserById?.at?.(0)?.phoneNumber ?? undefined,
      useredit_496957_value: this.state.spGetUserById?.at?.(0)?.address ?? undefined,
      useredit_705575_value: this.state.spGetUserById?.at?.(0)?.postalCode ?? undefined,
      useredit_976115_value: this.state.spGetUserById?.at?.(0)?.addressCity ?? undefined,
      useredit_512603_value: this.state.spGetUserById?.at?.(0)?.addressCountry ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UserEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserEditPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UserEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userById ?? this.props.screenInputs.userbyid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserEdit/UserEditPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sql_GetUserTypes = result?.data.sql_GetUserTypes;
    stateVars.sql_GetCompanies = result?.data.sql_GetCompanies;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.useredit_342604_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    formVars.useredit_274541_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.name : null
    );
    formVars.useredit_131060_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.surname : null
    );
    formVars.useredit_737823_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.email : null
    );
    formVars.useredit_929090_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.phoneprefix : null
    );
    formVars.useredit_728237_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.phoneNumber : null
    );
    formVars.useredit_496957_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.address : null
    );
    formVars.useredit_705575_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.postalCode : null
    );
    formVars.useredit_976115_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.addressCity : null
    );
    formVars.useredit_512603_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.addressCountry : null
    );
    stateVars.isComp322498Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp245412Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getKuserByEmail = result?.data.getKuserByEmail;
    stateVars.GetRolesSelectbox = result?.data.getRolesSelectbox;

    formVars.useredit_171122_value =
      stateVars.sql_GetUserRoles?.length > 0 ? stateVars.sql_GetUserRoles[0]?.allassigndeRoles : null;
    formVars.useredit_171122_options = stateVars.GetRolesSelectbox;
    stateVars.sql_GetUserRoles = result?.data.sql_GetUserRoles;
    formVars.useredit_171122_value =
      stateVars.sql_GetUserRoles?.length > 0 ? stateVars.sql_GetUserRoles[0]?.allassigndeRoles : null;
    formVars.useredit_171122_options = stateVars.GetRolesSelectbox;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserEditPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserEditPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.getKuserByEmail?.length > 0 ? this.state.getKuserByEmail[0]?.id : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "useredit_554491_value",
        ReactSystemFunctions.toString(
          this,
          this.state.getKuserByEmail?.length > 0 ? this.state.getKuserByEmail[0]?.language : null
        ),
        null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.UserEditPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.UserEditPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UserEditPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp322498Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp245412Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.useredit_342604_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    formVars.useredit_274541_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.name : null
    );

    formVars.useredit_131060_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.surname : null
    );

    formVars.useredit_737823_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.email : null
    );

    stateVars.isComp882215Visible =
      ReactSystemFunctions.isEmpty(this.props.screenInputs.role ?? this.props.screenInputs.role, null) === true
        ? "visible"
        : "hidden";
    formVars.useredit_171122_value = ReactSystemFunctions.toString(
      this,
      this.state.sql_GetUserRoles?.length > 0 ? this.state.sql_GetUserRoles[0]?.allassigndeRoles : null
    );

    stateVars.dataSource_171122 = this.state.GetRolesSelectbox;
    formVars.useredit_929090_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.phoneprefix : null
    );

    formVars.useredit_728237_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.phoneNumber : null
    );

    formVars.useredit_496957_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.address : null
    );

    formVars.useredit_705575_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.postalCode : null
    );

    formVars.useredit_976115_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.addressCity : null
    );

    formVars.useredit_512603_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.addressCountry : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UserEditComponent_462237_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserEditComponent_2994_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserEditComponent_2994_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UserEditComponent_2994_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "useredit_342604_value",
      ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  UserEditComponent_2994_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      isErrorOccurred = await this.UserEditComponent_2994_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserEditComponent_132321_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_274541_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_274541_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_728237_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_728237_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_929090_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_929090_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_131060_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_131060_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      screenUserId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userById ?? this.props.screenInputs.userbyid,
        "Guid"
      ),
      address_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_496957_value", "value", "", "", "")
        ),
        "string"
      ),
      addressCity_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_976115_value", "value", "", "", "")
        ),
        "string"
      ),
      addressCountry_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_512603_value", "value", "", "", "")
        ),
        "string"
      ),
      Company_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      companyID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      createdAt_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      createdBy_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      isDeleted_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.isDeleted : null
        ),
        "boolean"
      ),
      name_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_274541_value", "value", "", "", "")
        ),
        "string"
      ),
      phoneNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_728237_value", "value", "", "", "")
        ),
        "string"
      ),
      phonePrefix_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "useredit_929090_value", "value", "", "", "")
        ),
        "string"
      ),
      postalCode_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_705575_value", "value", "", "", "")
        ),
        "string"
      ),
      profileImage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_342604_value", "value", "", "", "")
        ),
        "string"
      ),
      status_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      surname_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_131060_value", "value", "", "", "")
        ),
        "string"
      ),
      userType_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userType ?? this.props.screenInputs.usertype,
        "number"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.kUserId : null
        ),
        "Guid"
      ),
      name_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_274541_value", "value", "", "", "")
        ),
        "string"
      ),
      surname_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_131060_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserEdit/UserEditComponent_132321_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetCustomersId = result?.data.spGetCustomersId;
    stateVars.SaveAppUsers = result?.data.saveAppUsers;
    stateVars.UpdateKUserEmail = result?.data.updateKUserEmail;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserEditComponent_132321_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserEditComponent_132321_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      rolesJson_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sql_GetUserRoles?.length > 0 ? this.state.sql_GetUserRoles[0]?.allassigndeRoles : null
        ),
        "string"
      ),
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.email : null
        ),
        "string"
      )
    };

    if (ReactSystemFunctions.isEmpty(this.props.screenInputs.role ?? this.props.screenInputs.role, null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UserEdit/UserEditComponent_132321_onClick1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.UserEditComponent_132321_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.UserEditComponent_132321_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserEditComponent_132321_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEmpty(this.props.screenInputs.role ?? this.props.screenInputs.role, null)) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_171122_value", "value", "GetRolesSelectbox", "name", "name")
        ),
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.UserEditComponent_132321_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.UserEditComponent_132321_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UserEditComponent_132321_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.role ?? this.props.screenInputs.role, null)) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.UserEditComponent_132321_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.UserEditComponent_132321_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UserEditComponent_132321_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      Userlang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "useredit_554491_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserEdit/UserEditComponent_132321_onClick4_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.updateUserLanguage = result?.data.updateUserLanguage;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserEditComponent_132321_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserEditComponent_132321_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "881d98d6_116b_0fd3_a4dc_7d85fecd27b5_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserEditComponent_42131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserEdit/UserEditComponent_42131_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckUserExists = result?.data.checkUserExists;
    stateVars.CheckAppUserExists = result?.data.checkAppUserExists;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserEditComponent_42131_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserEditComponent_42131_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserEditComponent_42131_onClick_if11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UserEditComponent_42131_onClick_if1f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserEditComponent_42131_onClick_if1f11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UserEditComponent_42131_onClick_if1f1f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.UserEditComponent_132321_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  UserEditComponent_42131_onClick_if1f1e1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "32281f59_49c5_c93c_bfac_5eae26401de0_notify",
        this.defaultML,
        "Bu mail adresi kullanımda!"
      ),
      "danger",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  UserEditComponent_42131_onClick_if1f11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.CheckAppUserExists?.length > 0 ? this.state.CheckAppUserExists[0]?.userCount : null
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.UserEditComponent_42131_onClick_if1f1f1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.UserEditComponent_42131_onClick_if1f1e1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  UserEditComponent_42131_onClick_if1e1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserEditComponent_42131_onClick_if1e11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UserEditComponent_42131_onClick_if1e1f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.UserEditComponent_132321_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  UserEditComponent_42131_onClick_if1e1e1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "32281f59_49c5_c93c_bfac_5eae26401de0_notify",
        this.defaultML,
        "Bu mail adresi kullanımda!"
      ),
      "danger",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  UserEditComponent_42131_onClick_if1e11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.CheckAppUserExists?.length > 0 ? this.state.CheckAppUserExists[0]?.userCount : null
        ),
        "0"
      )
    ) {
      isErrorOccurred = await this.UserEditComponent_42131_onClick_if1e1f1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.UserEditComponent_42131_onClick_if1e1e1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  UserEditComponent_42131_onClick_if11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.email : null
        ),
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        )
      )
    ) {
      isErrorOccurred = await this.UserEditComponent_42131_onClick_if1f1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.UserEditComponent_42131_onClick_if1e1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  UserEditComponent_42131_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_274541_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_274541_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_131060_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_131060_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_929090_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_929090_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_728237_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_728237_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_274541_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_131060_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_171122_value", "value", "GetRolesSelectbox", "name", "id")
        ),
        "string"
      ),
      parameter5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_929090_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_728237_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter7_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      parameter8_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserEdit/UserEditComponent_42131_onClick_else1", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.eightCompValidation = result?.data.eightCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserEditComponent_42131_onClick_else11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserEditComponent_42131_onClick_else11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.CheckUserExists?.length > 0 ? this.state.CheckUserExists[0]?.userCount : null
        ),
        "0"
      )
    ) {
      stateVars.CreateUser = await ReactSystemFunctions.createUser(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_274541_value", "value", "", "", "")
        ),
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_131060_value", "value", "", "", "")
        ),
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.UserEditComponent_42131_onClick_else12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.UserEditComponent_42131_onClick_else12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UserEditComponent_42131_onClick_else1f2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "useredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_274541_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_274541_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_728237_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_728237_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_929090_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_929090_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_131060_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "useredit_131060_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_496957_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_976115_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_512603_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_274541_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_728237_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_929090_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_705575_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter9_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "useredit_131060_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter10_0: ReactSystemFunctions.convertToTypeByName(" ", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserEdit/UserEditComponent_42131_onClick_else1f2", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.tenCompValidation = result?.data.tenCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserEditComponent_42131_onClick_else1f21_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserEditComponent_42131_onClick_else1f21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.UserEditComponent_132321_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  UserEditComponent_42131_onClick_else1e2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "32281f59_49c5_c93c_bfac_5eae26401de0_notify",
        this.defaultML,
        "Bu mail adresi kullanımda!"
      ),
      "danger",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  UserEditComponent_42131_onClick_else12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.CheckAppUserExists?.length > 0 ? this.state.CheckAppUserExists[0]?.userCount : null
        ),
        "0"
      )
    ) {
      isErrorOccurred = await this.UserEditComponent_42131_onClick_else1f2();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.UserEditComponent_42131_onClick_else1e2();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  UserEditComponent_42131_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.id : null),
        null
      )
    ) {
      isErrorOccurred = await this.UserEditComponent_42131_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.UserEditComponent_42131_onClick_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
