import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDeleteMyAccount_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDeleteMyAccount_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetLanguage: string;
  languageLabelResult: string;
  NAVIGATE: any;
  Signin: any;
  isLoggedIn: boolean;
  DeleteMyAccount: any;
  Notify: boolean;
  IfThenElse: boolean;
  goToNextScreenAfterLogin: any;
  Logout: boolean;
  goToSignInActivity: any;
  isComp502500Visible: "visible" | "hidden";
  isComp200367Visible: "visible" | "hidden";
}

export class DeleteMyAccount_ScreenBase extends React.PureComponent<IDeleteMyAccount_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 924163, PropertyName: "value", Value: "Kullanıcı Hesap Silme", TagName: "Label5_value" },
        {
          Id: 325786,
          PropertyName: "value",
          Value: "CFN Kimya Mobil uygulama kullanıcı hesap silme ekranı",
          TagName: "Label6_value"
        },
        { Id: 15838, PropertyName: "value", Value: "Eposta", TagName: "Label6_value" },
        { Id: 961136, PropertyName: "value", Value: "Şifre", TagName: "Label7_value" },
        { Id: 680779, PropertyName: "value", Value: "Şifremi Unuttum", TagName: "Label5_value" },
        { Id: 49298, PropertyName: "label", Value: "Hesabımı sil", TagName: "Button1_label" },
        { Id: 200367, PropertyName: "value", Value: "Çıkış yap", TagName: "Label3_value" },
        { Id: 148830, PropertyName: "value", Value: "Lütfen hesap bilgilerinizi giriniz", TagName: "Label8_value" },
        {
          Id: 30371253,
          PropertyName: "1732f607-9bb1-8fff-e6d3-ea6a6c9dd929_confirmation",
          Value: "Hesabınızı silmek istiyor musunuz?",
          TagName: "isLoggedIn_confirmation",
          IsConfirmation: true
        },
        {
          Id: 63358441,
          PropertyName: "3688433c-7660-ca7f-0338-5ee0592dba0d_notify",
          Value: "Hesabınız başarıyla silindi.",
          TagName: "Notify_notify",
          IsNotify: true
        }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "bd1ce858-06a4-450c-9ddf-b7cfebcec78e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 924163, PropertyName: "value", Value: "User Account Deletion" },
        { Id: 325786, PropertyName: "value", Value: "CFN Kimya Mobile Application's user account deletion screen" },
        { Id: 148830, PropertyName: "value", Value: "Please enter your account information" },
        { Id: 15838, PropertyName: "value", Value: "E-mail" },
        { Id: 961136, PropertyName: "value", Value: "Password" },
        { Id: 680779, PropertyName: "value", Value: "Forgot Password" },
        { Id: 49298, PropertyName: "label", Value: "Delete my Account" },
        { Id: 200367, PropertyName: "value", Value: "Sign out" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetLanguage: "",
      languageLabelResult: "",
      NAVIGATE: "",
      Signin: "",
      isLoggedIn: false,
      IfThenElse: false,
      goToNextScreenAfterLogin: "",
      Logout: false,
      goToSignInActivity: "",
      isComp502500Visible: "hidden",
      isComp200367Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("deletemyaccount", "deletemyaccount");
      return;
    }

    if (true) {
      await this.DeleteMyAccountPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("deletemyaccount", "deletemyaccount");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("deletemyaccount", "deletemyaccount");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DeleteMyAccountPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DeleteMyAccountPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DeleteMyAccountPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DeleteMyAccountPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DeleteMyAccount/DeleteMyAccountPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.languageLabelResult = result?.data.languageLabelResult;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  DeleteMyAccountComponent_680779_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DeleteMyAccount",
      "ForgotPassword",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DeleteMyAccountComponent_49298_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "deletemyaccount_721944_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "deletemyaccount_721944_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "deletemyaccount_721944_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "deletemyaccount_721944_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "deletemyaccount_861960_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "deletemyaccount_861960_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "72384f9f_379a_12da_779a_8d23fabc514b_confirmation",
        this.defaultML,
        "Do you want to delete your account?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.Signin = (
        (await ReactSystemFunctions.signin(
          this,
          ReactSystemFunctions.value(
            this,
            ReactSystemFunctions.value(this, "deletemyaccount_721944_value", "value", "", "", "")
          ),
          ReactSystemFunctions.value(
            this,
            ReactSystemFunctions.value(this, "deletemyaccount_861960_value", "value", "", "", "")
          )
        )) as any
      ).data;
      stateVars.isLoggedIn = await ReactSystemFunctions.isLoggedIn();

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.DeleteMyAccountComponent_49298_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  DeleteMyAccountComponent_49298_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DeleteMyAccount/DeleteMyAccountComponent_49298_onClick_if1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteMyAccount = result?.data.deleteMyAccount;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DeleteMyAccountComponent_49298_onClick_if11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DeleteMyAccountComponent_49298_onClick_if11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "dd043a0a_d375_56dc_93d8_936e5785ec77_notify",
        this.defaultML,
        "Your account has been deleted."
      ),
      "default",
      "bottom-right",
      0
    );
    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DeleteMyAccount",
      "DeleteMyAccount",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  DeleteMyAccountComponent_49298_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "54371d02_7621_5c48_260a_f4026bb16b7c_notify",
        this.defaultML,
        "Please check your account information."
      ),
      "warning",
      "bottom-right",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  DeleteMyAccountComponent_49298_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.isLoggedIn), true)) {
      isErrorOccurred = await this.DeleteMyAccountComponent_49298_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.DeleteMyAccountComponent_49298_onClick_else1();
      if (isErrorOccurred) return true;
    }

    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterLogin(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DeleteMyAccountComponent_200367_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DeleteMyAccountPageInit();
    }
  }
}
