import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProfileEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProfileEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  GetUserCurrentId: any[];
  spGetUserById: any[];
  PhotoFromGallery: string;
  SetValueOf: any;
  IfThenElse: boolean;
  CheckUserExists: any[];
  SaveAppUsers: any[];
  UpdateKUserEmail: any[];
  SelectKUser: any[];
  Notify: boolean;
  RemoveWhitespaces: string;
}

export class ProfileEdit_ScreenBase extends React.PureComponent<IProfileEdit_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 124536, PropertyName: "value", Value: "Profil Düzenle", TagName: "Label11_value" },
        { Id: 861512, PropertyName: "value", Value: "İsim", TagName: "Label4_value" },
        { Id: 963625, PropertyName: "value", Value: "Soyisim", TagName: "Label10_0_value" },
        { Id: 700365, PropertyName: "value", Value: "Alan Kodu", TagName: "Label10_0_value" },
        { Id: 372226, PropertyName: "value", Value: "Telefon Numarası", TagName: "Label10_0_value" },
        { Id: 668005, PropertyName: "value", Value: "Adres", TagName: "Label15_0_value" },
        { Id: 806039, PropertyName: "value", Value: "Ülke", TagName: "Label12_value" },
        { Id: 344639, PropertyName: "value", Value: "Şehir", TagName: "Label14_value" },
        { Id: 34680, PropertyName: "value", Value: "Posta Kodu", TagName: "Label11_0_value" },
        { Id: 42131, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 590006, PropertyName: "value", Value: "Eposta", TagName: "Label8_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "bdcbfaf8-e138-4def-95f1-566775a33fcf",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 124536, PropertyName: "value", Value: "Edit Profile" },
        { Id: 861512, PropertyName: "value", Value: "Name" },
        { Id: 963625, PropertyName: "value", Value: "Surname" },
        { Id: 590006, PropertyName: "value", Value: "E-mail" },
        { Id: 700365, PropertyName: "value", Value: "Prefix" },
        { Id: 299723, PropertyName: "placeholder", Value: "+90" },
        { Id: 372226, PropertyName: "value", Value: "Phone Number" },
        { Id: 668005, PropertyName: "value", Value: "Address" },
        { Id: 806039, PropertyName: "value", Value: "Country" },
        { Id: 344639, PropertyName: "value", Value: "City" },
        { Id: 34680, PropertyName: "value", Value: "Postal Code" },
        { Id: 42131, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      GetUserCurrentId: [],
      spGetUserById: [],
      PhotoFromGallery: "",
      IfThenElse: false,
      RemoveWhitespaces: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("profileedit", "profileedit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProfileEditPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("profileedit", "profileedit");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("profileedit", "profileedit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProfileEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      profileedit_342604_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined,
      profileedit_274541_value: this.state.spGetUserById?.at?.(0)?.name ?? undefined,
      profileedit_131060_value: this.state.spGetUserById?.at?.(0)?.surname ?? undefined,
      profileedit_737823_value: this.state.spGetUserById?.at?.(0)?.email ?? undefined,
      profileedit_299723_value: this.state.spGetUserById?.at?.(0)?.phoneprefix ?? undefined,
      profileedit_863770_value: this.state.spGetUserById?.at?.(0)?.phoneNumber ?? undefined,
      profileedit_496957_value: this.state.spGetUserById?.at?.(0)?.address ?? undefined,
      profileedit_719266_value: this.state.spGetUserById?.at?.(0)?.addressCountry ?? undefined,
      profileedit_654231_value: this.state.spGetUserById?.at?.(0)?.addressCity ?? undefined,
      profileedit_593537_value: this.state.spGetUserById?.at?.(0)?.postalCode ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ProfileEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProfileEditPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProfileEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProfileEdit/ProfileEditPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.profileedit_342604_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    formVars.profileedit_274541_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.name : null
    );
    formVars.profileedit_131060_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.surname : null
    );
    formVars.profileedit_737823_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.email : null
    );
    formVars.profileedit_299723_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.phoneprefix : null
    );
    formVars.profileedit_863770_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.phoneNumber : null
    );
    formVars.profileedit_496957_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.address : null
    );
    formVars.profileedit_719266_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.addressCountry : null
    );
    formVars.profileedit_654231_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.addressCity : null
    );
    formVars.profileedit_593537_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.postalCode : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProfileEditPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProfileEditPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.profileedit_342604_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    formVars.profileedit_274541_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.name : null
    );

    formVars.profileedit_131060_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.surname : null
    );

    formVars.profileedit_737823_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.email : null
    );

    formVars.profileedit_299723_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.phoneprefix : null
    );

    formVars.profileedit_863770_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.phoneNumber : null
    );

    formVars.profileedit_496957_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.address : null
    );

    formVars.profileedit_719266_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.addressCountry : null
    );

    formVars.profileedit_654231_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.addressCity : null
    );

    formVars.profileedit_593537_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.postalCode : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProfileEditComponent_462237_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProfileEditComponent_741880_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProfileEditComponent_741880_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProfileEditComponent_741880_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "profileedit_342604_value",
      ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProfileEditComponent_741880_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      isErrorOccurred = await this.ProfileEditComponent_741880_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProfileEditComponent_42131_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "profileedit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProfileEdit/ProfileEditComponent_42131_onClick_if0", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckUserExists = result?.data.checkUserExists;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProfileEditComponent_42131_onClick_if01_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProfileEditComponent_42131_onClick_if0f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_496957_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_496957_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_654231_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_654231_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_719266_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_719266_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "profileedit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_274541_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_274541_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_863770_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_863770_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_299723_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_299723_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_593537_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_593537_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_131060_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_131060_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.id : null),
        "Guid"
      ),
      address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_496957_value", "value", "", "", "")
        ),
        "string"
      ),
      addressCity_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_654231_value", "value", "", "", "")
        ),
        "string"
      ),
      addressCountry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_719266_value", "value", "", "", "")
        ),
        "string"
      ),
      Company_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      companyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.companyID : null
        ),
        "Guid"
      ),
      createdAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      createdBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      isDeleted_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_274541_value", "value", "", "", "")
        ),
        "string"
      ),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "profileedit_863770_value", "value", "", "", "")
        ),
        "string"
      ),
      phonePrefix_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "profileedit_299723_value", "value", "", "", "")
        ),
        "string"
      ),
      postalCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_593537_value", "value", "", "", "")
        ),
        "string"
      ),
      profileImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_342604_value", "value", "", "", "")
        ),
        "string"
      ),
      status_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      surname_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_131060_value", "value", "", "", "")
        ),
        "string"
      ),
      userType_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.userType : null
        ),
        "number"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.kUserId : null
        ),
        "Guid"
      ),
      name_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_274541_value", "value", "", "", "")
        ),
        "string"
      ),
      surname_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_131060_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProfileEdit/ProfileEditComponent_42131_onClick_if0f1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveAppUsers = result?.data.saveAppUsers;
    stateVars.UpdateKUserEmail = result?.data.updateKUserEmail;
    stateVars.SelectKUser = result?.data.selectKUser;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProfileEditComponent_42131_onClick_if0f11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProfileEditComponent_42131_onClick_if0f11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1e71d61f_c2c6_e1a8_2357_2d236a716753_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProfileEditComponent_42131_onClick_if0e1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "02bb6776_faa2_110a_72b9_87e1c533cda3_notify",
        this.defaultML,
        "Bu mail adresi kullanımda!"
      ),
      "danger",
      "bottom-right",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProfileEditComponent_42131_onClick_if01_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.CheckUserExists?.length > 0 ? this.state.CheckUserExists[0]?.userCount : null
        ),
        "0"
      )
    ) {
      isErrorOccurred = await this.ProfileEditComponent_42131_onClick_if0f1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ProfileEditComponent_42131_onClick_if0e1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProfileEditComponent_42131_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_496957_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_496957_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_654231_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_654231_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_719266_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_719266_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "profileedit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_274541_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_274541_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_863770_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_863770_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_299723_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_299723_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_593537_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_593537_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_131060_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "profileedit_131060_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.id : null),
        "Guid"
      ),
      address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_496957_value", "value", "", "", "")
        ),
        "string"
      ),
      addressCity_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_654231_value", "value", "", "", "")
        ),
        "string"
      ),
      addressCountry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_719266_value", "value", "", "", "")
        ),
        "string"
      ),
      Company_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      companyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.companyID : null
        ),
        "Guid"
      ),
      createdAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      createdBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      isDeleted_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_274541_value", "value", "", "", "")
        ),
        "string"
      ),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "profileedit_863770_value", "value", "", "", "")
        ),
        "string"
      ),
      phonePrefix_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "profileedit_299723_value", "value", "", "", "")
        ),
        "string"
      ),
      postalCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_593537_value", "value", "", "", "")
        ),
        "string"
      ),
      profileImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_342604_value", "value", "", "", "")
        ),
        "string"
      ),
      status_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      surname_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_131060_value", "value", "", "", "")
        ),
        "string"
      ),
      userType_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.userType : null
        ),
        "number"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.kUserId : null
        ),
        "Guid"
      ),
      name_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_274541_value", "value", "", "", "")
        ),
        "string"
      ),
      surname_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_131060_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProfileEdit/ProfileEditComponent_42131_onClick_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveAppUsers = result?.data.saveAppUsers;
    stateVars.UpdateKUserEmail = result?.data.updateKUserEmail;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProfileEditComponent_42131_onClick_else01_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProfileEditComponent_42131_onClick_else01_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "ed6c5597_d237_7f4f_8da0_a85a881cefa4_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProfileEditComponent_42131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.email : null
        ),
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profileedit_737823_value", "value", "", "", "")
        )
      )
    ) {
      isErrorOccurred = await this.ProfileEditComponent_42131_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ProfileEditComponent_42131_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProfileEditComponent_42131_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProfileEditComponent_42131_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.phoneNumber : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProfileEdit/ProfileEditComponent_42131_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RemoveWhitespaces = result?.data.removeWhitespaces;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
