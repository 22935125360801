import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDrawer,
  KPagination,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  PlacesAutocompleteInput,
  Tooltip,
  VerticalStack
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { AssignedVehicleView_ScreenBase } from "./assignedvehicleview-base";

import { PreviewDocument_Screen } from "../previewdocument/previewdocument";
import { PreviewInvoice_Screen } from "../previewinvoice/previewinvoice";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class AssignedVehicleView_Screen extends AssignedVehicleView_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              assignedvehicleview_303614_value: "d904a563-d1a7-4126-38a8-08dac24f7806",
              assignedvehicleview_499488_value:
                this.state.sqlGetVehicleRequestById?.at?.(0)?.customsAgentId ?? undefined,
              assignedvehicleview_811923_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.brokerId ?? undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="assignedvehicleview_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)",
                    lineHeight: "22px"
                  } as any
                }
              >
                <KRow
                  id="555086"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="632745"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="assignedvehicleview_303614_value">
                      <Image
                        id="303614"
                        placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                        zoomOnClick={false}
                        imageFit="fit"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            width: "180px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Image>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="456155"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Button
                      id="987347"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.AssignedVehicleViewComponent_987347_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 987347, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      icon="refresh"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderTopWidth: 1,
                          borderRightWidth: 1,
                          borderBottomWidth: 1,
                          borderLeftWidth: 1,
                          borderColor: "rgba(22, 193, 243, 1)",
                          borderStyle: "solid",
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 4,
                          paddingRight: 20,
                          paddingBottom: 8,
                          paddingLeft: 20,
                          height: "32px",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "rgba(22, 193, 243, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Button>
                  </KCol>

                  <KCol
                    id="674552"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Icon
                      id="155263"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.AssignedVehicleViewComponent_155263_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "24px",
                          color: "rgba(99, 100, 102, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="assignedvehicleview_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="270372"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="645508"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KRow
                      id="736153"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="55156"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KRow
                          id="409408"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="726748"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingBottom: 8,
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="21475"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderTopLeftRadius: 12,
                                  borderTopRightRadius: 12,
                                  borderBottomRightRadius: 12,
                                  borderBottomLeftRadius: 12,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="480991"
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <VerticalStack
                                  id="332860"
                                  visibility={this.state.isComp332860Visible}
                                  direction="vertical"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="641254"
                                    iconName="panorama_fish_eye"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(22, 193, 243, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="109681"
                                    value={ReactSystemFunctions.translate(this.ml, 109681, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>

                                <VerticalStack
                                  id="83322"
                                  visibility={this.state.isComp83322Visible}
                                  direction="vertical"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="966922"
                                    iconName="check_circle_outline"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(22, 193, 243, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="387926"
                                    value={ReactSystemFunctions.translate(this.ml, 387926, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>
                              </KCol>

                              <KCol
                                id="126996"
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <VerticalStack
                                  id="221232"
                                  visibility={this.state.isComp221232Visible}
                                  direction="vertical"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="270969"
                                    iconName="panorama_fish_eye"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(199, 199, 199, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="425638"
                                    value={ReactSystemFunctions.translate(this.ml, 425638, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(199, 199, 199, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>

                                <VerticalStack
                                  id="422610"
                                  visibility={this.state.isComp422610Visible}
                                  direction="vertical"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="277098"
                                    iconName="panorama_fish_eye"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(22, 193, 243, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="721516"
                                    value={ReactSystemFunctions.translate(this.ml, 721516, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>

                                <VerticalStack
                                  id="883895"
                                  visibility={this.state.isComp883895Visible}
                                  direction="vertical"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="278204"
                                    iconName="check_circle_outline"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(22, 193, 243, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="157583"
                                    value={ReactSystemFunctions.translate(this.ml, 157583, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>
                              </KCol>

                              <KCol
                                id="99287"
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <VerticalStack
                                  id="587160"
                                  visibility={this.state.isComp587160Visible}
                                  direction="vertical"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="57802"
                                    iconName="panorama_fish_eye"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(199, 199, 199, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="163746"
                                    value={ReactSystemFunctions.translate(this.ml, 163746, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(199, 199, 199, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>

                                <VerticalStack
                                  id="707288"
                                  visibility={this.state.isComp707288Visible}
                                  direction="vertical"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="217281"
                                    iconName="panorama_fish_eye"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(22, 193, 243, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="496842"
                                    value={ReactSystemFunctions.translate(this.ml, 496842, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>

                                <VerticalStack
                                  id="609812"
                                  visibility={this.state.isComp609812Visible}
                                  direction="vertical"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="553265"
                                    iconName="check_circle_outline"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(22, 193, 243, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="901446"
                                    value={ReactSystemFunctions.translate(this.ml, 901446, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>
                              </KCol>

                              <KCol
                                id="455195"
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <VerticalStack
                                  id="770317"
                                  visibility={this.state.isComp770317Visible}
                                  direction="vertical"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="762549"
                                    iconName="panorama_fish_eye"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(199, 199, 199, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="846146"
                                    value={ReactSystemFunctions.translate(this.ml, 846146, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(199, 199, 199, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>

                                <VerticalStack
                                  id="377814"
                                  visibility={this.state.isComp377814Visible}
                                  direction="vertical"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="125933"
                                    iconName="panorama_fish_eye"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(22, 193, 243, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="744344"
                                    value={ReactSystemFunctions.translate(this.ml, 744344, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>

                                <VerticalStack
                                  id="928987"
                                  visibility={this.state.isComp928987Visible}
                                  direction="vertical"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="436397"
                                    iconName="check_circle_outline"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(22, 193, 243, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Label
                                    id="167614"
                                    value={ReactSystemFunctions.translate(this.ml, 167614, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </VerticalStack>
                              </KCol>
                            </KRow>

                            <KRow
                              id="274275"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderTopLeftRadius: 12,
                                  borderTopRightRadius: 12,
                                  borderBottomRightRadius: 12,
                                  borderBottomLeftRadius: 12,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: null,
                                  paddingBottom: null,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="647130"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="570253"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopLeftRadius: 12,
                                      borderTopRightRadius: 12,
                                      borderBottomRightRadius: 12,
                                      borderBottomLeftRadius: 12,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 4,
                                      paddingRight: 8,
                                      paddingBottom: 4,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="697029"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="896106"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 12,
                                          borderTopRightRadius: 12,
                                          borderBottomRightRadius: 12,
                                          borderBottomLeftRadius: 12,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="168743"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="761565"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="198736"
                                            xs={16}
                                            sm={16}
                                            md={16}
                                            lg={16}
                                            xl={16}
                                            xxl={16}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="762279"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                762279,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14pt",
                                                  color: "rgba(22, 193, 243, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="874316"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-right",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></KCol>
                                        </KRow>

                                        <KRow
                                          id="311471"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingBottom: 0,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="146888"
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            xxl={12}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="732102"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                732102,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="239304"
                                              value={this.state.GetOrderList?.at?.(0)?.cardname ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "16px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="897439"
                                            xs={24}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            xxl={6}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="373708"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                373708,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="225970"
                                              value={this.state.GetOrderList?.at?.(0)?.custrefno ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="865390"
                                            visibility={this.state.isCompassignedvehicleview_865390AuthorizationVisible}
                                            visibilityByAuthorization={
                                              this.state.isCompassignedvehicleview_865390AuthorizationVisible
                                            }
                                            xs={24}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            xxl={6}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="583345"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                583345,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="282384"
                                              value={this.state.GetOrderList?.at?.(0)?.cardcode ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="892977"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="825887"
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            xxl={12}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="46425"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                46425,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="180459"
                                              value={this.state.GetOrderList?.at?.(0)?.docdate ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="418216"
                                            xs={24}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            xxl={6}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="685221"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                685221,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="245506"
                                              value={this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="672338"
                                            xs={24}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            xxl={6}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="267211"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                267211,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="543657"
                                              value={this.state.GetOrderList?.at?.(0)?.country ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="64893"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingBottom: 0,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="171458"
                                            xs={24}
                                            sm={18}
                                            md={18}
                                            lg={18}
                                            xl={18}
                                            xxl={18}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="216196"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                216196,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="462529"
                                              value={this.state.GetOrderList?.at?.(0)?.shiptoaddress ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "16px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="732293"
                                            xs={24}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            xxl={6}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="970034"
                                              visibility={this.state.isComp970034Visible}
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderTopLeftRadius: 12,
                                                  borderTopRightRadius: 12,
                                                  borderBottomRightRadius: 12,
                                                  borderBottomLeftRadius: 12,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(74, 144, 226, 0.12)",
                                                  paddingTop: 2,
                                                  paddingRight: 8,
                                                  paddingBottom: 2,
                                                  paddingLeft: 8,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="406950"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  406950,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-right",
                                                    display: "inline",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></Label>

                                              <Label
                                                id="948478"
                                                value={
                                                  this.state.sqlGetVehicleRequestById?.at?.(0)?.edeliveryNo ?? undefined
                                                }
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="60725"
                                  visibility={this.state.isCompassignedvehicleview_60725AuthorizationVisible}
                                  visibilityByAuthorization={
                                    this.state.isCompassignedvehicleview_60725AuthorizationVisible
                                  }
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopLeftRadius: 12,
                                      borderTopRightRadius: 12,
                                      borderBottomRightRadius: 12,
                                      borderBottomLeftRadius: 12,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 4,
                                      paddingRight: 8,
                                      paddingBottom: 4,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="130086"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="669887"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 12,
                                          borderTopRightRadius: 12,
                                          borderBottomRightRadius: 12,
                                          borderBottomLeftRadius: 12,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="729483"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="585009"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="707620"
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            xxl={12}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="742903"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                742903,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14pt",
                                                  color: "rgba(22, 193, 243, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="242043"
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            xxl={12}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="227342"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="766483"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  766483,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "block",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    color: "rgba(155, 155, 155, 1)",
                                                    lineHeight: "20px"
                                                  } as any
                                                }
                                              ></Label>

                                              <Label
                                                id="934636"
                                                value={
                                                  this.state.sqlGetVehicleRequestById?.at?.(0)?.createdDate ?? undefined
                                                }
                                                formatter="date"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="867437"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 4,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="108457"
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            xxl={12}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="215277"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="394592"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  394592,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "block",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    color: "rgba(155, 155, 155, 1)",
                                                    lineHeight: "20px"
                                                  } as any
                                                }
                                              ></Label>

                                              <Label
                                                id="260290"
                                                value={
                                                  this.state.sqlGetVehicleRequestById?.at?.(0)?.supplierCardName ??
                                                  undefined
                                                }
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </KCol>

                                          <KCol
                                            id="942690"
                                            xs={24}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            xxl={6}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="786077"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                786077,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="200182"
                                              value={
                                                this.state.sqlGetVehicleRequestById?.at?.(0)?.loadingDate ?? undefined
                                              }
                                              formatter="date"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="873369"
                                            visibility={this.state.isCompassignedvehicleview_873369AuthorizationVisible}
                                            visibilityByAuthorization={
                                              this.state.isCompassignedvehicleview_873369AuthorizationVisible
                                            }
                                            xs={24}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            xxl={6}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="823400"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="229813"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  229813,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "block",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    color: "rgba(155, 155, 155, 1)",
                                                    lineHeight: "20px"
                                                  } as any
                                                }
                                              ></Label>

                                              <Label
                                                id="942340"
                                                value={
                                                  this.state.sqlGetVehicleRequestById?.at?.(0)?.navlun ?? undefined
                                                }
                                                formatter="money"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "16px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></Label>

                                              <Label
                                                id="416530"
                                                value={
                                                  this.state.sqlGetVehicleRequestById?.at?.(0)?.currencyCode ??
                                                  undefined
                                                }
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="787773"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingBottom: 0,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="599843"
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="224999"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                224999,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="286122"
                                              value={
                                                this.state.sqlGetVehicleRequestById?.at?.(0)?.loadingAddress ??
                                                undefined
                                              }
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="899355"
                                  visibility={this.state.isCompassignedvehicleview_899355AuthorizationVisible}
                                  visibilityByAuthorization={
                                    this.state.isCompassignedvehicleview_899355AuthorizationVisible
                                  }
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopLeftRadius: 12,
                                      borderTopRightRadius: 12,
                                      borderBottomRightRadius: 12,
                                      borderBottomLeftRadius: 12,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 4,
                                      paddingRight: 8,
                                      paddingBottom: 4,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="840353"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="351057"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 12,
                                          borderTopRightRadius: 12,
                                          borderBottomRightRadius: 12,
                                          borderBottomLeftRadius: 12,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="573064"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingBottom: null,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="136243"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="765520"
                                            xs={24}
                                            sm={10}
                                            md={10}
                                            lg={10}
                                            xl={10}
                                            xxl={10}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="705882"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                705882,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14pt",
                                                  color: "rgba(22, 193, 243, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="466084"
                                            xs={24}
                                            sm={14}
                                            md={14}
                                            lg={14}
                                            xl={14}
                                            xxl={14}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 0,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="13035"
                                              visibility={this.state.isComp13035Visible}
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                13035,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14pt",
                                                  color: "rgba(208, 2, 27, 1)"
                                                } as any
                                              }
                                            ></Label>

                                            <Button
                                              id="201"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.AssignedVehicleViewComponent_201_onClick();
                                              }}
                                              showCursorPointer
                                              visibility={
                                                this.state.isComp201Visible == "hidden" ||
                                                this.state.isCompassignedvehicleview_201AuthorizationVisible == "hidden"
                                                  ? "hidden"
                                                  : "visible"
                                              }
                                              visibilityByAuthorization={
                                                this.state.isCompassignedvehicleview_201AuthorizationVisible == "hidden"
                                                  ? "hidden"
                                                  : "visible"
                                              }
                                              label={ReactSystemFunctions.translate(
                                                this.ml,
                                                201,
                                                "label",
                                                this.defaultML
                                              )}
                                              size="middle"
                                              loading={false}
                                              ghost={false}
                                              block={false}
                                              htmlType="button"
                                              iconPosition="left"
                                              danger={false}
                                              style={
                                                {
                                                  borderTopLeftRadius: 4,
                                                  borderTopRightRadius: 4,
                                                  borderBottomRightRadius: 4,
                                                  borderBottomLeftRadius: 4,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(22, 193, 243, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 20,
                                                  paddingBottom: 8,
                                                  paddingLeft: 20,
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "16px",
                                                  color: "rgba(255, 255, 255, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Button>

                                            <HorizontalStack
                                              id="973624"
                                              visibility={this.state.isComp973624Visible}
                                              direction="horizontal"
                                              size={4}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <Icon
                                                id="298022"
                                                iconName="check_circle_outline"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "32px",
                                                    color: "rgba(126, 211, 33, 1)",
                                                    letterSpacing: "1px"
                                                  } as any
                                                }
                                              ></Icon>

                                              <VerticalStack
                                                id="824818"
                                                direction="vertical"
                                                size={-5}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <HorizontalStack
                                                  id="22405"
                                                  direction="horizontal"
                                                  size={4}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="446761"
                                                    value={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      446761,
                                                      "value",
                                                      this.defaultML
                                                    )}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        width: "100px",
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "block",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                        color: "rgba(155, 155, 155, 1)",
                                                        lineHeight: "20px"
                                                      } as any
                                                    }
                                                  ></Label>

                                                  <Label
                                                    id="809542"
                                                    value={
                                                      this.state.sqlGetVehicleRequestById?.at?.(0)?.approverFullName ??
                                                      undefined
                                                    }
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "12px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>

                                                <HorizontalStack
                                                  id="446276"
                                                  direction="horizontal"
                                                  size={4}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="415819"
                                                    value={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      415819,
                                                      "value",
                                                      this.defaultML
                                                    )}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        width: "100px",
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                        color: "rgba(155, 155, 155, 1)",
                                                        lineHeight: "20px"
                                                      } as any
                                                    }
                                                  ></Label>

                                                  <Label
                                                    id="806706"
                                                    value={
                                                      this.state.sqlGetVehicleRequestById?.at?.(0)?.approvedAt ??
                                                      undefined
                                                    }
                                                    formatter="datetime"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "12px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>
                                              </VerticalStack>
                                            </HorizontalStack>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="349601"
                                          visibility={this.state.isComp349601Visible}
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="11744"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KRow
                                              id="260467"
                                              visibility={
                                                this.state.isComp260467Visible == "hidden" ||
                                                this.state.isCompassignedvehicleview_260467AuthorizationVisible ==
                                                  "hidden"
                                                  ? "hidden"
                                                  : "visible"
                                              }
                                              visibilityByAuthorization={
                                                this.state.isCompassignedvehicleview_260467AuthorizationVisible ==
                                                "hidden"
                                                  ? "hidden"
                                                  : "visible"
                                              }
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderTopWidth: 1,
                                                  borderBottomWidth: 1,
                                                  borderColor: "rgba(244, 244, 244, 1)",
                                                  borderStyle: "solid",
                                                  borderRightWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: null,
                                                  paddingBottom: null,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="43426"
                                                xs={24}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                xxl={12}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <HorizontalStack
                                                  id="505569"
                                                  direction="horizontal"
                                                  size={12}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="746223"
                                                    value={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      746223,
                                                      "value",
                                                      this.defaultML
                                                    )}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "block",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                        color: "rgba(155, 155, 155, 1)",
                                                        lineHeight: "20px"
                                                      } as any
                                                    }
                                                  ></Label>

                                                  <Label
                                                    id="722340"
                                                    value={
                                                      this.state.sqlGetVehicleRequestById?.at?.(0)
                                                        ?.estimatedDeliveryDate ?? undefined
                                                    }
                                                    formatter="date"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "16px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>
                                              </KCol>

                                              <KCol
                                                id="795643"
                                                xs={24}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                xxl={12}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <HorizontalStack
                                                  id="315558"
                                                  visibility={
                                                    this.state.isCompassignedvehicleview_315558AuthorizationVisible
                                                  }
                                                  visibilityByAuthorization={
                                                    this.state.isCompassignedvehicleview_315558AuthorizationVisible
                                                  }
                                                  direction="horizontal"
                                                  size={12}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="99083"
                                                    value={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      99083,
                                                      "value",
                                                      this.defaultML
                                                    )}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "block",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                        color: "rgba(155, 155, 155, 1)",
                                                        lineHeight: "20px"
                                                      } as any
                                                    }
                                                  ></Label>

                                                  <Label
                                                    id="871054"
                                                    value={
                                                      this.state.sqlGetVehicleRequestById?.at?.(0)?.invoiceNumber ??
                                                      undefined
                                                    }
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "block",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>

                                                  <Label
                                                    id="444544"
                                                    onClick={(e?: any) => {
                                                      if (e && e.stopPropagation) e.stopPropagation();
                                                      this.AssignedVehicleViewComponent_444544_onClick();
                                                    }}
                                                    showCursorPointer
                                                    visibility={this.state.isComp444544Visible}
                                                    value={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      444544,
                                                      "value",
                                                      this.defaultML
                                                    )}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 193, 243, 1)",
                                                        lineHeight: "22px",
                                                        textDecoration: "underline"
                                                      } as any
                                                    }
                                                  ></Label>

                                                  <Label
                                                    id="624121"
                                                    visibility={this.state.isComp624121Visible}
                                                    value={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      624121,
                                                      "value",
                                                      this.defaultML
                                                    )}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(208, 2, 27, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>
                                              </KCol>
                                            </KRow>

                                            <KRow
                                              id="107260"
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingBottom: 0,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="403114"
                                                xs={24}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                xxl={12}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 8,
                                                    paddingBottom: 0,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <Label
                                                  id="95668"
                                                  value={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    95668,
                                                    "value",
                                                    this.defaultML
                                                  )}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 400,
                                                      fontSize: "12px",
                                                      color: "rgba(155, 155, 155, 1)",
                                                      lineHeight: "20px"
                                                    } as any
                                                  }
                                                ></Label>

                                                <Label
                                                  id="799724"
                                                  value={
                                                    this.state.sqlGetVehicleRequestById?.at?.(0)?.truckPlateNumber ??
                                                    undefined
                                                  }
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "inline",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "16px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></Label>
                                              </KCol>

                                              <KCol
                                                id="494023"
                                                xs={24}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                xxl={12}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 8,
                                                    paddingBottom: 0,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <Label
                                                  id="864974"
                                                  value={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    864974,
                                                    "value",
                                                    this.defaultML
                                                  )}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 400,
                                                      fontSize: "12px",
                                                      color: "rgba(155, 155, 155, 1)",
                                                      lineHeight: "20px"
                                                    } as any
                                                  }
                                                ></Label>

                                                <Label
                                                  id="53508"
                                                  value={
                                                    this.state.sqlGetVehicleRequestById?.at?.(0)?.trailerPlateNumber ??
                                                    undefined
                                                  }
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "inline",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "16px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></Label>
                                              </KCol>
                                            </KRow>

                                            <KRow
                                              id="528314"
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: null,
                                                  paddingBottom: null,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="334187"
                                                xs={24}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                xxl={12}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 8,
                                                    paddingBottom: 0,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <Label
                                                  id="252660"
                                                  value={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    252660,
                                                    "value",
                                                    this.defaultML
                                                  )}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 400,
                                                      fontSize: "12px",
                                                      color: "rgba(155, 155, 155, 1)",
                                                      lineHeight: "20px"
                                                    } as any
                                                  }
                                                ></Label>

                                                <HorizontalStack
                                                  id="25690"
                                                  direction="horizontal"
                                                  size={4}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="871532"
                                                    value={
                                                      this.state.sqlGetVehicleRequestById?.at?.(0)?.driverFullName ??
                                                      undefined
                                                    }
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "16px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>
                                              </KCol>

                                              <KCol
                                                id="678769"
                                                xs={24}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                xxl={12}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 8,
                                                    paddingBottom: 0,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <Label
                                                  id="766898"
                                                  value={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    766898,
                                                    "value",
                                                    this.defaultML
                                                  )}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 400,
                                                      fontSize: "12px",
                                                      color: "rgba(155, 155, 155, 1)",
                                                      lineHeight: "20px"
                                                    } as any
                                                  }
                                                ></Label>

                                                <HorizontalStack
                                                  id="643238"
                                                  direction="horizontal"
                                                  size={4}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="409307"
                                                    value={
                                                      this.state.sqlGetVehicleRequestById?.at?.(0)?.formattedPhone ??
                                                      undefined
                                                    }
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "16px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>
                                              </KCol>
                                            </KRow>

                                            <KRow
                                              id="20508"
                                              visibility={
                                                this.state.isComp20508Visible == "hidden" ||
                                                this.state.isCompassignedvehicleview_20508AuthorizationVisible ==
                                                  "hidden"
                                                  ? "hidden"
                                                  : "visible"
                                              }
                                              visibilityByAuthorization={
                                                this.state.isCompassignedvehicleview_20508AuthorizationVisible ==
                                                "hidden"
                                                  ? "hidden"
                                                  : "visible"
                                              }
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="694350"
                                                xs={24}
                                                sm={24}
                                                md={24}
                                                lg={24}
                                                xl={24}
                                                xxl={24}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <KRow
                                                  id="221321"
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingBottom: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="209715"
                                                    xs={24}
                                                    sm={24}
                                                    md={24}
                                                    lg={24}
                                                    xl={24}
                                                    xxl={24}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="352194"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingBottom: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="291644"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <Label
                                                          id="28047"
                                                          value={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            28047,
                                                            "value",
                                                            this.defaultML
                                                          )}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "inline",
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 193, 243, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        ></Label>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="565634"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingBottom: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="278235"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="970250"
                                                          direction="horizontal"
                                                          size={4}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingLeft: 8,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <Label
                                                            id="268730"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              268730,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                width: "100px",
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                color: "rgba(155, 155, 155, 1)",
                                                                lineHeight: "20px"
                                                              } as any
                                                            }
                                                          ></Label>

                                                          <Label
                                                            id="360114"
                                                            value={
                                                              this.state.sqlGetVehicleRequestById?.at?.(0)
                                                                ?.supplierCardName ?? undefined
                                                            }
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="380460"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingBottom: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="82664"
                                                        xs={24}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="441932"
                                                          direction="horizontal"
                                                          size={4}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingLeft: 8,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <Label
                                                            id="496068"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              496068,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                width: "100px",
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                color: "rgba(155, 155, 155, 1)",
                                                                lineHeight: "20px"
                                                              } as any
                                                            }
                                                          ></Label>

                                                          <Label
                                                            id="339215"
                                                            value={
                                                              this.state.sqlGetVehicleRequestById?.at?.(0)
                                                                ?.assignerFullName ?? undefined
                                                            }
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="981258"
                                                        xs={24}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="89686"
                                                          direction="horizontal"
                                                          size={4}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <Label
                                                            id="530934"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              530934,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                width: "100px",
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "block",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                color: "rgba(155, 155, 155, 1)",
                                                                lineHeight: "20px"
                                                              } as any
                                                            }
                                                          ></Label>

                                                          <Label
                                                            id="28160"
                                                            value={
                                                              this.state.sqlGetVehicleRequestById?.at?.(0)
                                                                ?.assignedAt ?? undefined
                                                            }
                                                            formatter="date"
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="351500"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingBottom: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="412781"
                                                        xs={24}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="140274"
                                                          direction="horizontal"
                                                          size={4}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingLeft: 8,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <Label
                                                            id="642528"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              642528,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                width: "100px",
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "block",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                color: "rgba(155, 155, 155, 1)",
                                                                lineHeight: "20px"
                                                              } as any
                                                            }
                                                          ></Label>

                                                          <Label
                                                            id="12761"
                                                            value={
                                                              this.state.sqlGetVehicleRequestById?.at?.(0)
                                                                ?.assignerEmail ?? undefined
                                                            }
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="938702"
                                                        xs={24}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="413174"
                                                          direction="horizontal"
                                                          size={4}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <Label
                                                            id="447137"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              447137,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                width: "100px",
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "block",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                color: "rgba(155, 155, 155, 1)",
                                                                lineHeight: "20px"
                                                              } as any
                                                            }
                                                          ></Label>

                                                          <Label
                                                            id="763411"
                                                            value={
                                                              this.state.sqlGetVehicleRequestById?.at?.(0)
                                                                ?.assignerFormattedPhone ?? undefined
                                                            }
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>
                                                </KRow>
                                              </KCol>
                                            </KRow>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="190903"
                                  visibility={this.state.isComp190903Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="728058"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="970094"
                                      visibility={
                                        this.state.isComp970094Visible == "hidden" ||
                                        this.state.isCompassignedvehicleview_970094AuthorizationVisible == "hidden"
                                          ? "hidden"
                                          : "visible"
                                      }
                                      visibilityByAuthorization={
                                        this.state.isCompassignedvehicleview_970094AuthorizationVisible == "hidden"
                                          ? "hidden"
                                          : "visible"
                                      }
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 12,
                                          borderTopRightRadius: 12,
                                          borderBottomRightRadius: 12,
                                          borderBottomLeftRadius: 12,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 4,
                                          paddingRight: 8,
                                          paddingBottom: 4,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="663644"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="603044"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderTopLeftRadius: 12,
                                              borderTopRightRadius: 12,
                                              borderBottomRightRadius: 12,
                                              borderBottomLeftRadius: 12,
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="656863"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KRow
                                              id="535437"
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingBottom: 0,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="555660"
                                                xs={24}
                                                sm={24}
                                                md={24}
                                                lg={24}
                                                xl={24}
                                                xxl={24}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 8,
                                                    paddingBottom: 0,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <KRow
                                                  id="709836"
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="785088"
                                                    xs={8}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <HorizontalStack
                                                      id="776334"
                                                      direction="horizontal"
                                                      size={12}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          alignItems: "center",
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <Label
                                                        id="995252"
                                                        value={ReactSystemFunctions.translate(
                                                          this.ml,
                                                          995252,
                                                          "value",
                                                          this.defaultML
                                                        )}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "inline",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14pt",
                                                            color: "rgba(22, 193, 243, 1)"
                                                          } as any
                                                        }
                                                      ></Label>

                                                      <Icon
                                                        id="414740"
                                                        onClick={(e?: any) => {
                                                          if (e && e.stopPropagation) e.stopPropagation();
                                                          this.AssignedVehicleViewComponent_414740_onClick();
                                                        }}
                                                        showCursorPointer
                                                        visibility={
                                                          this.state
                                                            .isCompassignedvehicleview_414740AuthorizationVisible
                                                        }
                                                        visibilityByAuthorization={
                                                          this.state
                                                            .isCompassignedvehicleview_414740AuthorizationVisible
                                                        }
                                                        iconName="edit"
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "inline",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "24px",
                                                            color: "rgba(22, 193, 243, 1)",
                                                            letterSpacing: "1px"
                                                          } as any
                                                        }
                                                      ></Icon>
                                                    </HorizontalStack>
                                                  </KCol>

                                                  <KCol
                                                    id="832621"
                                                    xs={16}
                                                    sm={16}
                                                    md={16}
                                                    lg={16}
                                                    xl={16}
                                                    xxl={16}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 8,
                                                        paddingBottom: 0,
                                                        paddingLeft: 8,
                                                        textAlign: "-webkit-right",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <HorizontalStack
                                                      id="765677"
                                                      visibility={this.state.isComp765677Visible}
                                                      direction="horizontal"
                                                      size={12}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <Label
                                                        id="31955"
                                                        visibility={this.state.isComp31955Visible}
                                                        value={ReactSystemFunctions.translate(
                                                          this.ml,
                                                          31955,
                                                          "value",
                                                          this.defaultML
                                                        )}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "inline",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(208, 2, 27, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      ></Label>

                                                      <Label
                                                        id="120265"
                                                        visibility={this.state.isComp120265Visible}
                                                        value={this.state.GetOrderList?.at?.(0)?.country ?? undefined}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "inline",
                                                            fontFamily: "Montserrat",
                                                            fontWeight: 500,
                                                            fontSize: "13pt",
                                                            color: "rgba(208, 2, 27, 1)"
                                                          } as any
                                                        }
                                                      ></Label>
                                                    </HorizontalStack>
                                                  </KCol>
                                                </KRow>

                                                <KRow
                                                  id="188401"
                                                  visibility={this.state.isComp188401Visible}
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="5935"
                                                    xs={24}
                                                    sm={24}
                                                    md={24}
                                                    lg={24}
                                                    xl={24}
                                                    xxl={24}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="977943"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="885836"
                                                        xs={24}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={8}
                                                        xxl={8}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 8,
                                                            paddingBottom: 0,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <Label
                                                          id="198020"
                                                          value={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            198020,
                                                            "value",
                                                            this.defaultML
                                                          )}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "block",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 400,
                                                              fontSize: "12px",
                                                              color: "rgba(155, 155, 155, 1)",
                                                              lineHeight: "20px"
                                                            } as any
                                                          }
                                                        ></Label>
                                                      </KCol>

                                                      <KCol
                                                        id="551874"
                                                        xs={24}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={16}
                                                        xxl={16}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 8,
                                                            paddingBottom: 0,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item
                                                          className="kFormItem"
                                                          name="assignedvehicleview_499488_value"
                                                        >
                                                          <KSelectBox
                                                            id="499488"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.AssignedVehicleViewComponent_499488_onChange();
                                                            }}
                                                            kuikaRef={
                                                              this.assignedvehicleview_499488_value_kuikaSelectBoxRef
                                                            }
                                                            options={this.state.getCustomsAgents}
                                                            placeholder={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              499488,
                                                              "placeholder",
                                                              this.defaultML
                                                            )}
                                                            allowClear={false}
                                                            autoClearSearchValue={true}
                                                            showSearch={false}
                                                            widthType="fill"
                                                            containsNullItem={false}
                                                            sortBy="none"
                                                            datavaluefield="id"
                                                            datatextfield="cardName"
                                                            style={
                                                              {
                                                                borderTopLeftRadius: 4,
                                                                borderTopRightRadius: 4,
                                                                borderBottomRightRadius: 4,
                                                                borderBottomLeftRadius: 4,
                                                                borderTopWidth: 1,
                                                                borderRightWidth: 1,
                                                                borderBottomWidth: 1,
                                                                borderLeftWidth: 1,
                                                                borderColor: "rgba(218, 218, 218, 1)",
                                                                borderStyle: "solid",
                                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "contain",
                                                                backgroundPosition: "left",
                                                                height: "40px",
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          ></KSelectBox>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>
                                                </KRow>

                                                <KRow
                                                  id="174579"
                                                  visibility={this.state.isComp174579Visible}
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="662669"
                                                    xs={24}
                                                    sm={24}
                                                    md={24}
                                                    lg={24}
                                                    xl={24}
                                                    xxl={24}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="699068"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="759177"
                                                        xs={24}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 8,
                                                            paddingBottom: 0,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <Label
                                                          id="547601"
                                                          value={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            547601,
                                                            "value",
                                                            this.defaultML
                                                          )}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "block",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 400,
                                                              fontSize: "12px",
                                                              color: "rgba(155, 155, 155, 1)",
                                                              lineHeight: "20px"
                                                            } as any
                                                          }
                                                        ></Label>

                                                        <Label
                                                          id="534792"
                                                          value={
                                                            this.state.getCustomsAgentById?.at?.(0)?.cardName ??
                                                            undefined
                                                          }
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "inline",
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "16px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        ></Label>
                                                      </KCol>

                                                      <KCol
                                                        id="676621"
                                                        xs={24}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 8,
                                                            paddingBottom: 0,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <Label
                                                          id="661320"
                                                          value={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            661320,
                                                            "value",
                                                            this.defaultML
                                                          )}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "block",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 400,
                                                              fontSize: "12px",
                                                              color: "rgba(155, 155, 155, 1)",
                                                              lineHeight: "20px"
                                                            } as any
                                                          }
                                                        ></Label>

                                                        <Label
                                                          id="272389"
                                                          value={
                                                            this.state.getCustomsAgentById?.at?.(0)?.formattedPhone ??
                                                            undefined
                                                          }
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "inline",
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "16px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        ></Label>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>
                                                </KRow>
                                              </KCol>
                                            </KRow>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="291794"
                                      visibility={
                                        this.state.isComp291794Visible == "hidden" ||
                                        this.state.isCompassignedvehicleview_291794AuthorizationVisible == "hidden"
                                          ? "hidden"
                                          : "visible"
                                      }
                                      visibilityByAuthorization={
                                        this.state.isCompassignedvehicleview_291794AuthorizationVisible == "hidden"
                                          ? "hidden"
                                          : "visible"
                                      }
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 12,
                                          borderTopRightRadius: 12,
                                          borderBottomRightRadius: 12,
                                          borderBottomLeftRadius: 12,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 4,
                                          paddingRight: 8,
                                          paddingBottom: 4,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="248115"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="60245"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderTopLeftRadius: 12,
                                              borderTopRightRadius: 12,
                                              borderBottomRightRadius: 12,
                                              borderBottomLeftRadius: 12,
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="94988"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KRow
                                              id="652871"
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingBottom: 0,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="709111"
                                                xs={24}
                                                sm={24}
                                                md={24}
                                                lg={24}
                                                xl={24}
                                                xxl={24}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 8,
                                                    paddingBottom: 0,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <KRow
                                                  id="969962"
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="865823"
                                                    xs={8}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <HorizontalStack
                                                      id="301759"
                                                      direction="horizontal"
                                                      size={12}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          alignItems: "center",
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <Label
                                                        id="300658"
                                                        value={ReactSystemFunctions.translate(
                                                          this.ml,
                                                          300658,
                                                          "value",
                                                          this.defaultML
                                                        )}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "inline",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14pt",
                                                            color: "rgba(22, 193, 243, 1)"
                                                          } as any
                                                        }
                                                      ></Label>

                                                      <Icon
                                                        id="476554"
                                                        onClick={(e?: any) => {
                                                          if (e && e.stopPropagation) e.stopPropagation();
                                                          this.AssignedVehicleViewComponent_476554_onClick();
                                                        }}
                                                        showCursorPointer
                                                        visibility={
                                                          this.state
                                                            .isCompassignedvehicleview_476554AuthorizationVisible
                                                        }
                                                        visibilityByAuthorization={
                                                          this.state
                                                            .isCompassignedvehicleview_476554AuthorizationVisible
                                                        }
                                                        iconName="edit"
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "inline",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "24px",
                                                            color: "rgba(22, 193, 243, 1)",
                                                            letterSpacing: "1px"
                                                          } as any
                                                        }
                                                      ></Icon>
                                                    </HorizontalStack>
                                                  </KCol>

                                                  <KCol
                                                    id="687269"
                                                    xs={16}
                                                    sm={16}
                                                    md={16}
                                                    lg={16}
                                                    xl={16}
                                                    xxl={16}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 8,
                                                        paddingBottom: 0,
                                                        paddingLeft: 8,
                                                        textAlign: "-webkit-right",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <HorizontalStack
                                                      id="853405"
                                                      visibility={this.state.isComp853405Visible}
                                                      direction="horizontal"
                                                      size={12}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <Label
                                                        id="782931"
                                                        visibility={this.state.isComp782931Visible}
                                                        value={ReactSystemFunctions.translate(
                                                          this.ml,
                                                          782931,
                                                          "value",
                                                          this.defaultML
                                                        )}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "inline",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(208, 2, 27, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      ></Label>

                                                      <Label
                                                        id="987039"
                                                        visibility={this.state.isComp987039Visible}
                                                        value={
                                                          this.state.GetOrderList?.at?.(0)?.transpname ?? undefined
                                                        }
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "inline",
                                                            fontFamily: "Montserrat",
                                                            fontWeight: 500,
                                                            fontSize: "13pt",
                                                            color: "rgba(208, 2, 27, 1)"
                                                          } as any
                                                        }
                                                      ></Label>
                                                    </HorizontalStack>
                                                  </KCol>
                                                </KRow>

                                                <KRow
                                                  id="984754"
                                                  visibility={this.state.isComp984754Visible}
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="39708"
                                                    xs={24}
                                                    sm={24}
                                                    md={24}
                                                    lg={24}
                                                    xl={24}
                                                    xxl={24}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="612397"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="280800"
                                                        xs={24}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={8}
                                                        xxl={8}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 8,
                                                            paddingBottom: 0,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <Label
                                                          id="525505"
                                                          value={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            525505,
                                                            "value",
                                                            this.defaultML
                                                          )}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "block",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 400,
                                                              fontSize: "12px",
                                                              color: "rgba(155, 155, 155, 1)",
                                                              lineHeight: "20px"
                                                            } as any
                                                          }
                                                        ></Label>
                                                      </KCol>

                                                      <KCol
                                                        id="117157"
                                                        xs={24}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={16}
                                                        xxl={16}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 8,
                                                            paddingBottom: 0,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item
                                                          className="kFormItem"
                                                          name="assignedvehicleview_811923_value"
                                                        >
                                                          <KSelectBox
                                                            id="811923"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.AssignedVehicleViewComponent_811923_onChange();
                                                            }}
                                                            kuikaRef={
                                                              this.assignedvehicleview_811923_value_kuikaSelectBoxRef
                                                            }
                                                            options={this.state.getAllBrokersByFilter}
                                                            placeholder={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              811923,
                                                              "placeholder",
                                                              this.defaultML
                                                            )}
                                                            allowClear={false}
                                                            autoClearSearchValue={true}
                                                            showSearch={false}
                                                            widthType="fill"
                                                            containsNullItem={false}
                                                            sortBy="none"
                                                            datavaluefield="id"
                                                            datatextfield="fullname"
                                                            style={
                                                              {
                                                                borderTopLeftRadius: 4,
                                                                borderTopRightRadius: 4,
                                                                borderBottomRightRadius: 4,
                                                                borderBottomLeftRadius: 4,
                                                                borderTopWidth: 1,
                                                                borderRightWidth: 1,
                                                                borderBottomWidth: 1,
                                                                borderLeftWidth: 1,
                                                                borderColor: "rgba(218, 218, 218, 1)",
                                                                borderStyle: "solid",
                                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "contain",
                                                                backgroundPosition: "left",
                                                                height: "40px",
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          ></KSelectBox>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>
                                                </KRow>

                                                <KRow
                                                  id="227437"
                                                  visibility={this.state.isComp227437Visible}
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="484563"
                                                    xs={24}
                                                    sm={24}
                                                    md={24}
                                                    lg={24}
                                                    xl={24}
                                                    xxl={24}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="628510"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="614167"
                                                        xs={24}
                                                        sm={10}
                                                        md={10}
                                                        lg={10}
                                                        xl={10}
                                                        xxl={10}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <Label
                                                          id="869501"
                                                          value={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            869501,
                                                            "value",
                                                            this.defaultML
                                                          )}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "block",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 400,
                                                              fontSize: "12px",
                                                              color: "rgba(155, 155, 155, 1)",
                                                              lineHeight: "20px"
                                                            } as any
                                                          }
                                                        ></Label>

                                                        <Label
                                                          id="937800"
                                                          value={
                                                            this.state.getBrokerById?.at?.(0)?.fullname ?? undefined
                                                          }
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "inline",
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "16px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        ></Label>
                                                      </KCol>

                                                      <KCol
                                                        id="358796"
                                                        xs={24}
                                                        sm={10}
                                                        md={10}
                                                        lg={10}
                                                        xl={10}
                                                        xxl={10}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 8,
                                                            paddingBottom: 0,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <Label
                                                          id="824865"
                                                          value={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            824865,
                                                            "value",
                                                            this.defaultML
                                                          )}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "block",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 400,
                                                              fontSize: "12px",
                                                              color: "rgba(155, 155, 155, 1)",
                                                              lineHeight: "20px"
                                                            } as any
                                                          }
                                                        ></Label>

                                                        <Label
                                                          id="620093"
                                                          value={this.state.getBrokerById?.at?.(0)?.email ?? undefined}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "inline",
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "16px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        ></Label>
                                                      </KCol>

                                                      <KCol
                                                        id="830618"
                                                        xs={24}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 8,
                                                            paddingBottom: 0,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <Label
                                                          id="924092"
                                                          value={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            924092,
                                                            "value",
                                                            this.defaultML
                                                          )}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "block",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 400,
                                                              fontSize: "12px",
                                                              color: "rgba(155, 155, 155, 1)",
                                                              lineHeight: "20px"
                                                            } as any
                                                          }
                                                        ></Label>

                                                        <Label
                                                          id="416930"
                                                          value={
                                                            this.state.getBrokerById?.at?.(0)?.fullphone ?? undefined
                                                          }
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "inline",
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "16px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        ></Label>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>
                                                </KRow>
                                              </KCol>
                                            </KRow>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="733299"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 12,
                                          borderTopRightRadius: 12,
                                          borderBottomRightRadius: 12,
                                          borderBottomLeftRadius: 12,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 4,
                                          paddingBottom: 4,
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="239300"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="803014"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderTopLeftRadius: 12,
                                              borderTopRightRadius: 12,
                                              borderBottomRightRadius: 12,
                                              borderBottomLeftRadius: 12,
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: null,
                                              paddingBottom: null,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="556554"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KRow
                                              id="19907"
                                              visibility={
                                                this.state.isCompassignedvehicleview_19907AuthorizationVisible
                                              }
                                              visibilityByAuthorization={
                                                this.state.isCompassignedvehicleview_19907AuthorizationVisible
                                              }
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 8,
                                                  paddingBottom: 0,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="697641"
                                                xs={24}
                                                sm={24}
                                                md={24}
                                                lg={24}
                                                xl={24}
                                                xxl={24}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderTopLeftRadius: 12,
                                                    borderTopRightRadius: 12,
                                                    borderBottomRightRadius: 12,
                                                    borderBottomLeftRadius: 12,
                                                    borderTopWidth: 1,
                                                    borderRightWidth: 1,
                                                    borderBottomWidth: 1,
                                                    borderLeftWidth: 1,
                                                    borderColor: "rgba(227, 241, 250, 1)",
                                                    borderStyle: "solid",
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "contain",
                                                    backgroundPosition: "left",
                                                    paddingTop: 0,
                                                    paddingBottom: 4,
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <KRow
                                                  id="171303"
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 0,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="41697"
                                                    xs={16}
                                                    sm={16}
                                                    md={16}
                                                    lg={16}
                                                    xl={16}
                                                    xxl={16}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <Label
                                                      id="303193"
                                                      value={ReactSystemFunctions.translate(
                                                        this.ml,
                                                        303193,
                                                        "value",
                                                        this.defaultML
                                                      )}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14pt",
                                                          color: "rgba(22, 193, 243, 1)"
                                                        } as any
                                                      }
                                                    ></Label>

                                                    <Button
                                                      id="240932"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.AssignedVehicleViewComponent_240932_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={this.state.isComp240932Visible}
                                                      label={ReactSystemFunctions.translate(
                                                        this.ml,
                                                        240932,
                                                        "label",
                                                        this.defaultML
                                                      )}
                                                      size="middle"
                                                      loading={false}
                                                      ghost={false}
                                                      block={false}
                                                      htmlType="button"
                                                      iconPosition="left"
                                                      danger={false}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 4,
                                                          borderTopRightRadius: 4,
                                                          borderBottomRightRadius: 4,
                                                          borderBottomLeftRadius: 4,
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          backgroundColor: "rgba(22, 193, 243, 1)",
                                                          backgroundRepeat: "no-repeat",
                                                          backgroundSize: "contain",
                                                          backgroundPosition: "left",
                                                          paddingTop: 8,
                                                          paddingRight: 20,
                                                          paddingBottom: 8,
                                                          paddingLeft: 20,
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "16px",
                                                          color: "rgba(255, 255, 255, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    ></Button>

                                                    <Button
                                                      id="312407"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.AssignedVehicleViewComponent_312407_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={this.state.isComp312407Visible}
                                                      label={ReactSystemFunctions.translate(
                                                        this.ml,
                                                        312407,
                                                        "label",
                                                        this.defaultML
                                                      )}
                                                      size="middle"
                                                      loading={false}
                                                      ghost={false}
                                                      block={false}
                                                      htmlType="button"
                                                      iconPosition="left"
                                                      danger={false}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 4,
                                                          borderTopRightRadius: 4,
                                                          borderBottomRightRadius: 4,
                                                          borderBottomLeftRadius: 4,
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          backgroundColor: "rgba(22, 193, 243, 1)",
                                                          backgroundRepeat: "no-repeat",
                                                          backgroundSize: "contain",
                                                          backgroundPosition: "left",
                                                          paddingTop: 8,
                                                          paddingRight: 20,
                                                          paddingBottom: 8,
                                                          paddingLeft: 20,
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "16px",
                                                          color: "rgba(255, 255, 255, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    ></Button>
                                                  </KCol>

                                                  <KCol
                                                    id="290949"
                                                    xs={8}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-right",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <Button
                                                      id="377955"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.AssignedVehicleViewComponent_377955_onClick();
                                                      }}
                                                      showCursorPointer
                                                      label={ReactSystemFunctions.translate(
                                                        this.ml,
                                                        377955,
                                                        "label",
                                                        this.defaultML
                                                      )}
                                                      size="middle"
                                                      loading={false}
                                                      ghost={false}
                                                      block={false}
                                                      htmlType="button"
                                                      icon="refresh"
                                                      iconPosition="left"
                                                      danger={false}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 8,
                                                          borderTopRightRadius: 8,
                                                          borderBottomRightRadius: 8,
                                                          borderBottomLeftRadius: 8,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(22, 193, 243, 1)",
                                                          borderStyle: "solid",
                                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                                          backgroundRepeat: "no-repeat",
                                                          backgroundSize: "contain",
                                                          backgroundPosition: "left",
                                                          paddingTop: 4,
                                                          paddingRight: 20,
                                                          paddingBottom: 8,
                                                          paddingLeft: 20,
                                                          height: "32px",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "16px",
                                                          color: "rgba(22, 193, 243, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    ></Button>
                                                  </KCol>
                                                </KRow>

                                                <KRow
                                                  id="602553"
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 8,
                                                      paddingBottom: 0,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="371156"
                                                    xs={24}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="226669"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 12,
                                                          borderTopRightRadius: 12,
                                                          borderBottomRightRadius: 12,
                                                          borderBottomLeftRadius: 12,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(227, 241, 250, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 4,
                                                          paddingRight: 4,
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="163340"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: null,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KRow
                                                          id="890823"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="697088"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <Label
                                                              id="774992"
                                                              value={ReactSystemFunctions.translate(
                                                                this.ml,
                                                                774992,
                                                                "value",
                                                                this.defaultML
                                                              )}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "inline",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 400,
                                                                  fontSize: "12px",
                                                                  color: "rgba(155, 155, 155, 1)",
                                                                  lineHeight: "20px"
                                                                } as any
                                                              }
                                                            ></Label>
                                                          </KCol>
                                                        </KRow>

                                                        <KRow
                                                          id="937383"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="857120"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <KRow
                                                              id="421995"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: null,
                                                                  paddingBottom: null,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="103532"
                                                                xs={14}
                                                                sm={14}
                                                                md={14}
                                                                lg={14}
                                                                xl={14}
                                                                xxl={14}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    paddingTop: 2,
                                                                    paddingRight: 2,
                                                                    paddingBottom: 2,
                                                                    paddingLeft: 2,
                                                                    alignItems: "center",
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <HorizontalStack
                                                                  id="832239"
                                                                  direction="horizontal"
                                                                  size={4}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      alignItems: "center",
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <Icon
                                                                    id="269555"
                                                                    visibility={this.state.isComp269555Visible}
                                                                    iconName="attach_file"
                                                                    style={
                                                                      {
                                                                        borderTopLeftRadius: 100,
                                                                        borderTopRightRadius: 100,
                                                                        borderBottomRightRadius: 100,
                                                                        borderBottomLeftRadius: 100,
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        backgroundColor: "rgba(208, 2, 27, 1)",
                                                                        paddingTop: 6,
                                                                        paddingRight: 4,
                                                                        paddingBottom: 4,
                                                                        paddingLeft: 4,
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-left",
                                                                        display: "inline",
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 500,
                                                                        fontSize: "24px",
                                                                        color: "rgba(255, 255, 255, 1)",
                                                                        letterSpacing: "1px"
                                                                      } as any
                                                                    }
                                                                  ></Icon>

                                                                  <Icon
                                                                    id="646351"
                                                                    visibility={this.state.isComp646351Visible}
                                                                    iconName="attach_file"
                                                                    style={
                                                                      {
                                                                        borderTopLeftRadius: 100,
                                                                        borderTopRightRadius: 100,
                                                                        borderBottomRightRadius: 100,
                                                                        borderBottomLeftRadius: 100,
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        backgroundColor: "rgba(119, 195, 36, 1)",
                                                                        paddingTop: 6,
                                                                        paddingRight: 4,
                                                                        paddingBottom: 4,
                                                                        paddingLeft: 4,
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-left",
                                                                        display: "inline",
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 500,
                                                                        fontSize: "24px",
                                                                        color: "rgba(255, 255, 255, 1)",
                                                                        letterSpacing: "1px"
                                                                      } as any
                                                                    }
                                                                  ></Icon>

                                                                  <VerticalStack
                                                                    id="714118"
                                                                    direction="vertical"
                                                                    size={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <Label
                                                                      id="779908"
                                                                      visibility={this.state.isComp779908Visible}
                                                                      value={ReactSystemFunctions.translate(
                                                                        this.ml,
                                                                        779908,
                                                                        "value",
                                                                        this.defaultML
                                                                      )}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          textAlign: "-webkit-left",
                                                                          display: "inline",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(68, 68, 68, 1)",
                                                                          lineHeight: "22px"
                                                                        } as any
                                                                      }
                                                                    ></Label>

                                                                    <Label
                                                                      id="623164"
                                                                      onClick={(e?: any) => {
                                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                                        this.AssignedVehicleViewComponent_623164_onClick();
                                                                      }}
                                                                      showCursorPointer
                                                                      visibility={this.state.isComp623164Visible}
                                                                      value={
                                                                        this.state.getDocumentsName?.at?.(0)
                                                                          ?.commercialInvoiceName ?? undefined
                                                                      }
                                                                      maxLine={1}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          textAlign: "-webkit-left",
                                                                          display: "inline",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(22, 193, 243, 1)",
                                                                          lineHeight: "22px",
                                                                          textDecoration: "underline"
                                                                        } as any
                                                                      }
                                                                    ></Label>
                                                                  </VerticalStack>
                                                                </HorizontalStack>
                                                              </KCol>

                                                              <KCol
                                                                id="631186"
                                                                xs={10}
                                                                sm={10}
                                                                md={10}
                                                                lg={10}
                                                                xl={10}
                                                                xxl={10}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    paddingTop: 2,
                                                                    paddingRight: 2,
                                                                    paddingBottom: 2,
                                                                    paddingLeft: 2,
                                                                    alignItems: "center",
                                                                    textAlign: "-webkit-center",
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <Button
                                                                  id="739529"
                                                                  onClick={(e?: any) => {
                                                                    if (e && e.stopPropagation) e.stopPropagation();
                                                                    this.AssignedVehicleViewComponent_739529_onClick();
                                                                  }}
                                                                  showCursorPointer
                                                                  visibility={
                                                                    this.state
                                                                      .isCompassignedvehicleview_739529AuthorizationVisible
                                                                  }
                                                                  visibilityByAuthorization={
                                                                    this.state
                                                                      .isCompassignedvehicleview_739529AuthorizationVisible
                                                                  }
                                                                  label={ReactSystemFunctions.translate(
                                                                    this.ml,
                                                                    739529,
                                                                    "label",
                                                                    this.defaultML
                                                                  )}
                                                                  size="middle"
                                                                  loading={false}
                                                                  ghost={false}
                                                                  block={false}
                                                                  htmlType="button"
                                                                  iconPosition="left"
                                                                  danger={false}
                                                                  style={
                                                                    {
                                                                      borderTopLeftRadius: 4,
                                                                      borderTopRightRadius: 4,
                                                                      borderBottomRightRadius: 4,
                                                                      borderBottomLeftRadius: 4,
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      backgroundColor: "rgba(22, 193, 243, 1)",
                                                                      backgroundRepeat: "no-repeat",
                                                                      backgroundSize: "contain",
                                                                      backgroundPosition: "left",
                                                                      paddingTop: 4,
                                                                      paddingRight: 18,
                                                                      paddingBottom: 4,
                                                                      paddingLeft: 18,
                                                                      display: "inline",
                                                                      fontFamily: "Poppins",
                                                                      fontWeight: 500,
                                                                      fontSize: "12px",
                                                                      color: "rgba(255, 255, 255, 1)",
                                                                      lineHeight: "18px"
                                                                    } as any
                                                                  }
                                                                ></Button>
                                                              </KCol>
                                                            </KRow>
                                                          </KCol>
                                                        </KRow>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>

                                                  <KCol
                                                    id="292322"
                                                    xs={24}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="786756"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 12,
                                                          borderTopRightRadius: 12,
                                                          borderBottomRightRadius: 12,
                                                          borderBottomLeftRadius: 12,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(227, 241, 250, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 4,
                                                          paddingRight: 4,
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="713821"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KRow
                                                          id="767356"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="135722"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: null,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <KRow
                                                              id="306552"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="288701"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <HorizontalStack
                                                                  id="190129"
                                                                  direction="horizontal"
                                                                  size={12}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <Label
                                                                    id="196240"
                                                                    value={ReactSystemFunctions.translate(
                                                                      this.ml,
                                                                      196240,
                                                                      "value",
                                                                      this.defaultML
                                                                    )}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-left",
                                                                        display: "inline",
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 400,
                                                                        fontSize: "12px",
                                                                        color: "rgba(155, 155, 155, 1)",
                                                                        lineHeight: "20px"
                                                                      } as any
                                                                    }
                                                                  ></Label>

                                                                  <Label
                                                                    id="612905"
                                                                    value={
                                                                      this.state.sqlGetVehicleRequestById?.at?.(0)
                                                                        ?.edeliveryNo ?? undefined
                                                                    }
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-left",
                                                                        display: "inline",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "12px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  ></Label>
                                                                </HorizontalStack>
                                                              </KCol>
                                                            </KRow>

                                                            <KRow
                                                              id="866398"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="799151"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <KRow
                                                                  id="19356"
                                                                  align="top"
                                                                  justify="start"
                                                                  horizontalGutter={0}
                                                                  verticalGutter={0}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      paddingTop: null,
                                                                      paddingBottom: null,
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <KCol
                                                                    id="987094"
                                                                    xs={14}
                                                                    sm={14}
                                                                    md={14}
                                                                    lg={14}
                                                                    xl={14}
                                                                    xxl={14}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <HorizontalStack
                                                                      id="815830"
                                                                      direction="horizontal"
                                                                      size={4}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(68, 68, 68, 1)",
                                                                          lineHeight: "22px"
                                                                        } as any
                                                                      }
                                                                    >
                                                                      <Icon
                                                                        id="843310"
                                                                        visibility={this.state.isComp843310Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(208, 2, 27, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <Icon
                                                                        id="671036"
                                                                        visibility={this.state.isComp671036Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(119, 195, 36, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <VerticalStack
                                                                        id="172277"
                                                                        direction="vertical"
                                                                        size={0}
                                                                        style={
                                                                          {
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            fontFamily: "Roboto",
                                                                            fontWeight: 500,
                                                                            fontSize: "14px",
                                                                            color: "rgba(68, 68, 68, 1)",
                                                                            lineHeight: "22px"
                                                                          } as any
                                                                        }
                                                                      >
                                                                        <Label
                                                                          id="178060"
                                                                          visibility={this.state.isComp178060Visible}
                                                                          value={ReactSystemFunctions.translate(
                                                                            this.ml,
                                                                            178060,
                                                                            "value",
                                                                            this.defaultML
                                                                          )}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(68, 68, 68, 1)",
                                                                              lineHeight: "22px"
                                                                            } as any
                                                                          }
                                                                        ></Label>

                                                                        <Label
                                                                          id="826937"
                                                                          onClick={(e?: any) => {
                                                                            if (e && e.stopPropagation)
                                                                              e.stopPropagation();
                                                                            this.AssignedVehicleViewComponent_826937_onClick();
                                                                          }}
                                                                          showCursorPointer
                                                                          visibility={this.state.isComp826937Visible}
                                                                          value={
                                                                            this.state.getDocumentsName?.at?.(0)
                                                                              ?.irsaliyeName ?? undefined
                                                                          }
                                                                          maxLine={1}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(22, 193, 243, 1)",
                                                                              lineHeight: "22px",
                                                                              textDecoration: "underline"
                                                                            } as any
                                                                          }
                                                                        ></Label>
                                                                      </VerticalStack>
                                                                    </HorizontalStack>
                                                                  </KCol>

                                                                  <KCol
                                                                    id="825288"
                                                                    xs={10}
                                                                    sm={10}
                                                                    md={10}
                                                                    lg={10}
                                                                    xl={10}
                                                                    xxl={10}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <Button
                                                                      id="259412"
                                                                      onClick={(e?: any) => {
                                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                                        this.AssignedVehicleViewComponent_259412_onClick();
                                                                      }}
                                                                      showCursorPointer
                                                                      visibility={
                                                                        this.state
                                                                          .isCompassignedvehicleview_259412AuthorizationVisible
                                                                      }
                                                                      visibilityByAuthorization={
                                                                        this.state
                                                                          .isCompassignedvehicleview_259412AuthorizationVisible
                                                                      }
                                                                      label={ReactSystemFunctions.translate(
                                                                        this.ml,
                                                                        259412,
                                                                        "label",
                                                                        this.defaultML
                                                                      )}
                                                                      size="middle"
                                                                      loading={false}
                                                                      ghost={false}
                                                                      block={false}
                                                                      htmlType="button"
                                                                      iconPosition="left"
                                                                      danger={false}
                                                                      style={
                                                                        {
                                                                          borderTopLeftRadius: 4,
                                                                          borderTopRightRadius: 4,
                                                                          borderBottomRightRadius: 4,
                                                                          borderBottomLeftRadius: 4,
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          backgroundColor: "rgba(22, 193, 243, 1)",
                                                                          backgroundRepeat: "no-repeat",
                                                                          backgroundSize: "contain",
                                                                          backgroundPosition: "left",
                                                                          paddingTop: 4,
                                                                          paddingRight: 18,
                                                                          paddingBottom: 4,
                                                                          paddingLeft: 18,
                                                                          display: "inline",
                                                                          fontFamily: "Poppins",
                                                                          fontWeight: 500,
                                                                          fontSize: "12px",
                                                                          color: "rgba(255, 255, 255, 1)",
                                                                          lineHeight: "18px"
                                                                        } as any
                                                                      }
                                                                    ></Button>
                                                                  </KCol>
                                                                </KRow>
                                                              </KCol>
                                                            </KRow>
                                                          </KCol>
                                                        </KRow>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>

                                                  <KCol
                                                    id="600895"
                                                    xs={24}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="704589"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 12,
                                                          borderTopRightRadius: 12,
                                                          borderBottomRightRadius: 12,
                                                          borderBottomLeftRadius: 12,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(227, 241, 250, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 4,
                                                          paddingRight: 4,
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="47648"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: null,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KRow
                                                          id="955370"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="272044"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <Label
                                                              id="306440"
                                                              value={ReactSystemFunctions.translate(
                                                                this.ml,
                                                                306440,
                                                                "value",
                                                                this.defaultML
                                                              )}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "inline",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 400,
                                                                  fontSize: "12px",
                                                                  color: "rgba(155, 155, 155, 1)",
                                                                  lineHeight: "20px"
                                                                } as any
                                                              }
                                                            ></Label>
                                                          </KCol>
                                                        </KRow>

                                                        <KRow
                                                          id="636446"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="387793"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <KRow
                                                              id="929566"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: null,
                                                                  paddingBottom: null,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="187049"
                                                                xs={14}
                                                                sm={14}
                                                                md={14}
                                                                lg={14}
                                                                xl={14}
                                                                xxl={14}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    paddingTop: 2,
                                                                    paddingRight: 2,
                                                                    paddingBottom: 2,
                                                                    paddingLeft: 2,
                                                                    alignItems: "center",
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <HorizontalStack
                                                                  id="154674"
                                                                  direction="horizontal"
                                                                  size={4}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      alignItems: "center",
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <Icon
                                                                    id="213809"
                                                                    visibility={this.state.isComp213809Visible}
                                                                    iconName="attach_file"
                                                                    style={
                                                                      {
                                                                        borderTopLeftRadius: 100,
                                                                        borderTopRightRadius: 100,
                                                                        borderBottomRightRadius: 100,
                                                                        borderBottomLeftRadius: 100,
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        backgroundColor: "rgba(208, 2, 27, 1)",
                                                                        paddingTop: 6,
                                                                        paddingRight: 4,
                                                                        paddingBottom: 4,
                                                                        paddingLeft: 4,
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-left",
                                                                        display: "inline",
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 500,
                                                                        fontSize: "24px",
                                                                        color: "rgba(255, 255, 255, 1)",
                                                                        letterSpacing: "1px"
                                                                      } as any
                                                                    }
                                                                  ></Icon>

                                                                  <Icon
                                                                    id="271114"
                                                                    visibility={this.state.isComp271114Visible}
                                                                    iconName="attach_file"
                                                                    style={
                                                                      {
                                                                        borderTopLeftRadius: 100,
                                                                        borderTopRightRadius: 100,
                                                                        borderBottomRightRadius: 100,
                                                                        borderBottomLeftRadius: 100,
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        backgroundColor: "rgba(119, 195, 36, 1)",
                                                                        paddingTop: 6,
                                                                        paddingRight: 4,
                                                                        paddingBottom: 4,
                                                                        paddingLeft: 4,
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-left",
                                                                        display: "inline",
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 500,
                                                                        fontSize: "24px",
                                                                        color: "rgba(255, 255, 255, 1)",
                                                                        letterSpacing: "1px"
                                                                      } as any
                                                                    }
                                                                  ></Icon>

                                                                  <VerticalStack
                                                                    id="490472"
                                                                    direction="vertical"
                                                                    size={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <Label
                                                                      id="278091"
                                                                      visibility={this.state.isComp278091Visible}
                                                                      value={ReactSystemFunctions.translate(
                                                                        this.ml,
                                                                        278091,
                                                                        "value",
                                                                        this.defaultML
                                                                      )}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          textAlign: "-webkit-left",
                                                                          display: "inline",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(68, 68, 68, 1)",
                                                                          lineHeight: "22px"
                                                                        } as any
                                                                      }
                                                                    ></Label>

                                                                    <Label
                                                                      id="898310"
                                                                      onClick={(e?: any) => {
                                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                                        this.AssignedVehicleViewComponent_898310_onClick();
                                                                      }}
                                                                      showCursorPointer
                                                                      visibility={this.state.isComp898310Visible}
                                                                      value={
                                                                        this.state.getDocumentsName?.at?.(0)
                                                                          ?.efaturaName ?? undefined
                                                                      }
                                                                      maxLine={1}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          textAlign: "-webkit-left",
                                                                          display: "inline",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(22, 193, 243, 1)",
                                                                          lineHeight: "22px",
                                                                          textDecoration: "underline"
                                                                        } as any
                                                                      }
                                                                    ></Label>
                                                                  </VerticalStack>
                                                                </HorizontalStack>
                                                              </KCol>

                                                              <KCol
                                                                id="839175"
                                                                xs={10}
                                                                sm={10}
                                                                md={10}
                                                                lg={10}
                                                                xl={10}
                                                                xxl={10}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    paddingTop: 2,
                                                                    paddingRight: 2,
                                                                    paddingBottom: 2,
                                                                    paddingLeft: 2,
                                                                    alignItems: "center",
                                                                    textAlign: "-webkit-center",
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <Button
                                                                  id="426133"
                                                                  onClick={(e?: any) => {
                                                                    if (e && e.stopPropagation) e.stopPropagation();
                                                                    this.AssignedVehicleViewComponent_426133_onClick();
                                                                  }}
                                                                  showCursorPointer
                                                                  visibility={
                                                                    this.state
                                                                      .isCompassignedvehicleview_426133AuthorizationVisible
                                                                  }
                                                                  visibilityByAuthorization={
                                                                    this.state
                                                                      .isCompassignedvehicleview_426133AuthorizationVisible
                                                                  }
                                                                  label={ReactSystemFunctions.translate(
                                                                    this.ml,
                                                                    426133,
                                                                    "label",
                                                                    this.defaultML
                                                                  )}
                                                                  size="middle"
                                                                  loading={false}
                                                                  ghost={false}
                                                                  block={false}
                                                                  htmlType="button"
                                                                  iconPosition="left"
                                                                  danger={false}
                                                                  style={
                                                                    {
                                                                      borderTopLeftRadius: 4,
                                                                      borderTopRightRadius: 4,
                                                                      borderBottomRightRadius: 4,
                                                                      borderBottomLeftRadius: 4,
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      backgroundColor: "rgba(22, 193, 243, 1)",
                                                                      backgroundRepeat: "no-repeat",
                                                                      backgroundSize: "contain",
                                                                      backgroundPosition: "left",
                                                                      paddingTop: 4,
                                                                      paddingRight: 18,
                                                                      paddingBottom: 4,
                                                                      paddingLeft: 18,
                                                                      display: "inline",
                                                                      fontFamily: "Poppins",
                                                                      fontWeight: 500,
                                                                      fontSize: "12px",
                                                                      color: "rgba(255, 255, 255, 1)",
                                                                      lineHeight: "18px"
                                                                    } as any
                                                                  }
                                                                ></Button>
                                                              </KCol>
                                                            </KRow>
                                                          </KCol>
                                                        </KRow>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>
                                                </KRow>

                                                <KRow
                                                  id="616048"
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 8,
                                                      paddingBottom: 0,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="70491"
                                                    xs={8}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="743975"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 12,
                                                          borderTopRightRadius: 12,
                                                          borderBottomRightRadius: 12,
                                                          borderBottomLeftRadius: 12,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(227, 241, 250, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 4,
                                                          paddingRight: 4,
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="974204"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KRow
                                                          id="841371"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="360333"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: null,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <KRow
                                                              id="435647"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="566830"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <Label
                                                                  id="213239"
                                                                  value={ReactSystemFunctions.translate(
                                                                    this.ml,
                                                                    213239,
                                                                    "value",
                                                                    this.defaultML
                                                                  )}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      alignItems: "center",
                                                                      textAlign: "-webkit-left",
                                                                      display: "inline",
                                                                      fontFamily: "Poppins",
                                                                      fontWeight: 400,
                                                                      fontSize: "12px",
                                                                      color: "rgba(155, 155, 155, 1)",
                                                                      lineHeight: "20px"
                                                                    } as any
                                                                  }
                                                                ></Label>
                                                              </KCol>
                                                            </KRow>

                                                            <KRow
                                                              id="336079"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="910267"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <KRow
                                                                  id="541392"
                                                                  align="top"
                                                                  justify="start"
                                                                  horizontalGutter={0}
                                                                  verticalGutter={0}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      paddingTop: null,
                                                                      paddingBottom: null,
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <KCol
                                                                    id="846900"
                                                                    xs={14}
                                                                    sm={14}
                                                                    md={14}
                                                                    lg={14}
                                                                    xl={14}
                                                                    xxl={14}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <HorizontalStack
                                                                      id="423183"
                                                                      direction="horizontal"
                                                                      size={4}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(68, 68, 68, 1)",
                                                                          lineHeight: "22px"
                                                                        } as any
                                                                      }
                                                                    >
                                                                      <Icon
                                                                        id="352849"
                                                                        visibility={this.state.isComp352849Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(208, 2, 27, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <Icon
                                                                        id="572531"
                                                                        visibility={this.state.isComp572531Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(119, 195, 36, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <VerticalStack
                                                                        id="541312"
                                                                        direction="vertical"
                                                                        size={0}
                                                                        style={
                                                                          {
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            fontFamily: "Roboto",
                                                                            fontWeight: 500,
                                                                            fontSize: "14px",
                                                                            color: "rgba(68, 68, 68, 1)",
                                                                            lineHeight: "22px"
                                                                          } as any
                                                                        }
                                                                      >
                                                                        <Label
                                                                          id="346444"
                                                                          visibility={this.state.isComp346444Visible}
                                                                          value={ReactSystemFunctions.translate(
                                                                            this.ml,
                                                                            346444,
                                                                            "value",
                                                                            this.defaultML
                                                                          )}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(68, 68, 68, 1)",
                                                                              lineHeight: "22px"
                                                                            } as any
                                                                          }
                                                                        ></Label>

                                                                        <Label
                                                                          id="966119"
                                                                          onClick={(e?: any) => {
                                                                            if (e && e.stopPropagation)
                                                                              e.stopPropagation();
                                                                            this.AssignedVehicleViewComponent_966119_onClick();
                                                                          }}
                                                                          showCursorPointer
                                                                          visibility={this.state.isComp966119Visible}
                                                                          value={
                                                                            this.state.getDocumentsName?.at?.(0)
                                                                              ?.certificateOfAnalysisName ?? undefined
                                                                          }
                                                                          maxLine={1}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(22, 193, 243, 1)",
                                                                              lineHeight: "22px",
                                                                              textDecoration: "underline"
                                                                            } as any
                                                                          }
                                                                        ></Label>
                                                                      </VerticalStack>
                                                                    </HorizontalStack>
                                                                  </KCol>

                                                                  <KCol
                                                                    id="901406"
                                                                    xs={10}
                                                                    sm={10}
                                                                    md={10}
                                                                    lg={10}
                                                                    xl={10}
                                                                    xxl={10}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <Button
                                                                      id="171620"
                                                                      onClick={(e?: any) => {
                                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                                        this.AssignedVehicleViewComponent_171620_onClick();
                                                                      }}
                                                                      showCursorPointer
                                                                      visibility={
                                                                        this.state
                                                                          .isCompassignedvehicleview_171620AuthorizationVisible
                                                                      }
                                                                      visibilityByAuthorization={
                                                                        this.state
                                                                          .isCompassignedvehicleview_171620AuthorizationVisible
                                                                      }
                                                                      label={ReactSystemFunctions.translate(
                                                                        this.ml,
                                                                        171620,
                                                                        "label",
                                                                        this.defaultML
                                                                      )}
                                                                      size="middle"
                                                                      loading={false}
                                                                      ghost={false}
                                                                      block={false}
                                                                      htmlType="button"
                                                                      iconPosition="left"
                                                                      danger={false}
                                                                      style={
                                                                        {
                                                                          borderTopLeftRadius: 4,
                                                                          borderTopRightRadius: 4,
                                                                          borderBottomRightRadius: 4,
                                                                          borderBottomLeftRadius: 4,
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          backgroundColor: "rgba(22, 193, 243, 1)",
                                                                          backgroundRepeat: "no-repeat",
                                                                          backgroundSize: "contain",
                                                                          backgroundPosition: "left",
                                                                          paddingTop: 4,
                                                                          paddingRight: 18,
                                                                          paddingBottom: 4,
                                                                          paddingLeft: 18,
                                                                          display: "inline",
                                                                          fontFamily: "Poppins",
                                                                          fontWeight: 500,
                                                                          fontSize: "12px",
                                                                          color: "rgba(255, 255, 255, 1)",
                                                                          lineHeight: "18px"
                                                                        } as any
                                                                      }
                                                                    ></Button>
                                                                  </KCol>
                                                                </KRow>
                                                              </KCol>
                                                            </KRow>
                                                          </KCol>
                                                        </KRow>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>

                                                  <KCol
                                                    id="654258"
                                                    xs={8}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="904623"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 12,
                                                          borderTopRightRadius: 12,
                                                          borderBottomRightRadius: 12,
                                                          borderBottomLeftRadius: 12,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(227, 241, 250, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 4,
                                                          paddingRight: 4,
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="707511"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KRow
                                                          id="20067"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="819564"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: null,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <KRow
                                                              id="627465"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="191133"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <Label
                                                                  id="125562"
                                                                  value={ReactSystemFunctions.translate(
                                                                    this.ml,
                                                                    125562,
                                                                    "value",
                                                                    this.defaultML
                                                                  )}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      alignItems: "center",
                                                                      textAlign: "-webkit-left",
                                                                      display: "inline",
                                                                      fontFamily: "Poppins",
                                                                      fontWeight: 400,
                                                                      fontSize: "12px",
                                                                      color: "rgba(155, 155, 155, 1)",
                                                                      lineHeight: "20px"
                                                                    } as any
                                                                  }
                                                                ></Label>
                                                              </KCol>
                                                            </KRow>

                                                            <KRow
                                                              id="535345"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="315465"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <KRow
                                                                  id="184916"
                                                                  align="top"
                                                                  justify="start"
                                                                  horizontalGutter={0}
                                                                  verticalGutter={0}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      paddingTop: null,
                                                                      paddingBottom: null,
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <KCol
                                                                    id="434256"
                                                                    xs={14}
                                                                    sm={14}
                                                                    md={14}
                                                                    lg={14}
                                                                    xl={14}
                                                                    xxl={14}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <HorizontalStack
                                                                      id="289188"
                                                                      direction="horizontal"
                                                                      size={4}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(68, 68, 68, 1)",
                                                                          lineHeight: "22px"
                                                                        } as any
                                                                      }
                                                                    >
                                                                      <Icon
                                                                        id="668484"
                                                                        visibility={this.state.isComp668484Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(208, 2, 27, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <Icon
                                                                        id="126965"
                                                                        visibility={this.state.isComp126965Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(119, 195, 36, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <VerticalStack
                                                                        id="755077"
                                                                        direction="vertical"
                                                                        size={0}
                                                                        style={
                                                                          {
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            fontFamily: "Roboto",
                                                                            fontWeight: 500,
                                                                            fontSize: "14px",
                                                                            color: "rgba(68, 68, 68, 1)",
                                                                            lineHeight: "22px"
                                                                          } as any
                                                                        }
                                                                      >
                                                                        <Label
                                                                          id="846973"
                                                                          visibility={this.state.isComp846973Visible}
                                                                          value={ReactSystemFunctions.translate(
                                                                            this.ml,
                                                                            846973,
                                                                            "value",
                                                                            this.defaultML
                                                                          )}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(68, 68, 68, 1)",
                                                                              lineHeight: "22px"
                                                                            } as any
                                                                          }
                                                                        ></Label>

                                                                        <Label
                                                                          id="715893"
                                                                          onClick={(e?: any) => {
                                                                            if (e && e.stopPropagation)
                                                                              e.stopPropagation();
                                                                            this.AssignedVehicleViewComponent_715893_onClick();
                                                                          }}
                                                                          showCursorPointer
                                                                          visibility={this.state.isComp715893Visible}
                                                                          value={
                                                                            this.state.getDocumentsName?.at?.(0)
                                                                              ?.lotBasedAnalysisName ?? undefined
                                                                          }
                                                                          maxLine={1}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(22, 193, 243, 1)",
                                                                              lineHeight: "22px",
                                                                              textDecoration: "underline"
                                                                            } as any
                                                                          }
                                                                        ></Label>
                                                                      </VerticalStack>
                                                                    </HorizontalStack>
                                                                  </KCol>

                                                                  <KCol
                                                                    id="10471"
                                                                    xs={10}
                                                                    sm={10}
                                                                    md={10}
                                                                    lg={10}
                                                                    xl={10}
                                                                    xxl={10}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <Button
                                                                      id="843160"
                                                                      onClick={(e?: any) => {
                                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                                        this.AssignedVehicleViewComponent_843160_onClick();
                                                                      }}
                                                                      showCursorPointer
                                                                      visibility={
                                                                        this.state
                                                                          .isCompassignedvehicleview_843160AuthorizationVisible
                                                                      }
                                                                      visibilityByAuthorization={
                                                                        this.state
                                                                          .isCompassignedvehicleview_843160AuthorizationVisible
                                                                      }
                                                                      label={ReactSystemFunctions.translate(
                                                                        this.ml,
                                                                        843160,
                                                                        "label",
                                                                        this.defaultML
                                                                      )}
                                                                      size="middle"
                                                                      loading={false}
                                                                      ghost={false}
                                                                      block={false}
                                                                      htmlType="button"
                                                                      iconPosition="left"
                                                                      danger={false}
                                                                      style={
                                                                        {
                                                                          borderTopLeftRadius: 4,
                                                                          borderTopRightRadius: 4,
                                                                          borderBottomRightRadius: 4,
                                                                          borderBottomLeftRadius: 4,
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          backgroundColor: "rgba(22, 193, 243, 1)",
                                                                          backgroundRepeat: "no-repeat",
                                                                          backgroundSize: "contain",
                                                                          backgroundPosition: "left",
                                                                          paddingTop: 4,
                                                                          paddingRight: 18,
                                                                          paddingBottom: 4,
                                                                          paddingLeft: 18,
                                                                          display: "inline",
                                                                          fontFamily: "Poppins",
                                                                          fontWeight: 500,
                                                                          fontSize: "12px",
                                                                          color: "rgba(255, 255, 255, 1)",
                                                                          lineHeight: "18px"
                                                                        } as any
                                                                      }
                                                                    ></Button>
                                                                  </KCol>
                                                                </KRow>
                                                              </KCol>
                                                            </KRow>
                                                          </KCol>
                                                        </KRow>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>

                                                  <KCol
                                                    id="711373"
                                                    xs={8}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></KCol>
                                                </KRow>

                                                <KRow
                                                  id="840014"
                                                  visibility={this.state.isComp840014Visible}
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 8,
                                                      paddingBottom: 0,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="151637"
                                                    xs={24}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="375004"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 12,
                                                          borderTopRightRadius: 12,
                                                          borderBottomRightRadius: 12,
                                                          borderBottomLeftRadius: 12,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(227, 241, 250, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 4,
                                                          paddingRight: 4,
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="984023"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KRow
                                                          id="935659"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="869907"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: null,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <KRow
                                                              id="275670"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="861562"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <Label
                                                                  id="913054"
                                                                  value={ReactSystemFunctions.translate(
                                                                    this.ml,
                                                                    913054,
                                                                    "value",
                                                                    this.defaultML
                                                                  )}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      alignItems: "center",
                                                                      textAlign: "-webkit-left",
                                                                      display: "inline",
                                                                      fontFamily: "Poppins",
                                                                      fontWeight: 400,
                                                                      fontSize: "12px",
                                                                      color: "rgba(155, 155, 155, 1)",
                                                                      lineHeight: "20px"
                                                                    } as any
                                                                  }
                                                                ></Label>
                                                              </KCol>
                                                            </KRow>

                                                            <KRow
                                                              id="35789"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="467080"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <KRow
                                                                  id="554997"
                                                                  align="top"
                                                                  justify="start"
                                                                  horizontalGutter={0}
                                                                  verticalGutter={0}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      paddingTop: null,
                                                                      paddingBottom: null,
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <KCol
                                                                    id="442669"
                                                                    xs={14}
                                                                    sm={14}
                                                                    md={14}
                                                                    lg={14}
                                                                    xl={14}
                                                                    xxl={14}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <HorizontalStack
                                                                      id="963761"
                                                                      direction="horizontal"
                                                                      size={4}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(68, 68, 68, 1)",
                                                                          lineHeight: "22px"
                                                                        } as any
                                                                      }
                                                                    >
                                                                      <Icon
                                                                        id="77376"
                                                                        visibility={this.state.isComp77376Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(208, 2, 27, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <Icon
                                                                        id="830625"
                                                                        visibility={this.state.isComp830625Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(119, 195, 36, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <VerticalStack
                                                                        id="84633"
                                                                        direction="vertical"
                                                                        size={0}
                                                                        style={
                                                                          {
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            fontFamily: "Roboto",
                                                                            fontWeight: 500,
                                                                            fontSize: "14px",
                                                                            color: "rgba(68, 68, 68, 1)",
                                                                            lineHeight: "22px"
                                                                          } as any
                                                                        }
                                                                      >
                                                                        <Label
                                                                          id="289446"
                                                                          visibility={this.state.isComp289446Visible}
                                                                          value={ReactSystemFunctions.translate(
                                                                            this.ml,
                                                                            289446,
                                                                            "value",
                                                                            this.defaultML
                                                                          )}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(68, 68, 68, 1)",
                                                                              lineHeight: "22px"
                                                                            } as any
                                                                          }
                                                                        ></Label>

                                                                        <Label
                                                                          id="360611"
                                                                          onClick={(e?: any) => {
                                                                            if (e && e.stopPropagation)
                                                                              e.stopPropagation();
                                                                            this.AssignedVehicleViewComponent_360611_onClick();
                                                                          }}
                                                                          showCursorPointer
                                                                          visibility={this.state.isComp360611Visible}
                                                                          value={
                                                                            this.state.getDocumentsName?.at?.(0)
                                                                              ?.packingListName ?? undefined
                                                                          }
                                                                          maxLine={1}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(22, 193, 243, 1)",
                                                                              lineHeight: "22px",
                                                                              textDecoration: "underline"
                                                                            } as any
                                                                          }
                                                                        ></Label>
                                                                      </VerticalStack>
                                                                    </HorizontalStack>
                                                                  </KCol>

                                                                  <KCol
                                                                    id="387059"
                                                                    xs={10}
                                                                    sm={10}
                                                                    md={10}
                                                                    lg={10}
                                                                    xl={10}
                                                                    xxl={10}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <Button
                                                                      id="632668"
                                                                      onClick={(e?: any) => {
                                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                                        this.AssignedVehicleViewComponent_632668_onClick();
                                                                      }}
                                                                      showCursorPointer
                                                                      visibility={
                                                                        this.state
                                                                          .isCompassignedvehicleview_632668AuthorizationVisible
                                                                      }
                                                                      visibilityByAuthorization={
                                                                        this.state
                                                                          .isCompassignedvehicleview_632668AuthorizationVisible
                                                                      }
                                                                      label={ReactSystemFunctions.translate(
                                                                        this.ml,
                                                                        632668,
                                                                        "label",
                                                                        this.defaultML
                                                                      )}
                                                                      size="middle"
                                                                      loading={false}
                                                                      ghost={false}
                                                                      block={false}
                                                                      htmlType="button"
                                                                      iconPosition="left"
                                                                      danger={false}
                                                                      style={
                                                                        {
                                                                          borderTopLeftRadius: 4,
                                                                          borderTopRightRadius: 4,
                                                                          borderBottomRightRadius: 4,
                                                                          borderBottomLeftRadius: 4,
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          backgroundColor: "rgba(22, 193, 243, 1)",
                                                                          backgroundRepeat: "no-repeat",
                                                                          backgroundSize: "contain",
                                                                          backgroundPosition: "left",
                                                                          paddingTop: 4,
                                                                          paddingRight: 18,
                                                                          paddingBottom: 4,
                                                                          paddingLeft: 18,
                                                                          display: "inline",
                                                                          fontFamily: "Poppins",
                                                                          fontWeight: 500,
                                                                          fontSize: "12px",
                                                                          color: "rgba(255, 255, 255, 1)",
                                                                          lineHeight: "18px"
                                                                        } as any
                                                                      }
                                                                    ></Button>
                                                                  </KCol>
                                                                </KRow>
                                                              </KCol>
                                                            </KRow>
                                                          </KCol>
                                                        </KRow>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>

                                                  <KCol
                                                    id="859242"
                                                    xs={24}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="88675"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 12,
                                                          borderTopRightRadius: 12,
                                                          borderBottomRightRadius: 12,
                                                          borderBottomLeftRadius: 12,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(227, 241, 250, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 4,
                                                          paddingRight: 4,
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="845819"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: null,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KRow
                                                          id="223727"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="233492"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <Label
                                                              id="569037"
                                                              value={ReactSystemFunctions.translate(
                                                                this.ml,
                                                                569037,
                                                                "value",
                                                                this.defaultML
                                                              )}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "inline",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 400,
                                                                  fontSize: "12px",
                                                                  color: "rgba(155, 155, 155, 1)",
                                                                  lineHeight: "20px"
                                                                } as any
                                                              }
                                                            ></Label>
                                                          </KCol>
                                                        </KRow>

                                                        <KRow
                                                          id="362882"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="949335"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <KRow
                                                              id="728619"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: null,
                                                                  paddingBottom: null,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="279173"
                                                                xs={14}
                                                                sm={14}
                                                                md={14}
                                                                lg={14}
                                                                xl={14}
                                                                xxl={14}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    paddingTop: 2,
                                                                    paddingRight: 2,
                                                                    paddingBottom: 2,
                                                                    paddingLeft: 2,
                                                                    alignItems: "center",
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <HorizontalStack
                                                                  id="952986"
                                                                  direction="horizontal"
                                                                  size={4}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      alignItems: "center",
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <Icon
                                                                    id="307591"
                                                                    visibility={this.state.isComp307591Visible}
                                                                    iconName="attach_file"
                                                                    style={
                                                                      {
                                                                        borderTopLeftRadius: 100,
                                                                        borderTopRightRadius: 100,
                                                                        borderBottomRightRadius: 100,
                                                                        borderBottomLeftRadius: 100,
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        backgroundColor: "rgba(208, 2, 27, 1)",
                                                                        paddingTop: 6,
                                                                        paddingRight: 4,
                                                                        paddingBottom: 4,
                                                                        paddingLeft: 4,
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-left",
                                                                        display: "inline",
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 500,
                                                                        fontSize: "24px",
                                                                        color: "rgba(255, 255, 255, 1)",
                                                                        letterSpacing: "1px"
                                                                      } as any
                                                                    }
                                                                  ></Icon>

                                                                  <Icon
                                                                    id="372986"
                                                                    visibility={this.state.isComp372986Visible}
                                                                    iconName="attach_file"
                                                                    style={
                                                                      {
                                                                        borderTopLeftRadius: 100,
                                                                        borderTopRightRadius: 100,
                                                                        borderBottomRightRadius: 100,
                                                                        borderBottomLeftRadius: 100,
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        backgroundColor: "rgba(119, 195, 36, 1)",
                                                                        paddingTop: 6,
                                                                        paddingRight: 4,
                                                                        paddingBottom: 4,
                                                                        paddingLeft: 4,
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-left",
                                                                        display: "inline",
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 500,
                                                                        fontSize: "24px",
                                                                        color: "rgba(255, 255, 255, 1)",
                                                                        letterSpacing: "1px"
                                                                      } as any
                                                                    }
                                                                  ></Icon>

                                                                  <VerticalStack
                                                                    id="145476"
                                                                    direction="vertical"
                                                                    size={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <Label
                                                                      id="245535"
                                                                      visibility={this.state.isComp245535Visible}
                                                                      value={ReactSystemFunctions.translate(
                                                                        this.ml,
                                                                        245535,
                                                                        "value",
                                                                        this.defaultML
                                                                      )}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          textAlign: "-webkit-left",
                                                                          display: "inline",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(68, 68, 68, 1)",
                                                                          lineHeight: "22px"
                                                                        } as any
                                                                      }
                                                                    ></Label>

                                                                    <Label
                                                                      id="421537"
                                                                      onClick={(e?: any) => {
                                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                                        this.AssignedVehicleViewComponent_421537_onClick();
                                                                      }}
                                                                      showCursorPointer
                                                                      visibility={this.state.isComp421537Visible}
                                                                      value={
                                                                        this.state.getDocumentsName?.at?.(0)
                                                                          ?.truckDriverName ?? undefined
                                                                      }
                                                                      maxLine={1}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          textAlign: "-webkit-left",
                                                                          display: "inline",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(22, 193, 243, 1)",
                                                                          lineHeight: "22px",
                                                                          textDecoration: "underline"
                                                                        } as any
                                                                      }
                                                                    ></Label>
                                                                  </VerticalStack>
                                                                </HorizontalStack>
                                                              </KCol>

                                                              <KCol
                                                                id="156109"
                                                                xs={10}
                                                                sm={10}
                                                                md={10}
                                                                lg={10}
                                                                xl={10}
                                                                xxl={10}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    paddingTop: 2,
                                                                    paddingRight: 2,
                                                                    paddingBottom: 2,
                                                                    paddingLeft: 2,
                                                                    alignItems: "center",
                                                                    textAlign: "-webkit-center",
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <Button
                                                                  id="9095"
                                                                  onClick={(e?: any) => {
                                                                    if (e && e.stopPropagation) e.stopPropagation();
                                                                    this.AssignedVehicleViewComponent_9095_onClick();
                                                                  }}
                                                                  showCursorPointer
                                                                  visibility={
                                                                    this.state
                                                                      .isCompassignedvehicleview_9095AuthorizationVisible
                                                                  }
                                                                  visibilityByAuthorization={
                                                                    this.state
                                                                      .isCompassignedvehicleview_9095AuthorizationVisible
                                                                  }
                                                                  label={ReactSystemFunctions.translate(
                                                                    this.ml,
                                                                    9095,
                                                                    "label",
                                                                    this.defaultML
                                                                  )}
                                                                  size="middle"
                                                                  loading={false}
                                                                  ghost={false}
                                                                  block={false}
                                                                  htmlType="button"
                                                                  iconPosition="left"
                                                                  danger={false}
                                                                  style={
                                                                    {
                                                                      borderTopLeftRadius: 4,
                                                                      borderTopRightRadius: 4,
                                                                      borderBottomRightRadius: 4,
                                                                      borderBottomLeftRadius: 4,
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      backgroundColor: "rgba(22, 193, 243, 1)",
                                                                      backgroundRepeat: "no-repeat",
                                                                      backgroundSize: "contain",
                                                                      backgroundPosition: "left",
                                                                      paddingTop: 4,
                                                                      paddingRight: 18,
                                                                      paddingBottom: 4,
                                                                      paddingLeft: 18,
                                                                      display: "inline",
                                                                      fontFamily: "Poppins",
                                                                      fontWeight: 500,
                                                                      fontSize: "12px",
                                                                      color: "rgba(255, 255, 255, 1)",
                                                                      lineHeight: "18px"
                                                                    } as any
                                                                  }
                                                                ></Button>
                                                              </KCol>
                                                            </KRow>
                                                          </KCol>
                                                        </KRow>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>

                                                  <KCol
                                                    id="875224"
                                                    xs={24}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></KCol>
                                                </KRow>

                                                <KRow
                                                  id="188011"
                                                  visibility={
                                                    this.state.isComp188011Visible == "hidden" ||
                                                    this.state.isCompassignedvehicleview_188011AuthorizationVisible ==
                                                      "hidden"
                                                      ? "hidden"
                                                      : "visible"
                                                  }
                                                  visibilityByAuthorization={
                                                    this.state.isCompassignedvehicleview_188011AuthorizationVisible ==
                                                    "hidden"
                                                      ? "hidden"
                                                      : "visible"
                                                  }
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 8,
                                                      paddingBottom: 0,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="259535"
                                                    xs={24}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="422110"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 12,
                                                          borderTopRightRadius: 12,
                                                          borderBottomRightRadius: 12,
                                                          borderBottomLeftRadius: 12,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(227, 241, 250, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 4,
                                                          paddingRight: 4,
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="795960"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KRow
                                                          id="748440"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="856692"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: null,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <KRow
                                                              id="86117"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="506745"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <Label
                                                                  id="113482"
                                                                  value={ReactSystemFunctions.translate(
                                                                    this.ml,
                                                                    113482,
                                                                    "value",
                                                                    this.defaultML
                                                                  )}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      alignItems: "center",
                                                                      textAlign: "-webkit-left",
                                                                      display: "inline",
                                                                      fontFamily: "Poppins",
                                                                      fontWeight: 400,
                                                                      fontSize: "12px",
                                                                      color: "rgba(155, 155, 155, 1)",
                                                                      lineHeight: "20px"
                                                                    } as any
                                                                  }
                                                                ></Label>
                                                              </KCol>
                                                            </KRow>

                                                            <KRow
                                                              id="685486"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="783905"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <KRow
                                                                  id="594890"
                                                                  align="top"
                                                                  justify="start"
                                                                  horizontalGutter={0}
                                                                  verticalGutter={0}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      paddingTop: null,
                                                                      paddingBottom: null,
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <KCol
                                                                    id="398050"
                                                                    xs={14}
                                                                    sm={14}
                                                                    md={14}
                                                                    lg={14}
                                                                    xl={14}
                                                                    xxl={14}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <HorizontalStack
                                                                      id="1034"
                                                                      direction="horizontal"
                                                                      size={4}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(68, 68, 68, 1)",
                                                                          lineHeight: "22px"
                                                                        } as any
                                                                      }
                                                                    >
                                                                      <Icon
                                                                        id="688763"
                                                                        visibility={this.state.isComp688763Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(208, 2, 27, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <Icon
                                                                        id="688538"
                                                                        visibility={this.state.isComp688538Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(119, 195, 36, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <VerticalStack
                                                                        id="319527"
                                                                        direction="vertical"
                                                                        size={0}
                                                                        style={
                                                                          {
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            fontFamily: "Roboto",
                                                                            fontWeight: 500,
                                                                            fontSize: "14px",
                                                                            color: "rgba(68, 68, 68, 1)",
                                                                            lineHeight: "22px"
                                                                          } as any
                                                                        }
                                                                      >
                                                                        <Label
                                                                          id="606915"
                                                                          visibility={this.state.isComp606915Visible}
                                                                          value={ReactSystemFunctions.translate(
                                                                            this.ml,
                                                                            606915,
                                                                            "value",
                                                                            this.defaultML
                                                                          )}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(68, 68, 68, 1)",
                                                                              lineHeight: "22px"
                                                                            } as any
                                                                          }
                                                                        ></Label>

                                                                        <Label
                                                                          id="123700"
                                                                          onClick={(e?: any) => {
                                                                            if (e && e.stopPropagation)
                                                                              e.stopPropagation();
                                                                            this.AssignedVehicleViewComponent_123700_onClick();
                                                                          }}
                                                                          showCursorPointer
                                                                          visibility={this.state.isComp123700Visible}
                                                                          value={
                                                                            this.state.getDocumentsName?.at?.(0)
                                                                              ?.customsDeclarationsName ?? undefined
                                                                          }
                                                                          maxLine={1}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(22, 193, 243, 1)",
                                                                              lineHeight: "22px",
                                                                              textDecoration: "underline"
                                                                            } as any
                                                                          }
                                                                        ></Label>
                                                                      </VerticalStack>
                                                                    </HorizontalStack>
                                                                  </KCol>

                                                                  <KCol
                                                                    id="118951"
                                                                    xs={10}
                                                                    sm={10}
                                                                    md={10}
                                                                    lg={10}
                                                                    xl={10}
                                                                    xxl={10}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <Button
                                                                      id="530826"
                                                                      onClick={(e?: any) => {
                                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                                        this.AssignedVehicleViewComponent_530826_onClick();
                                                                      }}
                                                                      showCursorPointer
                                                                      visibility={
                                                                        this.state
                                                                          .isCompassignedvehicleview_530826AuthorizationVisible
                                                                      }
                                                                      visibilityByAuthorization={
                                                                        this.state
                                                                          .isCompassignedvehicleview_530826AuthorizationVisible
                                                                      }
                                                                      label={ReactSystemFunctions.translate(
                                                                        this.ml,
                                                                        530826,
                                                                        "label",
                                                                        this.defaultML
                                                                      )}
                                                                      size="middle"
                                                                      loading={false}
                                                                      ghost={false}
                                                                      block={false}
                                                                      htmlType="button"
                                                                      iconPosition="left"
                                                                      danger={false}
                                                                      style={
                                                                        {
                                                                          borderTopLeftRadius: 4,
                                                                          borderTopRightRadius: 4,
                                                                          borderBottomRightRadius: 4,
                                                                          borderBottomLeftRadius: 4,
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          backgroundColor: "rgba(22, 193, 243, 1)",
                                                                          backgroundRepeat: "no-repeat",
                                                                          backgroundSize: "contain",
                                                                          backgroundPosition: "left",
                                                                          paddingTop: 4,
                                                                          paddingRight: 18,
                                                                          paddingBottom: 4,
                                                                          paddingLeft: 18,
                                                                          display: "inline",
                                                                          fontFamily: "Poppins",
                                                                          fontWeight: 500,
                                                                          fontSize: "12px",
                                                                          color: "rgba(255, 255, 255, 1)",
                                                                          lineHeight: "18px"
                                                                        } as any
                                                                      }
                                                                    ></Button>
                                                                  </KCol>
                                                                </KRow>
                                                              </KCol>
                                                            </KRow>
                                                          </KCol>
                                                        </KRow>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>

                                                  <KCol
                                                    id="42251"
                                                    xs={24}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="761360"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 12,
                                                          borderTopRightRadius: 12,
                                                          borderBottomRightRadius: 12,
                                                          borderBottomLeftRadius: 12,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(227, 241, 250, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 4,
                                                          paddingRight: 4,
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="927657"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KRow
                                                          id="760511"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="70113"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: null,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <KRow
                                                              id="270906"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="960627"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <Label
                                                                  id="583111"
                                                                  value={ReactSystemFunctions.translate(
                                                                    this.ml,
                                                                    583111,
                                                                    "value",
                                                                    this.defaultML
                                                                  )}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      alignItems: "center",
                                                                      textAlign: "-webkit-left",
                                                                      display: "inline",
                                                                      fontFamily: "Poppins",
                                                                      fontWeight: 400,
                                                                      fontSize: "12px",
                                                                      color: "rgba(155, 155, 155, 1)",
                                                                      lineHeight: "20px"
                                                                    } as any
                                                                  }
                                                                ></Label>
                                                              </KCol>
                                                            </KRow>

                                                            <KRow
                                                              id="719264"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="323929"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <KRow
                                                                  id="487274"
                                                                  align="top"
                                                                  justify="start"
                                                                  horizontalGutter={0}
                                                                  verticalGutter={0}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      paddingTop: null,
                                                                      paddingBottom: null,
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <KCol
                                                                    id="687867"
                                                                    xs={14}
                                                                    sm={14}
                                                                    md={14}
                                                                    lg={14}
                                                                    xl={14}
                                                                    xxl={14}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <HorizontalStack
                                                                      id="162667"
                                                                      direction="horizontal"
                                                                      size={4}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(68, 68, 68, 1)",
                                                                          lineHeight: "22px"
                                                                        } as any
                                                                      }
                                                                    >
                                                                      <Icon
                                                                        id="993112"
                                                                        visibility={this.state.isComp993112Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(208, 2, 27, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <Icon
                                                                        id="556191"
                                                                        visibility={this.state.isComp556191Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(119, 195, 36, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <VerticalStack
                                                                        id="384844"
                                                                        direction="vertical"
                                                                        size={0}
                                                                        style={
                                                                          {
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            fontFamily: "Roboto",
                                                                            fontWeight: 500,
                                                                            fontSize: "14px",
                                                                            color: "rgba(68, 68, 68, 1)",
                                                                            lineHeight: "22px"
                                                                          } as any
                                                                        }
                                                                      >
                                                                        <Label
                                                                          id="582561"
                                                                          visibility={this.state.isComp582561Visible}
                                                                          value={ReactSystemFunctions.translate(
                                                                            this.ml,
                                                                            582561,
                                                                            "value",
                                                                            this.defaultML
                                                                          )}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(68, 68, 68, 1)",
                                                                              lineHeight: "22px"
                                                                            } as any
                                                                          }
                                                                        ></Label>

                                                                        <Label
                                                                          id="482066"
                                                                          onClick={(e?: any) => {
                                                                            if (e && e.stopPropagation)
                                                                              e.stopPropagation();
                                                                            this.AssignedVehicleViewComponent_482066_onClick();
                                                                          }}
                                                                          showCursorPointer
                                                                          visibility={this.state.isComp482066Visible}
                                                                          value={
                                                                            this.state.getDocumentsName?.at?.(0)
                                                                              ?.circulationATRName ?? undefined
                                                                          }
                                                                          maxLine={1}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(22, 193, 243, 1)",
                                                                              lineHeight: "22px",
                                                                              textDecoration: "underline"
                                                                            } as any
                                                                          }
                                                                        ></Label>
                                                                      </VerticalStack>
                                                                    </HorizontalStack>
                                                                  </KCol>

                                                                  <KCol
                                                                    id="157350"
                                                                    xs={10}
                                                                    sm={10}
                                                                    md={10}
                                                                    lg={10}
                                                                    xl={10}
                                                                    xxl={10}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <Button
                                                                      id="4484"
                                                                      onClick={(e?: any) => {
                                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                                        this.AssignedVehicleViewComponent_4484_onClick();
                                                                      }}
                                                                      showCursorPointer
                                                                      visibility={
                                                                        this.state
                                                                          .isCompassignedvehicleview_4484AuthorizationVisible
                                                                      }
                                                                      visibilityByAuthorization={
                                                                        this.state
                                                                          .isCompassignedvehicleview_4484AuthorizationVisible
                                                                      }
                                                                      label={ReactSystemFunctions.translate(
                                                                        this.ml,
                                                                        4484,
                                                                        "label",
                                                                        this.defaultML
                                                                      )}
                                                                      size="middle"
                                                                      loading={false}
                                                                      ghost={false}
                                                                      block={false}
                                                                      htmlType="button"
                                                                      iconPosition="left"
                                                                      danger={false}
                                                                      style={
                                                                        {
                                                                          borderTopLeftRadius: 4,
                                                                          borderTopRightRadius: 4,
                                                                          borderBottomRightRadius: 4,
                                                                          borderBottomLeftRadius: 4,
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          backgroundColor: "rgba(22, 193, 243, 1)",
                                                                          backgroundRepeat: "no-repeat",
                                                                          backgroundSize: "contain",
                                                                          backgroundPosition: "left",
                                                                          paddingTop: 4,
                                                                          paddingRight: 18,
                                                                          paddingBottom: 4,
                                                                          paddingLeft: 18,
                                                                          display: "inline",
                                                                          fontFamily: "Poppins",
                                                                          fontWeight: 500,
                                                                          fontSize: "12px",
                                                                          color: "rgba(255, 255, 255, 1)",
                                                                          lineHeight: "18px"
                                                                        } as any
                                                                      }
                                                                    ></Button>
                                                                  </KCol>
                                                                </KRow>
                                                              </KCol>
                                                            </KRow>
                                                          </KCol>
                                                        </KRow>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>

                                                  <KCol
                                                    id="533959"
                                                    xs={24}
                                                    sm={8}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    xxl={8}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        backgroundColor: "rgba(230, 230, 230, 0)",
                                                        paddingTop: 2,
                                                        paddingRight: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 2,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="287259"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 12,
                                                          borderTopRightRadius: 12,
                                                          borderBottomRightRadius: 12,
                                                          borderBottomLeftRadius: 12,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(227, 241, 250, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 4,
                                                          paddingRight: 4,
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="452504"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KRow
                                                          id="878729"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="907627"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: null,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <KRow
                                                              id="811234"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="638414"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <Label
                                                                  id="198662"
                                                                  value={ReactSystemFunctions.translate(
                                                                    this.ml,
                                                                    198662,
                                                                    "value",
                                                                    this.defaultML
                                                                  )}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      alignItems: "center",
                                                                      textAlign: "-webkit-left",
                                                                      display: "inline",
                                                                      fontFamily: "Poppins",
                                                                      fontWeight: 400,
                                                                      fontSize: "12px",
                                                                      color: "rgba(155, 155, 155, 1)",
                                                                      lineHeight: "20px"
                                                                    } as any
                                                                  }
                                                                ></Label>
                                                              </KCol>
                                                            </KRow>

                                                            <KRow
                                                              id="467073"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="253941"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <KRow
                                                                  id="244688"
                                                                  align="top"
                                                                  justify="start"
                                                                  horizontalGutter={0}
                                                                  verticalGutter={0}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      paddingTop: null,
                                                                      paddingBottom: null,
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <KCol
                                                                    id="479060"
                                                                    xs={14}
                                                                    sm={14}
                                                                    md={14}
                                                                    lg={14}
                                                                    xl={14}
                                                                    xxl={14}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <HorizontalStack
                                                                      id="240251"
                                                                      direction="horizontal"
                                                                      size={4}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(68, 68, 68, 1)",
                                                                          lineHeight: "22px"
                                                                        } as any
                                                                      }
                                                                    >
                                                                      <Icon
                                                                        id="698440"
                                                                        visibility={this.state.isComp698440Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(208, 2, 27, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <Icon
                                                                        id="176933"
                                                                        visibility={this.state.isComp176933Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(119, 195, 36, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <VerticalStack
                                                                        id="197286"
                                                                        direction="vertical"
                                                                        size={0}
                                                                        style={
                                                                          {
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            fontFamily: "Roboto",
                                                                            fontWeight: 500,
                                                                            fontSize: "14px",
                                                                            color: "rgba(68, 68, 68, 1)",
                                                                            lineHeight: "22px"
                                                                          } as any
                                                                        }
                                                                      >
                                                                        <Label
                                                                          id="54129"
                                                                          visibility={this.state.isComp54129Visible}
                                                                          value={ReactSystemFunctions.translate(
                                                                            this.ml,
                                                                            54129,
                                                                            "value",
                                                                            this.defaultML
                                                                          )}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(68, 68, 68, 1)",
                                                                              lineHeight: "22px"
                                                                            } as any
                                                                          }
                                                                        ></Label>

                                                                        <Label
                                                                          id="366556"
                                                                          onClick={(e?: any) => {
                                                                            if (e && e.stopPropagation)
                                                                              e.stopPropagation();
                                                                            this.AssignedVehicleViewComponent_366556_onClick();
                                                                          }}
                                                                          showCursorPointer
                                                                          visibility={this.state.isComp366556Visible}
                                                                          value={
                                                                            this.state.getDocumentsName?.at?.(0)
                                                                              ?.circulationEUROName ?? undefined
                                                                          }
                                                                          maxLine={1}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(22, 193, 243, 1)",
                                                                              lineHeight: "22px",
                                                                              textDecoration: "underline"
                                                                            } as any
                                                                          }
                                                                        ></Label>
                                                                      </VerticalStack>
                                                                    </HorizontalStack>
                                                                  </KCol>

                                                                  <KCol
                                                                    id="626347"
                                                                    xs={10}
                                                                    sm={10}
                                                                    md={10}
                                                                    lg={10}
                                                                    xl={10}
                                                                    xxl={10}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <Button
                                                                      id="477178"
                                                                      onClick={(e?: any) => {
                                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                                        this.AssignedVehicleViewComponent_477178_onClick();
                                                                      }}
                                                                      showCursorPointer
                                                                      visibility={
                                                                        this.state
                                                                          .isCompassignedvehicleview_477178AuthorizationVisible
                                                                      }
                                                                      visibilityByAuthorization={
                                                                        this.state
                                                                          .isCompassignedvehicleview_477178AuthorizationVisible
                                                                      }
                                                                      label={ReactSystemFunctions.translate(
                                                                        this.ml,
                                                                        477178,
                                                                        "label",
                                                                        this.defaultML
                                                                      )}
                                                                      size="middle"
                                                                      loading={false}
                                                                      ghost={false}
                                                                      block={false}
                                                                      htmlType="button"
                                                                      iconPosition="left"
                                                                      danger={false}
                                                                      style={
                                                                        {
                                                                          borderTopLeftRadius: 4,
                                                                          borderTopRightRadius: 4,
                                                                          borderBottomRightRadius: 4,
                                                                          borderBottomLeftRadius: 4,
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          backgroundColor: "rgba(22, 193, 243, 1)",
                                                                          backgroundRepeat: "no-repeat",
                                                                          backgroundSize: "contain",
                                                                          backgroundPosition: "left",
                                                                          paddingTop: 4,
                                                                          paddingRight: 18,
                                                                          paddingBottom: 4,
                                                                          paddingLeft: 18,
                                                                          display: "inline",
                                                                          fontFamily: "Poppins",
                                                                          fontWeight: 500,
                                                                          fontSize: "12px",
                                                                          color: "rgba(255, 255, 255, 1)",
                                                                          lineHeight: "18px"
                                                                        } as any
                                                                      }
                                                                    ></Button>
                                                                  </KCol>
                                                                </KRow>
                                                              </KCol>
                                                            </KRow>
                                                          </KCol>
                                                        </KRow>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>
                                                </KRow>
                                              </KCol>
                                            </KRow>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="260562"
                                      visibility={
                                        this.state.isComp260562Visible == "hidden" ||
                                        this.state.isCompassignedvehicleview_260562AuthorizationVisible == "hidden"
                                          ? "hidden"
                                          : "visible"
                                      }
                                      visibilityByAuthorization={
                                        this.state.isCompassignedvehicleview_260562AuthorizationVisible == "hidden"
                                          ? "hidden"
                                          : "visible"
                                      }
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 12,
                                          borderTopRightRadius: 12,
                                          borderBottomRightRadius: 12,
                                          borderBottomLeftRadius: 12,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 4,
                                          paddingRight: 8,
                                          paddingBottom: 4,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="519174"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderTopLeftRadius: 12,
                                            borderTopRightRadius: 12,
                                            borderBottomRightRadius: 12,
                                            borderBottomLeftRadius: 12,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(227, 241, 250, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingRight: 8,
                                            paddingBottom: 4,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="406081"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="194425"
                                            xs={16}
                                            sm={16}
                                            md={16}
                                            lg={16}
                                            xl={16}
                                            xxl={16}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="803401"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                803401,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14pt",
                                                  color: "rgba(22, 193, 243, 1)"
                                                } as any
                                              }
                                            ></Label>

                                            <Button
                                              id="534765"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.AssignedVehicleViewComponent_534765_onClick();
                                              }}
                                              showCursorPointer
                                              visibility={this.state.isComp534765Visible}
                                              label={ReactSystemFunctions.translate(
                                                this.ml,
                                                534765,
                                                "label",
                                                this.defaultML
                                              )}
                                              size="middle"
                                              loading={false}
                                              ghost={false}
                                              block={false}
                                              htmlType="button"
                                              iconPosition="left"
                                              danger={false}
                                              style={
                                                {
                                                  borderTopLeftRadius: 4,
                                                  borderTopRightRadius: 4,
                                                  borderBottomRightRadius: 4,
                                                  borderBottomLeftRadius: 4,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(22, 193, 243, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 20,
                                                  paddingBottom: 8,
                                                  paddingLeft: 20,
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "16px",
                                                  color: "rgba(255, 255, 255, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Button>

                                            <Button
                                              id="607622"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.AssignedVehicleViewComponent_607622_onClick();
                                              }}
                                              showCursorPointer
                                              visibility={this.state.isComp607622Visible}
                                              label={ReactSystemFunctions.translate(
                                                this.ml,
                                                607622,
                                                "label",
                                                this.defaultML
                                              )}
                                              size="middle"
                                              loading={false}
                                              ghost={false}
                                              block={false}
                                              htmlType="button"
                                              iconPosition="left"
                                              danger={false}
                                              style={
                                                {
                                                  borderTopLeftRadius: 4,
                                                  borderTopRightRadius: 4,
                                                  borderBottomRightRadius: 4,
                                                  borderBottomLeftRadius: 4,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(22, 193, 243, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 20,
                                                  paddingBottom: 8,
                                                  paddingLeft: 20,
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "16px",
                                                  color: "rgba(255, 255, 255, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Button>
                                          </KCol>

                                          <KCol
                                            id="828921"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-right",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Button
                                              id="48226"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.AssignedVehicleViewComponent_48226_onClick();
                                              }}
                                              showCursorPointer
                                              label={ReactSystemFunctions.translate(
                                                this.ml,
                                                48226,
                                                "label",
                                                this.defaultML
                                              )}
                                              size="middle"
                                              loading={false}
                                              ghost={false}
                                              block={false}
                                              htmlType="button"
                                              icon="refresh"
                                              iconPosition="left"
                                              danger={false}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderTopWidth: 1,
                                                  borderRightWidth: 1,
                                                  borderBottomWidth: 1,
                                                  borderLeftWidth: 1,
                                                  borderColor: "rgba(22, 193, 243, 1)",
                                                  borderStyle: "solid",
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 4,
                                                  paddingRight: 20,
                                                  paddingBottom: 8,
                                                  paddingLeft: 20,
                                                  height: "32px",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "16px",
                                                  color: "rgba(22, 193, 243, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Button>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="933255"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 8,
                                              paddingBottom: 0,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="691040"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KRow
                                              id="106532"
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderTopLeftRadius: 12,
                                                  borderTopRightRadius: 12,
                                                  borderBottomRightRadius: 12,
                                                  borderBottomLeftRadius: 12,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="91704"
                                                xs={24}
                                                sm={24}
                                                md={24}
                                                lg={24}
                                                xl={24}
                                                xxl={24}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <KRow
                                                  id="200576"
                                                  visibility={
                                                    this.state.isComp200576Visible == "hidden" ||
                                                    this.state.isCompassignedvehicleview_200576AuthorizationVisible ==
                                                      "hidden"
                                                      ? "hidden"
                                                      : "visible"
                                                  }
                                                  visibilityByAuthorization={
                                                    this.state.isCompassignedvehicleview_200576AuthorizationVisible ==
                                                    "hidden"
                                                      ? "hidden"
                                                      : "visible"
                                                  }
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="717303"
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    xxl={12}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <Form.Item
                                                      className="kFormItem"
                                                      name="assignedvehicleview_296683_value"
                                                      noStyle
                                                    >
                                                      <PlacesAutocompleteInput
                                                        id="296683"
                                                        kuikaRef={
                                                          this
                                                            .assignedvehicleview_296683_value_kuikaPlacesAutocompleteInputRef
                                                        }
                                                        placeholder={ReactSystemFunctions.translate(
                                                          this.ml,
                                                          296683,
                                                          "placeholder",
                                                          this.defaultML
                                                        )}
                                                        disabled={false}
                                                        allowClear={true}
                                                        style={
                                                          {
                                                            borderTopLeftRadius: 4,
                                                            borderTopRightRadius: 4,
                                                            borderBottomRightRadius: 4,
                                                            borderBottomLeftRadius: 4,
                                                            borderTopWidth: 1,
                                                            borderRightWidth: 1,
                                                            borderBottomWidth: 1,
                                                            borderLeftWidth: 1,
                                                            borderColor: "rgba(218, 218, 218, 1)",
                                                            borderStyle: "solid",
                                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundSize: "contain",
                                                            backgroundPosition: "left",
                                                            height: "40px",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      ></PlacesAutocompleteInput>
                                                    </Form.Item>
                                                  </KCol>

                                                  <KCol
                                                    id="104582"
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                    xxl={6}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingRight: 8,
                                                        paddingLeft: 8,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <Button
                                                      id="417598"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.AssignedVehicleViewComponent_417598_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={
                                                        this.state.isCompassignedvehicleview_417598AuthorizationVisible
                                                      }
                                                      visibilityByAuthorization={
                                                        this.state.isCompassignedvehicleview_417598AuthorizationVisible
                                                      }
                                                      label={ReactSystemFunctions.translate(
                                                        this.ml,
                                                        417598,
                                                        "label",
                                                        this.defaultML
                                                      )}
                                                      size="middle"
                                                      loading={false}
                                                      ghost={false}
                                                      block={false}
                                                      htmlType="button"
                                                      icon="add_location_alt"
                                                      iconPosition="right"
                                                      danger={false}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 4,
                                                          borderTopRightRadius: 4,
                                                          borderBottomRightRadius: 4,
                                                          borderBottomLeftRadius: 4,
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          backgroundColor: "rgba(22, 193, 243, 1)",
                                                          backgroundRepeat: "no-repeat",
                                                          backgroundSize: "contain",
                                                          backgroundPosition: "left",
                                                          paddingTop: 8,
                                                          paddingRight: 20,
                                                          paddingBottom: 8,
                                                          paddingLeft: 20,
                                                          width: "100%",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "16px",
                                                          color: "rgba(255, 255, 255, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    ></Button>
                                                  </KCol>

                                                  <KCol
                                                    id="736651"
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                    xxl={6}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <Button
                                                      id="536285"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.AssignedVehicleViewComponent_536285_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={
                                                        this.state.isComp536285Visible == "hidden" ||
                                                        this.state
                                                          .isCompassignedvehicleview_536285AuthorizationVisible ==
                                                          "hidden"
                                                          ? "hidden"
                                                          : "visible"
                                                      }
                                                      visibilityByAuthorization={
                                                        this.state
                                                          .isCompassignedvehicleview_536285AuthorizationVisible ==
                                                        "hidden"
                                                          ? "hidden"
                                                          : "visible"
                                                      }
                                                      label={ReactSystemFunctions.translate(
                                                        this.ml,
                                                        536285,
                                                        "label",
                                                        this.defaultML
                                                      )}
                                                      size="middle"
                                                      loading={false}
                                                      ghost={false}
                                                      block={false}
                                                      htmlType="button"
                                                      iconPosition="left"
                                                      danger={false}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 4,
                                                          borderTopRightRadius: 4,
                                                          borderBottomRightRadius: 4,
                                                          borderBottomLeftRadius: 4,
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          backgroundColor: "rgba(119, 195, 36, 1)",
                                                          backgroundRepeat: "no-repeat",
                                                          backgroundSize: "contain",
                                                          backgroundPosition: "left",
                                                          paddingTop: 8,
                                                          paddingRight: 20,
                                                          paddingBottom: 8,
                                                          paddingLeft: 20,
                                                          width: "100%",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "16px",
                                                          color: "rgba(255, 255, 255, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    ></Button>

                                                    <Button
                                                      id="371945"
                                                      onClick={(e?: any) => {
                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                        this.AssignedVehicleViewComponent_371945_onClick();
                                                      }}
                                                      showCursorPointer
                                                      visibility={
                                                        this.state.isComp371945Visible == "hidden" ||
                                                        this.state
                                                          .isCompassignedvehicleview_371945AuthorizationVisible ==
                                                          "hidden"
                                                          ? "hidden"
                                                          : "visible"
                                                      }
                                                      visibilityByAuthorization={
                                                        this.state
                                                          .isCompassignedvehicleview_371945AuthorizationVisible ==
                                                        "hidden"
                                                          ? "hidden"
                                                          : "visible"
                                                      }
                                                      label={ReactSystemFunctions.translate(
                                                        this.ml,
                                                        371945,
                                                        "label",
                                                        this.defaultML
                                                      )}
                                                      size="middle"
                                                      loading={false}
                                                      ghost={false}
                                                      block={false}
                                                      htmlType="button"
                                                      iconPosition="left"
                                                      danger={false}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 4,
                                                          borderTopRightRadius: 4,
                                                          borderBottomRightRadius: 4,
                                                          borderBottomLeftRadius: 4,
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          backgroundColor: "rgba(119, 195, 36, 1)",
                                                          backgroundRepeat: "no-repeat",
                                                          backgroundSize: "contain",
                                                          backgroundPosition: "left",
                                                          paddingTop: 8,
                                                          paddingRight: 20,
                                                          paddingBottom: 8,
                                                          paddingLeft: 20,
                                                          width: "100%",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "16px",
                                                          color: "rgba(255, 255, 255, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    ></Button>
                                                  </KCol>
                                                </KRow>

                                                <KRow
                                                  id="445236"
                                                  visibility={this.state.isComp445236Visible}
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="440573"
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    xxl={12}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="204565"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 12,
                                                          borderTopRightRadius: 12,
                                                          borderBottomRightRadius: 12,
                                                          borderBottomLeftRadius: 12,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(227, 241, 250, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 4,
                                                          paddingRight: 4,
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="993813"
                                                        xs={24}
                                                        sm={24}
                                                        md={24}
                                                        lg={24}
                                                        xl={24}
                                                        xxl={24}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KRow
                                                          id="788666"
                                                          align="top"
                                                          justify="start"
                                                          horizontalGutter={0}
                                                          verticalGutter={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <KCol
                                                            id="13416"
                                                            xs={24}
                                                            sm={24}
                                                            md={24}
                                                            lg={24}
                                                            xl={24}
                                                            xxl={24}
                                                            order={0}
                                                            pull={0}
                                                            push={0}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: null,
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          >
                                                            <KRow
                                                              id="349897"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="700474"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <Label
                                                                  id="411602"
                                                                  visibility={this.state.isComp411602Visible}
                                                                  value={ReactSystemFunctions.translate(
                                                                    this.ml,
                                                                    411602,
                                                                    "value",
                                                                    this.defaultML
                                                                  )}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      alignItems: "center",
                                                                      textAlign: "-webkit-left",
                                                                      display: "inline",
                                                                      fontFamily: "Poppins",
                                                                      fontWeight: 400,
                                                                      fontSize: "12px",
                                                                      color: "rgba(155, 155, 155, 1)",
                                                                      lineHeight: "20px"
                                                                    } as any
                                                                  }
                                                                ></Label>
                                                              </KCol>

                                                              <KCol
                                                                id="496666"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <Label
                                                                  id="799915"
                                                                  visibility={this.state.isComp799915Visible}
                                                                  value={ReactSystemFunctions.translate(
                                                                    this.ml,
                                                                    799915,
                                                                    "value",
                                                                    this.defaultML
                                                                  )}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      alignItems: "center",
                                                                      textAlign: "-webkit-left",
                                                                      display: "inline",
                                                                      fontFamily: "Poppins",
                                                                      fontWeight: 400,
                                                                      fontSize: "12px",
                                                                      color: "rgba(155, 155, 155, 1)",
                                                                      lineHeight: "20px"
                                                                    } as any
                                                                  }
                                                                ></Label>
                                                              </KCol>
                                                            </KRow>

                                                            <KRow
                                                              id="639909"
                                                              align="top"
                                                              justify="start"
                                                              horizontalGutter={0}
                                                              verticalGutter={0}
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            >
                                                              <KCol
                                                                id="222441"
                                                                xs={24}
                                                                sm={24}
                                                                md={24}
                                                                lg={24}
                                                                xl={24}
                                                                xxl={24}
                                                                order={0}
                                                                pull={0}
                                                                push={0}
                                                                style={
                                                                  {
                                                                    borderStyle: "solid",
                                                                    borderTopWidth: 0,
                                                                    borderRightWidth: 0,
                                                                    borderBottomWidth: 0,
                                                                    borderLeftWidth: 0,
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    color: "rgba(68, 68, 68, 1)",
                                                                    lineHeight: "22px"
                                                                  } as any
                                                                }
                                                              >
                                                                <KRow
                                                                  id="56956"
                                                                  align="top"
                                                                  justify="start"
                                                                  horizontalGutter={0}
                                                                  verticalGutter={0}
                                                                  style={
                                                                    {
                                                                      borderStyle: "solid",
                                                                      borderTopWidth: 0,
                                                                      borderRightWidth: 0,
                                                                      borderBottomWidth: 0,
                                                                      borderLeftWidth: 0,
                                                                      paddingTop: null,
                                                                      paddingBottom: null,
                                                                      fontFamily: "Roboto",
                                                                      fontWeight: 500,
                                                                      fontSize: "14px",
                                                                      color: "rgba(68, 68, 68, 1)",
                                                                      lineHeight: "22px"
                                                                    } as any
                                                                  }
                                                                >
                                                                  <KCol
                                                                    id="259858"
                                                                    xs={14}
                                                                    sm={14}
                                                                    md={14}
                                                                    lg={14}
                                                                    xl={14}
                                                                    xxl={14}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <HorizontalStack
                                                                      id="685204"
                                                                      direction="horizontal"
                                                                      size={4}
                                                                      style={
                                                                        {
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          alignItems: "center",
                                                                          fontFamily: "Roboto",
                                                                          fontWeight: 500,
                                                                          fontSize: "14px",
                                                                          color: "rgba(68, 68, 68, 1)",
                                                                          lineHeight: "22px"
                                                                        } as any
                                                                      }
                                                                    >
                                                                      <Icon
                                                                        id="557549"
                                                                        visibility={this.state.isComp557549Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(208, 2, 27, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <Icon
                                                                        id="24497"
                                                                        visibility={this.state.isComp24497Visible}
                                                                        iconName="attach_file"
                                                                        style={
                                                                          {
                                                                            borderTopLeftRadius: 100,
                                                                            borderTopRightRadius: 100,
                                                                            borderBottomRightRadius: 100,
                                                                            borderBottomLeftRadius: 100,
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            backgroundColor: "rgba(119, 195, 36, 1)",
                                                                            paddingTop: 6,
                                                                            paddingRight: 4,
                                                                            paddingBottom: 4,
                                                                            paddingLeft: 4,
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            alignItems: "center",
                                                                            textAlign: "-webkit-left",
                                                                            display: "inline",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 500,
                                                                            fontSize: "24px",
                                                                            color: "rgba(255, 255, 255, 1)",
                                                                            letterSpacing: "1px"
                                                                          } as any
                                                                        }
                                                                      ></Icon>

                                                                      <VerticalStack
                                                                        id="797269"
                                                                        direction="vertical"
                                                                        size={0}
                                                                        style={
                                                                          {
                                                                            borderStyle: "solid",
                                                                            borderTopWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderBottomWidth: 0,
                                                                            borderLeftWidth: 0,
                                                                            fontFamily: "Roboto",
                                                                            fontWeight: 500,
                                                                            fontSize: "14px",
                                                                            color: "rgba(68, 68, 68, 1)",
                                                                            lineHeight: "22px"
                                                                          } as any
                                                                        }
                                                                      >
                                                                        <Label
                                                                          id="229597"
                                                                          visibility={this.state.isComp229597Visible}
                                                                          value={ReactSystemFunctions.translate(
                                                                            this.ml,
                                                                            229597,
                                                                            "value",
                                                                            this.defaultML
                                                                          )}
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(68, 68, 68, 1)",
                                                                              lineHeight: "22px"
                                                                            } as any
                                                                          }
                                                                        ></Label>

                                                                        <Label
                                                                          id="848626"
                                                                          onClick={(e?: any) => {
                                                                            if (e && e.stopPropagation)
                                                                              e.stopPropagation();
                                                                            this.AssignedVehicleViewComponent_848626_onClick();
                                                                          }}
                                                                          showCursorPointer
                                                                          visibility={this.state.isComp848626Visible}
                                                                          value={
                                                                            this.state.getDocumentsName?.at?.(0)
                                                                              ?.cMRName ?? undefined
                                                                          }
                                                                          style={
                                                                            {
                                                                              borderStyle: "solid",
                                                                              borderTopWidth: 0,
                                                                              borderRightWidth: 0,
                                                                              borderBottomWidth: 0,
                                                                              borderLeftWidth: 0,
                                                                              alignItems: "center",
                                                                              textAlign: "-webkit-left",
                                                                              display: "inline",
                                                                              fontFamily: "Roboto",
                                                                              fontWeight: 500,
                                                                              fontSize: "14px",
                                                                              color: "rgba(22, 193, 243, 1)",
                                                                              lineHeight: "22px",
                                                                              textDecoration: "underline"
                                                                            } as any
                                                                          }
                                                                        ></Label>
                                                                      </VerticalStack>
                                                                    </HorizontalStack>
                                                                  </KCol>

                                                                  <KCol
                                                                    id="185773"
                                                                    xs={10}
                                                                    sm={10}
                                                                    md={10}
                                                                    lg={10}
                                                                    xl={10}
                                                                    xxl={10}
                                                                    order={0}
                                                                    pull={0}
                                                                    push={0}
                                                                    style={
                                                                      {
                                                                        borderStyle: "solid",
                                                                        borderTopWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderBottomWidth: 0,
                                                                        borderLeftWidth: 0,
                                                                        paddingTop: 2,
                                                                        paddingRight: 2,
                                                                        paddingBottom: 2,
                                                                        paddingLeft: 2,
                                                                        alignItems: "center",
                                                                        textAlign: "-webkit-center",
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        color: "rgba(68, 68, 68, 1)",
                                                                        lineHeight: "22px"
                                                                      } as any
                                                                    }
                                                                  >
                                                                    <Button
                                                                      id="478219"
                                                                      onClick={(e?: any) => {
                                                                        if (e && e.stopPropagation) e.stopPropagation();
                                                                        this.AssignedVehicleViewComponent_478219_onClick();
                                                                      }}
                                                                      showCursorPointer
                                                                      visibility={
                                                                        this.state
                                                                          .isCompassignedvehicleview_478219AuthorizationVisible
                                                                      }
                                                                      visibilityByAuthorization={
                                                                        this.state
                                                                          .isCompassignedvehicleview_478219AuthorizationVisible
                                                                      }
                                                                      label={ReactSystemFunctions.translate(
                                                                        this.ml,
                                                                        478219,
                                                                        "label",
                                                                        this.defaultML
                                                                      )}
                                                                      size="middle"
                                                                      loading={false}
                                                                      ghost={false}
                                                                      block={false}
                                                                      htmlType="button"
                                                                      iconPosition="left"
                                                                      danger={false}
                                                                      style={
                                                                        {
                                                                          borderTopLeftRadius: 4,
                                                                          borderTopRightRadius: 4,
                                                                          borderBottomRightRadius: 4,
                                                                          borderBottomLeftRadius: 4,
                                                                          borderStyle: "solid",
                                                                          borderTopWidth: 0,
                                                                          borderRightWidth: 0,
                                                                          borderBottomWidth: 0,
                                                                          borderLeftWidth: 0,
                                                                          backgroundColor: "rgba(22, 193, 243, 1)",
                                                                          backgroundRepeat: "no-repeat",
                                                                          backgroundSize: "contain",
                                                                          backgroundPosition: "left",
                                                                          paddingTop: 4,
                                                                          paddingRight: 18,
                                                                          paddingBottom: 4,
                                                                          paddingLeft: 18,
                                                                          display: "inline",
                                                                          fontFamily: "Poppins",
                                                                          fontWeight: 500,
                                                                          fontSize: "12px",
                                                                          color: "rgba(255, 255, 255, 1)",
                                                                          lineHeight: "18px"
                                                                        } as any
                                                                      }
                                                                    ></Button>
                                                                  </KCol>
                                                                </KRow>
                                                              </KCol>
                                                            </KRow>
                                                          </KCol>
                                                        </KRow>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>

                                                  <KCol
                                                    id="741273"
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    xxl={12}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></KCol>
                                                </KRow>

                                                <KRow
                                                  id="256975"
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="966153"
                                                    xs={24}
                                                    sm={24}
                                                    md={24}
                                                    lg={24}
                                                    xl={24}
                                                    xxl={24}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KTable
                                                      id="957942"
                                                      kuikaRef={this.assignedvehicleview_957942_value_kuikaTableRef}
                                                      form={this.props.form}
                                                      dataSource={this.state.spGetVehicleLocations}
                                                      size="middle"
                                                      bordered={true}
                                                      showHeader={true}
                                                      loading={false}
                                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                                        this.ml,
                                                        957942,
                                                        "nodatafoundmessage",
                                                        this.defaultML
                                                      )}
                                                      sorter={false}
                                                      pagination={false}
                                                      striped={false}
                                                      stripedColor="#F5F7FA"
                                                      insertRowActive={false}
                                                      transformedOnMobileResolution={false}
                                                      tableWidthMode="fit"
                                                      searchable={false}
                                                      filtering={false}
                                                      columnChooser={false}
                                                      insertRowPosition="top"
                                                      multiSelect={false}
                                                      pageSize={10}
                                                      showSizeChanger={false}
                                                      fixedHeader={false}
                                                      showNoDataFoundImage={false}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KTableHeader
                                                        id="111776"
                                                        hideOnMobileResolution={false}
                                                        textDirection="Default"
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 400,
                                                            fontSize: "12px",
                                                            color: "rgba(155, 155, 155, 1)",
                                                            lineHeight: "20px"
                                                          } as any
                                                        }
                                                      ></KTableHeader>

                                                      <KTableRow
                                                        id="27804"
                                                        hoverFontColor="red"
                                                        hoverBgColor="#F5F5F5"
                                                        style={
                                                          {
                                                            borderTopLeftRadius: 12,
                                                            borderTopRightRadius: 12,
                                                            borderBottomRightRadius: 12,
                                                            borderBottomLeftRadius: 12,
                                                            borderTopWidth: 1,
                                                            borderRightWidth: 1,
                                                            borderBottomWidth: 1,
                                                            borderLeftWidth: 1,
                                                            borderColor: "rgba(227, 241, 250, 1)",
                                                            borderStyle: "solid",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KTableColumn
                                                          id="690932"
                                                          title={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            690932,
                                                            "title",
                                                            this.defaultML
                                                          )}
                                                          alwaysVisibleOnMobileResolution={false}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 8,
                                                              paddingRight: 8,
                                                              paddingBottom: 8,
                                                              paddingLeft: 8,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <Label
                                                            id="347361"
                                                            value="[datafield:address]"
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </KTableColumn>

                                                        <KTableColumn
                                                          id="749879"
                                                          title={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            749879,
                                                            "title",
                                                            this.defaultML
                                                          )}
                                                          alwaysVisibleOnMobileResolution={false}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 8,
                                                              paddingRight: 8,
                                                              paddingBottom: 8,
                                                              paddingLeft: 8,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <Label
                                                            id="923653"
                                                            value="[datafield:createdDate]"
                                                            formatter="datetime"
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Roboto",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(68, 68, 68, 1)",
                                                                lineHeight: "22px"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </KTableColumn>

                                                        <KTableColumn
                                                          id="775887"
                                                          title={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            775887,
                                                            "title",
                                                            this.defaultML
                                                          )}
                                                          alwaysVisibleOnMobileResolution={false}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 8,
                                                              paddingRight: 8,
                                                              paddingBottom: 8,
                                                              paddingLeft: 8,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-right",
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <Tooltip
                                                            id="932793"
                                                            title={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              932793,
                                                              "title",
                                                              this.defaultML
                                                            )}
                                                            placement="top"
                                                            color="white"
                                                            style={
                                                              {
                                                                borderTopLeftRadius: 4,
                                                                borderTopRightRadius: 4,
                                                                borderBottomRightRadius: 4,
                                                                borderBottomLeftRadius: 4,
                                                                borderTopWidth: 1,
                                                                borderRightWidth: 1,
                                                                borderBottomWidth: 1,
                                                                borderLeftWidth: 1,
                                                                borderColor: "rgba(218, 218, 218, 1)",
                                                                borderStyle: "solid",
                                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "contain",
                                                                backgroundPosition: "left",
                                                                paddingTop: 8,
                                                                paddingRight: 8,
                                                                paddingBottom: 8,
                                                                paddingLeft: 8,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                color: "rgba(155, 155, 155, 1)",
                                                                lineHeight: "20px"
                                                              } as any
                                                            }
                                                          >
                                                            <Icon
                                                              id="159976"
                                                              onClick={(e?: any) => {
                                                                if (e && e.stopPropagation) e.stopPropagation();
                                                                this.AssignedVehicleViewComponent_159976_onClick();
                                                              }}
                                                              showCursorPointer
                                                              visibility={
                                                                this.state.isComp159976Visible == "hidden" ||
                                                                this.state
                                                                  .isCompassignedvehicleview_159976AuthorizationVisible ==
                                                                  "hidden"
                                                                  ? "hidden"
                                                                  : "visible"
                                                              }
                                                              visibilityByAuthorization={
                                                                this.state
                                                                  .isCompassignedvehicleview_159976AuthorizationVisible ==
                                                                "hidden"
                                                                  ? "hidden"
                                                                  : "visible"
                                                              }
                                                              iconName="delete"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "inline",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "20px",
                                                                  color: "rgba(208, 2, 27, 1)",
                                                                  letterSpacing: "1px"
                                                                } as any
                                                              }
                                                            ></Icon>
                                                          </Tooltip>
                                                        </KTableColumn>
                                                      </KTableRow>
                                                    </KTable>
                                                  </KCol>
                                                </KRow>
                                              </KCol>
                                            </KRow>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="26710"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderTopLeftRadius: 12,
                                              borderTopRightRadius: 12,
                                              borderBottomRightRadius: 12,
                                              borderBottomLeftRadius: 12,
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 0,
                                              paddingRight: 8,
                                              paddingBottom: 0,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="468211"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KRow
                                              id="694691"
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="728960"
                                                xs={24}
                                                sm={24}
                                                md={24}
                                                lg={24}
                                                xl={24}
                                                xxl={24}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <KRow
                                                  id="216321"
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 8,
                                                      paddingBottom: 0,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></KRow>

                                                <KRow
                                                  id="579164"
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 8,
                                                      paddingBottom: 0,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></KRow>

                                                <KRow
                                                  id="6398"
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="607682"
                                                    xs={24}
                                                    sm={24}
                                                    md={24}
                                                    lg={24}
                                                    xl={24}
                                                    xxl={24}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="66352"
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="373932"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="134120"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-right",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KPagination
                                                          id="168619"
                                                          onChange={(e?: any) => {
                                                            if (e && e.stopPropagation) e.stopPropagation();
                                                            this.AssignedVehicleViewComponent_168619_onChange();
                                                          }}
                                                          kuikaRef={
                                                            this.assignedvehicleview_168619_value_kuikaPaginationRef
                                                          }
                                                          total={
                                                            this.state.sqlVehicleLocationCount?.at?.(0)
                                                              ?.locationCount ?? undefined
                                                          }
                                                          pageSize={10}
                                                          defaultCurrent={1}
                                                          current={1}
                                                          defaultPageSize={10}
                                                          disabled={false}
                                                          hideOnSinglePage={true}
                                                          showLessItems={false}
                                                          showQuickJumper={false}
                                                          showSizeChanger={false}
                                                          showFirstPageButton={false}
                                                          showLastPageButton={false}
                                                          showNumbers={true}
                                                          showText={false}
                                                          options={[{ value: "Item 1" }, { value: "Item 2" }]}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0
                                                            } as any
                                                          }
                                                        ></KPagination>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>
                                                </KRow>
                                              </KCol>
                                            </KRow>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="848052"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible848052: false }, () => {
                      this.callCallbackFunction(848052);
                    });
                  }}
                  visible={this.state.NavVisible848052}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible848052 && (
                      <PreviewDocument_Screen
                        onClose={() => {
                          this.setState({ NavVisible848052: false }, () => {
                            this.callCallbackFunction(848052);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="51031"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible51031: false }, () => {
                      this.callCallbackFunction(51031);
                    });
                  }}
                  visible={this.state.NavVisible51031}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible51031 && (
                      <PreviewDocument_Screen
                        onClose={() => {
                          this.setState({ NavVisible51031: false }, () => {
                            this.callCallbackFunction(51031);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="267036"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible267036: false }, () => {
                      this.callCallbackFunction(267036);
                    });
                  }}
                  visible={this.state.NavVisible267036}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible267036 && (
                      <PreviewDocument_Screen
                        onClose={() => {
                          this.setState({ NavVisible267036: false }, () => {
                            this.callCallbackFunction(267036);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="198540"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible198540: false }, () => {
                      this.callCallbackFunction(198540);
                    });
                  }}
                  visible={this.state.NavVisible198540}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible198540 && (
                      <PreviewDocument_Screen
                        onClose={() => {
                          this.setState({ NavVisible198540: false }, () => {
                            this.callCallbackFunction(198540);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="486402"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible486402: false }, () => {
                      this.callCallbackFunction(486402);
                    });
                  }}
                  visible={this.state.NavVisible486402}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible486402 && (
                      <PreviewDocument_Screen
                        onClose={() => {
                          this.setState({ NavVisible486402: false }, () => {
                            this.callCallbackFunction(486402);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="427813"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible427813: false }, () => {
                      this.callCallbackFunction(427813);
                    });
                  }}
                  visible={this.state.NavVisible427813}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible427813 && (
                      <PreviewDocument_Screen
                        onClose={() => {
                          this.setState({ NavVisible427813: false }, () => {
                            this.callCallbackFunction(427813);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="402880"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible402880: false }, () => {
                      this.callCallbackFunction(402880);
                    });
                  }}
                  visible={this.state.NavVisible402880}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible402880 && (
                      <PreviewDocument_Screen
                        onClose={() => {
                          this.setState({ NavVisible402880: false }, () => {
                            this.callCallbackFunction(402880);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="279254"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible279254: false }, () => {
                      this.callCallbackFunction(279254);
                    });
                  }}
                  visible={this.state.NavVisible279254}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible279254 && (
                      <PreviewDocument_Screen
                        onClose={() => {
                          this.setState({ NavVisible279254: false }, () => {
                            this.callCallbackFunction(279254);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="432780"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible432780: false }, () => {
                      this.callCallbackFunction(432780);
                    });
                  }}
                  visible={this.state.NavVisible432780}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible432780 && (
                      <PreviewInvoice_Screen
                        onClose={() => {
                          this.setState({ NavVisible432780: false }, () => {
                            this.callCallbackFunction(432780);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="493725"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible493725: false }, () => {
                      this.callCallbackFunction(493725);
                    });
                  }}
                  visible={this.state.NavVisible493725}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible493725 && (
                      <PreviewDocument_Screen
                        onClose={() => {
                          this.setState({ NavVisible493725: false }, () => {
                            this.callCallbackFunction(493725);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="788135"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible788135: false }, () => {
                      this.callCallbackFunction(788135);
                    });
                  }}
                  visible={this.state.NavVisible788135}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible788135 && (
                      <PreviewDocument_Screen
                        onClose={() => {
                          this.setState({ NavVisible788135: false }, () => {
                            this.callCallbackFunction(788135);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="762261"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible762261: false }, () => {
                      this.callCallbackFunction(762261);
                    });
                  }}
                  visible={this.state.NavVisible762261}
                  width="60%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible762261 && (
                      <PreviewDocument_Screen
                        onClose={() => {
                          this.setState({ NavVisible762261: false }, () => {
                            this.callCallbackFunction(762261);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(AssignedVehicleView_Screen))))
  )
);
export { tmp as AssignedVehicleView_Screen };
//export default tmp;
//export { tmp as AssignedVehicleView_Screen };
