import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOrderProcess_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOrderProcess_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  GetLanguage: string;
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  GetOrderList: any[];
  SqlGetCustomerDetailsByCardNo: any[];
  spGetOrdersVehicleCount: any[];
  spGetOrderVehicles: any[];
  ChangeEnabledOf: any;
  GoBack: any;
  GetDeliveryList: any[];
  sqlDeleteVehicleRequest: number;
  TriggerEvent: string;
  isComp456541Visible: "visible" | "hidden";
  isComp342840Visible: "visible" | "hidden";
  isComp466684Visible: "visible" | "hidden";
  isComp844271Visible: "visible" | "hidden";
  isComp713708Visible: "visible" | "hidden";
  isComp750060Visible: "visible" | "hidden";
  isComp364436Visible: "visible" | "hidden";
  isComp947300Visible: "visible" | "hidden";
  isComp559802Enabled: "enabled" | "disabled";
  isComp782986Enabled: "enabled" | "disabled";
  isComporderprocess_122442AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_28145AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_860641AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_112613AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_542449AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_874326AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_49575AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_119229AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_364436AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_434627AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_666755AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_36086AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_975332AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_205945AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_142879AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_980785AuthorizationVisible: "visible" | "hidden";
  isComporderprocess_559802AuthorizationEnabled: "enabled" | "disabled";
  isComporderprocess_782986AuthorizationEnabled: "enabled" | "disabled";
}

export class OrderProcess_ScreenBase extends React.PureComponent<IOrderProcess_ScreenProps, any> {
  orderprocess_80582_value_kuikaTableRef: React.RefObject<any>;
  orderprocess_190718_value_kuikaTableRef: React.RefObject<any>;
  orderprocess_427261_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 3947, PropertyName: "value", Value: "Şirket Adı", TagName: "Label3_value" },
        { Id: 147379, PropertyName: "value", Value: "Nakliye Kodu", TagName: "Label17_value" },
        { Id: 796261, PropertyName: "value", Value: "Talep Edilen Araçlar", TagName: "Label34_0_value" },
        { Id: 364436, PropertyName: "label", Value: "Yeni Talep", TagName: "Button1_label" },
        { Id: 816746, PropertyName: "value", Value: "Lojistik Tedarikçi", TagName: "Label35_0_value" },
        { Id: 66302, PropertyName: "value", Value: "Yükleme Tarihi", TagName: "Label42_value" },
        { Id: 884605, PropertyName: "value", Value: "Navlun Faturası", TagName: "Label43_value" },
        { Id: 551617, PropertyName: "value", Value: "Durum", TagName: "Label41_value" },
        {
          Id: 190718,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table2_0_nodatafoundmessage"
        },
        { Id: 462385, PropertyName: "value", Value: "Atanan Araç", TagName: "Label37_0_value" },
        { Id: 611735, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 839410, PropertyName: "label", Value: "Çıkış", TagName: "ContentMenuItem2_2_label" },
        { Id: 453221, PropertyName: "value", Value: "Müşteri Bilgileri", TagName: "Label47_value" },
        { Id: 709199, PropertyName: "value", Value: "Nakliye Dahil", TagName: "Label2_0_value" },
        { Id: 111172, PropertyName: "value", Value: "Yurtdışı", TagName: "Label2_0_value" },
        { Id: 37992, PropertyName: "value", Value: "Müşteri Referans No", TagName: "Label2_0_value" },
        { Id: 127993, PropertyName: "value", Value: "Cari Kart No", TagName: "Label2_0_value" },
        { Id: 304055, PropertyName: "value", Value: "Teslimat Adresi", TagName: "Label2_0_value" },
        { Id: 123784, PropertyName: "value", Value: "Sipariş Bilgileri", TagName: "Label2_0_value" },
        { Id: 780387, PropertyName: "value", Value: "Sipariş No", TagName: "Label2_0_value" },
        { Id: 571178, PropertyName: "value", Value: "Sipariş Tarihi", TagName: "Label2_0_value" },
        { Id: 966778, PropertyName: "value", Value: "Taşıma Tipi", TagName: "Label2_0_value" },
        { Id: 106849, PropertyName: "value", Value: "Sipariş Kalemleri", TagName: "Label4_value" },
        { Id: 1240, PropertyName: "value", Value: "Ürün Kodu", TagName: "Label2_0_value" },
        { Id: 337769, PropertyName: "value", Value: "Açıklama", TagName: "Label2_0_value" },
        { Id: 122440, PropertyName: "value", Value: "Miktar", TagName: "Label2_0_value" },
        { Id: 75103, PropertyName: "value", Value: "Depo", TagName: "Label2_0_value" },
        {
          Id: 80582,
          PropertyName: "nodatafoundmessage",
          Value: "Veri Bulunamadı.",
          TagName: "Table2_nodatafoundmessage"
        },
        { Id: 457784, PropertyName: "value", Value: "Tedarikçi Bİlgileri", TagName: "Label48_value" },
        { Id: 721403, PropertyName: "value", Value: "Araç Plaka / Konteyner No", TagName: "Label2_0_value" },
        { Id: 9770, PropertyName: "value", Value: "Gümrükçü Bilgileri", TagName: "Label2_0_value" },
        { Id: 808807, PropertyName: "value", Value: "Tedarikçi No", TagName: "Label6_value" },
        { Id: 590446, PropertyName: "value", Value: "Araç Plaka / Konteyner No", TagName: "Label7_value" },
        { Id: 533036, PropertyName: "value", Value: "Sipariş Detayı", TagName: "Label2_0_value" },
        { Id: 412934, PropertyName: "value", Value: "Araç Plaka", TagName: "Label9_value" },
        { Id: 562376, PropertyName: "value", Value: "Teslimat Tarihi", TagName: "Label10_value" },
        { Id: 252091, PropertyName: "title", Value: "Konumlar", TagName: "Tooltip1_title" },
        { Id: 906287, PropertyName: "title", Value: "Görüntüle", TagName: "Tooltip2_title" },
        { Id: 962201, PropertyName: "title", Value: "Araç Ata", TagName: "Tooltip3_title" },
        { Id: 284770, PropertyName: "title", Value: "Düzenle", TagName: "Tooltip4_title" },
        { Id: 87482, PropertyName: "title", Value: "Sil", TagName: "Tooltip5_title" },
        { Id: 722139, PropertyName: "value", Value: "Aç", TagName: "Label14_value" },
        {
          Id: 73159072,
          PropertyName: "b04025f9-0998-30f0-a19d-d47136ed0291_confirmation",
          Value: "Are you sure you want to delete the vehicle request?",
          TagName: "sqlDeleteVehicleRequest_confirmation",
          IsConfirmation: true
        }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "6f733bc2-b5f2-413e-84c1-c279a3b59936",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 611735, PropertyName: "label", Value: "Profile" },
        { Id: 839410, PropertyName: "label", Value: "Logout" },
        { Id: 123784, PropertyName: "value", Value: "Order Information" },
        { Id: 453221, PropertyName: "value", Value: "Customer Information" },
        { Id: 3947, PropertyName: "value", Value: "Customer" },
        { Id: 37992, PropertyName: "value", Value: "Customer Ref No" },
        { Id: 127993, PropertyName: "value", Value: "Current Card Number" },
        { Id: 304055, PropertyName: "value", Value: "Delivery Address" },
        { Id: 533036, PropertyName: "value", Value: "Order Details" },
        { Id: 780387, PropertyName: "value", Value: "Order Number" },
        { Id: 571178, PropertyName: "value", Value: "Order Date" },
        { Id: 966778, PropertyName: "value", Value: "Type of Shipment" },
        { Id: 709199, PropertyName: "value", Value: "Including Shipping" },
        { Id: 111172, PropertyName: "value", Value: "Abroad" },
        { Id: 106849, PropertyName: "value", Value: "Order Items" },
        { Id: 1240, PropertyName: "value", Value: "Product Code" },
        { Id: 337769, PropertyName: "value", Value: "Description" },
        { Id: 122440, PropertyName: "value", Value: "Quantity" },
        { Id: 75103, PropertyName: "value", Value: "Warehouse" },
        { Id: 80582, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 993928, PropertyName: "value", Value: "[datafield:itemcode]" },
        { Id: 832273, PropertyName: "value", Value: "[datafield:dscription]" },
        { Id: 407544, PropertyName: "value", Value: "[datafield:quantitywithuom]" },
        { Id: 407808, PropertyName: "value", Value: "[datafield:whscode]" },
        { Id: 457784, PropertyName: "value", Value: "Supplier Information" },
        { Id: 147379, PropertyName: "value", Value: "Supplier Code" },
        { Id: 721403, PropertyName: "value", Value: "Vehicle Plate / Container Number" },
        { Id: 9770, PropertyName: "value", Value: "Customs Agent Information" },
        { Id: 808807, PropertyName: "value", Value: "Supplier Code" },
        { Id: 590446, PropertyName: "value", Value: "Vehicle Plate / Container Number" },
        { Id: 796261, PropertyName: "value", Value: "Requested Vehicles" },
        { Id: 364436, PropertyName: "label", Value: "New Request" },
        { Id: 816746, PropertyName: "value", Value: "Logistic Supplier" },
        { Id: 412934, PropertyName: "value", Value: "Vehicle Plate" },
        { Id: 66302, PropertyName: "value", Value: "Loading Date" },
        { Id: 884605, PropertyName: "value", Value: "Freight Invoice" },
        { Id: 562376, PropertyName: "value", Value: "Delivery Date" },
        { Id: 551617, PropertyName: "value", Value: "Status" },
        { Id: 190718, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 753514, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 732059, PropertyName: "value", Value: "[datafield:vehicleplate]" },
        { Id: 360522, PropertyName: "value", Value: "[datafield:loadingdate]" },
        { Id: 118771, PropertyName: "value", Value: "[datafield:navlun]" },
        { Id: 631631, PropertyName: "value", Value: "[datafield:currencycode]" },
        { Id: 722139, PropertyName: "value", Value: "View" },
        { Id: 12418, PropertyName: "value", Value: "[datafield:deliverydate]" },
        { Id: 462385, PropertyName: "value", Value: "[datafield:translatedstatus]" },
        { Id: 503457, PropertyName: "value", Value: "[datafield:vehiclelastlocation]" },
        { Id: 252091, PropertyName: "title", Value: "Locations" },
        { Id: 906287, PropertyName: "title", Value: "View" },
        { Id: 962201, PropertyName: "title", Value: "Assign Vehicle" },
        { Id: 284770, PropertyName: "title", Value: "Edit" },
        { Id: 87482, PropertyName: "title", Value: "Delete" },
        { Id: 841899, PropertyName: "label", Value: "pageinittrigger" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.orderprocess_80582_value_kuikaTableRef = React.createRef();
    this.orderprocess_190718_value_kuikaTableRef = React.createRef();
    this.orderprocess_427261_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      GetLanguage: "",
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      GetOrderList: [],
      SqlGetCustomerDetailsByCardNo: [],
      spGetOrdersVehicleCount: [],
      spGetOrderVehicles: [],
      ChangeEnabledOf: "",
      GoBack: "",
      GetDeliveryList: [],
      sqlDeleteVehicleRequest: 0,
      TriggerEvent: "",
      isComp456541Visible: "hidden",
      isComp342840Visible: "hidden",
      isComp466684Visible: "hidden",
      isComp844271Visible: "hidden",
      isComp713708Visible: "hidden",
      isComp750060Visible: "hidden",
      isComp364436Visible: "hidden",
      isComp947300Visible: "hidden",
      isComp559802Enabled: "enabled",
      isComp782986Enabled: "enabled",
      isComporderprocess_122442AuthorizationVisible: "visible",
      isComporderprocess_28145AuthorizationVisible: "visible",
      isComporderprocess_860641AuthorizationVisible: "visible",
      isComporderprocess_112613AuthorizationVisible: "visible",
      isComporderprocess_542449AuthorizationVisible: "visible",
      isComporderprocess_874326AuthorizationVisible: "visible",
      isComporderprocess_49575AuthorizationVisible: "visible",
      isComporderprocess_119229AuthorizationVisible: "visible",
      isComporderprocess_364436AuthorizationVisible: "visible",
      isComporderprocess_434627AuthorizationVisible: "visible",
      isComporderprocess_666755AuthorizationVisible: "visible",
      isComporderprocess_36086AuthorizationVisible: "visible",
      isComporderprocess_975332AuthorizationVisible: "visible",
      isComporderprocess_205945AuthorizationVisible: "visible",
      isComporderprocess_142879AuthorizationVisible: "visible",
      isComporderprocess_980785AuthorizationVisible: "visible",
      isComporderprocess_559802AuthorizationEnabled: "enabled",
      isComporderprocess_782986AuthorizationEnabled: "enabled"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("orderprocess", "orderprocess");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.OrderProcessPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_122442AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_28145AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_860641AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "e6c1fd52-eece-7ba2-b39e-d53dabf91a54",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "CustomsAgent",
      "LogisticSupplier",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_112613AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.disableIsNotAuthorized(this, "isComporderprocess_559802AuthorizationEnabled", []);
    ReactSystemFunctions.disableIsNotAuthorized(this, "isComporderprocess_782986AuthorizationEnabled", []);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_542449AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_874326AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_49575AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_119229AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_364436AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_434627AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Logistic",
      "LogisticSupplier",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_666755AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Logistic",
      "LogisticSupplier",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_36086AuthorizationVisible", [
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "Logistic",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_975332AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "e6c1fd52-eece-7ba2-b39e-d53dabf91a54",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "CustomsAgent",
      "LogisticSupplier",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_205945AuthorizationVisible", [
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_142879AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "Administrator",
      "Sale",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComporderprocess_980785AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("orderprocess", "orderprocess");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = [
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "CustomsAgent",
      "LogisticSupplier",
      "Finance",
      "Distributor"
    ];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("orderprocess", "orderprocess");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.OrderProcessPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      orderprocess_342840_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined,
      orderprocess_51867_value: this.state.GetOrderList?.at?.(0)?.shiptoaddressmultiline ?? undefined,
      orderprocess_559802_value: this.state.GetOrderList?.at?.(0)?.isincludeshipping ?? undefined,
      orderprocess_782986_value: this.state.GetOrderList?.at?.(0)?.isabroad ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  OrderProcessPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrderProcessPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  OrderProcessPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      OrderNumber_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      CustomerCardName_3: ReactSystemFunctions.convertToTypeByName(null, "string"),
      orderNo_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      pageCount_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "orderprocess_427261_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      current_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "orderprocess_427261_value", "current", "", "", "")
        ),
        "number"
      ),
      orderNo_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      lang_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "OrderProcess/OrderProcessPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.orderprocess_844271_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.orderprocess_342840_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp456541Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp342840Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp466684Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp844271Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GetOrderList = result?.data.getOrderList;

    formVars.orderprocess_312999_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardname : null
    );
    formVars.orderprocess_874309_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.custrefno : null
    );
    formVars.orderprocess_844427_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardcode : null
    );
    formVars.orderprocess_51867_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.shiptoaddressmultiline : null
    );
    formVars.orderprocess_691347_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docnum : null
    );
    formVars.orderprocess_375053_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    formVars.orderprocess_887189_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.transpname : null
    );
    formVars.orderprocess_559802_value = ReactSystemFunctions.value(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.isincludeshipping : null
    );
    formVars.orderprocess_782986_value = ReactSystemFunctions.value(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.isabroad : null
    );
    formVars.orderprocess_6937_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.plate : null
    );
    stateVars.SqlGetCustomerDetailsByCardNo = result?.data.sqlGetCustomerDetailsByCardNo;
    formVars.orderprocess_926485_value = ReactSystemFunctions.toString(
      this,
      stateVars.SqlGetCustomerDetailsByCardNo?.length > 0
        ? stateVars.SqlGetCustomerDetailsByCardNo[0]?.formattedPhone
        : null
    );
    stateVars.spGetOrdersVehicleCount = result?.data.spGetOrdersVehicleCount;
    stateVars.isComp364436Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spGetOrdersVehicleCount?.length > 0 ? stateVars.spGetOrdersVehicleCount[0]?.count : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spGetOrderVehicles = result?.data.spGetOrderVehicles;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrderProcessPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrderProcessPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp559802Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp782986Enabled", "disabled");
    stateVars.isComp456541Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.orderprocess_342840_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp342840Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp466684Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.orderprocess_844271_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp844271Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.orderprocess_312999_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
    );

    formVars.orderprocess_874309_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
    );

    formVars.orderprocess_844427_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardcode : null
    );

    formVars.orderprocess_51867_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.shiptoaddressmultiline : null
    );

    formVars.orderprocess_691347_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docnum : null
    );

    formVars.orderprocess_375053_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    formVars.orderprocess_887189_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.transpname : null
    );

    formVars.orderprocess_559802_value = ReactSystemFunctions.value(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.isincludeshipping : null
    );

    formVars.orderprocess_782986_value = ReactSystemFunctions.value(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.isabroad : null
    );

    stateVars.dataSource_80582 = this.state.GetOrderList;
    formVars.orderprocess_993928_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.itemcode : null
    );

    formVars.orderprocess_832273_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.dscription : null
    );

    formVars.orderprocess_407544_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.quantitywithuom : null
    );

    formVars.orderprocess_407808_value = ReactSystemFunctions.value(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.whscode : null
    );

    formVars.orderprocess_926485_value = ReactSystemFunctions.toString(
      this,
      this.state.SqlGetCustomerDetailsByCardNo?.length > 0
        ? this.state.SqlGetCustomerDetailsByCardNo[0]?.formattedPhone
        : null
    );

    formVars.orderprocess_6937_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.plate : null
    );

    stateVars.isComp364436Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spGetOrdersVehicleCount?.length > 0 ? this.state.spGetOrdersVehicleCount[0]?.count : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_190718 = this.state.spGetOrderVehicles;
    formVars.orderprocess_427261_total = ReactSystemFunctions.value(
      this,
      this.state.spGetOrdersVehicleCount?.length > 0 ? this.state.spGetOrdersVehicleCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  OrderProcessComponent_720975_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrderProcess",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "723574",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderProcessComponent_611735_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrderProcess",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "178685",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderProcessComponent_839410_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderProcessComponent_575989_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderProcessComponent_364436_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("RequestVehicle", "vehicleId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "RequestVehicle",
      "orderNo",
      this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno
    );
    KuikaAppManager.addToPageInputVariables(
      "RequestVehicle",
      "cardNo",
      this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrderProcess",
      "RequestVehicle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "283897",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderProcessComponent_722139_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewInvoice",
      "vehicleRequestId",
      ReactSystemFunctions.value(this, "orderprocess_190718_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrderProcess",
      "PreviewInvoice",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "827840",
      null,
      "right",
      null,
      "60%",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderProcessComponent_36086_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrderNumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      CustomerCardName_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "OrderProcess/OrderProcessComponent_36086_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetOrderList = result?.data.getOrderList;

    formVars.orderprocess_312999_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardname : null
    );
    formVars.orderprocess_874309_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.custrefno : null
    );
    formVars.orderprocess_844427_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardcode : null
    );
    formVars.orderprocess_51867_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.shiptoaddressmultiline : null
    );
    formVars.orderprocess_691347_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docnum : null
    );
    formVars.orderprocess_375053_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    formVars.orderprocess_887189_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.transpname : null
    );
    formVars.orderprocess_559802_value = ReactSystemFunctions.value(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.isincludeshipping : null
    );
    formVars.orderprocess_782986_value = ReactSystemFunctions.value(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.isabroad : null
    );
    formVars.orderprocess_6937_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.plate : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrderProcessComponent_36086_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrderProcessComponent_36086_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AddVehicleLocation",
      "vehicleId",
      ReactSystemFunctions.value(this, "orderprocess_190718_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "AddVehicleLocation",
      "orderNo",
      this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno
    );
    KuikaAppManager.addToPageInputVariables(
      "AddVehicleLocation",
      "cardNo",
      this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno
    );
    KuikaAppManager.addToPageInputVariables(
      "AddVehicleLocation",
      "custRefNo",
      ReactSystemFunctions.toString(
        this,
        this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.OrderProcessComponent_36086_onClick2_,
      "OrderProcess",
      "AddVehicleLocation",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "501171",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  OrderProcessComponent_36086_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.orderprocess_312999_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
    );

    formVars.orderprocess_874309_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
    );

    formVars.orderprocess_844427_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardcode : null
    );

    formVars.orderprocess_51867_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.shiptoaddressmultiline : null
    );

    formVars.orderprocess_691347_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docnum : null
    );

    formVars.orderprocess_375053_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    formVars.orderprocess_887189_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.transpname : null
    );

    formVars.orderprocess_559802_value = ReactSystemFunctions.value(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.isincludeshipping : null
    );

    formVars.orderprocess_782986_value = ReactSystemFunctions.value(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.isabroad : null
    );

    stateVars.dataSource_80582 = this.state.GetOrderList;
    formVars.orderprocess_993928_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.itemcode : null
    );

    formVars.orderprocess_832273_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.dscription : null
    );

    formVars.orderprocess_407544_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.quantitywithuom : null
    );

    formVars.orderprocess_407808_value = ReactSystemFunctions.value(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.whscode : null
    );

    formVars.orderprocess_6937_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.plate : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderProcessComponent_975332_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrderNumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      DeliveryNumber_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "OrderProcess/OrderProcessComponent_975332_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetDeliveryList = result?.data.getDeliveryList;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrderProcessComponent_975332_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrderProcessComponent_975332_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AssignedVehicleView",
      "vehicleId",
      ReactSystemFunctions.value(this, "orderprocess_190718_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "AssignedVehicleView",
      "orderNo",
      this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno
    );
    KuikaAppManager.addToPageInputVariables(
      "AssignedVehicleView",
      "cardNo",
      this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrderProcess",
      "AssignedVehicleView",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "269223",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderProcessComponent_205945_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AssignVehicle",
      "orderNo",
      this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno
    );
    KuikaAppManager.addToPageInputVariables(
      "AssignVehicle",
      "vehicleId",
      ReactSystemFunctions.value(this, "orderprocess_190718_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "AssignVehicle",
      "cardCode",
      this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrderProcess",
      "AssignVehicle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "54457",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderProcessComponent_142879_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "RequestVehicle",
      "vehicleId",
      ReactSystemFunctions.value(this, "orderprocess_190718_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "RequestVehicle",
      "orderNo",
      this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno
    );
    KuikaAppManager.addToPageInputVariables(
      "RequestVehicle",
      "cardNo",
      this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrderProcess",
      "RequestVehicle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "423853",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderProcessComponent_980785_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "orderprocess_190718_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isLessOrEqual(ReactSystemFunctions.value(this, "orderprocess_190718_value", "status"), "30")
    ) {
      const result = await Swal.fire({
        title: ReactSystemFunctions.translateCustomActions(
          this.ml,
          "b04025f9_0998_30f0_a19d_d47136ed0291_confirmation",
          this.defaultML,
          "Araç talebini silmek istediğinizden emin misiniz?"
        ),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText:
          localStorage.getItem("ml") === "tr_TR"
            ? "Onayla"
            : localStorage.getItem("ml") === "fr_FR"
            ? "Confirmer"
            : "Confirm",
        cancelButtonText:
          localStorage.getItem("ml") === "tr_TR"
            ? "Vazgeç"
            : localStorage.getItem("ml") === "fr_FR"
            ? "Annuler"
            : "Cancel"
      });

      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "OrderProcess/OrderProcessComponent_980785_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.sqlDeleteVehicleRequest = result?.data.sqlDeleteVehicleRequest;
        this.props.form.setFieldsValue(formVars);
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
            await this.OrderProcessComponent_980785_onClick1_();
            resolve();
          });
        });
      }
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.OrderProcessComponent_980785_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrderProcessComponent_980785_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.OrderProcessComponent_841899_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderProcessComponent_841899_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrderNumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      CustomerCardName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      orderNo_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      pageCount_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "orderprocess_427261_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      current_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "orderprocess_427261_value", "current", "", "", "")
        ),
        "number"
      ),
      orderNo_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      lang_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "OrderProcess/OrderProcessComponent_841899_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetOrderList = result?.data.getOrderList;

    formVars.orderprocess_312999_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardname : null
    );
    formVars.orderprocess_874309_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.custrefno : null
    );
    formVars.orderprocess_844427_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardcode : null
    );
    formVars.orderprocess_51867_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.shiptoaddressmultiline : null
    );
    formVars.orderprocess_691347_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docnum : null
    );
    formVars.orderprocess_375053_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    formVars.orderprocess_887189_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.transpname : null
    );
    formVars.orderprocess_559802_value = ReactSystemFunctions.value(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.isincludeshipping : null
    );
    formVars.orderprocess_782986_value = ReactSystemFunctions.value(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.isabroad : null
    );
    formVars.orderprocess_6937_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.plate : null
    );
    stateVars.SqlGetCustomerDetailsByCardNo = result?.data.sqlGetCustomerDetailsByCardNo;
    formVars.orderprocess_926485_value = ReactSystemFunctions.toString(
      this,
      stateVars.SqlGetCustomerDetailsByCardNo?.length > 0
        ? stateVars.SqlGetCustomerDetailsByCardNo[0]?.formattedPhone
        : null
    );
    stateVars.spGetOrdersVehicleCount = result?.data.spGetOrdersVehicleCount;
    stateVars.isComp364436Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spGetOrdersVehicleCount?.length > 0 ? stateVars.spGetOrdersVehicleCount[0]?.count : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spGetOrderVehicles = result?.data.spGetOrderVehicles;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrderProcessComponent_841899_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrderProcessComponent_841899_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.orderprocess_312999_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
    );

    formVars.orderprocess_874309_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
    );

    formVars.orderprocess_844427_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardcode : null
    );

    formVars.orderprocess_51867_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.shiptoaddressmultiline : null
    );

    formVars.orderprocess_691347_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docnum : null
    );

    formVars.orderprocess_375053_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    formVars.orderprocess_887189_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.transpname : null
    );

    formVars.orderprocess_559802_value = ReactSystemFunctions.value(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.isincludeshipping : null
    );

    formVars.orderprocess_782986_value = ReactSystemFunctions.value(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.isabroad : null
    );

    stateVars.dataSource_80582 = this.state.GetOrderList;
    formVars.orderprocess_993928_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.itemcode : null
    );

    formVars.orderprocess_832273_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.dscription : null
    );

    formVars.orderprocess_407544_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.quantitywithuom : null
    );

    formVars.orderprocess_407808_value = ReactSystemFunctions.value(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.whscode : null
    );

    formVars.orderprocess_926485_value = ReactSystemFunctions.toString(
      this,
      this.state.SqlGetCustomerDetailsByCardNo?.length > 0
        ? this.state.SqlGetCustomerDetailsByCardNo[0]?.formattedPhone
        : null
    );

    formVars.orderprocess_6937_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.plate : null
    );

    stateVars.dataSource_190718 = this.state.spGetOrderVehicles;
    formVars.orderprocess_427261_total = ReactSystemFunctions.value(
      this,
      this.state.spGetOrdersVehicleCount?.length > 0 ? this.state.spGetOrdersVehicleCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [723574, 178685, 283897, 501171, 269223, 54457, 423853] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.OrderProcessPageInit();
    }
    if (diId == 501171) {
      isErrorOccurred = await this.OrderProcessComponent_36086_onClick2_();
      if (isErrorOccurred) return true;
    }
  }
}
