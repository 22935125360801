import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashboardEditor_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashboardEditor_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  SetLanguage: string;
  GetLanguage: string;
  Logout: boolean;
  goToSignInActivity: any;
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  spGetDashboardSurveys: any[];
  spGetAllCountOfSurveysByStatus: any[];
  isComp506021Visible: "visible" | "hidden";
  isComp136873Visible: "visible" | "hidden";
  isComp521212Visible: "visible" | "hidden";
  isComp72123Visible: "visible" | "hidden";
  isComp853396Visible: "visible" | "hidden";
  isComp342161Visible: "visible" | "hidden";
  isComp327045Visible: "visible" | "hidden";
  isComp787508Visible: "visible" | "hidden";
}

export class DashboardEditor_ScreenBase extends React.PureComponent<IDashboardEditor_ScreenProps, any> {
  dashboardeditor_30870_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 513345, PropertyName: "value", Value: "Anketler", TagName: "Label7_value" },
        { Id: 963903, PropertyName: "value", Value: "Gönderilen", TagName: "Label2_value" },
        { Id: 109585, PropertyName: "value", Value: "Devam Eden", TagName: "Label4_value" },
        { Id: 72255, PropertyName: "value", Value: "Tamamlanan", TagName: "Label6_value" },
        { Id: 74138, PropertyName: "value", Value: "Son Anketler (10)", TagName: "Label8_value" },
        { Id: 458284, PropertyName: "value", Value: "Tümü", TagName: "Label9_value" },
        { Id: 56586, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 275436, PropertyName: "label", Value: "Çıkış Yap", TagName: "ContentMenuItem2_2_label" },
        {
          Id: 30870,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 923880, PropertyName: "title", Value: "Şirket", TagName: "TableColumn7_title" },
        { Id: 849005, PropertyName: "title", Value: "Sipariş No", TagName: "TableColumn0_0_title" },
        { Id: 700468, PropertyName: "title", Value: "İsim Soyisim", TagName: "TableColumn0_1_title" },
        { Id: 795677, PropertyName: "title", Value: "Gönderilme Tarihi", TagName: "TableColumn0_2_title" },
        { Id: 552481, PropertyName: "title", Value: "Tamamlanma Tarihi", TagName: "TableColumn0_3_title" },
        { Id: 37660, PropertyName: "title", Value: "Durumu", TagName: "TableColumn0_4_title" },
        { Id: 869801, PropertyName: "value", Value: "Anket Gönderildi", TagName: "Label2_1_value" },
        { Id: 238166, PropertyName: "value", Value: "Anket Tamamlanmadı", TagName: "Label2_1_value" },
        { Id: 142943, PropertyName: "value", Value: "Anket Tamamlandı", TagName: "Label2_1_value" },
        { Id: 303096, PropertyName: "title", Value: "Görüntüle", TagName: "Tooltip0_0_title" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "161e898f-d503-4d01-8b08-1e22495e987b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 506021, PropertyName: "value", Value: "TR" },
        { Id: 136873, PropertyName: "value", Value: "TR" },
        { Id: 774546, PropertyName: "value", Value: "|" },
        { Id: 521212, PropertyName: "value", Value: "EN" },
        { Id: 72123, PropertyName: "value", Value: "EN" },
        { Id: 56586, PropertyName: "label", Value: "Profile" },
        { Id: 275436, PropertyName: "label", Value: "Logout" },
        { Id: 513345, PropertyName: "value", Value: "Surveys" },
        { Id: 963903, PropertyName: "value", Value: "Sent" },
        { Id: 109585, PropertyName: "value", Value: "Incomplete" },
        { Id: 72255, PropertyName: "value", Value: "Completed" },
        { Id: 74138, PropertyName: "value", Value: "Surveys (10)" },
        { Id: 458284, PropertyName: "value", Value: "All" },
        { Id: 30870, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 923880, PropertyName: "title", Value: "Company" },
        { Id: 179972, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 849005, PropertyName: "title", Value: "Order No" },
        { Id: 764335, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 700468, PropertyName: "title", Value: "Name Surname" },
        { Id: 127068, PropertyName: "value", Value: "[datafield:name]" },
        { Id: 760629, PropertyName: "value", Value: "[datafield:surname]" },
        { Id: 795677, PropertyName: "title", Value: "Sent Date" },
        { Id: 344477, PropertyName: "value", Value: "[datafield:sentdate]" },
        { Id: 552481, PropertyName: "title", Value: "Completion Date" },
        { Id: 14850, PropertyName: "value", Value: "[datafield:completiondate]" },
        { Id: 966464, PropertyName: "value", Value: "--" },
        { Id: 37660, PropertyName: "title", Value: "Status" },
        { Id: 869801, PropertyName: "value", Value: "Survey Sent" },
        { Id: 238166, PropertyName: "value", Value: "Survey Incomplete" },
        { Id: 142943, PropertyName: "value", Value: "Survey Completed" },
        { Id: 303096, PropertyName: "title", Value: "View" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardeditor_30870_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      SetLanguage: "",
      GetLanguage: "",
      Logout: false,
      goToSignInActivity: "",
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      spGetDashboardSurveys: [],
      spGetAllCountOfSurveysByStatus: [],
      isComp506021Visible: "hidden",
      isComp136873Visible: "hidden",
      isComp521212Visible: "hidden",
      isComp72123Visible: "hidden",
      isComp853396Visible: "hidden",
      isComp342161Visible: "hidden",
      isComp327045Visible: "hidden",
      isComp787508Visible: "hidden"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardeditor", "dashboardeditor");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashboardEditorPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardeditor", "dashboardeditor");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Editor"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardeditor", "dashboardeditor");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashboardEditorPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboardeditor_342161_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DashboardEditorPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp506021Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp136873Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp521212Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp72123Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardEditorPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashboardEditorPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashboardEditor/DashboardEditorPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.dashboardeditor_787508_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.dashboardeditor_342161_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp853396Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp342161Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327045Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787508Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spGetDashboardSurveys = result?.data.spGetDashboardSurveys;

    stateVars.spGetAllCountOfSurveysByStatus = result?.data.spGetAllCountOfSurveysByStatus;
    formVars.dashboardeditor_614144_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetAllCountOfSurveysByStatus?.length > 0
        ? stateVars.spGetAllCountOfSurveysByStatus[0]?.countOfSent
        : null
    );
    formVars.dashboardeditor_731637_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetAllCountOfSurveysByStatus?.length > 0
        ? stateVars.spGetAllCountOfSurveysByStatus[0]?.countOfUncompleted
        : null
    );
    formVars.dashboardeditor_710559_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetAllCountOfSurveysByStatus?.length > 0
        ? stateVars.spGetAllCountOfSurveysByStatus[0]?.countOfCompleted
        : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardEditorPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardEditorPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp506021Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp136873Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp521212Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp72123Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp853396Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardeditor_342161_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp342161Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327045Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardeditor_787508_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp787508Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardeditor_614144_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetAllCountOfSurveysByStatus?.length > 0
        ? this.state.spGetAllCountOfSurveysByStatus[0]?.countOfSent
        : null
    );

    formVars.dashboardeditor_731637_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetAllCountOfSurveysByStatus?.length > 0
        ? this.state.spGetAllCountOfSurveysByStatus[0]?.countOfUncompleted
        : null
    );

    formVars.dashboardeditor_710559_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetAllCountOfSurveysByStatus?.length > 0
        ? this.state.spGetAllCountOfSurveysByStatus[0]?.countOfCompleted
        : null
    );

    stateVars.dataSource_30870 = this.state.spGetDashboardSurveys;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashboardEditorComponent_844448_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardEditor",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "889963",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardEditorComponent_506021_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp506021Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp136873Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp521212Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp72123Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardEditorComponent_521212_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "en_US");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp506021Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp136873Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp521212Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp72123Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardEditorComponent_56586_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardEditor",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "830857",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardEditorComponent_275436_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardEditorComponent_458284_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardEditor",
      "SurveyParticipants",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardEditorComponent_703025_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SurveyDetails",
      "surveyId",
      ReactSystemFunctions.value(this, "dashboardeditor_30870_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardEditor",
      "SurveyDetails",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "679993",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [889963, 830857, , 679993] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashboardEditorPageInit();
    }
  }
}
