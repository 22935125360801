import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRequestVehicle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRequestVehicle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  ToggleVisibilityOf: string;
  getCurrencyList: any[];
  sqlGetLogisticSuppliersSB: any[];
  GetOrderList: any[];
  getDistributorBySAPCode: any[];
  TriggerEvent: string;
  SetValueOf: any;
  IfThenElse: boolean;
  sqlGetVehicleRequestById: any[];
  SqlGetCustomerDetailsByCardNo: any[];
  checkOrderExists: any[];
  GetKUserID: any[];
  sqlSaveOrder: number;
  sqlGetOrderIdByOrderNo: any[];
  checkVehicleRequestExist: any[];
  sqlSaveVehicleRequest: number;
  sqlGetLogisticSupplierUsers: any[];
  SendMail: boolean;
  Notify: boolean;
  isComp860717Visible: "visible" | "hidden";
  isComp42131Visible: "visible" | "hidden";
  isComprequestvehicle_860717AuthorizationVisible: "visible" | "hidden";
  isComprequestvehicle_558014AuthorizationVisible: "visible" | "hidden";
}

export class RequestVehicle_ScreenBase extends React.PureComponent<IRequestVehicle_ScreenProps, any> {
  requestvehicle_631799_value_kuikaSelectBoxRef: React.RefObject<any>;
  requestvehicle_846231_value_kuikaDateRef: React.RefObject<any>;
  requestvehicle_195414_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 42131, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 521089, PropertyName: "value", Value: "Araç Talebi", TagName: "Label10_value" },
        { Id: 176514, PropertyName: "value", Value: "Sipariş No", TagName: "Label0_0_value" },
        { Id: 989728, PropertyName: "value", Value: "Sipariş Tarihi", TagName: "Label0_0_value" },
        { Id: 323324, PropertyName: "value", Value: "Müşteri İsmi", TagName: "Label0_0_value" },
        { Id: 53326, PropertyName: "value", Value: "Cari Kart No", TagName: "Label0_0_value" },
        { Id: 607676, PropertyName: "value", Value: "Teslimat Adresi", TagName: "Label0_0_value" },
        { Id: 908725, PropertyName: "value", Value: "Teslimat Ülkesi", TagName: "Label0_0_value" },
        { Id: 811812, PropertyName: "value", Value: "Telefon", TagName: "Label0_0_value" },
        { Id: 666714, PropertyName: "value", Value: "Lojistik Tedarikçi", TagName: "Label0_0_value" },
        { Id: 723458, PropertyName: "value", Value: "Yükleme Adresi", TagName: "Label0_0_value" },
        { Id: 330337, PropertyName: "value", Value: "Yükleme Tarihi", TagName: "Label0_0_value" },
        { Id: 448296, PropertyName: "value", Value: "Navlun", TagName: "Label0_0_value" },
        { Id: 846231, PropertyName: "placeholder", Value: "Tarih Seçin...", TagName: "Date1_placeholder" },
        { Id: 558014, PropertyName: "label", Value: "Kaydet", TagName: "Button2_label" },
        { Id: 631799, PropertyName: "placeholder", Value: "Seçim Yapın...", TagName: "SelectBox1_placeholder" },
        { Id: 808543, PropertyName: "value", Value: "Para Birimi", TagName: "Label1_value" },
        {
          Id: 195414,
          PropertyName: "placeholder",
          Value: "Para birimi seçiniz...",
          TagName: "sbCurrencyCode_placeholder"
        }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "a5957fc6-6e73-40a0-a823-c2dc22e1b8bb",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 176514, PropertyName: "value", Value: "Order Number" },
        { Id: 989728, PropertyName: "value", Value: "Order Date" },
        { Id: 323324, PropertyName: "value", Value: "Customer Name" },
        { Id: 53326, PropertyName: "value", Value: "Card Number" },
        { Id: 607676, PropertyName: "value", Value: "Shipping Address" },
        { Id: 908725, PropertyName: "value", Value: "Shipping Country" },
        { Id: 811812, PropertyName: "value", Value: "Phone" },
        { Id: 521089, PropertyName: "value", Value: "Vehicle Request" },
        { Id: 666714, PropertyName: "value", Value: "Logistic Supplier" },
        { Id: 631799, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 723458, PropertyName: "value", Value: "Loading Address" },
        { Id: 330337, PropertyName: "value", Value: "Loading Date" },
        { Id: 846231, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 448296, PropertyName: "value", Value: "Freigh" },
        { Id: 791184, PropertyName: "placeholder", Value: "33.45" },
        { Id: 808543, PropertyName: "value", Value: "Currency" },
        { Id: 195414, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 42131, PropertyName: "label", Value: "pageinittrigger" },
        { Id: 558014, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.requestvehicle_631799_value_kuikaSelectBoxRef = React.createRef();
    this.requestvehicle_846231_value_kuikaDateRef = React.createRef();
    this.requestvehicle_195414_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      ToggleVisibilityOf: "",
      getCurrencyList: [],
      sqlGetLogisticSuppliersSB: [],
      GetOrderList: [],
      getDistributorBySAPCode: [],
      TriggerEvent: "",
      IfThenElse: false,
      sqlGetVehicleRequestById: [],
      SqlGetCustomerDetailsByCardNo: [],
      checkOrderExists: [],
      sqlGetOrderIdByOrderNo: [],
      checkVehicleRequestExist: [],
      sqlSaveVehicleRequest: 0,
      sqlGetLogisticSupplierUsers: [],
      isComp860717Visible: "visible",
      isComp42131Visible: "hidden",
      isComprequestvehicle_860717AuthorizationVisible: "visible",
      isComprequestvehicle_558014AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("requestvehicle", "requestvehicle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.RequestVehiclePageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComprequestvehicle_860717AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Logistic",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isComprequestvehicle_558014AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Sale",
      "Logistic"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("requestvehicle", "requestvehicle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("requestvehicle", "requestvehicle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.RequestVehiclePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      requestvehicle_631799_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.logisticSupplierId ?? undefined,
      requestvehicle_999928_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.loadingAddress ?? undefined,
      requestvehicle_846231_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.loadingDate ?? undefined,
      requestvehicle_791184_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.navlun ?? undefined,
      requestvehicle_195414_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.currencyCode ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  RequestVehiclePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp860717Visible");

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehiclePageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RequestVehiclePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      OrderNumber_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      CustomerCardName_2: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "RequestVehicle/RequestVehiclePageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCurrencyList = result?.data.getCurrencyList;

    formVars.requestvehicle_195414_options = stateVars.getCurrencyList;
    stateVars.sqlGetLogisticSuppliersSB = result?.data.sqlGetLogisticSuppliersSB;

    formVars.requestvehicle_631799_options = stateVars.sqlGetLogisticSuppliersSB;
    stateVars.GetOrderList = result?.data.getOrderList;
    formVars.requestvehicle_440572_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docnum : null
    );
    formVars.requestvehicle_280222_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    formVars.requestvehicle_207811_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardname : null
    );
    formVars.requestvehicle_7137_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardcode : null
    );
    formVars.requestvehicle_52853_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.shiptoaddress : null
    );
    formVars.requestvehicle_551965_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.countrys : null
    );
    stateVars.getDistributorBySAPCode = result?.data.getDistributorBySAPCode;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehiclePageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestVehiclePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.RequestVehicleComponent_42131_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehiclePageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RequestVehiclePageInit_if3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehiclePageInit_if31_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RequestVehiclePageInit_if31_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requestvehicle_195414_value",
        ReactSystemFunctions.toString(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.doccurrency : null
        ),
        null,
        "isoCode"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RequestVehiclePageInit_else3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehiclePageInit_else31_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RequestVehiclePageInit_else3f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehiclePageInit_else3f11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RequestVehiclePageInit_else3f11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requestvehicle_195414_value",
        ReactSystemFunctions.toString(
          this,
          this.state.SqlGetCustomerDetailsByCardNo?.length > 0
            ? this.state.SqlGetCustomerDetailsByCardNo[0]?.currency
            : null
        ),
        null,
        "isoCode"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RequestVehiclePageInit_else31_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.SqlGetCustomerDetailsByCardNo?.length > 0
            ? this.state.SqlGetCustomerDetailsByCardNo[0]?.currency
            : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.RequestVehiclePageInit_else3f1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RequestVehiclePageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.doccurrency : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.RequestVehiclePageInit_if3();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.RequestVehiclePageInit_else3();
      if (isErrorOccurred) return true;
    }

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp860717Visible");
    formVars.requestvehicle_440572_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docnum : null
    );

    formVars.requestvehicle_280222_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    formVars.requestvehicle_207811_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
    );

    formVars.requestvehicle_7137_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardcode : null
    );

    formVars.requestvehicle_52853_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.shiptoaddress : null
    );

    formVars.requestvehicle_551965_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.countrys : null
    );

    stateVars.dataSource_631799 = this.state.sqlGetLogisticSuppliersSB;

    stateVars.dataSource_195414 = this.state.getCurrencyList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RequestVehicleComponent_462237_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RequestVehicleComponent_42131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentNo_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RequestVehicle/RequestVehicleComponent_42131_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sqlGetLogisticSuppliersSB = result?.data.sqlGetLogisticSuppliersSB;

    formVars.requestvehicle_631799_value =
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.logisticSupplierId : null;
    formVars.requestvehicle_631799_options = stateVars.sqlGetLogisticSuppliersSB;
    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.requestvehicle_631799_value =
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.logisticSupplierId : null;
    formVars.requestvehicle_631799_options = stateVars.sqlGetLogisticSuppliersSB;
    formVars.requestvehicle_999928_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );
    formVars.requestvehicle_846231_value = ReactSystemFunctions.value(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
    );
    formVars.requestvehicle_846231_disablebeforedate = Date();
    formVars.requestvehicle_195414_value =
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null;
    stateVars.SqlGetCustomerDetailsByCardNo = result?.data.sqlGetCustomerDetailsByCardNo;
    formVars.requestvehicle_352650_value = ReactSystemFunctions.toString(
      this,
      stateVars.SqlGetCustomerDetailsByCardNo?.length > 0
        ? stateVars.SqlGetCustomerDetailsByCardNo[0]?.formattedPhone
        : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehicleComponent_42131_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestVehicleComponent_42131_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.requestvehicle_352650_value = ReactSystemFunctions.toString(
      this,
      this.state.SqlGetCustomerDetailsByCardNo?.length > 0
        ? this.state.SqlGetCustomerDetailsByCardNo[0]?.formattedPhone
        : null
    );

    formVars.requestvehicle_631799_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.logisticSupplierId
        : null
    );

    stateVars.dataSource_631799 = this.state.sqlGetLogisticSuppliersSB;
    formVars.requestvehicle_999928_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );

    formVars.requestvehicle_846231_value = ReactSystemFunctions.value(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
    );

    formVars.requestvehicle_791184_value = ReactSystemFunctions.value(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
    );

    formVars.requestvehicle_195414_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RequestVehicleComponent_558014_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      orderNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RequestVehicle/RequestVehicleComponent_558014_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.checkOrderExists = result?.data.checkOrderExists;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehicleComponent_558014_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestVehicleComponent_558014_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      cardCode_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardcode : null
        ),
        "string"
      ),
      deliveryNumber_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      orderDate_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toDate(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
        ),
        "Date"
      ),
      orderNumber_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      orderStatus_1: ReactSystemFunctions.convertToTypeByName(0, "number"),
      updatedAt_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      itemCode_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.itemcode : null
        ),
        "string"
      ),
      custRefNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      createdAt_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RequestVehicle/RequestVehicleComponent_558014_onClick_if1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetKUserID = result?.data.getKUserID;
    stateVars.sqlSaveOrder = result?.data.sqlSaveOrder;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  RequestVehicleComponent_558014_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.checkOrderExists?.length > 0 ? this.state.checkOrderExists[0]?.orderCount : null
        ),
        "0"
      )
    ) {
      isErrorOccurred = await this.RequestVehicleComponent_558014_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehicleComponent_558014_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RequestVehicleComponent_558014_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "requestvehicle_631799_value",
            "value",
            "sqlGetLogisticSuppliersSB",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "requestvehicle_631799_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "requestvehicle_999928_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "requestvehicle_999928_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requestvehicle_846231_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "requestvehicle_846231_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requestvehicle_791184_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "requestvehicle_791184_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "requestvehicle_195414_value", "value", "getCurrencyList", "isoCode", "")
        ),
        null
      ),
      message: "*",
      formName: "requestvehicle_195414_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      orderNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      logisticSupplierId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requestvehicle_631799_value",
            "value",
            "sqlGetLogisticSuppliersSB",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      loadingAddress_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "requestvehicle_999928_value", "value", "", "", "")
        ),
        "string"
      ),
      loadingDate_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requestvehicle_846231_value", "value", "", "", "")
        ),
        "Date"
      ),
      navlun_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requestvehicle_791184_value", "value", "", "", "")
        ),
        "number"
      ),
      vehicleRequestId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      logisticSupplierId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requestvehicle_631799_value",
            "value",
            "sqlGetLogisticSuppliersSB",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      loadingDate_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requestvehicle_846231_value", "value", "", "", "")
        ),
        "Date"
      ),
      loadingAddress_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "requestvehicle_999928_value", "value", "", "", "")
        ),
        "string"
      ),
      navlun_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requestvehicle_791184_value", "value", "", "", "")
        ),
        "number"
      ),
      currencyCode_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "requestvehicle_195414_value",
            "value",
            "getCurrencyList",
            "isoCode",
            "isoCode"
          )
        ),
        "string"
      ),
      distributorID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getDistributorBySAPCode?.length > 0 ? this.state.getDistributorBySAPCode[0]?.id : null
        ),
        "Guid"
      ),
      salesPersonSAPCode_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.salesperson : null
        ),
        "string"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      approvedAt_2: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      approvedBy_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      assignedAt_2: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      assignedBy_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      containerNumber_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      createdDate_2: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      deliveryDate_2: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      deliveryNumber_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      driverName_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      driverPhoneNumber_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      driverSurname_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      estimatedDeliveryDate_2: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      invoiceNumber_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      isAbroad_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.isabroad : null
        ),
        "boolean"
      ),
      trailerPlateNumber_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      truckPlateNumber_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      supplierId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requestvehicle_631799_value",
            "value",
            "sqlGetLogisticSuppliersSB",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RequestVehicle/RequestVehicleComponent_558014_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sqlGetOrderIdByOrderNo = result?.data.sqlGetOrderIdByOrderNo;
    stateVars.checkVehicleRequestExist = result?.data.checkVehicleRequestExist;
    stateVars.sqlSaveVehicleRequest = result?.data.sqlSaveVehicleRequest;
    stateVars.sqlGetLogisticSupplierUsers = result?.data.sqlGetLogisticSupplierUsers;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehicleComponent_558014_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestVehicleComponent_558014_onClick_if3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehicleComponent_558014_onClick_if31_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RequestVehicleComponent_558014_onClick_if3f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("f6b8fdeb-0439-4b32-b089-f64885316b5c", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetLogisticSupplierUsers?.length > 0
            ? this.state.sqlGetLogisticSupplierUsers[0]?.userMails
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("CFN Kimya Yeni Araç Talebi", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      mailHeader_0: ReactSystemFunctions.convertToTypeByName("Yeni Araç Talebi", "string"),
      orderNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      mainText_0: ReactSystemFunctions.convertToTypeByName(
        "numaralı sipariş için bir araç talebi oluşturuldu.",
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RequestVehicle/RequestVehicleComponent_558014_onClick_if3f1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  RequestVehicleComponent_558014_onClick_if3e1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehicleComponent_558014_onClick_if3e11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestVehicleComponent_558014_onClick_if3e11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("f6b8fdeb-0439-4b32-b089-f64885316b5c", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetLogisticSupplierUsers?.length > 0
            ? this.state.sqlGetLogisticSupplierUsers[0]?.userMails
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("CFN Kimya Araç Talep Değişikliği", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      mailHeader_0: ReactSystemFunctions.convertToTypeByName("Araç Talep Değişikliği", "string"),
      orderNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      mainText_0: ReactSystemFunctions.convertToTypeByName(
        "numaralı sipariş için açılan araç talebinde değişiklik yapıldı. Lütfen uygulamadan gerekli kontrolleri yapınız.",
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.checkVehicleRequestExist?.length > 0 ? this.state.checkVehicleRequestExist[0]?.noAnyChange : null
        ),
        "0"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "RequestVehicle/RequestVehicleComponent_558014_onClick_if3e11_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  RequestVehicleComponent_558014_onClick_if31_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.checkVehicleRequestExist?.length > 0
            ? this.state.checkVehicleRequestExist[0]?.isSupplierEqual
            : null
        ),
        "0"
      )
    ) {
      isErrorOccurred = await this.RequestVehicleComponent_558014_onClick_if3f1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.RequestVehicleComponent_558014_onClick_if3e1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  RequestVehicleComponent_558014_onClick_else3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("f6b8fdeb-0439-4b32-b089-f64885316b5c", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetLogisticSupplierUsers?.length > 0
            ? this.state.sqlGetLogisticSupplierUsers[0]?.userMails
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("CFN Kimya Yeni Araç Talebi", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      mailHeader_0: ReactSystemFunctions.convertToTypeByName("Yeni Araç Talebi", "string"),
      orderNo_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      mainText_0: ReactSystemFunctions.convertToTypeByName(
        "numaralı sipariş için bir araç talebi oluşturuldu.",
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RequestVehicle/RequestVehicleComponent_558014_onClick_else3",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.RequestVehicleComponent_558014_onClick_else31_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RequestVehicleComponent_558014_onClick_else31_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "88876c7f_f91f_ce61_cf17_eaff13b46ba9_notify",
        this.defaultML,
        "Araç talebi başarıyla kaydedildi, tedarikçiye bilgilendirme e-postası atıldı."
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  RequestVehicleComponent_558014_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.checkVehicleRequestExist?.length > 0 ? this.state.checkVehicleRequestExist[0]?.id : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.RequestVehicleComponent_558014_onClick_if3();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.RequestVehicleComponent_558014_onClick_else3();
      if (isErrorOccurred) return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
