import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICurrentEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICurrentEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  SqlGetCurrentById: any[];
  getCurrencyList: any[];
  GetUserCurrentId: any[];
  SaveCurrents: number;
  Notify: boolean;
  isComp898158Visible: "visible" | "hidden";
  isComp457942Visible: "visible" | "hidden";
  isCompcurrentedit_747582AuthorizationVisible: "visible" | "hidden";
}

export class CurrentEdit_ScreenBase extends React.PureComponent<ICurrentEdit_ScreenProps, any> {
  currentedit_566308_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 241619, PropertyName: "value", Value: "Cari Kart No", TagName: "Label3_value" },
        { Id: 747582, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 898158, PropertyName: "value", Value: "Cari Düzenle", TagName: "Label3_0_value" },
        { Id: 457942, PropertyName: "value", Value: "Yeni Cari", TagName: "Label2_value" },
        { Id: 598575, PropertyName: "value", Value: "Cari Adı", TagName: "Label2_0_value" },
        { Id: 61029, PropertyName: "value", Value: "Para Birimi", TagName: "Label0_0_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "d0ec4cc0-1eef-46ae-aaf1-dcc75a8a28d3",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 898158, PropertyName: "value", Value: "Current Edit" },
        { Id: 457942, PropertyName: "value", Value: "New Current" },
        { Id: 241619, PropertyName: "value", Value: "Current No" },
        { Id: 598575, PropertyName: "value", Value: "Current Name" },
        { Id: 61029, PropertyName: "value", Value: "Currency" },
        { Id: 566308, PropertyName: "placeholder", Value: "Para birimi seçiniz..." },
        { Id: 747582, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.currentedit_566308_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      SqlGetCurrentById: [],
      getCurrencyList: [],
      GetUserCurrentId: [],
      SaveCurrents: 0,
      Notify: false,
      isComp898158Visible: "hidden",
      isComp457942Visible: "hidden",
      isCompcurrentedit_747582AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("currentedit", "currentedit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CurrentEditPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcurrentedit_747582AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("currentedit", "currentedit");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Administrator", "Logistic"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("currentedit", "currentedit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CurrentEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      currentedit_814447_value: this.state.SqlGetCurrentById?.at?.(0)?.currentNo ?? undefined,
      currentedit_882967_value: this.state.SqlGetCurrentById?.at?.(0)?.currentName ?? undefined,
      currentedit_566308_value: this.state.SqlGetCurrentById?.at?.(0)?.currency ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CurrentEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CurrentEditPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CurrentEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentId ?? this.props.screenInputs.currentid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CurrentEdit/CurrentEditPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SqlGetCurrentById = result?.data.sqlGetCurrentById;
    formVars.currentedit_814447_value = ReactSystemFunctions.toString(
      this,
      stateVars.SqlGetCurrentById?.length > 0 ? stateVars.SqlGetCurrentById[0]?.currentNo : null
    );
    formVars.currentedit_882967_value = ReactSystemFunctions.toString(
      this,
      stateVars.SqlGetCurrentById?.length > 0 ? stateVars.SqlGetCurrentById[0]?.currentName : null
    );
    formVars.currentedit_566308_value =
      stateVars.SqlGetCurrentById?.length > 0 ? stateVars.SqlGetCurrentById[0]?.currency : null;
    formVars.currentedit_566308_options = stateVars.getCurrencyList;
    stateVars.isComp898158Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SqlGetCurrentById?.length > 0 ? stateVars.SqlGetCurrentById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp457942Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SqlGetCurrentById?.length > 0 ? stateVars.SqlGetCurrentById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getCurrencyList = result?.data.getCurrencyList;

    formVars.currentedit_566308_value =
      stateVars.SqlGetCurrentById?.length > 0 ? stateVars.SqlGetCurrentById[0]?.currency : null;
    formVars.currentedit_566308_options = stateVars.getCurrencyList;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CurrentEditPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CurrentEditPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp898158Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SqlGetCurrentById?.length > 0 ? this.state.SqlGetCurrentById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp457942Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SqlGetCurrentById?.length > 0 ? this.state.SqlGetCurrentById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.currentedit_814447_value = ReactSystemFunctions.toString(
      this,
      this.state.SqlGetCurrentById?.length > 0 ? this.state.SqlGetCurrentById[0]?.currentNo : null
    );

    formVars.currentedit_882967_value = ReactSystemFunctions.toString(
      this,
      this.state.SqlGetCurrentById?.length > 0 ? this.state.SqlGetCurrentById[0]?.currentName : null
    );

    formVars.currentedit_566308_value = ReactSystemFunctions.toString(
      this,
      this.state.SqlGetCurrentById?.length > 0 ? this.state.SqlGetCurrentById[0]?.currency : null
    );

    stateVars.dataSource_566308 = this.state.getCurrencyList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CurrentEditComponent_701269_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CurrentEditComponent_747582_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "currentedit_882967_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "currentedit_882967_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "currentedit_814447_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "currentedit_814447_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "currentedit_566308_value", "value", "getCurrencyList", "isoCode", "")
        ),
        null
      ),
      message: "*",
      formName: "currentedit_566308_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      companyId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CompanyId ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      currentName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "currentedit_882967_value", "value", "", "", "")
        ),
        "string"
      ),
      currentNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "currentedit_814447_value", "value", "", "", "")
        ),
        "string"
      ),
      updatedAt_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      currency_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "currentedit_566308_value", "value", "getCurrencyList", "isoCode", "isoCode")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentId ?? this.props.screenInputs.currentid,
        "Guid"
      ),
      createdAt_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CurrentEdit/CurrentEditComponent_747582_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.SaveCurrents = result?.data.saveCurrents;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CurrentEditComponent_747582_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CurrentEditComponent_747582_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c21638b0_3306_4747_4201_24ea65608992_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
