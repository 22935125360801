import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICustomsAgentOrdersList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICustomsAgentOrdersList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  GetLanguage: string;
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  TriggerEvent: string;
  spGetCurrentCompanyId: any[];
  getVehicleRequestsofCustomAgent: any[];
  getVehicleRequestCountOfCustomsAgent: any[];
  isComp284309Visible: "visible" | "hidden";
  isComp956783Visible: "visible" | "hidden";
  isComp601503Visible: "visible" | "hidden";
  isComp133280Visible: "visible" | "hidden";
  isComp228903Visible: "visible" | "hidden";
}

export class CustomsAgentOrdersList_ScreenBase extends React.PureComponent<ICustomsAgentOrdersList_ScreenProps, any> {
  customsagentorderslist_696651_value_kuikaTableRef: React.RefObject<any>;
  customsagentorderslist_526793_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 71816, PropertyName: "placeholder", Value: "Ara...", TagName: "TextInput1_placeholder" },
        {
          Id: 696651,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 846713, PropertyName: "title", Value: "Sipariş Numarası", TagName: "TableColumn1_0_title" },
        { Id: 154367, PropertyName: "title", Value: "Müşteri", TagName: "TableColumn1_2_title" },
        { Id: 456316, PropertyName: "value", Value: "Belge Bekleyen Siparişler", TagName: "Label2_0_value" },
        { Id: 719758, PropertyName: "title", Value: "Sipariş Tarihi", TagName: "TableColumn6_title" },
        { Id: 201032, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 833959, PropertyName: "label", Value: "Çıkış", TagName: "ContentMenuItem2_2_label" },
        { Id: 198673, PropertyName: "title", Value: "Durum", TagName: "TableColumn7_title" },
        { Id: 730538, PropertyName: "title", Value: "Kayıt Eden", TagName: "TableColumn8_title" },
        { Id: 756278, PropertyName: "title", Value: "Kayıt Tarihi", TagName: "TableColumn9_title" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "e0f351d9-1984-43c6-a0a2-d5f1675db388",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 201032, PropertyName: "label", Value: "Profile" },
        { Id: 833959, PropertyName: "label", Value: "Logout" },
        { Id: 456316, PropertyName: "value", Value: "Document Pending Orders" },
        { Id: 228903, PropertyName: "label", Value: "pageinittrigger" },
        { Id: 71816, PropertyName: "placeholder", Value: "Search.." },
        { Id: 696651, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 154367, PropertyName: "title", Value: "Customer Name" },
        { Id: 469709, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 846713, PropertyName: "title", Value: "Order Number" },
        { Id: 494669, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 719758, PropertyName: "title", Value: "Order Date" },
        { Id: 744865, PropertyName: "value", Value: "[datafield:orderdate]" },
        { Id: 730538, PropertyName: "title", Value: "Created By" },
        { Id: 477924, PropertyName: "value", Value: "[datafield:creatorfullname]" },
        { Id: 198673, PropertyName: "title", Value: "Status" },
        { Id: 494963, PropertyName: "value", Value: "[datafield:orderstatusdesc]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.customsagentorderslist_696651_value_kuikaTableRef = React.createRef();
    this.customsagentorderslist_526793_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      GetLanguage: "",
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      TriggerEvent: "",
      spGetCurrentCompanyId: [],
      getVehicleRequestsofCustomAgent: [],
      getVehicleRequestCountOfCustomsAgent: [],
      isComp284309Visible: "hidden",
      isComp956783Visible: "hidden",
      isComp601503Visible: "hidden",
      isComp133280Visible: "hidden",
      isComp228903Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("customsagentorderslist", "customsagentorderslist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CustomsAgentOrdersListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("customsagentorderslist", "customsagentorderslist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("customsagentorderslist", "customsagentorderslist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CustomsAgentOrdersListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      customsagentorderslist_956783_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CustomsAgentOrdersListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomsAgentOrdersListPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CustomsAgentOrdersListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CustomsAgentOrdersList/CustomsAgentOrdersListPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.customsagentorderslist_133280_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.customsagentorderslist_956783_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp284309Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp956783Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp601503Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp133280Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomsAgentOrdersListPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CustomsAgentOrdersListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.CustomsAgentOrdersListComponent_228903_onClick()) return true;

    stateVars.isComp284309Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.customsagentorderslist_956783_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp956783Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp601503Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.customsagentorderslist_133280_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp133280Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CustomsAgentOrdersListComponent_401583_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CustomsAgentOrdersList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "505387",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomsAgentOrdersListComponent_201032_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CustomsAgentOrdersList",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "106589",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomsAgentOrdersListComponent_833959_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomsAgentOrdersListComponent_228903_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "Guid"
      ),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customsagentorderslist_526793_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customsagentorderslist_526793_value", "current", "", "", "")
        ),
        "number"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customsagentorderslist_71816_value", "value", "", "", "")
        ),
        "string"
      ),
      search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "customsagentorderslist_71816_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CustomsAgentOrdersList/CustomsAgentOrdersListComponent_228903_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetCurrentCompanyId = result?.data.spGetCurrentCompanyId;
    stateVars.getVehicleRequestsofCustomAgent = result?.data.getVehicleRequestsofCustomAgent;

    stateVars.getVehicleRequestCountOfCustomsAgent = result?.data.getVehicleRequestCountOfCustomsAgent;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomsAgentOrdersListComponent_228903_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CustomsAgentOrdersListComponent_228903_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_696651 = this.state.getVehicleRequestsofCustomAgent;
    formVars.customsagentorderslist_526793_total = ReactSystemFunctions.value(
      this,
      this.state.getVehicleRequestCountOfCustomsAgent?.length > 0
        ? this.state.getVehicleRequestCountOfCustomsAgent[0]?.vehicleRequestCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomsAgentOrdersListComponent_71816_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.CustomsAgentOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomsAgentOrdersListComponent_636392_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.CustomsAgentOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomsAgentOrdersListComponent_247973_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "customsagentorderslist_696651_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "customsagentorderslist_696651_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CustomsAgentOrdersList",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [505387, 106589] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.CustomsAgentOrdersListPageInit();
    }
  }
}
