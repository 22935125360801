import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAddVehicleLocation_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAddVehicleLocation_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetDeliveryList: any[];
  GetOrderList: any[];
  sqlGetVehicleRequestById: any[];
  spGetVehicleLocations: any[];
  sqlVehicleLocationCount: any[];
  spCheckDocuments: any[];
  sqlGetCompanyById: any[];
  SetValueOf: any;
  GetUserCurrentId: any[];
  spAddVehicleLocation: any[];
  spSetOrderDelivered: any[];
  sqlGetSurveyId: any[];
  spCurrentsAuthorizedUsers: any[];
  spNotificationTranslate: any[];
  SendNotificationToUsersWithParameters: any;
  TriggerEvent: string;
  Notify: boolean;
  IfThenElse: boolean;
  NAVIGATE: any;
  UploadFile: any;
  spUploadVehicleDocument: any[];
  sqlDeleteVehicleLocation: number;
  isComp274300Visible: "visible" | "hidden";
  isComp160769Visible: "visible" | "hidden";
  isComp451434Visible: "visible" | "hidden";
  isComp655789Visible: "visible" | "hidden";
  isComp797741Visible: "visible" | "hidden";
  isComp217779Visible: "visible" | "hidden";
  isComp34124Visible: "visible" | "hidden";
  isComp201865Visible: "visible" | "hidden";
  isComp374836Visible: "visible" | "hidden";
  isComp146458Visible: "visible" | "hidden";
  isComp923284Visible: "visible" | "hidden";
  isComp269705Visible: "visible" | "hidden";
  isComp513332Visible: "visible" | "hidden";
  isComp51194Visible: "visible" | "hidden";
  isComp764440Visible: "visible" | "hidden";
  isCompaddvehiclelocation_40183AuthorizationVisible: "visible" | "hidden";
  isCompaddvehiclelocation_890903AuthorizationVisible: "visible" | "hidden";
  isCompaddvehiclelocation_198369AuthorizationVisible: "visible" | "hidden";
  isCompaddvehiclelocation_160769AuthorizationVisible: "visible" | "hidden";
  isCompaddvehiclelocation_797741AuthorizationVisible: "visible" | "hidden";
  isCompaddvehiclelocation_663004AuthorizationVisible: "visible" | "hidden";
  isCompaddvehiclelocation_930520AuthorizationVisible: "visible" | "hidden";
  isCompaddvehiclelocation_764440AuthorizationVisible: "visible" | "hidden";
}

export class AddVehicleLocation_ScreenBase extends React.PureComponent<IAddVehicleLocation_ScreenProps, any> {
  addvehiclelocation_250946_value_kuikaPlacesAutocompleteInputRef: React.RefObject<any>;
  addvehiclelocation_158316_value_kuikaTableRef: React.RefObject<any>;
  addvehiclelocation_418573_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 250946, PropertyName: "placeholder", Value: "Konum Ara...", TagName: "LocationSearch0_0_placeholder" },
        { Id: 312333, PropertyName: "value", Value: "Konumlar", TagName: "Label0_0_value" },
        { Id: 798118, PropertyName: "label", Value: "Ekle", TagName: "Button0_0_label" },
        { Id: 217779, PropertyName: "label", Value: "Teslimatı Sonlandır", TagName: "Button0_0_label" },
        { Id: 34124, PropertyName: "label", Value: "Teslimatı Sonlandır", TagName: "Button6_label" },
        { Id: 513332, PropertyName: "value", Value: "Henüz yüklenmedi", TagName: "Label3_0_value" },
        { Id: 51194, PropertyName: "value", Value: "Belgeyi görüntüle", TagName: "Label3_1_value" },
        { Id: 930520, PropertyName: "label", Value: "Yükle", TagName: "Button4_0_label" },
        {
          Id: 158316,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table0_0_nodatafoundmessage"
        },
        { Id: 1196, PropertyName: "title", Value: "Adres", TagName: "TableColumn0_0_title" },
        { Id: 156435, PropertyName: "title", Value: "Tarih", TagName: "TableColumn0_1_title" },
        { Id: 374836, PropertyName: "value", Value: "CMR Belgesi", TagName: "Label3_0_value" },
        { Id: 747637, PropertyName: "value", Value: "Teslimat Bilgisi", TagName: "Label6_0_value" },
        { Id: 904516, PropertyName: "value", Value: "Müşteri Adı", TagName: "Label6_0_value" },
        { Id: 349382, PropertyName: "value", Value: "Müşteri Referans Numarası", TagName: "Label6_0_value" },
        { Id: 544788, PropertyName: "value", Value: "Kart Mumarası", TagName: "Label6_0_value" },
        { Id: 895844, PropertyName: "value", Value: "Sipariş Tarihi", TagName: "Label6_0_value" },
        { Id: 479193, PropertyName: "value", Value: "Sipariş Numarası", TagName: "Label6_0_value" },
        { Id: 118676, PropertyName: "value", Value: "Teslimat Ülkesi", TagName: "Label6_0_value" },
        { Id: 616742, PropertyName: "value", Value: "Teslimat Adresi", TagName: "Label6_0_value" },
        { Id: 951295, PropertyName: "value", Value: "İrsaliye Numarası", TagName: "Label6_0_value" },
        { Id: 54407, PropertyName: "value", Value: "Talep Edilen Araç Bilgisi", TagName: "Label0_0_value" },
        { Id: 378240, PropertyName: "value", Value: "Talep Edildiği Tarih", TagName: "Label0_0_value" },
        { Id: 998996, PropertyName: "value", Value: "Lojistik Tedarikçi", TagName: "Label0_0_value" },
        { Id: 518851, PropertyName: "value", Value: "Yükleme Tarihi", TagName: "Label0_0_value" },
        { Id: 518915, PropertyName: "value", Value: "Navlun", TagName: "Label0_0_value" },
        { Id: 5281, PropertyName: "value", Value: "Yükleme Adresi", TagName: "Label0_0_value" },
        { Id: 555081, PropertyName: "title", Value: "Sil", TagName: "Tooltip1_title" },
        { Id: 146458, PropertyName: "value", Value: "Teslim İrsaliyesi", TagName: "Label1_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "5960fe1c-3e1b-4432-972a-d7a73032dd70",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 747637, PropertyName: "value", Value: "Delivery Information" },
        { Id: 904516, PropertyName: "value", Value: "Customer Name" },
        { Id: 349382, PropertyName: "value", Value: "Customer Referance Number" },
        { Id: 544788, PropertyName: "value", Value: "Card Number" },
        { Id: 895844, PropertyName: "value", Value: "Order Date" },
        { Id: 479193, PropertyName: "value", Value: "Order Number" },
        { Id: 118676, PropertyName: "value", Value: "Shipping Country" },
        { Id: 616742, PropertyName: "value", Value: "Shipping Address" },
        { Id: 951295, PropertyName: "value", Value: "Waybill Number" },
        { Id: 54407, PropertyName: "value", Value: "Requested Vehicle Information" },
        { Id: 378240, PropertyName: "value", Value: "Requested at" },
        { Id: 998996, PropertyName: "value", Value: "Logistic Supplier" },
        { Id: 518851, PropertyName: "value", Value: "Loading Date" },
        { Id: 518915, PropertyName: "value", Value: "Freight" },
        { Id: 5281, PropertyName: "value", Value: "Loading Address" },
        { Id: 312333, PropertyName: "value", Value: "Locations" },
        { Id: 451434, PropertyName: "label", Value: "SetOrderDeliveredTrigger" },
        { Id: 655789, PropertyName: "label", Value: "addLocation" },
        { Id: 250946, PropertyName: "placeholder", Value: "Location search..." },
        { Id: 798118, PropertyName: "label", Value: "Add" },
        { Id: 217779, PropertyName: "label", Value: "Set as Delivered" },
        { Id: 34124, PropertyName: "label", Value: "Set as Delivered" },
        { Id: 374836, PropertyName: "value", Value: "CMR (Convention Marchandise Routier) Document" },
        { Id: 146458, PropertyName: "value", Value: "Delivery Waybill" },
        { Id: 513332, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 51194, PropertyName: "value", Value: "View document" },
        { Id: 930520, PropertyName: "label", Value: "Upload" },
        { Id: 158316, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 1196, PropertyName: "title", Value: "Address" },
        { Id: 880816, PropertyName: "value", Value: "[datafield:address]" },
        { Id: 156435, PropertyName: "title", Value: "Date" },
        { Id: 312240, PropertyName: "value", Value: "[datafield:createddate]" },
        { Id: 555081, PropertyName: "title", Value: "Delete" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.addvehiclelocation_250946_value_kuikaPlacesAutocompleteInputRef = React.createRef();
    this.addvehiclelocation_158316_value_kuikaTableRef = React.createRef();
    this.addvehiclelocation_418573_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetDeliveryList: [],
      GetOrderList: [],
      sqlGetVehicleRequestById: [],
      spGetVehicleLocations: [],
      sqlVehicleLocationCount: [],
      spCheckDocuments: [],
      sqlGetCompanyById: [],
      SetValueOf: "",
      GetUserCurrentId: [],
      spAddVehicleLocation: [],
      spSetOrderDelivered: [],
      sqlGetSurveyId: [],
      spCurrentsAuthorizedUsers: [],
      spNotificationTranslate: [],
      SendNotificationToUsersWithParameters: "",
      IfThenElse: false,
      NAVIGATE: "",
      UploadFile: "",
      spUploadVehicleDocument: [],
      sqlDeleteVehicleLocation: "",
      isComp274300Visible: "hidden",
      isComp160769Visible: "hidden",
      isComp451434Visible: "hidden",
      isComp655789Visible: "hidden",
      isComp797741Visible: "hidden",
      isComp217779Visible: "hidden",
      isComp34124Visible: "hidden",
      isComp201865Visible: "hidden",
      isComp374836Visible: "hidden",
      isComp146458Visible: "hidden",
      isComp923284Visible: "hidden",
      isComp269705Visible: "hidden",
      isComp513332Visible: "hidden",
      isComp51194Visible: "hidden",
      isComp764440Visible: "hidden",
      isCompaddvehiclelocation_40183AuthorizationVisible: "visible",
      isCompaddvehiclelocation_890903AuthorizationVisible: "visible",
      isCompaddvehiclelocation_198369AuthorizationVisible: "visible",
      isCompaddvehiclelocation_160769AuthorizationVisible: "visible",
      isCompaddvehiclelocation_797741AuthorizationVisible: "visible",
      isCompaddvehiclelocation_663004AuthorizationVisible: "visible",
      isCompaddvehiclelocation_930520AuthorizationVisible: "visible",
      isCompaddvehiclelocation_764440AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("addvehiclelocation", "addvehiclelocation");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.AddVehicleLocationPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompaddvehiclelocation_40183AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompaddvehiclelocation_890903AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompaddvehiclelocation_198369AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Logistic",
      "LogisticSupplier",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompaddvehiclelocation_160769AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompaddvehiclelocation_797741AuthorizationVisible", [
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompaddvehiclelocation_663004AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "e6c1fd52-eece-7ba2-b39e-d53dabf91a54",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "CustomsAgent",
      "LogisticSupplier",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompaddvehiclelocation_930520AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompaddvehiclelocation_764440AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "LogisticSupplier"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("addvehiclelocation", "addvehiclelocation");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("addvehiclelocation", "addvehiclelocation");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AddVehicleLocationPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  AddVehicleLocationPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      OrderNumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      DeliveryNumber_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      OrderNumber_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      CustomerCardName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      vehicleId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentPage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_418573_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_418573_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      vehicleId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AddVehicleLocation/AddVehicleLocationPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetDeliveryList = result?.data.getDeliveryList;
    stateVars.GetOrderList = result?.data.getOrderList;
    formVars.addvehiclelocation_437946_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardname : null
    );
    formVars.addvehiclelocation_396767_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.custrefno : null
    );
    formVars.addvehiclelocation_555996_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardcode : null
    );
    formVars.addvehiclelocation_757046_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    formVars.addvehiclelocation_350715_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.country : null
    );
    formVars.addvehiclelocation_267745_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.shiptoaddress : null
    );
    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.addvehiclelocation_956832_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );
    formVars.addvehiclelocation_46678_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.createdDate : null
    );
    formVars.addvehiclelocation_651998_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
    );
    formVars.addvehiclelocation_698469_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
    );
    formVars.addvehiclelocation_254071_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
    );
    formVars.addvehiclelocation_258395_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );
    stateVars.isComp274300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160769Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp797741Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "50"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp217779Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34124Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp201865Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374836Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146458Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp764440Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "50"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spGetVehicleLocations = result?.data.spGetVehicleLocations;

    stateVars.sqlVehicleLocationCount = result?.data.sqlVehicleLocationCount;
    stateVars.spCheckDocuments = result?.data.spCheckDocuments;
    stateVars.isComp923284Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp269705Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp513332Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp51194Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.sqlGetCompanyById = result?.data.sqlGetCompanyById;
    formVars.addvehiclelocation_974819_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetCompanyById?.length > 0 ? stateVars.sqlGetCompanyById[0]?.cardName : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AddVehicleLocationPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AddVehicleLocationPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.addvehiclelocation_437946_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
    );

    formVars.addvehiclelocation_396767_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
    );

    formVars.addvehiclelocation_555996_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardcode : null
    );

    formVars.addvehiclelocation_757046_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    formVars.addvehiclelocation_350715_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.country : null
    );

    formVars.addvehiclelocation_267745_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.shiptoaddress : null
    );

    stateVars.isComp274300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.addvehiclelocation_956832_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );

    formVars.addvehiclelocation_46678_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.createdDate : null
    );

    formVars.addvehiclelocation_974819_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetCompanyById?.length > 0 ? this.state.sqlGetCompanyById[0]?.cardName : null
    );

    formVars.addvehiclelocation_651998_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
    );

    formVars.addvehiclelocation_698469_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
    );

    formVars.addvehiclelocation_254071_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
    );

    formVars.addvehiclelocation_258395_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );

    stateVars.isComp160769Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp797741Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "50"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp217779Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34124Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp201865Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374836Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146458Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp923284Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp269705Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp513332Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp51194Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_158316 = this.state.spGetVehicleLocations;
    stateVars.isComp764440Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "50"
      ) === true
        ? "visible"
        : "hidden";
    formVars.addvehiclelocation_418573_total = ReactSystemFunctions.value(
      this,
      this.state.sqlVehicleLocationCount?.length > 0 ? this.state.sqlVehicleLocationCount[0]?.locationCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AddVehicleLocationComponent_399430_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_451434_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "addvehiclelocation_250946_value",
      ReactSystemFunctions.toString(
        this,
        this.state.GetDeliveryList?.length > 0 ? this.state.GetDeliveryList[0]?.shiptoaddress : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AddVehicleLocationComponent_451434_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AddVehicleLocationComponent_451434_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vehicleId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      createdDate_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      Address_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_250946_value", "Address", "", "", "")
        ),
        "string"
      ),
      latitude_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_250946_value", "Latitude", "", "", "")
        ),
        "number"
      ),
      longitude_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_250946_value", "Longitude", "", "", "")
        ),
        "number"
      ),
      vehicleId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentPage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_418573_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_418573_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      orderNo_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      vehicleId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentNo_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      notification_7: ReactSystemFunctions.convertToTypeByName(2, "number"),
      cardNo_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      screenId_8: ReactSystemFunctions.convertToTypeByName("CF9480F0-77C8-480A-AB5E-F04E07A0873F", "string"),
      screenInputName_8: ReactSystemFunctions.convertToTypeByName("parameter", "string"),
      Id_9: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AddVehicleLocation/AddVehicleLocationComponent_451434_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spAddVehicleLocation = result?.data.spAddVehicleLocation;
    stateVars.spGetVehicleLocations = result?.data.spGetVehicleLocations;

    stateVars.spSetOrderDelivered = result?.data.spSetOrderDelivered;
    stateVars.sqlGetSurveyId = result?.data.sqlGetSurveyId;
    stateVars.sqlVehicleLocationCount = result?.data.sqlVehicleLocationCount;
    stateVars.spCurrentsAuthorizedUsers = result?.data.spCurrentsAuthorizedUsers;
    stateVars.spNotificationTranslate = result?.data.spNotificationTranslate;
    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.addvehiclelocation_956832_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );
    formVars.addvehiclelocation_46678_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.createdDate : null
    );
    formVars.addvehiclelocation_651998_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
    );
    formVars.addvehiclelocation_698469_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
    );
    formVars.addvehiclelocation_254071_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
    );
    formVars.addvehiclelocation_258395_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );
    stateVars.isComp274300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160769Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp797741Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "50"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp217779Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34124Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp201865Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374836Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146458Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp764440Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "50"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AddVehicleLocationComponent_451434_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AddVehicleLocationComponent_451434_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.addvehiclelocation_956832_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );

    formVars.addvehiclelocation_46678_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.createdDate : null
    );

    formVars.addvehiclelocation_651998_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
    );

    formVars.addvehiclelocation_698469_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
    );

    formVars.addvehiclelocation_254071_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
    );

    formVars.addvehiclelocation_258395_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );

    stateVars.dataSource_158316 = this.state.spGetVehicleLocations;
    formVars.addvehiclelocation_418573_total = ReactSystemFunctions.value(
      this,
      this.state.sqlVehicleLocationCount?.length > 0 ? this.state.sqlVehicleLocationCount[0]?.locationCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_655789_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vehicleId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      createdDate_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      Address_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_250946_value", "Address", "", "", "")
        ),
        "string"
      ),
      latitude_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_250946_value", "Latitude", "", "", "")
        ),
        "number"
      ),
      longitude_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_250946_value", "Longitude", "", "", "")
        ),
        "number"
      ),
      vehicleId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentPage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_418573_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_418573_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      vehicleId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentNo_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      notification_5: ReactSystemFunctions.convertToTypeByName(1, "number"),
      cardNo_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AddVehicleLocation/AddVehicleLocationComponent_655789_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spAddVehicleLocation = result?.data.spAddVehicleLocation;
    stateVars.spGetVehicleLocations = result?.data.spGetVehicleLocations;

    stateVars.sqlVehicleLocationCount = result?.data.sqlVehicleLocationCount;
    stateVars.spCurrentsAuthorizedUsers = result?.data.spCurrentsAuthorizedUsers;
    stateVars.spNotificationTranslate = result?.data.spNotificationTranslate;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AddVehicleLocationComponent_655789_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AddVehicleLocationComponent_655789_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        "string"
      ),
      message_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.spNotificationTranslate?.length > 0
            ? this.state.spNotificationTranslate[0]?.notificationText
            : null
        ),
        "string"
      ),
      userNamesJSON_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.spCurrentsAuthorizedUsers?.length > 0 ? this.state.spCurrentsAuthorizedUsers[0]?.userJSON : null
        ),
        "string"
      ),
      screenId_0: ReactSystemFunctions.convertToTypeByName("9e77d583-6719-4778-8665-f3005e6e9222", "string"),
      screenInputName_0: ReactSystemFunctions.convertToTypeByName("parameter", "string"),
      screenInputValue_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      )
    };

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "28a4bf6c_06a8_2497_23a9_97e179b5e6f5_confirmation",
        this.defaultML,
        "Kullanıcılara bildirim gönderilecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AddVehicleLocation/AddVehicleLocationComponent_655789_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AddVehicleLocationComponent_655789_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AddVehicleLocationComponent_655789_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "addvehiclelocation_250946_value", "", null);

    stateVars.dataSource_158316 = this.state.spGetVehicleLocations;
    formVars.addvehiclelocation_418573_total = ReactSystemFunctions.value(
      this,
      this.state.sqlVehicleLocationCount?.length > 0 ? this.state.sqlVehicleLocationCount[0]?.locationCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_798118_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AddVehicleLocationComponent_655789_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_798118_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a659acaa_fb6b_9821_0c4d_f525ef0da476_notify",
        this.defaultML,
        "Son konumu eklemek için lütfen bir konum adresi giriniz!"
      ),
      "danger",
      "bottom-right",
      5
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_798118_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_250946_value", "Address", "", "", "")
        ),
        null
      )
    ) {
      isErrorOccurred = await this.AddVehicleLocationComponent_798118_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.AddVehicleLocationComponent_798118_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_217779_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AddVehicleLocationComponent_217779_onClick_if01_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AddVehicleLocationComponent_217779_onClick_if01_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "e16a054a_bedc_586d_587b_611dbd5f4052_confirmation",
        this.defaultML,
        "Sipariş Teslim Edildi olarak işaretlenecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (await this.AddVehicleLocationComponent_451434_onClick()) return true;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    }

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_217779_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a659acaa_fb6b_9821_0c4d_f525ef0da476_notify",
        this.defaultML,
        "Teslimat sürecini  sonlandırmadan önce lütfen teslim irsaliyesini yükleyiniz!"
      ),
      "danger",
      "bottom-right",
      5
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_217779_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      )
    ) {
      isErrorOccurred = await this.AddVehicleLocationComponent_217779_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.AddVehicleLocationComponent_217779_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_34124_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AddVehicleLocationComponent_34124_onClick_if01_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AddVehicleLocationComponent_34124_onClick_if01_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "e16a054a_bedc_586d_587b_611dbd5f4052_confirmation",
        this.defaultML,
        "Sipariş Teslim Edildi olarak işaretlenecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (await this.AddVehicleLocationComponent_451434_onClick()) return true;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    }

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_34124_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a659acaa_fb6b_9821_0c4d_f525ef0da476_notify",
        this.defaultML,
        "Teslimat sürecini  sonlandırmadan önce lütfen teslim CMR dokümanını yükleyiniz!"
      ),
      "danger",
      "bottom-right",
      5
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_34124_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      )
    ) {
      isErrorOccurred = await this.AddVehicleLocationComponent_34124_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.AddVehicleLocationComponent_34124_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_51194_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "a3976cfe-8ae7-4071-ac8b-24bb72e32685");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AddVehicleLocation",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "386831",
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AddVehicleLocationComponent_930520_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AddVehicleLocationComponent_930520_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AddVehicleLocationComponent_930520_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("a3976cfe-8ae7-4071-ac8b-24bb72e32685", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      vehicleRequestId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AddVehicleLocation/AddVehicleLocationComponent_930520_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
    stateVars.spCheckDocuments = result?.data.spCheckDocuments;
    stateVars.isComp923284Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp269705Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp513332Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp51194Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  AddVehicleLocationComponent_764440_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "addvehiclelocation_158316_value", "id"),
        "Guid"
      ),
      vehicleId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_418573_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "addvehiclelocation_418573_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "0ebd0753_961e_20a8_4396_200a2f1fbec4_confirmation",
        this.defaultML,
        "Konum silinecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AddVehicleLocation/AddVehicleLocationComponent_764440_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.sqlDeleteVehicleLocation = result?.data.sqlDeleteVehicleLocation;
      stateVars.spGetVehicleLocations = result?.data.spGetVehicleLocations;

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AddVehicleLocationComponent_764440_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AddVehicleLocationComponent_764440_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_158316 = this.state.spGetVehicleLocations;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [386831] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.AddVehicleLocationPageInit();
    }
  }
}
