import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICompanyView_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICompanyView_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetLanguage: string;
  GetCompanyById: any[];
  SqlGetCurrentCountCompanyFiltered: any[];
  spCurrentsCompanyFiltered: any[];
  GoBack: any;
  isComp112309Visible: "visible" | "hidden";
}

export class CompanyView_ScreenBase extends React.PureComponent<ICompanyView_ScreenProps, any> {
  companyview_108944_value_kuikaTableRef: React.RefObject<any>;
  companyview_381930_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 283363, PropertyName: "value", Value: "Kart Adı", TagName: "Label17_0_value" },
        { Id: 411431, PropertyName: "value", Value: "Alan Kodu", TagName: "Label15_0_value" },
        { Id: 346858, PropertyName: "value", Value: "Adres", TagName: "Label14_0_value" },
        { Id: 335000, PropertyName: "value", Value: "Şehir", TagName: "Label10_0_value" },
        { Id: 861183, PropertyName: "value", Value: "Ülke", TagName: "Label10_0_value" },
        { Id: 809964, PropertyName: "value", Value: "Posta Kodu", TagName: "Label8_0_value" },
        { Id: 46652, PropertyName: "value", Value: "Şirket Bilgileri", TagName: "Label17_value" },
        {
          Id: 108944,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 751398, PropertyName: "title", Value: "Cari kart no", TagName: "TableColumn1_0_title" },
        { Id: 321433, PropertyName: "title", Value: "Cari kart ismi", TagName: "TableColumn1_1_title" },
        { Id: 775801, PropertyName: "value", Value: "Cariler", TagName: "Label15_0_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "c12f9650-eea5-4ea5-806b-c7c246c20d2f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 46652, PropertyName: "value", Value: "Company Information" },
        { Id: 283363, PropertyName: "value", Value: "Card Name" },
        { Id: 346858, PropertyName: "value", Value: "Address" },
        { Id: 335000, PropertyName: "value", Value: "City" },
        { Id: 861183, PropertyName: "value", Value: "Country" },
        { Id: 809964, PropertyName: "value", Value: "Posta Code" },
        { Id: 411431, PropertyName: "value", Value: "Phone Number" },
        { Id: 775801, PropertyName: "value", Value: "Currents" },
        { Id: 108944, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 751398, PropertyName: "title", Value: "Current No" },
        { Id: 505392, PropertyName: "value", Value: "[datafield:currentno]" },
        { Id: 321433, PropertyName: "title", Value: "Current Name" },
        { Id: 692554, PropertyName: "value", Value: "[datafield:currentname]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.companyview_108944_value_kuikaTableRef = React.createRef();
    this.companyview_381930_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetLanguage: "",
      GetCompanyById: [],
      SqlGetCurrentCountCompanyFiltered: [],
      spCurrentsCompanyFiltered: [],
      GoBack: "",
      isComp112309Visible: "hidden"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("companyview", "companyview");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CompanyViewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("companyview", "companyview");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Administrator", "Sale", "Logistic", "Quality", "Finance"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("companyview", "companyview");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CompanyViewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CompanyViewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyViewPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyViewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      companyId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      currentPage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyview_381930_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyview_381930_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      companyId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CompanyView/CompanyViewPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetCompanyById = result?.data.getCompanyById;
    formVars.companyview_203874_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.cardName : null
    );
    formVars.companyview_405453_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.address : null
    );
    formVars.companyview_939965_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.addressCity : null
    );
    formVars.companyview_826088_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.addressCountry : null
    );
    formVars.companyview_910813_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.postaCode : null
    );
    formVars.companyview_476976_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.formattedPhone : null
    );
    stateVars.isComp112309Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.companyType : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SqlGetCurrentCountCompanyFiltered = result?.data.sqlGetCurrentCountCompanyFiltered;
    stateVars.spCurrentsCompanyFiltered = result?.data.spCurrentsCompanyFiltered;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyViewPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyViewPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.companyview_203874_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.cardName : null
    );

    formVars.companyview_405453_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.address : null
    );

    formVars.companyview_939965_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.addressCity : null
    );

    formVars.companyview_826088_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.addressCountry : null
    );

    formVars.companyview_910813_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.postaCode : null
    );

    formVars.companyview_476976_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.formattedPhone : null
    );

    stateVars.isComp112309Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.companyType : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_108944 = this.state.spCurrentsCompanyFiltered;
    formVars.companyview_381930_total = ReactSystemFunctions.value(
      this,
      this.state.SqlGetCurrentCountCompanyFiltered?.length > 0
        ? this.state.SqlGetCurrentCountCompanyFiltered[0]?.currentCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CompanyViewComponent_805810_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
