import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KContentMenu,
  KContentMenuItem,
  KContentMenuPanel,
  KDatePicker,
  KDrawer,
  KPagination,
  KPanel,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextInput,
  Tooltip
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { VehicleRequestedOrdersList_ScreenBase } from "./vehiclerequestedorderslist-base";

import { AddVehicleLocation_Screen } from "../addvehiclelocation/addvehiclelocation";
import { AssignVehicle_Screen } from "../assignvehicle/assignvehicle";
import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { Profile_Screen } from "../profile/profile";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class VehicleRequestedOrdersList_Screen extends VehicleRequestedOrdersList_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              vehiclerequestedorderslist_896578_value: "d904a563-d1a7-4126-38a8-08dac24f7806",
              vehiclerequestedorderslist_956783_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined,
              vehiclerequestedorderslist_225422_value: undefined,
              vehiclerequestedorderslist_654017_value: "",
              vehiclerequestedorderslist_71816_value: undefined,
              vehiclerequestedorderslist_827262_value: "66b0efbe-e455-463a-bb8b-08daf3a9971d"
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="vehiclerequestedorderslist_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)",
                    lineHeight: "22px"
                  } as any
                }
              >
                <KRow
                  id="741042"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingRight: 44,
                      paddingLeft: 44,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="424700"
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    xxl={1}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Icon
                      id="401583"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.VehicleRequestedOrdersListComponent_401583_onClick();
                      }}
                      showCursorPointer
                      iconName="menu"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "24px",
                          color: "rgba(99, 100, 102, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>

                  <KCol
                    id="799221"
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="768800"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 4,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 20,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <Label
                        id="592405"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.VehicleRequestedOrdersListComponent_592405_onClick();
                        }}
                        showCursorPointer
                        visibility={this.state.isComp592405Visible}
                        value={ReactSystemFunctions.translate(this.ml, 592405, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Label>

                      <Label
                        id="75168"
                        visibility={this.state.isComp75168Visible}
                        value={ReactSystemFunctions.translate(this.ml, 75168, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 193, 243, 1)",
                            lineHeight: "22px",
                            textDecoration: "underline"
                          } as any
                        }
                      ></Label>

                      <Label
                        id="653608"
                        value={ReactSystemFunctions.translate(this.ml, 653608, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Label>

                      <Label
                        id="133881"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.VehicleRequestedOrdersListComponent_133881_onClick();
                        }}
                        showCursorPointer
                        visibility={this.state.isComp133881Visible}
                        value={ReactSystemFunctions.translate(this.ml, 133881, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Label>

                      <Label
                        id="634824"
                        visibility={this.state.isComp634824Visible}
                        value={ReactSystemFunctions.translate(this.ml, 634824, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 193, 243, 1)",
                            lineHeight: "22px",
                            textDecoration: "underline"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="768546"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="vehiclerequestedorderslist_896578_value">
                      <Image
                        id="896578"
                        placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                        zoomOnClick={false}
                        imageFit="fit"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            width: "180px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Image>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="943198"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        textAlign: "-webkit-right",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="796129"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <Label
                        id="689673"
                        value={ReactSystemFunctions.getMyUsername()}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Label>

                      <KContentMenu
                        id="58870"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KContentMenuPanel
                          id="707866"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KPanel
                            id="815636"
                            scrolling="hidden"
                            scrollRadius={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                alignItems: "flex-start",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="157151"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="284309"
                                visibility={this.state.isComp284309Visible}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    alignItems: "center",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="vehiclerequestedorderslist_956783_value">
                                  <Image
                                    id="956783"
                                    visibility={this.state.isComp956783Visible}
                                    placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                                    zoomOnClick={false}
                                    imageFit="fill"
                                    style={
                                      {
                                        borderTopLeftRadius: 100,
                                        borderTopRightRadius: 100,
                                        borderBottomRightRadius: 100,
                                        borderBottomLeftRadius: 100,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        width: "48px",
                                        height: "48px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="601503"
                                visibility={this.state.isComp601503Visible}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Label
                                  id="133280"
                                  visibility={this.state.isComp133280Visible}
                                  value={this.state.GetUsersFirstLetter?.at?.(0)?.firstletters ?? undefined}
                                  style={
                                    {
                                      borderTopLeftRadius: 100,
                                      borderTopRightRadius: 100,
                                      borderBottomRightRadius: 100,
                                      borderBottomLeftRadius: 100,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(26, 72, 170, 1)",
                                      paddingTop: null,
                                      width: "48px",
                                      height: "48px",
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "52px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>
                          </KPanel>
                        </KContentMenuPanel>

                        <KContentMenuItem
                          id="201032"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.VehicleRequestedOrdersListComponent_201032_onClick();
                          }}
                          showCursorPointer
                          label={ReactSystemFunctions.translate(this.ml, 201032, "label", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></KContentMenuItem>

                        <KContentMenuItem
                          id="833959"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.VehicleRequestedOrdersListComponent_833959_onClick();
                          }}
                          showCursorPointer
                          label={ReactSystemFunctions.translate(this.ml, 833959, "label", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></KContentMenuItem>
                      </KContentMenu>
                    </HorizontalStack>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="vehiclerequestedorderslist_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="121809"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="455939"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KRow
                      id="703933"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopLeftRadius: 12,
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="518457"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KRow
                          id="463050"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="561176"
                            xs={16}
                            sm={16}
                            md={16}
                            lg={16}
                            xl={16}
                            xxl={16}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <Label
                              id="456316"
                              value={ReactSystemFunctions.translate(this.ml, 456316, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Roboto",
                                  fontWeight: 400,
                                  fontSize: "18pt",
                                  color: "rgba(22, 193, 243, 1)"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="591823"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-right",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <Button
                              id="228903"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.VehicleRequestedOrdersListComponent_228903_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp228903Visible}
                              label={ReactSystemFunctions.translate(this.ml, 228903, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(22, 193, 243, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 20,
                                  paddingBottom: 8,
                                  paddingLeft: 20,
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(255, 255, 255, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>
                          </KCol>
                        </KRow>

                        <KRow
                          id="242766"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingRight: null,
                              paddingLeft: null,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="360349"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                borderBottomRightRadius: 12,
                                borderBottomLeftRadius: 12,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(227, 241, 250, 1)",
                                borderStyle: "solid",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="84001"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="452292"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 24,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Label
                                  id="281326"
                                  value={ReactSystemFunctions.translate(this.ml, 281326, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14pt",
                                      color: "rgba(22, 193, 243, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>

                            <KRow
                              id="521458"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="331526"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 8,
                                    paddingBottom: 0,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="571277"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="905540"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="641255"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.VehicleRequestedOrdersListComponent_641255_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="92110"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="475972"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.requested ?? undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="340627"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            340627,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="47348"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="182025"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.VehicleRequestedOrdersListComponent_182025_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="261300"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="680640"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.waitingForApproval ??
                                            undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="463191"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            463191,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(208, 2, 27, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="129692"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="290695"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.VehicleRequestedOrdersListComponent_290695_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="350487"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="504442"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.approved ?? undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="356288"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            356288,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="250146"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="407346"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.VehicleRequestedOrdersListComponent_407346_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="904854"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="990457"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.loaded ?? undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="54404"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            54404,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="650631"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="58934"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.VehicleRequestedOrdersListComponent_58934_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="933794"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="349664"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.outOfDelivery ??
                                            undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="902196"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            902196,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="173240"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="165477"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.VehicleRequestedOrdersListComponent_165477_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="634933"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="178580"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.delivered ?? undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="533903"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            533903,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(103, 184, 15, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="772021"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 25,
                              paddingBottom: 25,
                              paddingLeft: 25,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="795232"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                borderBottomRightRadius: 12,
                                borderBottomLeftRadius: 12,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(227, 241, 250, 1)",
                                borderStyle: "solid",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="537353"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingLeft: 8,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="840403"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Label
                                  id="124830"
                                  value={ReactSystemFunctions.translate(this.ml, 124830, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "18px",
                                      color: "rgba(22, 193, 243, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>

                            <KRow
                              id="87438"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="80522"
                                xs={24}
                                sm={10}
                                md={10}
                                lg={10}
                                xl={10}
                                xxl={10}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="vehiclerequestedorderslist_225422_value">
                                  <KSelectBox
                                    id="225422"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.VehicleRequestedOrdersListComponent_225422_onChange();
                                    }}
                                    kuikaRef={this.vehiclerequestedorderslist_225422_value_kuikaSelectBoxRef}
                                    options={this.state.getVehicleStatusListForFilter}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      225422,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={true}
                                    sortBy="none"
                                    datavaluefield="status"
                                    datatextfield="orderStatusDesc"
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        width: "100%",
                                        height: "44px",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="195929"
                                xs={24}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="vehiclerequestedorderslist_654017_value">
                                  <KDatePicker
                                    id="654017"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.VehicleRequestedOrdersListComponent_654017_onChange();
                                    }}
                                    kuikaRef={this.vehiclerequestedorderslist_654017_value_kuikaDateRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      654017,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    format="DD/MM/YYYY"
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "44px",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></KDatePicker>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="622026"
                                xs={24}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="915271"
                                  direction="horizontal"
                                  size={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="vehiclerequestedorderslist_71816_value">
                                    <TextInput
                                      id="71816"
                                      onPressEnter={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.VehicleRequestedOrdersListComponent_71816_onPressEnter();
                                      }}
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        71816,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={false}
                                      bordered={true}
                                      disabled={false}
                                      type="text"
                                      iconColor="rgba(0, 0, 0, 1)"
                                      formatter="None"
                                      isCharOnly={false}
                                      autoComplete={true}
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(218, 218, 218, 1)",
                                          borderStyle: "solid",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          width: "300px",
                                          height: "42px",
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></TextInput>
                                  </Form.Item>

                                  <Form.Item className="kFormItem" name="vehiclerequestedorderslist_827262_value">
                                    <Image
                                      id="827262"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.VehicleRequestedOrdersListComponent_827262_onClick();
                                      }}
                                      showCursorPointer
                                      zoomOnClick={false}
                                      imageFit="fit"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          width: "24px",
                                          height: "24px",
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Image>
                                  </Form.Item>
                                </HorizontalStack>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="333624"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="745496"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KTable
                              id="696651"
                              kuikaRef={this.vehiclerequestedorderslist_696651_value_kuikaTableRef}
                              form={this.props.form}
                              dataSource={this.state.getVehicleRequestsOfSupplier}
                              size="middle"
                              bordered={true}
                              showHeader={true}
                              loading={false}
                              nodatafoundmessage={ReactSystemFunctions.translate(
                                this.ml,
                                696651,
                                "nodatafoundmessage",
                                this.defaultML
                              )}
                              sorter={false}
                              pagination={false}
                              striped={false}
                              stripedColor="#F5F7FA"
                              insertRowActive={false}
                              transformedOnMobileResolution={false}
                              tableWidthMode="fit"
                              searchable={false}
                              filtering={false}
                              columnChooser={false}
                              insertRowPosition="top"
                              multiSelect={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KTableHeader
                                id="450164"
                                hideOnMobileResolution={false}
                                textDirection="Default"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(155, 155, 155, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></KTableHeader>

                              <KTableRow
                                id="216804"
                                hoverFontColor="red"
                                hoverBgColor="#F5F5F5"
                                style={
                                  {
                                    borderTopWidth: 1,
                                    borderColor: "rgba(244, 244, 244, 1)",
                                    borderStyle: "solid",
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KTableColumn
                                  id="154367"
                                  title={ReactSystemFunctions.translate(this.ml, 154367, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="469709"
                                    value="[datafield:cardName]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="846713"
                                  title={ReactSystemFunctions.translate(this.ml, 846713, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="494669"
                                    value="[datafield:orderNumber]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="719758"
                                  title={ReactSystemFunctions.translate(this.ml, 719758, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="744865"
                                    value="[datafield:orderDate]"
                                    formatter="date"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="730538"
                                  title={ReactSystemFunctions.translate(this.ml, 730538, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="477924"
                                    value="[datafield:creatorFullName]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="198673"
                                  title={ReactSystemFunctions.translate(this.ml, 198673, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="494963"
                                    value="[datafield:orderStatusDesc]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="113474"
                                  title={ReactSystemFunctions.translate(this.ml, 113474, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      width: "10%",
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <HorizontalStack
                                    id="792681"
                                    direction="horizontal"
                                    size={12}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Tooltip
                                      id="750356"
                                      title={ReactSystemFunctions.translate(this.ml, 750356, "title", this.defaultML)}
                                      placement="top"
                                      color="white"
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(218, 218, 218, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          color: "rgba(155, 155, 155, 1)",
                                          lineHeight: "20px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="61359"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.VehicleRequestedOrdersListComponent_61359_onClick();
                                        }}
                                        showCursorPointer
                                        visExField1="[datafield:status]"
                                        visExField2="40"
                                        visExRule="isGreaterOrEqual"
                                        iconName="add_location_alt"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "24px",
                                            color: "rgba(22, 193, 243, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>
                                    </Tooltip>

                                    <Tooltip
                                      id="599150"
                                      title={ReactSystemFunctions.translate(this.ml, 599150, "title", this.defaultML)}
                                      placement="top"
                                      color="white"
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(218, 218, 218, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          color: "rgba(155, 155, 155, 1)",
                                          lineHeight: "20px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="427723"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.VehicleRequestedOrdersListComponent_427723_onClick();
                                        }}
                                        showCursorPointer
                                        visibility={
                                          this.state.isCompvehiclerequestedorderslist_427723AuthorizationVisible
                                        }
                                        visibilityByAuthorization={
                                          this.state.isCompvehiclerequestedorderslist_427723AuthorizationVisible
                                        }
                                        iconName="car_rental"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "24px",
                                            color: "rgba(0, 0, 0, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>
                                    </Tooltip>

                                    <Icon
                                      id="247973"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.VehicleRequestedOrdersListComponent_247973_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="arrow_forward_ios"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "24px",
                                          color: "rgba(22, 193, 243, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </HorizontalStack>
                                </KTableColumn>
                              </KTableRow>
                            </KTable>
                          </KCol>
                        </KRow>

                        <KRow
                          id="883809"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 8,
                              paddingBottom: 0,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="45978"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <HorizontalStack
                              id="713811"
                              direction="horizontal"
                              size={12}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Label
                                id="935216"
                                value={ReactSystemFunctions.translate(this.ml, 935216, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 193, 243, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="799660"
                                value={
                                  this.state.getCountOfVehicleRequestsOfSupplier?.at?.(0)?.recordCount ?? undefined
                                }
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "18px",
                                    color: "rgba(22, 193, 243, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Label>
                            </HorizontalStack>
                          </KCol>

                          <KCol
                            id="924051"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-right",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KPagination
                              id="934790"
                              onChange={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.VehicleRequestedOrdersListComponent_934790_onChange();
                              }}
                              kuikaRef={this.vehiclerequestedorderslist_934790_value_kuikaPaginationRef}
                              total={this.state.getCountOfVehicleRequestsOfSupplier?.at?.(0)?.recordCount ?? undefined}
                              pageSize={20}
                              defaultCurrent={1}
                              current={1}
                              defaultPageSize={10}
                              disabled={false}
                              hideOnSinglePage={true}
                              showLessItems={false}
                              showQuickJumper={false}
                              showSizeChanger={false}
                              showFirstPageButton={false}
                              showLastPageButton={false}
                              showNumbers={true}
                              showText={false}
                              options={[{ value: "Item 1" }, { value: "Item 2" }]}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0
                                } as any
                              }
                            ></KPagination>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="505387"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible505387: false }, () => {
                      this.callCallbackFunction(505387);
                    });
                  }}
                  visible={this.state.NavVisible505387}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible505387 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible505387: false }, () => {
                            this.callCallbackFunction(505387);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KRow
                  id="966839"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="288532"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  ></KCol>
                </KRow>

                <KDrawer
                  id="106589"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible106589: false }, () => {
                      this.callCallbackFunction(106589);
                    });
                  }}
                  visible={this.state.NavVisible106589}
                  width="400px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible106589 && (
                      <Profile_Screen
                        onClose={() => {
                          this.setState({ NavVisible106589: false }, () => {
                            this.callCallbackFunction(106589);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="169204"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible169204: false }, () => {
                      this.callCallbackFunction(169204);
                    });
                  }}
                  visible={this.state.NavVisible169204}
                  width="50%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible169204 && (
                      <AssignVehicle_Screen
                        onClose={() => {
                          this.setState({ NavVisible169204: false }, () => {
                            this.callCallbackFunction(169204);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="397395"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible397395: false }, () => {
                      this.callCallbackFunction(397395);
                    });
                  }}
                  visible={this.state.NavVisible397395}
                  width="70%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible397395 && (
                      <AddVehicleLocation_Screen
                        onClose={() => {
                          this.setState({ NavVisible397395: false }, () => {
                            this.callCallbackFunction(397395);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(
      withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(VehicleRequestedOrdersList_Screen)))
    )
  )
);
export { tmp as VehicleRequestedOrdersList_Screen };
//export default tmp;
//export { tmp as VehicleRequestedOrdersList_Screen };
