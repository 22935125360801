import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICompanyUserList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICompanyUserList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  GetLanguage: string;
  GetUserCurrentId: any[];
  GetUsersFirstLetter: any[];
  spGetUserById: any[];
  GetCompanyById: any[];
  spAppUsersCompanyFiltered: any[];
  GoBack: any;
  SetValueOf: any;
  TriggerEvent: string;
  ReverseBool: boolean;
  spDeleteUser: any[];
  spReactivateUser: any[];
  isComp154167Visible: "visible" | "hidden";
  isComp54070Visible: "visible" | "hidden";
  isComp146381Visible: "visible" | "hidden";
  isComp12839Visible: "visible" | "hidden";
  isComp930514Visible: "visible" | "hidden";
  isComp800287Visible: "visible" | "hidden";
  isComp110782Visible: "visible" | "hidden";
  isComp776881Visible: "visible" | "hidden";
  isComp139133Visible: "visible" | "hidden";
  isComp565505Visible: "visible" | "hidden";
  isComp691126Visible: "visible" | "hidden";
  isComp203431Visible: "visible" | "hidden";
  isComp590152Visible: "visible" | "hidden";
  isCompcompanyuserlist_669426AuthorizationVisible: "visible" | "hidden";
  isCompcompanyuserlist_931397AuthorizationVisible: "visible" | "hidden";
}

export class CompanyUserList_ScreenBase extends React.PureComponent<ICompanyUserList_ScreenProps, any> {
  companyuserlist_256573_value_kuikaTableRef: React.RefObject<any>;
  companyuserlist_182004_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 776881, PropertyName: "value", Value: "Müşteri", TagName: "Label11_value" },
        { Id: 544338, PropertyName: "placeholder", Value: "Ara...", TagName: "TextInput0_0_placeholder" },
        { Id: 669426, PropertyName: "label", Value: "Ekle", TagName: "Button1_0_label" },
        { Id: 119377, PropertyName: "value", Value: "İsim", TagName: "Label1_value" },
        { Id: 793692, PropertyName: "value", Value: "Soyisim", TagName: "Label2_value" },
        { Id: 550493, PropertyName: "value", Value: "E-Mail", TagName: "Label4_value" },
        { Id: 856874, PropertyName: "value", Value: "Telefon Numarası", TagName: "Label5_value" },
        {
          Id: 256573,
          PropertyName: "nodatafoundmessage",
          Value: "Veri Bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 767073, PropertyName: "value", Value: "Silinen Kullanıcıları Göster", TagName: "Label13_value" },
        { Id: 12839, PropertyName: "value", Value: "Müşteri", TagName: "Label2_0_value" },
        { Id: 930514, PropertyName: "value", Value: "Tedarikçi", TagName: "Label2_1_value" },
        { Id: 465850, PropertyName: "value", Value: "Bilgileri", TagName: "Label2_2_value" },
        { Id: 51009, PropertyName: "value", Value: "Şirket Adı", TagName: "Label20_value" },
        { Id: 139133, PropertyName: "value", Value: "Tedarikçi", TagName: "Label7_value" },
        { Id: 111520, PropertyName: "value", Value: "Kullanıcıları", TagName: "Label6_value" },
        { Id: 800287, PropertyName: "value", Value: "Gümrük Firması", TagName: "Label3_value" },
        { Id: 565505, PropertyName: "value", Value: "Gümrük Firması", TagName: "Label9_value" },
        { Id: 799239, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 674313, PropertyName: "label", Value: "Çıkış Yap", TagName: "ContentMenuItem2_2_label" },
        { Id: 327168, PropertyName: "title", Value: "Görüntüle", TagName: "Tooltip2_title" },
        { Id: 49676, PropertyName: "title", Value: "Düzenle", TagName: "Tooltip3_title" },
        { Id: 968277, PropertyName: "title", Value: "Sil", TagName: "Tooltip4_title" },
        { Id: 451257, PropertyName: "title", Value: "Kurtar", TagName: "Tooltip5_title" },
        { Id: 703328, PropertyName: "title", Value: "Cari Yetkileri", TagName: "Tooltip1_title" },
        { Id: 110782, PropertyName: "value", Value: "Distribütör", TagName: "Label10_value" },
        { Id: 691126, PropertyName: "value", Value: "Distribütör", TagName: "Label12_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "e7be2063-22ad-4cac-b48f-56d578f86674",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 799239, PropertyName: "label", Value: "Profile" },
        { Id: 674313, PropertyName: "label", Value: "Logout" },
        { Id: 12839, PropertyName: "value", Value: "Customer" },
        { Id: 930514, PropertyName: "value", Value: "Supplier" },
        { Id: 800287, PropertyName: "value", Value: "Customs Agent" },
        { Id: 110782, PropertyName: "value", Value: "Distributor" },
        { Id: 465850, PropertyName: "value", Value: "Informations" },
        { Id: 51009, PropertyName: "value", Value: "Title" },
        { Id: 776881, PropertyName: "value", Value: "Customer" },
        { Id: 139133, PropertyName: "value", Value: "Supplier" },
        { Id: 565505, PropertyName: "value", Value: "Customs Agent" },
        { Id: 691126, PropertyName: "value", Value: "Distributor" },
        { Id: 111520, PropertyName: "value", Value: "Users" },
        { Id: 203431, PropertyName: "label", Value: "!-Trigger User List-!" },
        { Id: 544338, PropertyName: "placeholder", Value: "Search..." },
        { Id: 767073, PropertyName: "value", Value: "Show Deactivated Users" },
        { Id: 669426, PropertyName: "label", Value: "New" },
        { Id: 119377, PropertyName: "value", Value: "Name" },
        { Id: 793692, PropertyName: "value", Value: "Surname" },
        { Id: 550493, PropertyName: "value", Value: "E-Mail" },
        { Id: 856874, PropertyName: "value", Value: "Phone Number" },
        { Id: 256573, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 194191, PropertyName: "value", Value: "[datafield:name]" },
        { Id: 297711, PropertyName: "value", Value: "[datafield:surname]" },
        { Id: 865944, PropertyName: "value", Value: "[datafield:email]" },
        { Id: 839726, PropertyName: "value", Value: "[datafield:formattedphone]" },
        { Id: 703328, PropertyName: "title", Value: "Current Authorizations" },
        { Id: 327168, PropertyName: "title", Value: "View" },
        { Id: 49676, PropertyName: "title", Value: "Edit" },
        { Id: 968277, PropertyName: "title", Value: "Delete" },
        { Id: 451257, PropertyName: "title", Value: "Recover" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.companyuserlist_256573_value_kuikaTableRef = React.createRef();
    this.companyuserlist_182004_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      GetLanguage: "",
      GetUserCurrentId: [],
      GetUsersFirstLetter: [],
      spGetUserById: [],
      GetCompanyById: [],
      spAppUsersCompanyFiltered: [],
      GoBack: "",
      SetValueOf: "",
      TriggerEvent: "",
      ReverseBool: false,
      spDeleteUser: [],
      spReactivateUser: [],
      isComp154167Visible: "hidden",
      isComp54070Visible: "hidden",
      isComp146381Visible: "hidden",
      isComp12839Visible: "hidden",
      isComp930514Visible: "hidden",
      isComp800287Visible: "hidden",
      isComp110782Visible: "hidden",
      isComp776881Visible: "hidden",
      isComp139133Visible: "hidden",
      isComp565505Visible: "hidden",
      isComp691126Visible: "hidden",
      isComp203431Visible: "hidden",
      isComp590152Visible: "hidden",
      isCompcompanyuserlist_669426AuthorizationVisible: "visible",
      isCompcompanyuserlist_931397AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("companyuserlist", "companyuserlist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CompanyUserListPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcompanyuserlist_669426AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcompanyuserlist_931397AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("companyuserlist", "companyuserlist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("companyuserlist", "companyuserlist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CompanyUserListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      companyuserlist_54070_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined,
      companyuserlist_342581_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CompanyUserListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyUserListPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyUserListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      currentPage_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyuserlist_182004_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyuserlist_182004_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyuserlist_544338_value", "value", "", "", "")
        ),
        "string"
      ),
      showDeletedUsers_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyuserlist_342581_value", "value", "", "", "")
        ),
        "boolean"
      ),
      userType_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userType ?? this.props.screenInputs.usertype,
        "number"
      ),
      companyId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CompanyUserList/CompanyUserListPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.companyuserlist_146381_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.companyuserlist_54070_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp154167Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54070Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp146381Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GetCompanyById = result?.data.getCompanyById;
    formVars.companyuserlist_535514_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.cardName : null
    );
    stateVars.spAppUsersCompanyFiltered = result?.data.spAppUsersCompanyFiltered;

    stateVars.isComp590152Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spAppUsersCompanyFiltered?.length > 0 ? stateVars.spAppUsersCompanyFiltered[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyUserListPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyUserListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp154167Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.companyuserlist_54070_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp54070Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.companyuserlist_146381_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp146381Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp12839Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Customer") === true
        ? "visible"
        : "hidden";
    stateVars.isComp930514Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "LogisticSupplier"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800287Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "CustomsAgent") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp110782Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Distributor") ===
      true
        ? "visible"
        : "hidden";
    formVars.companyuserlist_535514_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.cardName : null
    );

    stateVars.isComp776881Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Customer") === true
        ? "visible"
        : "hidden";
    stateVars.isComp139133Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "LogisticSupplier"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565505Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "CustomsAgent") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp691126Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Distributor") ===
      true
        ? "visible"
        : "hidden";

    stateVars.dataSource_256573 = this.state.spAppUsersCompanyFiltered;
    stateVars.isComp590152Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.spAppUsersCompanyFiltered?.length > 0 ? this.state.spAppUsersCompanyFiltered[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.companyuserlist_182004_total = ReactSystemFunctions.value(
      this,
      this.state.spAppUsersCompanyFiltered?.length > 0 ? this.state.spAppUsersCompanyFiltered[0]?.resultCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CompanyUserListComponent_602426_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyUserList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "762801",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_799239_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyUserList",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "11905",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_674313_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_977229_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_203431_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyuserlist_182004_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyuserlist_182004_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyuserlist_544338_value", "value", "", "", "")
        ),
        "string"
      ),
      showDeletedUsers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyuserlist_342581_value", "value", "", "", "")
        ),
        "boolean"
      ),
      userType_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userType ?? this.props.screenInputs.usertype,
        "number"
      ),
      companyId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyUserList/CompanyUserListComponent_203431_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spAppUsersCompanyFiltered = result?.data.spAppUsersCompanyFiltered;

    stateVars.isComp590152Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spAppUsersCompanyFiltered?.length > 0 ? stateVars.spAppUsersCompanyFiltered[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyUserListComponent_203431_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyUserListComponent_203431_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_256573 = this.state.spAppUsersCompanyFiltered;
    formVars.companyuserlist_182004_total = ReactSystemFunctions.value(
      this,
      this.state.spAppUsersCompanyFiltered?.length > 0 ? this.state.spAppUsersCompanyFiltered[0]?.resultCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_544338_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "companyuserlist_182004_value", 1, "current");
    if (await this.CompanyUserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_965785_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "companyuserlist_182004_value", 1, "current");
    if (await this.CompanyUserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_342581_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "companyuserlist_182004_value", 1, "current");
    if (await this.CompanyUserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_767073_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      value_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyuserlist_342581_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyUserList/CompanyUserListComponent_767073_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ReverseBool = result?.data.reverseBool;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyUserListComponent_767073_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyUserListComponent_767073_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyuserlist_342581_value",
      ReactSystemFunctions.value(this, this.state.ReverseBool),
      null
    );
    if (await this.CompanyUserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_669426_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UserEdit", "userById", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "userType",
      this.props.screenInputs.userType ?? this.props.screenInputs.usertype
    );
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "role",
      this.props.screenInputs.role ?? this.props.screenInputs.role
    );
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "companyId",
      this.props.screenInputs.companyId ?? this.props.screenInputs.companyid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyUserList",
      "UserEdit",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "384899",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_349000_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CustomerUserAuthorizeCurrent",
      "userId",
      ReactSystemFunctions.value(this, "companyuserlist_256573_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "CustomerUserAuthorizeCurrent",
      "companyType",
      this.props.screenInputs.role ?? this.props.screenInputs.role
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyUserList",
      "CustomerUserAuthorizeCurrent",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "659029",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_190982_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UserView",
      "userId",
      ReactSystemFunctions.value(this, "companyuserlist_256573_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyUserList",
      "UserView",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "644855",
      null,
      "right",
      null,
      "50%",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_260334_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "userById",
      ReactSystemFunctions.value(this, "companyuserlist_256573_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "userType",
      this.props.screenInputs.userType ?? this.props.screenInputs.usertype
    );
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "role",
      this.props.screenInputs.role ?? this.props.screenInputs.role
    );
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "companyId",
      ReactSystemFunctions.value(this, "companyuserlist_256573_value", "companyID")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyUserList",
      "UserEdit",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "743016",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_322700_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "companyuserlist_256573_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5e4c917d_27e7_9e27_fe2a_9d45be5ba80d_confirmation",
        this.defaultML,
        "Kullanıcı hesabı silinecek. Devam etmek istiyor musunuz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "CompanyUserList/CompanyUserListComponent_322700_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spDeleteUser = result?.data.spDeleteUser;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.CompanyUserListComponent_322700_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyUserListComponent_322700_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.CompanyUserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_636528_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "companyuserlist_256573_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyUserList/CompanyUserListComponent_636528_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spReactivateUser = result?.data.spReactivateUser;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyUserListComponent_636528_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyUserListComponent_636528_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.CompanyUserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyUserListComponent_182004_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.CompanyUserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [762801, 11905, 384899, 659029, 743016] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.CompanyUserListPageInit();
    }
  }
}
