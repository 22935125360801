import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDocumentsOfCustomerOrder_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDocumentsOfCustomerOrder_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetLanguage: string;
  getVehicleDocuments: any[];
  GoBack: any;
  NAVIGATE: any;
  isComp569806Visible: "visible" | "hidden";
  isCompdocumentsofcustomerorder_496565AuthorizationVisible: "visible" | "hidden";
}

export class DocumentsOfCustomerOrder_ScreenBase extends React.PureComponent<
  IDocumentsOfCustomerOrder_ScreenProps,
  any
> {
  documentsofcustomerorder_297232_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 459198, PropertyName: "value", Value: "Sipariş Dokümanları", TagName: "Label0_0_value" },
        { Id: 816779, PropertyName: "value", Value: "Henüz hiç doküman yüklenmedi.", TagName: "Label4_0_value" },
        {
          Id: 297232,
          PropertyName: "nodatafoundmessage",
          Value: "Henüz hiç doküman yüklenmedi.",
          TagName: "Table0_0_nodatafoundmessage"
        }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "c43b4e3b-014d-42c4-93a8-86fcb953e008",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 459198, PropertyName: "value", Value: "Order Documents" },
        { Id: 816779, PropertyName: "value", Value: "No documents have been uploaded yet." },
        { Id: 297232, PropertyName: "nodatafoundmessage", Value: "No documents uploaded." },
        { Id: 706575, PropertyName: "title", Value: "Title" },
        { Id: 981308, PropertyName: "value", Value: "[datafield:documenttypename]" },
        { Id: 64260, PropertyName: "value", Value: "[datafield:uploadedat]" },
        { Id: 409011, PropertyName: "value", Value: "[datafield:docname]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.documentsofcustomerorder_297232_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetLanguage: "",
      getVehicleDocuments: [],
      GoBack: "",
      NAVIGATE: "",
      isComp569806Visible: "hidden",
      isCompdocumentsofcustomerorder_496565AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("documentsofcustomerorder", "documentsofcustomerorder");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DocumentsOfCustomerOrderPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompdocumentsofcustomerorder_496565AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("documentsofcustomerorder", "documentsofcustomerorder");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("documentsofcustomerorder", "documentsofcustomerorder");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DocumentsOfCustomerOrderPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DocumentsOfCustomerOrderPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DocumentsOfCustomerOrderPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DocumentsOfCustomerOrderPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      lang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      isAbroad_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.isAbroad ?? this.props.screenInputs.isabroad,
        "boolean"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DocumentsOfCustomerOrder/DocumentsOfCustomerOrderPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getVehicleDocuments = result?.data.getVehicleDocuments;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DocumentsOfCustomerOrderPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DocumentsOfCustomerOrderPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_297232 = this.state.getVehicleDocuments;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DocumentsOfCustomerOrderComponent_671628_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DocumentsOfCustomerOrderComponent_409011_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.VehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "docType",
      ReactSystemFunctions.value(
        this,
        this.state.getVehicleDocuments?.length > 0 ? this.state.getVehicleDocuments[0]?.docType : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DocumentsOfCustomerOrder",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "582581",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [582581] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DocumentsOfCustomerOrderPageInit();
    }
  }
}
