import React from "react";
import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import { Redirect } from "../auth/redirect";
import { NoMatch404 } from "../components/common/no-match-404";
import { Dashboard, ForgotPassword, Login, ResetPassword } from "../components/default_screens/index";
import { PrivateRoute } from "./private-route";
import { Route } from "./route";
import { DashboardFinance_Screen } from "../screens/dashboardfinance/dashboardfinance";
import { CurrenciesEdit_Screen } from "../screens/currenciesedit/currenciesedit";
import { UserView_Screen } from "../screens/userview/userview";
import { Profile_Screen } from "../screens/profile/profile";
import { CompanyUserList_Screen } from "../screens/companyuserlist/companyuserlist";
import { UserEdit_Screen } from "../screens/useredit/useredit";
import { QuestionEdit_Screen } from "../screens/questionedit/questionedit";
import { DetailsOfCustomerOrder_Screen } from "../screens/detailsofcustomerorder/detailsofcustomerorder";
import { Signin_Screen } from "../screens/signin/signin";
import { ForgotPassword_Screen } from "../screens/forgotpassword/forgotpassword";
import { RequestVehicle_Screen } from "../screens/requestvehicle/requestvehicle";
import { PreviewInvoice_Screen } from "../screens/previewinvoice/previewinvoice";
import { ProductsList_Screen } from "../screens/productslist/productslist";
import { OrderProcess_Screen } from "../screens/orderprocess/orderprocess";
import { PreviewDocument_Screen } from "../screens/previewdocument/previewdocument";
import { Customers_Screen } from "../screens/customers/customers";
import { LeftMenuCustomer_Screen } from "../screens/leftmenucustomer/leftmenucustomer";
import { SurveySave_Screen } from "../screens/surveysave/surveysave";
import { CompanyProfileCustomer_Screen } from "../screens/companyprofilecustomer/companyprofilecustomer";
import { Suppliers_Screen } from "../screens/suppliers/suppliers";
import { ApplicationAreasEdit_Screen } from "../screens/applicationareasedit/applicationareasedit";
import { RateSurveyQuestion_Screen } from "../screens/ratesurveyquestion/ratesurveyquestion";
import { ProfileEdit_Screen } from "../screens/profileedit/profileedit";
import { CompanyCurrentList_Screen } from "../screens/companycurrentlist/companycurrentlist";
import { CompanyView_Screen } from "../screens/companyview/companyview";
import { OrderTracking_Screen } from "../screens/ordertracking/ordertracking";
import { BrokerList_Screen } from "../screens/brokerlist/brokerlist";
import { VehicleRequestedOrdersList_Screen } from "../screens/vehiclerequestedorderslist/vehiclerequestedorderslist";
import { DashboardDistributor_Screen } from "../screens/dashboarddistributor/dashboarddistributor";
import { DocumentUploadedListCustomsAgent_Screen } from "../screens/documentuploadedlistcustomsagent/documentuploadedlistcustomsagent";
import { DashboardCustomer_Screen } from "../screens/dashboardcustomer/dashboardcustomer";
import { UserList_Screen } from "../screens/userlist/userlist";
import { Distributors_Screen } from "../screens/distributors/distributors";
import { DocumentWaitingUploadListCA_Screen } from "../screens/documentwaitinguploadlistca/documentwaitinguploadlistca";
import { CertificateEdit_Screen } from "../screens/certificateedit/certificateedit";
import { DashboardAdmin_Screen } from "../screens/dashboardadmin/dashboardadmin";
import { RequestedVehicleView_Screen } from "../screens/requestedvehicleview/requestedvehicleview";
import { LeftMenu_Screen } from "../screens/leftmenu/leftmenu";
import { CompanyEdit_Screen } from "../screens/companyedit/companyedit";
import { SurveyParticipants_Screen } from "../screens/surveyparticipants/surveyparticipants";
import { ApplicationAreasEditcopy_Screen } from "../screens/applicationareaseditcopy/applicationareaseditcopy";
import { HaberList_Screen } from "../screens/haberlist/haberlist";
import { SurveyDetails_Screen } from "../screens/surveydetails/surveydetails";
import { CustomerUserAuthorizeCurrent_Screen } from "../screens/customeruserauthorizecurrent/customeruserauthorizecurrent";
import { VerificationCode_Screen } from "../screens/verificationcode/verificationcode";
import { ResetPassword_Screen } from "../screens/resetpassword/resetpassword";
import { CurrentEdit_Screen } from "../screens/currentedit/currentedit";
import { ContactForm_Screen } from "../screens/contactform/contactform";
import { HaberDetay_Screen } from "../screens/haberdetay/haberdetay";
import { CurrenciesList_Screen } from "../screens/currencieslist/currencieslist";
import { AssignedVehicleView_Screen } from "../screens/assignedvehicleview/assignedvehicleview";
import { SurveyQuestions_Screen } from "../screens/surveyquestions/surveyquestions";
import { CustomsAgentOrdersList_Screen } from "../screens/customsagentorderslist/customsagentorderslist";
import { CertificateList_Screen } from "../screens/certificatelist/certificatelist";
import { ApplicationAreasView_Screen } from "../screens/applicationareasview/applicationareasview";
import { DashboardCustomsAgent_Screen } from "../screens/dashboardcustomsagent/dashboardcustomsagent";
import { DashboardUser_Screen } from "../screens/dashboarduser/dashboarduser";
import { ProductGroupView_Screen } from "../screens/productgroupview/productgroupview";
import { ApplicationAreas_Screen } from "../screens/applicationareas/applicationareas";
import { BrokerEdit_Screen } from "../screens/brokeredit/brokeredit";
import { DashboardQuality_Screen } from "../screens/dashboardquality/dashboardquality";
import { AddVehicleLocation_Screen } from "../screens/addvehiclelocation/addvehiclelocation";
import { AssignVehicle_Screen } from "../screens/assignvehicle/assignvehicle";
import { ProductGroup_Screen } from "../screens/productgroup/productgroup";
import { OrdersInProgress_Screen } from "../screens/ordersinprogress/ordersinprogress";
import { CompanyProfileEdit_Screen } from "../screens/companyprofileedit/companyprofileedit";
import { DeleteMyAccount_Screen } from "../screens/deletemyaccount/deletemyaccount";
import { LocationsOfCustomerOrder_Screen } from "../screens/locationsofcustomerorder/locationsofcustomerorder";
import { OrderSearch_Screen } from "../screens/ordersearch/ordersearch";
import { YeniHaber_Screen } from "../screens/yenihaber/yenihaber";
import { DashboardLogistic_Screen } from "../screens/dashboardlogistic/dashboardlogistic";
import { DashboardSale_Screen } from "../screens/dashboardsale/dashboardsale";
import { CurrentView_Screen } from "../screens/currentview/currentview";
import { NewPassword_Screen } from "../screens/newpassword/newpassword";
import { CustomsAgents_Screen } from "../screens/customsagents/customsagents";
import { ProductsEdit_Screen } from "../screens/productsedit/productsedit";
import { Signup_Screen } from "../screens/signup/signup";
import { DocumentsOfCustomerOrder_Screen } from "../screens/documentsofcustomerorder/documentsofcustomerorder";
import { ProductGroupEdit_Screen } from "../screens/productgroupedit/productgroupedit";
import { DashboardEditor_Screen } from "../screens/dashboardeditor/dashboardeditor";
import { ProductsView_Screen } from "../screens/productsview/productsview";



import { IAppMode, IAuthType } from "../bootstrapper";
import { KuikaInfo } from "../components/default_screens/kuika-info";
import { MfeLogin } from "../components/mfe/mfe-login";
import { MsalLogin } from "../components/msal/msal-login";
import { MsalRedirect } from "../components/msal/msal-redirect";
import { WFMailInteraction } from "../screens/wf-mail-interaction";
import { KMainFunctions } from "../shared/utilty/main-functions";

const confJson = require("../shared/json/conf.json");

interface IRouterState {}

export type IRouterType = "HashRouter" | "BrowserRouter";

interface IRouterProps {
  authType: IAuthType;
  routerType?: IRouterType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;
export class Router extends React.Component<IRouterProps, IRouterState> {
  timerId: any = null;

  componentDidMount = () => {
    window.onfocus = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = true;
    };

    window.onblur = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = false;
    };

    if (!window.styler_speed) {
      window.styler_speed = 20;
    } else if (window.styler_speed < 500) {
      window.styler_speed += 100;
    }

    if (window?.kuika?.activeKuikaComponent !== undefined) {
      return;
    }
    const runTimeout = () => {
      this.timerId = setTimeout(() => {
        const allFormItems: NodeListOf<HTMLElement> = document.querySelectorAll(".kFormItem");
        if (allFormItems) {
          allFormItems.forEach((formItem) => {
            if (formItem.getAttribute("data-display-affected") === "true") {
              return;
            }
            const antFormItemControlInputContent: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content"
            );
            const antFormItemControlInput: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input"
            );
            const antFormItemControl: HTMLElement = formItem.querySelector(".ant-form-item-control");

            if (
              antFormItemControlInputContent &&
              antFormItemControlInputContent.children &&
              antFormItemControlInputContent.children.length > 0
            ) {
              const formChild: HTMLElement = antFormItemControlInputContent.children[0] as HTMLElement;
              let calculatedDisplay: string = formChild.style.display;
              if (
                calculatedDisplay &&
                antFormItemControl &&
                antFormItemControlInput &&
                antFormItemControlInputContent
              ) {
                if (calculatedDisplay === "inline-flex" || calculatedDisplay === "inline-block") {
                  calculatedDisplay = "";
                  formItem.style.width = formChild.style.width ?? "auto";
                  formItem.style.display = "inline-block";
                  formItem.setAttribute("data-display-affected", "true");
                }
              }
            }
          });
        }
        this.componentDidMount();
      }, window.styler_speed);
    };

    runTimeout();

    setTimeout(() => {
      clearTimeout(this.timerId);
    }, 10000);
  };

  render() {
    const KuikaRouter = this.KuikaRouter();
    return (
      <>
        <KuikaRouter>
          <Switch key="Switch">
            <PrivateRoute exact path="/" component={ProductGroup_Screen} />
                        <PrivateRoute exact path="/dashboardfinance" component={DashboardFinance_Screen} />
            <PrivateRoute exact path="/currenciesedit" component={CurrenciesEdit_Screen} />
            <PrivateRoute exact path="/userview" component={UserView_Screen} />
            <PrivateRoute exact path="/profile" component={Profile_Screen} />
            <PrivateRoute exact path="/companyuserlist" component={CompanyUserList_Screen} />
            <PrivateRoute exact path="/useredit" component={UserEdit_Screen} />
            <PrivateRoute exact path="/questionedit" component={QuestionEdit_Screen} />
            <PrivateRoute exact path="/detailsofcustomerorder" component={DetailsOfCustomerOrder_Screen} />
            <Route exact path="/signin" component={this.LoginComponent()} />
            <Route exact path="/forgotpassword" component={ForgotPassword_Screen} />
            <PrivateRoute exact path="/requestvehicle" component={RequestVehicle_Screen} />
            <Route exact path="/previewinvoice" component={PreviewInvoice_Screen} />
            <PrivateRoute exact path="/productslist" component={ProductsList_Screen} />
            <PrivateRoute exact path="/orderprocess" component={OrderProcess_Screen} />
            <Route exact path="/previewdocument" component={PreviewDocument_Screen} />
            <PrivateRoute exact path="/customers" component={Customers_Screen} />
            <PrivateRoute exact path="/leftmenucustomer" component={LeftMenuCustomer_Screen} />
            <PrivateRoute exact path="/surveysave" component={SurveySave_Screen} />
            <PrivateRoute exact path="/companyprofilecustomer" component={CompanyProfileCustomer_Screen} />
            <PrivateRoute exact path="/suppliers" component={Suppliers_Screen} />
            <PrivateRoute exact path="/applicationareasedit" component={ApplicationAreasEdit_Screen} />
            <PrivateRoute exact path="/ratesurveyquestion" component={RateSurveyQuestion_Screen} />
            <PrivateRoute exact path="/profileedit" component={ProfileEdit_Screen} />
            <PrivateRoute exact path="/companycurrentlist" component={CompanyCurrentList_Screen} />
            <PrivateRoute exact path="/companyview" component={CompanyView_Screen} />
            <Route exact path="/ordertracking" component={OrderTracking_Screen} />
            <PrivateRoute exact path="/brokerlist" component={BrokerList_Screen} />
            <PrivateRoute exact path="/vehiclerequestedorderslist" component={VehicleRequestedOrdersList_Screen} />
            <PrivateRoute exact path="/dashboarddistributor" component={DashboardDistributor_Screen} />
            <PrivateRoute exact path="/documentuploadedlistcustomsagent" component={DocumentUploadedListCustomsAgent_Screen} />
            <PrivateRoute exact path="/dashboardcustomer" component={DashboardCustomer_Screen} />
            <PrivateRoute exact path="/userlist" component={UserList_Screen} />
            <PrivateRoute exact path="/distributors" component={Distributors_Screen} />
            <PrivateRoute exact path="/documentwaitinguploadlistca" component={DocumentWaitingUploadListCA_Screen} />
            <PrivateRoute exact path="/certificateedit" component={CertificateEdit_Screen} />
            <PrivateRoute exact path="/dashboardadmin" component={DashboardAdmin_Screen} />
            <PrivateRoute exact path="/requestedvehicleview" component={RequestedVehicleView_Screen} />
            <PrivateRoute exact path="/leftmenu" component={LeftMenu_Screen} />
            <PrivateRoute exact path="/companyedit" component={CompanyEdit_Screen} />
            <PrivateRoute exact path="/surveyparticipants" component={SurveyParticipants_Screen} />
            <PrivateRoute exact path="/applicationareaseditcopy" component={ApplicationAreasEditcopy_Screen} />
            <PrivateRoute exact path="/haberlist" component={HaberList_Screen} />
            <PrivateRoute exact path="/surveydetails" component={SurveyDetails_Screen} />
            <PrivateRoute exact path="/customeruserauthorizecurrent" component={CustomerUserAuthorizeCurrent_Screen} />
            <Route exact path="/verificationcode" component={VerificationCode_Screen} />
            <Route exact path="/resetpassword" component={ResetPassword_Screen} />
            <PrivateRoute exact path="/currentedit" component={CurrentEdit_Screen} />
            <PrivateRoute exact path="/contactform" component={ContactForm_Screen} />
            <Route exact path="/haberdetay" component={HaberDetay_Screen} />
            <PrivateRoute exact path="/currencieslist" component={CurrenciesList_Screen} />
            <PrivateRoute exact path="/assignedvehicleview" component={AssignedVehicleView_Screen} />
            <PrivateRoute exact path="/surveyquestions" component={SurveyQuestions_Screen} />
            <PrivateRoute exact path="/customsagentorderslist" component={CustomsAgentOrdersList_Screen} />
            <PrivateRoute exact path="/certificatelist" component={CertificateList_Screen} />
            <PrivateRoute exact path="/applicationareasview" component={ApplicationAreasView_Screen} />
            <PrivateRoute exact path="/dashboardcustomsagent" component={DashboardCustomsAgent_Screen} />
            <PrivateRoute exact path="/dashboarduser" component={DashboardUser_Screen} />
            <PrivateRoute exact path="/productgroupview" component={ProductGroupView_Screen} />
            <PrivateRoute exact path="/applicationareas" component={ApplicationAreas_Screen} />
            <PrivateRoute exact path="/brokeredit" component={BrokerEdit_Screen} />
            <PrivateRoute exact path="/dashboardquality" component={DashboardQuality_Screen} />
            <PrivateRoute exact path="/addvehiclelocation" component={AddVehicleLocation_Screen} />
            <PrivateRoute exact path="/assignvehicle" component={AssignVehicle_Screen} />
            <PrivateRoute exact path="/productgroup" component={ProductGroup_Screen} />
            <PrivateRoute exact path="/ordersinprogress" component={OrdersInProgress_Screen} />
            <PrivateRoute exact path="/companyprofileedit" component={CompanyProfileEdit_Screen} />
            <Route exact path="/deletemyaccount" component={DeleteMyAccount_Screen} />
            <PrivateRoute exact path="/locationsofcustomerorder" component={LocationsOfCustomerOrder_Screen} />
            <PrivateRoute exact path="/ordersearch" component={OrderSearch_Screen} />
            <Route exact path="/yenihaber" component={YeniHaber_Screen} />
            <PrivateRoute exact path="/dashboardlogistic" component={DashboardLogistic_Screen} />
            <PrivateRoute exact path="/dashboardsale" component={DashboardSale_Screen} />
            <PrivateRoute exact path="/currentview" component={CurrentView_Screen} />
            <PrivateRoute exact path="/newpassword" component={NewPassword_Screen} />
            <PrivateRoute exact path="/customsagents" component={CustomsAgents_Screen} />
            <PrivateRoute exact path="/productsedit" component={ProductsEdit_Screen} />
            <Route exact path="/signup" component={Signup_Screen} />
            <PrivateRoute exact path="/documentsofcustomerorder" component={DocumentsOfCustomerOrder_Screen} />
            <PrivateRoute exact path="/productgroupedit" component={ProductGroupEdit_Screen} />
            <PrivateRoute exact path="/dashboardeditor" component={DashboardEditor_Screen} />
            <PrivateRoute exact path="/productsview" component={ProductsView_Screen} />

            <Route path="/redirect" component={Redirect} />
            <Route path={"/login"} component={this.LoginComponent()} />
            
            
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/kuikainfo" component={KuikaInfo} />
            <Route exact path="/msalredirect" component={MsalRedirect} />
            <Route exact path="/wfmailinteraction" component={WFMailInteraction} />
            <Route path="*" component={NoMatch404} />
          </Switch>
        </KuikaRouter>
      </>
    );
  }

  KuikaRouter = () => {
    // Web component case
    let isWebComponentApp: any;
    isWebComponentApp = this.props.appMode === "WebComponent";
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app.appName === confJson.applicationName && app.workspace === confJson.domainName) {
          isWebComponentApp = true;
        }
      });
    }
    if (window.kuika === undefined) {
      window.kuika = {};
    }
    window.kuika.isWebComponentApp = isWebComponentApp;

    if (isWebComponentApp === true || this.props.routerType === "HashRouter") {
      return (props: any) => {
        return <HashRouter key="HashRouter">{props.children}</HashRouter>;
      };
    }
    return (props: any) => {
      if (KMainFunctions.virtualDirectoryURL === "auto" || window?.location?.protocol === "file:") {
        return <BrowserRouter key="BrowserRouter">{props.children}</BrowserRouter>;
      }
      return (
        <BrowserRouter basename={KMainFunctions.virtualDirectoryURL} key="BrowserRouter">
          {props.children}
        </BrowserRouter>
      );
    };
  };

  LoginComponent = () => {
    if (this.props.authType === "MSAL") {
      return MsalLogin;
    }

    const { external_access_token } = this.props;
    const { external_refresh_token } = this.props;
    const { external_user } = this.props;
    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      return (props: any) => {
        return (
          <MfeLogin
            external_user={external_user}
            external_access_token={external_access_token}
            external_refresh_token={external_refresh_token}
          ></MfeLogin>
        );
      };
    }

    if (this.props.authType === "Kuika") {
      return Signin_Screen;
    }
    return <></>;
  };
}
