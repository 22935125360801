import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KContentMenu,
  KContentMenuItem,
  KContentMenuPanel,
  KDrawer,
  KGallery,
  KPagination,
  KPanel,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { DashboardSale_ScreenBase } from "./dashboardsale-base";

import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { Profile_Screen } from "../profile/profile";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class DashboardSale_Screen extends DashboardSale_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              dashboardsale_340328_value: "d904a563-d1a7-4126-38a8-08dac24f7806",
              dashboardsale_807602_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="dashboardsale_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)",
                    lineHeight: "22px"
                  } as any
                }
              >
                <KRow
                  id="505897"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingRight: 44,
                      paddingLeft: 44,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="847888"
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    xxl={1}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Icon
                      id="844448"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.DashboardSaleComponent_844448_onClick();
                      }}
                      showCursorPointer
                      iconName="menu"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "24px",
                          color: "rgba(99, 100, 102, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>

                  <KCol
                    id="542726"
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="287726"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 4,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 20,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <Label
                        id="666662"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.DashboardSaleComponent_666662_onClick();
                        }}
                        showCursorPointer
                        visibility={this.state.isComp666662Visible}
                        value={ReactSystemFunctions.translate(this.ml, 666662, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Label>

                      <Label
                        id="597975"
                        visibility={this.state.isComp597975Visible}
                        value={ReactSystemFunctions.translate(this.ml, 597975, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 193, 243, 1)",
                            lineHeight: "22px",
                            textDecoration: "underline"
                          } as any
                        }
                      ></Label>

                      <Label
                        id="953892"
                        value={ReactSystemFunctions.translate(this.ml, 953892, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Label>

                      <Label
                        id="595872"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.DashboardSaleComponent_595872_onClick();
                        }}
                        showCursorPointer
                        visibility={this.state.isComp595872Visible}
                        value={ReactSystemFunctions.translate(this.ml, 595872, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Label>

                      <Label
                        id="355968"
                        visibility={this.state.isComp355968Visible}
                        value={ReactSystemFunctions.translate(this.ml, 355968, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 193, 243, 1)",
                            lineHeight: "22px",
                            textDecoration: "underline"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="512302"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="dashboardsale_340328_value">
                      <Image
                        id="340328"
                        placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                        zoomOnClick={false}
                        imageFit="fit"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            width: "180px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Image>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="159275"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="116424"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <Label
                        id="436618"
                        value={ReactSystemFunctions.getMyUsername()}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Label>

                      <KContentMenu
                        id="984945"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KContentMenuPanel
                          id="218615"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KPanel
                            id="329459"
                            scrolling="hidden"
                            scrollRadius={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                alignItems: "flex-start",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="271521"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="237173"
                                visibility={this.state.isComp237173Visible}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    alignItems: "center",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="dashboardsale_807602_value">
                                  <Image
                                    id="807602"
                                    visibility={this.state.isComp807602Visible}
                                    placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                                    zoomOnClick={false}
                                    imageFit="fill"
                                    style={
                                      {
                                        borderTopLeftRadius: 100,
                                        borderTopRightRadius: 100,
                                        borderBottomRightRadius: 100,
                                        borderBottomLeftRadius: 100,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        width: "48px",
                                        height: "48px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="782825"
                                visibility={this.state.isComp782825Visible}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Label
                                  id="912810"
                                  visibility={this.state.isComp912810Visible}
                                  value={this.state.GetUsersFirstLetter?.at?.(0)?.firstletters ?? undefined}
                                  style={
                                    {
                                      borderTopLeftRadius: 100,
                                      borderTopRightRadius: 100,
                                      borderBottomRightRadius: 100,
                                      borderBottomLeftRadius: 100,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(26, 72, 170, 1)",
                                      paddingTop: null,
                                      width: "48px",
                                      height: "48px",
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "52px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>
                          </KPanel>
                        </KContentMenuPanel>

                        <KContentMenuItem
                          id="836338"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.DashboardSaleComponent_836338_onClick();
                          }}
                          showCursorPointer
                          label={ReactSystemFunctions.translate(this.ml, 836338, "label", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></KContentMenuItem>

                        <KContentMenuItem
                          id="171447"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.DashboardSaleComponent_171447_onClick();
                          }}
                          showCursorPointer
                          label={ReactSystemFunctions.translate(this.ml, 171447, "label", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></KContentMenuItem>
                      </KContentMenu>
                    </HorizontalStack>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="dashboardsale_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="971622"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="96352"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 40,
                        paddingBottom: 8,
                        paddingLeft: 40,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KRow
                      id="602374"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="354744"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KRow
                          id="261702"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingRight: null,
                              paddingLeft: null,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="755041"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                borderBottomRightRadius: 12,
                                borderBottomLeftRadius: 12,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(227, 241, 250, 1)",
                                borderStyle: "solid",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="884980"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="129632"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 24,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Label
                                  id="212248"
                                  value={ReactSystemFunctions.translate(this.ml, 212248, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14pt",
                                      color: "rgba(22, 193, 243, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>

                            <KRow
                              id="728956"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 0,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="221178"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 8,
                                    paddingBottom: 0,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="569671"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="672579"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="737607"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.DashboardSaleComponent_737607_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="977641"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="196975"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.requested ?? undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="586821"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            586821,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="585371"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="166717"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.DashboardSaleComponent_166717_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="473174"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="685368"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.waitingForApproval ??
                                            undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="148979"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            148979,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(208, 2, 27, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="103530"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="25549"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.DashboardSaleComponent_25549_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="923358"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="576380"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.approved ?? undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="159666"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            159666,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="494806"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="138105"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.DashboardSaleComponent_138105_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="800940"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="781965"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.loaded ?? undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="228223"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            228223,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="455984"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="373411"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.DashboardSaleComponent_373411_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="617594"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="429009"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.outOfDelivery ??
                                            undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="590330"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            590330,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>

                                  <KCol
                                    id="947728"
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 4,
                                        paddingBottom: 8,
                                        paddingLeft: 4,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="102985"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.DashboardSaleComponent_102985_onClick();
                                      }}
                                      showCursorPointer
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="680966"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="340960"
                                          value={
                                            this.state.getCountOfOrdersByVehicleStatus?.at?.(0)?.delivered ?? undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "22pt",
                                              color: "rgba(0, 0, 0, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="280549"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            280549,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 400,
                                              fontSize: "14px",
                                              color: "rgba(103, 184, 15, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="15572"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="774072"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                borderBottomRightRadius: 12,
                                borderBottomLeftRadius: 12,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(227, 241, 250, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="616671"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  paddingTop: null,
                                  paddingRight: null,
                                  paddingLeft: null,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="944401"
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={16}
                                xxl={16}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 0,
                                    paddingLeft: 24,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Label
                                  id="870116"
                                  value={ReactSystemFunctions.translate(this.ml, 870116, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "14pt",
                                      color: "rgba(22, 193, 243, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="185928"
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 0,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="865610"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <HorizontalStack
                                    id="135999"
                                    direction="horizontal"
                                    size={4}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="324758"
                                      value={ReactSystemFunctions.translate(this.ml, 324758, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 193, 243, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="255633"
                                      value={
                                        this.state.getCountOfOrdersInProgress?.at?.(0)?.countOfRecords ?? undefined
                                      }
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(22, 193, 243, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="872280"
                                      value={ReactSystemFunctions.translate(this.ml, 872280, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 193, 243, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </HorizontalStack>

                                  <Label
                                    id="536585"
                                    value={ReactSystemFunctions.translate(this.ml, 536585, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>

                                  <Icon
                                    id="738943"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.DashboardSaleComponent_738943_onClick();
                                    }}
                                    showCursorPointer
                                    iconName="east"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "24px",
                                        color: "rgba(22, 193, 243, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </HorizontalStack>
                              </KCol>
                            </KRow>

                            <KRow
                              id="462267"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="985207"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KGallery
                                  id="672810"
                                  kuikaRef={this.dashboardsale_672810_value_kuikaGalleryViewRef}
                                  dataSource={this.state.spGetOrdersInProgress}
                                  loading={false}
                                  phoneColumnsCount={1}
                                  tabletColumnsCount={2}
                                  desktopColumnsCount={2}
                                  hoverFontColor="black"
                                  hoverBgColor="white"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    672810,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  showNoDataFoundImage={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="238203"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="182882"
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      lg={24}
                                      xl={24}
                                      xxl={24}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 12,
                                          borderTopRightRadius: 12,
                                          borderBottomRightRadius: 12,
                                          borderBottomLeftRadius: 12,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(227, 241, 250, 1)",
                                          borderStyle: "solid",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KRow
                                        id="530568"
                                        align="top"
                                        justify="start"
                                        horizontalGutter={0}
                                        verticalGutter={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KCol
                                          id="170057"
                                          xs={22}
                                          sm={22}
                                          md={22}
                                          lg={22}
                                          xl={22}
                                          xxl={22}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KPanel
                                            id="832051"
                                            scrolling="hidden"
                                            scrollRadius={false}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                height: "80px",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KRow
                                              id="581672"
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="731686"
                                                xs={24}
                                                sm={24}
                                                md={24}
                                                lg={24}
                                                xl={24}
                                                xxl={24}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 4,
                                                    paddingRight: 8,
                                                    paddingBottom: 4,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <Label
                                                  id="627826"
                                                  value="[datafield:cardName]"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "inline",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "16px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></Label>
                                              </KCol>
                                            </KRow>

                                            <KRow
                                              id="203418"
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="834220"
                                                xs={14}
                                                sm={14}
                                                md={14}
                                                lg={14}
                                                xl={14}
                                                xxl={14}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 2,
                                                    paddingRight: 8,
                                                    paddingBottom: 2,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <HorizontalStack
                                                  id="608796"
                                                  direction="horizontal"
                                                  size={12}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="290051"
                                                    value="[datafield:cardCode]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>

                                                  <Label
                                                    id="362412"
                                                    value="[datafield:orderNumber]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>

                                                  <Label
                                                    id="785307"
                                                    value="[datafield:orderDate]"
                                                    formatter="date"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>
                                              </KCol>

                                              <KCol
                                                id="388891"
                                                xs={10}
                                                sm={10}
                                                md={10}
                                                lg={10}
                                                xl={10}
                                                xxl={10}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 2,
                                                    paddingRight: 8,
                                                    paddingBottom: 2,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-right",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <HorizontalStack
                                                  id="692075"
                                                  direction="horizontal"
                                                  size={12}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="119975"
                                                    value="[datafield:orderStatusDesc]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>
                                              </KCol>
                                            </KRow>
                                          </KPanel>
                                        </KCol>

                                        <KCol
                                          id="554108"
                                          xs={2}
                                          sm={2}
                                          md={2}
                                          lg={2}
                                          xl={2}
                                          xxl={2}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Icon
                                            id="586026"
                                            onClick={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.DashboardSaleComponent_586026_onClick();
                                            }}
                                            showCursorPointer
                                            iconName="arrow_forward_ios"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "32px",
                                                color: "rgba(22, 193, 243, 1)",
                                                letterSpacing: "1px"
                                              } as any
                                            }
                                          ></Icon>
                                        </KCol>
                                      </KRow>
                                    </KCol>
                                  </KRow>
                                </KGallery>
                              </KCol>
                            </KRow>

                            <KRow
                              id="588417"
                              visibility={this.state.isComp588417Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="502373"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KPagination
                                  id="10983"
                                  onChange={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.DashboardSaleComponent_10983_onChange();
                                  }}
                                  kuikaRef={this.dashboardsale_10983_value_kuikaPaginationRef}
                                  total={this.state.getCountOfOrdersInProgress?.at?.(0)?.countOfRecords ?? undefined}
                                  pageSize={10}
                                  defaultCurrent={1}
                                  current={1}
                                  defaultPageSize={10}
                                  disabled={false}
                                  hideOnSinglePage={true}
                                  showLessItems={false}
                                  showQuickJumper={false}
                                  showSizeChanger={false}
                                  showFirstPageButton={false}
                                  showLastPageButton={false}
                                  showNumbers={true}
                                  showText={false}
                                  options={[{ value: "Item 1" }, { value: "Item 2" }]}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0
                                    } as any
                                  }
                                ></KPagination>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="520206"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="790937"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderTopLeftRadius: 12,
                            borderTopRightRadius: 12,
                            borderBottomRightRadius: 12,
                            borderBottomLeftRadius: 12,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(227, 241, 250, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KRow
                          id="852014"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              paddingTop: null,
                              paddingRight: null,
                              paddingLeft: null,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="722911"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 24,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <Label
                              id="736771"
                              value={ReactSystemFunctions.translate(this.ml, 736771, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "14pt",
                                  color: "rgba(22, 193, 243, 1)"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>

                        <KRow
                          id="572895"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingBottom: 0,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="578034"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: null,
                                paddingRight: 50,
                                paddingLeft: 50,
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="173368"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="340561"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="909209"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(227, 241, 250, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="954408"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="893795"
                                      value={
                                        this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.at?.(0)?.unfinished ??
                                        undefined
                                      }
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingBottom: null,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "22pt",
                                          color: "rgba(245, 166, 35, 1)",
                                          lineHeight: "28px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="306620"
                                      value={ReactSystemFunctions.translate(this.ml, 306620, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>
                                </KRow>
                              </KCol>

                              <KCol
                                id="632535"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="774088"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(227, 241, 250, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="320813"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        textAlign: "-webkit-center",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="699228"
                                      value={
                                        this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.at?.(0)?.completed ??
                                        undefined
                                      }
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingBottom: null,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "22pt",
                                          color: "rgba(126, 211, 33, 1)",
                                          lineHeight: "28px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="895667"
                                      value={ReactSystemFunctions.translate(this.ml, 895667, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>
                                </KRow>
                              </KCol>

                              <KCol
                                id="496427"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="178125"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(227, 241, 250, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="467170"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        textAlign: "-webkit-center",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="410259"
                                      value={
                                        this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.at?.(0)?.total ??
                                        undefined
                                      }
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingBottom: null,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "22pt",
                                          color: "rgba(0, 0, 0, 1)",
                                          lineHeight: "30px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="88203"
                                      value={ReactSystemFunctions.translate(this.ml, 88203, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="875555"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="247408"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="171773"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: null,
                                  paddingRight: 16,
                                  paddingLeft: 16,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="871233"
                                xs={16}
                                sm={16}
                                md={16}
                                lg={16}
                                xl={16}
                                xxl={16}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="521373"
                                  direction="horizontal"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="166399"
                                    value={ReactSystemFunctions.translate(this.ml, 166399, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </HorizontalStack>
                              </KCol>

                              <KCol
                                id="171734"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="475513"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingRight: 16,
                                      alignItems: "center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="67941"
                                    value={ReactSystemFunctions.translate(this.ml, 67941, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>

                                  <Icon
                                    id="268452"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.DashboardSaleComponent_268452_onClick();
                                    }}
                                    showCursorPointer
                                    iconName="east"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "32px",
                                        color: "rgba(22, 193, 243, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </HorizontalStack>
                              </KCol>
                            </KRow>

                            <KRow
                              id="243911"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="606388"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 8,
                                    alignItems: "flex-start",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="362367"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="34399"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderTopLeftRadius: 12,
                                        borderTopRightRadius: 12,
                                        borderBottomRightRadius: 12,
                                        borderBottomLeftRadius: 12,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KTable
                                      id="281029"
                                      kuikaRef={this.dashboardsale_281029_value_kuikaTableRef}
                                      form={this.props.form}
                                      dataSource={this.state.getAllUnCompletedDocsOfCFNRoles_yd}
                                      size="middle"
                                      bordered={true}
                                      showHeader={true}
                                      loading={false}
                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                        this.ml,
                                        281029,
                                        "nodatafoundmessage",
                                        this.defaultML
                                      )}
                                      sorter={false}
                                      pagination={false}
                                      striped={false}
                                      stripedColor="#F5F7FA"
                                      insertRowActive={false}
                                      transformedOnMobileResolution={false}
                                      tableWidthMode="fit"
                                      searchable={false}
                                      filtering={false}
                                      columnChooser={false}
                                      insertRowPosition="top"
                                      multiSelect={false}
                                      pageSize={10}
                                      showSizeChanger={false}
                                      style={
                                        {
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KTableHeader
                                        id="432587"
                                        hideOnMobileResolution={false}
                                        textDirection="Default"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(155, 155, 155, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></KTableHeader>

                                      <KTableRow
                                        id="121951"
                                        hoverFontColor="red"
                                        hoverBgColor="#F5F5F5"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(227, 241, 250, 1)",
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KTableColumn
                                          id="596449"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            596449,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="673168"
                                            value="[datafield:cardName]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="598838"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            598838,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="367289"
                                            value="[datafield:orderNumber]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="290898"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            290898,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="417045"
                                            value="[datafield:orderdate]"
                                            formatter="date"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(155, 155, 155, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="20578"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            20578,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="300707"
                                            value="[datafield:loadingDate]"
                                            formatter="date"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(155, 155, 155, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="237524"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            237524,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="490784"
                                            value="[datafield:docCount]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="271268"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            271268,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="874383"
                                            value="[datafield:orderStatusDesc]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="207748"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            207748,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Icon
                                            id="596653"
                                            onClick={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.DashboardSaleComponent_596653_onClick();
                                            }}
                                            showCursorPointer
                                            iconName="arrow_forward_ios"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "24px",
                                                color: "rgba(22, 193, 243, 1)",
                                                letterSpacing: "1px"
                                              } as any
                                            }
                                          ></Icon>
                                        </KTableColumn>
                                      </KTableRow>
                                    </KTable>

                                    <KRow
                                      id="918295"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="669269"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KPagination
                                          id="586901"
                                          onChange={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.DashboardSaleComponent_586901_onChange();
                                          }}
                                          kuikaRef={this.dashboardsale_586901_value_kuikaPaginationRef}
                                          total={
                                            this.state.getAllCountOfUnCompletedDocsOfCFNRoles_yd?.at?.(0)?.unfinished ??
                                            undefined
                                          }
                                          pageSize={10}
                                          defaultCurrent={1}
                                          current={1}
                                          defaultPageSize={10}
                                          disabled={false}
                                          hideOnSinglePage={true}
                                          showLessItems={false}
                                          showQuickJumper={false}
                                          showSizeChanger={false}
                                          showFirstPageButton={false}
                                          showLastPageButton={false}
                                          showNumbers={true}
                                          showText={false}
                                          options={[{ value: "Item 1" }, { value: "Item 2" }]}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0
                                            } as any
                                          }
                                        ></KPagination>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="513529"
                      visibility={this.state.isComp513529Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="285342"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderTopLeftRadius: 12,
                            borderTopRightRadius: 12,
                            borderBottomRightRadius: 12,
                            borderBottomLeftRadius: 12,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(227, 241, 250, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KRow
                          id="422433"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              paddingTop: null,
                              paddingRight: null,
                              paddingLeft: null,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="241762"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 24,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <Label
                              id="743598"
                              value={ReactSystemFunctions.translate(this.ml, 743598, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "14pt",
                                  color: "rgba(22, 193, 243, 1)"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>

                        <KRow
                          id="691191"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingBottom: 0,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="122594"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: null,
                                paddingRight: 50,
                                paddingLeft: 50,
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="569659"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="544119"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="709051"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(227, 241, 250, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="289575"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="66171"
                                      value={
                                        this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.at?.(0)?.unfinished ??
                                        undefined
                                      }
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingBottom: null,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "22pt",
                                          color: "rgba(245, 166, 35, 1)",
                                          lineHeight: "28px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="360915"
                                      value={ReactSystemFunctions.translate(this.ml, 360915, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>
                                </KRow>
                              </KCol>

                              <KCol
                                id="930752"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="773888"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(227, 241, 250, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="663786"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        textAlign: "-webkit-center",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="263586"
                                      value={
                                        this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.at?.(0)?.completed ??
                                        undefined
                                      }
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingBottom: null,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "22pt",
                                          color: "rgba(126, 211, 33, 1)",
                                          lineHeight: "28px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="901026"
                                      value={ReactSystemFunctions.translate(this.ml, 901026, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>
                                </KRow>
                              </KCol>

                              <KCol
                                id="374826"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="167771"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(227, 241, 250, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="284594"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        textAlign: "-webkit-center",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="771843"
                                      value={
                                        this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.at?.(0)?.total ?? undefined
                                      }
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingBottom: null,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "22pt",
                                          color: "rgba(0, 0, 0, 1)",
                                          lineHeight: "30px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="60000"
                                      value={ReactSystemFunctions.translate(this.ml, 60000, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="704444"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="923312"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="928672"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: null,
                                  paddingRight: 16,
                                  paddingLeft: 16,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="442439"
                                xs={16}
                                sm={16}
                                md={16}
                                lg={16}
                                xl={16}
                                xxl={16}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="135680"
                                  direction="horizontal"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="629988"
                                    value={ReactSystemFunctions.translate(this.ml, 629988, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </HorizontalStack>
                              </KCol>

                              <KCol
                                id="976193"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="894525"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingRight: 16,
                                      alignItems: "center",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="3236"
                                    value={ReactSystemFunctions.translate(this.ml, 3236, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>

                                  <Icon
                                    id="35770"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.DashboardSaleComponent_35770_onClick();
                                    }}
                                    showCursorPointer
                                    iconName="east"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "32px",
                                        color: "rgba(22, 193, 243, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </HorizontalStack>
                              </KCol>
                            </KRow>

                            <KRow
                              id="379944"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="264839"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 8,
                                    alignItems: "flex-start",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="130539"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="61335"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderTopLeftRadius: 12,
                                        borderTopRightRadius: 12,
                                        borderBottomRightRadius: 12,
                                        borderBottomLeftRadius: 12,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KTable
                                      id="646524"
                                      kuikaRef={this.dashboardsale_646524_value_kuikaTableRef}
                                      form={this.props.form}
                                      dataSource={this.state.getAllUnCompletedDocsOfCFNRoles_yi}
                                      size="middle"
                                      bordered={true}
                                      transformedOnMobileResolution={false}
                                      showHeader={true}
                                      loading={false}
                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                        this.ml,
                                        646524,
                                        "nodatafoundmessage",
                                        this.defaultML
                                      )}
                                      tableWidthMode="fit"
                                      searchable={false}
                                      sorter={false}
                                      filtering={false}
                                      pagination={false}
                                      pageSize={10}
                                      showSizeChanger={false}
                                      columnChooser={false}
                                      striped={false}
                                      stripedColor="#F5F7FA"
                                      insertRowActive={false}
                                      insertRowPosition="top"
                                      multiSelect={false}
                                      style={
                                        {
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KTableHeader
                                        id="284579"
                                        hideOnMobileResolution={false}
                                        textDirection="Default"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(155, 155, 155, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></KTableHeader>

                                      <KTableRow
                                        id="679837"
                                        hoverFontColor="red"
                                        hoverBgColor="#F5F5F5"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(227, 241, 250, 1)",
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KTableColumn
                                          id="442678"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            442678,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="398184"
                                            value="[datafield:cardName]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="189067"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            189067,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="860982"
                                            value="[datafield:orderNumber]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="974895"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            974895,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="544945"
                                            value="[datafield:orderdate]"
                                            formatter="date"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(155, 155, 155, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="65179"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            65179,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="913968"
                                            value="[datafield:loadingDate]"
                                            formatter="date"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(155, 155, 155, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="681268"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            681268,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="707124"
                                            value="[datafield:docCount]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="857737"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            857737,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="283997"
                                            value="[datafield:orderStatusDesc]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="898621"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            898621,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Icon
                                            id="760043"
                                            onClick={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.DashboardSaleComponent_760043_onClick();
                                            }}
                                            showCursorPointer
                                            iconName="arrow_forward_ios"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "24px",
                                                color: "rgba(22, 193, 243, 1)",
                                                letterSpacing: "1px"
                                              } as any
                                            }
                                          ></Icon>
                                        </KTableColumn>
                                      </KTableRow>
                                    </KTable>

                                    <KRow
                                      id="29775"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="998326"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KPagination
                                          id="821765"
                                          onChange={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.DashboardSaleComponent_821765_onChange();
                                          }}
                                          kuikaRef={this.dashboardsale_821765_value_kuikaPaginationRef}
                                          total={
                                            this.state.getCountOfUnCompletedDocsOfCFNRoles_yi?.at?.(0)?.unfinished ??
                                            undefined
                                          }
                                          pageSize={10}
                                          defaultCurrent={1}
                                          current={1}
                                          defaultPageSize={10}
                                          disabled={false}
                                          hideOnSinglePage={true}
                                          showLessItems={false}
                                          showQuickJumper={false}
                                          showSizeChanger={false}
                                          options={[{ value: "Item 1" }, { value: "Item 2" }]}
                                          showFirstPageButton={false}
                                          showLastPageButton={false}
                                          showNumbers={true}
                                          showText={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0
                                            } as any
                                          }
                                        ></KPagination>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="889963"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible889963: false }, () => {
                      this.callCallbackFunction(889963);
                    });
                  }}
                  visible={this.state.NavVisible889963}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible889963 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible889963: false }, () => {
                            this.callCallbackFunction(889963);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="819369"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible819369: false }, () => {
                      this.callCallbackFunction(819369);
                    });
                  }}
                  visible={this.state.NavVisible819369}
                  width="400px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible819369 && (
                      <Profile_Screen
                        onClose={() => {
                          this.setState({ NavVisible819369: false }, () => {
                            this.callCallbackFunction(819369);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(DashboardSale_Screen))))
  )
);
export { tmp as DashboardSale_Screen };
//export default tmp;
//export { tmp as DashboardSale_Screen };
