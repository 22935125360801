import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICompanyProfileEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICompanyProfileEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  SetValueOf: any;
  GetLanguage: string;
  SelectCompanyProfileByKey: any[];
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  PhotoFromGallery: string;
  SaveCompanyProfile: number;
  Notify: boolean;
  ChangeVisibilityOf: string;
  IfThenElse: boolean;
  fourCompValidation: string;
  UploadFile: any;
  updateKVKKFileContent: number;
  OpenPdf: any;
  updateKVKKFileUrl: number;
  isComp410040Visible: "visible" | "hidden";
  isComp498569Visible: "visible" | "hidden";
  isComp791975Visible: "visible" | "hidden";
  isComp194808Visible: "visible" | "hidden";
  isComp282569Visible: "visible" | "hidden";
  isComp370687Visible: "visible" | "hidden";
  isComp795035Visible: "visible" | "hidden";
  isComp820342Visible: "visible" | "hidden";
  isComp518820Visible: "visible" | "hidden";
  isComp177603Visible: "visible" | "hidden";
}

export class CompanyProfileEdit_ScreenBase extends React.PureComponent<ICompanyProfileEdit_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 28407, PropertyName: "tab", Value: "Şirket Bilgisi", TagName: "TabItem1_0_tab" },
        { Id: 696187, PropertyName: "value", Value: "Logo", TagName: "Label1_0_value" },
        { Id: 9544, PropertyName: "label", Value: "Kaydet", TagName: "btnSave_label" },
        { Id: 23391, PropertyName: "tab", Value: "Vizyon & Misyon", TagName: "TabItem1_1_tab" },
        { Id: 815426, PropertyName: "value", Value: "Vizyon", TagName: "Label16_value" },
        { Id: 2165817, PropertyName: "value", Value: "Vizyon", TagName: "lvision_label_value" },
        { Id: 751403, PropertyName: "value", Value: "Vizyon", TagName: "Label12_value" },
        { Id: 410040, PropertyName: "label", Value: "Kaydet", TagName: "Button0_0_label" },
        { Id: 388027, PropertyName: "value", Value: "Misyon", TagName: "Label17_value" },
        { Id: 2165818, PropertyName: "value", Value: "Misyon", TagName: "lblmission_value" },
        { Id: 427214, PropertyName: "value", Value: "Misyon", TagName: "Label13_value" },
        { Id: 791975, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 340046, PropertyName: "tab", Value: "İletişim Bilgisi", TagName: "TabItem1_2_tab" },
        { Id: 2165845, PropertyName: "value", Value: "Adres", TagName: "Address_label_value" },
        { Id: 2165846, PropertyName: "value", Value: "Telefon Numarası", TagName: "phoneNumber_label_value" },
        { Id: 2165847, PropertyName: "value", Value: "Email", TagName: "email_label_value" },
        { Id: 2165848, PropertyName: "value", Value: "Linkedin URL", TagName: "linkedinUrl_label_value" },
        { Id: 2165849, PropertyName: "value", Value: "Youtube URL", TagName: "youtubeUrl_label_value" },
        { Id: 2165850, PropertyName: "value", Value: "Fax Numarası", TagName: "faxNumber_label_value" },
        { Id: 467289, PropertyName: "label", Value: "Kaydet", TagName: "Button0_0_label" },
        { Id: 56801, PropertyName: "tab", Value: "Hakkımızda", TagName: "TabItem4_tab" },
        { Id: 768204, PropertyName: "tab", Value: "Türkçe", TagName: "TabItem9_0_tab" },
        { Id: 2165816, PropertyName: "value", Value: "Hakkımızda Görseli", TagName: "aboutUs_label_value" },
        { Id: 344407, PropertyName: "tab", Value: "İngilizce", TagName: "TabItem9_1_tab" },
        { Id: 185635, PropertyName: "label", Value: "Kaydet", TagName: "Button0_0_label" },
        { Id: 339725, PropertyName: "value", Value: "Şirket Profili", TagName: "Label18_value" },
        { Id: 498569, PropertyName: "label", Value: "Kaydet", TagName: "Button2_label" },
        { Id: 194808, PropertyName: "label", Value: "Kaydet", TagName: "Button3_label" },
        { Id: 518820, PropertyName: "label", Value: "Kaydet", TagName: "Button4_label" },
        { Id: 928473, PropertyName: "tab", Value: "İngilizce", TagName: "TabItem5_1_tab" },
        { Id: 101525, PropertyName: "tab", Value: "İngilizce", TagName: "TabItem7_1_tab" },
        {
          Id: 141964,
          PropertyName: "value",
          Value:
            "Bu alan, mobil uygulama üzerindeki logo, vizyon, misyon, hakkımızda ve iletişim bilgilerini değiştirebilmek veya güncelleyebilmek için kullanılır.",
          TagName: "Label3_0_value"
        },
        { Id: 987643, PropertyName: "value", Value: "Başlık", TagName: "Label4_value" },
        { Id: 279736, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 306344, PropertyName: "label", Value: "Çıkış Yap", TagName: "ContentMenuItem2_2_label" },
        { Id: 556922, PropertyName: "tab", Value: "Türkçe", TagName: "TabItem5_0_tab" },
        { Id: 532497, PropertyName: "tab", Value: "Türkçe", TagName: "TabItem7_0_tab" },
        { Id: 488133, PropertyName: "tab", Value: "KVKK", TagName: "TabItem11_tab" },
        { Id: 557876, PropertyName: "value", Value: "KVKK Dosyası (URL)", TagName: "Label20_value" },
        { Id: 101267, PropertyName: "placeholder", Value: "Buraya Yazın...", TagName: "TextInput8_placeholder" },
        { Id: 847986, PropertyName: "value", Value: "KVKK Dosyası (PDF)", TagName: "Label21_value" },
        { Id: 805466, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "664893f7-4087-4e30-8883-b49218d3a969",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 279736, PropertyName: "label", Value: "Profile" },
        { Id: 306344, PropertyName: "label", Value: "Logout" },
        { Id: 339725, PropertyName: "value", Value: "Company Profile" },
        {
          Id: 141964,
          PropertyName: "value",
          Value:
            "This field is used to change or update the logo, vision, mission, about us and contact information on the mobile application."
        },
        { Id: 28407, PropertyName: "tab", Value: "Company Information" },
        { Id: 696187, PropertyName: "value", Value: "Logo" },
        { Id: 987643, PropertyName: "value", Value: "Title" },
        { Id: 276161, PropertyName: "placeholder", Value: "..." },
        { Id: 9544, PropertyName: "label", Value: "Save" },
        { Id: 23391, PropertyName: "tab", Value: "Vision & Mission" },
        { Id: 815426, PropertyName: "value", Value: "Vision" },
        { Id: 556922, PropertyName: "tab", Value: "Turkish" },
        { Id: 2165817, PropertyName: "value", Value: "Vision" },
        { Id: 500144, PropertyName: "placeholder", Value: "TR" },
        { Id: 928473, PropertyName: "tab", Value: "English" },
        { Id: 751403, PropertyName: "value", Value: "Vision" },
        { Id: 596414, PropertyName: "placeholder", Value: "ENG" },
        { Id: 410040, PropertyName: "label", Value: "Save" },
        { Id: 498569, PropertyName: "label", Value: "Save" },
        { Id: 388027, PropertyName: "value", Value: "Mission" },
        { Id: 532497, PropertyName: "tab", Value: "Turkish" },
        { Id: 2165818, PropertyName: "value", Value: "Mission" },
        { Id: 181411, PropertyName: "placeholder", Value: "TR" },
        { Id: 101525, PropertyName: "tab", Value: "English" },
        { Id: 427214, PropertyName: "value", Value: "Mission" },
        { Id: 529767, PropertyName: "placeholder", Value: "ENG" },
        { Id: 791975, PropertyName: "label", Value: "Save" },
        { Id: 194808, PropertyName: "label", Value: "Save" },
        { Id: 340046, PropertyName: "tab", Value: "Contact Information" },
        { Id: 2165845, PropertyName: "value", Value: "Address" },
        { Id: 31658450, PropertyName: "placeholder", Value: "..." },
        { Id: 2165846, PropertyName: "value", Value: "Phone Number" },
        { Id: 31658460, PropertyName: "placeholder", Value: "..." },
        { Id: 2165847, PropertyName: "value", Value: "E-mail" },
        { Id: 31658470, PropertyName: "placeholder", Value: "..." },
        { Id: 2165848, PropertyName: "value", Value: "Linkedin URL" },
        { Id: 31658480, PropertyName: "placeholder", Value: "..." },
        { Id: 2165849, PropertyName: "value", Value: "Youtube URL" },
        { Id: 31658490, PropertyName: "placeholder", Value: "..." },
        { Id: 2165850, PropertyName: "value", Value: "Fax Number" },
        { Id: 31658500, PropertyName: "placeholder", Value: "..." },
        { Id: 467289, PropertyName: "label", Value: "Save" },
        { Id: 56801, PropertyName: "tab", Value: "About Us" },
        { Id: 2165816, PropertyName: "value", Value: "About Us Image" },
        { Id: 768204, PropertyName: "tab", Value: "Turkish" },
        { Id: 946121, PropertyName: "placeholder", Value: "TR" },
        { Id: 344407, PropertyName: "tab", Value: "English" },
        { Id: 127901, PropertyName: "placeholder", Value: "ENG" },
        { Id: 185635, PropertyName: "label", Value: "Save" },
        { Id: 518820, PropertyName: "label", Value: "Save" },
        { Id: 488133, PropertyName: "tab", Value: "KVKK" },
        { Id: 557876, PropertyName: "value", Value: "KVKK File (URL)" },
        { Id: 101267, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 847986, PropertyName: "value", Value: "KVKK File (PDF)" },
        { Id: 177603, PropertyName: "value", Value: "kvkk.pdf" },
        { Id: 805466, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      SetValueOf: "",
      GetLanguage: "",
      SelectCompanyProfileByKey: [],
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      PhotoFromGallery: "",
      SaveCompanyProfile: 0,
      Notify: false,
      IfThenElse: false,
      fourCompValidation: "",
      UploadFile: "",
      updateKVKKFileContent: 0,
      OpenPdf: "",
      updateKVKKFileUrl: "",
      isComp410040Visible: "visible",
      isComp498569Visible: "hidden",
      isComp791975Visible: "visible",
      isComp194808Visible: "visible",
      isComp282569Visible: "hidden",
      isComp370687Visible: "hidden",
      isComp795035Visible: "hidden",
      isComp820342Visible: "hidden",
      isComp518820Visible: "hidden",
      isComp177603Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("companyprofileedit", "companyprofileedit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CompanyProfileEditPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("companyprofileedit", "companyprofileedit");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("companyprofileedit", "companyprofileedit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CompanyProfileEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      companyprofileedit_370687_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined,
      companyprofileedit_31658600_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.logo ?? undefined,
      companyprofileedit_276161_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.title ?? undefined,
      companyprofileedit_31658230_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.visionImage ?? undefined,
      companyprofileedit_500144_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.vision ?? undefined,
      companyprofileedit_596414_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.visionEN ?? undefined,
      companyprofileedit_31658240_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.missionImage ?? undefined,
      companyprofileedit_181411_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.mission ?? undefined,
      companyprofileedit_529767_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.missionEN ?? undefined,
      companyprofileedit_31658450_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.address ?? undefined,
      companyprofileedit_31658460_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.phoneNumber ?? undefined,
      companyprofileedit_31658470_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.email ?? undefined,
      companyprofileedit_31658480_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.linkedinUrl ?? undefined,
      companyprofileedit_31658490_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.youtubeUrl ?? undefined,
      companyprofileedit_31658500_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.faxNumber ?? undefined,
      companyprofileedit_31658220_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.aboutUsImage ?? undefined,
      companyprofileedit_946121_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.aboutUs ?? undefined,
      companyprofileedit_127901_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.aboutUsEN ?? undefined,
      companyprofileedit_101267_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.kvkkFileUrl ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CompanyProfileEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyprofileedit_114630_value",
      "28407",
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyprofileedit_114611_value",
      "556922",
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyprofileedit_435611_value",
      "532497",
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyprofileedit_663214_value",
      "768204",
      null
    );
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName("797EA3AE-EC81-6963-5C2B-0E312BCD0D66", "Guid"),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CompanyProfileEdit/CompanyProfileEditPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCompanyProfileByKey = result?.data.selectCompanyProfileByKey;
    formVars.companyprofileedit_31658600_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.logo : null
    );
    formVars.companyprofileedit_276161_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.title : null
    );
    formVars.companyprofileedit_31658230_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.visionImage : null
    );
    formVars.companyprofileedit_500144_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.vision : null
    );
    formVars.companyprofileedit_596414_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.visionEN : null
    );
    formVars.companyprofileedit_31658240_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.missionImage : null
    );
    formVars.companyprofileedit_181411_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.mission : null
    );
    formVars.companyprofileedit_529767_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.missionEN : null
    );
    formVars.companyprofileedit_31658450_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.address : null
    );
    formVars.companyprofileedit_31658460_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.phoneNumber : null
    );
    formVars.companyprofileedit_31658470_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.email : null
    );
    formVars.companyprofileedit_31658480_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.linkedinUrl : null
    );
    formVars.companyprofileedit_31658490_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.youtubeUrl : null
    );
    formVars.companyprofileedit_31658500_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.faxNumber : null
    );
    formVars.companyprofileedit_31658220_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.aboutUsImage : null
    );
    formVars.companyprofileedit_946121_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.aboutUs : null
    );
    formVars.companyprofileedit_127901_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.aboutUsEN : null
    );
    formVars.companyprofileedit_101267_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.kvkkFileUrl : null
    );
    stateVars.isComp177603Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectCompanyProfileByKey?.length > 0 ? stateVars.SelectCompanyProfileByKey[0]?.kvkkFile : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.companyprofileedit_820342_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.companyprofileedit_370687_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp282569Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp370687Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795035Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820342Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp282569Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.companyprofileedit_370687_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp370687Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp795035Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.companyprofileedit_820342_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp820342Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.companyprofileedit_31658600_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.logo : null
    );

    formVars.companyprofileedit_276161_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.title : null
    );

    formVars.companyprofileedit_31658230_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionImage : null
    );

    formVars.companyprofileedit_500144_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.vision : null
    );

    formVars.companyprofileedit_596414_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionEN : null
    );

    formVars.companyprofileedit_31658240_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.missionImage : null
    );

    formVars.companyprofileedit_181411_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.mission : null
    );

    formVars.companyprofileedit_529767_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.missionEN : null
    );

    formVars.companyprofileedit_31658450_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.address : null
    );

    formVars.companyprofileedit_31658460_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.phoneNumber : null
    );

    formVars.companyprofileedit_31658470_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.email : null
    );

    formVars.companyprofileedit_31658480_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.linkedinUrl : null
    );

    formVars.companyprofileedit_31658490_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.youtubeUrl : null
    );

    formVars.companyprofileedit_31658500_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.faxNumber : null
    );

    formVars.companyprofileedit_31658220_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUsImage : null
    );

    formVars.companyprofileedit_946121_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUs : null
    );

    formVars.companyprofileedit_127901_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUsEN : null
    );

    formVars.companyprofileedit_101267_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.kvkkFileUrl : null
    );

    stateVars.isComp177603Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.kvkkFile : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CompanyProfileEditComponent_296916_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyProfileEdit",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "282236",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_279736_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyProfileEdit",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "273031",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_306344_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_694186_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_694186_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditComponent_694186_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "companyprofileedit_31658600_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_276161_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_276161_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      aboutUs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUs : null
        ),
        "string"
      ),
      aboutUsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUsEN : null
        ),
        "string"
      ),
      aboutUsImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.aboutUsImage
            : null
        ),
        "string"
      ),
      Address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.address : null
        ),
        "string"
      ),
      createdAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      createdBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.email : null
        ),
        "string"
      ),
      faxNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.faxNumber : null
        ),
        "string"
      ),
      linkedinUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.linkedinUrl : null
        ),
        "string"
      ),
      logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658600_value", "value", "", "", "")
        ),
        "string"
      ),
      mission_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.mission : null
        ),
        "string"
      ),
      missionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.missionEN : null
        ),
        "string"
      ),
      missionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.missionImage
            : null
        ),
        "string"
      ),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.phoneNumber : null
        ),
        "string"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_276161_value", "value", "", "", "")
        ),
        "string"
      ),
      updatedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      updatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vision_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.vision : null
        ),
        "string"
      ),
      visionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionEN : null
        ),
        "string"
      ),
      visionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionImage : null
        ),
        "string"
      ),
      youtubeUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.youtubeUrl : null
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("797EA3AE-EC81-6963-5C2B-0E312BCD0D66", "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCompanyProfile = result?.data.saveCompanyProfile;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "0cf47e7b_9967_2348_7af1_555fa98332f9_notify",
        this.defaultML,
        "Kaydedildi!"
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_52529_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_52529_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditComponent_52529_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "companyprofileedit_31658230_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_114611_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp410040Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp498569Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_114611_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp410040Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp498569Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_114611_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_114611_value", "value", "", "", "")
        ),
        "556922"
      )
    ) {
      isErrorOccurred = await this.CompanyProfileEditComponent_114611_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.CompanyProfileEditComponent_114611_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_410040_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_500144_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_500144_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_500144_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_410040_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_410040_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_410040_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyprofileedit_114611_value",
      "928473",
      null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_410040_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditComponent_410040_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_500144_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_500144_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_596414_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_596414_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658230_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "companyprofileedit_31658230_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      aboutUs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUs : null
        ),
        "string"
      ),
      aboutUsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUsEN : null
        ),
        "string"
      ),
      aboutUsImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.aboutUsImage
            : null
        ),
        "string"
      ),
      Address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.address : null
        ),
        "string"
      ),
      createdAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      createdBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.email : null
        ),
        "string"
      ),
      faxNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.faxNumber : null
        ),
        "string"
      ),
      linkedinUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.linkedinUrl : null
        ),
        "string"
      ),
      logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.logo : null
        ),
        "string"
      ),
      mission_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.mission : null
        ),
        "string"
      ),
      missionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.missionEN : null
        ),
        "string"
      ),
      missionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.missionImage
            : null
        ),
        "string"
      ),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.phoneNumber : null
        ),
        "string"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.title : null
        ),
        "string"
      ),
      updatedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      updatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vision_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_500144_value", "value", "", "", "")
        ),
        "string"
      ),
      visionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_596414_value", "value", "", "", "")
        ),
        "string"
      ),
      visionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658230_value", "value", "", "", "")
        ),
        "string"
      ),
      youtubeUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.youtubeUrl : null
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("797EA3AE-EC81-6963-5C2B-0E312BCD0D66", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_410040_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCompanyProfile = result?.data.saveCompanyProfile;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_410040_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_410040_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c054a10f_4e84_a68c_b0f8_bf2b56e0c7ff_notify",
        this.defaultML,
        "Kaydedildi!"
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_498569_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_596414_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_596414_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_596414_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_498569_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_498569_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_498569_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyprofileedit_114611_value",
      "556922",
      null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_498569_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditComponent_498569_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_500144_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_500144_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_596414_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_596414_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658230_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "companyprofileedit_31658230_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      aboutUs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUs : null
        ),
        "string"
      ),
      aboutUsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUsEN : null
        ),
        "string"
      ),
      aboutUsImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.aboutUsImage
            : null
        ),
        "string"
      ),
      Address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.address : null
        ),
        "string"
      ),
      createdAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      createdBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.email : null
        ),
        "string"
      ),
      faxNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.faxNumber : null
        ),
        "string"
      ),
      linkedinUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.linkedinUrl : null
        ),
        "string"
      ),
      logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.logo : null
        ),
        "string"
      ),
      mission_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.mission : null
        ),
        "string"
      ),
      missionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.missionEN : null
        ),
        "string"
      ),
      missionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.missionImage
            : null
        ),
        "string"
      ),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.phoneNumber : null
        ),
        "string"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.title : null
        ),
        "string"
      ),
      updatedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      updatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vision_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_500144_value", "value", "", "", "")
        ),
        "string"
      ),
      visionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_596414_value", "value", "", "", "")
        ),
        "string"
      ),
      visionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658230_value", "value", "", "", "")
        ),
        "string"
      ),
      youtubeUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.youtubeUrl : null
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("797EA3AE-EC81-6963-5C2B-0E312BCD0D66", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_498569_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCompanyProfile = result?.data.saveCompanyProfile;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_498569_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_498569_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fa20faa4_9c7f_1862_0a8e_117dbab83015_notify",
        this.defaultML,
        "Kaydedildi!"
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_493576_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_493576_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditComponent_493576_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "companyprofileedit_31658240_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_435611_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp791975Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp194808Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_435611_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp791975Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp194808Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_435611_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_435611_value", "value", "", "", "")
        ),
        "532497"
      )
    ) {
      isErrorOccurred = await this.CompanyProfileEditComponent_435611_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.CompanyProfileEditComponent_435611_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_791975_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_181411_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_181411_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_181411_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_791975_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_791975_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_791975_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyprofileedit_435611_value",
      "101525",
      null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_791975_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditComponent_791975_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_181411_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_181411_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_529767_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "companyprofileedit_529767_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658240_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "companyprofileedit_31658240_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      aboutUs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUs : null
        ),
        "string"
      ),
      aboutUsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUsEN : null
        ),
        "string"
      ),
      aboutUsImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.aboutUsImage
            : null
        ),
        "string"
      ),
      Address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.address : null
        ),
        "string"
      ),
      createdAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      createdBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.email : null
        ),
        "string"
      ),
      faxNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.faxNumber : null
        ),
        "string"
      ),
      linkedinUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.linkedinUrl : null
        ),
        "string"
      ),
      logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.logo : null
        ),
        "string"
      ),
      mission_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_181411_value", "value", "", "", "")
        ),
        "string"
      ),
      missionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_529767_value", "value", "", "", "")
        ),
        "string"
      ),
      missionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658240_value", "value", "", "", "")
        ),
        "string"
      ),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.phoneNumber : null
        ),
        "string"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.title : null
        ),
        "string"
      ),
      updatedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      updatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vision_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.vision : null
        ),
        "string"
      ),
      visionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionEN : null
        ),
        "string"
      ),
      visionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionImage : null
        ),
        "string"
      ),
      youtubeUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.youtubeUrl : null
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("797EA3AE-EC81-6963-5C2B-0E312BCD0D66", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_791975_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCompanyProfile = result?.data.saveCompanyProfile;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_791975_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_791975_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "3eb2239b_5dd8_cb92_062b_0df6844e5de4_notify",
        this.defaultML,
        "Kaydedildi!"
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_194808_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_529767_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "companyprofileedit_529767_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_529767_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_194808_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_194808_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_194808_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyprofileedit_435611_value",
      "532497",
      null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_194808_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditComponent_194808_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_181411_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_181411_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_529767_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "companyprofileedit_529767_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658240_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "companyprofileedit_31658240_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      aboutUs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUs : null
        ),
        "string"
      ),
      aboutUsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUsEN : null
        ),
        "string"
      ),
      aboutUsImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.aboutUsImage
            : null
        ),
        "string"
      ),
      Address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.address : null
        ),
        "string"
      ),
      createdAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      createdBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.email : null
        ),
        "string"
      ),
      faxNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.faxNumber : null
        ),
        "string"
      ),
      linkedinUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.linkedinUrl : null
        ),
        "string"
      ),
      logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.logo : null
        ),
        "string"
      ),
      mission_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_181411_value", "value", "", "", "")
        ),
        "string"
      ),
      missionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_529767_value", "value", "", "", "")
        ),
        "string"
      ),
      missionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658240_value", "value", "", "", "")
        ),
        "string"
      ),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.phoneNumber : null
        ),
        "string"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.title : null
        ),
        "string"
      ),
      updatedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      updatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vision_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.vision : null
        ),
        "string"
      ),
      visionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionEN : null
        ),
        "string"
      ),
      visionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionImage : null
        ),
        "string"
      ),
      youtubeUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.youtubeUrl : null
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("797EA3AE-EC81-6963-5C2B-0E312BCD0D66", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_194808_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCompanyProfile = result?.data.saveCompanyProfile;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_194808_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_194808_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "e1de172c_c574_a4bd_1e72_273ca3c4999b_notify",
        this.defaultML,
        "Kaydedildi!"
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_467289_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658450_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_31658450_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658470_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_31658470_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658500_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_31658500_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658480_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_31658480_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658460_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_31658460_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658490_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_31658490_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      aboutUs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUs : null
        ),
        "string"
      ),
      aboutUsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.aboutUsEN : null
        ),
        "string"
      ),
      aboutUsImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.aboutUsImage
            : null
        ),
        "string"
      ),
      Address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658450_value", "value", "", "", "")
        ),
        "string"
      ),
      createdAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      createdBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658470_value", "value", "", "", "")
        ),
        "string"
      ),
      faxNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658500_value", "value", "", "", "")
        ),
        "string"
      ),
      linkedinUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658480_value", "value", "", "", "")
        ),
        "string"
      ),
      logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.logo : null
        ),
        "string"
      ),
      mission_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.mission : null
        ),
        "string"
      ),
      missionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.missionEN : null
        ),
        "string"
      ),
      missionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.missionImage
            : null
        ),
        "string"
      ),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658460_value", "value", "", "", "")
        ),
        "string"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.title : null
        ),
        "string"
      ),
      updatedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      updatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vision_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.vision : null
        ),
        "string"
      ),
      visionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionEN : null
        ),
        "string"
      ),
      visionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionImage : null
        ),
        "string"
      ),
      youtubeUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658490_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("797EA3AE-EC81-6963-5C2B-0E312BCD0D66", "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_467289_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCompanyProfile = result?.data.saveCompanyProfile;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_467289_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_467289_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b75be39e_0aba_28b7_11d8_9d08a159255c_notify",
        this.defaultML,
        "Kaydedildi!"
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_310219_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_310219_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditComponent_310219_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "companyprofileedit_31658220_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_185635_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_946121_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_946121_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_946121_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdfasdfa", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("sadfasdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_185635_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_185635_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_185635_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyprofileedit_663214_value",
      "344407",
      null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_185635_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditComponent_185635_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_946121_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_946121_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_127901_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_127901_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      aboutUs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_946121_value", "value", "", "", "")
        ),
        "string"
      ),
      aboutUsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_127901_value", "value", "", "", "")
        ),
        "string"
      ),
      aboutUsImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658220_value", "value", "", "", "")
        ),
        "string"
      ),
      Address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.address : null
        ),
        "string"
      ),
      createdAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      createdBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.email : null
        ),
        "string"
      ),
      faxNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.faxNumber : null
        ),
        "string"
      ),
      linkedinUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.linkedinUrl : null
        ),
        "string"
      ),
      logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.logo : null
        ),
        "string"
      ),
      mission_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.mission : null
        ),
        "string"
      ),
      missionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.missionEN : null
        ),
        "string"
      ),
      missionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.missionImage
            : null
        ),
        "string"
      ),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.phoneNumber : null
        ),
        "string"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.title : null
        ),
        "string"
      ),
      updatedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      updatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vision_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.vision : null
        ),
        "string"
      ),
      visionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionEN : null
        ),
        "string"
      ),
      visionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionImage : null
        ),
        "string"
      ),
      youtubeUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.youtubeUrl : null
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("797EA3AE-EC81-6963-5C2B-0E312BCD0D66", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_185635_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCompanyProfile = result?.data.saveCompanyProfile;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_185635_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_185635_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1194ec59_9a04_5d31_d164_ad7d38835234_notify",
        this.defaultML,
        "Kaydedildi!"
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_518820_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_946121_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_946121_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_946121_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdfasdfa", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("sadfasdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_518820_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_518820_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_518820_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyprofileedit_663214_value",
      "768204",
      null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_518820_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditComponent_518820_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_946121_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_946121_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_127901_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_127901_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      aboutUs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_946121_value", "value", "", "", "")
        ),
        "string"
      ),
      aboutUsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_127901_value", "value", "", "", "")
        ),
        "string"
      ),
      aboutUsImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_31658220_value", "value", "", "", "")
        ),
        "string"
      ),
      Address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.address : null
        ),
        "string"
      ),
      createdAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      createdBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.email : null
        ),
        "string"
      ),
      faxNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.faxNumber : null
        ),
        "string"
      ),
      linkedinUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.linkedinUrl : null
        ),
        "string"
      ),
      logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.logo : null
        ),
        "string"
      ),
      mission_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.mission : null
        ),
        "string"
      ),
      missionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.missionEN : null
        ),
        "string"
      ),
      missionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0
            ? this.state.SelectCompanyProfileByKey[0]?.missionImage
            : null
        ),
        "string"
      ),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.phoneNumber : null
        ),
        "string"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.title : null
        ),
        "string"
      ),
      updatedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      updatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vision_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.vision : null
        ),
        "string"
      ),
      visionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionEN : null
        ),
        "string"
      ),
      visionImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.visionImage : null
        ),
        "string"
      ),
      youtubeUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.youtubeUrl : null
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("797EA3AE-EC81-6963-5C2B-0E312BCD0D66", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_518820_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCompanyProfile = result?.data.saveCompanyProfile;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_518820_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileEditComponent_518820_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "2152e702_0afe_37cb_b903_21e8a1e54996_notify",
        this.defaultML,
        "Kaydedildi!"
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_224452_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileEditComponent_224452_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileEditComponent_224452_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kvkkFileContent_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.id : null
        ),
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "CompanyProfileEdit/CompanyProfileEditComponent_224452_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.updateKVKKFileContent = result?.data.updateKVKKFileContent;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  CompanyProfileEditComponent_177603_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.OpenPdf = await ReactSystemFunctions.openPdf(
      ReactSystemFunctions.toString(
        this,
        this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.kvkkFile : null
      ),
      false,
      "newTab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileEditComponent_805466_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_101267_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyprofileedit_101267_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kvkkFileUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyprofileedit_101267_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.id : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileEdit/CompanyProfileEditComponent_805466_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.updateKVKKFileUrl = result?.data.updateKVKKFileUrl;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [282236, 273031] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.CompanyProfileEditPageInit();
    }
  }
}
