import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICompanyEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICompanyEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  SqlGetLanguages: any[];
  GetLanguage: string;
  GetCompanyById: any[];
  SetValueOf: any;
  IfThenElse: boolean;
  SaveCompanies: number;
  Notify: boolean;
  isComp275171Visible: "visible" | "hidden";
  isComp521089Visible: "visible" | "hidden";
  isComp216075Visible: "visible" | "hidden";
  isCompcompanyedit_42131AuthorizationVisible: "visible" | "hidden";
}

export class CompanyEdit_ScreenBase extends React.PureComponent<ICompanyEdit_ScreenProps, any> {
  companyedit_577698_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 861512, PropertyName: "value", Value: "Müşteri Referans Numarası", TagName: "Label4_value" },
        { Id: 963625, PropertyName: "value", Value: "Şirket Adı", TagName: "Label10_0_value" },
        { Id: 42131, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 949935, PropertyName: "value", Value: "Adres", TagName: "Label6_0_value" },
        { Id: 717958, PropertyName: "value", Value: "Şehir", TagName: "Label5_0_value" },
        { Id: 265799, PropertyName: "value", Value: "Ülke", TagName: "Label7_0_value" },
        { Id: 233609, PropertyName: "value", Value: "Posta Kodu", TagName: "Label4_0_value" },
        { Id: 275171, PropertyName: "value", Value: "Şirketi Düzenle", TagName: "Label9_value" },
        { Id: 521089, PropertyName: "value", Value: "Yeni Şirket", TagName: "Label10_value" },
        { Id: 20830, PropertyName: "value", Value: "Telefon Numarası", TagName: "Label0_0_value" },
        { Id: 544874, PropertyName: "value", Value: "Tercih Edilen Dil", TagName: "Label0_0_value" },
        { Id: 461866, PropertyName: "value", Value: "Alan Kodu", TagName: "Label0_0_value" },
        { Id: 349627, PropertyName: "value", Value: "Tedarikçi Referans Numarası", TagName: "Label1_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "071c34c3-25d0-4b80-a83b-a48bcc49a793",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 275171, PropertyName: "value", Value: "Company Edit" },
        { Id: 521089, PropertyName: "value", Value: "New Company" },
        { Id: 963625, PropertyName: "value", Value: "Company Name" },
        { Id: 108605, PropertyName: "value", Value: "Distributor (SAP) Code" },
        { Id: 172468, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 461866, PropertyName: "value", Value: "Prefix" },
        { Id: 543328, PropertyName: "placeholder", Value: "+90" },
        { Id: 20830, PropertyName: "value", Value: "Phone Number" },
        { Id: 544874, PropertyName: "value", Value: "Preferred Language" },
        { Id: 949935, PropertyName: "value", Value: "Addrees" },
        { Id: 717958, PropertyName: "value", Value: "City" },
        { Id: 265799, PropertyName: "value", Value: "Country" },
        { Id: 233609, PropertyName: "value", Value: "Posta Code" },
        { Id: 42131, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.companyedit_577698_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SqlGetLanguages: [],
      GetLanguage: "",
      GetCompanyById: [],
      IfThenElse: false,
      SaveCompanies: 0,
      Notify: false,
      isComp275171Visible: "hidden",
      isComp521089Visible: "hidden",
      isComp216075Visible: "hidden",
      isCompcompanyedit_42131AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("companyedit", "companyedit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CompanyEditPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcompanyedit_42131AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("companyedit", "companyedit");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Administrator", "Logistic"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("companyedit", "companyedit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CompanyEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      companyedit_131060_value: this.state.GetCompanyById?.at?.(0)?.cardName ?? undefined,
      companyedit_172468_value: this.state.GetCompanyById?.at?.(0)?.salesPersonSAPCode ?? undefined,
      companyedit_543328_value: this.state.GetCompanyById?.at?.(0)?.phonePrefix ?? undefined,
      companyedit_383063_value: this.state.GetCompanyById?.at?.(0)?.phoneNumber ?? undefined,
      companyedit_89846_value: this.state.GetCompanyById?.at?.(0)?.address ?? undefined,
      companyedit_402223_value: this.state.GetCompanyById?.at?.(0)?.addressCity ?? undefined,
      companyedit_901632_value: this.state.GetCompanyById?.at?.(0)?.addressCountry ?? undefined,
      companyedit_751574_value: this.state.GetCompanyById?.at?.(0)?.postaCode ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CompanyEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CompanyEdit/CompanyEditPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SqlGetLanguages = result?.data.sqlGetLanguages;

    formVars.companyedit_577698_options = stateVars.SqlGetLanguages;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyEditPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyEditPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyEditPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CompanyEdit/CompanyEditPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetCompanyById = result?.data.getCompanyById;
    formVars.companyedit_131060_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.cardName : null
    );
    formVars.companyedit_172468_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.salesPersonSAPCode : null
    );
    formVars.companyedit_543328_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.phonePrefix : null
    );
    formVars.companyedit_383063_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.phoneNumber : null
    );
    formVars.companyedit_89846_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.address : null
    );
    formVars.companyedit_402223_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.addressCity : null
    );
    formVars.companyedit_901632_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.addressCountry : null
    );
    formVars.companyedit_751574_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.postaCode : null
    );
    stateVars.isComp275171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp521089Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyEditPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyEditPageInit_if3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyedit_577698_value",
      "tr_TR",
      null,
      "languageKey"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CompanyEditPageInit_else3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyedit_577698_value",
      ReactSystemFunctions.toString(
        this,
        this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.preferredLang : null
      ),
      null,
      "languageKey"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CompanyEditPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.preferredLang : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.CompanyEditPageInit_if3();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.CompanyEditPageInit_else3();
      if (isErrorOccurred) return true;
    }

    stateVars.isComp275171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp521089Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.companyedit_131060_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.cardName : null
    );

    stateVars.isComp216075Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.companyType ?? this.props.screenInputs.companytype,
        "4"
      ) === true
        ? "visible"
        : "hidden";
    formVars.companyedit_172468_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.salesPersonSAPCode : null
    );

    formVars.companyedit_543328_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.phonePrefix : null
    );

    formVars.companyedit_383063_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.phoneNumber : null
    );

    stateVars.dataSource_577698 = this.state.SqlGetLanguages;
    formVars.companyedit_89846_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.address : null
    );

    formVars.companyedit_402223_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.addressCity : null
    );

    formVars.companyedit_901632_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.addressCountry : null
    );

    formVars.companyedit_751574_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.postaCode : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CompanyEditComponent_462237_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyEditComponent_42131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_131060_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyedit_89846_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_131060_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "companyedit_402223_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_131060_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "companyedit_901632_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_131060_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyedit_131060_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_383063_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyedit_383063_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_543328_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyedit_543328_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_131060_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "companyedit_751574_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_577698_value", "value", "SqlGetLanguages", "languageKey", "")
        ),
        null
      ),
      message: "*",
      formName: "companyedit_577698_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_172468_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "companyedit_172468_value",
      condition: ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.companyType ?? this.props.screenInputs.companytype,
        4
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_89846_value", "value", "", "", "")
        ),
        "string"
      ),
      addressCity_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_402223_value", "value", "", "", "")
        ),
        "string"
      ),
      addressCountry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_901632_value", "value", "", "", "")
        ),
        "string"
      ),
      referenceNo_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      cardName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_131060_value", "value", "", "", "")
        ),
        "string"
      ),
      isDeleted_0: ReactSystemFunctions.convertToTypeByName(false, "string"),
      logo_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_383063_value", "value", "", "", "")
        ),
        "string"
      ),
      phonePrefix_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyedit_543328_value", "value", "", "", "")
        ),
        "string"
      ),
      postaCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_751574_value", "value", "", "", "")
        ),
        "string"
      ),
      status_0: ReactSystemFunctions.convertToTypeByName(null, "number"),
      companyType_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyType ?? this.props.screenInputs.companytype,
        "number"
      ),
      preferredLang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "companyedit_577698_value", "value", "SqlGetLanguages", "languageKey", "")
        ),
        "string"
      ),
      salesPersonSAPCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyedit_172468_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CompanyEdit/CompanyEditComponent_42131_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCompanies = result?.data.saveCompanies;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyEditComponent_42131_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyEditComponent_42131_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "65159e0e_1091_cfae_eb04_62a46704bb20_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
