import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IResetPassword_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IResetPassword_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetLanguage: string;
  languageLabelResult: string;
  PasswordReset: string;
  goToSignInActivity: any;
  NAVIGATE: any;
  isComp24210Visible: "visible" | "hidden";
}

export class ResetPassword_ScreenBase extends React.PureComponent<IResetPassword_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 71170, PropertyName: "value", Value: "Şifre Sıfırlama", TagName: "Label5_value" },
        { Id: 593462, PropertyName: "value", Value: "Lütfen yeni şifrenizi girin", TagName: "Label6_value" },
        { Id: 906841, PropertyName: "value", Value: "Yeni Şifre", TagName: "Label5_value" },
        { Id: 350128, PropertyName: "value", Value: "Yeni Şifre Tekrar", TagName: "Label6_value" },
        { Id: 480248, PropertyName: "label", Value: "Gönder", TagName: "Button1_label" },
        { Id: 956371, PropertyName: "value", Value: "Henüz kayıtlı değil misiniz ?", TagName: "Label2_value" },
        { Id: 793037, PropertyName: "value", Value: "Kayıt Ol", TagName: "Label3_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "86421b90-b438-49f1-a071-235165b04fe4",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 71170, PropertyName: "value", Value: "Reset Your Password" },
        { Id: 593462, PropertyName: "value", Value: "Please write your new password." },
        { Id: 906841, PropertyName: "value", Value: "New Password" },
        { Id: 287847, PropertyName: "placeholder", Value: "***********" },
        { Id: 350128, PropertyName: "value", Value: "New Password Again" },
        { Id: 888072, PropertyName: "placeholder", Value: "***********" },
        { Id: 480248, PropertyName: "label", Value: "Send" },
        { Id: 956371, PropertyName: "value", Value: "Would you like to become a member ?" },
        { Id: 793037, PropertyName: "value", Value: "Sign up" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetLanguage: "",
      languageLabelResult: "",
      PasswordReset: "",
      goToSignInActivity: "",
      NAVIGATE: "",
      isComp24210Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("resetpassword", "resetpassword");
      return;
    }

    if (true) {
      await this.ResetPasswordPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("resetpassword", "resetpassword");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("resetpassword", "resetpassword");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ResetPasswordPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ResetPasswordPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ResetPasswordPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ResetPasswordPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ResetPassword/ResetPasswordPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.languageLabelResult = result?.data.languageLabelResult;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  ResetPasswordComponent_480248_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "resetpassword_888072_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "resetpassword_287847_value", "value", "", "", "")
        )
      ),
      message: "Girilen şifreler eşleşmiyor.",
      formName: "resetpassword_888072_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PasswordReset = await ReactSystemFunctions.resetPassword(
      this,
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "resetpassword_888072_value", "value", "", "", "")
      ),
      null,
      null
    );
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ResetPasswordComponent_793037_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ResetPassword",
      "Signup",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
