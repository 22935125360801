import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBrokerEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBrokerEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  getBrokerById: any[];
  saveBroker: number;
  isComp322498Visible: "visible" | "hidden";
  isComp245412Visible: "visible" | "hidden";
  isCompbrokeredit_42131AuthorizationVisible: "visible" | "hidden";
}

export class BrokerEdit_ScreenBase extends React.PureComponent<IBrokerEdit_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 861512, PropertyName: "value", Value: "İsim", TagName: "Label4_value" },
        { Id: 963625, PropertyName: "value", Value: "Soyisim", TagName: "Label10_0_value" },
        { Id: 700365, PropertyName: "value", Value: "Alan Kodu", TagName: "Label10_0_value" },
        { Id: 372226, PropertyName: "value", Value: "Telefon Numarası", TagName: "Label10_0_value" },
        { Id: 42131, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 245412, PropertyName: "value", Value: "Yeni Broker", TagName: "Label1_value" },
        { Id: 322498, PropertyName: "value", Value: "Broker Düzenle", TagName: "Label13_value" },
        { Id: 590006, PropertyName: "value", Value: "Eposta", TagName: "Label8_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "9d2511bd-b880-435e-8de0-817ae86c4be8",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 322498, PropertyName: "value", Value: "Broker Edit" },
        { Id: 245412, PropertyName: "value", Value: "Create Broker" },
        { Id: 861512, PropertyName: "value", Value: "Name" },
        { Id: 963625, PropertyName: "value", Value: "Surname" },
        { Id: 590006, PropertyName: "value", Value: "E-mail" },
        { Id: 700365, PropertyName: "value", Value: "Prefix" },
        { Id: 929090, PropertyName: "placeholder", Value: "+90" },
        { Id: 372226, PropertyName: "value", Value: "Phone Number" },
        { Id: 42131, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      getBrokerById: [],
      saveBroker: "",
      isComp322498Visible: "hidden",
      isComp245412Visible: "hidden",
      isCompbrokeredit_42131AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("brokeredit", "brokeredit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.BrokerEditPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompbrokeredit_42131AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("brokeredit", "brokeredit");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("brokeredit", "brokeredit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BrokerEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      brokeredit_274541_value: this.state.getBrokerById?.at?.(0)?.name ?? undefined,
      brokeredit_131060_value: this.state.getBrokerById?.at?.(0)?.surname ?? undefined,
      brokeredit_737823_value: this.state.getBrokerById?.at?.(0)?.email ?? undefined,
      brokeredit_929090_value: this.state.getBrokerById?.at?.(0)?.phoneprefix ?? undefined,
      brokeredit_728237_value: this.state.getBrokerById?.at?.(0)?.phoneNumber ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  BrokerEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.BrokerEditPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  BrokerEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      brokerId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.brokerId ?? this.props.screenInputs.brokerid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BrokerEdit/BrokerEditPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getBrokerById = result?.data.getBrokerById;
    formVars.brokeredit_274541_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.name : null
    );
    formVars.brokeredit_131060_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.surname : null
    );
    formVars.brokeredit_737823_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.email : null
    );
    formVars.brokeredit_929090_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.phoneprefix : null
    );
    formVars.brokeredit_728237_value = ReactSystemFunctions.toString(
      this,
      stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.phoneNumber : null
    );
    stateVars.isComp322498Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp245412Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.BrokerEditPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BrokerEditPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp322498Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp245412Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.brokeredit_274541_value = ReactSystemFunctions.toString(
      this,
      this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.name : null
    );

    formVars.brokeredit_131060_value = ReactSystemFunctions.toString(
      this,
      this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.surname : null
    );

    formVars.brokeredit_737823_value = ReactSystemFunctions.toString(
      this,
      this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.email : null
    );

    formVars.brokeredit_929090_value = ReactSystemFunctions.toString(
      this,
      this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.phoneprefix : null
    );

    formVars.brokeredit_728237_value = ReactSystemFunctions.toString(
      this,
      this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.phoneNumber : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BrokerEditComponent_462237_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BrokerEditComponent_42131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brokeredit_274541_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "brokeredit_274541_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brokeredit_131060_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "brokeredit_131060_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brokeredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "brokeredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brokeredit_737823_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "brokeredit_737823_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brokeredit_929090_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "brokeredit_929090_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brokeredit_728237_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "brokeredit_728237_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brokeredit_274541_value", "value", "", "", "")
        ),
        "string"
      ),
      surname_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brokeredit_131060_value", "value", "", "", "")
        ),
        "string"
      ),
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brokeredit_737823_value", "value", "", "", "")
        ),
        "string"
      ),
      phoneprefix_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brokeredit_929090_value", "value", "", "", "")
        ),
        "string"
      ),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "brokeredit_728237_value", "value", "", "", "")
        ),
        "string"
      ),
      brokerId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.brokerId ?? this.props.screenInputs.brokerid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BrokerEdit/BrokerEditComponent_42131_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.saveBroker = result?.data.saveBroker;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.BrokerEditComponent_42131_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BrokerEditComponent_42131_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
