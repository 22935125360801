import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ILeftMenuCustomer_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ILeftMenuCustomer_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  isComp762261Visible: "visible" | "hidden";
  isComp138101Visible: "visible" | "hidden";
  isComp823300Visible: "visible" | "hidden";
  isComp453414Visible: "visible" | "hidden";
  isCompleftmenucustomer_62111AuthorizationVisible: "visible" | "hidden";
  isCompleftmenucustomer_15992AuthorizationVisible: "visible" | "hidden";
  isCompleftmenucustomer_24171AuthorizationVisible: "visible" | "hidden";
  isCompleftmenucustomer_910552AuthorizationVisible: "visible" | "hidden";
  isCompleftmenucustomer_19191AuthorizationVisible: "visible" | "hidden";
  isCompleftmenucustomer_762261AuthorizationVisible: "visible" | "hidden";
  isCompleftmenucustomer_138101AuthorizationVisible: "visible" | "hidden";
  isCompleftmenucustomer_823300AuthorizationVisible: "visible" | "hidden";
  isCompleftmenucustomer_453414AuthorizationVisible: "visible" | "hidden";
}

export class LeftMenuCustomer_ScreenBase extends React.PureComponent<ILeftMenuCustomer_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 618255, PropertyName: "value", Value: "Şirket Profili", TagName: "Label1_0_value" },
        { Id: 961588, PropertyName: "value", Value: "Versiyon 1.0.0", TagName: "Label11_value" },
        { Id: 674888, PropertyName: "value", Value: "Anasayfa", TagName: "Label0_0_value" },
        { Id: 607152, PropertyName: "value", Value: "Çıkış Yap", TagName: "Label10_value" },
        { Id: 693081, PropertyName: "value", Value: "Yetkili Kullanıcılar", TagName: "Label0_0_value" },
        { Id: 845168, PropertyName: "value", Value: "Yola Çıkan Siparişler", TagName: "Label0_0_value" },
        { Id: 754993, PropertyName: "value", Value: "Hazırlanan Siparişler", TagName: "Label0_0_value" },
        { Id: 755321, PropertyName: "value", Value: "Sipariş Anketleri", TagName: "Label0_0_value" },
        { Id: 605213, PropertyName: "value", Value: "Teslim Edilen Siparişler", TagName: "Label0_0_value" },
        { Id: 211109, PropertyName: "value", Value: "Profil Bilgileri", TagName: "Label0_0_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "950e1734-a77b-4019-9ce3-5fbde7807a37",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 674888, PropertyName: "value", Value: "Dashboard" },
        { Id: 754993, PropertyName: "value", Value: "Orders in Preparation" },
        { Id: 845168, PropertyName: "value", Value: "Orders out of Delivery" },
        { Id: 605213, PropertyName: "value", Value: "Delivered Orders" },
        { Id: 211109, PropertyName: "value", Value: "My Profile" },
        { Id: 755321, PropertyName: "value", Value: "Surveys" },
        { Id: 693081, PropertyName: "value", Value: "Authorized Users" },
        { Id: 618255, PropertyName: "value", Value: "Company Profile" },
        { Id: 961588, PropertyName: "value", Value: "Version 1.0.0" },
        { Id: 607152, PropertyName: "value", Value: "Logout" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      isComp762261Visible: "hidden",
      isComp138101Visible: "hidden",
      isComp823300Visible: "hidden",
      isComp453414Visible: "hidden",
      isCompleftmenucustomer_62111AuthorizationVisible: "visible",
      isCompleftmenucustomer_15992AuthorizationVisible: "visible",
      isCompleftmenucustomer_24171AuthorizationVisible: "visible",
      isCompleftmenucustomer_910552AuthorizationVisible: "visible",
      isCompleftmenucustomer_19191AuthorizationVisible: "visible",
      isCompleftmenucustomer_762261AuthorizationVisible: "visible",
      isCompleftmenucustomer_138101AuthorizationVisible: "visible",
      isCompleftmenucustomer_823300AuthorizationVisible: "visible",
      isCompleftmenucustomer_453414AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("leftmenucustomer", "leftmenucustomer");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenucustomer_62111AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenucustomer_15992AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenucustomer_24171AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenucustomer_910552AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenucustomer_19191AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenucustomer_762261AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenucustomer_138101AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenucustomer_823300AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenucustomer_453414AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("leftmenucustomer", "leftmenucustomer");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Customer"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("leftmenucustomer", "leftmenucustomer");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  LeftMenuCustomerComponent_84674_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuCustomerComponent_15992_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("DashboardCustomer", "st", 0);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenuCustomer",
      "DashboardCustomer",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuCustomerComponent_24171_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("DashboardCustomer", "st", 0);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenuCustomer",
      "DashboardCustomer",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuCustomerComponent_910552_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("DashboardCustomer", "st", 10);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenuCustomer",
      "DashboardCustomer",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuCustomerComponent_19191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("DashboardCustomer", "st", 20);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenuCustomer",
      "DashboardCustomer",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuCustomerComponent_762261_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenuCustomer",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "862907",
      null,
      "right",
      null,
      "500px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuCustomerComponent_823300_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenuCustomer",
      "DocumentWaitingUploadListCA",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuCustomerComponent_988826_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuCustomerComponent_942656_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuCustomerComponent_607152_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuCustomerComponent_547928_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, 862907] as number[];
  }
}
