import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICurrenciesEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICurrenciesEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  sqlGetCurrencyById: any[];
  GetUserCurrentId: any[];
  currenciesSave: number;
  Notify: boolean;
  isComp898158Visible: "visible" | "hidden";
  isComp457942Visible: "visible" | "hidden";
}

export class CurrenciesEdit_ScreenBase extends React.PureComponent<ICurrenciesEdit_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 241619, PropertyName: "value", Value: "ISO Kodu", TagName: "Label3_value" },
        { Id: 747582, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 898158, PropertyName: "value", Value: "Para Birimi Düzenle", TagName: "Label3_0_value" },
        { Id: 457942, PropertyName: "value", Value: "Yeni Para Birimi", TagName: "Label2_value" },
        { Id: 598575, PropertyName: "value", Value: "Adı", TagName: "Label2_0_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "4f9a99c0-91a2-4433-ad68-98dff4e4c17c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 898158, PropertyName: "value", Value: "Currencies Edit" },
        { Id: 457942, PropertyName: "value", Value: "Currencies Current" },
        { Id: 241619, PropertyName: "value", Value: "ISO Code" },
        { Id: 598575, PropertyName: "value", Value: "Name" },
        { Id: 747582, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      sqlGetCurrencyById: [],
      GetUserCurrentId: [],
      currenciesSave: "",
      Notify: false,
      isComp898158Visible: "hidden",
      isComp457942Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("currenciesedit", "currenciesedit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CurrenciesEditPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("currenciesedit", "currenciesedit");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("currenciesedit", "currenciesedit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CurrenciesEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      currenciesedit_814447_value: this.state.sqlGetCurrencyById?.at?.(0)?.isoCode ?? undefined,
      currenciesedit_882967_value: this.state.sqlGetCurrencyById?.at?.(0)?.name ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CurrenciesEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CurrenciesEditPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CurrenciesEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.currencyId ?? this.props.screenInputs.currencyid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CurrenciesEdit/CurrenciesEditPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sqlGetCurrencyById = result?.data.sqlGetCurrencyById;
    formVars.currenciesedit_814447_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetCurrencyById?.length > 0 ? stateVars.sqlGetCurrencyById[0]?.isoCode : null
    );
    formVars.currenciesedit_882967_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetCurrencyById?.length > 0 ? stateVars.sqlGetCurrencyById[0]?.name : null
    );
    stateVars.isComp898158Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetCurrencyById?.length > 0 ? stateVars.sqlGetCurrencyById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp457942Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetCurrencyById?.length > 0 ? stateVars.sqlGetCurrencyById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CurrenciesEditPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CurrenciesEditPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp898158Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetCurrencyById?.length > 0 ? this.state.sqlGetCurrencyById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp457942Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetCurrencyById?.length > 0 ? this.state.sqlGetCurrencyById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.currenciesedit_814447_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetCurrencyById?.length > 0 ? this.state.sqlGetCurrencyById[0]?.isoCode : null
    );

    formVars.currenciesedit_882967_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetCurrencyById?.length > 0 ? this.state.sqlGetCurrencyById[0]?.name : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CurrenciesEditComponent_701269_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CurrenciesEditComponent_747582_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "currenciesedit_814447_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "currenciesedit_814447_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "currenciesedit_882967_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "currenciesedit_882967_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      isoCode_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "currenciesedit_814447_value", "value", "", "", "")
        ),
        "string"
      ),
      name_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "currenciesedit_882967_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.currencyId ?? this.props.screenInputs.currencyid,
        "Guid"
      ),
      showInList_1: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CurrenciesEdit/CurrenciesEditComponent_747582_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.currenciesSave = result?.data.currenciesSave;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CurrenciesEditComponent_747582_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CurrenciesEditComponent_747582_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c21638b0_3306_4747_4201_24ea65608992_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
