import { createRoot } from "react-dom/client";
import { AppBootstrapper, IAppMode, IAuthType } from "./bootstrapper";
import { IRouterType } from "./routes/router";
import WebComponentInit from "./web-component-init";

const owner = document.getElementById("cfn-kimya-web-v02");
if (owner) {
  const container = document.getElementById("cfn-kimya-web-v02");

  const root = createRoot(container!);

  const authType: IAuthType = "Kuika";
  const routerType: IRouterType = "BrowserRouter";
  const appMode: IAppMode = "WebSite";

  root.render(
    <AppBootstrapper authType={authType} routerType={routerType} appMode={appMode} />,
    document.getElementById("cfn-kimya-web-v02")
  );

  if ((module as any).hot) {
    (module as any).hot.accept();
  }
} else {
  WebComponentInit();
}

const workspace = "cfnKimya";
const app = "webV02";
const webComponentTag = "cfn-kimya-web-v02";
export { app, webComponentTag, workspace };
