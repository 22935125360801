import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ILeftMenu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ILeftMenu_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  ChangeVisibilityOf: string;
  NAVIGATE: any;
  ToggleVisibilityOf: string;
  Logout: boolean;
  goToSignInActivity: any;
  isComp691316Visible: "visible" | "hidden";
  isComp314034Visible: "visible" | "hidden";
  isComp145943Visible: "visible" | "hidden";
  isComp931917Visible: "visible" | "hidden";
  isCompleftmenu_15992AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_16468AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_256713AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_733135AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_227867AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_906534AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_918599AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_275906AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_152612AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_24171AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_910552AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_470909AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_702082AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_823300AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_453414AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_756702AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_24291AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_239914AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_333127AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_759836AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_172570AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_942224AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_154492AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_519916AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_138101AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_764405AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_432226AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_336938AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_145943AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_443433AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_734294AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_241419AuthorizationVisible: "visible" | "hidden";
  isCompleftmenu_931917AuthorizationVisible: "visible" | "hidden";
}

export class LeftMenu_ScreenBase extends React.PureComponent<ILeftMenu_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 735025, PropertyName: "value", Value: "Uygulama Alanları", TagName: "Label4_value" },
        { Id: 898363, PropertyName: "value", Value: "Sertifika", TagName: "Label1_0_value" },
        { Id: 618255, PropertyName: "value", Value: "Şirket Profili", TagName: "Label1_0_value" },
        { Id: 457947, PropertyName: "value", Value: "Ürün Grubu", TagName: "Label1_0_value" },
        { Id: 231447, PropertyName: "value", Value: "Ürün Listesi", TagName: "Label3_0_value" },
        { Id: 578571, PropertyName: "value", Value: "Haberler", TagName: "Label9_0_value" },
        { Id: 961588, PropertyName: "value", Value: "Versiyon 1.0.0", TagName: "Label11_value" },
        { Id: 621933, PropertyName: "value", Value: "Anket Soruları", TagName: "Label1_0_value" },
        { Id: 674888, PropertyName: "value", Value: "Anasayfa", TagName: "Label0_0_value" },
        { Id: 607152, PropertyName: "value", Value: "Çıkış Yap", TagName: "Label10_value" },
        { Id: 693081, PropertyName: "value", Value: "CFN Kimya Kullanıcıları", TagName: "Label0_0_value" },
        { Id: 465434, PropertyName: "value", Value: "Müşteriler", TagName: "Label0_0_value" },
        { Id: 831743, PropertyName: "value", Value: "Tedarikçiler", TagName: "Label0_0_value" },
        { Id: 219793, PropertyName: "value", Value: "Ana Sayfa", TagName: "DashboardCustomsResponsible_value" },
        { Id: 966551, PropertyName: "value", Value: "Ana Sayfa", TagName: "DashboardEditor_value" },
        { Id: 980942, PropertyName: "value", Value: "Ana Sayfa", TagName: "DashboardLogistic_value" },
        { Id: 375595, PropertyName: "value", Value: "Ana Sayfa", TagName: "DashboardLogisticSupplier_value" },
        { Id: 140666, PropertyName: "value", Value: "Ana Sayfa", TagName: "DashboardQuality_value" },
        { Id: 963550, PropertyName: "value", Value: "Ana Sayfa", TagName: "DashboardSale_value" },
        { Id: 816565, PropertyName: "value", Value: "Araç Talepleri", TagName: "Label0_0_value" },
        { Id: 845168, PropertyName: "value", Value: "Devam Eden Siparişler", TagName: "Label0_0_value" },
        { Id: 326545, PropertyName: "value", Value: "Ürünler", TagName: "Label0_0_value" },
        { Id: 53797, PropertyName: "value", Value: "Mobil Uygulama İçerikleri", TagName: "Label0_0_value" },
        { Id: 754993, PropertyName: "value", Value: "Yeni Siparişler", TagName: "Label0_0_value" },
        { Id: 969553, PropertyName: "value", Value: "Gümrükçüler", TagName: "Label0_0_value" },
        { Id: 755321, PropertyName: "value", Value: "Anketler", TagName: "Label0_0_value" },
        { Id: 535778, PropertyName: "value", Value: "Profil", TagName: "Label0_0_value" },
        { Id: 279284, PropertyName: "value", Value: "Para Birimleri", TagName: "Label0_0_value" },
        { Id: 446084, PropertyName: "value", Value: "Belge Yüklenecekler", TagName: "Label0_0_value" },
        { Id: 794590, PropertyName: "value", Value: "Belge Yüklenenler", TagName: "Label0_0_value" },
        { Id: 309297, PropertyName: "value", Value: "Ana Sayfa", TagName: "Label0_0_value" },
        { Id: 397118, PropertyName: "value", Value: "Ana Sayfa", TagName: "Label0_0_value" },
        { Id: 452284, PropertyName: "value", Value: "Distribütörler", TagName: "Label0_0_value" },
        { Id: 736265, PropertyName: "value", Value: "Brokerlar", TagName: "Label0_0_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "9b7f7e27-d5e3-46db-86ae-bda6274af83a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 674888, PropertyName: "value", Value: "Dashboard" },
        { Id: 309297, PropertyName: "value", Value: "Dashboard" },
        { Id: 219793, PropertyName: "value", Value: "Dashboard" },
        { Id: 966551, PropertyName: "value", Value: "Dashboard" },
        { Id: 980942, PropertyName: "value", Value: "Dashboard" },
        { Id: 375595, PropertyName: "value", Value: "Dashboard" },
        { Id: 140666, PropertyName: "value", Value: "Dashboard" },
        { Id: 963550, PropertyName: "value", Value: "Dashboard" },
        { Id: 397118, PropertyName: "value", Value: "Dashboard" },
        { Id: 754993, PropertyName: "value", Value: "New Orders" },
        { Id: 845168, PropertyName: "value", Value: "Orders in Progress" },
        { Id: 816565, PropertyName: "value", Value: "Vehicle Requests" },
        { Id: 446084, PropertyName: "value", Value: "Document Upload Waiting" },
        { Id: 794590, PropertyName: "value", Value: "Document Upload Completed" },
        { Id: 693081, PropertyName: "value", Value: "CFN Kimya Users" },
        { Id: 465434, PropertyName: "value", Value: "Customers" },
        { Id: 831743, PropertyName: "value", Value: "Suppliers" },
        { Id: 969553, PropertyName: "value", Value: "Customs Agents" },
        { Id: 452284, PropertyName: "value", Value: "Distributors" },
        { Id: 736265, PropertyName: "value", Value: "Brokers" },
        { Id: 279284, PropertyName: "value", Value: "Currencies" },
        { Id: 621933, PropertyName: "value", Value: "Survey Questions" },
        { Id: 755321, PropertyName: "value", Value: "Surveys" },
        { Id: 53797, PropertyName: "value", Value: "Mobile Application Contents" },
        { Id: 326545, PropertyName: "value", Value: "Products" },
        { Id: 457947, PropertyName: "value", Value: "Product Group" },
        { Id: 231447, PropertyName: "value", Value: "Products List" },
        { Id: 735025, PropertyName: "value", Value: "Application Areas" },
        { Id: 898363, PropertyName: "value", Value: "Certificate" },
        { Id: 578571, PropertyName: "value", Value: "News" },
        { Id: 618255, PropertyName: "value", Value: "Company Profile" },
        { Id: 535778, PropertyName: "value", Value: "Profile" },
        { Id: 961588, PropertyName: "value", Value: "Version 1.0.0" },
        { Id: 607152, PropertyName: "value", Value: "Logout" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      ChangeVisibilityOf: "",
      NAVIGATE: "",
      ToggleVisibilityOf: "",
      Logout: false,
      goToSignInActivity: "",
      isComp691316Visible: "visible",
      isComp314034Visible: "hidden",
      isComp145943Visible: "hidden",
      isComp931917Visible: "hidden",
      isCompleftmenu_15992AuthorizationVisible: "visible",
      isCompleftmenu_16468AuthorizationVisible: "visible",
      isCompleftmenu_256713AuthorizationVisible: "visible",
      isCompleftmenu_733135AuthorizationVisible: "visible",
      isCompleftmenu_227867AuthorizationVisible: "visible",
      isCompleftmenu_906534AuthorizationVisible: "visible",
      isCompleftmenu_918599AuthorizationVisible: "visible",
      isCompleftmenu_275906AuthorizationVisible: "visible",
      isCompleftmenu_152612AuthorizationVisible: "visible",
      isCompleftmenu_24171AuthorizationVisible: "visible",
      isCompleftmenu_910552AuthorizationVisible: "visible",
      isCompleftmenu_470909AuthorizationVisible: "visible",
      isCompleftmenu_702082AuthorizationVisible: "visible",
      isCompleftmenu_823300AuthorizationVisible: "visible",
      isCompleftmenu_453414AuthorizationVisible: "visible",
      isCompleftmenu_756702AuthorizationVisible: "visible",
      isCompleftmenu_24291AuthorizationVisible: "visible",
      isCompleftmenu_239914AuthorizationVisible: "visible",
      isCompleftmenu_333127AuthorizationVisible: "visible",
      isCompleftmenu_759836AuthorizationVisible: "visible",
      isCompleftmenu_172570AuthorizationVisible: "visible",
      isCompleftmenu_942224AuthorizationVisible: "visible",
      isCompleftmenu_154492AuthorizationVisible: "visible",
      isCompleftmenu_519916AuthorizationVisible: "visible",
      isCompleftmenu_138101AuthorizationVisible: "visible",
      isCompleftmenu_764405AuthorizationVisible: "visible",
      isCompleftmenu_432226AuthorizationVisible: "visible",
      isCompleftmenu_336938AuthorizationVisible: "visible",
      isCompleftmenu_145943AuthorizationVisible: "visible",
      isCompleftmenu_443433AuthorizationVisible: "visible",
      isCompleftmenu_734294AuthorizationVisible: "visible",
      isCompleftmenu_241419AuthorizationVisible: "visible",
      isCompleftmenu_931917AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "leftmenu");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.LeftMenuPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_15992AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_16468AuthorizationVisible", [
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_256713AuthorizationVisible", [
      "e6c1fd52-eece-7ba2-b39e-d53dabf91a54",
      "CustomsAgent"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_733135AuthorizationVisible", [
      "68869d11-d6f3-1804-65bd-383ff48dffb8",
      "Editor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_227867AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_906534AuthorizationVisible", [
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_918599AuthorizationVisible", [
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "Quality"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_275906AuthorizationVisible", [
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "Sale"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_152612AuthorizationVisible", [
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_24171AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_910552AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_470909AuthorizationVisible", [
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_702082AuthorizationVisible", [
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_823300AuthorizationVisible", [
      "e6c1fd52-eece-7ba2-b39e-d53dabf91a54",
      "CustomsAgent"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_453414AuthorizationVisible", [
      "e6c1fd52-eece-7ba2-b39e-d53dabf91a54",
      "CustomsAgent"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_756702AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "Administrator",
      "Sale",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_24291AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_239914AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_333127AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_759836AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "Administrator",
      "Sale",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_172570AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "Administrator",
      "Sale",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_942224AuthorizationVisible", [
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Sale",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_154492AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "Administrator",
      "Sale",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_519916AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "68869d11-d6f3-1804-65bd-383ff48dffb8",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic",
      "Editor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_138101AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "68869d11-d6f3-1804-65bd-383ff48dffb8",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic",
      "Editor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_764405AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "68869d11-d6f3-1804-65bd-383ff48dffb8",
      "Administrator",
      "Editor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_432226AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "68869d11-d6f3-1804-65bd-383ff48dffb8",
      "Administrator",
      "Editor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_336938AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "68869d11-d6f3-1804-65bd-383ff48dffb8",
      "Administrator",
      "Editor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_145943AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "68869d11-d6f3-1804-65bd-383ff48dffb8",
      "Administrator",
      "Editor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_443433AuthorizationVisible", []);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_734294AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "68869d11-d6f3-1804-65bd-383ff48dffb8",
      "Administrator",
      "Editor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_241419AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "68869d11-d6f3-1804-65bd-383ff48dffb8",
      "Administrator",
      "Editor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompleftmenu_931917AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "68869d11-d6f3-1804-65bd-383ff48dffb8",
      "Administrator",
      "Editor"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "leftmenu");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "leftmenu");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.LeftMenuPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  LeftMenuPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp691316Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp314034Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  LeftMenuComponent_84674_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_15992_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DashboardAdmin",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_16468_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DashboardDistributor",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_256713_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DashboardCustomsAgent",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_733135_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DashboardEditor",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_227867_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DashboardLogistic",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_906534_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "VehicleRequestedOrdersList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_918599_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DashboardQuality",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_275906_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DashboardSale",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_152612_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DashboardFinance",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_24171_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "OrderSearch",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_910552_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_702082_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "VehicleRequestedOrdersList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_823300_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DocumentWaitingUploadListCA",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_453414_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DocumentUploadedListCustomsAgent",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_24291_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UserList", "userType", 1);
    KuikaAppManager.addToPageInputVariables("UserList", "headerText", "CFN");
    KuikaAppManager.addToPageInputVariables("UserList", "role", "");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "UserList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_239914_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "Customers",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_333127_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "Suppliers",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_759836_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "CustomsAgents",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_172570_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "Distributors",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_942224_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "BrokerList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_154492_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "CurrenciesList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_519916_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "surveyQuestions",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_138101_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SurveyParticipants",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_336938_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp145943Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp691316Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp314034Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_870176_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("ProductGroup", "catalogueId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ProductGroup",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_593704_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ProductsList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_624023_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "ApplicationAreas",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_579202_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "CertificateList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_734294_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "HaberList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_241419_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CompanyProfileEdit", "Id", "797EA3AE-EC81-6963-5C2B-0E312BCD0D66");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "CompanyProfileEdit",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_931917_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "Profile",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_988826_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_942656_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_607152_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_547928_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
