import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAssignVehicle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAssignVehicle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  sqlGetLogisticSuppliersSB: any[];
  GetOrderList: any[];
  sqlGetVehicleRequestById: any[];
  SqlGetCustomerDetailsByCardNo: any[];
  sqlGetCompanyById: any[];
  GoBack: any;
  TriggerEvent: string;
  ChangeVisibilityOf: string;
  GetUserCurrentId: any[];
  updateVehicleAssignmentData: number;
  NAVIGATE: any;
  UploadFile: any;
  uploadInvoiceContent: any[];
  sqlAssignVehicles: number;
  Notify: boolean;
  getUsersEmailByRoleAndCompanyAGG: any[];
  SendMail: boolean;
  isComp866109Visible: "visible" | "hidden";
  isComp619512Visible: "visible" | "hidden";
  isComp812699Visible: "visible" | "hidden";
  isComp960726Visible: "visible" | "hidden";
  isComp973316Visible: "visible" | "hidden";
  isComp430740Visible: "visible" | "hidden";
  isComp476567Visible: "visible" | "hidden";
  isComp42134Visible: "visible" | "hidden";
  isComp300692Visible: "visible" | "hidden";
  isComp947900Visible: "visible" | "hidden";
  isComp437226Visible: "visible" | "hidden";
  isComp515901Visible: "visible" | "hidden";
  isComp368078Visible: "visible" | "hidden";
  isComp42131Visible: "visible" | "hidden";
  isCompassignvehicle_86876AuthorizationVisible: "visible" | "hidden";
  isCompassignvehicle_42134AuthorizationVisible: "visible" | "hidden";
  isCompassignvehicle_804283AuthorizationVisible: "visible" | "hidden";
  isCompassignvehicle_183318AuthorizationVisible: "visible" | "hidden";
  isCompassignvehicle_42131AuthorizationVisible: "visible" | "hidden";
  isCompassignvehicle_960726AuthorizationVisible: "visible" | "hidden";
}

export class AssignVehicle_ScreenBase extends React.PureComponent<IAssignVehicle_ScreenProps, any> {
  assignvehicle_726243_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 42131, PropertyName: "label", Value: "Araç Ata", TagName: "Button1_label" },
        { Id: 521089, PropertyName: "value", Value: "Araç Atama Bilgileri", TagName: "Label10_value" },
        { Id: 716243, PropertyName: "value", Value: "Müşteri ", TagName: "Label0_0_value" },
        { Id: 236000, PropertyName: "value", Value: "Teslimat Adresi", TagName: "Label0_0_value" },
        { Id: 130612, PropertyName: "value", Value: "Yükleme Adresi", TagName: "Label0_0_value" },
        { Id: 323324, PropertyName: "value", Value: "Yükleme Tarihi", TagName: "Label0_0_value" },
        { Id: 666714, PropertyName: "value", Value: "Fatura Numarası", TagName: "Label0_0_value" },
        { Id: 723458, PropertyName: "value", Value: "Çekici Plakası ", TagName: "Label0_0_value" },
        { Id: 330337, PropertyName: "value", Value: "Dorse Plakası / Konteyner Numarası", TagName: "Label0_0_value" },
        { Id: 86560, PropertyName: "value", Value: "Adı", TagName: "Label0_0_value" },
        { Id: 134979, PropertyName: "value", Value: "Soyadı", TagName: "Label0_0_value" },
        { Id: 520848, PropertyName: "value", Value: "Alan Kodu", TagName: "Label0_0_value" },
        { Id: 145641, PropertyName: "value", Value: "Telefon", TagName: "Label0_0_value" },
        { Id: 557055, PropertyName: "value", Value: "Müşteri Referans No", TagName: "Label0_0_value" },
        { Id: 540638, PropertyName: "value", Value: "Sipariş No", TagName: "Label0_0_value" },
        { Id: 84510, PropertyName: "value", Value: "Sipariş Tarihi", TagName: "Label0_0_value" },
        { Id: 596312, PropertyName: "value", Value: "Navlun", TagName: "Label1_value" },
        { Id: 210116, PropertyName: "value", Value: "Tahmini Teslimat Tarihi (ETA)", TagName: "Label0_0_value" },
        { Id: 894397, PropertyName: "value", Value: "Çekici Plakası ", TagName: "Label0_0_value" },
        { Id: 502158, PropertyName: "value", Value: "Dorse Plakası / Konteyner Numarası", TagName: "Label0_0_value" },
        { Id: 188066, PropertyName: "value", Value: "Sürücü Bilgileri", TagName: "Label0_0_value" },
        { Id: 805517, PropertyName: "value", Value: "İsim", TagName: "Label0_0_value" },
        { Id: 732071, PropertyName: "value", Value: "Soyisim", TagName: "Label0_0_value" },
        { Id: 181846, PropertyName: "value", Value: "Alan Kodu", TagName: "Label0_0_value" },
        { Id: 968394, PropertyName: "value", Value: "Telefon", TagName: "Label0_0_value" },
        { Id: 743282, PropertyName: "value", Value: "Sürücü Bilgileri", TagName: "Label0_0_value" },
        { Id: 726243, PropertyName: "placeholder", Value: "Tarih Seçiniz...", TagName: "Date0_1_placeholder" },
        { Id: 42134, PropertyName: "label", Value: "Kaydet", TagName: "Button2_label" },
        { Id: 758064, PropertyName: "value", Value: "Sürücü Bilgileri", TagName: "Label0_0_value" },
        { Id: 841376, PropertyName: "value", Value: "Fatura Numarası", TagName: "Label0_0_value" },
        { Id: 869220, PropertyName: "value", Value: "Tahmini Teslimat Tarihi", TagName: "Label0_0_value" },
        { Id: 613201, PropertyName: "value", Value: "Dorse Plakası / Konteyner Numarası", TagName: "Label0_0_value" },
        { Id: 856687, PropertyName: "value", Value: "Fatura", TagName: "Label0_0_value" },
        { Id: 437226, PropertyName: "value", Value: "Fatura Henüz Yüklenmedi!", TagName: "Label0_1_value" },
        { Id: 515901, PropertyName: "value", Value: "Faturayı Görüntüle", TagName: "Label0_2_value" },
        { Id: 183318, PropertyName: "label", Value: "Faturayı Yükle", TagName: "Button0_0_label" },
        { Id: 960726, PropertyName: "label", Value: "Kaydet", TagName: "btnSaveAssignInfo_label" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "c7e58509-8567-45c8-bf6e-c570452bd360",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 430740, PropertyName: "label", Value: "pageinittrigger" },
        { Id: 716243, PropertyName: "value", Value: "Customer" },
        { Id: 557055, PropertyName: "value", Value: "Customer Ref No" },
        { Id: 540638, PropertyName: "value", Value: "Order Number" },
        { Id: 84510, PropertyName: "value", Value: "Order Date" },
        { Id: 236000, PropertyName: "value", Value: "Shipping Address" },
        { Id: 130612, PropertyName: "value", Value: "Loading Address" },
        { Id: 323324, PropertyName: "value", Value: "Loading Date" },
        { Id: 596312, PropertyName: "value", Value: "Freight" },
        { Id: 521089, PropertyName: "value", Value: "Assignment Information" },
        { Id: 210116, PropertyName: "value", Value: "Estimated Delivery Date (ETA)" },
        { Id: 726243, PropertyName: "placeholder", Value: "Select Date..." },
        { Id: 666714, PropertyName: "value", Value: "Invoice Number" },
        { Id: 42134, PropertyName: "label", Value: "Save" },
        { Id: 856687, PropertyName: "value", Value: "Invoice" },
        { Id: 437226, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 515901, PropertyName: "value", Value: "View document" },
        { Id: 183318, PropertyName: "label", Value: "Upload" },
        { Id: 743282, PropertyName: "value", Value: "Truck/Container Information" },
        { Id: 723458, PropertyName: "value", Value: "Truck Plate Number" },
        { Id: 330337, PropertyName: "value", Value: "Trailer Plate Number / Container Number" },
        { Id: 758064, PropertyName: "value", Value: "Driver Information" },
        { Id: 86560, PropertyName: "value", Value: "Name" },
        { Id: 134979, PropertyName: "value", Value: "Surname" },
        { Id: 520848, PropertyName: "value", Value: "Prefix" },
        { Id: 974124, PropertyName: "placeholder", Value: "+90" },
        { Id: 145641, PropertyName: "value", Value: "Phone" },
        { Id: 841376, PropertyName: "value", Value: "Invoice Number" },
        { Id: 869220, PropertyName: "value", Value: "Estimated Delivery Date" },
        { Id: 613201, PropertyName: "value", Value: "Truck/Container Information" },
        { Id: 894397, PropertyName: "value", Value: "Truck Plate Number" },
        { Id: 502158, PropertyName: "value", Value: "Trailer Plate Number / Container Number" },
        { Id: 188066, PropertyName: "value", Value: "Driver Information" },
        { Id: 805517, PropertyName: "value", Value: "Name" },
        { Id: 732071, PropertyName: "value", Value: "Surname" },
        { Id: 181846, PropertyName: "value", Value: "Prefix" },
        { Id: 968394, PropertyName: "value", Value: "Phone" },
        { Id: 42131, PropertyName: "label", Value: "Assign Vehicle" },
        { Id: 960726, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.assignvehicle_726243_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      sqlGetLogisticSuppliersSB: [],
      GetOrderList: [],
      sqlGetVehicleRequestById: [],
      SqlGetCustomerDetailsByCardNo: [],
      sqlGetCompanyById: [],
      GoBack: "",
      TriggerEvent: "",
      ChangeVisibilityOf: "",
      GetUserCurrentId: [],
      updateVehicleAssignmentData: "",
      NAVIGATE: "",
      UploadFile: "",
      uploadInvoiceContent: [],
      sqlAssignVehicles: 0,
      Notify: false,
      getUsersEmailByRoleAndCompanyAGG: [],
      SendMail: false,
      isComp866109Visible: "hidden",
      isComp619512Visible: "hidden",
      isComp812699Visible: "hidden",
      isComp960726Visible: "hidden",
      isComp973316Visible: "hidden",
      isComp430740Visible: "hidden",
      isComp476567Visible: "hidden",
      isComp42134Visible: "hidden",
      isComp300692Visible: "hidden",
      isComp947900Visible: "hidden",
      isComp437226Visible: "hidden",
      isComp515901Visible: "hidden",
      isComp368078Visible: "hidden",
      isComp42131Visible: "hidden",
      isCompassignvehicle_86876AuthorizationVisible: "visible",
      isCompassignvehicle_42134AuthorizationVisible: "visible",
      isCompassignvehicle_804283AuthorizationVisible: "visible",
      isCompassignvehicle_183318AuthorizationVisible: "visible",
      isCompassignvehicle_42131AuthorizationVisible: "visible",
      isCompassignvehicle_960726AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("assignvehicle", "assignvehicle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.AssignVehiclePageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignvehicle_86876AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "Logistic",
      "LogisticSupplier",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignvehicle_42134AuthorizationVisible", [
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignvehicle_804283AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Logistic",
      "LogisticSupplier",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignvehicle_183318AuthorizationVisible", [
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignvehicle_42131AuthorizationVisible", [
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignvehicle_960726AuthorizationVisible", [
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "LogisticSupplier"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("assignvehicle", "assignvehicle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("assignvehicle", "assignvehicle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AssignVehiclePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      assignvehicle_726243_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.estimatedDeliveryDate ?? undefined,
      assignvehicle_171861_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.invoiceNumber ?? undefined,
      assignvehicle_999928_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.truckPlateNumber ?? undefined,
      assignvehicle_230440_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.trailerPlateNumber ?? undefined,
      assignvehicle_318093_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.driverName ?? undefined,
      assignvehicle_766936_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.driverSurname ?? undefined,
      assignvehicle_974124_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.driverPhonePrefix ?? undefined,
      assignvehicle_894109_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.driverPhoneNumber ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  AssignVehiclePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AssignVehicleComponent_430740_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignVehiclePageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignVehiclePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp866109Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehiclePageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehiclePageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehiclePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp619512Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehiclePageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehiclePageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehiclePageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp812699Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehiclePageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehiclePageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehiclePageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp42134Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp300692Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp947900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp437226Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp515901Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973316Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp42131Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AssignVehicleComponent_430740_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrderNumber_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardCode ?? this.props.screenInputs.cardcode,
        "string"
      ),
      CustomerCardName_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentNo_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardCode ?? this.props.screenInputs.cardcode,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignVehicle/AssignVehicleComponent_430740_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sqlGetLogisticSuppliersSB = result?.data.sqlGetLogisticSuppliersSB;
    stateVars.GetOrderList = result?.data.getOrderList;
    formVars.assignvehicle_9756_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardname : null
    );
    formVars.assignvehicle_788161_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.custrefno : null
    );
    formVars.assignvehicle_85377_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    formVars.assignvehicle_625727_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.shiptoaddressmultiline : null
    );
    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.assignvehicle_56735_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );
    formVars.assignvehicle_207811_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
    );
    formVars.assignvehicle_34974_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
    );
    formVars.assignvehicle_198738_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
    );
    formVars.assignvehicle_726243_value = ReactSystemFunctions.value(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );
    formVars.assignvehicle_171861_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );
    formVars.assignvehicle_999928_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );
    formVars.assignvehicle_230440_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber : null
    );
    formVars.assignvehicle_318093_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverName : null
    );
    formVars.assignvehicle_766936_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverSurname : null
    );
    formVars.assignvehicle_974124_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );
    formVars.assignvehicle_894109_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );
    formVars.assignvehicle_505876_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );
    formVars.assignvehicle_692177_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );
    formVars.assignvehicle_514067_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );
    formVars.assignvehicle_973660_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber : null
    );
    formVars.assignvehicle_120957_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverName : null
    );
    formVars.assignvehicle_559217_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverSurname : null
    );
    formVars.assignvehicle_970813_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );
    formVars.assignvehicle_382442_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );
    stateVars.isComp42134Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp300692Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp947900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp437226Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp515901Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973316Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp42131Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SqlGetCustomerDetailsByCardNo = result?.data.sqlGetCustomerDetailsByCardNo;
    stateVars.sqlGetCompanyById = result?.data.sqlGetCompanyById;
    formVars.assignvehicle_633588_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetCompanyById?.length > 0 ? stateVars.sqlGetCompanyById[0]?.cardName : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignVehicleComponent_430740_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignVehicleComponent_430740_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.assignvehicle_9756_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
    );

    formVars.assignvehicle_788161_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
    );

    formVars.assignvehicle_85377_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    formVars.assignvehicle_625727_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.shiptoaddressmultiline : null
    );

    formVars.assignvehicle_56735_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );

    formVars.assignvehicle_207811_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
    );

    formVars.assignvehicle_34974_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
    );

    formVars.assignvehicle_198738_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
    );

    formVars.assignvehicle_633588_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetCompanyById?.length > 0 ? this.state.sqlGetCompanyById[0]?.cardName : null
    );

    formVars.assignvehicle_726243_value = ReactSystemFunctions.value(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );

    formVars.assignvehicle_171861_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );

    formVars.assignvehicle_999928_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );

    formVars.assignvehicle_230440_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
        : null
    );

    formVars.assignvehicle_318093_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverName : null
    );

    formVars.assignvehicle_766936_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverSurname : null
    );

    formVars.assignvehicle_974124_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );

    formVars.assignvehicle_894109_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );

    formVars.assignvehicle_505876_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );

    formVars.assignvehicle_692177_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );

    formVars.assignvehicle_514067_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );

    formVars.assignvehicle_973660_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
        : null
    );

    formVars.assignvehicle_120957_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverName : null
    );

    formVars.assignvehicle_559217_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverSurname : null
    );

    formVars.assignvehicle_970813_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );

    formVars.assignvehicle_382442_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignVehicleComponent_621706_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignVehicleComponent_42134_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_171861_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "assignvehicle_171861_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      invoiceNumber_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_171861_value", "value", "", "", "")
        ),
        "string"
      ),
      estimatedDeliveryDate_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_726243_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AssignVehicle/AssignVehicleComponent_42134_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.assignvehicle_56735_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );
    formVars.assignvehicle_207811_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
    );
    formVars.assignvehicle_34974_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
    );
    formVars.assignvehicle_198738_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
    );
    formVars.assignvehicle_726243_value = ReactSystemFunctions.value(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );
    formVars.assignvehicle_171861_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );
    formVars.assignvehicle_999928_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );
    formVars.assignvehicle_230440_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber : null
    );
    formVars.assignvehicle_318093_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverName : null
    );
    formVars.assignvehicle_766936_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverSurname : null
    );
    formVars.assignvehicle_974124_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );
    formVars.assignvehicle_894109_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );
    formVars.assignvehicle_505876_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );
    formVars.assignvehicle_692177_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );
    formVars.assignvehicle_514067_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );
    formVars.assignvehicle_973660_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber : null
    );
    formVars.assignvehicle_120957_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverName : null
    );
    formVars.assignvehicle_559217_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverSurname : null
    );
    formVars.assignvehicle_970813_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );
    formVars.assignvehicle_382442_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );
    stateVars.isComp42134Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp300692Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp947900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp437226Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp515901Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973316Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp42131Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.updateVehicleAssignmentData = result?.data.updateVehicleAssignmentData;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignVehicleComponent_42134_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignVehicleComponent_42134_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);
    formVars.assignvehicle_56735_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );

    formVars.assignvehicle_207811_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
    );

    formVars.assignvehicle_34974_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
    );

    formVars.assignvehicle_198738_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
    );

    formVars.assignvehicle_726243_value = ReactSystemFunctions.value(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );

    formVars.assignvehicle_171861_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );

    formVars.assignvehicle_999928_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );

    formVars.assignvehicle_230440_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
        : null
    );

    formVars.assignvehicle_318093_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverName : null
    );

    formVars.assignvehicle_766936_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverSurname : null
    );

    formVars.assignvehicle_974124_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );

    formVars.assignvehicle_894109_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );

    formVars.assignvehicle_505876_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );

    formVars.assignvehicle_692177_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );

    formVars.assignvehicle_514067_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );

    formVars.assignvehicle_973660_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
        : null
    );

    formVars.assignvehicle_120957_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverName : null
    );

    formVars.assignvehicle_559217_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverSurname : null
    );

    formVars.assignvehicle_970813_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
    );

    formVars.assignvehicle_382442_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignVehicleComponent_515901_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewInvoice",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignVehicle",
      "PreviewInvoice",
      "",
      "newTab",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignVehicleComponent_183318_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignVehicleComponent_183318_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      invoiceContent_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignVehicle/AssignVehicleComponent_183318_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.uploadInvoiceContent = result?.data.uploadInvoiceContent;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignVehicle/AssignVehicleComponent_183318_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
      formVars.assignvehicle_56735_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
      );
      formVars.assignvehicle_207811_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
      );
      formVars.assignvehicle_34974_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
      );
      formVars.assignvehicle_198738_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
      );
      formVars.assignvehicle_726243_value = ReactSystemFunctions.value(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0
          ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
          : null
      );
      formVars.assignvehicle_171861_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
      );
      formVars.assignvehicle_999928_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
      );
      formVars.assignvehicle_230440_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0
          ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber
          : null
      );
      formVars.assignvehicle_318093_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverName : null
      );
      formVars.assignvehicle_766936_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverSurname : null
      );
      formVars.assignvehicle_974124_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
      );
      formVars.assignvehicle_894109_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
      );
      formVars.assignvehicle_505876_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
      );
      formVars.assignvehicle_692177_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0
          ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
          : null
      );
      formVars.assignvehicle_514067_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
      );
      formVars.assignvehicle_973660_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0
          ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber
          : null
      );
      formVars.assignvehicle_120957_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverName : null
      );
      formVars.assignvehicle_559217_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverSurname : null
      );
      formVars.assignvehicle_970813_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhonePrefix : null
      );
      formVars.assignvehicle_382442_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverPhoneNumber : null
      );
      stateVars.isComp42134Visible =
        ReactSystemFunctions.isLessThan(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "60"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp300692Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0
              ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
              : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp947900Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0
              ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp437226Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0
              ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
              : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp515901Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0
              ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp973316Visible =
        ReactSystemFunctions.isLessThan(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "40"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp42131Visible =
        ReactSystemFunctions.isLessOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "10"
        ) === true
          ? "visible"
          : "hidden";
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_56735_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_207811_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_34974_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_198738_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_726243_value = ReactSystemFunctions.value(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_171861_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_999928_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_230440_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_318093_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverName : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_766936_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverSurname : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_974124_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.driverPhonePrefix
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_894109_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.driverPhoneNumber
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_505876_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_692177_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_514067_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_973660_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick19_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick19_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_120957_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverName : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick20_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick20_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_559217_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverSurname : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick21_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick21_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_970813_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.driverPhonePrefix
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_183318_onClick22_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignVehicleComponent_183318_onClick22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      formVars.assignvehicle_382442_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.driverPhoneNumber
          : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  AssignVehicleComponent_973316_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp866109Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp812699Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp960726Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp973316Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignVehicleComponent_42131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_974124_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "assignvehicle_974124_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_318093_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "assignvehicle_318093_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_894109_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "assignvehicle_894109_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_766936_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "assignvehicle_766936_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_999928_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "assignvehicle_999928_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      driverPhonePrefix_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_974124_value", "value", "", "", "")
        ),
        "string"
      ),
      assignedAt_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      driverName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_318093_value", "value", "", "", "")
        ),
        "string"
      ),
      driverPhoneNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_894109_value", "value", "", "", "")
        ),
        "string"
      ),
      driverSurname_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_766936_value", "value", "", "", "")
        ),
        "string"
      ),
      status_1: ReactSystemFunctions.convertToTypeByName(20, "number"),
      trailerPlateNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_230440_value", "value", "", "", "")
        ),
        "string"
      ),
      truckPlateNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_999928_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AssignVehicle/AssignVehicleComponent_42131_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.sqlAssignVehicles = result?.data.sqlAssignVehicles;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignVehicleComponent_42131_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignVehicleComponent_42131_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c684a352_9dd8_2124_1db2_fb1b5f55261d_notify",
        this.defaultML,
        "Girilen bilgiler kaydedilip onaya gönderildi."
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignVehicleComponent_42131_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignVehicleComponent_42131_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      RoleId_0: ReactSystemFunctions.convertToTypeByName("67A9EE96-BF0B-29E0-2DFF-A17F004E4660", "Guid"),
      companyID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      RoleName_0: ReactSystemFunctions.convertToTypeByName("Logistic", "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("f6b8fdeb-0439-4b32-b089-f64885316b5c", "string"),
      subject_1: ReactSystemFunctions.convertToTypeByName("Araç talebiniz için araç ataması yapıldı.", "string"),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      orderNo_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      mainText_1: ReactSystemFunctions.convertToTypeByName(
        "Numaralı siparişe bağlı araç talebinize tedarikçi firma tarafından araç atandı. Onayınızı beklemekte.",
        "string"
      ),
      mailHeader_1: ReactSystemFunctions.convertToTypeByName("Yeni araç ataması", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignVehicle/AssignVehicleComponent_42131_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUsersEmailByRoleAndCompanyAGG = result?.data.getUsersEmailByRoleAndCompanyAGG;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignVehicleComponent_42131_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignVehicleComponent_42131_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignVehicleComponent_960726_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignVehicle/AssignVehicleComponent_960726_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignVehicleComponent_960726_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignVehicleComponent_960726_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_974124_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "assignvehicle_974124_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_318093_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "assignvehicle_318093_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_894109_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "assignvehicle_894109_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_766936_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "assignvehicle_766936_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_999928_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "assignvehicle_999928_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      driverPhonePrefix_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_974124_value", "value", "", "", "")
        ),
        "string"
      ),
      assignedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      assignedBy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "Guid"
      ),
      driverName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_318093_value", "value", "", "", "")
        ),
        "string"
      ),
      driverPhoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_894109_value", "value", "", "", "")
        ),
        "string"
      ),
      driverSurname_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_766936_value", "value", "", "", "")
        ),
        "string"
      ),
      status_0: ReactSystemFunctions.convertToTypeByName(20, "number"),
      trailerPlateNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_230440_value", "value", "", "", "")
        ),
        "string"
      ),
      truckPlateNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignvehicle_999928_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      )
    };

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1f71782a_2876_12cd_b21b_03bab6e3f255_confirmation",
        this.defaultML,
        "Değişiklikler kayıt edildikten sonra tekrar onaya gönderilecek. Devam etmek istiyor musunuz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignVehicle/AssignVehicleComponent_960726_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.sqlAssignVehicles = result?.data.sqlAssignVehicles;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignVehicleComponent_960726_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignVehicleComponent_960726_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "6aea25d4_476f_1220_c71b_f4f43f21f6cd_notify",
        this.defaultML,
        "Değişiklikler güncellenip tekrar onaya gönderildi."
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignVehicleComponent_960726_onClick3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignVehicleComponent_960726_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      RoleId_0: ReactSystemFunctions.convertToTypeByName("67A9EE96-BF0B-29E0-2DFF-A17F004E4660", "Guid"),
      companyID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      RoleName_0: ReactSystemFunctions.convertToTypeByName("Logistic", "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("f6b8fdeb-0439-4b32-b089-f64885316b5c", "string"),
      subject_1: ReactSystemFunctions.convertToTypeByName("Araç atama bilgileri güncellendi", "string"),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      orderNo_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      mainText_1: ReactSystemFunctions.convertToTypeByName(
        "Numaralı siparişe atanan araç bilgilerinde değişiklik yapıldı. Lütfen uygulamadan gerekli kontrolleri yapınız.",
        "string"
      ),
      mailHeader_1: ReactSystemFunctions.convertToTypeByName("Atanan araç bilgilerinde değişiklik", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignVehicle/AssignVehicleComponent_960726_onClick3_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUsersEmailByRoleAndCompanyAGG = result?.data.getUsersEmailByRoleAndCompanyAGG;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignVehicleComponent_960726_onClick4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignVehicleComponent_960726_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
