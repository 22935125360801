import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashboardCustomsAgent_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashboardCustomsAgent_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  SetLanguage: string;
  GetLanguage: string;
  Logout: boolean;
  goToSignInActivity: any;
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  spGetCurrentCompanyId: any[];
  getCountOfUnCompletedJobsOfCustomsAgent: any[];
  getUnCompletedJobsOfCustomsAgent: any[];
  getCompletedJobsOfCustomsAgent: any[];
  isComp666662Visible: "visible" | "hidden";
  isComp597975Visible: "visible" | "hidden";
  isComp595872Visible: "visible" | "hidden";
  isComp355968Visible: "visible" | "hidden";
  isComp237173Visible: "visible" | "hidden";
  isComp807602Visible: "visible" | "hidden";
  isComp782825Visible: "visible" | "hidden";
  isComp912810Visible: "visible" | "hidden";
}

export class DashboardCustomsAgent_ScreenBase extends React.PureComponent<IDashboardCustomsAgent_ScreenProps, any> {
  dashboardcustomsagent_646524_value_kuikaTableRef: React.RefObject<any>;
  dashboardcustomsagent_724291_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 513345, PropertyName: "value", Value: "Bu Ay", TagName: "Label7_value" },
        { Id: 360915, PropertyName: "value", Value: "Belge Yüklenmesi Bekleniyor", TagName: "Label2_1_value" },
        { Id: 901026, PropertyName: "value", Value: "Tamamlandı", TagName: "Label2_1_value" },
        { Id: 629988, PropertyName: "value", Value: "Belge Yüklenmesi Bekleniyor", TagName: "Label2_0_value" },
        {
          Id: 646524,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 189067, PropertyName: "title", Value: "Sipariş Numarası", TagName: "TableColumn0_0_title" },
        { Id: 974895, PropertyName: "title", Value: "Sipariş Tarihi", TagName: "TableColumn0_2_title" },
        { Id: 836338, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 171447, PropertyName: "label", Value: "Çıkış Yap", TagName: "ContentMenuItem2_2_label" },
        { Id: 60000, PropertyName: "value", Value: "Toplam", TagName: "Label7_1_value" },
        { Id: 442678, PropertyName: "title", Value: "Müşteri", TagName: "TableColumn9_title" },
        { Id: 65179, PropertyName: "title", Value: "Yükleme Tarihi", TagName: "TableColumn8_title" },
        { Id: 681268, PropertyName: "title", Value: "Belge Sayısı", TagName: "TableColumn7_title" },
        { Id: 428361, PropertyName: "value", Value: "Tamamlanan", TagName: "Label7_0_value" },
        {
          Id: 724291,
          PropertyName: "nodatafoundmessage",
          Value: "Veri Bulunamadı",
          TagName: "Table2_nodatafoundmessage"
        },
        { Id: 506253, PropertyName: "title", Value: "Müşteri", TagName: "TableColumn0_0_title" },
        { Id: 659920, PropertyName: "title", Value: "Sipariş No", TagName: "TableColumn0_1_title" },
        { Id: 672918, PropertyName: "title", Value: "Sipariş Tarihi", TagName: "TableColumn0_2_title" },
        { Id: 524490, PropertyName: "title", Value: "Yükleme Tarihi", TagName: "TableColumn0_3_title" },
        { Id: 552791, PropertyName: "title", Value: "Belge Sayısı", TagName: "TableColumn0_4_title" },
        { Id: 3236, PropertyName: "value", Value: "Hepsi", TagName: "Label7_1_value" },
        { Id: 981961, PropertyName: "value", Value: "Hepsi", TagName: "Label7_1_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "d9f75a2c-8fe0-4c99-a47a-2b371f6961e0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 666662, PropertyName: "value", Value: "TR" },
        { Id: 597975, PropertyName: "value", Value: "TR" },
        { Id: 953892, PropertyName: "value", Value: "|" },
        { Id: 595872, PropertyName: "value", Value: "EN" },
        { Id: 355968, PropertyName: "value", Value: "EN" },
        { Id: 836338, PropertyName: "label", Value: "Profile" },
        { Id: 171447, PropertyName: "label", Value: "Logout" },
        { Id: 513345, PropertyName: "value", Value: "This month" },
        { Id: 360915, PropertyName: "value", Value: "Document Upload Waiting" },
        { Id: 901026, PropertyName: "value", Value: "Completed" },
        { Id: 60000, PropertyName: "value", Value: "Total" },
        { Id: 629988, PropertyName: "value", Value: "Document Upload Waiting" },
        { Id: 446025, PropertyName: "value", Value: "(" },
        { Id: 810043, PropertyName: "value", Value: ")" },
        { Id: 3236, PropertyName: "value", Value: "All" },
        { Id: 646524, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 442678, PropertyName: "title", Value: "Customer" },
        { Id: 398184, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 189067, PropertyName: "title", Value: "Order Number" },
        { Id: 860982, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 974895, PropertyName: "title", Value: "Order Date" },
        { Id: 544945, PropertyName: "value", Value: "[datafield:orderdate]" },
        { Id: 65179, PropertyName: "title", Value: "Loading Date" },
        { Id: 913968, PropertyName: "value", Value: "[datafield:loadingdate]" },
        { Id: 681268, PropertyName: "title", Value: "Uploaded Doc Count" },
        { Id: 707124, PropertyName: "value", Value: "[datafield:doccount]" },
        { Id: 428361, PropertyName: "value", Value: "Completed" },
        { Id: 231902, PropertyName: "value", Value: "(" },
        { Id: 17374, PropertyName: "value", Value: ")" },
        { Id: 981961, PropertyName: "value", Value: "All" },
        { Id: 724291, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 506253, PropertyName: "title", Value: "Customer" },
        { Id: 942484, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 659920, PropertyName: "title", Value: "Order Number" },
        { Id: 113146, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 672918, PropertyName: "title", Value: "Order Date" },
        { Id: 266249, PropertyName: "value", Value: "[datafield:orderdate]" },
        { Id: 524490, PropertyName: "title", Value: "Loading Date" },
        { Id: 522638, PropertyName: "value", Value: "[datafield:loadingdate]" },
        { Id: 552791, PropertyName: "title", Value: "Uploaded Doc Count" },
        { Id: 955829, PropertyName: "value", Value: "[datafield:doccount]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardcustomsagent_646524_value_kuikaTableRef = React.createRef();
    this.dashboardcustomsagent_724291_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      SetLanguage: "",
      GetLanguage: "",
      Logout: false,
      goToSignInActivity: "",
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      spGetCurrentCompanyId: [],
      getCountOfUnCompletedJobsOfCustomsAgent: [],
      getUnCompletedJobsOfCustomsAgent: [],
      getCompletedJobsOfCustomsAgent: [],
      isComp666662Visible: "hidden",
      isComp597975Visible: "hidden",
      isComp595872Visible: "hidden",
      isComp355968Visible: "hidden",
      isComp237173Visible: "hidden",
      isComp807602Visible: "hidden",
      isComp782825Visible: "hidden",
      isComp912810Visible: "hidden"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardcustomsagent", "dashboardcustomsagent");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashboardCustomsAgentPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardcustomsagent", "dashboardcustomsagent");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["CustomsAgent"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardcustomsagent", "dashboardcustomsagent");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashboardCustomsAgentPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboardcustomsagent_807602_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DashboardCustomsAgentPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardCustomsAgentPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashboardCustomsAgentPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      minDocNumber_4: ReactSystemFunctions.convertToTypeByName(2, "number"),
      minDocNumber_5: ReactSystemFunctions.convertToTypeByName(2, "number"),
      minDocNumber_6: ReactSystemFunctions.convertToTypeByName(2, "number")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardCustomsAgent/DashboardCustomsAgentPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.dashboardcustomsagent_912810_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.dashboardcustomsagent_807602_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp237173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp807602Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782825Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp912810Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spGetCurrentCompanyId = result?.data.spGetCurrentCompanyId;
    stateVars.getCountOfUnCompletedJobsOfCustomsAgent = result?.data.getCountOfUnCompletedJobsOfCustomsAgent;
    formVars.dashboardcustomsagent_66171_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? stateVars.getCountOfUnCompletedJobsOfCustomsAgent[0]?.unfinished
        : null
    );
    formVars.dashboardcustomsagent_263586_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? stateVars.getCountOfUnCompletedJobsOfCustomsAgent[0]?.completed
        : null
    );
    formVars.dashboardcustomsagent_771843_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? stateVars.getCountOfUnCompletedJobsOfCustomsAgent[0]?.total
        : null
    );
    formVars.dashboardcustomsagent_556885_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? stateVars.getCountOfUnCompletedJobsOfCustomsAgent[0]?.unfinished
        : null
    );
    formVars.dashboardcustomsagent_367726_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? stateVars.getCountOfUnCompletedJobsOfCustomsAgent[0]?.completed
        : null
    );
    stateVars.getUnCompletedJobsOfCustomsAgent = result?.data.getUnCompletedJobsOfCustomsAgent;

    stateVars.getCompletedJobsOfCustomsAgent = result?.data.getCompletedJobsOfCustomsAgent;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardCustomsAgentPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardCustomsAgentPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp237173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardcustomsagent_807602_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp807602Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782825Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardcustomsagent_912810_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp912810Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardcustomsagent_66171_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? this.state.getCountOfUnCompletedJobsOfCustomsAgent[0]?.unfinished
        : null
    );

    formVars.dashboardcustomsagent_263586_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? this.state.getCountOfUnCompletedJobsOfCustomsAgent[0]?.completed
        : null
    );

    formVars.dashboardcustomsagent_771843_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? this.state.getCountOfUnCompletedJobsOfCustomsAgent[0]?.total
        : null
    );

    formVars.dashboardcustomsagent_556885_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? this.state.getCountOfUnCompletedJobsOfCustomsAgent[0]?.unfinished
        : null
    );

    stateVars.dataSource_646524 = this.state.getUnCompletedJobsOfCustomsAgent;
    formVars.dashboardcustomsagent_367726_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUnCompletedJobsOfCustomsAgent?.length > 0
        ? this.state.getCountOfUnCompletedJobsOfCustomsAgent[0]?.completed
        : null
    );

    stateVars.dataSource_724291 = this.state.getCompletedJobsOfCustomsAgent;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashboardCustomsAgentComponent_844448_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomsAgent",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "889963",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomsAgentComponent_666662_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomsAgentComponent_595872_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "en_US");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp666662Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp597975Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp595872Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp355968Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomsAgentComponent_836338_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomsAgent",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "819369",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomsAgentComponent_171447_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomsAgentComponent_709051_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomsAgent",
      "DocumentWaitingUploadListCA",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomsAgentComponent_773888_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomsAgent",
      "DocumentUploadedListCustomsAgent",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomsAgentComponent_35770_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomsAgent",
      "DocumentWaitingUploadListCA",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomsAgentComponent_760043_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "dashboardcustomsagent_646524_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "dashboardcustomsagent_646524_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomsAgent",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomsAgentComponent_329871_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomsAgent",
      "DocumentUploadedListCustomsAgent",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomsAgentComponent_843265_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "dashboardcustomsagent_724291_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "dashboardcustomsagent_724291_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomsAgent",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [889963, 819369, , , , , ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashboardCustomsAgentPageInit();
    }
  }
}
