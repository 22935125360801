import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProfile_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProfile_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  GetUserCurrentId: any[];
  spGetUserById: any[];
  GetCompanyById: any[];
  GetUsersFirstLetter: any[];
  NAVIGATE: any;
  SetLanguage: string;
  isComp217729Visible: "visible" | "hidden";
  isComp897200Visible: "visible" | "hidden";
  isComp469755Visible: "visible" | "hidden";
  isComp88131Visible: "visible" | "hidden";
}

export class Profile_ScreenBase extends React.PureComponent<IProfile_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 215503, PropertyName: "value", Value: "Düzenle", TagName: "Label3_value" },
        { Id: 908539, PropertyName: "value", Value: "Şifre Değiştir", TagName: "Label15_1_value" },
        { Id: 818779, PropertyName: "value", Value: "Şirket Adı", TagName: "Label6_value" },
        { Id: 237660, PropertyName: "value", Value: "Telefon Numarası", TagName: "Label15_0_value" },
        { Id: 590006, PropertyName: "value", Value: "Eposta", TagName: "Label8_value" },
        { Id: 200463, PropertyName: "value", Value: "Adres", TagName: "Label0_0_value" },
        { Id: 853901, PropertyName: "value", Value: "Ülke", TagName: "Label0_0_value" },
        { Id: 547836, PropertyName: "value", Value: "Şehir", TagName: "Label0_0_value" },
        { Id: 24407, PropertyName: "value", Value: "Posta Kodu", TagName: "Label0_0_value" },
        { Id: 668638, PropertyName: "value", Value: "TR", TagName: "Label1_value" },
        { Id: 315746, PropertyName: "value", Value: "EN", TagName: "Label1_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "07677cff-c3bc-46a3-a865-145ad18d8a16",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 215503, PropertyName: "value", Value: "Edit" },
        { Id: 908539, PropertyName: "value", Value: "Change Password" },
        { Id: 237660, PropertyName: "value", Value: "Phone Number" },
        { Id: 590006, PropertyName: "value", Value: "Email" },
        { Id: 200463, PropertyName: "value", Value: "Address" },
        { Id: 853901, PropertyName: "value", Value: "Country" },
        { Id: 547836, PropertyName: "value", Value: "City" },
        { Id: 24407, PropertyName: "value", Value: "Postal Code" },
        { Id: 217729, PropertyName: "value", Value: "TR" },
        { Id: 897200, PropertyName: "value", Value: "TR" },
        { Id: 913357, PropertyName: "value", Value: "|" },
        { Id: 469755, PropertyName: "value", Value: "EN" },
        { Id: 88131, PropertyName: "value", Value: "EN" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      GetUserCurrentId: [],
      spGetUserById: [],
      GetCompanyById: [],
      GetUsersFirstLetter: [],
      NAVIGATE: "",
      SetLanguage: "",
      isComp217729Visible: "hidden",
      isComp897200Visible: "hidden",
      isComp469755Visible: "hidden",
      isComp88131Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("profile", "profile");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProfilePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("profile", "profile");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("profile", "profile");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProfilePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      profile_342604_value: this.state.spGetUserById?.at?.(-1)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ProfilePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp217729Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp897200Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp469755Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp88131Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProfilePageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProfilePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      UserName_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Profile/ProfilePageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.profile_342604_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0
        ? stateVars.spGetUserById[stateVars.spGetUserById.length - 1].profileImage
        : null
    );
    formVars.profile_519958_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.nameSurname : null
    );
    formVars.profile_841742_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.formattedPhone : null
    );
    formVars.profile_254958_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.email : null
    );
    formVars.profile_849747_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.address : null
    );
    formVars.profile_182180_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.addressCountry : null
    );
    formVars.profile_389707_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.addressCity : null
    );
    formVars.profile_985787_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.postalCode : null
    );
    stateVars.GetCompanyById = result?.data.getCompanyById;
    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProfilePageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProfilePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.profile_342604_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0
        ? this.state.spGetUserById[this.state.spGetUserById.length - 1].profileImage
        : null
    );

    formVars.profile_519958_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.nameSurname : null
    );

    formVars.profile_841742_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.formattedPhone : null
    );

    formVars.profile_254958_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.email : null
    );

    formVars.profile_849747_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.address : null
    );

    formVars.profile_182180_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.addressCountry : null
    );

    formVars.profile_389707_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.addressCity : null
    );

    formVars.profile_985787_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.postalCode : null
    );

    stateVars.isComp217729Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp897200Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp469755Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp88131Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProfileComponent_956166_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProfileComponent_215503_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Profile",
      "ProfileEdit",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "411051",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProfileComponent_908539_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Profile",
      "NewPassword",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "473864",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProfileComponent_217729_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp217729Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp897200Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp469755Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp88131Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProfileComponent_469755_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "en_US");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp217729Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp897200Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp469755Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp88131Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [411051, 473864] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ProfilePageInit();
    }
  }
}
