import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRateSurveyQuestion_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRateSurveyQuestion_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  TriggerEvent: string;
  GetUserCurrentId: any[];
  getSurveyAnswersWithQuestions: any[];
  SaveuserSurveyAnswers: any[];
  GoBack: any;
  isComp184954Visible: "visible" | "hidden";
  isComp674682Visible: "visible" | "hidden";
  isCompratesurveyquestion_865384AuthorizationVisible: "visible" | "hidden";
  isCompratesurveyquestion_184954AuthorizationEnabled: "enabled" | "disabled";
}

export class RateSurveyQuestion_ScreenBase extends React.PureComponent<IRateSurveyQuestion_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 37842, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 165912, PropertyName: "value", Value: "Anket Sorusu", TagName: "Label1_value" },
        { Id: 674682, PropertyName: "placeholder", Value: "Buraya yazın...", TagName: "TextArea1_placeholder" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "73f9ca74-42ee-44ba-a3b7-5683a6a5c6c2",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 165912, PropertyName: "value", Value: "Survey Question" },
        { Id: 674682, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 37842, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      TriggerEvent: "",
      GetUserCurrentId: [],
      getSurveyAnswersWithQuestions: [],
      SaveuserSurveyAnswers: [],
      GoBack: "",
      isComp184954Visible: "hidden",
      isComp674682Visible: "hidden",
      isCompratesurveyquestion_865384AuthorizationVisible: "visible",
      isCompratesurveyquestion_184954AuthorizationEnabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ratesurveyquestion", "ratesurveyquestion");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.rateSurveyQuestionPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompratesurveyquestion_865384AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);
    ReactSystemFunctions.disableIsNotAuthorized(this, "isCompratesurveyquestion_184954AuthorizationEnabled", []);

    KuikaAppManager.calculateAndSetBodyHeight("ratesurveyquestion", "ratesurveyquestion");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("ratesurveyquestion", "ratesurveyquestion");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.rateSurveyQuestionPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      ratesurveyquestion_184954_value: this.state.getSurveyAnswersWithQuestions?.at?.(0)?.answer ?? undefined,
      ratesurveyquestion_674682_value: this.state.getSurveyAnswersWithQuestions?.at?.(0)?.answer ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  rateSurveyQuestionPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.lang ?? this.props.screenInputs.lang,
        "string"
      ),
      surveyId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      ),
      QuestionNo_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.questionNo ?? this.props.screenInputs.questionno,
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "rateSurveyQuestion/rateSurveyQuestionPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.getSurveyAnswersWithQuestions = result?.data.getSurveyAnswersWithQuestions;
    formVars.ratesurveyquestion_410464_value = ReactSystemFunctions.toString(
      this,
      stateVars.getSurveyAnswersWithQuestions?.length > 0
        ? stateVars.getSurveyAnswersWithQuestions[0]?.questionText
        : null
    );
    formVars.ratesurveyquestion_674682_value = ReactSystemFunctions.toString(
      this,
      stateVars.getSurveyAnswersWithQuestions?.length > 0 ? stateVars.getSurveyAnswersWithQuestions[0]?.answer : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.rateSurveyQuestionPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  rateSurveyQuestionPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.ratesurveyquestion_410464_value = ReactSystemFunctions.toString(
      this,
      this.state.getSurveyAnswersWithQuestions?.length > 0
        ? this.state.getSurveyAnswersWithQuestions[0]?.questionText
        : null
    );

    formVars.ratesurveyquestion_184954_value = ReactSystemFunctions.toInteger(
      this,
      this.state.getSurveyAnswersWithQuestions?.length > 0 ? this.state.getSurveyAnswersWithQuestions[0]?.answer : null
    );

    stateVars.isComp184954Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.questionNo ?? this.props.screenInputs.questionno,
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.ratesurveyquestion_674682_value = ReactSystemFunctions.toString(
      this,
      this.state.getSurveyAnswersWithQuestions?.length > 0 ? this.state.getSurveyAnswersWithQuestions[0]?.answer : null
    );

    stateVars.isComp674682Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.questionNo ?? this.props.screenInputs.questionno, "10") ===
      true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  rateSurveyQuestionComponent_566937_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.rateSurveyQuestionComponent_37842_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  rateSurveyQuestionComponent_184954_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      answer_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ratesurveyquestion_184954_value", "value", "", "", "")
        ),
        "string"
      ),
      question_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getSurveyAnswersWithQuestions?.length > 0
            ? this.state.getSurveyAnswersWithQuestions[0]?.question
            : null
        ),
        "string"
      ),
      questionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getSurveyAnswersWithQuestions?.length > 0
            ? this.state.getSurveyAnswersWithQuestions[0]?.questionEN
            : null
        ),
        "string"
      ),
      surveyID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      ),
      userID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "Guid"
      ),
      questionId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getSurveyAnswersWithQuestions?.length > 0
            ? this.state.getSurveyAnswersWithQuestions[0]?.surveyQuestionsId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "rateSurveyQuestion/rateSurveyQuestionComponent_184954_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveuserSurveyAnswers = result?.data.saveuserSurveyAnswers;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.rateSurveyQuestionComponent_184954_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  rateSurveyQuestionComponent_184954_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  rateSurveyQuestionComponent_37842_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      answer_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ratesurveyquestion_184954_value", "value", "", "", "")
        ),
        "string"
      ),
      question_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getSurveyAnswersWithQuestions?.length > 0
            ? this.state.getSurveyAnswersWithQuestions[0]?.question
            : null
        ),
        "string"
      ),
      questionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getSurveyAnswersWithQuestions?.length > 0
            ? this.state.getSurveyAnswersWithQuestions[0]?.questionEN
            : null
        ),
        "string"
      ),
      surveyID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      ),
      userID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "Guid"
      ),
      questionId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getSurveyAnswersWithQuestions?.length > 0
            ? this.state.getSurveyAnswersWithQuestions[0]?.surveyQuestionsId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isNotEqualTo(this.props.screenInputs.questionNo ?? this.props.screenInputs.questionno, "10")
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "rateSurveyQuestion/rateSurveyQuestionComponent_37842_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveuserSurveyAnswers = result?.data.saveuserSurveyAnswers;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.rateSurveyQuestionComponent_37842_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.rateSurveyQuestionComponent_37842_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  rateSurveyQuestionComponent_37842_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      answer_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ratesurveyquestion_674682_value", "value", "", "", "")
        ),
        "string"
      ),
      question_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getSurveyAnswersWithQuestions?.length > 0
            ? this.state.getSurveyAnswersWithQuestions[0]?.question
            : null
        ),
        "string"
      ),
      questionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getSurveyAnswersWithQuestions?.length > 0
            ? this.state.getSurveyAnswersWithQuestions[0]?.questionEN
            : null
        ),
        "string"
      ),
      surveyID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      ),
      userID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "Guid"
      ),
      questionId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getSurveyAnswersWithQuestions?.length > 0
            ? this.state.getSurveyAnswersWithQuestions[0]?.surveyQuestionsId
            : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.questionNo ?? this.props.screenInputs.questionno, "10")
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "rateSurveyQuestion/rateSurveyQuestionComponent_37842_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveuserSurveyAnswers = result?.data.saveuserSurveyAnswers;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.rateSurveyQuestionComponent_37842_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.rateSurveyQuestionComponent_37842_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  rateSurveyQuestionComponent_37842_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
