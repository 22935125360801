import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashboardCustomer_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashboardCustomer_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  SetLanguage: string;
  GetLanguage: string;
  Logout: boolean;
  goToSignInActivity: any;
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  getMobileOrderStatusSB: any[];
  SetValueOf: any;
  getCountOfUsersAuthorizedOrders: any[];
  getCountOfUsersOrdersByFilter: any[];
  getUsersAuthorizedOrders: any[];
  WriteToSessionStorage: any;
  TriggerEvent: string;
  IfThenElse: boolean;
  isComp978011Visible: "visible" | "hidden";
  isComp513832Visible: "visible" | "hidden";
  isComp504101Visible: "visible" | "hidden";
  isComp550182Visible: "visible" | "hidden";
  isComp168246Visible: "visible" | "hidden";
  isComp10124Visible: "visible" | "hidden";
  isComp238797Visible: "visible" | "hidden";
  isComp793926Visible: "visible" | "hidden";
  isComp585474Visible: "visible" | "hidden";
  isComp757917Visible: "visible" | "hidden";
}

export class DashboardCustomer_ScreenBase extends React.PureComponent<IDashboardCustomer_ScreenProps, any> {
  dashboardcustomer_305730_value_kuikaDateRef: React.RefObject<any>;
  dashboardcustomer_862491_value_kuikaDateRef: React.RefObject<any>;
  dashboardcustomer_67263_value_kuikaSelectBoxRef: React.RefObject<any>;
  dashboardcustomer_52693_value_kuikaGalleryViewRef: React.RefObject<any>;
  dashboardcustomer_898077_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 513345, PropertyName: "value", Value: "Şimdiye Kadar", TagName: "Label7_value" },
        { Id: 963903, PropertyName: "value", Value: "Hazırlanmakta", TagName: "Label2_value" },
        { Id: 581009, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 333614, PropertyName: "label", Value: "Çıkış", TagName: "ContentMenuItem2_2_label" },
        { Id: 836845, PropertyName: "value", Value: "Toplam", TagName: "Label7_1_value" },
        { Id: 861513, PropertyName: "value", Value: "Yola Çıkan", TagName: "Label7_1_value" },
        { Id: 239852, PropertyName: "value", Value: "Teslim Edilen", TagName: "Label7_1_value" },
        { Id: 958623, PropertyName: "value", Value: "Kayıtları Filtrele", TagName: "Label7_0_value" },
        { Id: 690103, PropertyName: "value", Value: "Filtre Temizle", TagName: "Label32_value" },
        { Id: 305730, PropertyName: "placeholder", Value: "Başlangıç Tarihi Seçin...", TagName: "Date0_0_placeholder" },
        { Id: 862491, PropertyName: "placeholder", Value: "Bitiş Tarihi Seçin...", TagName: "Date1_placeholder" },
        { Id: 574867, PropertyName: "placeholder", Value: "Ara..", TagName: "TextInput0_0_placeholder" },
        { Id: 728971, PropertyName: "value", Value: "Durumuna Göre Siparişler", TagName: "Label28_value" },
        { Id: 67263, PropertyName: "placeholder", Value: "Sipariş Durumu...", TagName: "sbOrderStatusCus_placeholder" },
        { Id: 662184, PropertyName: "value", Value: "Diğer Sipariş Bilgileri", TagName: "Label27_value" },
        { Id: 311009, PropertyName: "value", Value: "Belgeler", TagName: "Label7_2_value" },
        { Id: 585926, PropertyName: "value", Value: "Lokasyonlar", TagName: "Label7_2_value" },
        { Id: 673727, PropertyName: "value", Value: "Anket", TagName: "Label7_2_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "615cecca-2cda-491f-bdba-bef2b4757017",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 978011, PropertyName: "value", Value: "TR" },
        { Id: 513832, PropertyName: "value", Value: "TR" },
        { Id: 603913, PropertyName: "value", Value: "|" },
        { Id: 504101, PropertyName: "value", Value: "EN" },
        { Id: 550182, PropertyName: "value", Value: "EN" },
        { Id: 248154, PropertyName: "label", Value: "Company Profile" },
        { Id: 581009, PropertyName: "label", Value: "My Profile" },
        { Id: 333614, PropertyName: "label", Value: "Logout" },
        { Id: 513345, PropertyName: "value", Value: "Up to Now" },
        { Id: 963903, PropertyName: "value", Value: "In Preperation" },
        { Id: 861513, PropertyName: "value", Value: "Out of Delivery" },
        { Id: 239852, PropertyName: "value", Value: "Delivered" },
        { Id: 836845, PropertyName: "value", Value: "Total" },
        { Id: 958623, PropertyName: "value", Value: "Filter Records" },
        { Id: 585474, PropertyName: "label", Value: "btnTriggerSearch" },
        { Id: 690103, PropertyName: "value", Value: "Clear Filters" },
        { Id: 305730, PropertyName: "placeholder", Value: "Select Start Date..." },
        { Id: 862491, PropertyName: "placeholder", Value: "Select End Date..." },
        { Id: 574867, PropertyName: "placeholder", Value: "Search.." },
        { Id: 728971, PropertyName: "value", Value: "Order By  Status" },
        { Id: 67263, PropertyName: "placeholder", Value: "Choose Status..." },
        { Id: 52693, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 311830, PropertyName: "value", Value: "[datafield:ordercode]" },
        { Id: 943774, PropertyName: "value", Value: "[datafield:orderdate]" },
        { Id: 45060, PropertyName: "value", Value: "[datafield:statuname]" },
        { Id: 662184, PropertyName: "value", Value: "Other Order Information" },
        { Id: 311009, PropertyName: "value", Value: "Documents" },
        { Id: 585926, PropertyName: "value", Value: "Locations" },
        { Id: 673727, PropertyName: "value", Value: "Survey" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardcustomer_305730_value_kuikaDateRef = React.createRef();
    this.dashboardcustomer_862491_value_kuikaDateRef = React.createRef();
    this.dashboardcustomer_67263_value_kuikaSelectBoxRef = React.createRef();
    this.dashboardcustomer_52693_value_kuikaGalleryViewRef = React.createRef();
    this.dashboardcustomer_898077_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      SetLanguage: "",
      GetLanguage: "",
      Logout: false,
      goToSignInActivity: "",
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      getMobileOrderStatusSB: [],
      SetValueOf: "",
      getCountOfUsersAuthorizedOrders: [],
      getCountOfUsersOrdersByFilter: [],
      getUsersAuthorizedOrders: [],
      WriteToSessionStorage: "",
      TriggerEvent: "",
      IfThenElse: false,
      isComp978011Visible: "hidden",
      isComp513832Visible: "hidden",
      isComp504101Visible: "hidden",
      isComp550182Visible: "hidden",
      isComp168246Visible: "hidden",
      isComp10124Visible: "hidden",
      isComp238797Visible: "hidden",
      isComp793926Visible: "hidden",
      isComp585474Visible: "hidden",
      isComp757917Visible: "hidden"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardcustomer", "dashboardcustomer");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashboardCustomerPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardcustomer", "dashboardcustomer");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Customer"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardcustomer", "dashboardcustomer");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashboardCustomerPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboardcustomer_10124_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DashboardCustomerPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp978011Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp513832Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp504101Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp550182Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardCustomerPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashboardCustomerPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      lang_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashboardCustomer/DashboardCustomerPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.dashboardcustomer_793926_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.dashboardcustomer_10124_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp168246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp238797Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp793926Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getMobileOrderStatusSB = result?.data.getMobileOrderStatusSB;

    formVars.dashboardcustomer_67263_options = stateVars.getMobileOrderStatusSB;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardCustomerPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardCustomerPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardcustomer_67263_value",
      this.props.screenInputs.st ?? this.props.screenInputs.st,
      null,
      "Status"
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardCustomerPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashboardCustomerPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      userId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.id : null),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashboardCustomer/DashboardCustomerPageInit3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCountOfUsersAuthorizedOrders = result?.data.getCountOfUsersAuthorizedOrders;
    formVars.dashboardcustomer_980780_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUsersAuthorizedOrders?.length > 0
        ? stateVars.getCountOfUsersAuthorizedOrders[0]?.inPreparetion
        : null
    );
    formVars.dashboardcustomer_979586_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUsersAuthorizedOrders?.length > 0
        ? stateVars.getCountOfUsersAuthorizedOrders[0]?.outOfDelivery
        : null
    );
    formVars.dashboardcustomer_31535_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUsersAuthorizedOrders?.length > 0
        ? stateVars.getCountOfUsersAuthorizedOrders[0]?.delivered
        : null
    );
    formVars.dashboardcustomer_919422_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUsersAuthorizedOrders?.length > 0 ? stateVars.getCountOfUsersAuthorizedOrders[0]?.total : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardCustomerPageInit4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardCustomerPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "dashboardcustomer_898077_value", 1, null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardCustomerPageInit5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashboardCustomerPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      userId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.id : null),
        "Guid"
      ),
      startdate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_305730_value", "value", "", "", "")
        ),
        "Date"
      ),
      enddate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_862491_value", "value", "", "", "")
        ),
        "Date"
      ),
      orderStatus_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardcustomer_67263_value",
            "value",
            "getMobileOrderStatusSB",
            "status",
            "status"
          )
        ),
        "string"
      ),
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_574867_value", "value", "", "", "")
        ),
        "string"
      ),
      userId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.id : null),
        "Guid"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_898077_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_898077_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      startdate_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_305730_value", "value", "", "", "")
        ),
        "Date"
      ),
      enddate_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_862491_value", "value", "", "", "")
        ),
        "Date"
      ),
      orderStatus_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardcustomer_67263_value",
            "value",
            "getMobileOrderStatusSB",
            "status",
            "status"
          )
        ),
        "number"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_574867_value", "value", "", "", "")
        ),
        "string"
      ),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashboardCustomer/DashboardCustomerPageInit5_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCountOfUsersOrdersByFilter = result?.data.getCountOfUsersOrdersByFilter;
    formVars.dashboardcustomer_571097_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUsersOrdersByFilter?.length > 0
        ? stateVars.getCountOfUsersOrdersByFilter[0]?.recordCountStr
        : null
    );
    stateVars.getUsersAuthorizedOrders = result?.data.getUsersAuthorizedOrders;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardCustomerPageInit6_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardCustomerPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.WriteToSessionStorage = await ReactSystemFunctions.writeToSessionStorage(
      "companyId",
      ReactSystemFunctions.toString(
        this,
        this.state.getUsersAuthorizedOrders?.length > 0 ? this.state.getUsersAuthorizedOrders[0]?.companyId : null
      )
    );
    stateVars.isComp978011Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp513832Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp504101Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp550182Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp168246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardcustomer_10124_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp10124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp238797Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardcustomer_793926_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp793926Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardcustomer_980780_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUsersAuthorizedOrders?.length > 0
        ? this.state.getCountOfUsersAuthorizedOrders[0]?.inPreparetion
        : null
    );

    formVars.dashboardcustomer_979586_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUsersAuthorizedOrders?.length > 0
        ? this.state.getCountOfUsersAuthorizedOrders[0]?.outOfDelivery
        : null
    );

    formVars.dashboardcustomer_31535_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUsersAuthorizedOrders?.length > 0
        ? this.state.getCountOfUsersAuthorizedOrders[0]?.delivered
        : null
    );

    formVars.dashboardcustomer_919422_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUsersAuthorizedOrders?.length > 0
        ? this.state.getCountOfUsersAuthorizedOrders[0]?.total
        : null
    );

    formVars.dashboardcustomer_571097_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUsersOrdersByFilter?.length > 0
        ? this.state.getCountOfUsersOrdersByFilter[0]?.recordCountStr
        : null
    );

    stateVars.dataSource_67263 = this.state.getMobileOrderStatusSB;

    stateVars.dataSource_52693 = this.state.getUsersAuthorizedOrders;
    formVars.dashboardcustomer_898077_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfUsersOrdersByFilter?.length > 0
        ? this.state.getCountOfUsersOrdersByFilter[0]?.recordCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashboardCustomerComponent_844448_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomer",
      "LeftMenuCustomer",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "889963",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_978011_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp978011Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp513832Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp504101Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp550182Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_504101_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "en_US");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp978011Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp513832Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp504101Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp550182Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_248154_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CompanyProfileCustomer",
      "companyId",
      ReactSystemFunctions.value(
        this,
        this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.companyID : null
      )
    );
    KuikaAppManager.addToPageInputVariables("CompanyProfileCustomer", "userType", 3);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomer",
      "CompanyProfileCustomer",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "580716",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_581009_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomer",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "697468",
      null,
      "right",
      null,
      "600px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_333614_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_809418_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardcustomer_67263_value",
      "0",
      null,
      "Status"
    );
    if (await this.DashboardCustomerComponent_585474_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_748324_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardcustomer_67263_value",
      "10",
      null,
      "Status"
    );
    if (await this.DashboardCustomerComponent_585474_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_531137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardcustomer_67263_value",
      "20",
      null,
      "Status"
    );
    if (await this.DashboardCustomerComponent_585474_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_332069_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboardcustomer_67263_value",
      "1020",
      null,
      "Status"
    );
    if (await this.DashboardCustomerComponent_585474_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_585474_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "dashboardcustomer_898077_value", 1, null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardCustomerComponent_585474_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashboardCustomerComponent_585474_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.id : null),
        "Guid"
      ),
      startdate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_305730_value", "value", "", "", "")
        ),
        "Date"
      ),
      enddate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_862491_value", "value", "", "", "")
        ),
        "Date"
      ),
      orderStatus_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardcustomer_67263_value",
            "value",
            "getMobileOrderStatusSB",
            "status",
            "status"
          )
        ),
        "string"
      ),
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_574867_value", "value", "", "", "")
        ),
        "string"
      ),
      userId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.id : null),
        "Guid"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_898077_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_898077_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      startdate_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_305730_value", "value", "", "", "")
        ),
        "Date"
      ),
      enddate_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_862491_value", "value", "", "", "")
        ),
        "Date"
      ),
      orderStatus_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "dashboardcustomer_67263_value",
            "value",
            "getMobileOrderStatusSB",
            "status",
            "status"
          )
        ),
        "number"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboardcustomer_574867_value", "value", "", "", "")
        ),
        "string"
      ),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardCustomer/DashboardCustomerComponent_585474_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCountOfUsersOrdersByFilter = result?.data.getCountOfUsersOrdersByFilter;
    formVars.dashboardcustomer_571097_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfUsersOrdersByFilter?.length > 0
        ? stateVars.getCountOfUsersOrdersByFilter[0]?.recordCountStr
        : null
    );
    stateVars.getUsersAuthorizedOrders = result?.data.getUsersAuthorizedOrders;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardCustomerComponent_585474_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardCustomerComponent_585474_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardcustomer_571097_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfUsersOrdersByFilter?.length > 0
        ? this.state.getCountOfUsersOrdersByFilter[0]?.recordCountStr
        : null
    );

    stateVars.dataSource_52693 = this.state.getUsersAuthorizedOrders;
    formVars.dashboardcustomer_898077_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfUsersOrdersByFilter?.length > 0
        ? this.state.getCountOfUsersOrdersByFilter[0]?.recordCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_690103_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "dashboardcustomer_305730_value", null, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "dashboardcustomer_862491_value", null, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "dashboardcustomer_574867_value", null, null);
    if (await this.DashboardCustomerComponent_585474_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_305730_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.DashboardCustomerComponent_585474_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_862491_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.DashboardCustomerComponent_585474_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_574867_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.DashboardCustomerComponent_585474_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_445205_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.DashboardCustomerComponent_585474_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_67263_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.DashboardCustomerComponent_585474_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_244838_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DocumentsOfCustomerOrder",
      "VehicleId",
      ReactSystemFunctions.value(this, "dashboardcustomer_52693_value", "vehicleRequestId")
    );
    KuikaAppManager.addToPageInputVariables(
      "DocumentsOfCustomerOrder",
      "isAbroad",
      ReactSystemFunctions.value(this, "dashboardcustomer_52693_value", "isAbroad")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomer",
      "DocumentsOfCustomerOrder",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "834400",
      null,
      "right",
      null,
      "80%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_509488_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "LocationsOfCustomerOrder",
      "VehicleId",
      ReactSystemFunctions.value(this, "dashboardcustomer_52693_value", "vehicleRequestId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomer",
      "LocationsOfCustomerOrder",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "353904",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_463569_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SurveyDetails",
      "surveyId",
      ReactSystemFunctions.value(this, "dashboardcustomer_52693_value", "surveyId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomer",
      "SurveyDetails",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "665885",
      null,
      "right",
      null,
      "80%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_463569_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SurveySave",
      "surveyId",
      ReactSystemFunctions.value(this, "dashboardcustomer_52693_value", "surveyId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomer",
      "SurveySave",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "884752",
      null,
      "right",
      null,
      "80%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_463569_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "dashboardcustomer_52693_value", "surveyStatus"),
        "20"
      )
    ) {
      isErrorOccurred = await this.DashboardCustomerComponent_463569_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.DashboardCustomerComponent_463569_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_131462_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DetailsOfCustomerOrder",
      "orderNo",
      ReactSystemFunctions.value(this, "dashboardcustomer_52693_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "DetailsOfCustomerOrder",
      "cardNo",
      ReactSystemFunctions.value(this, "dashboardcustomer_52693_value", "cardCode")
    );
    KuikaAppManager.addToPageInputVariables(
      "DetailsOfCustomerOrder",
      "VehicleId",
      ReactSystemFunctions.value(this, "dashboardcustomer_52693_value", "vehicleRequestId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardCustomer",
      "DetailsOfCustomerOrder",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "396286",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardCustomerComponent_898077_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.DashboardCustomerComponent_585474_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [889963, 580716, 697468, 834400, 353904, 665885, 884752, 396286] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashboardCustomerPageInit();
    }
  }
}
