import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOrdersInProgress_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOrdersInProgress_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  GetLanguage: string;
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  getVehicleStatusListForFilter: any[];
  getLogisticSuppliers: any[];
  getCustomsAgents: any[];
  SetValueOf: any;
  TriggerEvent: string;
  getCountOfOrdersByFilter: any[];
  getOrdersByFilter: any[];
  getCountOfListOfVehicleRequestByStatus: any[];
  checkAndUpdateSAPLoadStatus: boolean;
  Notify: boolean;
  IfThenElse: boolean;
  sqlApproveVehicleRequest: number;
  isComp439082Visible: "visible" | "hidden";
  isComp164081Visible: "visible" | "hidden";
  isComp870395Visible: "visible" | "hidden";
  isComp40949Visible: "visible" | "hidden";
  isComp626622Visible: "visible" | "hidden";
  isCompordersinprogress_683851AuthorizationVisible: "visible" | "hidden";
  isCompordersinprogress_913358AuthorizationVisible: "visible" | "hidden";
  isCompordersinprogress_558994AuthorizationVisible: "visible" | "hidden";
  isCompordersinprogress_455052AuthorizationVisible: "visible" | "hidden";
}

export class OrdersInProgress_ScreenBase extends React.PureComponent<IOrdersInProgress_ScreenProps, any> {
  ordersinprogress_325479_value_kuikaSelectBoxRef: React.RefObject<any>;
  ordersinprogress_415868_value_kuikaSelectBoxRef: React.RefObject<any>;
  ordersinprogress_178619_value_kuikaSelectBoxRef: React.RefObject<any>;
  ordersinprogress_44094_value_kuikaDateRef: React.RefObject<any>;
  ordersinprogress_696651_value_kuikaTableRef: React.RefObject<any>;
  ordersinprogress_131161_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 71816, PropertyName: "placeholder", Value: "Ara...", TagName: "TextInput1_placeholder" },
        {
          Id: 696651,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 846713, PropertyName: "title", Value: "Sipariş No", TagName: "TableColumn1_0_title" },
        { Id: 308776, PropertyName: "title", Value: "Cari Kod", TagName: "TableColumn1_1_title" },
        { Id: 154367, PropertyName: "title", Value: "Müşteri", TagName: "TableColumn1_2_title" },
        { Id: 456316, PropertyName: "value", Value: "Siparişler", TagName: "Label2_0_value" },
        { Id: 683851, PropertyName: "label", Value: "Yeni Araç Talebi", TagName: "Button1_label" },
        { Id: 391112, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 881345, PropertyName: "label", Value: "Çıkış", TagName: "ContentMenuItem2_2_label" },
        { Id: 719466, PropertyName: "title", Value: "Sipariş Tarihi", TagName: "TableColumn1_title" },
        { Id: 93906, PropertyName: "title", Value: "Durum", TagName: "TableColumn6_title" },
        { Id: 774548, PropertyName: "title", Value: "Sipariş Detayı", TagName: "Tooltip1_title" },
        { Id: 325479, PropertyName: "placeholder", Value: "Durum Seçiniz...", TagName: "SelectBox1_placeholder" },
        { Id: 415868, PropertyName: "placeholder", Value: "Tedarikçi Seçiniz...", TagName: "SelectBox3_placeholder" },
        { Id: 178619, PropertyName: "placeholder", Value: "Gümrükçü Seçiniz...", TagName: "SelectBox2_placeholder" },
        { Id: 44094, PropertyName: "placeholder", Value: "Sipariş Tarihi Şeçiniz...", TagName: "Date1_placeholder" },
        { Id: 992973, PropertyName: "value", Value: "Toplam Kayıt Sayısı :", TagName: "Label2_0_value" },
        { Id: 968687, PropertyName: "value", Value: "Filtre", TagName: "Label11_value" },
        { Id: 455052, PropertyName: "label", Value: "Onayla", TagName: "Button1_label" },
        { Id: 980640, PropertyName: "title", Value: "Araç Görüntüle", TagName: "Tooltip2_title" },
        { Id: 910834, PropertyName: "title", Value: "Plaka", TagName: "TableColumn8_title" },
        { Id: 558994, PropertyName: "title", Value: "Navlun Fatura", TagName: "TableColumn7_title" },
        { Id: 143752, PropertyName: "value", Value: "Faturayı Gör", TagName: "Label2_2_value" },
        { Id: 179382, PropertyName: "value", Value: "Henüz Yüklenmedi", TagName: "Label2_1_value" },
        {
          Id: 49414528,
          PropertyName: "2d763b11-f829-2d24-508f-2676b9a33e73_confirmation",
          Value: "Atanan araç bilgilerini onaylamak istiyor musunuz?",
          TagName: "sqlApproveVehicleRequest_confirmation",
          IsConfirmation: true
        },
        { Id: 170648, PropertyName: "label", Value: "Yükleme Durumu Güncelle", TagName: "Button2_label" },
        {
          Id: 71911629,
          PropertyName: "49110175-6ca2-8a7e-fe02-d3386bfbf21b_notify",
          Value: "Araç ataması onaylı siparişlerin yükleme durumu başarıyla güncellendi.",
          TagName: "Notify_notify",
          IsNotify: true
        },
        {
          Id: 39410164,
          PropertyName: "60afc9af-87b4-88b9-e5fd-0e2246da3839_notify",
          Value: "Yükleme durumu güncellemesi için onaylı araç ataması olan sipariş bulunmamakta.",
          TagName: "Notify_notify",
          IsNotify: true
        },
        {
          Id: 864048,
          PropertyName: "title",
          Value: "Yükleme durumu güncellemesi için kontrol edilecek sipariş sayısı",
          TagName: "Tooltip3_title"
        }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "ce7dc570-3030-4421-8222-1f33e3fb6d39",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 391112, PropertyName: "label", Value: "Profile" },
        { Id: 881345, PropertyName: "label", Value: "Logout" },
        { Id: 456316, PropertyName: "value", Value: "Orders" },
        { Id: 626622, PropertyName: "label", Value: "pageinttrigger" },
        { Id: 683851, PropertyName: "label", Value: "New Vehicle Request" },
        { Id: 968687, PropertyName: "value", Value: "Filter" },
        { Id: 325479, PropertyName: "placeholder", Value: "Choose Status..." },
        { Id: 415868, PropertyName: "placeholder", Value: "Choose Logistic Supplier..." },
        { Id: 178619, PropertyName: "placeholder", Value: "Choose Customs Agent..." },
        { Id: 44094, PropertyName: "placeholder", Value: "Select Order Date..." },
        { Id: 71816, PropertyName: "placeholder", Value: "Search.." },
        { Id: 864048, PropertyName: "title", Value: "Number of orders to check for loading status update" },
        { Id: 170648, PropertyName: "label", Value: "Refresh Loading States" },
        { Id: 696651, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 154367, PropertyName: "title", Value: "Customer" },
        { Id: 469709, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 308776, PropertyName: "title", Value: "Current Code" },
        { Id: 400373, PropertyName: "value", Value: "[datafield:cardcode]" },
        { Id: 846713, PropertyName: "title", Value: "Order #" },
        { Id: 494669, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 719466, PropertyName: "title", Value: "Order Date" },
        { Id: 982637, PropertyName: "value", Value: "[datafield:orderdate]" },
        { Id: 910834, PropertyName: "title", Value: "Vehicle Plate" },
        { Id: 820481, PropertyName: "value", Value: "[datafield:vehicleplate]" },
        { Id: 558994, PropertyName: "title", Value: "Freight Invoice" },
        { Id: 143752, PropertyName: "value", Value: "View Invoice" },
        { Id: 179382, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 93906, PropertyName: "title", Value: "Status" },
        { Id: 194101, PropertyName: "value", Value: "[datafield:orderstatusdesc]" },
        { Id: 455052, PropertyName: "label", Value: "Approve" },
        { Id: 980640, PropertyName: "title", Value: "View Vehicle" },
        { Id: 774548, PropertyName: "title", Value: "Order Details" },
        { Id: 992973, PropertyName: "value", Value: "Total Records Count :" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ordersinprogress_325479_value_kuikaSelectBoxRef = React.createRef();
    this.ordersinprogress_415868_value_kuikaSelectBoxRef = React.createRef();
    this.ordersinprogress_178619_value_kuikaSelectBoxRef = React.createRef();
    this.ordersinprogress_44094_value_kuikaDateRef = React.createRef();
    this.ordersinprogress_696651_value_kuikaTableRef = React.createRef();
    this.ordersinprogress_131161_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      GetLanguage: "",
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      getVehicleStatusListForFilter: [],
      getLogisticSuppliers: [],
      getCustomsAgents: [],
      SetValueOf: "",
      TriggerEvent: "",
      getCountOfOrdersByFilter: [],
      getOrdersByFilter: [],
      getCountOfListOfVehicleRequestByStatus: [],
      IfThenElse: false,
      sqlApproveVehicleRequest: 0,
      isComp439082Visible: "hidden",
      isComp164081Visible: "hidden",
      isComp870395Visible: "hidden",
      isComp40949Visible: "hidden",
      isComp626622Visible: "hidden",
      isCompordersinprogress_683851AuthorizationVisible: "visible",
      isCompordersinprogress_913358AuthorizationVisible: "visible",
      isCompordersinprogress_558994AuthorizationVisible: "visible",
      isCompordersinprogress_455052AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ordersinprogress", "ordersinprogress");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.OrdersInProgressPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompordersinprogress_683851AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompordersinprogress_913358AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompordersinprogress_558994AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Logistic",
      "LogisticSupplier",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompordersinprogress_455052AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "Logistic"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("ordersinprogress", "ordersinprogress");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Administrator", "Sale", "Logistic", "Quality", "Finance"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("ordersinprogress", "ordersinprogress");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.OrdersInProgressPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      ordersinprogress_164081_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  OrdersInProgressPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrdersInProgressPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  OrdersInProgressPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      lang_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "OrdersInProgress/OrdersInProgressPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.ordersinprogress_40949_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.ordersinprogress_164081_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp439082Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164081Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp870395Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp40949Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getVehicleStatusListForFilter = result?.data.getVehicleStatusListForFilter;

    formVars.ordersinprogress_325479_options = stateVars.getVehicleStatusListForFilter;
    stateVars.getLogisticSuppliers = result?.data.getLogisticSuppliers;

    formVars.ordersinprogress_415868_options = stateVars.getLogisticSuppliers;
    stateVars.getCustomsAgents = result?.data.getCustomsAgents;

    formVars.ordersinprogress_178619_options = stateVars.getCustomsAgents;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrdersInProgressPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrdersInProgressPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(this.props.screenInputs.st ?? this.props.screenInputs.st, null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "ordersinprogress_325479_value",
        this.props.screenInputs.st ?? this.props.screenInputs.st,
        null,
        "status"
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.OrdersInProgressPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.OrdersInProgressPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  OrdersInProgressPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.OrdersInProgressComponent_626622_onClick()) return true;

    stateVars.isComp439082Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.ordersinprogress_164081_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp164081Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp870395Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.ordersinprogress_40949_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp40949Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_325479 = this.state.getVehicleStatusListForFilter;

    stateVars.dataSource_415868 = this.state.getLogisticSuppliers;

    stateVars.dataSource_178619 = this.state.getCustomsAgents;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  OrdersInProgressComponent_401583_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrdersInProgress",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "505387",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_391112_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrdersInProgress",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "568024",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_881345_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_626622_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "ordersinprogress_131161_value", 1, null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrdersInProgressComponent_626622_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  OrdersInProgressComponent_626622_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_71816_value", "value", "", "", "")
        ),
        "string"
      ),
      vehicleStatus_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "ordersinprogress_325479_value",
            "value",
            "getVehicleStatusListForFilter",
            "status",
            "status"
          )
        ),
        "number"
      ),
      customsAgentId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_178619_value", "value", "getCustomsAgents", "id", "id")
        ),
        "Guid"
      ),
      logisticSupplierId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_415868_value", "value", "getLogisticSuppliers", "id", "id")
        ),
        "Guid"
      ),
      distributorID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      orderStartDateFilter_0: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      orderEndDateFilter_0: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      orderAtDateFilter_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_44094_value", "value", "", "", "")
        ),
        "Date"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_71816_value", "value", "", "", "")
        ),
        "string"
      ),
      pageCount_1: ReactSystemFunctions.convertToTypeByName(20, "number"),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(1, "number"),
      vehicleStatus_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "ordersinprogress_325479_value",
            "value",
            "getVehicleStatusListForFilter",
            "status",
            "status"
          )
        ),
        "number"
      ),
      customsAgentId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_178619_value", "value", "getCustomsAgents", "id", "id")
        ),
        "Guid"
      ),
      logisticSupplierId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_415868_value", "value", "getLogisticSuppliers", "id", "id")
        ),
        "Guid"
      ),
      distributorID_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      orderStartDateFilter_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      orderEndDateFilter_1: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      orderAtDateFilter_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_44094_value", "value", "", "", "")
        ),
        "Date"
      ),
      vehicleRequestStatus_2: ReactSystemFunctions.convertToTypeByName(30, "number")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OrdersInProgress/OrdersInProgressComponent_626622_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCountOfOrdersByFilter = result?.data.getCountOfOrdersByFilter;
    formVars.ordersinprogress_84895_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByFilter?.length > 0 ? stateVars.getCountOfOrdersByFilter[0]?.recordCountOfOrders : null
    );
    stateVars.getOrdersByFilter = result?.data.getOrdersByFilter;

    stateVars.getCountOfListOfVehicleRequestByStatus = result?.data.getCountOfListOfVehicleRequestByStatus;
    formVars.ordersinprogress_826561_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfListOfVehicleRequestByStatus?.length > 0
        ? stateVars.getCountOfListOfVehicleRequestByStatus[0]?.countOfRecords
        : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrdersInProgressComponent_626622_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrdersInProgressComponent_626622_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.ordersinprogress_826561_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfListOfVehicleRequestByStatus?.length > 0
        ? this.state.getCountOfListOfVehicleRequestByStatus[0]?.countOfRecords
        : null
    );

    stateVars.dataSource_696651 = this.state.getOrdersByFilter;
    formVars.ordersinprogress_84895_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByFilter?.length > 0
        ? this.state.getCountOfOrdersByFilter[0]?.recordCountOfOrders
        : null
    );

    formVars.ordersinprogress_131161_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfOrdersByFilter?.length > 0
        ? this.state.getCountOfOrdersByFilter[0]?.recordCountOfOrders
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_683851_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrdersInProgress",
      "OrderSearch",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_325479_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.OrdersInProgressComponent_626622_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_415868_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.OrdersInProgressComponent_626622_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_178619_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.OrdersInProgressComponent_626622_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_44094_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.OrdersInProgressComponent_626622_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_71816_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.OrdersInProgressComponent_626622_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_84962_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.OrdersInProgressComponent_626622_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_826561_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "ordersinprogress_325479_value",
      "30",
      null,
      "status"
    );
    if (await this.OrdersInProgressComponent_626622_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_170648_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestStatus_0: ReactSystemFunctions.convertToTypeByName(30, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OrdersInProgress/OrdersInProgressComponent_170648_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCountOfListOfVehicleRequestByStatus = result?.data.getCountOfListOfVehicleRequestByStatus;
    formVars.ordersinprogress_826561_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfListOfVehicleRequestByStatus?.length > 0
        ? stateVars.getCountOfListOfVehicleRequestByStatus[0]?.countOfRecords
        : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrdersInProgressComponent_170648_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrdersInProgressComponent_170648_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OrdersInProgress/OrdersInProgressComponent_170648_onClick_if1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.checkAndUpdateSAPLoadStatus = result?.data.checkAndUpdateSAPLoadStatus;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrdersInProgressComponent_170648_onClick_if11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrdersInProgressComponent_170648_onClick_if11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.OrdersInProgressComponent_626622_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrdersInProgressComponent_170648_onClick_if12_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  OrdersInProgressComponent_170648_onClick_if12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.checkAndUpdateSAPLoadStatus), true)
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "49110175_6ca2_8a7e_fe02_d3386bfbf21b_notify",
          this.defaultML,
          "The loading status of orders with approved vehicle assignment has been successfully updated."
        ),
        "default",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  OrdersInProgressComponent_170648_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "60afc9af_87b4_88b9_e5fd_0e2246da3839_notify",
        this.defaultML,
        "There are no orders with approved vehicle assignment for vehicle loading status update."
      ),
      "default",
      "bottom-right",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_170648_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.getCountOfListOfVehicleRequestByStatus?.length > 0
            ? this.state.getCountOfListOfVehicleRequestByStatus[0]?.countOfRecords
            : null
        ),
        "0"
      )
    ) {
      isErrorOccurred = await this.OrdersInProgressComponent_170648_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.OrdersInProgressComponent_170648_onClick_else1();
      if (isErrorOccurred) return true;
    }

    formVars.ordersinprogress_826561_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfListOfVehicleRequestByStatus?.length > 0
        ? this.state.getCountOfListOfVehicleRequestByStatus[0]?.countOfRecords
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_216804_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "ordersinprogress_696651_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "ordersinprogress_696651_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrdersInProgress",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_143752_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewInvoice",
      "vehicleRequestId",
      ReactSystemFunctions.value(this, "ordersinprogress_696651_value", "vehicleRequestId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrdersInProgress",
      "PreviewInvoice",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "55071",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_455052_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ordersinprogress_696651_value", "vehicleRequestId"),
        "Guid"
      ),
      approvedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      approvedBy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "2d763b11_f829_2d24_508f_2676b9a33e73_confirmation",
        this.defaultML,
        "Do you want to approve assigned vehicle information?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "OrdersInProgress/OrdersInProgressComponent_455052_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.sqlApproveVehicleRequest = result?.data.sqlApproveVehicleRequest;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.OrdersInProgressComponent_455052_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrdersInProgressComponent_455052_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.OrdersInProgressComponent_626622_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_720927_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "RequestedVehicleView",
      "orderNo",
      ReactSystemFunctions.value(this, "ordersinprogress_696651_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "RequestedVehicleView",
      "vehicleId",
      ReactSystemFunctions.value(this, "ordersinprogress_696651_value", "vehicleRequestId")
    );
    KuikaAppManager.addToPageInputVariables(
      "RequestedVehicleView",
      "cardCode",
      ReactSystemFunctions.value(this, "ordersinprogress_696651_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrdersInProgress",
      "RequestedVehicleView",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "14969",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_671814_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "ordersinprogress_696651_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "ordersinprogress_696651_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrdersInProgress",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrdersInProgressComponent_131161_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_71816_value", "value", "", "", "")
        ),
        "string"
      ),
      vehicleStatus_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "ordersinprogress_325479_value",
            "value",
            "getVehicleStatusListForFilter",
            "status",
            "status"
          )
        ),
        "number"
      ),
      customsAgentId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_178619_value", "value", "getCustomsAgents", "id", "id")
        ),
        "Guid"
      ),
      logisticSupplierId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_415868_value", "value", "getLogisticSuppliers", "id", "id")
        ),
        "Guid"
      ),
      distributorID_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      orderStartDateFilter_0: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      orderEndDateFilter_0: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      orderAtDateFilter_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_44094_value", "value", "", "", "")
        ),
        "Date"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_71816_value", "value", "", "", "")
        ),
        "string"
      ),
      pageCount_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_131161_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_131161_value", "current", "", "", "")
        ),
        "number"
      ),
      vehicleStatus_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "ordersinprogress_325479_value",
            "value",
            "getVehicleStatusListForFilter",
            "status",
            "status"
          )
        ),
        "number"
      ),
      customsAgentId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_178619_value", "value", "getCustomsAgents", "id", "id")
        ),
        "Guid"
      ),
      logisticSupplierId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_415868_value", "value", "getLogisticSuppliers", "id", "id")
        ),
        "Guid"
      ),
      distributorID_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      orderStartDateFilter_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      orderEndDateFilter_1: ReactSystemFunctions.convertToTypeByName(null, "Date"),
      orderAtDateFilter_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersinprogress_44094_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OrdersInProgress/OrdersInProgressComponent_131161_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCountOfOrdersByFilter = result?.data.getCountOfOrdersByFilter;
    formVars.ordersinprogress_84895_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByFilter?.length > 0 ? stateVars.getCountOfOrdersByFilter[0]?.recordCountOfOrders : null
    );
    stateVars.getOrdersByFilter = result?.data.getOrdersByFilter;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrdersInProgressComponent_131161_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrdersInProgressComponent_131161_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_696651 = this.state.getOrdersByFilter;
    formVars.ordersinprogress_84895_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByFilter?.length > 0
        ? this.state.getCountOfOrdersByFilter[0]?.recordCountOfOrders
        : null
    );

    formVars.ordersinprogress_131161_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfOrdersByFilter?.length > 0
        ? this.state.getCountOfOrdersByFilter[0]?.recordCountOfOrders
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [505387, 568024, , , 55071, 14969] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.OrdersInProgressPageInit();
    }
  }
}
