import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUserList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUserList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  GetLanguage: string;
  GetUsersFirstLetter: any[];
  appUsersSP: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  GoBack: any;
  SetValueOf: any;
  TriggerEvent: string;
  ReverseBool: boolean;
  spReactivateUser: any[];
  spDeleteUser: any[];
  isComp762115Visible: "visible" | "hidden";
  isComp67943Visible: "visible" | "hidden";
  isComp500539Visible: "visible" | "hidden";
  isComp305832Visible: "visible" | "hidden";
  isComp485894Visible: "visible" | "hidden";
  isComp766273Visible: "visible" | "hidden";
  isComp203431Visible: "visible" | "hidden";
  isComp590152Visible: "visible" | "hidden";
  isCompuserlist_669426AuthorizationVisible: "visible" | "hidden";
  isCompuserlist_931397AuthorizationVisible: "visible" | "hidden";
}

export class UserList_ScreenBase extends React.PureComponent<IUserList_ScreenProps, any> {
  userlist_256573_value_kuikaTableRef: React.RefObject<any>;
  userlist_182004_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 776881, PropertyName: "value", Value: "Kullanıcılar", TagName: "Label11_value" },
        { Id: 544338, PropertyName: "placeholder", Value: "Ara...", TagName: "TextInput0_0_placeholder" },
        { Id: 669426, PropertyName: "label", Value: "Ekle", TagName: "Button1_0_label" },
        { Id: 119377, PropertyName: "value", Value: "İsim", TagName: "Label1_value" },
        { Id: 793692, PropertyName: "value", Value: "Soyisim", TagName: "Label2_value" },
        { Id: 874625, PropertyName: "value", Value: "Rol", TagName: "Label3_value" },
        { Id: 550493, PropertyName: "value", Value: "Eposta", TagName: "Label4_value" },
        { Id: 856874, PropertyName: "value", Value: "Telefon Numarası", TagName: "Label5_value" },
        {
          Id: 256573,
          PropertyName: "nodatafoundmessage",
          Value: "Veri Bulunamadı",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 767073, PropertyName: "value", Value: "Silinen Kullanıcıları Göster", TagName: "Label13_value" },
        { Id: 491025, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 513170, PropertyName: "label", Value: "Çıkış", TagName: "ContentMenuItem2_2_label" },
        { Id: 111520, PropertyName: "value", Value: "Kullanıcılar", TagName: "Label6_value" },
        { Id: 49526, PropertyName: "title", Value: "Görüntüle", TagName: "Tooltip1_title" },
        { Id: 871285, PropertyName: "title", Value: "Düzenle", TagName: "Tooltip2_title" },
        { Id: 376456, PropertyName: "title", Value: "Sil", TagName: "Tooltip3_title" },
        { Id: 460082, PropertyName: "title", Value: "Kurtar", TagName: "Tooltip4_title" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "ce723184-9107-405b-9310-d8cfdb4dbae6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 491025, PropertyName: "label", Value: "Profile" },
        { Id: 513170, PropertyName: "label", Value: "Logout" },
        { Id: 111520, PropertyName: "value", Value: "Users" },
        { Id: 203431, PropertyName: "label", Value: "!-Trigger User List-!" },
        { Id: 544338, PropertyName: "placeholder", Value: "Search..." },
        { Id: 767073, PropertyName: "value", Value: "Show Deactivated Users" },
        { Id: 669426, PropertyName: "label", Value: "New" },
        { Id: 119377, PropertyName: "value", Value: "Name" },
        { Id: 793692, PropertyName: "value", Value: "Surname" },
        { Id: 874625, PropertyName: "value", Value: "Role" },
        { Id: 550493, PropertyName: "value", Value: "E-Mail" },
        { Id: 856874, PropertyName: "value", Value: "Phone Number" },
        { Id: 256573, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 194191, PropertyName: "value", Value: "[datafield:name]" },
        { Id: 297711, PropertyName: "value", Value: "[datafield:surname]" },
        { Id: 201585, PropertyName: "value", Value: "[datafield:rolename]" },
        { Id: 865944, PropertyName: "value", Value: "[datafield:email]" },
        { Id: 839726, PropertyName: "value", Value: "[datafield:formattedphone]" },
        { Id: 49526, PropertyName: "title", Value: "View" },
        { Id: 871285, PropertyName: "title", Value: "Edit" },
        { Id: 460082, PropertyName: "title", Value: "Recover" },
        { Id: 376456, PropertyName: "title", Value: "Delete" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.userlist_256573_value_kuikaTableRef = React.createRef();
    this.userlist_182004_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      GetLanguage: "",
      GetUsersFirstLetter: [],
      appUsersSP: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      GoBack: "",
      SetValueOf: "",
      TriggerEvent: "",
      ReverseBool: false,
      spReactivateUser: [],
      spDeleteUser: [],
      isComp762115Visible: "hidden",
      isComp67943Visible: "hidden",
      isComp500539Visible: "hidden",
      isComp305832Visible: "hidden",
      isComp485894Visible: "hidden",
      isComp766273Visible: "hidden",
      isComp203431Visible: "hidden",
      isComp590152Visible: "hidden",
      isCompuserlist_669426AuthorizationVisible: "visible",
      isCompuserlist_931397AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("userlist", "userlist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UserListPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompuserlist_669426AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompuserlist_931397AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("userlist", "userlist");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Administrator", "Sale", "Logistic", "Quality", "Finance"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("userlist", "userlist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UserListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      userlist_500539_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined,
      userlist_342581_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UserListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserListPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UserListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_182004_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_182004_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_544338_value", "value", "", "", "")
        ),
        "string"
      ),
      showDeletedUsers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_342581_value", "value", "", "", "")
        ),
        "boolean"
      ),
      userType_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userType ?? this.props.screenInputs.usertype,
        "number"
      ),
      email_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserList/UserListPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.userlist_485894_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.appUsersSP = result?.data.appUsersSP;

    stateVars.isComp590152Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, stateVars.appUsersSP?.length > 0 ? stateVars.appUsersSP[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.userlist_500539_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp67943Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500539Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp305832Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485894Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserListPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp762115Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.userType ?? this.props.screenInputs.usertype, "1") === true
        ? "visible"
        : "hidden";
    stateVars.isComp67943Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.userlist_500539_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp500539Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp305832Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.userlist_485894_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp485894Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp766273Visible =
      ReactSystemFunctions.isNotEqualTo(this.props.screenInputs.userType ?? this.props.screenInputs.usertype, "1") ===
      true
        ? "visible"
        : "hidden";

    stateVars.dataSource_256573 = this.state.appUsersSP;
    stateVars.isComp590152Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.appUsersSP?.length > 0 ? this.state.appUsersSP[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.userlist_182004_total = ReactSystemFunctions.value(
      this,
      this.state.appUsersSP?.length > 0 ? this.state.appUsersSP[0]?.resultCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UserListComponent_762115_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UserList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "489295",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_491025_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UserList",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "623328",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_513170_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_766273_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_203431_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_182004_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_182004_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_544338_value", "value", "", "", "")
        ),
        "string"
      ),
      showDeletedUsers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_342581_value", "value", "", "", "")
        ),
        "boolean"
      ),
      userType_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userType ?? this.props.screenInputs.usertype,
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserList/UserListComponent_203431_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.appUsersSP = result?.data.appUsersSP;

    stateVars.isComp590152Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, stateVars.appUsersSP?.length > 0 ? stateVars.appUsersSP[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserListComponent_203431_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListComponent_203431_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_256573 = this.state.appUsersSP;
    formVars.userlist_182004_total = ReactSystemFunctions.value(
      this,
      this.state.appUsersSP?.length > 0 ? this.state.appUsersSP[0]?.resultCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_544338_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "userlist_182004_value", 1, "current");
    if (await this.UserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_965785_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.UserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_342581_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "userlist_182004_value", 1, "current");
    if (await this.UserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_767073_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      value_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_342581_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserList/UserListComponent_767073_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ReverseBool = result?.data.reverseBool;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserListComponent_767073_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListComponent_767073_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "userlist_342581_value",
      ReactSystemFunctions.value(this, this.state.ReverseBool),
      null
    );
    if (await this.UserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_669426_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UserEdit", "userById", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "userType",
      this.props.screenInputs.userType ?? this.props.screenInputs.usertype
    );
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "role",
      this.props.screenInputs.role ?? this.props.screenInputs.role
    );
    KuikaAppManager.addToPageInputVariables("UserEdit", "companyId", "00000000-0000-0000-0000-000000000000");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UserList",
      "UserEdit",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "384899",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_190982_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UserView",
      "userId",
      ReactSystemFunctions.value(this, "userlist_256573_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UserList",
      "UserView",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "644855",
      null,
      "right",
      null,
      "520px",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_260334_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "userById",
      ReactSystemFunctions.value(this, "userlist_256573_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "userType",
      this.props.screenInputs.userType ?? this.props.screenInputs.usertype
    );
    KuikaAppManager.addToPageInputVariables("UserEdit", "role", "");
    KuikaAppManager.addToPageInputVariables(
      "UserEdit",
      "companyId",
      ReactSystemFunctions.value(this, "userlist_256573_value", "companyID")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UserList",
      "UserEdit",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "743016",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_636528_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "userlist_256573_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserList/UserListComponent_636528_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spReactivateUser = result?.data.spReactivateUser;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.UserListComponent_636528_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListComponent_636528_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.UserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_322700_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "userlist_256573_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5e4c917d_27e7_9e27_fe2a_9d45be5ba80d_confirmation",
        this.defaultML,
        "Kullanıcı bilgisi silinecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UserList/UserListComponent_322700_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spDeleteUser = result?.data.spDeleteUser;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.UserListComponent_322700_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListComponent_322700_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.UserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_182004_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.UserListComponent_203431_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [489295, 623328, 384899, 743016] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UserListPageInit();
    }
  }
}
