import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICompanyProfileCustomer_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICompanyProfileCustomer_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetLanguage: string;
  GetCompanyById: any[];
  SqlGetCurrentCountCompanyFiltered: any[];
  spCurrentsCompanyFiltered: any[];
  spAppUsersCompanyFiltered: any[];
  GoBack: any;
  SetValueOf: any;
  TriggerEvent: string;
  NAVIGATE: any;
  isComp112309Visible: "visible" | "hidden";
  isComp484447Visible: "visible" | "hidden";
  isComp811222Visible: "visible" | "hidden";
  isComp465010Visible: "visible" | "hidden";
}

export class CompanyProfileCustomer_ScreenBase extends React.PureComponent<ICompanyProfileCustomer_ScreenProps, any> {
  companyprofilecustomer_108944_value_kuikaTableRef: React.RefObject<any>;
  companyprofilecustomer_381930_value_kuikaPaginationRef: React.RefObject<any>;
  companyprofilecustomer_390994_value_kuikaTableRef: React.RefObject<any>;
  companyprofilecustomer_884400_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 283363, PropertyName: "value", Value: "Ünvan", TagName: "Label17_0_value" },
        { Id: 411431, PropertyName: "value", Value: "Alan Kodu", TagName: "Label15_0_value" },
        { Id: 346858, PropertyName: "value", Value: "Adres", TagName: "Label14_0_value" },
        { Id: 335000, PropertyName: "value", Value: "Şehir", TagName: "Label10_0_value" },
        { Id: 861183, PropertyName: "value", Value: "Ülke", TagName: "Label10_0_value" },
        { Id: 809964, PropertyName: "value", Value: "Posta Kodu", TagName: "Label8_0_value" },
        { Id: 46652, PropertyName: "value", Value: "Şirket Bilgileri", TagName: "Label17_value" },
        {
          Id: 108944,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 751398, PropertyName: "title", Value: "Cari kart no", TagName: "TableColumn1_0_title" },
        { Id: 321433, PropertyName: "title", Value: "Cari kart ismi", TagName: "TableColumn1_1_title" },
        { Id: 775801, PropertyName: "value", Value: "Cariler", TagName: "Label15_0_value" },
        { Id: 162241, PropertyName: "value", Value: "Yetkili Kullanıcılar", TagName: "Label16_4_value" },
        { Id: 60592, PropertyName: "value", Value: "Silinen Kullanıcıları Göster", TagName: "Label16_1_value" },
        { Id: 646287, PropertyName: "value", Value: "İsim", TagName: "Label16_0_value" },
        { Id: 478147, PropertyName: "value", Value: "Soyisim", TagName: "Label16_0_value" },
        { Id: 742476, PropertyName: "value", Value: "Telefon", TagName: "Label16_0_value" },
        { Id: 511040, PropertyName: "value", Value: "E-posta", TagName: "Label16_0_value" },
        {
          Id: 390994,
          PropertyName: "nodatafoundmessage",
          Value: "Veri Bulunamadı..",
          TagName: "Table1_0_nodatafoundmessage"
        }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "27125bf9-e9b8-40b7-b884-5192f5b293b6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 46652, PropertyName: "value", Value: "Company Information" },
        { Id: 283363, PropertyName: "value", Value: "Title" },
        { Id: 346858, PropertyName: "value", Value: "Address" },
        { Id: 335000, PropertyName: "value", Value: "City" },
        { Id: 861183, PropertyName: "value", Value: "Country" },
        { Id: 809964, PropertyName: "value", Value: "Posta Code" },
        { Id: 411431, PropertyName: "value", Value: "Phone Number" },
        { Id: 775801, PropertyName: "value", Value: "Currents" },
        { Id: 108944, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 751398, PropertyName: "title", Value: "Current No" },
        { Id: 505392, PropertyName: "value", Value: "[datafield:currentno]" },
        { Id: 321433, PropertyName: "title", Value: "Current Name" },
        { Id: 692554, PropertyName: "value", Value: "[datafield:currentname]" },
        { Id: 162241, PropertyName: "value", Value: "Users" },
        { Id: 484447, PropertyName: "label", Value: "!-Trigger User List-!" },
        { Id: 60592, PropertyName: "value", Value: "Show Deactivated Users" },
        { Id: 646287, PropertyName: "value", Value: "Name" },
        { Id: 478147, PropertyName: "value", Value: "Surname" },
        { Id: 742476, PropertyName: "value", Value: "Phone Number" },
        { Id: 511040, PropertyName: "value", Value: "E-Mail" },
        { Id: 390994, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 741475, PropertyName: "value", Value: "[datafield:name]" },
        { Id: 324419, PropertyName: "value", Value: "[datafield:surname]" },
        { Id: 525004, PropertyName: "value", Value: "[datafield:formattedphone]" },
        { Id: 456933, PropertyName: "value", Value: "[datafield:email]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.companyprofilecustomer_108944_value_kuikaTableRef = React.createRef();
    this.companyprofilecustomer_381930_value_kuikaPaginationRef = React.createRef();
    this.companyprofilecustomer_390994_value_kuikaTableRef = React.createRef();
    this.companyprofilecustomer_884400_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetLanguage: "",
      GetCompanyById: [],
      SqlGetCurrentCountCompanyFiltered: [],
      spCurrentsCompanyFiltered: [],
      spAppUsersCompanyFiltered: [],
      GoBack: "",
      SetValueOf: "",
      TriggerEvent: "",
      NAVIGATE: "",
      isComp112309Visible: "hidden",
      isComp484447Visible: "hidden",
      isComp811222Visible: "hidden",
      isComp465010Visible: "hidden"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("companyprofilecustomer", "companyprofilecustomer");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CompanyProfileCustomerPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("companyprofilecustomer", "companyprofilecustomer");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Customer"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("companyprofilecustomer", "companyprofilecustomer");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CompanyProfileCustomerPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      companyprofilecustomer_773973_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CompanyProfileCustomerPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileCustomerPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CompanyProfileCustomerPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      companyId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      currentPage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofilecustomer_381930_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofilecustomer_381930_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      companyId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      currentPage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofilecustomer_884400_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofilecustomer_884400_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      showDeletedUsers_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofilecustomer_773973_value", "value", "", "", "")
        ),
        "boolean"
      ),
      userType_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userType ?? this.props.screenInputs.usertype,
        "number"
      ),
      companyId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileCustomer/CompanyProfileCustomerPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetCompanyById = result?.data.getCompanyById;
    formVars.companyprofilecustomer_203874_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.cardName : null
    );
    formVars.companyprofilecustomer_405453_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.address : null
    );
    formVars.companyprofilecustomer_939965_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.addressCity : null
    );
    formVars.companyprofilecustomer_826088_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.addressCountry : null
    );
    formVars.companyprofilecustomer_910813_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.postaCode : null
    );
    formVars.companyprofilecustomer_476976_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.formattedPhone : null
    );
    stateVars.SqlGetCurrentCountCompanyFiltered = result?.data.sqlGetCurrentCountCompanyFiltered;
    stateVars.spCurrentsCompanyFiltered = result?.data.spCurrentsCompanyFiltered;

    stateVars.spAppUsersCompanyFiltered = result?.data.spAppUsersCompanyFiltered;

    stateVars.isComp465010Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spAppUsersCompanyFiltered?.length > 0 ? stateVars.spAppUsersCompanyFiltered[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileCustomerPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileCustomerPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.companyprofilecustomer_203874_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.cardName : null
    );

    formVars.companyprofilecustomer_405453_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.address : null
    );

    formVars.companyprofilecustomer_939965_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.addressCity : null
    );

    formVars.companyprofilecustomer_826088_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.addressCountry : null
    );

    formVars.companyprofilecustomer_910813_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.postaCode : null
    );

    formVars.companyprofilecustomer_476976_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.formattedPhone : null
    );

    stateVars.dataSource_108944 = this.state.spCurrentsCompanyFiltered;
    formVars.companyprofilecustomer_381930_total = ReactSystemFunctions.value(
      this,
      this.state.SqlGetCurrentCountCompanyFiltered?.length > 0
        ? this.state.SqlGetCurrentCountCompanyFiltered[0]?.currentCount
        : null
    );

    stateVars.dataSource_390994 = this.state.spAppUsersCompanyFiltered;
    stateVars.isComp465010Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.spAppUsersCompanyFiltered?.length > 0 ? this.state.spAppUsersCompanyFiltered[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.companyprofilecustomer_884400_total = ReactSystemFunctions.value(
      this,
      this.state.spAppUsersCompanyFiltered?.length > 0 ? this.state.spAppUsersCompanyFiltered[0]?.resultCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CompanyProfileCustomerComponent_805810_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileCustomerComponent_484447_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofilecustomer_884400_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofilecustomer_884400_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      showDeletedUsers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "companyprofilecustomer_773973_value", "value", "", "", "")
        ),
        "boolean"
      ),
      userType_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userType ?? this.props.screenInputs.usertype,
        "number"
      ),
      companyId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyId ?? this.props.screenInputs.companyid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CompanyProfileCustomer/CompanyProfileCustomerComponent_484447_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spAppUsersCompanyFiltered = result?.data.spAppUsersCompanyFiltered;

    stateVars.isComp465010Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.spAppUsersCompanyFiltered?.length > 0 ? stateVars.spAppUsersCompanyFiltered[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CompanyProfileCustomerComponent_484447_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CompanyProfileCustomerComponent_484447_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_390994 = this.state.spAppUsersCompanyFiltered;
    formVars.companyprofilecustomer_884400_total = ReactSystemFunctions.value(
      this,
      this.state.spAppUsersCompanyFiltered?.length > 0 ? this.state.spAppUsersCompanyFiltered[0]?.resultCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileCustomerComponent_773973_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "companyprofilecustomer_884400_value",
      1,
      "current"
    );
    if (await this.CompanyProfileCustomerComponent_484447_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileCustomerComponent_811222_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UserView",
      "userId",
      ReactSystemFunctions.value(this, "companyprofilecustomer_390994_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "CompanyProfileCustomer",
      "UserView",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "648435",
      null,
      "right",
      null,
      "600px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CompanyProfileCustomerComponent_884400_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.CompanyProfileCustomerComponent_484447_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [648435] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.CompanyProfileCustomerPageInit();
    }
  }
}
