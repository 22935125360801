import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProductGroupEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProductGroupEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  SetValueOf: any;
  GetLanguage: string;
  SelectProductsGroupById: any[];
  SelectCertificatesForProductGroup: any[];
  ChangeVisibilityOf: string;
  IfThenElse: boolean;
  RemoveCertificatesFromProductGroupCertificatesAsDraft: number;
  AddProductGroupCertificatesIsDraft: number;
  fourCompValidation: string;
  SaveProductsGroup: number;
  UpdateDraftProductGroupCertificatesForProductGroup: number;
  Notify: boolean;
  isComp945131Visible: "visible" | "hidden";
  isComp618873Visible: "visible" | "hidden";
  isComp392185Visible: "visible" | "hidden";
  isComp548046Visible: "visible" | "hidden";
}

export class ProductGroupEdit_ScreenBase extends React.PureComponent<IProductGroupEdit_ScreenProps, any> {
  productgroupedit_802204_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      LanguagePhrases: [
        { Id: 936092, PropertyName: "value", Value: "İsim", TagName: "Label4_0_value" },
        { Id: 1901, PropertyName: "value", Value: "Açıklama", TagName: "Label4_0_value" },
        { Id: 110550, PropertyName: "value", Value: "Sertifika Seçimi", TagName: "Label4_0_value" },
        { Id: 945131, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 618873, PropertyName: "label", Value: "Kaydet", TagName: "Button2_label" },
        { Id: 392185, PropertyName: "value", Value: "Ürün Grubu Düzenle", TagName: "Label10_value" },
        { Id: 807914, PropertyName: "value", Value: "Özellikler", TagName: "Label8_value" },
        {
          Id: 802204,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 140566, PropertyName: "title", Value: "İsim", TagName: "TableColumn1_0_title" },
        { Id: 302526, PropertyName: "value", Value: "Açıklama", TagName: "Label9_value" },
        { Id: 110994, PropertyName: "value", Value: "Özellikler", TagName: "Label4_0_value" },
        { Id: 548046, PropertyName: "value", Value: "Yeni Ürün Grubu", TagName: "Label1_value" },
        { Id: 811109, PropertyName: "tab", Value: "Türkçe", TagName: "TabItem1_0_tab" },
        { Id: 142951, PropertyName: "tab", Value: "İngilizce", TagName: "TabItem1_1_tab" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "31e0be7d-0ae0-42ca-b772-6f36c1ea08e6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 392185, PropertyName: "value", Value: "Product Group Edit" },
        { Id: 548046, PropertyName: "value", Value: "New Product Group" },
        { Id: 936092, PropertyName: "value", Value: "Name" },
        { Id: 811109, PropertyName: "tab", Value: "Turkish" },
        { Id: 1901, PropertyName: "value", Value: "Description" },
        { Id: 419178, PropertyName: "placeholder", Value: "TR" },
        { Id: 807914, PropertyName: "value", Value: "Property" },
        { Id: 877456, PropertyName: "placeholder", Value: "TR" },
        { Id: 142951, PropertyName: "tab", Value: "English" },
        { Id: 302526, PropertyName: "value", Value: "Description" },
        { Id: 617503, PropertyName: "placeholder", Value: "ENG" },
        { Id: 110994, PropertyName: "value", Value: "Property" },
        { Id: 763977, PropertyName: "placeholder", Value: "ENG" },
        { Id: 110550, PropertyName: "value", Value: "Choose Certificates" },
        { Id: 802204, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 140566, PropertyName: "title", Value: "Name" },
        { Id: 77910, PropertyName: "value", Value: "[datafield:name]" },
        { Id: 945131, PropertyName: "label", Value: "Save" },
        { Id: 618873, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.productgroupedit_802204_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SetValueOf: "",
      GetLanguage: "",
      SelectProductsGroupById: [],
      SelectCertificatesForProductGroup: [],
      IfThenElse: false,
      fourCompValidation: "",
      SaveProductsGroup: 0,
      UpdateDraftProductGroupCertificatesForProductGroup: 0,
      Notify: false,
      isComp945131Visible: "visible",
      isComp618873Visible: "hidden",
      isComp392185Visible: "hidden",
      isComp548046Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("productgroupedit", "productgroupedit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProductGroupEditPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("productgroupedit", "productgroupedit");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("productgroupedit", "productgroupedit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProductGroupEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      productgroupedit_203218_value: this.state.SelectProductsGroupById?.at?.(0)?.name ?? undefined,
      productgroupedit_419178_value: this.state.SelectProductsGroupById?.at?.(0)?.descripton ?? undefined,
      productgroupedit_877456_value: this.state.SelectProductsGroupById?.at?.(0)?.properties ?? undefined,
      productgroupedit_617503_value: this.state.SelectProductsGroupById?.at?.(0)?.descriptonEN ?? undefined,
      productgroupedit_763977_value: this.state.SelectProductsGroupById?.at?.(0)?.propertiesEN ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ProductGroupEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productgroupedit_121787_value", "811109", null);
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupEditPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productGroupID ?? this.props.screenInputs.productgroupid,
        "Guid"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      productsGroupId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productGroupID ?? this.props.screenInputs.productgroupid,
        "Guid"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(1, "number"),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(20, "number")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductGroupEdit/ProductGroupEditPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectProductsGroupById = result?.data.selectProductsGroupById;
    formVars.productgroupedit_203218_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsGroupById?.length > 0 ? stateVars.SelectProductsGroupById[0]?.name : null
    );
    formVars.productgroupedit_419178_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsGroupById?.length > 0 ? stateVars.SelectProductsGroupById[0]?.descripton : null
    );
    formVars.productgroupedit_877456_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsGroupById?.length > 0 ? stateVars.SelectProductsGroupById[0]?.properties : null
    );
    formVars.productgroupedit_617503_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsGroupById?.length > 0 ? stateVars.SelectProductsGroupById[0]?.descriptonEN : null
    );
    formVars.productgroupedit_763977_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsGroupById?.length > 0 ? stateVars.SelectProductsGroupById[0]?.propertiesEN : null
    );
    stateVars.isComp392185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectProductsGroupById?.length > 0 ? stateVars.SelectProductsGroupById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp548046Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectProductsGroupById?.length > 0 ? stateVars.SelectProductsGroupById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SelectCertificatesForProductGroup = result?.data.selectCertificatesForProductGroup;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupEditPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupEditPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp392185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp548046Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.productgroupedit_203218_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.name : null
    );

    formVars.productgroupedit_419178_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.descripton : null
    );

    formVars.productgroupedit_877456_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.properties : null
    );

    formVars.productgroupedit_617503_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.descriptonEN : null
    );

    formVars.productgroupedit_763977_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.propertiesEN : null
    );

    stateVars.dataSource_802204 = this.state.SelectCertificatesForProductGroup;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProductGroupEditComponent_577437_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupEditComponent_121787_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp945131Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp618873Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProductGroupEditComponent_121787_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp618873Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp945131Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProductGroupEditComponent_121787_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_121787_value", "value", "", "", "")
        ),
        "811109"
      )
    ) {
      isErrorOccurred = await this.ProductGroupEditComponent_121787_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ProductGroupEditComponent_121787_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupEditComponent_298661_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      certificateID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "productgroupedit_802204_value", "id"),
        "Guid"
      ),
      productsGroupID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productGroupID ?? this.props.screenInputs.productgroupid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroupEdit/ProductGroupEditComponent_298661_onClick_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RemoveCertificatesFromProductGroupCertificatesAsDraft =
      result?.data.removeCertificatesFromProductGroupCertificatesAsDraft;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  ProductGroupEditComponent_298661_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      certificateID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "productgroupedit_802204_value", "id"),
        "Guid"
      ),
      productsGroupID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productGroupID ?? this.props.screenInputs.productgroupid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroupEdit/ProductGroupEditComponent_298661_onClick_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AddProductGroupCertificatesIsDraft = result?.data.addProductGroupCertificatesIsDraft;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  ProductGroupEditComponent_298661_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "productgroupedit_802204_value", "isSelected"),
        "1"
      )
    ) {
      isErrorOccurred = await this.ProductGroupEditComponent_298661_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ProductGroupEditComponent_298661_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupEditComponent_298661_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupEditComponent_298661_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      productsGroupId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productGroupID ?? this.props.screenInputs.productgroupid,
        "Guid"
      ),
      currentPage_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      pageSize_0: ReactSystemFunctions.convertToTypeByName(20, "number")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroupEdit/ProductGroupEditComponent_298661_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCertificatesForProductGroup = result?.data.selectCertificatesForProductGroup;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupEditComponent_298661_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupEditComponent_298661_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_802204 = this.state.SelectCertificatesForProductGroup;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupEditComponent_945131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_203218_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_203218_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_419178_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_419178_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_877456_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_877456_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_203218_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_419178_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdfasdfasdfasdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_877456_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroupEdit/ProductGroupEditComponent_945131_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupEditComponent_945131_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupEditComponent_945131_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productgroupedit_121787_value", "142951", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupEditComponent_945131_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupEditComponent_945131_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_419178_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_419178_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_203218_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_203218_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_877456_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_877456_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_617503_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_617503_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_763977_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_763977_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      catalogueID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.catalogueID : null
        ),
        "Guid"
      ),
      descripton_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_419178_value", "value", "", "", "")
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_203218_value", "value", "", "", "")
        ),
        "string"
      ),
      properties_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_877456_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productGroupID ?? this.props.screenInputs.productgroupid,
        "Guid"
      ),
      descriptonEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_617503_value", "value", "", "", "")
        ),
        "string"
      ),
      propertiesEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_763977_value", "value", "", "", "")
        ),
        "string"
      ),
      certificateID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.SelectCertificatesForProductGroup?.length > 0
            ? this.state.SelectCertificatesForProductGroup[0]?.id
            : null
        ),
        "Guid"
      ),
      productsGroupID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productGroupID ?? this.props.screenInputs.productgroupid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroupEdit/ProductGroupEditComponent_945131_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveProductsGroup = result?.data.saveProductsGroup;
    stateVars.UpdateDraftProductGroupCertificatesForProductGroup =
      result?.data.updateDraftProductGroupCertificatesForProductGroup;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupEditComponent_945131_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupEditComponent_945131_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "66418ac5_2069_8f9a_0bd4_0a52b0921ddf_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupEditComponent_618873_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_203218_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_203218_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_617503_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_617503_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_763977_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_763977_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_203218_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_617503_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdfasdfasdfasdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_763977_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroupEdit/ProductGroupEditComponent_618873_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupEditComponent_618873_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupEditComponent_618873_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productgroupedit_121787_value", "811109", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupEditComponent_618873_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupEditComponent_618873_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_419178_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_419178_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_203218_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_203218_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_877456_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_877456_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_617503_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_617503_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_763977_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroupedit_763977_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      catalogueID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.catalogueID : null
        ),
        "Guid"
      ),
      descripton_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_419178_value", "value", "", "", "")
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_203218_value", "value", "", "", "")
        ),
        "string"
      ),
      properties_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_877456_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productGroupID ?? this.props.screenInputs.productgroupid,
        "Guid"
      ),
      descriptonEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_617503_value", "value", "", "", "")
        ),
        "string"
      ),
      propertiesEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroupedit_763977_value", "value", "", "", "")
        ),
        "string"
      ),
      certificateID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.SelectCertificatesForProductGroup?.length > 0
            ? this.state.SelectCertificatesForProductGroup[0]?.id
            : null
        ),
        "Guid"
      ),
      productsGroupID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productGroupID ?? this.props.screenInputs.productgroupid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroupEdit/ProductGroupEditComponent_618873_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveProductsGroup = result?.data.saveProductsGroup;
    stateVars.UpdateDraftProductGroupCertificatesForProductGroup =
      result?.data.updateDraftProductGroupCertificatesForProductGroup;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupEditComponent_618873_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupEditComponent_618873_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "7ef57c19_9039_d781_8662_87f26dc71ac1_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
