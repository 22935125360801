import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISignup_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISignup_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetLanguage: string;
  languageLabelResult: string;
  Signup: any;
  goToNextScreenAfterSignup: any;
  NAVIGATE: any;
  isComp10332Visible: "visible" | "hidden";
  isComp456137Visible: "visible" | "hidden";
}

export class Signup_ScreenBase extends React.PureComponent<ISignup_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 621849, PropertyName: "value", Value: "Hesap Oluştur", TagName: "Label5_value" },
        { Id: 875986, PropertyName: "value", Value: "Hadi Başlayalım", TagName: "Label6_value" },
        { Id: 188304, PropertyName: "value", Value: "İsim", TagName: "Label9_value" },
        { Id: 385602, PropertyName: "value", Value: "Şifre", TagName: "Label11_value" },
        { Id: 609753, PropertyName: "value", Value: "Şunları kabul ediyorsunuz", TagName: "Label5_value" },
        { Id: 583993, PropertyName: "value", Value: "Kullanım Koşulları", TagName: "Label6_value" },
        { Id: 605356, PropertyName: "value", Value: "ve", TagName: "Label7_value" },
        { Id: 795394, PropertyName: "value", Value: "Gizlilik Politikası", TagName: "Label8_value" },
        { Id: 895473, PropertyName: "label", Value: "Kayıt Ol", TagName: "Button1_label" },
        { Id: 312523, PropertyName: "value", Value: "Zaten hesabınız var mı?", TagName: "Label2_value" },
        { Id: 937140, PropertyName: "value", Value: "Giriş Yap", TagName: "Label3_value" },
        { Id: 102203, PropertyName: "value", Value: "Eposta", TagName: "Label10_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "d6b44587-613c-4281-8ace-3f4a3fe16d7c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 621849, PropertyName: "value", Value: "Create an account" },
        { Id: 875986, PropertyName: "value", Value: "Let's get started" },
        { Id: 188304, PropertyName: "value", Value: "Name" },
        { Id: 102203, PropertyName: "value", Value: "E-mail" },
        { Id: 385602, PropertyName: "value", Value: "Password" },
        { Id: 609753, PropertyName: "value", Value: "You are agreeing to the" },
        { Id: 583993, PropertyName: "value", Value: "Terms of Services" },
        { Id: 605356, PropertyName: "value", Value: "and" },
        { Id: 795394, PropertyName: "value", Value: "Privacy Policy" },
        { Id: 895473, PropertyName: "label", Value: "Get Started" },
        { Id: 312523, PropertyName: "value", Value: "Already a member ?" },
        { Id: 937140, PropertyName: "value", Value: "Sign in" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetLanguage: "",
      languageLabelResult: "",
      Signup: "",
      goToNextScreenAfterSignup: "",
      NAVIGATE: "",
      isComp10332Visible: "hidden",
      isComp456137Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("signup", "signup");
      return;
    }

    if (true) {
      await this.SignupPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("signup", "signup");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("signup", "signup");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SignupPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SignupPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SignupPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SignupPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.languageLabelResult = result?.data.languageLabelResult;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SignupComponent_895473_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_890562_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_890562_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Signup = (
      (await ReactSystemFunctions.signup(
        this,
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_890562_value", "value", "", "", "")),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_497780_value", "value", "", "", "")),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_219201_value", "value", "", "", "")),
        "",
        true,
        null,
        null,
        null
      )) as any
    ).data;
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterSignup(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_937140_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Signup",
      "Signin",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
