import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProductGroupView_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProductGroupView_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  SelectProductsGroupById: any[];
  GetCertificatesOfProductGroup: any[];
}

export class ProductGroupView_ScreenBase extends React.PureComponent<IProductGroupView_ScreenProps, any> {
  productgroupview_610174_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 92994, PropertyName: "value", Value: "İsim", TagName: "Label0_0_value" },
        { Id: 614633, PropertyName: "value", Value: "Açıklama", TagName: "Label0_0_value" },
        { Id: 912470, PropertyName: "value", Value: "Özellikler", TagName: "Label0_0_value" },
        { Id: 6374, PropertyName: "value", Value: "Ürün Grubu", TagName: "Label11_value" },
        { Id: 422020, PropertyName: "value", Value: "Sertifikalar", TagName: "Label12_value" },
        {
          Id: 610174,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "a77e33ee-a221-4a58-b8a4-1f3bac8ff4d5",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 6374, PropertyName: "value", Value: "Product Group" },
        { Id: 92994, PropertyName: "value", Value: "Name" },
        { Id: 614633, PropertyName: "value", Value: "Description" },
        { Id: 912470, PropertyName: "value", Value: "Property" },
        { Id: 422020, PropertyName: "value", Value: "Certificates" },
        { Id: 610174, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 552503, PropertyName: "value", Value: "[datafield:name]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.productgroupview_610174_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      SelectProductsGroupById: [],
      GetCertificatesOfProductGroup: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("productgroupview", "productgroupview");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProductGroupViewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("productgroupview", "productgroupview");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("productgroupview", "productgroupview");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProductGroupViewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ProductGroupViewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupViewPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupViewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productGroupID ?? this.props.screenInputs.productgroupid,
        "Guid"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      productsGroupID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productGroupID ?? this.props.screenInputs.productgroupid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductGroupView/ProductGroupViewPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectProductsGroupById = result?.data.selectProductsGroupById;
    formVars.productgroupview_312137_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsGroupById?.length > 0 ? stateVars.SelectProductsGroupById[0]?.name : null
    );
    formVars.productgroupview_496369_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsGroupById?.length > 0 ? stateVars.SelectProductsGroupById[0]?.description_Label : null
    );
    formVars.productgroupview_447675_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsGroupById?.length > 0 ? stateVars.SelectProductsGroupById[0]?.properties_Label : null
    );
    stateVars.GetCertificatesOfProductGroup = result?.data.getCertificatesOfProductGroup;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupViewPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupViewPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.productgroupview_312137_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.name : null
    );

    formVars.productgroupview_496369_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.description_Label : null
    );

    formVars.productgroupview_447675_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsGroupById?.length > 0 ? this.state.SelectProductsGroupById[0]?.properties_Label : null
    );

    stateVars.dataSource_610174 = this.state.GetCertificatesOfProductGroup;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProductGroupViewComponent_986771_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
