import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISurveyDetails_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISurveyDetails_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  spGetSurveyAnswers: any[];
  spGetSurveyDetails: any[];
  spGetOrderDetailsById: any[];
  GetOrderList: any[];
  GoBack: any;
  isCompsurveydetails_845843AuthorizationEnabled: "enabled" | "disabled";
}

export class SurveyDetails_ScreenBase extends React.PureComponent<ISurveyDetails_ScreenProps, any> {
  surveydetails_196551_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        {
          Id: 196551,
          PropertyName: "nodatafoundmessage",
          Value: "Veri Bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 142743, PropertyName: "placeholder", Value: "Yazınız...", TagName: "TextArea1_placeholder" },
        { Id: 243559, PropertyName: "value", Value: "Müşteri", TagName: "label0_0_value" },
        { Id: 137581, PropertyName: "value", Value: "Müşteri Ref. No", TagName: "label0_0_value" },
        { Id: 968542, PropertyName: "value", Value: "Cari No", TagName: "label0_0_value" },
        { Id: 381391, PropertyName: "value", Value: "Sipariş No", TagName: "label0_0_value" },
        { Id: 253933, PropertyName: "value", Value: "Sipariş Tarihi", TagName: "label0_0_value" },
        { Id: 34882, PropertyName: "value", Value: "Müşteri Memnuniyet Anketi Detayları", TagName: "label10_0_value" },
        { Id: 797250, PropertyName: "value", Value: "Sorular ve Cevaplar", TagName: "label11_0_value" },
        { Id: 398132, PropertyName: "value", Value: "Tamamlayan İsmi", TagName: "label12_0_value" },
        { Id: 751502, PropertyName: "value", Value: "Tamamlaya E-posta", TagName: "label12_0_value" },
        { Id: 449716, PropertyName: "value", Value: "Tamamlanma Tarihi", TagName: "label17_0_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "d0f90d28-a22e-48dc-8dce-98d574b3a932",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 34882, PropertyName: "value", Value: "Customer Satisfaction Survey Details" },
        { Id: 243559, PropertyName: "value", Value: "Customer" },
        { Id: 137581, PropertyName: "value", Value: "Customer Ref No" },
        { Id: 968542, PropertyName: "value", Value: "Card Number" },
        { Id: 381391, PropertyName: "value", Value: "Order Number" },
        { Id: 253933, PropertyName: "value", Value: "Order Date" },
        { Id: 398132, PropertyName: "value", Value: "Completer Name" },
        { Id: 751502, PropertyName: "value", Value: "Completer email" },
        { Id: 449716, PropertyName: "value", Value: "Send Date" },
        { Id: 302673, PropertyName: "value", Value: "Completion Date" },
        { Id: 797250, PropertyName: "value", Value: "Questions and Answers" },
        { Id: 196551, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 190229, PropertyName: "value", Value: "[datafield:numberedquestion]" },
        { Id: 142743, PropertyName: "placeholder", Value: "Type here..." }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.surveydetails_196551_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      spGetSurveyAnswers: [],
      spGetSurveyDetails: [],
      spGetOrderDetailsById: [],
      GetOrderList: [],
      GoBack: "",
      isCompsurveydetails_845843AuthorizationEnabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("surveydetails", "surveydetails");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SurveyDetailsPageInit();
    }
    ReactSystemFunctions.disableIsNotAuthorized(this, "isCompsurveydetails_845843AuthorizationEnabled", []);

    KuikaAppManager.calculateAndSetBodyHeight("surveydetails", "surveydetails");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("surveydetails", "surveydetails");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SurveyDetailsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SurveyDetailsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      surveyId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      ),
      surveyId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      ),
      CustomerCardName_3: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SurveyDetails/SurveyDetailsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetSurveyAnswers = result?.data.spGetSurveyAnswers;

    stateVars.spGetSurveyDetails = result?.data.spGetSurveyDetails;
    formVars.surveydetails_399317_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetSurveyDetails?.length > 0 ? stateVars.spGetSurveyDetails[0]?.userFullName : null
    );
    formVars.surveydetails_930673_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetSurveyDetails?.length > 0 ? stateVars.spGetSurveyDetails[0]?.email : null
    );
    formVars.surveydetails_984906_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetSurveyDetails?.length > 0 ? stateVars.spGetSurveyDetails[0]?.sentDate : null
    );
    formVars.surveydetails_961511_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetSurveyDetails?.length > 0 ? stateVars.spGetSurveyDetails[0]?.completionDate : null
    );
    stateVars.spGetOrderDetailsById = result?.data.spGetOrderDetailsById;
    stateVars.GetOrderList = result?.data.getOrderList;
    formVars.surveydetails_600680_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardname : null
    );
    formVars.surveydetails_729512_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.custrefno : null
    );
    formVars.surveydetails_331875_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardcode : null
    );
    formVars.surveydetails_978606_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docnum : null
    );
    formVars.surveydetails_25741_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.SurveyDetailsPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SurveyDetailsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.surveydetails_600680_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
    );

    formVars.surveydetails_729512_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
    );

    formVars.surveydetails_331875_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardcode : null
    );

    formVars.surveydetails_978606_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docnum : null
    );

    formVars.surveydetails_25741_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    formVars.surveydetails_399317_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetSurveyDetails?.length > 0 ? this.state.spGetSurveyDetails[0]?.userFullName : null
    );

    formVars.surveydetails_930673_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetSurveyDetails?.length > 0 ? this.state.spGetSurveyDetails[0]?.email : null
    );

    formVars.surveydetails_984906_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetSurveyDetails?.length > 0 ? this.state.spGetSurveyDetails[0]?.sentDate : null
    );

    formVars.surveydetails_961511_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetSurveyDetails?.length > 0 ? this.state.spGetSurveyDetails[0]?.completionDate : null
    );

    stateVars.dataSource_196551 = this.state.spGetSurveyAnswers;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SurveyDetailsComponent_671628_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
