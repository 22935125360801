import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IVehicleRequestedOrdersList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IVehicleRequestedOrdersList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  SetLanguage: string;
  GetLanguage: string;
  Logout: boolean;
  goToSignInActivity: any;
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  spGetCurrentCompanyId: any[];
  getVehicleStatusListForFilter: any[];
  getCountOfOrdersByVehicleStatus: any[];
  TriggerEvent: string;
  SetValueOf: any;
  getCountOfVehicleRequestsOfSupplier: any[];
  getVehicleRequestsOfSupplier: any[];
  isComp592405Visible: "visible" | "hidden";
  isComp75168Visible: "visible" | "hidden";
  isComp133881Visible: "visible" | "hidden";
  isComp634824Visible: "visible" | "hidden";
  isComp284309Visible: "visible" | "hidden";
  isComp956783Visible: "visible" | "hidden";
  isComp601503Visible: "visible" | "hidden";
  isComp133280Visible: "visible" | "hidden";
  isComp228903Visible: "visible" | "hidden";
  isCompvehiclerequestedorderslist_427723AuthorizationVisible: "visible" | "hidden";
}

export class VehicleRequestedOrdersList_ScreenBase extends React.PureComponent<
  IVehicleRequestedOrdersList_ScreenProps,
  any
> {
  vehiclerequestedorderslist_225422_value_kuikaSelectBoxRef: React.RefObject<any>;
  vehiclerequestedorderslist_654017_value_kuikaDateRef: React.RefObject<any>;
  vehiclerequestedorderslist_696651_value_kuikaTableRef: React.RefObject<any>;
  vehiclerequestedorderslist_934790_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 71816, PropertyName: "placeholder", Value: "Ara...", TagName: "TextInput1_placeholder" },
        {
          Id: 696651,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 846713, PropertyName: "title", Value: "Sipariş Numarası", TagName: "TableColumn1_0_title" },
        { Id: 154367, PropertyName: "title", Value: "Müşteri", TagName: "TableColumn1_2_title" },
        { Id: 456316, PropertyName: "value", Value: "Araç Talepleri", TagName: "Label2_0_value" },
        { Id: 719758, PropertyName: "title", Value: "Sipariş Tarihi", TagName: "TableColumn6_title" },
        { Id: 201032, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 833959, PropertyName: "label", Value: "Çıkış", TagName: "ContentMenuItem2_2_label" },
        { Id: 198673, PropertyName: "title", Value: "Durum", TagName: "TableColumn7_title" },
        { Id: 730538, PropertyName: "title", Value: "Talep Eden", TagName: "TableColumn8_title" },
        { Id: 281326, PropertyName: "value", Value: "Şimdiye Kadar", TagName: "Label2_0_value" },
        { Id: 340627, PropertyName: "value", Value: "Talep Edilen", TagName: "Label2_1_value" },
        { Id: 463191, PropertyName: "value", Value: "Onay Bekleyen", TagName: "Label2_1_value" },
        { Id: 356288, PropertyName: "value", Value: "Onaylanan", TagName: "Label2_1_value" },
        { Id: 54404, PropertyName: "value", Value: "Yüklenen", TagName: "Label2_1_value" },
        { Id: 902196, PropertyName: "value", Value: "Yola Çıkan", TagName: "Label2_1_value" },
        { Id: 533903, PropertyName: "value", Value: "Teslim Edilen", TagName: "Label2_1_value" },
        { Id: 124830, PropertyName: "value", Value: "Filtre", TagName: "Label2_0_value" },
        { Id: 225422, PropertyName: "placeholder", Value: "Durum Seçiniz...", TagName: "SelectBox0_0_placeholder" },
        { Id: 935216, PropertyName: "value", Value: "Toplam Kayıt Sayısı :", TagName: "Label2_0_value" },
        { Id: 750356, PropertyName: "title", Value: "Konumlar", TagName: "Tooltip1_title" },
        { Id: 599150, PropertyName: "title", Value: "Araç Ata", TagName: "Tooltip2_title" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "5b0f626d-7a4d-4fba-a3dc-b2227153d7f6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 592405, PropertyName: "value", Value: "TR" },
        { Id: 75168, PropertyName: "value", Value: "TR" },
        { Id: 653608, PropertyName: "value", Value: "|" },
        { Id: 133881, PropertyName: "value", Value: "EN" },
        { Id: 634824, PropertyName: "value", Value: "EN" },
        { Id: 201032, PropertyName: "label", Value: "Profile" },
        { Id: 833959, PropertyName: "label", Value: "Logout" },
        { Id: 456316, PropertyName: "value", Value: "Vehicle Requests" },
        { Id: 228903, PropertyName: "label", Value: "pageinittrigger" },
        { Id: 281326, PropertyName: "value", Value: "Up to Now" },
        { Id: 340627, PropertyName: "value", Value: "Requested" },
        { Id: 463191, PropertyName: "value", Value: "Waiting for Approval" },
        { Id: 356288, PropertyName: "value", Value: "Approved" },
        { Id: 54404, PropertyName: "value", Value: "Loaded" },
        { Id: 902196, PropertyName: "value", Value: "Out of Delivery" },
        { Id: 533903, PropertyName: "value", Value: "Delivered" },
        { Id: 124830, PropertyName: "value", Value: "Filter Records" },
        { Id: 225422, PropertyName: "placeholder", Value: "Choose Status..." },
        { Id: 654017, PropertyName: "placeholder", Value: "Select Order Date..." },
        { Id: 71816, PropertyName: "placeholder", Value: "Search.." },
        { Id: 696651, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 154367, PropertyName: "title", Value: "Customer Name" },
        { Id: 469709, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 846713, PropertyName: "title", Value: "Order Number" },
        { Id: 494669, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 719758, PropertyName: "title", Value: "Order Date" },
        { Id: 744865, PropertyName: "value", Value: "[datafield:orderdate]" },
        { Id: 730538, PropertyName: "title", Value: "Created By" },
        { Id: 477924, PropertyName: "value", Value: "[datafield:creatorfullname]" },
        { Id: 198673, PropertyName: "title", Value: "Status" },
        { Id: 494963, PropertyName: "value", Value: "[datafield:orderstatusdesc]" },
        { Id: 750356, PropertyName: "title", Value: "Locations" },
        { Id: 599150, PropertyName: "title", Value: "Assign Vehicle" },
        { Id: 935216, PropertyName: "value", Value: "Total Records Count :" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.vehiclerequestedorderslist_225422_value_kuikaSelectBoxRef = React.createRef();
    this.vehiclerequestedorderslist_654017_value_kuikaDateRef = React.createRef();
    this.vehiclerequestedorderslist_696651_value_kuikaTableRef = React.createRef();
    this.vehiclerequestedorderslist_934790_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      SetLanguage: "",
      GetLanguage: "",
      Logout: false,
      goToSignInActivity: "",
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      spGetCurrentCompanyId: [],
      getVehicleStatusListForFilter: [],
      getCountOfOrdersByVehicleStatus: [],
      TriggerEvent: "",
      SetValueOf: "",
      getCountOfVehicleRequestsOfSupplier: [],
      getVehicleRequestsOfSupplier: [],
      isComp592405Visible: "hidden",
      isComp75168Visible: "hidden",
      isComp133881Visible: "hidden",
      isComp634824Visible: "hidden",
      isComp284309Visible: "hidden",
      isComp956783Visible: "hidden",
      isComp601503Visible: "hidden",
      isComp133280Visible: "hidden",
      isComp228903Visible: "hidden",
      isCompvehiclerequestedorderslist_427723AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("vehiclerequestedorderslist", "vehiclerequestedorderslist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.VehicleRequestedOrdersListPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompvehiclerequestedorderslist_427723AuthorizationVisible", [
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "LogisticSupplier"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("vehiclerequestedorderslist", "vehiclerequestedorderslist");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["LogisticSupplier"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("vehiclerequestedorderslist", "vehiclerequestedorderslist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.VehicleRequestedOrdersListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      vehiclerequestedorderslist_956783_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  VehicleRequestedOrdersListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp592405Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp75168Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp133881Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp634824Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.VehicleRequestedOrdersListPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  VehicleRequestedOrdersListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      lang_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      customsAgentId_5: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      distributorID_5: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VehicleRequestedOrdersList/VehicleRequestedOrdersListPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.vehiclerequestedorderslist_133280_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.vehiclerequestedorderslist_956783_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp284309Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp956783Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp601503Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp133280Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spGetCurrentCompanyId = result?.data.spGetCurrentCompanyId;
    stateVars.getVehicleStatusListForFilter = result?.data.getVehicleStatusListForFilter;

    formVars.vehiclerequestedorderslist_225422_options = stateVars.getVehicleStatusListForFilter;
    stateVars.getCountOfOrdersByVehicleStatus = result?.data.getCountOfOrdersByVehicleStatus;
    formVars.vehiclerequestedorderslist_475972_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.requested
        : null
    );
    formVars.vehiclerequestedorderslist_680640_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.waitingForApproval
        : null
    );
    formVars.vehiclerequestedorderslist_504442_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.approved
        : null
    );
    formVars.vehiclerequestedorderslist_990457_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.loaded
        : null
    );
    formVars.vehiclerequestedorderslist_349664_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.outOfDelivery
        : null
    );
    formVars.vehiclerequestedorderslist_178580_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.delivered
        : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.VehicleRequestedOrdersListPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VehicleRequestedOrdersListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.VehicleRequestedOrdersListComponent_228903_onClick()) return true;

    stateVars.isComp592405Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp75168Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp133881Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp634824Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp284309Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.vehiclerequestedorderslist_956783_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp956783Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp601503Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.vehiclerequestedorderslist_133280_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp133280Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.vehiclerequestedorderslist_475972_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.requested
        : null
    );

    formVars.vehiclerequestedorderslist_680640_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.waitingForApproval
        : null
    );

    formVars.vehiclerequestedorderslist_504442_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.approved
        : null
    );

    formVars.vehiclerequestedorderslist_990457_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.loaded
        : null
    );

    formVars.vehiclerequestedorderslist_349664_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.outOfDelivery
        : null
    );

    formVars.vehiclerequestedorderslist_178580_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.delivered
        : null
    );

    stateVars.dataSource_225422 = this.state.getVehicleStatusListForFilter;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_401583_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VehicleRequestedOrdersList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "505387",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_592405_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp592405Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp75168Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp133881Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp634824Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_133881_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "en_US");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp592405Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp75168Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp133881Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp634824Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_201032_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VehicleRequestedOrdersList",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "106589",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_833959_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_228903_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "vehiclerequestedorderslist_934790_value",
      1,
      null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.VehicleRequestedOrdersListComponent_228903_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  VehicleRequestedOrdersListComponent_228903_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      logisticSupplierId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetCurrentCompanyId?.length > 0 ? this.state.spGetCurrentCompanyId[0]?.id : null
        ),
        "Guid"
      ),
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vehiclerequestedorderslist_71816_value", "value", "", "", "")
        ),
        "string"
      ),
      vehicleStatus_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "vehiclerequestedorderslist_225422_value",
            "value",
            "getVehicleStatusListForFilter",
            "status",
            "status"
          )
        ),
        "number"
      ),
      orderAtDateFilter_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vehiclerequestedorderslist_654017_value", "value", "", "", "")
        ),
        "Date"
      ),
      logisticSupplierId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetCurrentCompanyId?.length > 0 ? this.state.spGetCurrentCompanyId[0]?.id : null
        ),
        "Guid"
      ),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(20, "number"),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(1, "number"),
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vehiclerequestedorderslist_71816_value", "value", "", "", "")
        ),
        "string"
      ),
      vehicleStatus_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "vehiclerequestedorderslist_225422_value",
            "value",
            "getVehicleStatusListForFilter",
            "status",
            "status"
          )
        ),
        "number"
      ),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      orderAtDateFilter_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vehiclerequestedorderslist_654017_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VehicleRequestedOrdersList/VehicleRequestedOrdersListComponent_228903_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCountOfVehicleRequestsOfSupplier = result?.data.getCountOfVehicleRequestsOfSupplier;
    formVars.vehiclerequestedorderslist_799660_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfVehicleRequestsOfSupplier?.length > 0
        ? stateVars.getCountOfVehicleRequestsOfSupplier[0]?.recordCount
        : null
    );
    stateVars.getVehicleRequestsOfSupplier = result?.data.getVehicleRequestsOfSupplier;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.VehicleRequestedOrdersListComponent_228903_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VehicleRequestedOrdersListComponent_228903_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_696651 = this.state.getVehicleRequestsOfSupplier;
    formVars.vehiclerequestedorderslist_799660_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfVehicleRequestsOfSupplier?.length > 0
        ? this.state.getCountOfVehicleRequestsOfSupplier[0]?.recordCount
        : null
    );

    formVars.vehiclerequestedorderslist_934790_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfVehicleRequestsOfSupplier?.length > 0
        ? this.state.getCountOfVehicleRequestsOfSupplier[0]?.recordCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_641255_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "vehiclerequestedorderslist_225422_value",
      "0",
      null,
      "status"
    );
    if (await this.VehicleRequestedOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_182025_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "vehiclerequestedorderslist_225422_value",
      "20",
      null,
      "status"
    );
    if (await this.VehicleRequestedOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_290695_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "vehiclerequestedorderslist_225422_value",
      "30",
      null,
      "status"
    );
    if (await this.VehicleRequestedOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_407346_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "vehiclerequestedorderslist_225422_value",
      "40",
      null,
      "status"
    );
    if (await this.VehicleRequestedOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_58934_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "vehiclerequestedorderslist_225422_value",
      "50",
      null,
      "status"
    );
    if (await this.VehicleRequestedOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_165477_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "vehiclerequestedorderslist_225422_value",
      "60",
      null,
      "status"
    );
    if (await this.VehicleRequestedOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_225422_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.VehicleRequestedOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_654017_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.VehicleRequestedOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_71816_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.VehicleRequestedOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_827262_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.VehicleRequestedOrdersListComponent_228903_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_61359_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AddVehicleLocation",
      "vehicleId",
      ReactSystemFunctions.value(this, "vehiclerequestedorderslist_696651_value", "vrid")
    );
    KuikaAppManager.addToPageInputVariables(
      "AddVehicleLocation",
      "orderNo",
      ReactSystemFunctions.value(this, "vehiclerequestedorderslist_696651_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "AddVehicleLocation",
      "cardNo",
      ReactSystemFunctions.value(this, "vehiclerequestedorderslist_696651_value", "cardCode")
    );
    KuikaAppManager.addToPageInputVariables(
      "AddVehicleLocation",
      "custRefNo",
      ReactSystemFunctions.value(this, "vehiclerequestedorderslist_696651_value", "custRefNo")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VehicleRequestedOrdersList",
      "AddVehicleLocation",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "397395",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_427723_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AssignVehicle",
      "orderNo",
      ReactSystemFunctions.value(this, "vehiclerequestedorderslist_696651_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "AssignVehicle",
      "vehicleId",
      ReactSystemFunctions.value(this, "vehiclerequestedorderslist_696651_value", "vrid")
    );
    KuikaAppManager.addToPageInputVariables(
      "AssignVehicle",
      "cardCode",
      ReactSystemFunctions.value(this, "vehiclerequestedorderslist_696651_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VehicleRequestedOrdersList",
      "AssignVehicle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "169204",
      null,
      "right",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_247973_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "vehiclerequestedorderslist_696651_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "vehiclerequestedorderslist_696651_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VehicleRequestedOrdersList",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VehicleRequestedOrdersListComponent_934790_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      logisticSupplierId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetCurrentCompanyId?.length > 0 ? this.state.spGetCurrentCompanyId[0]?.id : null
        ),
        "Guid"
      ),
      search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vehiclerequestedorderslist_71816_value", "value", "", "", "")
        ),
        "string"
      ),
      vehicleStatus_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "vehiclerequestedorderslist_225422_value",
            "value",
            "getVehicleStatusListForFilter",
            "status",
            "status"
          )
        ),
        "number"
      ),
      orderAtDateFilter_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vehiclerequestedorderslist_654017_value", "value", "", "", "")
        ),
        "Date"
      ),
      logisticSupplierId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetCurrentCompanyId?.length > 0 ? this.state.spGetCurrentCompanyId[0]?.id : null
        ),
        "Guid"
      ),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vehiclerequestedorderslist_934790_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vehiclerequestedorderslist_934790_value", "current", "", "", "")
        ),
        "number"
      ),
      search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vehiclerequestedorderslist_71816_value", "value", "", "", "")
        ),
        "string"
      ),
      vehicleStatus_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "vehiclerequestedorderslist_225422_value",
            "value",
            "getVehicleStatusListForFilter",
            "status",
            "status"
          )
        ),
        "number"
      ),
      lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      orderAtDateFilter_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vehiclerequestedorderslist_654017_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VehicleRequestedOrdersList/VehicleRequestedOrdersListComponent_934790_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCountOfVehicleRequestsOfSupplier = result?.data.getCountOfVehicleRequestsOfSupplier;
    formVars.vehiclerequestedorderslist_799660_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfVehicleRequestsOfSupplier?.length > 0
        ? stateVars.getCountOfVehicleRequestsOfSupplier[0]?.recordCount
        : null
    );
    stateVars.getVehicleRequestsOfSupplier = result?.data.getVehicleRequestsOfSupplier;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.VehicleRequestedOrdersListComponent_934790_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VehicleRequestedOrdersListComponent_934790_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_696651 = this.state.getVehicleRequestsOfSupplier;
    formVars.vehiclerequestedorderslist_799660_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfVehicleRequestsOfSupplier?.length > 0
        ? this.state.getCountOfVehicleRequestsOfSupplier[0]?.recordCount
        : null
    );

    formVars.vehiclerequestedorderslist_934790_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfVehicleRequestsOfSupplier?.length > 0
        ? this.state.getCountOfVehicleRequestsOfSupplier[0]?.recordCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [505387, 106589, 397395, 169204] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.VehicleRequestedOrdersListPageInit();
    }
  }
}
