import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IQuestionEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IQuestionEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  SetValueOf: any;
  GetQuestionById: any[];
  ChangeVisibilityOf: string;
  IfThenElse: boolean;
  fourCompValidation: string;
  Notify: boolean;
  SaveSurveyQuestion: number;
  isComp915194Visible: "visible" | "hidden";
  isComp702479Visible: "visible" | "hidden";
}

export class QuestionEdit_ScreenBase extends React.PureComponent<IQuestionEdit_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 212161, PropertyName: "value", Value: "Soruyu Düzenle", TagName: "Label1_0_value" },
        { Id: 438233, PropertyName: "tab", Value: "Türkçe", TagName: "TabItem1_0_tab" },
        { Id: 306409, PropertyName: "value", Value: "Soru", TagName: "Label2_value" },
        { Id: 846708, PropertyName: "placeholder", Value: "Yazınız..", TagName: "TextArea1_1_placeholder" },
        { Id: 915194, PropertyName: "label", Value: "Kaydet", TagName: "save_TR_label" },
        { Id: 519312, PropertyName: "tab", Value: "İngilizce", TagName: "TabItem1_1_tab" },
        { Id: 301068, PropertyName: "placeholder", Value: "Yazınız..", TagName: "TextArea2_placeholder" },
        { Id: 702479, PropertyName: "label", Value: "Kaydet", TagName: "save_EN_label" },
        { Id: 775421, PropertyName: "value", Value: "Soru", TagName: "Label3_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "ab8ef5af-062a-40e0-8d11-9288ae509b42",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 212161, PropertyName: "value", Value: "Edit Question" },
        { Id: 438233, PropertyName: "tab", Value: "Turkish" },
        { Id: 306409, PropertyName: "value", Value: "Question" },
        { Id: 846708, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 519312, PropertyName: "tab", Value: "English" },
        { Id: 775421, PropertyName: "value", Value: "Question" },
        { Id: 301068, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 915194, PropertyName: "label", Value: "Save" },
        { Id: 702479, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      SetValueOf: "",
      GetQuestionById: [],
      IfThenElse: false,
      fourCompValidation: "",
      Notify: false,
      SaveSurveyQuestion: 0,
      isComp915194Visible: "visible",
      isComp702479Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("questionedit", "questionedit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.questionEditPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("questionedit", "questionedit");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("questionedit", "questionedit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.questionEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      questionedit_846708_value: this.state.GetQuestionById?.at?.(0)?.question ?? undefined,
      questionedit_301068_value: this.state.GetQuestionById?.at?.(0)?.questionEN ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  questionEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "questionedit_590353_value", "438233", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.questionEditPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  questionEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "questionEdit/questionEditPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetQuestionById = result?.data.getQuestionById;
    formVars.questionedit_846708_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetQuestionById?.length > 0 ? stateVars.GetQuestionById[0]?.question : null
    );
    formVars.questionedit_301068_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetQuestionById?.length > 0 ? stateVars.GetQuestionById[0]?.questionEN : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.questionEditPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  questionEditPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.questionedit_846708_value = ReactSystemFunctions.toString(
      this,
      this.state.GetQuestionById?.length > 0 ? this.state.GetQuestionById[0]?.question : null
    );

    formVars.questionedit_301068_value = ReactSystemFunctions.toString(
      this,
      this.state.GetQuestionById?.length > 0 ? this.state.GetQuestionById[0]?.questionEN : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  questionEditComponent_181446_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  questionEditComponent_590353_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp915194Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp702479Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  questionEditComponent_590353_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp915194Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp702479Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  questionEditComponent_590353_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "questionedit_590353_value", "value", "", "", "")
        ),
        "438233"
      )
    ) {
      isErrorOccurred = await this.questionEditComponent_590353_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.questionEditComponent_590353_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  questionEditComponent_915194_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_846708_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "questionedit_846708_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_846708_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdfasdfasd", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "questionEdit/questionEditComponent_915194_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.questionEditComponent_915194_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  questionEditComponent_915194_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "questionedit_590353_value", "519312", null);
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "d7824a3c_0b66_bf3e_8270_5aa79e48fbfb_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.questionEditComponent_915194_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  questionEditComponent_915194_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_846708_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "questionedit_846708_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_301068_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "questionedit_301068_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      question_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_846708_value", "value", "", "", "")
        ),
        "string"
      ),
      questionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_301068_value", "value", "", "", "")
        ),
        "string"
      ),
      updatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      updatedDate_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "questionEdit/questionEditComponent_915194_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveSurveyQuestion = result?.data.saveSurveyQuestion;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.questionEditComponent_915194_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  questionEditComponent_915194_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  questionEditComponent_702479_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_301068_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "questionedit_301068_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_301068_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdfasdfasd", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "questionEdit/questionEditComponent_702479_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.questionEditComponent_702479_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  questionEditComponent_702479_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "questionedit_590353_value", "438233", null);
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "ee5564aa_d8dd_307d_e2e9_3503abe6b9d9_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.questionEditComponent_702479_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  questionEditComponent_702479_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_846708_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "questionedit_846708_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_301068_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "questionedit_301068_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      question_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_846708_value", "value", "", "", "")
        ),
        "string"
      ),
      questionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "questionedit_301068_value", "value", "", "", "")
        ),
        "string"
      ),
      updatedBy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      updatedDate_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.surveyId ?? this.props.screenInputs.surveyid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "questionEdit/questionEditComponent_702479_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveSurveyQuestion = result?.data.saveSurveyQuestion;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.questionEditComponent_702479_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  questionEditComponent_702479_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
