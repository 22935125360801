import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KContentMenu,
  KContentMenuItem,
  KContentMenuPanel,
  KDrawer,
  KPanel,
  KRow,
  KTab,
  KTabItem,
  Label,
  TextArea,
  TextInput,
  VerticalStack
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { CompanyProfileEdit_ScreenBase } from "./companyprofileedit-base";

import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { Profile_Screen } from "../profile/profile";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class CompanyProfileEdit_Screen extends CompanyProfileEdit_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              companyprofileedit_308785_value: "d904a563-d1a7-4126-38a8-08dac24f7806",
              companyprofileedit_370687_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined,
              companyprofileedit_114630_value: undefined,
              companyprofileedit_31658600_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.logo ?? undefined,
              companyprofileedit_276161_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.title ?? undefined,
              companyprofileedit_31658230_value:
                this.state.SelectCompanyProfileByKey?.at?.(0)?.visionImage ?? undefined,
              companyprofileedit_114611_value: undefined,
              companyprofileedit_500144_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.vision ?? undefined,
              companyprofileedit_596414_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.visionEN ?? undefined,
              companyprofileedit_31658240_value:
                this.state.SelectCompanyProfileByKey?.at?.(0)?.missionImage ?? undefined,
              companyprofileedit_435611_value: undefined,
              companyprofileedit_181411_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.mission ?? undefined,
              companyprofileedit_529767_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.missionEN ?? undefined,
              companyprofileedit_31658450_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.address ?? undefined,
              companyprofileedit_31658460_value:
                this.state.SelectCompanyProfileByKey?.at?.(0)?.phoneNumber ?? undefined,
              companyprofileedit_31658470_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.email ?? undefined,
              companyprofileedit_31658480_value:
                this.state.SelectCompanyProfileByKey?.at?.(0)?.linkedinUrl ?? undefined,
              companyprofileedit_31658490_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.youtubeUrl ?? undefined,
              companyprofileedit_31658500_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.faxNumber ?? undefined,
              companyprofileedit_31658220_value:
                this.state.SelectCompanyProfileByKey?.at?.(0)?.aboutUsImage ?? undefined,
              companyprofileedit_663214_value: undefined,
              companyprofileedit_946121_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.aboutUs ?? undefined,
              companyprofileedit_127901_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.aboutUsEN ?? undefined,
              companyprofileedit_101267_value: this.state.SelectCompanyProfileByKey?.at?.(0)?.kvkkFileUrl ?? undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="companyprofileedit_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="472882"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingRight: 44,
                      paddingLeft: 44,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="622344"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Icon
                      id="296916"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.CompanyProfileEditComponent_296916_onClick();
                      }}
                      showCursorPointer
                      iconName="menu"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>

                  <KCol
                    id="548878"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="companyprofileedit_308785_value">
                      <Image
                        id="308785"
                        placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                        zoomOnClick={false}
                        imageFit="fit"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            width: "180px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Image>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="643036"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        textAlign: "-webkit-right",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="42942"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <Label
                        id="279889"
                        value={ReactSystemFunctions.getMyUsername()}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Label>

                      <KContentMenu
                        id="533362"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KContentMenuPanel
                          id="999047"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KPanel
                            id="217053"
                            scrolling="hidden"
                            scrollRadius={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                alignItems: "flex-start",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="777724"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="282569"
                                visibility={this.state.isComp282569Visible}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    alignItems: "center",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="companyprofileedit_370687_value">
                                  <Image
                                    id="370687"
                                    visibility={this.state.isComp370687Visible}
                                    placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                                    zoomOnClick={false}
                                    imageFit="fill"
                                    style={
                                      {
                                        borderTopLeftRadius: 100,
                                        borderTopRightRadius: 100,
                                        borderBottomRightRadius: 100,
                                        borderBottomLeftRadius: 100,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        width: "48px",
                                        height: "48px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="795035"
                                visibility={this.state.isComp795035Visible}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Label
                                  id="820342"
                                  visibility={this.state.isComp820342Visible}
                                  value={this.state.GetUsersFirstLetter?.at?.(0)?.firstletters ?? undefined}
                                  style={
                                    {
                                      borderTopLeftRadius: 100,
                                      borderTopRightRadius: 100,
                                      borderBottomRightRadius: 100,
                                      borderBottomLeftRadius: 100,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(26, 72, 170, 1)",
                                      paddingTop: null,
                                      width: "48px",
                                      height: "48px",
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "52px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>
                          </KPanel>
                        </KContentMenuPanel>

                        <KContentMenuItem
                          id="279736"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.CompanyProfileEditComponent_279736_onClick();
                          }}
                          showCursorPointer
                          label={ReactSystemFunctions.translate(this.ml, 279736, "label", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></KContentMenuItem>

                        <KContentMenuItem
                          id="306344"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.CompanyProfileEditComponent_306344_onClick();
                          }}
                          showCursorPointer
                          label={ReactSystemFunctions.translate(this.ml, 306344, "label", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></KContentMenuItem>
                      </KContentMenu>
                    </HorizontalStack>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="companyprofileedit_body"
                style={{ backgroundColor: "rgba(244, 244, 244, 1)", display: "block" } as any}
              >
                <KRow
                  id="176430"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 48,
                      paddingRight: 48,
                      paddingBottom: 48,
                      paddingLeft: 48,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="302640"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                        borderBottomLeftRadius: 4,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KRow
                      id="19378"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="718531"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KRow
                          id="272333"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingLeft: 8,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="123918"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <Label
                              id="339725"
                              value={ReactSystemFunctions.translate(this.ml, 339725, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Roboto",
                                  fontWeight: 300,
                                  fontSize: "18pt",
                                  color: "rgba(0, 0, 0, 1)"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>

                        <KRow
                          id="897698"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: null,
                              paddingLeft: 8,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="949418"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <Label
                              id="141964"
                              value={ReactSystemFunctions.translate(this.ml, 141964, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Roboto",
                                  fontWeight: 300,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>

                        <Form.Item className="kFormItem" name="companyprofileedit_114630_value" noStyle>
                          <KTab
                            id="114630"
                            type="line"
                            size="default"
                            tabPosition="top"
                            selectedTabColor="#0078FF"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KTabItem
                              id="28407"
                              tab={ReactSystemFunctions.translate(this.ml, 28407, "tab", this.defaultML)}
                              key={28407}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KRow
                                id="56712"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="230249"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="682650"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="417"
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      lg={24}
                                      xl={24}
                                      xxl={24}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KRow
                                        id="829892"
                                        align="top"
                                        justify="start"
                                        horizontalGutter={0}
                                        verticalGutter={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KCol
                                          id="297537"
                                          xs={24}
                                          sm={24}
                                          md={24}
                                          lg={24}
                                          xl={24}
                                          xxl={24}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="132927"
                                            direction="vertical"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="696187"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                696187,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Form.Item className="kFormItem" name="companyprofileedit_31658600_value">
                                              <Image
                                                id="31658600"
                                                placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                                                zoomOnClick={false}
                                                imageFit="fill"
                                                style={
                                                  {
                                                    borderTopLeftRadius: 10,
                                                    borderTopRightRadius: 10,
                                                    borderBottomRightRadius: 10,
                                                    borderBottomLeftRadius: 10,
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    width: "640px",
                                                    height: "164px",
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "block",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></Image>
                                            </Form.Item>

                                            <Icon
                                              id="694186"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.CompanyProfileEditComponent_694186_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="add_photo_alternate"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(155, 155, 155, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>
                                          </VerticalStack>
                                        </KCol>
                                      </KRow>

                                      <KRow
                                        id="250910"
                                        align="top"
                                        justify="start"
                                        horizontalGutter={0}
                                        verticalGutter={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KCol
                                          id="436835"
                                          xs={24}
                                          sm={24}
                                          md={24}
                                          lg={24}
                                          xl={24}
                                          xxl={24}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="987643"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              987643,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "block",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                color: "rgba(155, 155, 155, 1)",
                                                lineHeight: "20px"
                                              } as any
                                            }
                                          ></Label>

                                          <Form.Item className="kFormItem" name="companyprofileedit_276161_value">
                                            <TextInput
                                              id="276161"
                                              placeholder={ReactSystemFunctions.translate(
                                                this.ml,
                                                276161,
                                                "placeholder",
                                                this.defaultML
                                              )}
                                              allowClear={false}
                                              bordered={true}
                                              disabled={false}
                                              type="text"
                                              iconColor="rgba(0, 0, 0, 1)"
                                              formatter="None"
                                              isCharOnly={false}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderTopWidth: 1,
                                                  borderRightWidth: 1,
                                                  borderBottomWidth: 1,
                                                  borderLeftWidth: 1,
                                                  borderColor: "rgba(218, 218, 218, 1)",
                                                  borderStyle: "solid",
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></TextInput>
                                          </Form.Item>
                                        </KCol>
                                      </KRow>

                                      <KRow
                                        id="410355"
                                        align="top"
                                        justify="start"
                                        horizontalGutter={0}
                                        verticalGutter={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: null,
                                            paddingBottom: null,
                                            paddingLeft: null,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KCol
                                          id="755817"
                                          xs={24}
                                          sm={24}
                                          md={24}
                                          lg={24}
                                          xl={24}
                                          xxl={24}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              textAlign: "-webkit-right",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Button
                                            id="9544"
                                            onClick={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.CompanyProfileEditComponent_9544_onClick();
                                            }}
                                            showCursorPointer
                                            label={ReactSystemFunctions.translate(
                                              this.ml,
                                              9544,
                                              "label",
                                              this.defaultML
                                            )}
                                            size="middle"
                                            loading={false}
                                            ghost={false}
                                            block={false}
                                            htmlType="button"
                                            icon="keyboard_return"
                                            iconPosition="right"
                                            danger={false}
                                            style={
                                              {
                                                borderTopLeftRadius: 4,
                                                borderTopRightRadius: 4,
                                                borderBottomRightRadius: 4,
                                                borderBottomLeftRadius: 4,
                                                backgroundColor: "rgba(22, 193, 243, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 8,
                                                paddingRight: 20,
                                                paddingBottom: 8,
                                                paddingLeft: 20,
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                color: "rgba(255, 255, 255, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Button>
                                        </KCol>
                                      </KRow>
                                    </KCol>
                                  </KRow>
                                </KCol>
                              </KRow>
                            </KTabItem>

                            <KTabItem
                              id="23391"
                              tab={ReactSystemFunctions.translate(this.ml, 23391, "tab", this.defaultML)}
                              key={23391}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KRow
                                id="587694"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="887319"
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  xxl={12}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="912227"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="161235"
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      lg={24}
                                      xl={24}
                                      xxl={24}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="815426"
                                        value={ReactSystemFunctions.translate(this.ml, 815426, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Label>

                                      <VerticalStack
                                        id="223057"
                                        direction="vertical"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="companyprofileedit_31658230_value">
                                          <Image
                                            id="31658230"
                                            placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                                            zoomOnClick={false}
                                            imageFit="fill"
                                            style={
                                              {
                                                borderTopLeftRadius: 10,
                                                borderTopRightRadius: 10,
                                                borderBottomRightRadius: 10,
                                                borderBottomLeftRadius: 10,
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                height: "164px",
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Image>
                                        </Form.Item>

                                        <Icon
                                          id="52529"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.CompanyProfileEditComponent_52529_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="add_photo_alternate"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(155, 155, 155, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Form.Item className="kFormItem" name="companyprofileedit_114611_value" noStyle>
                                          <KTab
                                            id="114611"
                                            onChange={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.CompanyProfileEditComponent_114611_onChange();
                                            }}
                                            type="line"
                                            size="default"
                                            tabPosition="top"
                                            selectedTabColor="#0078FF"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KTabItem
                                              id="556922"
                                              tab={ReactSystemFunctions.translate(
                                                this.ml,
                                                556922,
                                                "tab",
                                                this.defaultML
                                              )}
                                              key={556922}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="2165817"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  2165817,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "block",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    color: "rgba(155, 155, 155, 1)",
                                                    lineHeight: "20px"
                                                  } as any
                                                }
                                              ></Label>

                                              <Form.Item className="kFormItem" name="companyprofileedit_500144_value">
                                                <TextArea
                                                  id="500144"
                                                  placeholder={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    500144,
                                                    "placeholder",
                                                    this.defaultML
                                                  )}
                                                  allowClear={false}
                                                  autoSize={false}
                                                  showCount={false}
                                                  formatter="None"
                                                  readOnly={false}
                                                  style={
                                                    {
                                                      borderTopLeftRadius: 4,
                                                      borderTopRightRadius: 4,
                                                      borderBottomRightRadius: 4,
                                                      borderBottomLeftRadius: 4,
                                                      borderTopWidth: 1,
                                                      borderRightWidth: 1,
                                                      borderBottomWidth: 1,
                                                      borderLeftWidth: 1,
                                                      borderColor: "rgba(218, 218, 218, 1)",
                                                      borderStyle: "solid",
                                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                                      backgroundRepeat: "no-repeat",
                                                      backgroundSize: "contain",
                                                      backgroundPosition: "left",
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      minHeight: "160px",
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></TextArea>
                                              </Form.Item>
                                            </KTabItem>

                                            <KTabItem
                                              id="928473"
                                              tab={ReactSystemFunctions.translate(
                                                this.ml,
                                                928473,
                                                "tab",
                                                this.defaultML
                                              )}
                                              key={928473}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="751403"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  751403,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    color: "rgba(155, 155, 155, 1)",
                                                    lineHeight: "20px"
                                                  } as any
                                                }
                                              ></Label>

                                              <Form.Item className="kFormItem" name="companyprofileedit_596414_value">
                                                <TextArea
                                                  id="596414"
                                                  placeholder={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    596414,
                                                    "placeholder",
                                                    this.defaultML
                                                  )}
                                                  allowClear={false}
                                                  autoSize={false}
                                                  showCount={false}
                                                  formatter="None"
                                                  readOnly={false}
                                                  style={
                                                    {
                                                      borderTopLeftRadius: 4,
                                                      borderTopRightRadius: 4,
                                                      borderBottomRightRadius: 4,
                                                      borderBottomLeftRadius: 4,
                                                      borderTopWidth: 1,
                                                      borderRightWidth: 1,
                                                      borderBottomWidth: 1,
                                                      borderLeftWidth: 1,
                                                      borderColor: "rgba(218, 218, 218, 1)",
                                                      borderStyle: "solid",
                                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                                      backgroundRepeat: "no-repeat",
                                                      backgroundSize: "contain",
                                                      backgroundPosition: "left",
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      minHeight: "160px",
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></TextArea>
                                              </Form.Item>
                                            </KTabItem>
                                          </KTab>
                                        </Form.Item>

                                        <KRow
                                          id="684889"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="419340"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                textAlign: "-webkit-right",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Button
                                              id="410040"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.CompanyProfileEditComponent_410040_onClick();
                                              }}
                                              showCursorPointer
                                              visibility={this.state.isComp410040Visible}
                                              label={ReactSystemFunctions.translate(
                                                this.ml,
                                                410040,
                                                "label",
                                                this.defaultML
                                              )}
                                              size="middle"
                                              loading={false}
                                              ghost={false}
                                              block={false}
                                              htmlType="button"
                                              icon="keyboard_return"
                                              iconPosition="right"
                                              danger={false}
                                              style={
                                                {
                                                  borderTopLeftRadius: 4,
                                                  borderTopRightRadius: 4,
                                                  borderBottomRightRadius: 4,
                                                  borderBottomLeftRadius: 4,
                                                  backgroundColor: "rgba(22, 193, 243, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 20,
                                                  paddingBottom: 8,
                                                  paddingLeft: 20,
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "16px",
                                                  color: "rgba(255, 255, 255, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Button>

                                            <Button
                                              id="498569"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.CompanyProfileEditComponent_498569_onClick();
                                              }}
                                              showCursorPointer
                                              visibility={this.state.isComp498569Visible}
                                              label={ReactSystemFunctions.translate(
                                                this.ml,
                                                498569,
                                                "label",
                                                this.defaultML
                                              )}
                                              size="middle"
                                              loading={false}
                                              ghost={false}
                                              block={false}
                                              htmlType="button"
                                              icon="keyboard_return"
                                              iconPosition="right"
                                              danger={false}
                                              style={
                                                {
                                                  borderTopLeftRadius: 4,
                                                  borderTopRightRadius: 4,
                                                  borderBottomRightRadius: 4,
                                                  borderBottomLeftRadius: 4,
                                                  backgroundColor: "rgba(22, 193, 243, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 20,
                                                  paddingBottom: 8,
                                                  paddingLeft: 20,
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "16px",
                                                  color: "rgba(255, 255, 255, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Button>
                                          </KCol>
                                        </KRow>
                                      </VerticalStack>
                                    </KCol>
                                  </KRow>
                                </KCol>

                                <KCol
                                  id="477796"
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  xxl={12}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="9165824"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="1165824"
                                      xs={8}
                                      sm={24}
                                      md={24}
                                      lg={24}
                                      xl={24}
                                      xxl={24}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="388027"
                                        value={ReactSystemFunctions.translate(this.ml, 388027, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      ></Label>

                                      <VerticalStack
                                        id="705371"
                                        direction="vertical"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            textAlign: "-webkit-center",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="companyprofileedit_31658240_value">
                                          <Image
                                            id="31658240"
                                            placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                                            zoomOnClick={false}
                                            imageFit="fill"
                                            style={
                                              {
                                                borderTopLeftRadius: 10,
                                                borderTopRightRadius: 10,
                                                borderBottomRightRadius: 10,
                                                borderBottomLeftRadius: 10,
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                height: "164px",
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Image>
                                        </Form.Item>

                                        <Icon
                                          id="493576"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.CompanyProfileEditComponent_493576_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="add_photo_alternate"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(155, 155, 155, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Form.Item className="kFormItem" name="companyprofileedit_435611_value" noStyle>
                                          <KTab
                                            id="435611"
                                            onChange={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.CompanyProfileEditComponent_435611_onChange();
                                            }}
                                            type="line"
                                            size="default"
                                            tabPosition="top"
                                            selectedTabColor="#0078FF"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KTabItem
                                              id="532497"
                                              tab={ReactSystemFunctions.translate(
                                                this.ml,
                                                532497,
                                                "tab",
                                                this.defaultML
                                              )}
                                              key={532497}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="2165818"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  2165818,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "block",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    color: "rgba(155, 155, 155, 1)",
                                                    lineHeight: "20px"
                                                  } as any
                                                }
                                              ></Label>

                                              <Form.Item className="kFormItem" name="companyprofileedit_181411_value">
                                                <TextArea
                                                  id="181411"
                                                  placeholder={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    181411,
                                                    "placeholder",
                                                    this.defaultML
                                                  )}
                                                  allowClear={false}
                                                  autoSize={false}
                                                  showCount={false}
                                                  formatter="None"
                                                  readOnly={false}
                                                  style={
                                                    {
                                                      borderTopLeftRadius: 4,
                                                      borderTopRightRadius: 4,
                                                      borderBottomRightRadius: 4,
                                                      borderBottomLeftRadius: 4,
                                                      borderTopWidth: 1,
                                                      borderRightWidth: 1,
                                                      borderBottomWidth: 1,
                                                      borderLeftWidth: 1,
                                                      borderColor: "rgba(218, 218, 218, 1)",
                                                      borderStyle: "solid",
                                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                                      backgroundRepeat: "no-repeat",
                                                      backgroundSize: "contain",
                                                      backgroundPosition: "left",
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      minHeight: "160px",
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></TextArea>
                                              </Form.Item>
                                            </KTabItem>

                                            <KTabItem
                                              id="101525"
                                              tab={ReactSystemFunctions.translate(
                                                this.ml,
                                                101525,
                                                "tab",
                                                this.defaultML
                                              )}
                                              key={101525}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="427214"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  427214,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    color: "rgba(155, 155, 155, 1)",
                                                    lineHeight: "20px"
                                                  } as any
                                                }
                                              ></Label>

                                              <Form.Item className="kFormItem" name="companyprofileedit_529767_value">
                                                <TextArea
                                                  id="529767"
                                                  placeholder={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    529767,
                                                    "placeholder",
                                                    this.defaultML
                                                  )}
                                                  allowClear={false}
                                                  autoSize={false}
                                                  showCount={false}
                                                  formatter="None"
                                                  readOnly={false}
                                                  style={
                                                    {
                                                      borderTopLeftRadius: 4,
                                                      borderTopRightRadius: 4,
                                                      borderBottomRightRadius: 4,
                                                      borderBottomLeftRadius: 4,
                                                      borderTopWidth: 1,
                                                      borderRightWidth: 1,
                                                      borderBottomWidth: 1,
                                                      borderLeftWidth: 1,
                                                      borderColor: "rgba(218, 218, 218, 1)",
                                                      borderStyle: "solid",
                                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                                      backgroundRepeat: "no-repeat",
                                                      backgroundSize: "contain",
                                                      backgroundPosition: "left",
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      minHeight: "160px",
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></TextArea>
                                              </Form.Item>
                                            </KTabItem>
                                          </KTab>
                                        </Form.Item>

                                        <KRow
                                          id="957742"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="812511"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                textAlign: "-webkit-right",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Button
                                              id="791975"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.CompanyProfileEditComponent_791975_onClick();
                                              }}
                                              showCursorPointer
                                              visibility={this.state.isComp791975Visible}
                                              label={ReactSystemFunctions.translate(
                                                this.ml,
                                                791975,
                                                "label",
                                                this.defaultML
                                              )}
                                              size="middle"
                                              loading={false}
                                              ghost={false}
                                              block={false}
                                              htmlType="button"
                                              icon="keyboard_return"
                                              iconPosition="right"
                                              danger={false}
                                              style={
                                                {
                                                  borderTopLeftRadius: 4,
                                                  borderTopRightRadius: 4,
                                                  borderBottomRightRadius: 4,
                                                  borderBottomLeftRadius: 4,
                                                  backgroundColor: "rgba(22, 193, 243, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 20,
                                                  paddingBottom: 8,
                                                  paddingLeft: 20,
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "16px",
                                                  color: "rgba(255, 255, 255, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Button>

                                            <Button
                                              id="194808"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.CompanyProfileEditComponent_194808_onClick();
                                              }}
                                              showCursorPointer
                                              visibility={this.state.isComp194808Visible}
                                              label={ReactSystemFunctions.translate(
                                                this.ml,
                                                194808,
                                                "label",
                                                this.defaultML
                                              )}
                                              size="middle"
                                              loading={false}
                                              ghost={false}
                                              block={false}
                                              htmlType="button"
                                              icon="keyboard_return"
                                              iconPosition="right"
                                              danger={false}
                                              style={
                                                {
                                                  borderTopLeftRadius: 4,
                                                  borderTopRightRadius: 4,
                                                  borderBottomRightRadius: 4,
                                                  borderBottomLeftRadius: 4,
                                                  backgroundColor: "rgba(22, 193, 243, 1)",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 8,
                                                  paddingRight: 20,
                                                  paddingBottom: 8,
                                                  paddingLeft: 20,
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "16px",
                                                  color: "rgba(255, 255, 255, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></Button>
                                          </KCol>
                                        </KRow>
                                      </VerticalStack>
                                    </KCol>
                                  </KRow>
                                </KCol>
                              </KRow>
                            </KTabItem>

                            <KTabItem
                              id="340046"
                              tab={ReactSystemFunctions.translate(this.ml, 340046, "tab", this.defaultML)}
                              key={340046}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KRow
                                id="332841"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="911978"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="9165845"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="1165845"
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      lg={24}
                                      xl={24}
                                      xxl={24}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="2165845"
                                        value={ReactSystemFunctions.translate(
                                          this.ml,
                                          2165845,
                                          "value",
                                          this.defaultML
                                        )}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            color: "rgba(155, 155, 155, 1)",
                                            lineHeight: "20px"
                                          } as any
                                        }
                                      ></Label>

                                      <Form.Item className="kFormItem" name="companyprofileedit_31658450_value">
                                        <TextInput
                                          id="31658450"
                                          placeholder={ReactSystemFunctions.translate(
                                            this.ml,
                                            31658450,
                                            "placeholder",
                                            this.defaultML
                                          )}
                                          allowClear={false}
                                          bordered={true}
                                          disabled={false}
                                          type="text"
                                          iconColor="rgba(0, 0, 0, 1)"
                                          formatter="None"
                                          isCharOnly={false}
                                          style={
                                            {
                                              borderTopLeftRadius: 8,
                                              borderTopRightRadius: 8,
                                              borderBottomRightRadius: 8,
                                              borderBottomLeftRadius: 8,
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></TextInput>
                                      </Form.Item>
                                    </KCol>
                                  </KRow>

                                  <KRow
                                    id="478739"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="771513"
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      lg={24}
                                      xl={24}
                                      xxl={24}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KRow
                                        id="9165846"
                                        align="top"
                                        justify="start"
                                        horizontalGutter={0}
                                        verticalGutter={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KCol
                                          id="1165846"
                                          xs={24}
                                          sm={24}
                                          md={24}
                                          lg={24}
                                          xl={24}
                                          xxl={24}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="2165846"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              2165846,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "block",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                color: "rgba(155, 155, 155, 1)",
                                                lineHeight: "20px"
                                              } as any
                                            }
                                          ></Label>

                                          <Form.Item className="kFormItem" name="companyprofileedit_31658460_value">
                                            <TextInput
                                              id="31658460"
                                              placeholder={ReactSystemFunctions.translate(
                                                this.ml,
                                                31658460,
                                                "placeholder",
                                                this.defaultML
                                              )}
                                              allowClear={false}
                                              bordered={true}
                                              disabled={false}
                                              type="text"
                                              iconColor="rgba(0, 0, 0, 1)"
                                              mask="9999999999999"
                                              formatter="None"
                                              isCharOnly={false}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderTopWidth: 1,
                                                  borderRightWidth: 1,
                                                  borderBottomWidth: 1,
                                                  borderLeftWidth: 1,
                                                  borderColor: "rgba(218, 218, 218, 1)",
                                                  borderStyle: "solid",
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            ></TextInput>
                                          </Form.Item>
                                        </KCol>
                                      </KRow>

                                      <KRow
                                        id="198554"
                                        align="top"
                                        justify="start"
                                        horizontalGutter={0}
                                        verticalGutter={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KCol
                                          id="342884"
                                          xs={24}
                                          sm={24}
                                          md={24}
                                          lg={24}
                                          xl={24}
                                          xxl={24}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KRow
                                            id="9165847"
                                            align="top"
                                            justify="start"
                                            horizontalGutter={0}
                                            verticalGutter={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KCol
                                              id="1165847"
                                              xs={24}
                                              sm={24}
                                              md={24}
                                              lg={24}
                                              xl={24}
                                              xxl={24}
                                              order={0}
                                              pull={0}
                                              push={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="2165847"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  2165847,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "block",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    color: "rgba(155, 155, 155, 1)",
                                                    lineHeight: "20px"
                                                  } as any
                                                }
                                              ></Label>

                                              <Form.Item className="kFormItem" name="companyprofileedit_31658470_value">
                                                <TextInput
                                                  id="31658470"
                                                  placeholder={ReactSystemFunctions.translate(
                                                    this.ml,
                                                    31658470,
                                                    "placeholder",
                                                    this.defaultML
                                                  )}
                                                  allowClear={false}
                                                  bordered={true}
                                                  disabled={false}
                                                  type="text"
                                                  iconColor="rgba(0, 0, 0, 1)"
                                                  formatter="None"
                                                  isCharOnly={false}
                                                  style={
                                                    {
                                                      borderTopLeftRadius: 8,
                                                      borderTopRightRadius: 8,
                                                      borderBottomRightRadius: 8,
                                                      borderBottomLeftRadius: 8,
                                                      borderTopWidth: 1,
                                                      borderRightWidth: 1,
                                                      borderBottomWidth: 1,
                                                      borderLeftWidth: 1,
                                                      borderColor: "rgba(218, 218, 218, 1)",
                                                      borderStyle: "solid",
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                ></TextInput>
                                              </Form.Item>
                                            </KCol>
                                          </KRow>

                                          <KRow
                                            id="535387"
                                            align="top"
                                            justify="start"
                                            horizontalGutter={0}
                                            verticalGutter={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <KCol
                                              id="893555"
                                              xs={24}
                                              sm={24}
                                              md={24}
                                              lg={24}
                                              xl={24}
                                              xxl={24}
                                              order={0}
                                              pull={0}
                                              push={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  fontFamily: "Roboto",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  lineHeight: "22px"
                                                } as any
                                              }
                                            >
                                              <KRow
                                                id="9165848"
                                                align="top"
                                                justify="start"
                                                horizontalGutter={0}
                                                verticalGutter={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <KCol
                                                  id="1165848"
                                                  xs={24}
                                                  sm={24}
                                                  md={24}
                                                  lg={24}
                                                  xl={24}
                                                  xxl={24}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="2165848"
                                                    value={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      2165848,
                                                      "value",
                                                      this.defaultML
                                                    )}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "block",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                        color: "rgba(155, 155, 155, 1)",
                                                        lineHeight: "20px"
                                                      } as any
                                                    }
                                                  ></Label>

                                                  <Form.Item
                                                    className="kFormItem"
                                                    name="companyprofileedit_31658480_value"
                                                  >
                                                    <TextInput
                                                      id="31658480"
                                                      placeholder={ReactSystemFunctions.translate(
                                                        this.ml,
                                                        31658480,
                                                        "placeholder",
                                                        this.defaultML
                                                      )}
                                                      allowClear={false}
                                                      bordered={true}
                                                      disabled={false}
                                                      type="text"
                                                      iconColor="rgba(0, 0, 0, 1)"
                                                      formatter="None"
                                                      isCharOnly={false}
                                                      style={
                                                        {
                                                          borderTopLeftRadius: 8,
                                                          borderTopRightRadius: 8,
                                                          borderBottomRightRadius: 8,
                                                          borderBottomLeftRadius: 8,
                                                          borderTopWidth: 1,
                                                          borderRightWidth: 1,
                                                          borderBottomWidth: 1,
                                                          borderLeftWidth: 1,
                                                          borderColor: "rgba(218, 218, 218, 1)",
                                                          borderStyle: "solid",
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "block",
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    ></TextInput>
                                                  </Form.Item>
                                                </KCol>
                                              </KRow>

                                              <KRow
                                                id="260245"
                                                align="top"
                                                justify="start"
                                                horizontalGutter={0}
                                                verticalGutter={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              >
                                                <KCol
                                                  id="162250"
                                                  xs={24}
                                                  sm={24}
                                                  md={24}
                                                  lg={24}
                                                  xl={24}
                                                  xxl={24}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      fontFamily: "Roboto",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      lineHeight: "22px"
                                                    } as any
                                                  }
                                                >
                                                  <KRow
                                                    id="9165849"
                                                    align="top"
                                                    justify="start"
                                                    horizontalGutter={0}
                                                    verticalGutter={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KCol
                                                      id="1165849"
                                                      xs={24}
                                                      sm={24}
                                                      md={24}
                                                      lg={24}
                                                      xl={24}
                                                      xxl={24}
                                                      order={0}
                                                      pull={0}
                                                      push={0}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <Label
                                                        id="2165849"
                                                        value={ReactSystemFunctions.translate(
                                                          this.ml,
                                                          2165849,
                                                          "value",
                                                          this.defaultML
                                                        )}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "block",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 400,
                                                            fontSize: "12px",
                                                            color: "rgba(155, 155, 155, 1)",
                                                            lineHeight: "20px"
                                                          } as any
                                                        }
                                                      ></Label>

                                                      <Form.Item
                                                        className="kFormItem"
                                                        name="companyprofileedit_31658490_value"
                                                      >
                                                        <TextInput
                                                          id="31658490"
                                                          placeholder={ReactSystemFunctions.translate(
                                                            this.ml,
                                                            31658490,
                                                            "placeholder",
                                                            this.defaultML
                                                          )}
                                                          allowClear={false}
                                                          bordered={true}
                                                          disabled={false}
                                                          type="text"
                                                          iconColor="rgba(0, 0, 0, 1)"
                                                          formatter="None"
                                                          isCharOnly={false}
                                                          style={
                                                            {
                                                              borderTopLeftRadius: 8,
                                                              borderTopRightRadius: 8,
                                                              borderBottomRightRadius: 8,
                                                              borderBottomLeftRadius: 8,
                                                              borderTopWidth: 1,
                                                              borderRightWidth: 1,
                                                              borderBottomWidth: 1,
                                                              borderLeftWidth: 1,
                                                              borderColor: "rgba(218, 218, 218, 1)",
                                                              borderStyle: "solid",
                                                              paddingTop: 8,
                                                              paddingRight: 8,
                                                              paddingBottom: 8,
                                                              paddingLeft: 8,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "block",
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        ></TextInput>
                                                      </Form.Item>
                                                    </KCol>
                                                  </KRow>

                                                  <KRow
                                                    id="183025"
                                                    align="top"
                                                    justify="start"
                                                    horizontalGutter={0}
                                                    verticalGutter={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Roboto",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(68, 68, 68, 1)",
                                                        lineHeight: "22px"
                                                      } as any
                                                    }
                                                  >
                                                    <KCol
                                                      id="219714"
                                                      xs={24}
                                                      sm={24}
                                                      md={24}
                                                      lg={24}
                                                      xl={24}
                                                      xxl={24}
                                                      order={0}
                                                      pull={0}
                                                      push={0}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          fontFamily: "Roboto",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(68, 68, 68, 1)",
                                                          lineHeight: "22px"
                                                        } as any
                                                      }
                                                    >
                                                      <KRow
                                                        id="9165850"
                                                        align="top"
                                                        justify="start"
                                                        horizontalGutter={0}
                                                        verticalGutter={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(68, 68, 68, 1)",
                                                            lineHeight: "22px"
                                                          } as any
                                                        }
                                                      >
                                                        <KCol
                                                          id="1165850"
                                                          xs={24}
                                                          sm={24}
                                                          md={24}
                                                          lg={24}
                                                          xl={24}
                                                          xxl={24}
                                                          order={0}
                                                          pull={0}
                                                          push={0}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 8,
                                                              paddingRight: 8,
                                                              paddingBottom: 8,
                                                              paddingLeft: 8,
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              fontFamily: "Roboto",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(68, 68, 68, 1)",
                                                              lineHeight: "22px"
                                                            } as any
                                                          }
                                                        >
                                                          <Label
                                                            id="2165850"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              2165850,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "block",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                color: "rgba(155, 155, 155, 1)",
                                                                lineHeight: "20px"
                                                              } as any
                                                            }
                                                          ></Label>

                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="companyprofileedit_31658500_value"
                                                          >
                                                            <TextInput
                                                              id="31658500"
                                                              placeholder={ReactSystemFunctions.translate(
                                                                this.ml,
                                                                31658500,
                                                                "placeholder",
                                                                this.defaultML
                                                              )}
                                                              allowClear={false}
                                                              bordered={true}
                                                              disabled={false}
                                                              type="text"
                                                              iconColor="rgba(0, 0, 0, 1)"
                                                              formatter="None"
                                                              isCharOnly={false}
                                                              style={
                                                                {
                                                                  borderTopLeftRadius: 8,
                                                                  borderTopRightRadius: 8,
                                                                  borderBottomRightRadius: 8,
                                                                  borderBottomLeftRadius: 8,
                                                                  borderTopWidth: 1,
                                                                  borderRightWidth: 1,
                                                                  borderBottomWidth: 1,
                                                                  borderLeftWidth: 1,
                                                                  borderColor: "rgba(218, 218, 218, 1)",
                                                                  borderStyle: "solid",
                                                                  paddingTop: 8,
                                                                  paddingRight: 8,
                                                                  paddingBottom: 8,
                                                                  paddingLeft: 8,
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Roboto",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(68, 68, 68, 1)",
                                                                  lineHeight: "22px"
                                                                } as any
                                                              }
                                                            ></TextInput>
                                                          </Form.Item>
                                                        </KCol>
                                                      </KRow>
                                                    </KCol>
                                                  </KRow>
                                                </KCol>
                                              </KRow>
                                            </KCol>
                                          </KRow>
                                        </KCol>
                                      </KRow>
                                    </KCol>
                                  </KRow>
                                </KCol>
                              </KRow>

                              <KRow
                                id="166060"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="234297"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      textAlign: "-webkit-right",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Button
                                    id="467289"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.CompanyProfileEditComponent_467289_onClick();
                                    }}
                                    showCursorPointer
                                    label={ReactSystemFunctions.translate(this.ml, 467289, "label", this.defaultML)}
                                    size="middle"
                                    loading={false}
                                    ghost={false}
                                    block={false}
                                    htmlType="button"
                                    icon="keyboard_return"
                                    iconPosition="right"
                                    danger={false}
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        backgroundColor: "rgba(22, 193, 243, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 20,
                                        paddingBottom: 8,
                                        paddingLeft: 20,
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        color: "rgba(255, 255, 255, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Button>
                                </KCol>
                              </KRow>
                            </KTabItem>

                            <KTabItem
                              id="56801"
                              tab={ReactSystemFunctions.translate(this.ml, 56801, "tab", this.defaultML)}
                              key={56801}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KRow
                                id="706133"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="934481"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="9165822"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="1165822"
                                      xs={8}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      xxl={12}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingRight: 16,
                                          paddingLeft: 16,
                                          textAlign: "-webkit-center",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <VerticalStack
                                        id="265461"
                                        direction="vertical"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="2165816"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            2165816,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(155, 155, 155, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        ></Label>

                                        <Form.Item className="kFormItem" name="companyprofileedit_31658220_value">
                                          <Image
                                            id="31658220"
                                            placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                                            zoomOnClick={false}
                                            imageFit="fill"
                                            style={
                                              {
                                                borderTopLeftRadius: 10,
                                                borderTopRightRadius: 10,
                                                borderBottomRightRadius: 10,
                                                borderBottomLeftRadius: 10,
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                width: "100%",
                                                height: "164px",
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Image>
                                        </Form.Item>

                                        <Icon
                                          id="310219"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.CompanyProfileEditComponent_310219_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="add_photo_alternate"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(155, 155, 155, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>
                                      </VerticalStack>
                                    </KCol>

                                    <KCol
                                      id="901521"
                                      xs={8}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      xxl={12}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="companyprofileedit_663214_value" noStyle>
                                        <KTab
                                          id="663214"
                                          type="line"
                                          size="default"
                                          tabPosition="top"
                                          selectedTabColor="#0078FF"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <KTabItem
                                            id="768204"
                                            tab={ReactSystemFunctions.translate(this.ml, 768204, "tab", this.defaultML)}
                                            key={768204}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Form.Item className="kFormItem" name="companyprofileedit_946121_value">
                                              <TextArea
                                                id="946121"
                                                placeholder={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  946121,
                                                  "placeholder",
                                                  this.defaultML
                                                )}
                                                allowClear={false}
                                                autoSize={false}
                                                showCount={false}
                                                formatter="None"
                                                readOnly={false}
                                                style={
                                                  {
                                                    borderTopLeftRadius: 4,
                                                    borderTopRightRadius: 4,
                                                    borderBottomRightRadius: 4,
                                                    borderBottomLeftRadius: 4,
                                                    borderTopWidth: 1,
                                                    borderRightWidth: 1,
                                                    borderBottomWidth: 1,
                                                    borderLeftWidth: 1,
                                                    borderColor: "rgba(218, 218, 218, 1)",
                                                    borderStyle: "solid",
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "contain",
                                                    backgroundPosition: "left",
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    height: "80px",
                                                    minHeight: "160px",
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "block",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></TextArea>
                                            </Form.Item>
                                          </KTabItem>

                                          <KTabItem
                                            id="344407"
                                            tab={ReactSystemFunctions.translate(this.ml, 344407, "tab", this.defaultML)}
                                            key={344407}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Roboto",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          >
                                            <Form.Item className="kFormItem" name="companyprofileedit_127901_value">
                                              <TextArea
                                                id="127901"
                                                placeholder={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  127901,
                                                  "placeholder",
                                                  this.defaultML
                                                )}
                                                allowClear={false}
                                                autoSize={false}
                                                showCount={false}
                                                formatter="None"
                                                readOnly={false}
                                                style={
                                                  {
                                                    borderTopLeftRadius: 4,
                                                    borderTopRightRadius: 4,
                                                    borderBottomRightRadius: 4,
                                                    borderBottomLeftRadius: 4,
                                                    borderTopWidth: 1,
                                                    borderRightWidth: 1,
                                                    borderBottomWidth: 1,
                                                    borderLeftWidth: 1,
                                                    borderColor: "rgba(218, 218, 218, 1)",
                                                    borderStyle: "solid",
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "contain",
                                                    backgroundPosition: "left",
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    height: "80px",
                                                    minHeight: "160px",
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "block",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    lineHeight: "22px"
                                                  } as any
                                                }
                                              ></TextArea>
                                            </Form.Item>
                                          </KTabItem>
                                        </KTab>
                                      </Form.Item>

                                      <KRow
                                        id="171348"
                                        align="top"
                                        justify="start"
                                        horizontalGutter={0}
                                        verticalGutter={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <KCol
                                          id="978220"
                                          xs={24}
                                          sm={24}
                                          md={24}
                                          lg={24}
                                          xl={24}
                                          xxl={24}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              textAlign: "-webkit-right",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        >
                                          <Button
                                            id="185635"
                                            onClick={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.CompanyProfileEditComponent_185635_onClick();
                                            }}
                                            showCursorPointer
                                            label={ReactSystemFunctions.translate(
                                              this.ml,
                                              185635,
                                              "label",
                                              this.defaultML
                                            )}
                                            size="middle"
                                            loading={false}
                                            ghost={false}
                                            block={false}
                                            htmlType="button"
                                            icon="keyboard_return"
                                            iconPosition="right"
                                            danger={false}
                                            style={
                                              {
                                                borderTopLeftRadius: 4,
                                                borderTopRightRadius: 4,
                                                borderBottomRightRadius: 4,
                                                borderBottomLeftRadius: 4,
                                                backgroundColor: "rgba(22, 193, 243, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 8,
                                                paddingRight: 20,
                                                paddingBottom: 8,
                                                paddingLeft: 20,
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                color: "rgba(255, 255, 255, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Button>

                                          <Button
                                            id="518820"
                                            onClick={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.CompanyProfileEditComponent_518820_onClick();
                                            }}
                                            showCursorPointer
                                            visibility={this.state.isComp518820Visible}
                                            label={ReactSystemFunctions.translate(
                                              this.ml,
                                              518820,
                                              "label",
                                              this.defaultML
                                            )}
                                            size="middle"
                                            loading={false}
                                            ghost={false}
                                            block={false}
                                            htmlType="button"
                                            icon="keyboard_return"
                                            iconPosition="right"
                                            danger={false}
                                            style={
                                              {
                                                borderTopLeftRadius: 4,
                                                borderTopRightRadius: 4,
                                                borderBottomRightRadius: 4,
                                                borderBottomLeftRadius: 4,
                                                backgroundColor: "rgba(22, 193, 243, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 8,
                                                paddingRight: 20,
                                                paddingBottom: 8,
                                                paddingLeft: 20,
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                color: "rgba(255, 255, 255, 1)",
                                                lineHeight: "22px"
                                              } as any
                                            }
                                          ></Button>
                                        </KCol>
                                      </KRow>
                                    </KCol>
                                  </KRow>
                                </KCol>
                              </KRow>
                            </KTabItem>

                            <KTabItem
                              id="488133"
                              tab={ReactSystemFunctions.translate(this.ml, 488133, "tab", this.defaultML)}
                              key={488133}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KRow
                                id="508311"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="750008"
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  xxl={6}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="557876"
                                    value={ReactSystemFunctions.translate(this.ml, 557876, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="202683"
                                  xs={18}
                                  sm={18}
                                  md={18}
                                  lg={18}
                                  xl={18}
                                  xxl={18}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="companyprofileedit_101267_value">
                                    <TextInput
                                      id="101267"
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        101267,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={false}
                                      bordered={true}
                                      disabled={false}
                                      type="text"
                                      iconColor="rgba(0, 0, 0, 1)"
                                      formatter="None"
                                      isCharOnly={false}
                                      style={
                                        {
                                          borderTopLeftRadius: 4,
                                          borderTopRightRadius: 4,
                                          borderBottomRightRadius: 4,
                                          borderBottomLeftRadius: 4,
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(218, 218, 218, 1)",
                                          borderStyle: "solid",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></TextInput>
                                  </Form.Item>
                                </KCol>
                              </KRow>

                              <KRow
                                id="801592"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="529807"
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  xxl={6}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="847986"
                                    value={ReactSystemFunctions.translate(this.ml, 847986, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="571981"
                                  xs={18}
                                  sm={18}
                                  md={18}
                                  lg={18}
                                  xl={18}
                                  xxl={18}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <HorizontalStack
                                    id="219569"
                                    direction="horizontal"
                                    size={12}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="224452"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.CompanyProfileEditComponent_224452_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="picture_as_pdf"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "24px",
                                          color: "rgba(155, 155, 155, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Label
                                      id="177603"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.CompanyProfileEditComponent_177603_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp177603Visible}
                                      value={ReactSystemFunctions.translate(this.ml, 177603, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 193, 243, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </HorizontalStack>
                                </KCol>
                              </KRow>

                              <KRow
                                id="39575"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="589187"
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  xxl={12}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></KCol>

                                <KCol
                                  id="696615"
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  xxl={12}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Button
                                    id="805466"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.CompanyProfileEditComponent_805466_onClick();
                                    }}
                                    showCursorPointer
                                    label={ReactSystemFunctions.translate(this.ml, 805466, "label", this.defaultML)}
                                    size="middle"
                                    loading={false}
                                    ghost={false}
                                    block={false}
                                    htmlType="button"
                                    icon="keyboard_return"
                                    iconPosition="right"
                                    danger={false}
                                    style={
                                      {
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        borderBottomRightRadius: 4,
                                        borderBottomLeftRadius: 4,
                                        backgroundColor: "rgba(22, 193, 243, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 20,
                                        paddingBottom: 8,
                                        paddingLeft: 20,
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        color: "rgba(255, 255, 255, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Button>
                                </KCol>
                              </KRow>
                            </KTabItem>
                          </KTab>
                        </Form.Item>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="282236"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible282236: false }, () => {
                      this.callCallbackFunction(282236);
                    });
                  }}
                  visible={this.state.NavVisible282236}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible282236 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible282236: false }, () => {
                            this.callCallbackFunction(282236);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="273031"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible273031: false }, () => {
                      this.callCallbackFunction(273031);
                    });
                  }}
                  visible={this.state.NavVisible273031}
                  width="400px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible273031 && (
                      <Profile_Screen
                        onClose={() => {
                          this.setState({ NavVisible273031: false }, () => {
                            this.callCallbackFunction(273031);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(CompanyProfileEdit_Screen))))
  )
);
export { tmp as CompanyProfileEdit_Screen };
//export default tmp;
//export { tmp as CompanyProfileEdit_Screen };
