import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICustomerUserAuthorizeCurrent_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICustomerUserAuthorizeCurrent_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  spGetUserById: any[];
  GetCompanyById: any[];
  TriggerEvent: string;
  GetUserCurrentId: any[];
  SqlAddCurrentAuthorization: number;
  SqlRemoveCurrentAuthorization: number;
  spGetAuthorizedCurrentsForUser: any[];
  spGetUnauthorizedCurrentsForUser: any[];
  isComp339054Visible: "visible" | "hidden";
  isCompcustomeruserauthorizecurrent_7197AuthorizationVisible: "visible" | "hidden";
  isCompcustomeruserauthorizecurrent_727720AuthorizationVisible: "visible" | "hidden";
}

export class CustomerUserAuthorizeCurrent_ScreenBase extends React.PureComponent<
  ICustomerUserAuthorizeCurrent_ScreenProps,
  any
> {
  customeruserauthorizecurrent_230825_value_kuikaSelectBoxRef: React.RefObject<any>;
  customeruserauthorizecurrent_181895_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 861512, PropertyName: "value", Value: "Rol", TagName: "Label4_value" },
        { Id: 283363, PropertyName: "value", Value: "Eposta", TagName: "Label17_0_value" },
        { Id: 871797, PropertyName: "value", Value: "Şirket", TagName: "Label0_0_value" },
        { Id: 691965, PropertyName: "value", Value: "Yetkili Cari ", TagName: "Label0_0_value" },
        { Id: 230825, PropertyName: "placeholder", Value: "Seçiniz...", TagName: "currentSelectbox_placeholder" },
        { Id: 7197, PropertyName: "label", Value: "Ekle", TagName: "Button1_label" },
        {
          Id: 181895,
          PropertyName: "nodatafoundmessage",
          Value: "Veri Bulunaması.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 954226, PropertyName: "title", Value: "Cari Kart No", TagName: "TableColumn1_0_title" },
        { Id: 188716, PropertyName: "title", Value: "Cari Kart İsmi", TagName: "TableColumn1_1_title" },
        { Id: 805828, PropertyName: "title", Value: "Sil", TagName: "Tooltip1_title" },
        { Id: 517978, PropertyName: "value", Value: "Telefon", TagName: "Label0_0_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "b9541369-f115-4558-8371-1a16a37d5ce0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 861512, PropertyName: "value", Value: "Role" },
        { Id: 871797, PropertyName: "value", Value: "Company" },
        { Id: 283363, PropertyName: "value", Value: "E-mail" },
        { Id: 517978, PropertyName: "value", Value: "Phone Number" },
        { Id: 691965, PropertyName: "value", Value: "Authorized Currents" },
        { Id: 230825, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 7197, PropertyName: "label", Value: "Add" },
        { Id: 181895, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 954226, PropertyName: "title", Value: "Current No" },
        { Id: 764480, PropertyName: "value", Value: "[datafield:currentno]" },
        { Id: 188716, PropertyName: "title", Value: "Current Name" },
        { Id: 516909, PropertyName: "value", Value: "[datafield:currentname]" },
        { Id: 805828, PropertyName: "title", Value: "Delete" },
        { Id: 339054, PropertyName: "label", Value: "!-Trigger Current List-!" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.customeruserauthorizecurrent_230825_value_kuikaSelectBoxRef = React.createRef();
    this.customeruserauthorizecurrent_181895_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      spGetUserById: [],
      GetCompanyById: [],
      TriggerEvent: "",
      GetUserCurrentId: [],
      SqlAddCurrentAuthorization: "",
      SqlRemoveCurrentAuthorization: "",
      spGetAuthorizedCurrentsForUser: [],
      spGetUnauthorizedCurrentsForUser: [],
      isComp339054Visible: "hidden",
      isCompcustomeruserauthorizecurrent_7197AuthorizationVisible: "visible",
      isCompcustomeruserauthorizecurrent_727720AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("customeruserauthorizecurrent", "customeruserauthorizecurrent");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CustomerUserAuthorizeCurrentPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcustomeruserauthorizecurrent_7197AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompcustomeruserauthorizecurrent_727720AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("customeruserauthorizecurrent", "customeruserauthorizecurrent");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("customeruserauthorizecurrent", "customeruserauthorizecurrent");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CustomerUserAuthorizeCurrentPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      customeruserauthorizecurrent_342604_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CustomerUserAuthorizeCurrentPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomerUserAuthorizeCurrentPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CustomerUserAuthorizeCurrentPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      lang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userId ?? this.props.screenInputs.userid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CustomerUserAuthorizeCurrent/CustomerUserAuthorizeCurrentPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.customeruserauthorizecurrent_342604_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    formVars.customeruserauthorizecurrent_519958_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.nameSurname : null
    );
    formVars.customeruserauthorizecurrent_699032_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.roleName : null
    );
    formVars.customeruserauthorizecurrent_203874_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.email : null
    );
    formVars.customeruserauthorizecurrent_49718_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.phoneprefix : null
    );
    formVars.customeruserauthorizecurrent_528992_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.phoneNumber : null
    );
    stateVars.GetCompanyById = result?.data.getCompanyById;
    formVars.customeruserauthorizecurrent_482947_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompanyById?.length > 0 ? stateVars.GetCompanyById[0]?.cardName : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomerUserAuthorizeCurrentPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CustomerUserAuthorizeCurrentPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.CustomerUserAuthorizeCurrentComponent_339054_onClick()) return true;

    formVars.customeruserauthorizecurrent_342604_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    formVars.customeruserauthorizecurrent_519958_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.nameSurname : null
    );

    formVars.customeruserauthorizecurrent_699032_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.roleName : null
    );

    formVars.customeruserauthorizecurrent_482947_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompanyById?.length > 0 ? this.state.GetCompanyById[0]?.cardName : null
    );

    formVars.customeruserauthorizecurrent_203874_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.email : null
    );

    formVars.customeruserauthorizecurrent_49718_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.phoneprefix : null
    );

    formVars.customeruserauthorizecurrent_528992_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.phoneNumber : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CustomerUserAuthorizeCurrentComponent_805810_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomerUserAuthorizeCurrentComponent_7197_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "customeruserauthorizecurrent_230825_value",
            "value",
            "spGetUnauthorizedCurrentsForUser",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "customeruserauthorizecurrent_230825_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      currentId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "customeruserauthorizecurrent_230825_value",
            "value",
            "spGetUnauthorizedCurrentsForUser",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      userId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userId ?? this.props.screenInputs.userid,
        "Guid"
      ),
      createdAt_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CustomerUserAuthorizeCurrent/CustomerUserAuthorizeCurrentComponent_7197_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.SqlAddCurrentAuthorization = result?.data.sqlAddCurrentAuthorization;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomerUserAuthorizeCurrentComponent_7197_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CustomerUserAuthorizeCurrentComponent_7197_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.CustomerUserAuthorizeCurrentComponent_339054_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomerUserAuthorizeCurrentComponent_505026_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userId ?? this.props.screenInputs.userid,
        "Guid"
      ),
      currentId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "customeruserauthorizecurrent_181895_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CustomerUserAuthorizeCurrent/CustomerUserAuthorizeCurrentComponent_505026_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SqlRemoveCurrentAuthorization = result?.data.sqlRemoveCurrentAuthorization;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomerUserAuthorizeCurrentComponent_505026_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CustomerUserAuthorizeCurrentComponent_505026_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.CustomerUserAuthorizeCurrentComponent_339054_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CustomerUserAuthorizeCurrentComponent_339054_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      companyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.companyID : null
        ),
        "Guid"
      ),
      companyType_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyType ?? this.props.screenInputs.companytype,
        "string"
      ),
      userId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userId ?? this.props.screenInputs.userid,
        "Guid"
      ),
      companyId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.companyID : null
        ),
        "Guid"
      ),
      companyType_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.companyType ?? this.props.screenInputs.companytype,
        "string"
      ),
      userId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userId ?? this.props.screenInputs.userid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CustomerUserAuthorizeCurrent/CustomerUserAuthorizeCurrentComponent_339054_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetAuthorizedCurrentsForUser = result?.data.spGetAuthorizedCurrentsForUser;

    stateVars.spGetUnauthorizedCurrentsForUser = result?.data.spGetUnauthorizedCurrentsForUser;

    formVars.customeruserauthorizecurrent_230825_options = stateVars.spGetUnauthorizedCurrentsForUser;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CustomerUserAuthorizeCurrentComponent_339054_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CustomerUserAuthorizeCurrentComponent_339054_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_230825 = this.state.spGetUnauthorizedCurrentsForUser;

    stateVars.dataSource_181895 = this.state.spGetAuthorizedCurrentsForUser;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
