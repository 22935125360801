import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProductGroup_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProductGroup_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  GetLanguage: string;
  SetValueOf: any;
  ProductGroupTotalCount: any[];
  TriggerEvent: string;
  SelectCatalogue: any[];
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  SelectProductsGroup: any[];
  ToggleVisibilityOf: string;
  languageLabelResult: string;
  ChangeVisibilityOf: string;
  IfThenElse: boolean;
  fourCompValidation: string;
  SaveCatalogue: number;
  DeleteProductsGroup: number;
  isComp819639Visible: "visible" | "hidden";
  isComp698637Visible: "visible" | "hidden";
  isComp979781Visible: "visible" | "hidden";
  isComp360521Visible: "visible" | "hidden";
  isComp773417Visible: "visible" | "hidden";
  isComp117940Visible: "visible" | "hidden";
  isComp153136Visible: "visible" | "hidden";
  isComp396506Visible: "visible" | "hidden";
  isComp636720Visible: "visible" | "hidden";
  isComp263046Visible: "visible" | "hidden";
  isComp910867Visible: "visible" | "hidden";
  isComp291365Visible: "visible" | "hidden";
  isComp553567Visible: "visible" | "hidden";
  isComp911733Visible: "visible" | "hidden";
  isComp207785Visible: "visible" | "hidden";
  isComp16041Visible: "visible" | "hidden";
  isCompproductgroup_698045AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_819639AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_856751AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_360521AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_773417AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_763471AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_117940AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_841428AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_636720AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_263046AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_365729AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_90023AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_554071AuthorizationVisible: "visible" | "hidden";
  isCompproductgroup_289420AuthorizationEnabled: "enabled" | "disabled";
}

export class ProductGroup_ScreenBase extends React.PureComponent<IProductGroup_ScreenProps, any> {
  productgroup_213830_value_kuikaTableRef: React.RefObject<any>;
  productgroup_134397_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 477547, PropertyName: "value", Value: "Katalog", TagName: "Label0_0_value" },
        { Id: 890967, PropertyName: "value", Value: "EPS Nedir?", TagName: "Label7_value" },
        { Id: 360521, PropertyName: "label", Value: "Kaydet", TagName: "Button3_label" },
        { Id: 773417, PropertyName: "label", Value: "Kaydet", TagName: "Button5_label" },
        { Id: 313120, PropertyName: "value", Value: "Kullanım Alanları", TagName: "Label8_value" },
        { Id: 636720, PropertyName: "label", Value: "Kaydet", TagName: "Button4_label" },
        { Id: 263046, PropertyName: "label", Value: "Kaydet", TagName: "Button6_label" },
        { Id: 808842, PropertyName: "value", Value: "Ürün Grupları", TagName: "Label9_0_value" },
        { Id: 365729, PropertyName: "label", Value: "Ekle", TagName: "Button1_label" },
        {
          Id: 213830,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 616571, PropertyName: "title", Value: "İsim", TagName: "TableColumn0_0_title" },
        { Id: 392898, PropertyName: "title", Value: "Özellikler", TagName: "TableColumn0_1_title" },
        { Id: 20329, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 860430, PropertyName: "label", Value: "Çıkış Yap", TagName: "ContentMenuItem2_2_label" },
        { Id: 433529, PropertyName: "tab", Value: "Türkçe", TagName: "TabItem0_0_tab" },
        { Id: 522521, PropertyName: "tab", Value: "İngilizce", TagName: "TabItem5_tab" },
        { Id: 108508, PropertyName: "tab", Value: "Türkçe", TagName: "TabItem2_0_tab" },
        { Id: 708235, PropertyName: "tab", Value: "İngilizce", TagName: "TabItem2_1_tab" },
        { Id: 369831, PropertyName: "title", Value: "Açıklama", TagName: "TableColumn4_title" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "0692a59b-06c6-4d9f-97e6-7bdc293de893",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 20329, PropertyName: "label", Value: "Profile" },
        { Id: 860430, PropertyName: "label", Value: "Logout" },
        { Id: 477547, PropertyName: "value", Value: "Catalogue" },
        { Id: 207785, PropertyName: "label", Value: "!-Trigger Product Group List-!" },
        { Id: 890967, PropertyName: "value", Value: "What is EPS?" },
        { Id: 433529, PropertyName: "tab", Value: "Turkish" },
        { Id: 522521, PropertyName: "tab", Value: "English" },
        { Id: 360521, PropertyName: "label", Value: "Save" },
        { Id: 773417, PropertyName: "label", Value: "Save" },
        { Id: 313120, PropertyName: "value", Value: "Usage Areas" },
        { Id: 108508, PropertyName: "tab", Value: "Turkish" },
        { Id: 708235, PropertyName: "tab", Value: "English" },
        { Id: 636720, PropertyName: "label", Value: "Save" },
        { Id: 263046, PropertyName: "label", Value: "Save" },
        { Id: 808842, PropertyName: "value", Value: "Product Groups" },
        { Id: 365729, PropertyName: "label", Value: "New" },
        { Id: 213830, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 616571, PropertyName: "title", Value: "Name" },
        { Id: 936762, PropertyName: "value", Value: "[datafield:name]" },
        { Id: 392898, PropertyName: "title", Value: "Properties" },
        { Id: 106049, PropertyName: "value", Value: "[datafield:properties_label]" },
        { Id: 369831, PropertyName: "title", Value: "Description" },
        { Id: 310720, PropertyName: "value", Value: "[datafield:description_label]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.productgroup_213830_value_kuikaTableRef = React.createRef();
    this.productgroup_134397_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      GetLanguage: "",
      SetValueOf: "",
      ProductGroupTotalCount: [],
      TriggerEvent: "",
      SelectCatalogue: [],
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      SelectProductsGroup: [],
      ToggleVisibilityOf: "",
      languageLabelResult: "",
      IfThenElse: false,
      fourCompValidation: "",
      SaveCatalogue: 0,
      DeleteProductsGroup: 0,
      isComp819639Visible: "hidden",
      isComp698637Visible: "hidden",
      isComp979781Visible: "visible",
      isComp360521Visible: "visible",
      isComp773417Visible: "hidden",
      isComp117940Visible: "hidden",
      isComp153136Visible: "visible",
      isComp396506Visible: "visible",
      isComp636720Visible: "visible",
      isComp263046Visible: "hidden",
      isComp910867Visible: "hidden",
      isComp291365Visible: "hidden",
      isComp553567Visible: "hidden",
      isComp911733Visible: "hidden",
      isComp207785Visible: "hidden",
      isComp16041Visible: "hidden",
      isCompproductgroup_698045AuthorizationVisible: "visible",
      isCompproductgroup_819639AuthorizationVisible: "visible",
      isCompproductgroup_856751AuthorizationVisible: "visible",
      isCompproductgroup_360521AuthorizationVisible: "visible",
      isCompproductgroup_773417AuthorizationVisible: "visible",
      isCompproductgroup_763471AuthorizationVisible: "visible",
      isCompproductgroup_117940AuthorizationVisible: "visible",
      isCompproductgroup_841428AuthorizationVisible: "visible",
      isCompproductgroup_636720AuthorizationVisible: "visible",
      isCompproductgroup_263046AuthorizationVisible: "visible",
      isCompproductgroup_365729AuthorizationVisible: "visible",
      isCompproductgroup_90023AuthorizationVisible: "visible",
      isCompproductgroup_554071AuthorizationVisible: "visible",
      isCompproductgroup_289420AuthorizationEnabled: "enabled"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("productgroup", "productgroup");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProductGroupPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_698045AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_819639AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_856751AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "68869d11-d6f3-1804-65bd-383ff48dffb8",
      "Administrator",
      "Editor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_360521AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_773417AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_763471AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_117940AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_841428AuthorizationVisible", []);
    ReactSystemFunctions.disableIsNotAuthorized(this, "isCompproductgroup_289420AuthorizationEnabled", []);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_636720AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_263046AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_365729AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_90023AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompproductgroup_554071AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("productgroup", "productgroup");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Administrator", "Editor"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("productgroup", "productgroup");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProductGroupPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      productgroup_291365_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined,
      productgroup_856751_value: this.state.SelectCatalogue?.at?.(0)?.description ?? undefined,
      productgroup_824107_value: this.state.SelectCatalogue?.at?.(0)?.descriptionEN ?? undefined,
      productgroup_841428_value: this.state.SelectCatalogue?.at?.(0)?.usageAreas ?? undefined,
      productgroup_289420_value: this.state.SelectCatalogue?.at?.(0)?.usageAreasEN ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ProductGroupPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productgroup_819639_value", "433529", null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productgroup_117940_value", "108508", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductGroup/ProductGroupPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProductGroupTotalCount = result?.data.productGroupTotalCount;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.ProductGroupComponent_207785_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductGroup/ProductGroupPageInit3_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCatalogue = result?.data.selectCatalogue;
    formVars.productgroup_979781_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.descriptionLABEL : null
    );
    formVars.productgroup_856751_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.description : null
    );
    formVars.productgroup_824107_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.descriptionEN : null
    );
    formVars.productgroup_396506_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreasLABEL : null
    );
    formVars.productgroup_841428_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreas : null
    );
    formVars.productgroup_289420_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreasEN : null
    );
    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.productgroup_911733_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.productgroup_291365_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp910867Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291365Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp553567Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp911733Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupPageInit4_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp910867Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.productgroup_291365_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp291365Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp553567Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.productgroup_911733_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp911733Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.productgroup_979781_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionLABEL : null
    );

    formVars.productgroup_856751_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.description : null
    );

    formVars.productgroup_824107_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionEN : null
    );

    formVars.productgroup_396506_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasLABEL : null
    );

    formVars.productgroup_841428_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreas : null
    );

    formVars.productgroup_289420_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasEN : null
    );

    formVars.productgroup_134397_total = ReactSystemFunctions.value(
      this,
      this.state.ProductGroupTotalCount?.length > 0 ? this.state.ProductGroupTotalCount[0]?.totalCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProductGroupComponent_456465_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ProductGroup",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "38713",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_20329_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ProductGroup",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "828595",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_860430_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_207785_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      current_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productgroup_134397_value", "current", "", "", "")
        ),
        "number"
      ),
      pageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productgroup_134397_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductGroup/ProductGroupComponent_207785_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectProductsGroup = result?.data.selectProductsGroup;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_207785_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_207785_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_213830 = this.state.SelectProductsGroup;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_698045_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp819639Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp698637Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp979781Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_819639_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_819639_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_819639_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_819639_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.languageLabelResult = result?.data.languageLabelResult;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_819639_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_819639_onChange_if2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp360521Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp773417Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProductGroupComponent_819639_onChange_else2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp773417Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp360521Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProductGroupComponent_819639_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productgroup_819639_value", "value", "", "", "")
        ),
        "433529"
      )
    ) {
      isErrorOccurred = await this.ProductGroupComponent_819639_onChange_if2();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ProductGroupComponent_819639_onChange_else2();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_360521_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_856751_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroup_856751_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_856751_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductGroup/ProductGroupComponent_360521_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_360521_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_360521_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productgroup_819639_value", "522521", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_360521_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_360521_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_856751_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroup_856751_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_824107_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroup_824107_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      description_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_856751_value", "value", "", "", "")
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      usageAreas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreas : null
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("333EDC5F-9C0A-4459-845A-F63333F534B7", "Guid"),
      descriptionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_824107_value", "value", "", "", "")
        ),
        "string"
      ),
      usageAreasEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasEN : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_360521_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCatalogue = result?.data.saveCatalogue;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_360521_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_360521_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.ProductGroupComponent_698045_onClick()) return true;

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_360521_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_360521_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_360521_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCatalogue = result?.data.selectCatalogue;
    formVars.productgroup_979781_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.descriptionLABEL : null
    );
    formVars.productgroup_856751_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.description : null
    );
    formVars.productgroup_824107_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.descriptionEN : null
    );
    formVars.productgroup_396506_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreasLABEL : null
    );
    formVars.productgroup_841428_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreas : null
    );
    formVars.productgroup_289420_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreasEN : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_360521_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_360521_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.productgroup_979781_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionLABEL : null
    );

    formVars.productgroup_856751_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.description : null
    );

    formVars.productgroup_824107_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionEN : null
    );

    formVars.productgroup_396506_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasLABEL : null
    );

    formVars.productgroup_841428_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreas : null
    );

    formVars.productgroup_289420_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasEN : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_773417_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_824107_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroup_824107_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_824107_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductGroup/ProductGroupComponent_773417_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_773417_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_773417_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productgroup_819639_value", "433529", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_773417_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_773417_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_856751_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroup_856751_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_824107_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroup_824107_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      description_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_856751_value", "value", "", "", "")
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      usageAreas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreas : null
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("333EDC5F-9C0A-4459-845A-F63333F534B7", "Guid"),
      descriptionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_824107_value", "value", "", "", "")
        ),
        "string"
      ),
      usageAreasEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasEN : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_773417_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCatalogue = result?.data.saveCatalogue;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_773417_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_773417_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.ProductGroupComponent_698045_onClick()) return true;

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_773417_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_773417_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_773417_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCatalogue = result?.data.selectCatalogue;
    formVars.productgroup_979781_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.descriptionLABEL : null
    );
    formVars.productgroup_856751_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.description : null
    );
    formVars.productgroup_824107_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.descriptionEN : null
    );
    formVars.productgroup_396506_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreasLABEL : null
    );
    formVars.productgroup_841428_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreas : null
    );
    formVars.productgroup_289420_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreasEN : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_773417_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_773417_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.productgroup_979781_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionLABEL : null
    );

    formVars.productgroup_856751_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.description : null
    );

    formVars.productgroup_824107_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionEN : null
    );

    formVars.productgroup_396506_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasLABEL : null
    );

    formVars.productgroup_841428_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreas : null
    );

    formVars.productgroup_289420_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasEN : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_763471_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp117940Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp153136Visible");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp396506Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_117940_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_117940_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_117940_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_117940_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.languageLabelResult = result?.data.languageLabelResult;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_117940_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_117940_onChange_if2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp636720Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp263046Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProductGroupComponent_117940_onChange_else2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp636720Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp263046Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProductGroupComponent_117940_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productgroup_117940_value", "value", "", "", "")
        ),
        "108508"
      )
    ) {
      isErrorOccurred = await this.ProductGroupComponent_117940_onChange_if2();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ProductGroupComponent_117940_onChange_else2();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_636720_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_841428_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "productgroup_841428_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_841428_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductGroup/ProductGroupComponent_636720_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_636720_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_636720_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productgroup_117940_value", "708235", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_636720_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_636720_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_841428_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "productgroup_841428_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_289420_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroup_289420_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      description_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.description : null
        ),
        "string"
      ),
      descriptionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionEN : null
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      usageAreas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_841428_value", "value", "", "", "")
        ),
        "string"
      ),
      usageAreasEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_289420_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("333EDC5F-9C0A-4459-845A-F63333F534B7", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_636720_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCatalogue = result?.data.saveCatalogue;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_636720_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_636720_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.ProductGroupComponent_763471_onClick()) return true;

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_636720_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_636720_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_636720_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCatalogue = result?.data.selectCatalogue;
    formVars.productgroup_979781_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.descriptionLABEL : null
    );
    formVars.productgroup_856751_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.description : null
    );
    formVars.productgroup_824107_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.descriptionEN : null
    );
    formVars.productgroup_396506_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreasLABEL : null
    );
    formVars.productgroup_841428_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreas : null
    );
    formVars.productgroup_289420_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreasEN : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_636720_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_636720_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.productgroup_979781_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionLABEL : null
    );

    formVars.productgroup_856751_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.description : null
    );

    formVars.productgroup_824107_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionEN : null
    );

    formVars.productgroup_396506_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasLABEL : null
    );

    formVars.productgroup_841428_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreas : null
    );

    formVars.productgroup_289420_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasEN : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_263046_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_289420_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroup_289420_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_289420_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductGroup/ProductGroupComponent_263046_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_263046_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_263046_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productgroup_117940_value", "108508", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_263046_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_263046_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_841428_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "productgroup_841428_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_289420_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productgroup_289420_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      description_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.description : null
        ),
        "string"
      ),
      descriptionEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionEN : null
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      usageAreas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_841428_value", "value", "", "", "")
        ),
        "string"
      ),
      usageAreasEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productgroup_289420_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName("333EDC5F-9C0A-4459-845A-F63333F534B7", "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_263046_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCatalogue = result?.data.saveCatalogue;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_263046_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_263046_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.ProductGroupComponent_763471_onClick()) return true;

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_263046_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_263046_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_263046_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCatalogue = result?.data.selectCatalogue;
    formVars.productgroup_979781_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.descriptionLABEL : null
    );
    formVars.productgroup_856751_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.description : null
    );
    formVars.productgroup_824107_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.descriptionEN : null
    );
    formVars.productgroup_396506_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreasLABEL : null
    );
    formVars.productgroup_841428_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreas : null
    );
    formVars.productgroup_289420_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCatalogue?.length > 0 ? stateVars.SelectCatalogue[0]?.usageAreasEN : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_263046_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_263046_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.productgroup_979781_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionLABEL : null
    );

    formVars.productgroup_856751_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.description : null
    );

    formVars.productgroup_824107_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.descriptionEN : null
    );

    formVars.productgroup_396506_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasLABEL : null
    );

    formVars.productgroup_841428_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreas : null
    );

    formVars.productgroup_289420_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCatalogue?.length > 0 ? this.state.SelectCatalogue[0]?.usageAreasEN : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_365729_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("ProductGroupEdit", "productGroupID", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ProductGroup",
      "ProductGroupEdit",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "112755",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_213830_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_213830_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_213830_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_213830_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.languageLabelResult = result?.data.languageLabelResult;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  ProductGroupComponent_251281_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ProductGroupView",
      "productGroupID",
      ReactSystemFunctions.value(this, "productgroup_213830_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ProductGroup",
      "ProductGroupView",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "491358",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_90023_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ProductGroupEdit",
      "productGroupID",
      ReactSystemFunctions.value(this, "productgroup_213830_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ProductGroup",
      "ProductGroupEdit",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "502730",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_554071_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "productgroup_213830_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "4776309d_d2d2_19f8_426d_78e5dc1ac7e4_confirmation",
        this.defaultML,
        "This Product Group will be deleted. Are you sure?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ProductGroup/ProductGroupComponent_554071_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteProductsGroup = result?.data.deleteProductsGroup;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.ProductGroupComponent_554071_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_554071_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_554071_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductGroupComponent_554071_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      current_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productgroup_134397_value", "current", "", "", "")
        ),
        "number"
      ),
      pageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productgroup_134397_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductGroup/ProductGroupComponent_554071_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectProductsGroup = result?.data.selectProductsGroup;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductGroupComponent_554071_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductGroupComponent_554071_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_213830 = this.state.SelectProductsGroup;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductGroupComponent_134397_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.ProductGroupComponent_207785_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [38713, 828595, 112755, 491358, 502730] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ProductGroupPageInit();
    }
  }
}
