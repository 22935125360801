import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAssignedVehicleView_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAssignedVehicleView_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  TriggerEvent: string;
  GoBack: any;
  GetLanguage: string;
  GetOrderList: any[];
  sqlGetVehicleRequestById: any[];
  spVehicleRequestStatus: any[];
  SqlGetCustomerDetailsByCardNo: any[];
  GetDeliveryList: any[];
  updateStatusOfVehicle: any[];
  IfThenElse: boolean;
  getCustomsAgentById: any[];
  getBrokerById: any[];
  sqlVehicleLocationCount: any[];
  spGetVehicleLocations: any[];
  getFrontEndUrl: string;
  getBackEndUrl: string;
  GetUserCurrentId: any[];
  sqlApproveVehicleRequest: number;
  NAVIGATE: any;
  getCustomsAgents: any[];
  ToggleVisibilityOf: string;
  customsAgentSave: number;
  getUsersEmailByRoleAndCompanyAGG: any[];
  SendMail: boolean;
  canSelectBroker: any[];
  Notify: boolean;
  getAllBrokersByFilter: any[];
  ChangeVisibilityOf: string;
  updateBrokerIdonVehicleRequest: number;
  spCheckDocuments: any[];
  getDocumentsName: any[];
  getDocumentsLink: any[];
  UploadFile: any;
  spUploadVehicleDocument: any[];
  spAddVehicleLocation: any[];
  spCurrentsAuthorizedUsers: any[];
  spNotificationTranslate: any[];
  SendNotificationToUsersWithParameters: any;
  SetValueOf: any;
  spSetOrderDelivered: any[];
  sqlGetSurveyId: any[];
  sqlGetUsersByRolesAGG: any[];
  sqlDeleteVehicleLocation: number;
  isComp188401Visible: "visible" | "hidden";
  isComp984754Visible: "visible" | "hidden";
  isComp332860Visible: "visible" | "hidden";
  isComp83322Visible: "visible" | "hidden";
  isComp221232Visible: "visible" | "hidden";
  isComp422610Visible: "visible" | "hidden";
  isComp883895Visible: "visible" | "hidden";
  isComp587160Visible: "visible" | "hidden";
  isComp707288Visible: "visible" | "hidden";
  isComp609812Visible: "visible" | "hidden";
  isComp770317Visible: "visible" | "hidden";
  isComp377814Visible: "visible" | "hidden";
  isComp928987Visible: "visible" | "hidden";
  isComp970034Visible: "visible" | "hidden";
  isComp13035Visible: "visible" | "hidden";
  isComp201Visible: "visible" | "hidden";
  isComp973624Visible: "visible" | "hidden";
  isComp349601Visible: "visible" | "hidden";
  isComp260467Visible: "visible" | "hidden";
  isComp444544Visible: "visible" | "hidden";
  isComp624121Visible: "visible" | "hidden";
  isComp20508Visible: "visible" | "hidden";
  isComp190903Visible: "visible" | "hidden";
  isComp970094Visible: "visible" | "hidden";
  isComp765677Visible: "visible" | "hidden";
  isComp31955Visible: "visible" | "hidden";
  isComp120265Visible: "visible" | "hidden";
  isComp174579Visible: "visible" | "hidden";
  isComp291794Visible: "visible" | "hidden";
  isComp853405Visible: "visible" | "hidden";
  isComp782931Visible: "visible" | "hidden";
  isComp987039Visible: "visible" | "hidden";
  isComp227437Visible: "visible" | "hidden";
  isComp240932Visible: "visible" | "hidden";
  isComp312407Visible: "visible" | "hidden";
  isComp269555Visible: "visible" | "hidden";
  isComp646351Visible: "visible" | "hidden";
  isComp779908Visible: "visible" | "hidden";
  isComp623164Visible: "visible" | "hidden";
  isComp843310Visible: "visible" | "hidden";
  isComp671036Visible: "visible" | "hidden";
  isComp178060Visible: "visible" | "hidden";
  isComp826937Visible: "visible" | "hidden";
  isComp213809Visible: "visible" | "hidden";
  isComp271114Visible: "visible" | "hidden";
  isComp278091Visible: "visible" | "hidden";
  isComp898310Visible: "visible" | "hidden";
  isComp352849Visible: "visible" | "hidden";
  isComp572531Visible: "visible" | "hidden";
  isComp346444Visible: "visible" | "hidden";
  isComp966119Visible: "visible" | "hidden";
  isComp668484Visible: "visible" | "hidden";
  isComp126965Visible: "visible" | "hidden";
  isComp846973Visible: "visible" | "hidden";
  isComp715893Visible: "visible" | "hidden";
  isComp840014Visible: "visible" | "hidden";
  isComp77376Visible: "visible" | "hidden";
  isComp830625Visible: "visible" | "hidden";
  isComp289446Visible: "visible" | "hidden";
  isComp360611Visible: "visible" | "hidden";
  isComp307591Visible: "visible" | "hidden";
  isComp372986Visible: "visible" | "hidden";
  isComp245535Visible: "visible" | "hidden";
  isComp421537Visible: "visible" | "hidden";
  isComp188011Visible: "visible" | "hidden";
  isComp688763Visible: "visible" | "hidden";
  isComp688538Visible: "visible" | "hidden";
  isComp606915Visible: "visible" | "hidden";
  isComp123700Visible: "visible" | "hidden";
  isComp993112Visible: "visible" | "hidden";
  isComp556191Visible: "visible" | "hidden";
  isComp582561Visible: "visible" | "hidden";
  isComp482066Visible: "visible" | "hidden";
  isComp698440Visible: "visible" | "hidden";
  isComp176933Visible: "visible" | "hidden";
  isComp54129Visible: "visible" | "hidden";
  isComp366556Visible: "visible" | "hidden";
  isComp260562Visible: "visible" | "hidden";
  isComp534765Visible: "visible" | "hidden";
  isComp607622Visible: "visible" | "hidden";
  isComp200576Visible: "visible" | "hidden";
  isComp536285Visible: "visible" | "hidden";
  isComp371945Visible: "visible" | "hidden";
  isComp445236Visible: "visible" | "hidden";
  isComp411602Visible: "visible" | "hidden";
  isComp799915Visible: "visible" | "hidden";
  isComp557549Visible: "visible" | "hidden";
  isComp24497Visible: "visible" | "hidden";
  isComp229597Visible: "visible" | "hidden";
  isComp848626Visible: "visible" | "hidden";
  isComp159976Visible: "visible" | "hidden";
  isCompassignedvehicleview_865390AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_60725AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_873369AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_899355AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_201AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_260467AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_315558AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_20508AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_970094AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_414740AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_291794AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_476554AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_19907AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_739529AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_259412AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_426133AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_171620AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_843160AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_632668AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_9095AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_188011AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_530826AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_4484AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_477178AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_260562AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_200576AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_417598AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_536285AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_371945AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_478219AuthorizationVisible: "visible" | "hidden";
  isCompassignedvehicleview_159976AuthorizationVisible: "visible" | "hidden";
}

export class AssignedVehicleView_ScreenBase extends React.PureComponent<IAssignedVehicleView_ScreenProps, any> {
  assignedvehicleview_499488_value_kuikaSelectBoxRef: React.RefObject<any>;
  assignedvehicleview_811923_value_kuikaSelectBoxRef: React.RefObject<any>;
  assignedvehicleview_296683_value_kuikaPlacesAutocompleteInputRef: React.RefObject<any>;
  assignedvehicleview_957942_value_kuikaTableRef: React.RefObject<any>;
  assignedvehicleview_168619_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 685221, PropertyName: "value", Value: "Sipariş Numarası", TagName: "Label0_0_value" },
        { Id: 46425, PropertyName: "value", Value: "Sipariş Tarihi", TagName: "Label0_0_value" },
        { Id: 732102, PropertyName: "value", Value: "Cari İsmi", TagName: "Label0_0_value" },
        { Id: 583345, PropertyName: "value", Value: "Cari Numarası", TagName: "Label0_0_value" },
        { Id: 267211, PropertyName: "value", Value: "Teslimat Ülkesi", TagName: "Label0_0_value" },
        { Id: 216196, PropertyName: "value", Value: "Teslimat Adresi", TagName: "Label0_0_value" },
        { Id: 224999, PropertyName: "value", Value: "Yükleme Adresi", TagName: "Label0_0_value" },
        { Id: 786077, PropertyName: "value", Value: "Yükleme Tarihi", TagName: "Label0_0_value" },
        { Id: 229813, PropertyName: "value", Value: "Navlun", TagName: "Label0_0_value" },
        { Id: 742903, PropertyName: "value", Value: "Araç Talep Bilgileri", TagName: "Label0_0_value" },
        { Id: 496068, PropertyName: "value", Value: "Atayan Kişi", TagName: "Label0_0_value" },
        { Id: 642528, PropertyName: "value", Value: "Email", TagName: "Label0_0_value" },
        { Id: 530934, PropertyName: "value", Value: "Atanma Tarihi", TagName: "Label0_0_value" },
        { Id: 99083, PropertyName: "value", Value: "Fatura Numarası", TagName: "Label0_0_value" },
        { Id: 95668, PropertyName: "value", Value: "Kamyon Plakası", TagName: "Label0_0_value" },
        { Id: 864974, PropertyName: "value", Value: "Dorse Plakası / Konteyner Numarası", TagName: "Label0_0_value" },
        { Id: 746223, PropertyName: "value", Value: "Tahmini Teslimat Tarihi (ETA)", TagName: "Label0_0_value" },
        { Id: 252660, PropertyName: "value", Value: "Sürücü Adı", TagName: "Label0_0_value" },
        { Id: 766898, PropertyName: "value", Value: "Sürücü Telefonu", TagName: "Label0_0_value" },
        { Id: 201, PropertyName: "label", Value: "Onayla", TagName: "Button0_0_label" },
        { Id: 705882, PropertyName: "value", Value: "Atanan Araç Bilgileri", TagName: "Label0_0_value" },
        { Id: 447137, PropertyName: "value", Value: "Telefon", TagName: "Label0_0_value" },
        { Id: 13035, PropertyName: "value", Value: "Araç Ataması Bekleniyor", TagName: "Label0_0_value" },
        { Id: 303193, PropertyName: "value", Value: "Belgeler", TagName: "Label0_0_value" },
        { Id: 774992, PropertyName: "value", Value: "Fatura", TagName: "Label0_0_value" },
        { Id: 779908, PropertyName: "value", Value: "Yüklenmedi", TagName: "Label0_1_value" },
        { Id: 623164, PropertyName: "value", Value: "Belgeyi görüntüle", TagName: "Label2_value" },
        { Id: 739529, PropertyName: "label", Value: "Yükle", TagName: "Button0_0_label" },
        { Id: 213239, PropertyName: "value", Value: "Analiz Sertifikası", TagName: "Label0_0_value" },
        { Id: 346444, PropertyName: "value", Value: "Yüklenmedi", TagName: "Label0_1_value" },
        { Id: 966119, PropertyName: "value", Value: "Belgeyi görüntüle", TagName: "Label3_value" },
        { Id: 171620, PropertyName: "label", Value: "Yükle", TagName: "Button0_0_label" },
        { Id: 913054, PropertyName: "value", Value: "Çeki Listesi", TagName: "Label0_0_value" },
        { Id: 289446, PropertyName: "value", Value: "Yüklenmedi", TagName: "Label0_1_value" },
        { Id: 360611, PropertyName: "value", Value: "Belgeyi görüntüle", TagName: "Label4_value" },
        { Id: 632668, PropertyName: "label", Value: "Yükle", TagName: "Button0_0_label" },
        { Id: 125562, PropertyName: "value", Value: "Lot Bazlı Analiz Sertifikası", TagName: "Label0_0_value" },
        { Id: 846973, PropertyName: "value", Value: "Yüklenmedi", TagName: "Label0_1_value" },
        { Id: 715893, PropertyName: "value", Value: "Belgeyi görüntüle", TagName: "Label5_value" },
        { Id: 843160, PropertyName: "label", Value: "Yükle", TagName: "Button0_0_label" },
        { Id: 113482, PropertyName: "value", Value: "Gümrük Beyannamesi", TagName: "Label0_0_value" },
        { Id: 606915, PropertyName: "value", Value: "Yüklenmedi", TagName: "Label0_1_value" },
        { Id: 123700, PropertyName: "value", Value: "Belgeyi görüntüle", TagName: "Label6_value" },
        { Id: 530826, PropertyName: "label", Value: "Yükle", TagName: "Button0_0_label" },
        { Id: 583111, PropertyName: "value", Value: "Temel Dolaşım Belgesi - ATR", TagName: "Label0_0_value" },
        { Id: 582561, PropertyName: "value", Value: "Yüklenmedi", TagName: "Label0_1_value" },
        { Id: 482066, PropertyName: "value", Value: "Belgeyi görüntüle", TagName: "Label7_value" },
        { Id: 4484, PropertyName: "label", Value: "Yükle", TagName: "Button0_0_label" },
        { Id: 198662, PropertyName: "value", Value: "Temel Dolaşım Belgesi - EURO 1", TagName: "Label0_0_value" },
        { Id: 54129, PropertyName: "value", Value: "Yüklenmedi", TagName: "Label0_1_value" },
        { Id: 366556, PropertyName: "value", Value: "Belgeyi görüntüle", TagName: "Label0_2_value" },
        { Id: 477178, PropertyName: "label", Value: "Yükle", TagName: "Button0_0_label" },
        { Id: 109681, PropertyName: "value", Value: "Araç Atama", TagName: "Label0_1_value" },
        { Id: 387926, PropertyName: "value", Value: "Araç Atandı", TagName: "Label0_1_value" },
        { Id: 425638, PropertyName: "value", Value: "Araç Onay", TagName: "Label0_1_value" },
        { Id: 721516, PropertyName: "value", Value: "Araç Onay", TagName: "Label0_1_value" },
        { Id: 157583, PropertyName: "value", Value: "Araç Onaylandı", TagName: "Label0_1_value" },
        { Id: 163746, PropertyName: "value", Value: "Araç Yükleme", TagName: "Label0_1_value" },
        { Id: 496842, PropertyName: "value", Value: "Araç Yükleme", TagName: "Label0_1_value" },
        { Id: 901446, PropertyName: "value", Value: "Araç Yüklendi", TagName: "Label0_1_value" },
        { Id: 846146, PropertyName: "value", Value: "Teslim Ediliyor", TagName: "Label0_1_value" },
        { Id: 744344, PropertyName: "value", Value: "Teslim Ediliyor", TagName: "Label0_1_value" },
        { Id: 167614, PropertyName: "value", Value: "Teslim Edildi", TagName: "Label0_1_value" },
        { Id: 762279, PropertyName: "value", Value: "Teslimat Bilgileri", TagName: "Label0_0_value" },
        { Id: 394592, PropertyName: "value", Value: "Lojistik Tedarikçi Firma", TagName: "Label0_0_value" },
        { Id: 766483, PropertyName: "value", Value: "Talep Tarihi", TagName: "Label0_0_value" },
        { Id: 296683, PropertyName: "placeholder", Value: "Lokasyon Arama", TagName: "LocationSearch1_placeholder" },
        { Id: 417598, PropertyName: "label", Value: "Ekle", TagName: "Button11_label" },
        {
          Id: 957942,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 690932, PropertyName: "title", Value: "Adres", TagName: "TableColumn1_2_title" },
        { Id: 749879, PropertyName: "title", Value: "Tarih", TagName: "TableColumn1_3_title" },
        { Id: 995252, PropertyName: "value", Value: "Gümrükçü Bilgileri", TagName: "Label92_value" },
        { Id: 198020, PropertyName: "value", Value: "Gümrükçü", TagName: "Label0_0_value" },
        { Id: 499488, PropertyName: "placeholder", Value: "Gümrükçü Seçiniz...", TagName: "SelectBox1_placeholder" },
        { Id: 547601, PropertyName: "value", Value: "Gümrükçü", TagName: "Label0_0_value" },
        { Id: 661320, PropertyName: "value", Value: "Telefon", TagName: "Label0_0_value" },
        { Id: 196240, PropertyName: "value", Value: "İrsaliye", TagName: "Label0_0_value" },
        { Id: 373708, PropertyName: "value", Value: "Müşteri Referans Numarası", TagName: "Label106_value" },
        { Id: 444544, PropertyName: "value", Value: "Fatura Görüntüle", TagName: "Label1_value" },
        { Id: 624121, PropertyName: "value", Value: "Fatura henüz yüklenmedi", TagName: "Label9_value" },
        { Id: 446761, PropertyName: "value", Value: "Onaylayan", TagName: "Label0_2_value" },
        { Id: 415819, PropertyName: "value", Value: "Onay Tarihi", TagName: "Label0_1_value" },
        { Id: 259412, PropertyName: "label", Value: "Yükle", TagName: "Button0_0_label" },
        { Id: 406950, PropertyName: "value", Value: "İrsaliye No", TagName: "Label0_0_value" },
        { Id: 178060, PropertyName: "value", Value: "Yüklenmedi", TagName: "Label0_1_value" },
        { Id: 826937, PropertyName: "value", Value: "Belgeyi görüntüle", TagName: "Label0_2_value" },
        { Id: 803401, PropertyName: "value", Value: "Lokasyonlar", TagName: "Label0_0_value" },
        { Id: 229597, PropertyName: "value", Value: "Yüklenmedi", TagName: "Label0_0_value" },
        { Id: 848626, PropertyName: "value", Value: "Belgeyi görüntüle", TagName: "Label0_1_value" },
        { Id: 478219, PropertyName: "label", Value: "Yükle", TagName: "Button0_0_label" },
        { Id: 536285, PropertyName: "label", Value: "Teslimatı Sonlandır", TagName: "Button17_label" },
        { Id: 371945, PropertyName: "label", Value: "Teslimatı Sonlandır", TagName: "Button1_label" },
        { Id: 411602, PropertyName: "value", Value: "Teslim CMR Belgesi", TagName: "Label0_0_value" },
        { Id: 28047, PropertyName: "value", Value: "Atama Bilgileri", TagName: "Label1_value" },
        { Id: 268730, PropertyName: "value", Value: "Tedarikçi", TagName: "Label0_0_value" },
        { Id: 799915, PropertyName: "value", Value: "Teslim İrsaliyesi", TagName: "Label0_0_value" },
        { Id: 306440, PropertyName: "value", Value: "E-Fatura", TagName: "Label0_0_value" },
        { Id: 569037, PropertyName: "value", Value: "Sürücü Belgeleri", TagName: "Label0_0_value" },
        { Id: 278091, PropertyName: "value", Value: "Yüklenmedi", TagName: "Label0_0_value" },
        { Id: 426133, PropertyName: "label", Value: "Yükle", TagName: "Button0_0_label" },
        { Id: 245535, PropertyName: "value", Value: "Yüklenmedi", TagName: "Label0_0_value" },
        { Id: 9095, PropertyName: "label", Value: "Yükle", TagName: "Button0_0_label" },
        {
          Id: 31955,
          PropertyName: "value",
          Value: "Gümrükçü seçmeniz gerekiyor çünkü sipariş ülkesi",
          TagName: "Label0_0_value"
        },
        { Id: 300658, PropertyName: "value", Value: "Broker Bİlgileri", TagName: "Label0_0_value" },
        {
          Id: 782931,
          PropertyName: "value",
          Value: "Broker seçmeniz gerekiyor çünkü taşıma tipi",
          TagName: "Label1_value"
        },
        { Id: 525505, PropertyName: "value", Value: "Seçilen Broker", TagName: "Label0_0_value" },
        { Id: 811923, PropertyName: "placeholder", Value: "Broker Seçiniz...", TagName: "sbBrokerId_placeholder" },
        { Id: 824865, PropertyName: "value", Value: "E-posta", TagName: "Label0_0_value" },
        { Id: 924092, PropertyName: "value", Value: "Telefon ", TagName: "Label0_0_value" },
        { Id: 932793, PropertyName: "title", Value: "Sil", TagName: "Tooltip1_title" },
        {
          Id: 13813883,
          PropertyName: "52654351-469c-5bb8-35f0-0044e608018c_confirmation",
          Value:
            "E-fatura yüklendikten sonra gümrükçü / broker e-posta yoluyla bilgilendirilecek. Devam etmek istiyor musunuz?",
          TagName: "UploadFile_confirmation",
          IsConfirmation: true
        },
        { Id: 869501, PropertyName: "value", Value: "İsim", TagName: "Label13_value" },
        { Id: 987347, PropertyName: "label", Value: "Tüm Verileri Tazele", TagName: "Button1_label" },
        { Id: 377955, PropertyName: "label", Value: "Belgeleri Tazele", TagName: "Button0_1_label" },
        { Id: 48226, PropertyName: "label", Value: "Lokasyonları Tazele", TagName: "Button1_label" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "133f9552-c820-4594-a27a-6f9691c4896c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 987347, PropertyName: "label", Value: "Refresh All" },
        { Id: 109681, PropertyName: "value", Value: "Vehicle Assignment" },
        { Id: 387926, PropertyName: "value", Value: "Vehicle Assigned" },
        { Id: 425638, PropertyName: "value", Value: "Vehicle Approvement" },
        { Id: 721516, PropertyName: "value", Value: "Vehicle Approvement" },
        { Id: 157583, PropertyName: "value", Value: "Vehicle Approved" },
        { Id: 163746, PropertyName: "value", Value: "Vehicle Loading" },
        { Id: 496842, PropertyName: "value", Value: "Vehicle Loading" },
        { Id: 901446, PropertyName: "value", Value: "Vehicle Loaded" },
        { Id: 846146, PropertyName: "value", Value: "Out of Delivery" },
        { Id: 744344, PropertyName: "value", Value: "Out of Delivery" },
        { Id: 167614, PropertyName: "value", Value: "Delivered" },
        { Id: 762279, PropertyName: "value", Value: "Delivery Information" },
        { Id: 732102, PropertyName: "value", Value: "Customer Name" },
        { Id: 373708, PropertyName: "value", Value: "Customer Referance Number" },
        { Id: 583345, PropertyName: "value", Value: "Card Number" },
        { Id: 46425, PropertyName: "value", Value: "Order Date" },
        { Id: 685221, PropertyName: "value", Value: "Order Number" },
        { Id: 267211, PropertyName: "value", Value: "Shipping Country" },
        { Id: 216196, PropertyName: "value", Value: "Shipping Address" },
        { Id: 406950, PropertyName: "value", Value: "Waybill Number" },
        { Id: 742903, PropertyName: "value", Value: "Requested Vehicle Information" },
        { Id: 766483, PropertyName: "value", Value: "Requested at" },
        { Id: 394592, PropertyName: "value", Value: "Logistic Supplier" },
        { Id: 786077, PropertyName: "value", Value: "Loading Date" },
        { Id: 229813, PropertyName: "value", Value: "Freigh" },
        { Id: 224999, PropertyName: "value", Value: "Loading Address" },
        { Id: 705882, PropertyName: "value", Value: "Assigned Vehicle Information" },
        { Id: 13035, PropertyName: "value", Value: "Waiting for Vehicle Assignment" },
        { Id: 201, PropertyName: "label", Value: "Approve" },
        { Id: 446761, PropertyName: "value", Value: "Approved By" },
        { Id: 415819, PropertyName: "value", Value: "Approval Date" },
        { Id: 746223, PropertyName: "value", Value: "Estimated Delivery Date (ETA)" },
        { Id: 99083, PropertyName: "value", Value: "Invoice Number" },
        { Id: 444544, PropertyName: "value", Value: "View Invoice" },
        { Id: 624121, PropertyName: "value", Value: "Invoice is not uploaded" },
        { Id: 95668, PropertyName: "value", Value: "Truck Plate Number" },
        { Id: 864974, PropertyName: "value", Value: "Trailer Plate Number / Container Number" },
        { Id: 252660, PropertyName: "value", Value: "Driver Name" },
        { Id: 766898, PropertyName: "value", Value: "Driver Phone" },
        { Id: 28047, PropertyName: "value", Value: "Assignment Info" },
        { Id: 268730, PropertyName: "value", Value: "Supplier" },
        { Id: 496068, PropertyName: "value", Value: "Assigned By" },
        { Id: 530934, PropertyName: "value", Value: "Assigne Date" },
        { Id: 642528, PropertyName: "value", Value: "Email" },
        { Id: 447137, PropertyName: "value", Value: "Phone" },
        { Id: 995252, PropertyName: "value", Value: "Customs Agent Information" },
        { Id: 31955, PropertyName: "value", Value: "Custom Agent is required because Order country is" },
        { Id: 198020, PropertyName: "value", Value: "Customs Agent" },
        { Id: 499488, PropertyName: "placeholder", Value: "Choose Customs Agent..." },
        { Id: 547601, PropertyName: "value", Value: "Customs Agent" },
        { Id: 661320, PropertyName: "value", Value: "Phone" },
        { Id: 300658, PropertyName: "value", Value: "Broker Information" },
        { Id: 782931, PropertyName: "value", Value: "Broker is required because transport type is" },
        { Id: 525505, PropertyName: "value", Value: "Selected Broker" },
        { Id: 811923, PropertyName: "placeholder", Value: "Choose Broker..." },
        { Id: 869501, PropertyName: "value", Value: "Name" },
        { Id: 824865, PropertyName: "value", Value: "E-mail" },
        { Id: 924092, PropertyName: "value", Value: "Phone" },
        { Id: 303193, PropertyName: "value", Value: "Documents" },
        { Id: 240932, PropertyName: "label", Value: "!-documentStatusTrigger-!" },
        { Id: 312407, PropertyName: "label", Value: "Send E-mail" },
        { Id: 377955, PropertyName: "label", Value: "Refresh Documents" },
        { Id: 774992, PropertyName: "value", Value: "Commercial Invoice" },
        { Id: 779908, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 739529, PropertyName: "label", Value: "Upload" },
        { Id: 196240, PropertyName: "value", Value: "Waybill" },
        { Id: 178060, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 259412, PropertyName: "label", Value: "Upload" },
        { Id: 306440, PropertyName: "value", Value: "E-Invoice" },
        { Id: 278091, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 426133, PropertyName: "label", Value: "Upload" },
        { Id: 213239, PropertyName: "value", Value: "Certificate of Analysis" },
        { Id: 346444, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 171620, PropertyName: "label", Value: "Upload" },
        { Id: 125562, PropertyName: "value", Value: "Certificate of Lot-Based Analysis" },
        { Id: 846973, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 843160, PropertyName: "label", Value: "Upload" },
        { Id: 913054, PropertyName: "value", Value: "Packing List" },
        { Id: 289446, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 632668, PropertyName: "label", Value: "Upload" },
        { Id: 569037, PropertyName: "value", Value: "Truck Driver" },
        { Id: 245535, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 9095, PropertyName: "label", Value: "Upload" },
        { Id: 113482, PropertyName: "value", Value: "Customs Declaration" },
        { Id: 606915, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 530826, PropertyName: "label", Value: "Upload" },
        { Id: 583111, PropertyName: "value", Value: "Basic Circulation Document - 1" },
        { Id: 582561, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 4484, PropertyName: "label", Value: "Upload" },
        { Id: 198662, PropertyName: "value", Value: "Basic Circulation Document - 2" },
        { Id: 54129, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 477178, PropertyName: "label", Value: "Upload" },
        { Id: 803401, PropertyName: "value", Value: "Locations" },
        { Id: 534765, PropertyName: "label", Value: "addLocationTrigger" },
        { Id: 607622, PropertyName: "label", Value: "SetOrderDeliveredTrigger" },
        { Id: 48226, PropertyName: "label", Value: "Refresh Locations" },
        { Id: 296683, PropertyName: "placeholder", Value: "Location search..." },
        { Id: 417598, PropertyName: "label", Value: "Add" },
        { Id: 536285, PropertyName: "label", Value: "Set as Delivered" },
        { Id: 371945, PropertyName: "label", Value: "Set as Delivered" },
        { Id: 411602, PropertyName: "value", Value: "CMR (Convention Marchandise Routier) Document" },
        { Id: 799915, PropertyName: "value", Value: "Delivery Waybill" },
        { Id: 229597, PropertyName: "value", Value: "Not uploaded yet" },
        { Id: 478219, PropertyName: "label", Value: "Upload" },
        { Id: 957942, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 690932, PropertyName: "title", Value: "Address" },
        { Id: 347361, PropertyName: "value", Value: "[datafield:address]" },
        { Id: 749879, PropertyName: "title", Value: "Date" },
        { Id: 923653, PropertyName: "value", Value: "[datafield:createddate]" },
        { Id: 932793, PropertyName: "title", Value: "Delete" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.assignedvehicleview_499488_value_kuikaSelectBoxRef = React.createRef();
    this.assignedvehicleview_811923_value_kuikaSelectBoxRef = React.createRef();
    this.assignedvehicleview_296683_value_kuikaPlacesAutocompleteInputRef = React.createRef();
    this.assignedvehicleview_957942_value_kuikaTableRef = React.createRef();
    this.assignedvehicleview_168619_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      TriggerEvent: "",
      GoBack: "",
      GetLanguage: "",
      GetOrderList: [],
      sqlGetVehicleRequestById: [],
      spVehicleRequestStatus: [],
      SqlGetCustomerDetailsByCardNo: [],
      IfThenElse: false,
      getCustomsAgentById: [],
      getBrokerById: [],
      sqlVehicleLocationCount: [],
      spGetVehicleLocations: [],
      getFrontEndUrl: "",
      getBackEndUrl: "",
      GetUserCurrentId: [],
      sqlApproveVehicleRequest: 0,
      NAVIGATE: "",
      getCustomsAgents: [],
      ToggleVisibilityOf: "",
      customsAgentSave: 0,
      getUsersEmailByRoleAndCompanyAGG: [],
      SendMail: false,
      canSelectBroker: [],
      Notify: false,
      getAllBrokersByFilter: [],
      ChangeVisibilityOf: "",
      updateBrokerIdonVehicleRequest: "",
      spCheckDocuments: [],
      getDocumentsName: [],
      getDocumentsLink: [],
      UploadFile: "",
      spUploadVehicleDocument: [],
      spAddVehicleLocation: [],
      spCurrentsAuthorizedUsers: [],
      spNotificationTranslate: [],
      SendNotificationToUsersWithParameters: "",
      SetValueOf: "",
      GetDeliveryList: [],
      spSetOrderDelivered: [],
      sqlGetSurveyId: [],
      sqlGetUsersByRolesAGG: [],
      sqlDeleteVehicleLocation: "",
      isComp188401Visible: "hidden",
      isComp984754Visible: "hidden",
      isComp332860Visible: "hidden",
      isComp83322Visible: "hidden",
      isComp221232Visible: "hidden",
      isComp422610Visible: "hidden",
      isComp883895Visible: "hidden",
      isComp587160Visible: "hidden",
      isComp707288Visible: "hidden",
      isComp609812Visible: "hidden",
      isComp770317Visible: "hidden",
      isComp377814Visible: "hidden",
      isComp928987Visible: "hidden",
      isComp970034Visible: "hidden",
      isComp13035Visible: "hidden",
      isComp201Visible: "hidden",
      isComp973624Visible: "hidden",
      isComp349601Visible: "hidden",
      isComp260467Visible: "hidden",
      isComp444544Visible: "hidden",
      isComp624121Visible: "hidden",
      isComp20508Visible: "hidden",
      isComp190903Visible: "hidden",
      isComp970094Visible: "hidden",
      isComp765677Visible: "hidden",
      isComp31955Visible: "hidden",
      isComp120265Visible: "hidden",
      isComp174579Visible: "hidden",
      isComp291794Visible: "hidden",
      isComp853405Visible: "hidden",
      isComp782931Visible: "hidden",
      isComp987039Visible: "hidden",
      isComp227437Visible: "hidden",
      isComp240932Visible: "hidden",
      isComp312407Visible: "hidden",
      isComp269555Visible: "hidden",
      isComp646351Visible: "hidden",
      isComp779908Visible: "hidden",
      isComp623164Visible: "hidden",
      isComp843310Visible: "hidden",
      isComp671036Visible: "hidden",
      isComp178060Visible: "hidden",
      isComp826937Visible: "hidden",
      isComp213809Visible: "hidden",
      isComp271114Visible: "hidden",
      isComp278091Visible: "hidden",
      isComp898310Visible: "hidden",
      isComp352849Visible: "hidden",
      isComp572531Visible: "hidden",
      isComp346444Visible: "hidden",
      isComp966119Visible: "hidden",
      isComp668484Visible: "hidden",
      isComp126965Visible: "hidden",
      isComp846973Visible: "hidden",
      isComp715893Visible: "hidden",
      isComp840014Visible: "hidden",
      isComp77376Visible: "hidden",
      isComp830625Visible: "hidden",
      isComp289446Visible: "hidden",
      isComp360611Visible: "hidden",
      isComp307591Visible: "hidden",
      isComp372986Visible: "hidden",
      isComp245535Visible: "hidden",
      isComp421537Visible: "hidden",
      isComp188011Visible: "hidden",
      isComp688763Visible: "hidden",
      isComp688538Visible: "hidden",
      isComp606915Visible: "hidden",
      isComp123700Visible: "hidden",
      isComp993112Visible: "hidden",
      isComp556191Visible: "hidden",
      isComp582561Visible: "hidden",
      isComp482066Visible: "hidden",
      isComp698440Visible: "hidden",
      isComp176933Visible: "hidden",
      isComp54129Visible: "hidden",
      isComp366556Visible: "hidden",
      isComp260562Visible: "hidden",
      isComp534765Visible: "hidden",
      isComp607622Visible: "hidden",
      isComp200576Visible: "hidden",
      isComp536285Visible: "hidden",
      isComp371945Visible: "hidden",
      isComp445236Visible: "hidden",
      isComp411602Visible: "hidden",
      isComp799915Visible: "hidden",
      isComp557549Visible: "hidden",
      isComp24497Visible: "hidden",
      isComp229597Visible: "hidden",
      isComp848626Visible: "hidden",
      isComp159976Visible: "hidden",
      isCompassignedvehicleview_865390AuthorizationVisible: "visible",
      isCompassignedvehicleview_60725AuthorizationVisible: "visible",
      isCompassignedvehicleview_873369AuthorizationVisible: "visible",
      isCompassignedvehicleview_899355AuthorizationVisible: "visible",
      isCompassignedvehicleview_201AuthorizationVisible: "visible",
      isCompassignedvehicleview_260467AuthorizationVisible: "visible",
      isCompassignedvehicleview_315558AuthorizationVisible: "visible",
      isCompassignedvehicleview_20508AuthorizationVisible: "visible",
      isCompassignedvehicleview_970094AuthorizationVisible: "visible",
      isCompassignedvehicleview_414740AuthorizationVisible: "visible",
      isCompassignedvehicleview_291794AuthorizationVisible: "visible",
      isCompassignedvehicleview_476554AuthorizationVisible: "visible",
      isCompassignedvehicleview_19907AuthorizationVisible: "visible",
      isCompassignedvehicleview_739529AuthorizationVisible: "visible",
      isCompassignedvehicleview_259412AuthorizationVisible: "visible",
      isCompassignedvehicleview_426133AuthorizationVisible: "visible",
      isCompassignedvehicleview_171620AuthorizationVisible: "visible",
      isCompassignedvehicleview_843160AuthorizationVisible: "visible",
      isCompassignedvehicleview_632668AuthorizationVisible: "visible",
      isCompassignedvehicleview_9095AuthorizationVisible: "visible",
      isCompassignedvehicleview_188011AuthorizationVisible: "visible",
      isCompassignedvehicleview_530826AuthorizationVisible: "visible",
      isCompassignedvehicleview_4484AuthorizationVisible: "visible",
      isCompassignedvehicleview_477178AuthorizationVisible: "visible",
      isCompassignedvehicleview_260562AuthorizationVisible: "visible",
      isCompassignedvehicleview_200576AuthorizationVisible: "visible",
      isCompassignedvehicleview_417598AuthorizationVisible: "visible",
      isCompassignedvehicleview_536285AuthorizationVisible: "visible",
      isCompassignedvehicleview_371945AuthorizationVisible: "visible",
      isCompassignedvehicleview_478219AuthorizationVisible: "visible",
      isCompassignedvehicleview_159976AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("assignedvehicleview", "assignedvehicleview");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.AssignedVehicleViewPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_865390AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_60725AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "LogisticSupplier",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_873369AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Logistic",
      "LogisticSupplier",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_899355AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "LogisticSupplier",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_201AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_260467AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_315558AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Logistic",
      "LogisticSupplier",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_20508AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "Logistic",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_970094AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_414740AuthorizationVisible", [
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "Administrator",
      "Sale",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_291794AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_476554AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "Administrator",
      "Sale"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_19907AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "e6c1fd52-eece-7ba2-b39e-d53dabf91a54",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "CustomsAgent",
      "LogisticSupplier",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_739529AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "Administrator",
      "Sale"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_259412AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_426133AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "Administrator",
      "Finance"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_171620AuthorizationVisible", [
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "Sale"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_843160AuthorizationVisible", [
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "Quality"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_632668AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "Administrator",
      "Sale"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_9095AuthorizationVisible", [
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "Sale"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_188011AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "e6c1fd52-eece-7ba2-b39e-d53dabf91a54",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "CustomsAgent",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_530826AuthorizationVisible", [
      "e6c1fd52-eece-7ba2-b39e-d53dabf91a54",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "CustomsAgent"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_4484AuthorizationVisible", [
      "e6c1fd52-eece-7ba2-b39e-d53dabf91a54",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "CustomsAgent"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_477178AuthorizationVisible", [
      "e6c1fd52-eece-7ba2-b39e-d53dabf91a54",
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "Administrator",
      "CustomsAgent"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_260562AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "b8112198-5018-9048-bdc6-17f684ae6bb4",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "619b2ffa-5e84-3bb3-272a-ce40b100df44",
      "fbfb759a-3a06-7d47-1005-2e93219e2fe1",
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "LogisticSupplier",
      "Finance",
      "Distributor"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_200576AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_417598AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "Logistic",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_536285AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "Logistic",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_371945AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Logistic",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_478219AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "LogisticSupplier"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompassignedvehicleview_159976AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "022a7485-39ce-7977-4602-f04702f8e3d1",
      "Administrator",
      "Logistic",
      "LogisticSupplier"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("assignedvehicleview", "assignedvehicleview");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = [
      "Administrator",
      "Sale",
      "Logistic",
      "Quality",
      "CustomsAgent",
      "LogisticSupplier",
      "Finance",
      "Distributor"
    ];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("assignedvehicleview", "assignedvehicleview");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AssignedVehicleViewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      assignedvehicleview_499488_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.customsAgentId ?? undefined,
      assignedvehicleview_811923_value: this.state.sqlGetVehicleRequestById?.at?.(0)?.brokerId ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  AssignedVehicleViewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      OrderNumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      CustomerCardName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentNo_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AssignedVehicleView/AssignedVehicleViewPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetOrderList = result?.data.getOrderList;
    formVars.assignedvehicleview_239304_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardname : null
    );
    formVars.assignedvehicleview_225970_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.custrefno : null
    );
    formVars.assignedvehicleview_282384_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.cardcode : null
    );
    formVars.assignedvehicleview_180459_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    formVars.assignedvehicleview_543657_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.country : null
    );
    formVars.assignedvehicleview_462529_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.shiptoaddress : null
    );
    formVars.assignedvehicleview_120265_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.country : null
    );
    formVars.assignedvehicleview_987039_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.transpname : null
    );
    stateVars.isComp840014Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.isabroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.isabroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.assignedvehicleview_948478_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );
    formVars.assignedvehicleview_934636_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.createdDate : null
    );
    formVars.assignedvehicleview_260290_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );
    formVars.assignedvehicleview_200182_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
    );
    formVars.assignedvehicleview_942340_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
    );
    formVars.assignedvehicleview_416530_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
    );
    formVars.assignedvehicleview_286122_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );
    formVars.assignedvehicleview_809542_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approverFullName : null
    );
    formVars.assignedvehicleview_806706_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approvedAt : null
    );
    formVars.assignedvehicleview_722340_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );
    formVars.assignedvehicleview_871054_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );
    formVars.assignedvehicleview_799724_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );
    formVars.assignedvehicleview_53508_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber : null
    );
    formVars.assignedvehicleview_871532_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverFullName : null
    );
    formVars.assignedvehicleview_409307_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.formattedPhone : null
    );
    formVars.assignedvehicleview_360114_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );
    formVars.assignedvehicleview_339215_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignerFullName : null
    );
    formVars.assignedvehicleview_28160_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignedAt : null
    );
    formVars.assignedvehicleview_12761_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignerEmail : null
    );
    formVars.assignedvehicleview_763411_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.assignerFormattedPhone
        : null
    );
    formVars.assignedvehicleview_499488_value =
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null;
    formVars.assignedvehicleview_811923_value =
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.brokerId : null;
    formVars.assignedvehicleview_612905_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );
    stateVars.isComp970034Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13035Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp201Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973624Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349601Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260467Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444544Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp20508Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp190903Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp31955Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp120265Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp174579Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291794Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782931Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp987039Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227437Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260562Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp200576Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536285Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp371945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp445236Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp411602Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159976Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spVehicleRequestStatus = result?.data.spVehicleRequestStatus;
    stateVars.isComp332860Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleAssignment : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83322Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleAssignment : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp221232Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleApprovement : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422610Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleApprovement : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp883895Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleApprovement : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587160Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleLoading : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp707288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleLoading : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp609812Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleLoading : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp770317Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleDelivered : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp377814Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleDelivered : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp928987Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleDelivered : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SqlGetCustomerDetailsByCardNo = result?.data.sqlGetCustomerDetailsByCardNo;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit_if2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewPageInit_if21_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit_if21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      OrderNumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      DeliveryNumber_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewPageInit_if21_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetDeliveryList = result?.data.getDeliveryList;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit_if22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit_if22_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit_if22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      edeliveryNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetDeliveryList?.length > 0 ? this.state.GetDeliveryList[0]?.edeliveryno : null
        ),
        "string"
      ),
      vehicleRequestStatus_0: ReactSystemFunctions.convertToTypeByName(40, "number"),
      orderNumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      orderStatus_0: ReactSystemFunctions.convertToTypeByName(10, "number")
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.GetDeliveryList?.length > 0 ? this.state.GetDeliveryList[0]?.edeliveryno : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewPageInit_if22_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.updateStatusOfVehicle = result?.data.updateStatusOfVehicle;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  AssignedVehicleViewPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docstatus : null
        ),
        "C"
      )
    ) {
      isErrorOccurred = await this.AssignedVehicleViewPageInit_if2();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId
            : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewPageInit3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.getCustomsAgentById = result?.data.getCustomsAgentById;
      formVars.assignedvehicleview_534792_value = ReactSystemFunctions.toString(
        this,
        stateVars.getCustomsAgentById?.length > 0 ? stateVars.getCustomsAgentById[0]?.cardName : null
      );
      formVars.assignedvehicleview_272389_value = ReactSystemFunctions.toString(
        this,
        stateVars.getCustomsAgentById?.length > 0 ? stateVars.getCustomsAgentById[0]?.formattedPhone : null
      );
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      brokerId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.brokerId : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.brokerId : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewPageInit4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.getBrokerById = result?.data.getBrokerById;
      formVars.assignedvehicleview_937800_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.fullname : null
      );
      formVars.assignedvehicleview_620093_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.email : null
      );
      formVars.assignedvehicleview_416930_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.fullphone : null
      );
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewPageInit6_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      vehicleId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      vehicleId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AssignedVehicleView/AssignedVehicleViewPageInit6_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sqlVehicleLocationCount = result?.data.sqlVehicleLocationCount;
    stateVars.spGetVehicleLocations = result?.data.spGetVehicleLocations;

    stateVars.getFrontEndUrl = result?.data.getFrontEndUrl;
    stateVars.getBackEndUrl = result?.data.getBackEndUrl;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewPageInit7_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp332860Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spVehicleRequestStatus?.length > 0 ? this.state.spVehicleRequestStatus[0]?.vehicleAssignment : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83322Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spVehicleRequestStatus?.length > 0 ? this.state.spVehicleRequestStatus[0]?.vehicleAssignment : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp221232Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spVehicleRequestStatus?.length > 0
            ? this.state.spVehicleRequestStatus[0]?.vehicleApprovement
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422610Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spVehicleRequestStatus?.length > 0
            ? this.state.spVehicleRequestStatus[0]?.vehicleApprovement
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp883895Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spVehicleRequestStatus?.length > 0
            ? this.state.spVehicleRequestStatus[0]?.vehicleApprovement
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587160Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spVehicleRequestStatus?.length > 0 ? this.state.spVehicleRequestStatus[0]?.vehicleLoading : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp707288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spVehicleRequestStatus?.length > 0 ? this.state.spVehicleRequestStatus[0]?.vehicleLoading : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp609812Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spVehicleRequestStatus?.length > 0 ? this.state.spVehicleRequestStatus[0]?.vehicleLoading : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp770317Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spVehicleRequestStatus?.length > 0 ? this.state.spVehicleRequestStatus[0]?.vehicleDelivered : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp377814Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spVehicleRequestStatus?.length > 0 ? this.state.spVehicleRequestStatus[0]?.vehicleDelivered : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp928987Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.spVehicleRequestStatus?.length > 0 ? this.state.spVehicleRequestStatus[0]?.vehicleDelivered : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_239304_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
    );

    formVars.assignedvehicleview_225970_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
    );

    formVars.assignedvehicleview_282384_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardcode : null
    );

    formVars.assignedvehicleview_180459_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    formVars.assignedvehicleview_543657_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.country : null
    );

    formVars.assignedvehicleview_462529_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.shiptoaddress : null
    );

    stateVars.isComp970034Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_948478_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );

    formVars.assignedvehicleview_934636_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.createdDate : null
    );

    formVars.assignedvehicleview_260290_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );

    formVars.assignedvehicleview_200182_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
    );

    formVars.assignedvehicleview_942340_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
    );

    formVars.assignedvehicleview_416530_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
    );

    formVars.assignedvehicleview_286122_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );

    stateVars.isComp13035Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp201Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973624Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_809542_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approverFullName : null
    );

    formVars.assignedvehicleview_806706_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approvedAt : null
    );

    stateVars.isComp349601Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260467Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_722340_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );

    formVars.assignedvehicleview_871054_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );

    stateVars.isComp444544Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_799724_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );

    formVars.assignedvehicleview_53508_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
        : null
    );

    formVars.assignedvehicleview_871532_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverFullName : null
    );

    formVars.assignedvehicleview_409307_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.formattedPhone : null
    );

    stateVars.isComp20508Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_360114_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );

    formVars.assignedvehicleview_339215_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignerFullName : null
    );

    formVars.assignedvehicleview_28160_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignedAt : null
    );

    formVars.assignedvehicleview_12761_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignerEmail : null
    );

    formVars.assignedvehicleview_763411_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.assignerFormattedPhone
        : null
    );

    stateVars.isComp190903Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp31955Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_120265_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.country : null
    );

    stateVars.isComp120265Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_499488_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId : null
    );

    stateVars.isComp174579Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewPageInit8_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId
            : null
        ),
        null
      )
    ) {
      formVars.assignedvehicleview_534792_value = ReactSystemFunctions.toString(
        this,
        this.state.getCustomsAgentById?.length > 0 ? this.state.getCustomsAgentById[0]?.cardName : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId
            : null
        ),
        null
      )
    ) {
      formVars.assignedvehicleview_272389_value = ReactSystemFunctions.toString(
        this,
        this.state.getCustomsAgentById?.length > 0 ? this.state.getCustomsAgentById[0]?.formattedPhone : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp291794Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782931Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_987039_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.transpname : null
    );

    stateVars.isComp987039Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_811923_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.brokerId : null
    );

    stateVars.isComp227437Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewPageInit11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.brokerId : null
        ),
        null
      )
    ) {
      formVars.assignedvehicleview_937800_value = ReactSystemFunctions.toString(
        this,
        this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.fullname : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.brokerId : null
        ),
        null
      )
    ) {
      formVars.assignedvehicleview_620093_value = ReactSystemFunctions.toString(
        this,
        this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.email : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.brokerId : null
        ),
        null
      )
    ) {
      formVars.assignedvehicleview_416930_value = ReactSystemFunctions.toString(
        this,
        this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.fullphone : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewPageInit14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp269555Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.commercialInvoiceCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp646351Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.commercialInvoiceCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp779908Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.commercialInvoiceCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623164Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.commercialInvoiceCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_612905_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );

    stateVars.isComp843310Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.irsaliyeCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671036Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.irsaliyeCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178060Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.irsaliyeCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp826937Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.irsaliyeCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213809Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.efaturaCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271114Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.efaturaCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp278091Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.efaturaCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp898310Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.efaturaCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352849Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.certificateOfAnalysisCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp572531Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.certificateOfAnalysisCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346444Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.certificateOfAnalysisCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966119Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.certificateOfAnalysisCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp668484Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.lotBasedAnalysisCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126965Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.lotBasedAnalysisCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846973Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.lotBasedAnalysisCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715893Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.lotBasedAnalysisCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840014Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.isabroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77376Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.packingListCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830625Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.packingListCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289446Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.packingListCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360611Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.packingListCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp307591Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.truckDriverCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp372986Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.truckDriverCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp245535Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.truckDriverCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp421537Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.truckDriverCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.isabroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp688763Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.customsDeclarationsCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp688538Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.customsDeclarationsCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp606915Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.customsDeclarationsCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp123700Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.customsDeclarationsCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp993112Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.circulationATRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp556191Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.circulationATRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp582561Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.circulationATRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp482066Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.circulationATRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698440Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.circulationEUROCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp176933Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.circulationEUROCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54129Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.circulationEUROCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp366556Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.circulationEUROCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260562Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp200576Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536285Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp371945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp445236Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp411602Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp557549Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp24497Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229597Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848626Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_957942 = this.state.spGetVehicleLocations;
    stateVars.isComp159976Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    formVars.assignedvehicleview_168619_total = ReactSystemFunctions.value(
      this,
      this.state.sqlVehicleLocationCount?.length > 0 ? this.state.sqlVehicleLocationCount[0]?.locationCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_987347_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    await this.AssignedVehicleViewPageInit();

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_155263_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_201_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_201_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_201_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_201_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      approvedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      approvedBy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "Guid"
      )
    };

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "09799d69_a086_501e_4c15_05309ac94a4e_confirmation",
        this.defaultML,
        "Atanan araç bilgilerini onaylamak istiyor musunuz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_201_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.sqlApproveVehicleRequest = result?.data.sqlApproveVehicleRequest;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_201_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_201_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_444544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewInvoice",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewInvoice",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "432780",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_414740_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_414740_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.getCustomsAgents = result?.data.getCustomsAgents;

      formVars.assignedvehicleview_499488_options = stateVars.getCustomsAgents;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_414740_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_414740_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_414740_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp188401Visible");

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_414740_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_414740_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      )
    ) {
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_414740_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_414740_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_414740_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      )
    ) {
      stateVars.dataSource_499488 = this.state.getCustomsAgents;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_499488_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_499488_value", "value", "getCustomsAgents", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "assignedvehicleview_499488_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.isabroad : null
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      customsAgentId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_499488_value", "value", "getCustomsAgents", "id", "id")
        ),
        "Guid"
      ),
      vehicleRequestsId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_499488_value", "value", "getCustomsAgents", "id", "id")
        ),
        "Guid"
      ),
      RoleId_3: ReactSystemFunctions.convertToTypeByName("E6C1FD52-EECE-7BA2-B39E-D53DABF91A54", "Guid"),
      RoleName_3: ReactSystemFunctions.convertToTypeByName("CustomsAgent", "string"),
      templateId_4: ReactSystemFunctions.convertToTypeByName("c5063af2-7fea-4e30-8141-bf65c3230988", "string"),
      subject_4: ReactSystemFunctions.convertToTypeByName("CFN Kimya yeni sipariş", "string"),
      sender_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      cardname_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SqlGetCustomerDetailsByCardNo?.length > 0
            ? this.state.SqlGetCustomerDetailsByCardNo[0]?.cardName
            : null
        ),
        "string"
      ),
      siparisno_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      frontEndUrl_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.getFrontEndUrl),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_499488_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.customsAgentSave = result?.data.customsAgentSave;
    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.assignedvehicleview_948478_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );
    formVars.assignedvehicleview_934636_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.createdDate : null
    );
    formVars.assignedvehicleview_260290_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );
    formVars.assignedvehicleview_200182_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
    );
    formVars.assignedvehicleview_942340_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
    );
    formVars.assignedvehicleview_416530_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
    );
    formVars.assignedvehicleview_286122_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );
    formVars.assignedvehicleview_809542_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approverFullName : null
    );
    formVars.assignedvehicleview_806706_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approvedAt : null
    );
    formVars.assignedvehicleview_722340_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );
    formVars.assignedvehicleview_871054_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );
    formVars.assignedvehicleview_799724_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );
    formVars.assignedvehicleview_53508_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber : null
    );
    formVars.assignedvehicleview_871532_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverFullName : null
    );
    formVars.assignedvehicleview_409307_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.formattedPhone : null
    );
    formVars.assignedvehicleview_360114_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );
    formVars.assignedvehicleview_339215_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignerFullName : null
    );
    formVars.assignedvehicleview_28160_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignedAt : null
    );
    formVars.assignedvehicleview_12761_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignerEmail : null
    );
    formVars.assignedvehicleview_763411_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.assignerFormattedPhone
        : null
    );
    formVars.assignedvehicleview_499488_value =
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null;
    formVars.assignedvehicleview_811923_value =
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.brokerId : null;
    formVars.assignedvehicleview_612905_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );
    stateVars.isComp970034Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13035Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp201Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973624Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349601Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260467Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444544Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp20508Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp190903Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp31955Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp120265Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp174579Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291794Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782931Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp987039Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227437Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260562Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp200576Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536285Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp371945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp445236Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp411602Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159976Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getCustomsAgentById = result?.data.getCustomsAgentById;
    formVars.assignedvehicleview_534792_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCustomsAgentById?.length > 0 ? stateVars.getCustomsAgentById[0]?.cardName : null
    );
    formVars.assignedvehicleview_272389_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCustomsAgentById?.length > 0 ? stateVars.getCustomsAgentById[0]?.formattedPhone : null
    );
    stateVars.getUsersEmailByRoleAndCompanyAGG = result?.data.getUsersEmailByRoleAndCompanyAGG;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_499488_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_499488_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp188401Visible");
    formVars.assignedvehicleview_948478_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );

    formVars.assignedvehicleview_934636_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.createdDate : null
    );

    formVars.assignedvehicleview_260290_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );

    formVars.assignedvehicleview_200182_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
    );

    formVars.assignedvehicleview_942340_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
    );

    formVars.assignedvehicleview_416530_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
    );

    formVars.assignedvehicleview_286122_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );

    formVars.assignedvehicleview_809542_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approverFullName : null
    );

    formVars.assignedvehicleview_806706_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approvedAt : null
    );

    formVars.assignedvehicleview_722340_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );

    formVars.assignedvehicleview_871054_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );

    formVars.assignedvehicleview_799724_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );

    formVars.assignedvehicleview_53508_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
        : null
    );

    formVars.assignedvehicleview_871532_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverFullName : null
    );

    formVars.assignedvehicleview_409307_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.formattedPhone : null
    );

    formVars.assignedvehicleview_360114_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );

    formVars.assignedvehicleview_339215_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignerFullName : null
    );

    formVars.assignedvehicleview_28160_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignedAt : null
    );

    formVars.assignedvehicleview_12761_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignerEmail : null
    );

    formVars.assignedvehicleview_763411_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.assignerFormattedPhone
        : null
    );

    formVars.assignedvehicleview_499488_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId : null
    );

    formVars.assignedvehicleview_534792_value = ReactSystemFunctions.toString(
      this,
      this.state.getCustomsAgentById?.length > 0 ? this.state.getCustomsAgentById[0]?.cardName : null
    );

    formVars.assignedvehicleview_272389_value = ReactSystemFunctions.toString(
      this,
      this.state.getCustomsAgentById?.length > 0 ? this.state.getCustomsAgentById[0]?.formattedPhone : null
    );

    formVars.assignedvehicleview_811923_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.brokerId : null
    );

    formVars.assignedvehicleview_612905_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_476554_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      transportType_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.transpname : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_476554_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.canSelectBroker = result?.data.canSelectBroker;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_476554_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_476554_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "0"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "34d46f1a_e78f_f52a_935c_aac92204dda1_notify",
          this.defaultML,
          "Fatura, Çeki listesi, Analiz Sertifikası ve Sürücü belgelerinin yüklenmiş olması gerekmektedir."
        ),
        "warning",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_476554_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_476554_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_476554_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      search_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      pageSize_0: ReactSystemFunctions.convertToTypeByName(20, "number"),
      current_0: ReactSystemFunctions.convertToTypeByName(1, "number")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_476554_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.getAllBrokersByFilter = result?.data.getAllBrokersByFilter;

      formVars.assignedvehicleview_811923_options = stateVars.getAllBrokersByFilter;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_476554_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_476554_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_476554_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp984754Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_476554_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_476554_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_476554_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_476554_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_476554_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_476554_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      stateVars.dataSource_811923 = this.state.getAllBrokersByFilter;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_811923_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      transportType_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.transpname : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_811923_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.canSelectBroker = result?.data.canSelectBroker;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_811923_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "0"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "087bdec7_00a1_a033_b1e8_ea58643ac47f_notify",
          this.defaultML,
          "Fatura, Çeki listesi, Analiz Sertifikası ve Sürücü belgelerinin yüklenmiş olması gerekmektedir."
        ),
        "warning",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "assignedvehicleview_811923_value",
            "value",
            "getAllBrokersByFilter",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "assignedvehicleview_811923_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      brokerID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "assignedvehicleview_811923_value",
            "value",
            "getAllBrokersByFilter",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      vehicleRequestsId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_811923_onChange2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.updateBrokerIdonVehicleRequest = result?.data.updateBrokerIdonVehicleRequest;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "assignedvehicleview_811923_value",
            "value",
            "getAllBrokersByFilter",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "assignedvehicleview_811923_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      brokerId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "assignedvehicleview_811923_value",
            "value",
            "getAllBrokersByFilter",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_811923_onChange3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.getBrokerById = result?.data.getBrokerById;
      formVars.assignedvehicleview_937800_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.fullname : null
      );
      formVars.assignedvehicleview_620093_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.email : null
      );
      formVars.assignedvehicleview_416930_value = ReactSystemFunctions.toString(
        this,
        stateVars.getBrokerById?.length > 0 ? stateVars.getBrokerById[0]?.fullphone : null
      );
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_811923_onChange4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
      formVars.assignedvehicleview_948478_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
      );
      formVars.assignedvehicleview_934636_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.createdDate : null
      );
      formVars.assignedvehicleview_260290_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.supplierCardName : null
      );
      formVars.assignedvehicleview_200182_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
      );
      formVars.assignedvehicleview_942340_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
      );
      formVars.assignedvehicleview_416530_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
      );
      formVars.assignedvehicleview_286122_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
      );
      formVars.assignedvehicleview_809542_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approverFullName : null
      );
      formVars.assignedvehicleview_806706_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approvedAt : null
      );
      formVars.assignedvehicleview_722340_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0
          ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
          : null
      );
      formVars.assignedvehicleview_871054_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
      );
      formVars.assignedvehicleview_799724_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
      );
      formVars.assignedvehicleview_53508_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0
          ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber
          : null
      );
      formVars.assignedvehicleview_871532_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverFullName : null
      );
      formVars.assignedvehicleview_409307_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.formattedPhone : null
      );
      formVars.assignedvehicleview_360114_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.supplierCardName : null
      );
      formVars.assignedvehicleview_339215_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignerFullName : null
      );
      formVars.assignedvehicleview_28160_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignedAt : null
      );
      formVars.assignedvehicleview_12761_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignerEmail : null
      );
      formVars.assignedvehicleview_763411_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0
          ? stateVars.sqlGetVehicleRequestById[0]?.assignerFormattedPhone
          : null
      );
      formVars.assignedvehicleview_499488_value =
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null;
      formVars.assignedvehicleview_811923_value =
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.brokerId : null;
      formVars.assignedvehicleview_612905_value = ReactSystemFunctions.toString(
        this,
        stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
      );
      stateVars.isComp970034Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.toString(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp13035Visible =
        ReactSystemFunctions.isLessOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "10"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp201Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "20"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp973624Visible =
        ReactSystemFunctions.isGreaterOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "30"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp349601Visible =
        ReactSystemFunctions.isGreaterOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "20"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp260467Visible =
        ReactSystemFunctions.isGreaterOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "40"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp444544Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0
              ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
              : null
          ),
          "1"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp624121Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0
              ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
              : null
          ),
          "0"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp20508Visible =
        ReactSystemFunctions.isGreaterOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "20"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp190903Visible =
        ReactSystemFunctions.isGreaterOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "30"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp970094Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
          ),
          true
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp31955Visible =
        ReactSystemFunctions.isLessOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "10"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp120265Visible =
        ReactSystemFunctions.isLessOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "10"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp174579Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0
              ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId
              : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp291794Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0
              ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId
              : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp782931Visible =
        ReactSystemFunctions.isLessOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "10"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp987039Visible =
        ReactSystemFunctions.isLessOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "10"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp227437Visible =
        ReactSystemFunctions.isNotEmpty(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0
              ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId
              : null
          ),
          null
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp260562Visible =
        ReactSystemFunctions.isGreaterOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "40"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp200576Visible =
        ReactSystemFunctions.isLessThan(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "60"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp536285Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
          ),
          false
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp371945Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
          ),
          true
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp445236Visible =
        ReactSystemFunctions.isGreaterOrEqual(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "40"
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp411602Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
          ),
          true
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp799915Visible =
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
          ),
          false
        ) === true
          ? "visible"
          : "hidden";
      stateVars.isComp159976Visible =
        ReactSystemFunctions.isLessThan(
          ReactSystemFunctions.value(
            this,
            stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
          ),
          "60"
        ) === true
          ? "visible"
          : "hidden";
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp984754Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_948478_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_934636_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.createdDate : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_260290_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.supplierCardName
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_200182_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_942340_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_416530_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_286122_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_809542_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.approverFullName
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_806706_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approvedAt : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_722340_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_871054_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange17_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange17_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_799724_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange18_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange18_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_53508_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange19_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange19_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_871532_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverFullName : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange20_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange20_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_409307_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.formattedPhone : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange21_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange21_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_360114_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.supplierCardName
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange22_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_339215_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.assignerFullName
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange23_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange23_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_28160_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignedAt : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange24_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange24_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_12761_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignerEmail : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange25_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange25_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange25_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_763411_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0
          ? this.state.sqlGetVehicleRequestById[0]?.assignerFormattedPhone
          : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange26_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange26_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange26_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_499488_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange27_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange27_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange27_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_811923_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.brokerId : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange28_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange28_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange28_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_937800_value = ReactSystemFunctions.toString(
        this,
        this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.fullname : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange29_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange29_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange29_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_620093_value = ReactSystemFunctions.toString(
        this,
        this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.email : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange30_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange30_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_416930_value = ReactSystemFunctions.toString(
        this,
        this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.fullphone : null
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange31_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_811923_onChange31_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_811923_onChange31_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.canSelectBroker?.length > 0 ? this.state.canSelectBroker[0]?.canSelectBroker : null
        ),
        "1"
      )
    ) {
      formVars.assignedvehicleview_612905_value = ReactSystemFunctions.toString(
        this,
        this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_240932_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      vehicleRequestId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_240932_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spCheckDocuments = result?.data.spCheckDocuments;
    stateVars.isComp269555Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.commercialInvoiceCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp646351Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.commercialInvoiceCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp779908Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.commercialInvoiceCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623164Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.commercialInvoiceCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843310Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.irsaliyeCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671036Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.irsaliyeCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178060Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.irsaliyeCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp826937Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.irsaliyeCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213809Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.efaturaCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271114Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.efaturaCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp278091Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.efaturaCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp898310Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.efaturaCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352849Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.certificateOfAnalysisCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp572531Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.certificateOfAnalysisCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346444Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.certificateOfAnalysisCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966119Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.certificateOfAnalysisCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp668484Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.lotBasedAnalysisCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126965Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.lotBasedAnalysisCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp846973Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.lotBasedAnalysisCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715893Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.lotBasedAnalysisCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77376Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.packingListCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830625Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.packingListCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289446Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.packingListCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360611Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.packingListCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp307591Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.truckDriverCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp372986Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.truckDriverCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp245535Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.truckDriverCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp421537Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.truckDriverCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp688763Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.customsDeclarationsCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp688538Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.customsDeclarationsCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp606915Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.customsDeclarationsCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp123700Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.customsDeclarationsCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp993112Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.circulationATRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp556191Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.circulationATRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp582561Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.circulationATRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp482066Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.circulationATRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698440Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.circulationEUROCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp176933Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.circulationEUROCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54129Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.circulationEUROCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp366556Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.circulationEUROCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp557549Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp24497Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229597Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848626Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.spCheckDocuments?.length > 0 ? stateVars.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getDocumentsName = result?.data.getDocumentsName;
    formVars.assignedvehicleview_623164_value = ReactSystemFunctions.toString(
      this,
      stateVars.getDocumentsName?.length > 0 ? stateVars.getDocumentsName[0]?.commercialInvoiceName : null
    );
    formVars.assignedvehicleview_826937_value = ReactSystemFunctions.toString(
      this,
      stateVars.getDocumentsName?.length > 0 ? stateVars.getDocumentsName[0]?.irsaliyeName : null
    );
    formVars.assignedvehicleview_898310_value = ReactSystemFunctions.toString(
      this,
      stateVars.getDocumentsName?.length > 0 ? stateVars.getDocumentsName[0]?.efaturaName : null
    );
    formVars.assignedvehicleview_966119_value = ReactSystemFunctions.toString(
      this,
      stateVars.getDocumentsName?.length > 0 ? stateVars.getDocumentsName[0]?.certificateOfAnalysisName : null
    );
    formVars.assignedvehicleview_715893_value = ReactSystemFunctions.toString(
      this,
      stateVars.getDocumentsName?.length > 0 ? stateVars.getDocumentsName[0]?.lotBasedAnalysisName : null
    );
    formVars.assignedvehicleview_360611_value = ReactSystemFunctions.toString(
      this,
      stateVars.getDocumentsName?.length > 0 ? stateVars.getDocumentsName[0]?.packingListName : null
    );
    formVars.assignedvehicleview_421537_value = ReactSystemFunctions.toString(
      this,
      stateVars.getDocumentsName?.length > 0 ? stateVars.getDocumentsName[0]?.truckDriverName : null
    );
    formVars.assignedvehicleview_123700_value = ReactSystemFunctions.toString(
      this,
      stateVars.getDocumentsName?.length > 0 ? stateVars.getDocumentsName[0]?.customsDeclarationsName : null
    );
    formVars.assignedvehicleview_482066_value = ReactSystemFunctions.toString(
      this,
      stateVars.getDocumentsName?.length > 0 ? stateVars.getDocumentsName[0]?.circulationATRName : null
    );
    formVars.assignedvehicleview_366556_value = ReactSystemFunctions.toString(
      this,
      stateVars.getDocumentsName?.length > 0 ? stateVars.getDocumentsName[0]?.circulationEUROName : null
    );
    formVars.assignedvehicleview_848626_value = ReactSystemFunctions.toString(
      this,
      stateVars.getDocumentsName?.length > 0 ? stateVars.getDocumentsName[0]?.cMRName : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_240932_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_240932_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.assignedvehicleview_623164_value = ReactSystemFunctions.toString(
      this,
      this.state.getDocumentsName?.length > 0 ? this.state.getDocumentsName[0]?.commercialInvoiceName : null
    );

    formVars.assignedvehicleview_826937_value = ReactSystemFunctions.toString(
      this,
      this.state.getDocumentsName?.length > 0 ? this.state.getDocumentsName[0]?.irsaliyeName : null
    );

    formVars.assignedvehicleview_898310_value = ReactSystemFunctions.toString(
      this,
      this.state.getDocumentsName?.length > 0 ? this.state.getDocumentsName[0]?.efaturaName : null
    );

    formVars.assignedvehicleview_966119_value = ReactSystemFunctions.toString(
      this,
      this.state.getDocumentsName?.length > 0 ? this.state.getDocumentsName[0]?.certificateOfAnalysisName : null
    );

    formVars.assignedvehicleview_715893_value = ReactSystemFunctions.toString(
      this,
      this.state.getDocumentsName?.length > 0 ? this.state.getDocumentsName[0]?.lotBasedAnalysisName : null
    );

    formVars.assignedvehicleview_360611_value = ReactSystemFunctions.toString(
      this,
      this.state.getDocumentsName?.length > 0 ? this.state.getDocumentsName[0]?.packingListName : null
    );

    formVars.assignedvehicleview_421537_value = ReactSystemFunctions.toString(
      this,
      this.state.getDocumentsName?.length > 0 ? this.state.getDocumentsName[0]?.truckDriverName : null
    );

    formVars.assignedvehicleview_123700_value = ReactSystemFunctions.toString(
      this,
      this.state.getDocumentsName?.length > 0 ? this.state.getDocumentsName[0]?.customsDeclarationsName : null
    );

    formVars.assignedvehicleview_482066_value = ReactSystemFunctions.toString(
      this,
      this.state.getDocumentsName?.length > 0 ? this.state.getDocumentsName[0]?.circulationATRName : null
    );

    formVars.assignedvehicleview_366556_value = ReactSystemFunctions.toString(
      this,
      this.state.getDocumentsName?.length > 0 ? this.state.getDocumentsName[0]?.circulationEUROName : null
    );

    formVars.assignedvehicleview_848626_value = ReactSystemFunctions.toString(
      this,
      this.state.getDocumentsName?.length > 0 ? this.state.getDocumentsName[0]?.cMRName : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_312407_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      templateId_2: ReactSystemFunctions.convertToTypeByName("f3ccfcbf-1fd7-4e1a-9bb6-00f20e81620f", "string"),
      receivers_2: ReactSystemFunctions.convertToTypeByName("gokhan.seyrankaya+testmail@kuika.com", "string"),
      subject_2: ReactSystemFunctions.convertToTypeByName("CFN Kimya Sipariş belgeleri yüklendi.", "string"),
      sender_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      customsAgenFullname_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getCustomsAgentById?.length > 0 ? this.state.getCustomsAgentById[0]?.cardName : null
        ),
        "string"
      ),
      cardname_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.cardname : null
        ),
        "string"
      ),
      siparisno_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.deliveryNumber
            : null
        ),
        "string"
      ),
      vehicleplate_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.vehiclePlate : null
        ),
        "string"
      ),
      loadingdate_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_312407_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getBackEndUrl = result?.data.getBackEndUrl;
    stateVars.getDocumentsLink = result?.data.getDocumentsLink;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_377955_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_623164_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "1590D2F8-52E6-4D81-8FF8-98F77D319204");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "848052",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_739529_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_739529_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_739529_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("1590D2F8-52E6-4D81-8FF8-98F77D319204", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_739529_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_739529_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_739529_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_739529_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_826937_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "b83d48f7-6e82-4e09-a2db-da1158f6e45c");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "279254",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_259412_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_259412_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_259412_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("B83D48F7-6E82-4E09-A2DB-DA1158F6E45C", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_259412_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_259412_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_259412_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_259412_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_898310_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "aed1823f-b624-418a-b64e-906e2ef3b062");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "788135",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_426133_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_426133_onClick_if01_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_426133_onClick_if01_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "52654351_469c_5bb8_35f0_0044e608018c_confirmation",
        this.defaultML,
        "After Uploading E-Invoice, Custom Agents / Broker  will be informed via e-mail. Do you want to continue?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_426133_onClick_if02_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_426133_onClick_if0f2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_426133_onClick_if0f21_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_426133_onClick_if0f21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("aed1823f-b624-418a-b64e-906e2ef3b062", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_426133_onClick_if0f21_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_426133_onClick_if0f22_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_426133_onClick_if0f22_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_426133_onClick_if0f22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_426133_onClick_if0f23_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_426133_onClick_if0f23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      RoleId_0: ReactSystemFunctions.convertToTypeByName("E6C1FD52-EECE-7BA2-B39E-D53DABF91A54", "Guid"),
      companyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.getCustomsAgentById?.length > 0 ? this.state.getCustomsAgentById[0]?.id : null
        ),
        "Guid"
      ),
      RoleName_0: ReactSystemFunctions.convertToTypeByName("CustomsAgent", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_426133_onClick_if0f23_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUsersEmailByRoleAndCompanyAGG = result?.data.getUsersEmailByRoleAndCompanyAGG;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_426133_onClick_if0f24_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_426133_onClick_if0f24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("f3ccfcbf-1fd7-4e1a-9bb6-00f20e81620f", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUsersEmailByRoleAndCompanyAGG?.length > 0
            ? this.state.getUsersEmailByRoleAndCompanyAGG[0]?.userMails
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("Sipariş belgeleri yüklendi", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      customsAgentFullname_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getCustomsAgentById?.length > 0 ? this.state.getCustomsAgentById[0]?.cardName : null
        ),
        "string"
      ),
      cardname_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SqlGetCustomerDetailsByCardNo?.length > 0
            ? this.state.SqlGetCustomerDetailsByCardNo[0]?.cardName
            : null
        ),
        "string"
      ),
      siparisno_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      vehicleplate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.vehiclePlate : null
        ),
        "string"
      ),
      loadingdate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate_f : null
        ),
        "string"
      ),
      frontEndUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.getFrontEndUrl),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_426133_onClick_if0f24_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_426133_onClick_if0f25_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_426133_onClick_if0f25_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_426133_onClick_if0f25_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      backendURL_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.getBackEndUrl),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.email : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_426133_onClick_if0f25_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.getDocumentsLink = result?.data.getDocumentsLink;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_426133_onClick_if0f26_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_426133_onClick_if0f26_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_426133_onClick_if0f26_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("c01339a3-2da6-492e-8baf-5d8880a71849", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.email : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("Sipariş belgeleri yüklendi", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      vehicleplate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.vehiclePlate : null
        ),
        "string"
      ),
      loadingdate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate_f : null
        ),
        "string"
      ),
      brokerFullname_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.fullname : null
        ),
        "string"
      ),
      customercardname_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SqlGetCustomerDetailsByCardNo?.length > 0
            ? this.state.SqlGetCustomerDetailsByCardNo[0]?.cardName
            : null
        ),
        "string"
      ),
      ordernumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      customsAgentCardName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getCustomsAgentById?.length > 0 ? this.state.getCustomsAgentById[0]?.cardName : null
        ),
        "string"
      ),
      customsAgentPhone_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getCustomsAgentById?.length > 0 ? this.state.getCustomsAgentById[0]?.formattedPhone : null
        ),
        "string"
      ),
      customsAgentEmail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getUsersEmailByRoleAndCompanyAGG?.length > 0
            ? this.state.getUsersEmailByRoleAndCompanyAGG[0]?.userMails
            : null
        ),
        "string"
      ),
      invoiceLink_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getDocumentsLink?.length > 0 ? this.state.getDocumentsLink[0]?.commercialInvoiceLink : null
        ),
        "string"
      ),
      packinglistLink_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getDocumentsLink?.length > 0 ? this.state.getDocumentsLink[0]?.packingListLink : null
        ),
        "string"
      ),
      analysiscertificateLink_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getDocumentsLink?.length > 0 ? this.state.getDocumentsLink[0]?.certificateOfAnalysisLink : null
        ),
        "string"
      ),
      truckdriverLink_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getDocumentsLink?.length > 0 ? this.state.getDocumentsLink[0]?.truckDriverLink : null
        ),
        "string"
      ),
      einvoiceLink_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.getDocumentsLink?.length > 0 ? this.state.getDocumentsLink[0]?.efaturaLink : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.getBrokerById?.length > 0 ? this.state.getBrokerById[0]?.email : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_426133_onClick_if0f26_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_426133_onClick_if02_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      isErrorOccurred = await this.AssignedVehicleViewComponent_426133_onClick_if0f2();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_426133_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_426133_onClick_else01_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_426133_onClick_else0f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_426133_onClick_else0f11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_426133_onClick_else0f11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("aed1823f-b624-418a-b64e-906e2ef3b062", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_426133_onClick_else0f11_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_426133_onClick_else0f12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_426133_onClick_else0f12_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_426133_onClick_else0f12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_426133_onClick_else01_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      isErrorOccurred = await this.AssignedVehicleViewComponent_426133_onClick_else0f1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_426133_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      )
    ) {
      isErrorOccurred = await this.AssignedVehicleViewComponent_426133_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.AssignedVehicleViewComponent_426133_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_966119_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "CC8E5CE4-1A5F-426C-A15F-82B1574BC9F2");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "51031",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_171620_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_171620_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_171620_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("CC8E5CE4-1A5F-426C-A15F-82B1574BC9F2", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_171620_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_171620_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_171620_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_171620_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_715893_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "2890DB52-75C9-4907-8B39-B3C52E7751F2");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "198540",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_843160_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_843160_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_843160_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("2890DB52-75C9-4907-8B39-B3C52E7751F2", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_843160_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_843160_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_843160_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_843160_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_360611_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "703FA622-628F-4B4D-927A-AC78E0009FDF");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "267036",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_632668_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_632668_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_632668_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("703FA622-628F-4B4D-927A-AC78E0009FDF", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_632668_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_632668_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_632668_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_632668_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_421537_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "1f3cd4e4-77da-49fe-a894-7fe4bd990a65");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "762261",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_9095_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_9095_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_9095_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("1f3cd4e4-77da-49fe-a894-7fe4bd990a65", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_9095_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_9095_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_9095_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_9095_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_123700_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "79CD54B1-3C5D-4254-B8E8-B550A5E7421D");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486402",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_530826_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_530826_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_530826_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("79CD54B1-3C5D-4254-B8E8-B550A5E7421D", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_530826_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_530826_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_530826_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_530826_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_482066_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "9651DA3E-3331-4747-9E29-FD5A317F4EEA");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "427813",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_4484_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_4484_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_4484_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("9651DA3E-3331-4747-9E29-FD5A317F4EEA", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_4484_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_4484_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_4484_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_4484_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_366556_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "80419DD8-9CA9-4F50-A3E7-F6F7543B620C");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "402880",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_477178_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_477178_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_477178_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("80419DD8-9CA9-4F50-A3E7-F6F7543B620C", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_477178_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_477178_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_477178_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_477178_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_534765_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vehicleId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      createdDate_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      Address_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_296683_value", "Address", "", "", "")
        ),
        "string"
      ),
      latitude_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_296683_value", "Latitude", "", "", "")
        ),
        "number"
      ),
      longitude_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_296683_value", "Longitude", "", "", "")
        ),
        "number"
      ),
      vehicleId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentPage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      vehicleId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentNo_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      notification_5: ReactSystemFunctions.convertToTypeByName(1, "number"),
      cardNo_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_534765_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spAddVehicleLocation = result?.data.spAddVehicleLocation;
    stateVars.spGetVehicleLocations = result?.data.spGetVehicleLocations;

    stateVars.sqlVehicleLocationCount = result?.data.sqlVehicleLocationCount;
    stateVars.spCurrentsAuthorizedUsers = result?.data.spCurrentsAuthorizedUsers;
    stateVars.spNotificationTranslate = result?.data.spNotificationTranslate;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_534765_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_534765_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        "string"
      ),
      message_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.spNotificationTranslate?.length > 0
            ? this.state.spNotificationTranslate[0]?.notificationText
            : null
        ),
        "string"
      ),
      userNamesJSON_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.spCurrentsAuthorizedUsers?.length > 0 ? this.state.spCurrentsAuthorizedUsers[0]?.userJSON : null
        ),
        "string"
      ),
      screenId_0: ReactSystemFunctions.convertToTypeByName("9e77d583-6719-4778-8665-f3005e6e9222", "string"),
      screenInputName_0: ReactSystemFunctions.convertToTypeByName("parameter", "string"),
      screenInputValue_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      )
    };

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "3b898905_37b1_e128_6172_2fcce5ecec2c_confirmation",
        this.defaultML,
        "Kullanıcılara bildirim gönderilecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_534765_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_534765_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_534765_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "assignedvehicleview_296683_value", "", null);

    stateVars.dataSource_957942 = this.state.spGetVehicleLocations;
    formVars.assignedvehicleview_168619_total = ReactSystemFunctions.value(
      this,
      this.state.sqlVehicleLocationCount?.length > 0 ? this.state.sqlVehicleLocationCount[0]?.locationCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_607622_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrderNumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      DeliveryNumber_0: ReactSystemFunctions.convertToTypeByName(null, "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_607622_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetDeliveryList = result?.data.getDeliveryList;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_607622_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_607622_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "assignedvehicleview_296683_value",
      ReactSystemFunctions.toString(
        this,
        this.state.GetDeliveryList?.length > 0 ? this.state.GetDeliveryList[0]?.shiptoaddress : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_607622_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_607622_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      vehicleId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      createdDate_1: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      Address_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_296683_value", "Address", "", "", "")
        ),
        "string"
      ),
      latitude_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_296683_value", "Latitude", "", "", "")
        ),
        "number"
      ),
      longitude_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_296683_value", "Longitude", "", "", "")
        ),
        "number"
      ),
      vehicleId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentPage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      orderNo_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      userId_3: ReactSystemFunctions.convertToTypeByName("00000000-0000-0000-0000-000000000000", "Guid"),
      vehicleId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentNo_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      notification_7: ReactSystemFunctions.convertToTypeByName(2, "number"),
      cardNo_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      screenId_8: ReactSystemFunctions.convertToTypeByName("CF9480F0-77C8-480A-AB5E-F04E07A0873F", "string"),
      screenInputName_8: ReactSystemFunctions.convertToTypeByName("parameter", "string"),
      Id_9: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      RoleId_10: ReactSystemFunctions.convertToTypeByName("67A9EE96-BF0B-29E0-2DFF-A17F004E4660", "Guid"),
      Role2Id_10: ReactSystemFunctions.convertToTypeByName("60BD931A-6D67-3A1D-DF1F-E103A12471BB", "Guid"),
      Role3Id_10: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      Role4Id_10: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      templateId_11: ReactSystemFunctions.convertToTypeByName("f6b8fdeb-0439-4b32-b089-f64885316b5c", "string"),
      subject_11: ReactSystemFunctions.convertToTypeByName("Sipariş Teslim Edildi", "string"),
      sender_11: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_11: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_11: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_11: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_11: ReactSystemFunctions.convertToTypeByName("", "string"),
      orderNo_11: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      mainText_11: ReactSystemFunctions.convertToTypeByName("Numaralı sipariş teslim edildi.", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_607622_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spAddVehicleLocation = result?.data.spAddVehicleLocation;
    stateVars.spGetVehicleLocations = result?.data.spGetVehicleLocations;

    stateVars.spSetOrderDelivered = result?.data.spSetOrderDelivered;
    stateVars.sqlVehicleLocationCount = result?.data.sqlVehicleLocationCount;
    stateVars.sqlGetSurveyId = result?.data.sqlGetSurveyId;
    stateVars.spCurrentsAuthorizedUsers = result?.data.spCurrentsAuthorizedUsers;
    stateVars.spNotificationTranslate = result?.data.spNotificationTranslate;
    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.assignedvehicleview_948478_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );
    formVars.assignedvehicleview_934636_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.createdDate : null
    );
    formVars.assignedvehicleview_260290_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );
    formVars.assignedvehicleview_200182_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
    );
    formVars.assignedvehicleview_942340_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
    );
    formVars.assignedvehicleview_416530_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
    );
    formVars.assignedvehicleview_286122_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );
    formVars.assignedvehicleview_809542_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approverFullName : null
    );
    formVars.assignedvehicleview_806706_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approvedAt : null
    );
    formVars.assignedvehicleview_722340_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );
    formVars.assignedvehicleview_871054_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );
    formVars.assignedvehicleview_799724_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );
    formVars.assignedvehicleview_53508_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber : null
    );
    formVars.assignedvehicleview_871532_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverFullName : null
    );
    formVars.assignedvehicleview_409307_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.formattedPhone : null
    );
    formVars.assignedvehicleview_360114_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );
    formVars.assignedvehicleview_339215_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignerFullName : null
    );
    formVars.assignedvehicleview_28160_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignedAt : null
    );
    formVars.assignedvehicleview_12761_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignerEmail : null
    );
    formVars.assignedvehicleview_763411_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.assignerFormattedPhone
        : null
    );
    formVars.assignedvehicleview_499488_value =
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null;
    formVars.assignedvehicleview_811923_value =
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.brokerId : null;
    formVars.assignedvehicleview_612905_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );
    stateVars.isComp970034Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13035Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp201Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973624Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349601Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260467Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444544Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp20508Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp190903Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp31955Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp120265Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp174579Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291794Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782931Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp987039Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227437Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260562Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp200576Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536285Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp371945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp445236Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp411602Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159976Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.sqlGetUsersByRolesAGG = result?.data.sqlGetUsersByRolesAGG;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_607622_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_607622_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.assignedvehicleview_948478_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );

    formVars.assignedvehicleview_934636_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.createdDate : null
    );

    formVars.assignedvehicleview_260290_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );

    formVars.assignedvehicleview_200182_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
    );

    formVars.assignedvehicleview_942340_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
    );

    formVars.assignedvehicleview_416530_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
    );

    formVars.assignedvehicleview_286122_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );

    formVars.assignedvehicleview_809542_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approverFullName : null
    );

    formVars.assignedvehicleview_806706_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approvedAt : null
    );

    formVars.assignedvehicleview_722340_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );

    formVars.assignedvehicleview_871054_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );

    formVars.assignedvehicleview_799724_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );

    formVars.assignedvehicleview_53508_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
        : null
    );

    formVars.assignedvehicleview_871532_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverFullName : null
    );

    formVars.assignedvehicleview_409307_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.formattedPhone : null
    );

    formVars.assignedvehicleview_360114_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );

    formVars.assignedvehicleview_339215_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignerFullName : null
    );

    formVars.assignedvehicleview_28160_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignedAt : null
    );

    formVars.assignedvehicleview_12761_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignerEmail : null
    );

    formVars.assignedvehicleview_763411_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.assignerFormattedPhone
        : null
    );

    formVars.assignedvehicleview_499488_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId : null
    );

    formVars.assignedvehicleview_811923_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.brokerId : null
    );

    formVars.assignedvehicleview_612905_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );

    stateVars.dataSource_957942 = this.state.spGetVehicleLocations;
    formVars.assignedvehicleview_168619_total = ReactSystemFunctions.value(
      this,
      this.state.sqlVehicleLocationCount?.length > 0 ? this.state.sqlVehicleLocationCount[0]?.locationCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_48226_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      vehicleId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      vehicleId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentPage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_48226_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.assignedvehicleview_948478_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );
    formVars.assignedvehicleview_934636_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.createdDate : null
    );
    formVars.assignedvehicleview_260290_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );
    formVars.assignedvehicleview_200182_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate : null
    );
    formVars.assignedvehicleview_942340_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.navlun : null
    );
    formVars.assignedvehicleview_416530_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.currencyCode : null
    );
    formVars.assignedvehicleview_286122_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );
    formVars.assignedvehicleview_809542_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approverFullName : null
    );
    formVars.assignedvehicleview_806706_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.approvedAt : null
    );
    formVars.assignedvehicleview_722340_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );
    formVars.assignedvehicleview_871054_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );
    formVars.assignedvehicleview_799724_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );
    formVars.assignedvehicleview_53508_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber : null
    );
    formVars.assignedvehicleview_871532_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.driverFullName : null
    );
    formVars.assignedvehicleview_409307_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.formattedPhone : null
    );
    formVars.assignedvehicleview_360114_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );
    formVars.assignedvehicleview_339215_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignerFullName : null
    );
    formVars.assignedvehicleview_28160_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignedAt : null
    );
    formVars.assignedvehicleview_12761_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.assignerEmail : null
    );
    formVars.assignedvehicleview_763411_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.assignerFormattedPhone
        : null
    );
    formVars.assignedvehicleview_499488_value =
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null;
    formVars.assignedvehicleview_811923_value =
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.brokerId : null;
    formVars.assignedvehicleview_612905_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );
    stateVars.isComp970034Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13035Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp201Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973624Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349601Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260467Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444544Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.isInvoiceUploaded
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp20508Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp190903Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "30"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp31955Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp120265Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp174579Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291794Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782931Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp987039Visible =
      ReactSystemFunctions.isLessOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227437Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.customsAgentId : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260562Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp200576Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536285Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp371945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp445236Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "40"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp411602Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799915Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.isAbroad : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159976Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.status : null
        ),
        "60"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spVehicleRequestStatus = result?.data.spVehicleRequestStatus;
    stateVars.isComp332860Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleAssignment : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83322Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleAssignment : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp221232Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleApprovement : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422610Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleApprovement : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp883895Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleApprovement : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587160Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleLoading : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp707288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleLoading : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp609812Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleLoading : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp770317Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleDelivered : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp377814Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleDelivered : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp928987Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.spVehicleRequestStatus?.length > 0 ? stateVars.spVehicleRequestStatus[0]?.vehicleDelivered : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.sqlVehicleLocationCount = result?.data.sqlVehicleLocationCount;
    stateVars.spGetVehicleLocations = result?.data.spGetVehicleLocations;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_48226_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_48226_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.assignedvehicleview_948478_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );

    formVars.assignedvehicleview_934636_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.createdDate : null
    );

    formVars.assignedvehicleview_260290_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );

    formVars.assignedvehicleview_200182_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate : null
    );

    formVars.assignedvehicleview_942340_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.navlun : null
    );

    formVars.assignedvehicleview_416530_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.currencyCode : null
    );

    formVars.assignedvehicleview_286122_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingAddress : null
    );

    formVars.assignedvehicleview_809542_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approverFullName : null
    );

    formVars.assignedvehicleview_806706_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.approvedAt : null
    );

    formVars.assignedvehicleview_722340_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );

    formVars.assignedvehicleview_871054_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.invoiceNumber : null
    );

    formVars.assignedvehicleview_799724_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );

    formVars.assignedvehicleview_53508_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
        : null
    );

    formVars.assignedvehicleview_871532_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.driverFullName : null
    );

    formVars.assignedvehicleview_409307_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.formattedPhone : null
    );

    formVars.assignedvehicleview_360114_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );

    formVars.assignedvehicleview_339215_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignerFullName : null
    );

    formVars.assignedvehicleview_28160_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignedAt : null
    );

    formVars.assignedvehicleview_12761_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.assignerEmail : null
    );

    formVars.assignedvehicleview_763411_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.assignerFormattedPhone
        : null
    );

    formVars.assignedvehicleview_499488_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.customsAgentId : null
    );

    formVars.assignedvehicleview_811923_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.brokerId : null
    );

    formVars.assignedvehicleview_612905_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );

    stateVars.dataSource_957942 = this.state.spGetVehicleLocations;
    formVars.assignedvehicleview_168619_total = ReactSystemFunctions.value(
      this,
      this.state.sqlVehicleLocationCount?.length > 0 ? this.state.sqlVehicleLocationCount[0]?.locationCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_417598_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_296683_value", "Address", "", "", "")
        ),
        null
      )
    ) {
      KuikaAppManager.showSpinner(this);

      if (await this.AssignedVehicleViewComponent_534765_onClick()) return true;

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_417598_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_417598_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_417598_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_296683_value", "Address", "", "", "")
        ),
        null
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "6208eed6_356d_de45_d938_aa7cc8a71f03_notify",
          this.defaultML,
          "Son konumu eklemek için lütfen bir konum adresi giriniz!"
        ),
        "danger",
        "bottom-right",
        5
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_536285_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_536285_onClick_if01_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_536285_onClick_if01_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "97a4b73f_4848_a149_9ad6_ee97cd6146f8_confirmation",
        this.defaultML,
        "Sipariş Teslim Edildi olarak işaretlenecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (await this.AssignedVehicleViewComponent_607622_onClick()) return true;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    }

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_536285_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a659acaa_fb6b_9821_0c4d_f525ef0da476_notify",
        this.defaultML,
        "Teslimat sürecini  sonlandırmadan önce lütfen teslim irsaliyesini yükleyiniz!"
      ),
      "danger",
      "bottom-right",
      5
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_536285_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      )
    ) {
      isErrorOccurred = await this.AssignedVehicleViewComponent_536285_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.AssignedVehicleViewComponent_536285_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_371945_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_371945_onClick_if01_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_371945_onClick_if01_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "97a4b73f_4848_a149_9ad6_ee97cd6146f8_confirmation",
        this.defaultML,
        "Sipariş Teslim Edildi olarak işaretlenecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (await this.AssignedVehicleViewComponent_607622_onClick()) return true;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    }

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_371945_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a659acaa_fb6b_9821_0c4d_f525ef0da476_notify",
        this.defaultML,
        "Teslimat sürecini  sonlandırmadan önce lütfen teslim CMR dokümanını yükleyiniz!"
      ),
      "danger",
      "bottom-right",
      5
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_371945_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          this.state.spCheckDocuments?.length > 0 ? this.state.spCheckDocuments[0]?.cMRCount : null
        ),
        "0"
      )
    ) {
      isErrorOccurred = await this.AssignedVehicleViewComponent_371945_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.AssignedVehicleViewComponent_371945_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_848626_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PreviewDocument",
      "vehicleRequestId",
      this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid
    );
    KuikaAppManager.addToPageInputVariables("PreviewDocument", "docType", "a3976cfe-8ae7-4071-ac8b-24bb72e32685");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AssignedVehicleView",
      "PreviewDocument",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "493725",
      null,
      "right",
      null,
      "60%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_478219_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_478219_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_478219_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleRequestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      docType_0: ReactSystemFunctions.convertToTypeByName("a3976cfe-8ae7-4071-ac8b-24bb72e32685", "Guid"),
      docName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      uploadedAt_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_478219_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spUploadVehicleDocument = result?.data.spUploadVehicleDocument;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_478219_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_478219_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    if (await this.AssignedVehicleViewComponent_240932_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_159976_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "assignedvehicleview_957942_value", "id"),
        "Guid"
      ),
      vehicleId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "37eb25f6_cb16_f12b_d73f_8f1338b9ed7e_confirmation",
        this.defaultML,
        "Konum silinecek. Devam edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "AssignedVehicleView/AssignedVehicleViewComponent_159976_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.sqlDeleteVehicleLocation = result?.data.sqlDeleteVehicleLocation;
      stateVars.spGetVehicleLocations = result?.data.spGetVehicleLocations;

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.AssignedVehicleViewComponent_159976_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_159976_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_957942 = this.state.spGetVehicleLocations;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AssignedVehicleViewComponent_168619_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      vehicleId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.vehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      currentPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "current", "", "", "")
        ),
        "number"
      ),
      pageCount_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "assignedvehicleview_168619_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AssignedVehicleView/AssignedVehicleViewComponent_168619_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetVehicleLocations = result?.data.spGetVehicleLocations;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.AssignedVehicleViewComponent_168619_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AssignedVehicleViewComponent_168619_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_957942 = this.state.spGetVehicleLocations;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [
      432780, 848052, 279254, 788135, 51031, 198540, 267036, 762261, 486402, 427813, 402880, 493725
    ] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.AssignedVehicleViewPageInit();
    }
  }
}
