import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICertificateEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICertificateEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  SelectCertificatesById: any[];
  GetLanguage: string;
  PhotoFromGallery: string;
  SetValueOf: any;
  SaveCertificates: number;
  Notify: boolean;
  isComp898158Visible: "visible" | "hidden";
  isComp457942Visible: "visible" | "hidden";
}

export class CertificateEdit_ScreenBase extends React.PureComponent<ICertificateEdit_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 880079, PropertyName: "value", Value: "Görsel Ekle", TagName: "Label1_value" },
        { Id: 241619, PropertyName: "value", Value: "Sertifika İsmi", TagName: "Label3_value" },
        { Id: 747582, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 898158, PropertyName: "value", Value: "Sertifika Düzenle", TagName: "Label3_0_value" },
        { Id: 457942, PropertyName: "value", Value: "Yeni Sertifika", TagName: "Label2_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "23975bb1-8612-469b-a3b8-bbb32cb14875",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 898158, PropertyName: "value", Value: "Certificate Edit" },
        { Id: 457942, PropertyName: "value", Value: "New Certificate" },
        { Id: 880079, PropertyName: "value", Value: "Add image" },
        { Id: 241619, PropertyName: "value", Value: "Certificate Name" },
        { Id: 598575, PropertyName: "value", Value: "URL" },
        { Id: 747582, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SelectCertificatesById: [],
      GetLanguage: "",
      PhotoFromGallery: "",
      SetValueOf: "",
      SaveCertificates: 0,
      Notify: false,
      isComp898158Visible: "hidden",
      isComp457942Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("certificateedit", "certificateedit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CertificateEditPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("certificateedit", "certificateedit");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("certificateedit", "certificateedit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CertificateEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      certificateedit_332428_value: this.state.SelectCertificatesById?.at?.(0)?.certificateImage ?? undefined,
      certificateedit_814447_value: this.state.SelectCertificatesById?.at?.(0)?.name ?? undefined,
      certificateedit_882967_value: this.state.SelectCertificatesById?.at?.(0)?.url ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  CertificateEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CertificateID ?? this.props.screenInputs.certificateid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CertificateEdit/CertificateEditPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCertificatesById = result?.data.selectCertificatesById;
    formVars.certificateedit_332428_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCertificatesById?.length > 0 ? stateVars.SelectCertificatesById[0]?.certificateImage : null
    );
    formVars.certificateedit_814447_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCertificatesById?.length > 0 ? stateVars.SelectCertificatesById[0]?.name : null
    );
    formVars.certificateedit_882967_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCertificatesById?.length > 0 ? stateVars.SelectCertificatesById[0]?.url : null
    );
    stateVars.isComp898158Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectCertificatesById?.length > 0 ? stateVars.SelectCertificatesById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp457942Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectCertificatesById?.length > 0 ? stateVars.SelectCertificatesById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CertificateEditPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CertificateEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp898158Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SelectCertificatesById?.length > 0 ? this.state.SelectCertificatesById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp457942Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SelectCertificatesById?.length > 0 ? this.state.SelectCertificatesById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.certificateedit_332428_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCertificatesById?.length > 0 ? this.state.SelectCertificatesById[0]?.certificateImage : null
    );

    formVars.certificateedit_814447_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCertificatesById?.length > 0 ? this.state.SelectCertificatesById[0]?.name : null
    );

    formVars.certificateedit_882967_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectCertificatesById?.length > 0 ? this.state.SelectCertificatesById[0]?.url : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CertificateEditComponent_701269_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CertificateEditComponent_880079_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CertificateEditComponent_880079_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CertificateEditComponent_880079_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "certificateedit_332428_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  CertificateEditComponent_747582_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "certificateedit_332428_value", "value", "", "", "")
        ),
        "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
      ),
      message: "This field is required",
      formName: "certificateedit_332428_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "certificateedit_814447_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "certificateedit_814447_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "certificateedit_882967_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "certificateedit_882967_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      certificateImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "certificateedit_332428_value", "value", "", "", "")
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "certificateedit_814447_value", "value", "", "", "")
        ),
        "string"
      ),
      url_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "certificateedit_882967_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CertificateID ?? this.props.screenInputs.certificateid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "CertificateEdit/CertificateEditComponent_747582_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveCertificates = result?.data.saveCertificates;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.CertificateEditComponent_747582_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CertificateEditComponent_747582_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "098ba94f_f5cc_4e53_edf5_a4d8dd63d55a_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
