import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDetailsOfCustomerOrder_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDetailsOfCustomerOrder_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetLanguage: string;
  GetOrderList: any[];
  sqlGetVehicleRequestById: any[];
  getOrderDetailsByOrderNo: any[];
  getLastVehicleLocation: any[];
  GoBack: any;
  isComp765446Visible: "visible" | "hidden";
  isComp364273Visible: "visible" | "hidden";
  isComp666070Visible: "visible" | "hidden";
  isComp698225Visible: "visible" | "hidden";
  isComp310052Visible: "visible" | "hidden";
  isComp66794Visible: "visible" | "hidden";
  isComp733223Visible: "visible" | "hidden";
  isComp147104Visible: "visible" | "hidden";
  isComp926424Visible: "visible" | "hidden";
  isComp870510Visible: "visible" | "hidden";
  isComp300492Visible: "visible" | "hidden";
  isComp778100Visible: "visible" | "hidden";
  isComp360829Visible: "visible" | "hidden";
  isComp644135Visible: "visible" | "hidden";
  isComp172337Visible: "visible" | "hidden";
  isComp198157Visible: "visible" | "hidden";
  isComp354149Visible: "visible" | "hidden";
  isCompdetailsofcustomerorder_496565AuthorizationVisible: "visible" | "hidden";
}

export class DetailsOfCustomerOrder_ScreenBase extends React.PureComponent<IDetailsOfCustomerOrder_ScreenProps, any> {
  detailsofcustomerorder_367095_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 733223, PropertyName: "value", Value: "Hazırlanmakta", TagName: "Label0_0_value" },
        { Id: 147104, PropertyName: "value", Value: "Hazırlanmakta", TagName: "Label0_1_value" },
        { Id: 870510, PropertyName: "value", Value: "Yolda", TagName: "Label0_0_value" },
        { Id: 300492, PropertyName: "value", Value: "Yolda", TagName: "Label0_1_value" },
        { Id: 360829, PropertyName: "value", Value: "Teslim Edilen", TagName: "Label0_0_value" },
        { Id: 644135, PropertyName: "value", Value: "Teslim Edilen", TagName: "Label0_1_value" },
        { Id: 110985, PropertyName: "value", Value: "Siapriş Teslimat Bilgileri", TagName: "Label0_0_value" },
        { Id: 76622, PropertyName: "value", Value: "Sipariş No", TagName: "Label0_0_value" },
        { Id: 54800, PropertyName: "value", Value: "Sipariş Tarihi", TagName: "Label0_0_value" },
        { Id: 727539, PropertyName: "value", Value: "Referans No", TagName: "Label0_0_value" },
        { Id: 583216, PropertyName: "value", Value: "Yükleme Tarihi", TagName: "Label0_0_value" },
        { Id: 121694, PropertyName: "value", Value: "Tahmini Teslimat Tarihi", TagName: "Label0_0_value" },
        { Id: 214637, PropertyName: "value", Value: "İrsaliye No", TagName: "Label0_0_value" },
        { Id: 154299, PropertyName: "value", Value: "Teslimat Adresi", TagName: "Label0_0_value" },
        { Id: 623896, PropertyName: "value", Value: "Araç Bilgileri", TagName: "Label0_0_value" },
        { Id: 405309, PropertyName: "value", Value: "Lojistik Tedarikçi Firma", TagName: "Label0_0_value" },
        { Id: 333554, PropertyName: "value", Value: "Araç Plakası", TagName: "Label0_0_value" },
        { Id: 785897, PropertyName: "value", Value: "Çekici Plakası / Konteyner No", TagName: "Label0_0_value" },
        { Id: 210745, PropertyName: "value", Value: "Sipariş Kalemleri", TagName: "Label0_0_value" },
        {
          Id: 367095,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı...",
          TagName: "Table0_0_nodatafoundmessage"
        },
        { Id: 264420, PropertyName: "title", Value: "Açıklama", TagName: "TableColumn0_0_title" },
        { Id: 256416, PropertyName: "title", Value: "Miktar", TagName: "TableColumn0_1_title" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "ef405fcb-7203-488c-ae51-3d009e8cca35",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 733223, PropertyName: "value", Value: "In Preperation" },
        { Id: 147104, PropertyName: "value", Value: "In Preperation" },
        { Id: 870510, PropertyName: "value", Value: "Out of Delivery" },
        { Id: 300492, PropertyName: "value", Value: "Out of Delivery" },
        { Id: 360829, PropertyName: "value", Value: "Delivered" },
        { Id: 644135, PropertyName: "value", Value: "Delivered" },
        { Id: 110985, PropertyName: "value", Value: "Order Delivery Information" },
        { Id: 76622, PropertyName: "value", Value: "Order Number" },
        { Id: 54800, PropertyName: "value", Value: "Order Date" },
        { Id: 727539, PropertyName: "value", Value: "Reference No" },
        { Id: 583216, PropertyName: "value", Value: "Loading Date" },
        { Id: 121694, PropertyName: "value", Value: "Estimated Delivery Date (ETA)" },
        { Id: 214637, PropertyName: "value", Value: "Waybill Number" },
        { Id: 154299, PropertyName: "value", Value: "Delivery Address" },
        { Id: 623896, PropertyName: "value", Value: "Vehicle Information" },
        { Id: 405309, PropertyName: "value", Value: "Logistics Supplier Company" },
        { Id: 333554, PropertyName: "value", Value: "Vehicle Plate" },
        { Id: 785897, PropertyName: "value", Value: "Trailer Plate / Container No" },
        { Id: 210745, PropertyName: "value", Value: "Order Details" },
        { Id: 367095, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 264420, PropertyName: "title", Value: "Description" },
        { Id: 56757, PropertyName: "value", Value: "[datafield:dscription]" },
        { Id: 256416, PropertyName: "title", Value: "Amount" },
        { Id: 654839, PropertyName: "value", Value: "[datafield:quantitywithuom]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.detailsofcustomerorder_367095_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetLanguage: "",
      GetOrderList: [],
      sqlGetVehicleRequestById: [],
      getOrderDetailsByOrderNo: [],
      getLastVehicleLocation: [],
      GoBack: "",
      isComp765446Visible: "hidden",
      isComp364273Visible: "hidden",
      isComp666070Visible: "hidden",
      isComp698225Visible: "hidden",
      isComp310052Visible: "hidden",
      isComp66794Visible: "hidden",
      isComp733223Visible: "hidden",
      isComp147104Visible: "hidden",
      isComp926424Visible: "hidden",
      isComp870510Visible: "hidden",
      isComp300492Visible: "hidden",
      isComp778100Visible: "hidden",
      isComp360829Visible: "hidden",
      isComp644135Visible: "hidden",
      isComp172337Visible: "hidden",
      isComp198157Visible: "hidden",
      isComp354149Visible: "hidden",
      isCompdetailsofcustomerorder_496565AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("detailsofcustomerorder", "detailsofcustomerorder");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DetailsOfCustomerOrderPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompdetailsofcustomerorder_496565AuthorizationVisible", [
      "e015a9fb-bd45-093e-49f5-64ceb1fd9b3a",
      "Customer"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("detailsofcustomerorder", "detailsofcustomerorder");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("detailsofcustomerorder", "detailsofcustomerorder");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DetailsOfCustomerOrderPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DetailsOfCustomerOrderPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DetailsOfCustomerOrderPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DetailsOfCustomerOrderPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      OrderNumber_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      CustomerCardCode_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.cardNo ?? this.props.screenInputs.cardno,
        "string"
      ),
      CustomerCardName_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      ),
      orderNo_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno,
        "string"
      ),
      vehicleId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VehicleId ?? this.props.screenInputs.vehicleid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DetailsOfCustomerOrder/DetailsOfCustomerOrderPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetOrderList = result?.data.getOrderList;

    formVars.detailsofcustomerorder_298200_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.docdate : null
    );
    formVars.detailsofcustomerorder_187642_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.custrefno : null
    );
    formVars.detailsofcustomerorder_292879_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetOrderList?.length > 0 ? stateVars.GetOrderList[0]?.shiptoaddress : null
    );
    stateVars.sqlGetVehicleRequestById = result?.data.sqlGetVehicleRequestById;
    formVars.detailsofcustomerorder_926424_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.createdDate : null
    );
    formVars.detailsofcustomerorder_778100_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate_f : null
    );
    formVars.detailsofcustomerorder_112955_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.loadingDate_f : null
    );
    formVars.detailsofcustomerorder_306255_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0
        ? stateVars.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );
    formVars.detailsofcustomerorder_942256_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );
    formVars.detailsofcustomerorder_342812_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );
    formVars.detailsofcustomerorder_921878_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );
    formVars.detailsofcustomerorder_40896_value = ReactSystemFunctions.toString(
      this,
      stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.trailerPlateNumber : null
    );
    stateVars.isComp198157Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0 ? stateVars.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354149Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.sqlGetVehicleRequestById?.length > 0
            ? stateVars.sqlGetVehicleRequestById[0]?.truckPlateNumber
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getOrderDetailsByOrderNo = result?.data.getOrderDetailsByOrderNo;
    formVars.detailsofcustomerorder_873943_value = ReactSystemFunctions.toString(
      this,
      stateVars.getOrderDetailsByOrderNo?.length > 0 ? stateVars.getOrderDetailsByOrderNo[0]?.customerTitle : null
    );
    stateVars.isComp765446Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0
            ? stateVars.getOrderDetailsByOrderNo[0]?.hazirlaniyorVisibility
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364273Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0
            ? stateVars.getOrderDetailsByOrderNo[0]?.hazirlaniyorVisibility
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666070Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0 ? stateVars.getOrderDetailsByOrderNo[0]?.yoldaVisibility : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0 ? stateVars.getOrderDetailsByOrderNo[0]?.yoldaVisibility : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp310052Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0
            ? stateVars.getOrderDetailsByOrderNo[0]?.teslimedildiVisibility
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66794Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0
            ? stateVars.getOrderDetailsByOrderNo[0]?.teslimedildiVisibility
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp733223Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0 ? stateVars.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp147104Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0 ? stateVars.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp926424Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0
            ? stateVars.getOrderDetailsByOrderNo[0]?.hazirlaniyorVisibility
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp870510Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0 ? stateVars.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp300492Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0 ? stateVars.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778100Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0 ? stateVars.getOrderDetailsByOrderNo[0]?.yoldaVisibility : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360829Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0 ? stateVars.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp644135Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0 ? stateVars.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getOrderDetailsByOrderNo?.length > 0
            ? stateVars.getOrderDetailsByOrderNo[0]?.teslimedildiVisibility
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getLastVehicleLocation = result?.data.getLastVehicleLocation;
    formVars.detailsofcustomerorder_172337_value = ReactSystemFunctions.toString(
      this,
      stateVars.getLastVehicleLocation?.length > 0 ? stateVars.getLastVehicleLocation[0]?.createdDate : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DetailsOfCustomerOrderPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DetailsOfCustomerOrderPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp765446Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0
            ? this.state.getOrderDetailsByOrderNo[0]?.hazirlaniyorVisibility
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364273Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0
            ? this.state.getOrderDetailsByOrderNo[0]?.hazirlaniyorVisibility
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666070Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0
            ? this.state.getOrderDetailsByOrderNo[0]?.yoldaVisibility
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0
            ? this.state.getOrderDetailsByOrderNo[0]?.yoldaVisibility
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp310052Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0
            ? this.state.getOrderDetailsByOrderNo[0]?.teslimedildiVisibility
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp66794Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0
            ? this.state.getOrderDetailsByOrderNo[0]?.teslimedildiVisibility
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp733223Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0 ? this.state.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp147104Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0 ? this.state.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.detailsofcustomerorder_926424_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.createdDate : null
    );

    stateVars.isComp926424Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0
            ? this.state.getOrderDetailsByOrderNo[0]?.hazirlaniyorVisibility
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp870510Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0 ? this.state.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp300492Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0 ? this.state.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.detailsofcustomerorder_778100_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate_f : null
    );

    stateVars.isComp778100Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0
            ? this.state.getOrderDetailsByOrderNo[0]?.yoldaVisibility
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360829Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0 ? this.state.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp644135Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0 ? this.state.getOrderDetailsByOrderNo[0]?.orderStatus : null
        ),
        "20"
      ) === true
        ? "visible"
        : "hidden";
    formVars.detailsofcustomerorder_172337_value = ReactSystemFunctions.toString(
      this,
      this.state.getLastVehicleLocation?.length > 0 ? this.state.getLastVehicleLocation[0]?.createdDate : null
    );

    stateVars.isComp172337Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.getOrderDetailsByOrderNo?.length > 0
            ? this.state.getOrderDetailsByOrderNo[0]?.teslimedildiVisibility
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.detailsofcustomerorder_298200_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.docdate : null
    );

    formVars.detailsofcustomerorder_187642_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.custrefno : null
    );

    stateVars.isComp198157Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.detailsofcustomerorder_112955_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.loadingDate_f : null
    );

    formVars.detailsofcustomerorder_306255_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.estimatedDeliveryDate
        : null
    );

    formVars.detailsofcustomerorder_942256_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.edeliveryNo : null
    );

    formVars.detailsofcustomerorder_873943_value = ReactSystemFunctions.toString(
      this,
      this.state.getOrderDetailsByOrderNo?.length > 0 ? this.state.getOrderDetailsByOrderNo[0]?.customerTitle : null
    );

    formVars.detailsofcustomerorder_292879_value = ReactSystemFunctions.toString(
      this,
      this.state.GetOrderList?.length > 0 ? this.state.GetOrderList[0]?.shiptoaddress : null
    );

    stateVars.isComp354149Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.sqlGetVehicleRequestById?.length > 0
            ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.detailsofcustomerorder_342812_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.supplierCardName : null
    );

    formVars.detailsofcustomerorder_921878_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0 ? this.state.sqlGetVehicleRequestById[0]?.truckPlateNumber : null
    );

    formVars.detailsofcustomerorder_40896_value = ReactSystemFunctions.toString(
      this,
      this.state.sqlGetVehicleRequestById?.length > 0
        ? this.state.sqlGetVehicleRequestById[0]?.trailerPlateNumber
        : null
    );

    stateVars.dataSource_367095 = this.state.GetOrderList;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DetailsOfCustomerOrderComponent_671628_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
