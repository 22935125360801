import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Icon,
  Image,
  KCol,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  VerticalStack
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { DetailsOfCustomerOrder_ScreenBase } from "./detailsofcustomerorder-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class DetailsOfCustomerOrder_Screen extends DetailsOfCustomerOrder_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{ detailsofcustomerorder_778062_value: "d904a563-d1a7-4126-38a8-08dac24f7806" }}
          >
            <div
              id="detailsofcustomerorder_body"
              style={
                {
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  borderBottomLeftRadius: 20,
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  display: "block"
                } as any
              }
            >
              <KRow
                id="573965"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    alignItems: "center",
                    textAlign: "-webkit-left",
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)",
                    lineHeight: "22px"
                  } as any
                }
              >
                <KCol
                  id="267895"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <Form.Item className="kFormItem" name="detailsofcustomerorder_778062_value">
                    <Image
                      id="778062"
                      placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                      zoomOnClick={false}
                      imageFit="fit"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          width: "180px",
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Image>
                  </Form.Item>
                </KCol>

                <KCol
                  id="121565"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-right",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <Icon
                    id="671628"
                    onClick={(e?: any) => {
                      if (e && e.stopPropagation) e.stopPropagation();
                      this.DetailsOfCustomerOrderComponent_671628_onClick();
                    }}
                    showCursorPointer
                    iconName="close"
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "24px",
                        color: "rgba(245, 34, 45, 1)",
                        letterSpacing: "1px"
                      } as any
                    }
                  ></Icon>
                </KCol>
              </KRow>

              <KRow
                id="496565"
                visibility={this.state.isCompdetailsofcustomerorder_496565AuthorizationVisible}
                visibilityByAuthorization={this.state.isCompdetailsofcustomerorder_496565AuthorizationVisible}
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 12,
                    paddingRight: 24,
                    paddingBottom: 24,
                    paddingLeft: 24,
                    alignItems: "center",
                    textAlign: "-webkit-left",
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)",
                    lineHeight: "22px"
                  } as any
                }
              >
                <KCol
                  id="254843"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderTopLeftRadius: 12,
                      borderTopRightRadius: 12,
                      borderBottomRightRadius: 12,
                      borderBottomLeftRadius: 12,
                      borderTopWidth: 1,
                      borderRightWidth: 1,
                      borderBottomWidth: 1,
                      borderLeftWidth: 1,
                      borderColor: "rgba(227, 241, 250, 1)",
                      borderStyle: "solid",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KRow
                    id="664622"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KCol
                      id="766743"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KRow
                        id="616429"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KCol
                          id="715733"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderTopLeftRadius: 12,
                              borderTopRightRadius: 12,
                              borderBottomRightRadius: 12,
                              borderBottomLeftRadius: 12,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(227, 241, 250, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KRow
                            id="135675"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KCol
                              id="645978"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KRow
                                id="671755"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 8,
                                    paddingBottom: 0,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="260213"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="990398"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="460351"
                                      xs={7}
                                      sm={7}
                                      md={7}
                                      lg={7}
                                      xl={7}
                                      xxl={7}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="765446"
                                        visibility={this.state.isComp765446Visible}
                                        iconName="radio_button_unchecked"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 0)",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "32px",
                                            color: "rgba(22, 193, 243, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Icon
                                        id="364273"
                                        visibility={this.state.isComp364273Visible}
                                        iconName="check_circle"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 0)",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "32px",
                                            color: "rgba(22, 193, 243, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>
                                    </KCol>

                                    <KCol
                                      id="550929"
                                      xs={1}
                                      sm={1}
                                      md={1}
                                      lg={1}
                                      xl={1}
                                      xxl={1}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="194436"
                                        iconName="more_horiz"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 0)",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "32px",
                                            color: "rgba(22, 193, 243, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>
                                    </KCol>

                                    <KCol
                                      id="390298"
                                      xs={7}
                                      sm={7}
                                      md={7}
                                      lg={7}
                                      xl={7}
                                      xxl={7}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          textAlign: "-webkit-center",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="666070"
                                        visibility={this.state.isComp666070Visible}
                                        iconName="radio_button_unchecked"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 0)",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "32px",
                                            color: "rgba(22, 193, 243, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Icon
                                        id="698225"
                                        visibility={this.state.isComp698225Visible}
                                        iconName="check_circle"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 0)",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "32px",
                                            color: "rgba(22, 193, 243, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>
                                    </KCol>

                                    <KCol
                                      id="610634"
                                      xs={1}
                                      sm={1}
                                      md={1}
                                      lg={1}
                                      xl={1}
                                      xxl={1}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="941582"
                                        iconName="more_horiz"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 0)",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "32px",
                                            color: "rgba(22, 193, 243, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>
                                    </KCol>

                                    <KCol
                                      id="272055"
                                      xs={7}
                                      sm={7}
                                      md={7}
                                      lg={7}
                                      xl={7}
                                      xxl={7}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          textAlign: "-webkit-center",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="310052"
                                        visibility={this.state.isComp310052Visible}
                                        iconName="radio_button_unchecked"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 0)",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "32px",
                                            color: "rgba(22, 193, 243, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Icon
                                        id="66794"
                                        visibility={this.state.isComp66794Visible}
                                        iconName="check_circle"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 0)",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "32px",
                                            color: "rgba(22, 193, 243, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>
                                    </KCol>

                                    <KCol
                                      id="78975"
                                      xs={1}
                                      sm={1}
                                      md={1}
                                      lg={1}
                                      xl={1}
                                      xxl={1}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></KCol>
                                  </KRow>
                                </KCol>
                              </KRow>

                              <KRow
                                id="115540"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 8,
                                    paddingBottom: 0,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="134309"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="211315"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="722748"
                                      xs={7}
                                      sm={7}
                                      md={7}
                                      lg={7}
                                      xl={7}
                                      xxl={7}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="733223"
                                        visibility={this.state.isComp733223Visible}
                                        value={ReactSystemFunctions.translate(this.ml, 733223, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingRight: null,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "32px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="147104"
                                        visibility={this.state.isComp147104Visible}
                                        value={ReactSystemFunctions.translate(this.ml, 147104, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingRight: null,
                                            paddingLeft: 10,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 193, 243, 1)",
                                            lineHeight: "32px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="926424"
                                        visibility={this.state.isComp926424Visible}
                                        value={this.state.sqlGetVehicleRequestById?.at?.(0)?.createdDate ?? undefined}
                                        formatter="date"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingLeft: 10,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "32px"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="138237"
                                      xs={1}
                                      sm={1}
                                      md={1}
                                      lg={1}
                                      xl={1}
                                      xxl={1}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></KCol>

                                    <KCol
                                      id="78141"
                                      xs={7}
                                      sm={7}
                                      md={7}
                                      lg={7}
                                      xl={7}
                                      xxl={7}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          textAlign: "-webkit-center",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="870510"
                                        visibility={this.state.isComp870510Visible}
                                        value={ReactSystemFunctions.translate(this.ml, 870510, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingRight: null,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "32px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="300492"
                                        visibility={this.state.isComp300492Visible}
                                        value={ReactSystemFunctions.translate(this.ml, 300492, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingRight: null,
                                            paddingLeft: 10,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 193, 243, 1)",
                                            lineHeight: "32px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="778100"
                                        visibility={this.state.isComp778100Visible}
                                        value={this.state.sqlGetVehicleRequestById?.at?.(0)?.loadingDate_f ?? undefined}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingLeft: 10,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "32px"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="49526"
                                      xs={1}
                                      sm={1}
                                      md={1}
                                      lg={1}
                                      xl={1}
                                      xxl={1}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></KCol>

                                    <KCol
                                      id="301386"
                                      xs={7}
                                      sm={7}
                                      md={7}
                                      lg={7}
                                      xl={7}
                                      xxl={7}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          textAlign: "-webkit-center",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="360829"
                                        visibility={this.state.isComp360829Visible}
                                        value={ReactSystemFunctions.translate(this.ml, 360829, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingRight: null,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "32px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="644135"
                                        visibility={this.state.isComp644135Visible}
                                        value={ReactSystemFunctions.translate(this.ml, 644135, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingRight: null,
                                            paddingLeft: 10,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 193, 243, 1)",
                                            lineHeight: "32px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="172337"
                                        visibility={this.state.isComp172337Visible}
                                        value={this.state.getLastVehicleLocation?.at?.(0)?.createdDate ?? undefined}
                                        formatter="date"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingLeft: 10,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "32px"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="635666"
                                      xs={1}
                                      sm={1}
                                      md={1}
                                      lg={1}
                                      xl={1}
                                      xxl={1}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></KCol>
                                  </KRow>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="432764"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KCol
                          id="603175"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KRow
                            id="389877"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KCol
                              id="130796"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KRow
                                id="275048"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="703463"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="110985"
                                    value={ReactSystemFunctions.translate(this.ml, 110985, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                        fontSize: "18px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>
                              </KRow>

                              <KRow
                                id="565386"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 2,
                                    paddingRight: 8,
                                    paddingBottom: 2,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="757218"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <VerticalStack
                                    id="464947"
                                    direction="vertical"
                                    size={6}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="76622"
                                      value={ReactSystemFunctions.translate(this.ml, 76622, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          color: "rgba(155, 155, 155, 1)",
                                          lineHeight: "20px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="350230"
                                      value={this.props.screenInputs.orderNo ?? this.props.screenInputs.orderno}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </VerticalStack>
                                </KCol>

                                <KCol
                                  id="826480"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <VerticalStack
                                    id="261645"
                                    direction="vertical"
                                    size={6}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="54800"
                                      value={ReactSystemFunctions.translate(this.ml, 54800, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          color: "rgba(155, 155, 155, 1)",
                                          lineHeight: "20px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="298200"
                                      value={this.state.GetOrderList?.at?.(0)?.docdate ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </VerticalStack>
                                </KCol>

                                <KCol
                                  id="729096"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <VerticalStack
                                    id="833278"
                                    direction="vertical"
                                    size={6}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="727539"
                                      value={ReactSystemFunctions.translate(this.ml, 727539, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          color: "rgba(155, 155, 155, 1)",
                                          lineHeight: "20px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="187642"
                                      value={this.state.GetOrderList?.at?.(0)?.custrefno ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </VerticalStack>
                                </KCol>
                              </KRow>

                              <KRow
                                id="198157"
                                visibility={this.state.isComp198157Visible}
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 2,
                                    paddingRight: 8,
                                    paddingBottom: 2,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="115848"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <VerticalStack
                                    id="224015"
                                    direction="vertical"
                                    size={6}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="583216"
                                      value={ReactSystemFunctions.translate(this.ml, 583216, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          color: "rgba(155, 155, 155, 1)",
                                          lineHeight: "20px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="112955"
                                      value={this.state.sqlGetVehicleRequestById?.at?.(0)?.loadingDate_f ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </VerticalStack>
                                </KCol>

                                <KCol
                                  id="827376"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <VerticalStack
                                    id="223825"
                                    direction="vertical"
                                    size={6}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="121694"
                                      value={ReactSystemFunctions.translate(this.ml, 121694, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          color: "rgba(155, 155, 155, 1)",
                                          lineHeight: "20px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="306255"
                                      value={
                                        this.state.sqlGetVehicleRequestById?.at?.(0)?.estimatedDeliveryDate ?? undefined
                                      }
                                      formatter="date"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </VerticalStack>
                                </KCol>

                                <KCol
                                  id="786254"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <VerticalStack
                                    id="912313"
                                    direction="vertical"
                                    size={6}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="214637"
                                      value={ReactSystemFunctions.translate(this.ml, 214637, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          color: "rgba(155, 155, 155, 1)",
                                          lineHeight: "20px"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="942256"
                                      value={this.state.sqlGetVehicleRequestById?.at?.(0)?.edeliveryNo ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </VerticalStack>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="412800"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KCol
                          id="1616"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              alignItems: "flex-start",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KRow
                            id="787824"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KCol
                              id="715167"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KRow
                                id="49581"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="328639"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="154299"
                                    value={ReactSystemFunctions.translate(this.ml, 154299, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                        fontSize: "18px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>
                              </KRow>

                              <KRow
                                id="895855"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="789207"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <VerticalStack
                                    id="355132"
                                    direction="vertical"
                                    size={6}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="873943"
                                      value={this.state.getOrderDetailsByOrderNo?.at?.(0)?.customerTitle ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 2,
                                          paddingBottom: 2,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 400,
                                          fontSize: "16px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "16px"
                                        } as any
                                      }
                                    ></Label>
                                  </VerticalStack>
                                </KCol>
                              </KRow>

                              <KRow
                                id="34079"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="425142"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <VerticalStack
                                    id="674009"
                                    direction="vertical"
                                    size={6}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="292879"
                                      value={this.state.GetOrderList?.at?.(0)?.shiptoaddress ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 2,
                                          paddingBottom: 2,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Label>
                                  </VerticalStack>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="354149"
                        visibility={this.state.isComp354149Visible}
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KCol
                          id="103621"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KRow
                            id="736918"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KCol
                              id="929635"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KRow
                                id="488466"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="851441"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="623896"
                                    value={ReactSystemFunctions.translate(this.ml, 623896, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                        fontSize: "18px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>
                              </KRow>

                              <KRow
                                id="761502"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="864432"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="405309"
                                    value={ReactSystemFunctions.translate(this.ml, 405309, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(155, 155, 155, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="417911"
                                  xs={16}
                                  sm={16}
                                  md={16}
                                  lg={16}
                                  xl={16}
                                  xxl={16}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="342812"
                                    value={this.state.sqlGetVehicleRequestById?.at?.(0)?.supplierCardName ?? undefined}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>
                              </KRow>

                              <KRow
                                id="379071"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="607416"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="333554"
                                    value={ReactSystemFunctions.translate(this.ml, 333554, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(155, 155, 155, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="242563"
                                  xs={16}
                                  sm={16}
                                  md={16}
                                  lg={16}
                                  xl={16}
                                  xxl={16}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="921878"
                                    value={this.state.sqlGetVehicleRequestById?.at?.(0)?.truckPlateNumber ?? undefined}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>
                              </KRow>

                              <KRow
                                id="56767"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="570847"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      paddingTop: null,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="785897"
                                    value={ReactSystemFunctions.translate(this.ml, 785897, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(155, 155, 155, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="559003"
                                  xs={16}
                                  sm={16}
                                  md={16}
                                  lg={16}
                                  xl={16}
                                  xxl={16}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="40896"
                                    value={
                                      this.state.sqlGetVehicleRequestById?.at?.(0)?.trailerPlateNumber ?? undefined
                                    }
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>

                      <KRow
                        id="259322"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 0,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KCol
                          id="126767"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KRow
                            id="955243"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KCol
                              id="223415"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KRow
                                id="452401"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 8,
                                    paddingBottom: 0,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="683597"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingBottom: null,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="210745"
                                    value={ReactSystemFunctions.translate(this.ml, 210745, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                        fontSize: "18px",
                                        color: "rgba(22, 193, 243, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>
                              </KRow>

                              <KRow
                                id="63212"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="53814"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                >
                                  <KTable
                                    id="367095"
                                    kuikaRef={this.detailsofcustomerorder_367095_value_kuikaTableRef}
                                    form={this.props.form}
                                    dataSource={this.state.GetOrderList}
                                    size="middle"
                                    bordered={true}
                                    showHeader={true}
                                    loading={false}
                                    nodatafoundmessage={ReactSystemFunctions.translate(
                                      this.ml,
                                      367095,
                                      "nodatafoundmessage",
                                      this.defaultML
                                    )}
                                    sorter={false}
                                    pagination={false}
                                    striped={false}
                                    stripedColor="#F5F7FA"
                                    insertRowActive={false}
                                    transformedOnMobileResolution={false}
                                    tableWidthMode="fit"
                                    searchable={false}
                                    filtering={false}
                                    columnChooser={false}
                                    insertRowPosition="top"
                                    multiSelect={false}
                                    pageSize={10}
                                    showSizeChanger={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  >
                                    <KTableHeader
                                      id="411855"
                                      hideOnMobileResolution={false}
                                      textDirection="Default"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 2,
                                          paddingRight: 8,
                                          paddingBottom: 2,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          color: "rgba(155, 155, 155, 1)",
                                          lineHeight: "20px"
                                        } as any
                                      }
                                    ></KTableHeader>

                                    <KTableRow
                                      id="872757"
                                      hoverFontColor="red"
                                      hoverBgColor="#F5F5F5"
                                      style={
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: "rgba(244, 244, 244, 1)",
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Roboto",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    >
                                      <KTableColumn
                                        id="264420"
                                        title={ReactSystemFunctions.translate(this.ml, 264420, "title", this.defaultML)}
                                        alwaysVisibleOnMobileResolution={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="56757"
                                          value="[datafield:dscription]"
                                          formatter=""
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KTableColumn>

                                      <KTableColumn
                                        id="256416"
                                        title={ReactSystemFunctions.translate(this.ml, 256416, "title", this.defaultML)}
                                        alwaysVisibleOnMobileResolution={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Roboto",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            lineHeight: "22px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="654839"
                                          value="[datafield:quantitywithuom]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Roboto",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              lineHeight: "22px"
                                            } as any
                                          }
                                        ></Label>
                                      </KTableColumn>
                                    </KTableRow>
                                  </KTable>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>

              <KRow
                id="398823"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)",
                    lineHeight: "22px"
                  } as any
                }
              ></KRow>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(DetailsOfCustomerOrder_Screen))))
  )
);
export { tmp as DetailsOfCustomerOrder_Screen };
//export default tmp;
//export { tmp as DetailsOfCustomerOrder_Screen };
