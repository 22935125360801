import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IYeniHaber_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IYeniHaber_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  SelectNewsById: any[];
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  DeleteNew: number;
  MakeNewsDraft: number;
  NAVIGATE: any;
  fourCompValidation: string;
  SetValueOf: any;
  SaveNews: number;
  Notify: boolean;
  ChangeVisibilityOf: string;
  IfThenElse: boolean;
  PhotoFromGallery: string;
  isComp619060Visible: "visible" | "hidden";
  isComp205640Visible: "visible" | "hidden";
  isComp293351Visible: "visible" | "hidden";
  isComp331456Visible: "visible" | "hidden";
  isComp955195Visible: "visible" | "hidden";
  isComp999766Visible: "visible" | "hidden";
  isComp928136Visible: "visible" | "hidden";
  isComp898503Visible: "visible" | "hidden";
}

export class YeniHaber_ScreenBase extends React.PureComponent<IYeniHaber_ScreenProps, any> {
  yenihaber_767842_value_kuikaDateRef: React.RefObject<any>;
  yenihaber_341287_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 955195, PropertyName: "value", Value: "Haber Oluştur", TagName: "Label1_value" },
        { Id: 999766, PropertyName: "value", Value: "Haber Düzenle", TagName: "Label8_value" },
        { Id: 928136, PropertyName: "label", Value: "Sil", TagName: "Button4_label" },
        { Id: 898503, PropertyName: "label", Value: "Yayından Kaldır", TagName: "Button3_label" },
        { Id: 293351, PropertyName: "label", Value: "Önizleme", TagName: "Button1_label" },
        { Id: 331456, PropertyName: "label", Value: "Önizleme", TagName: "Button6_label" },
        { Id: 932277, PropertyName: "label", Value: "Taslak Olarak Kaydet", TagName: "Button2_0_label" },
        { Id: 884638, PropertyName: "label", Value: "Kaydet ve Yayınla", TagName: "ContentMenuItem3_4_label" },
        { Id: 241391, PropertyName: "label", Value: "Taslak Olarak Kaydet", TagName: "Button4_0_label" },
        { Id: 286118, PropertyName: "label", Value: "Kaydet ve Yayınla", TagName: "ContentMenuItem8_4_label" },
        { Id: 976648, PropertyName: "value", Value: "Başlık", TagName: "Label9_value" },
        { Id: 18577, PropertyName: "value", Value: "İçerik", TagName: "Label12_value" },
        { Id: 310383, PropertyName: "value", Value: "Başlık", TagName: "Label9_0_value" },
        { Id: 372977, PropertyName: "value", Value: "İçerik", TagName: "Label11_value" },
        { Id: 3152, PropertyName: "value", Value: "Kapak Görseli", TagName: "Label4_value" },
        { Id: 941706, PropertyName: "value", Value: "Yayınlanma Tarihi", TagName: "Label5_value" },
        { Id: 551896, PropertyName: "value", Value: "Yayından Kaldırılma Tarihi", TagName: "Label6_value" },
        { Id: 779663, PropertyName: "tab", Value: "Türkçe", TagName: "TabItem1_0_tab" },
        { Id: 535893, PropertyName: "tab", Value: "İngilizce", TagName: "TabItem1_1_tab" },
        {
          Id: 42262,
          PropertyName: "value",
          Value: "Önerilen fotoğraf oranı 16:9 (1920x1080)",
          TagName: "Label10_value"
        }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "6e0039d1-f891-44e8-bc5e-c72467e3d3b9",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 955195, PropertyName: "value", Value: "Create News" },
        { Id: 999766, PropertyName: "value", Value: "Edit News" },
        { Id: 928136, PropertyName: "label", Value: "Delete" },
        { Id: 898503, PropertyName: "label", Value: "Expire" },
        { Id: 293351, PropertyName: "label", Value: "Preview" },
        { Id: 331456, PropertyName: "label", Value: "Preview" },
        { Id: 932277, PropertyName: "label", Value: "Save As Draft" },
        { Id: 884638, PropertyName: "label", Value: "Save and Publish" },
        { Id: 241391, PropertyName: "label", Value: "Save As Draft" },
        { Id: 286118, PropertyName: "label", Value: "Save and Publish" },
        { Id: 779663, PropertyName: "tab", Value: "Turkish" },
        { Id: 976648, PropertyName: "value", Value: "Title" },
        { Id: 212487, PropertyName: "placeholder", Value: "TR" },
        { Id: 18577, PropertyName: "value", Value: "Content" },
        { Id: 535893, PropertyName: "tab", Value: "English" },
        { Id: 310383, PropertyName: "value", Value: "Title" },
        { Id: 121451, PropertyName: "placeholder", Value: "EN" },
        { Id: 372977, PropertyName: "value", Value: "Content" },
        { Id: 3152, PropertyName: "value", Value: "Cover Image" },
        { Id: 42262, PropertyName: "value", Value: "Recommended photograph ratio is 16:9 (1920x1080)" },
        { Id: 941706, PropertyName: "value", Value: "Release Date" },
        { Id: 767842, PropertyName: "placeholder", Value: "..." },
        { Id: 551896, PropertyName: "value", Value: "Expire Date" },
        { Id: 341287, PropertyName: "placeholder", Value: "..." }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.yenihaber_767842_value_kuikaDateRef = React.createRef();
    this.yenihaber_341287_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      SelectNewsById: [],
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      DeleteNew: 0,
      MakeNewsDraft: 0,
      NAVIGATE: "",
      fourCompValidation: "",
      SetValueOf: "",
      SaveNews: 0,
      Notify: false,
      IfThenElse: false,
      PhotoFromGallery: "",
      isComp619060Visible: "visible",
      isComp205640Visible: "hidden",
      isComp293351Visible: "visible",
      isComp331456Visible: "hidden",
      isComp955195Visible: "hidden",
      isComp999766Visible: "hidden",
      isComp928136Visible: "hidden",
      isComp898503Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("yenihaber", "yenihaber");
      return;
    }

    if (true) {
      await this.YeniHaberPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("yenihaber", "yenihaber");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("yenihaber", "yenihaber");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.YeniHaberPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      yenihaber_212487_value: this.state.SelectNewsById?.at?.(0)?.title ?? undefined,
      yenihaber_157245_value: this.state.SelectNewsById?.at?.(0)?.content ?? undefined,
      yenihaber_121451_value: this.state.SelectNewsById?.at?.(0)?.titleEN ?? undefined,
      yenihaber_936640_value: this.state.SelectNewsById?.at?.(0)?.contentEN ?? undefined,
      yenihaber_852994_value: this.state.SelectNewsById?.at?.(0)?.coverImage ?? undefined,
      yenihaber_767842_value: this.state.SelectNewsById?.at?.(0)?.releaseDate ?? undefined,
      yenihaber_341287_value: this.state.SelectNewsById?.at?.(0)?.takedownDate ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  YeniHaberPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YeniHaberPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.newsID ?? this.props.screenInputs.newsid,
        "Guid"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YeniHaber/YeniHaberPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectNewsById = result?.data.selectNewsById;
    formVars.yenihaber_212487_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectNewsById?.length > 0 ? stateVars.SelectNewsById[0]?.title : null
    );
    formVars.yenihaber_121451_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectNewsById?.length > 0 ? stateVars.SelectNewsById[0]?.titleEN : null
    );
    formVars.yenihaber_852994_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectNewsById?.length > 0 ? stateVars.SelectNewsById[0]?.coverImage : null
    );
    formVars.yenihaber_767842_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectNewsById?.length > 0 ? stateVars.SelectNewsById[0]?.releaseDate : null
    );
    formVars.yenihaber_767842_disablebeforedate = Date();
    formVars.yenihaber_341287_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectNewsById?.length > 0 ? stateVars.SelectNewsById[0]?.takedownDate : null
    );
    formVars.yenihaber_341287_disablebeforedate = ReactSystemFunctions.value(
      this,
      ReactSystemFunctions.value(this, "yenihaber_767842_value", "value", "", "", "")
    );
    stateVars.isComp955195Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectNewsById?.length > 0 ? stateVars.SelectNewsById[0]?.title : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp999766Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectNewsById?.length > 0 ? stateVars.SelectNewsById[0]?.title : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp928136Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectNewsById?.length > 0 ? stateVars.SelectNewsById[0]?.title : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp898503Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectNewsById?.length > 0 ? stateVars.SelectNewsById[0]?.isDraft : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YeniHaberPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp955195Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectNewsById?.length > 0 ? this.state.SelectNewsById[0]?.title : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp999766Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectNewsById?.length > 0 ? this.state.SelectNewsById[0]?.title : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp928136Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectNewsById?.length > 0 ? this.state.SelectNewsById[0]?.title : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp898503Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.SelectNewsById?.length > 0 ? this.state.SelectNewsById[0]?.isDraft : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.yenihaber_212487_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectNewsById?.length > 0 ? this.state.SelectNewsById[0]?.title : null
    );

    formVars.yenihaber_157245_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectNewsById?.length > 0 ? this.state.SelectNewsById[0]?.content : null
    );

    formVars.yenihaber_121451_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectNewsById?.length > 0 ? this.state.SelectNewsById[0]?.titleEN : null
    );

    formVars.yenihaber_936640_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectNewsById?.length > 0 ? this.state.SelectNewsById[0]?.contentEN : null
    );

    formVars.yenihaber_852994_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectNewsById?.length > 0 ? this.state.SelectNewsById[0]?.coverImage : null
    );

    formVars.yenihaber_767842_value = ReactSystemFunctions.value(
      this,
      this.state.SelectNewsById?.length > 0 ? this.state.SelectNewsById[0]?.releaseDate : null
    );

    formVars.yenihaber_341287_value = ReactSystemFunctions.value(
      this,
      this.state.SelectNewsById?.length > 0 ? this.state.SelectNewsById[0]?.takedownDate : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  YeniHaberComponent_30880_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YeniHaberComponent_928136_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.newsID ?? this.props.screenInputs.newsid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "034ab375_9728_afff_7fbd_e00bfaf534a4_confirmation",
        this.defaultML,
        "Haber silmek istediğinizden emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "YeniHaber/YeniHaberComponent_928136_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteNew = result?.data.deleteNew;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.YeniHaberComponent_928136_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YeniHaberComponent_928136_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YeniHaberComponent_898503_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.newsID ?? this.props.screenInputs.newsid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YeniHaber/YeniHaberComponent_898503_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MakeNewsDraft = result?.data.makeNewsDraft;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_898503_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YeniHaberComponent_898503_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YeniHaberComponent_293351_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("HaberDetay", "newsID", "00000000-0000-0000-0000-000000000000");
    KuikaAppManager.addToPageInputVariables(
      "HaberDetay",
      "title",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "HaberDetay",
      "content",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "HaberDetay",
      "image",
      ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "yenihaber_852994_value", "value", "", "", ""))
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "YeniHaber",
      "HaberDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "798299",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YeniHaberComponent_331456_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("HaberDetay", "newsID", "00000000-0000-0000-0000-000000000000");
    KuikaAppManager.addToPageInputVariables(
      "HaberDetay",
      "title",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "yenihaber_121451_value", "value", "", "", "")
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "HaberDetay",
      "content",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "yenihaber_936640_value", "value", "", "", "")
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "HaberDetay",
      "image",
      ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "yenihaber_852994_value", "value", "", "", ""))
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "YeniHaber",
      "HaberDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "304643",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YeniHaberComponent_932277_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_936640_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_121451_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_212487_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_157245_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_936640_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_121451_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YeniHaber/YeniHaberComponent_932277_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_932277_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YeniHaberComponent_932277_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "yenihaber_802290_value", "535893", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_932277_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YeniHaberComponent_932277_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_157245_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_936640_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_767842_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_767842_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_341287_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_341287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_212487_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_121451_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      author_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        "string"
      ),
      contentEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_936640_value", "value", "", "", "")
        ),
        "string"
      ),
      coverImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_852994_value", "value", "", "", "")
        ),
        "string"
      ),
      isDraft_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      releaseDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_767842_value", "value", "", "", "")
        ),
        "Date"
      ),
      takedownDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_341287_value", "value", "", "", "")
        ),
        "Date"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        "string"
      ),
      titleEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_121451_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.newsID ?? this.props.screenInputs.newsid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YeniHaber/YeniHaberComponent_932277_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveNews = result?.data.saveNews;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_932277_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YeniHaberComponent_932277_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "7f405673_79bc_c215_05a8_b8dcf13ab46a_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YeniHaberComponent_884638_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_212487_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_157245_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName("asdfadfasdf", "string"),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YeniHaber/YeniHaberComponent_884638_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_884638_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YeniHaberComponent_884638_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "yenihaber_802290_value", "535893", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_884638_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YeniHaberComponent_884638_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_157245_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_936640_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_767842_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_767842_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_341287_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_341287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_212487_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_121451_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      author_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        "string"
      ),
      contentEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_936640_value", "value", "", "", "")
        ),
        "string"
      ),
      coverImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_852994_value", "value", "", "", "")
        ),
        "string"
      ),
      isDraft_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      releaseDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_767842_value", "value", "", "", "")
        ),
        "Date"
      ),
      takedownDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_341287_value", "value", "", "", "")
        ),
        "Date"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        "string"
      ),
      titleEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_121451_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.newsID ?? this.props.screenInputs.newsid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YeniHaber/YeniHaberComponent_884638_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveNews = result?.data.saveNews;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_884638_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YeniHaberComponent_884638_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "98581712_410c_98f8_4848_befe532b8b2b_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YeniHaberComponent_241391_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_767842_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_767842_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_341287_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_341287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_121451_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_936640_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_767842_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_341287_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_121451_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_936640_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YeniHaber/YeniHaberComponent_241391_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_241391_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YeniHaberComponent_241391_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "yenihaber_802290_value", "779663", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_241391_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YeniHaberComponent_241391_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_157245_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_936640_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_767842_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_767842_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_341287_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_341287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_212487_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_121451_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      author_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        "string"
      ),
      contentEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_936640_value", "value", "", "", "")
        ),
        "string"
      ),
      coverImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_852994_value", "value", "", "", "")
        ),
        "string"
      ),
      isDraft_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      releaseDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_767842_value", "value", "", "", "")
        ),
        "Date"
      ),
      takedownDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_341287_value", "value", "", "", "")
        ),
        "Date"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        "string"
      ),
      titleEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_121451_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.newsID ?? this.props.screenInputs.newsid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YeniHaber/YeniHaberComponent_241391_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveNews = result?.data.saveNews;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_241391_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YeniHaberComponent_241391_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b752e319_655a_e842_5a3a_d9b5b4ea166e_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YeniHaberComponent_286118_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_121451_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_936640_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName("asdfadfasdf", "string"),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_121451_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_936640_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YeniHaber/YeniHaberComponent_286118_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_286118_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YeniHaberComponent_286118_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "yenihaber_802290_value", "779663", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_286118_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YeniHaberComponent_286118_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_157245_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_936640_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_767842_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_767842_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_341287_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_341287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_212487_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "yenihaber_121451_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      author_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "string"
      ),
      content_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_157245_value", "value", "", "", "")
        ),
        "string"
      ),
      contentEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_936640_value", "value", "", "", "")
        ),
        "string"
      ),
      coverImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_852994_value", "value", "", "", "")
        ),
        "string"
      ),
      isDraft_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      releaseDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_767842_value", "value", "", "", "")
        ),
        "Date"
      ),
      takedownDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_341287_value", "value", "", "", "")
        ),
        "Date"
      ),
      title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_212487_value", "value", "", "", "")
        ),
        "string"
      ),
      titleEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "yenihaber_121451_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.newsID ?? this.props.screenInputs.newsid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "YeniHaber/YeniHaberComponent_286118_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveNews = result?.data.saveNews;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_286118_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  YeniHaberComponent_286118_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "50e3cf67_874a_7822_2267_368237871950_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YeniHaberComponent_802290_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp619060Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp205640Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp293351Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp331456Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  YeniHaberComponent_802290_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp619060Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp205640Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp293351Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp331456Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  YeniHaberComponent_802290_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "yenihaber_802290_value", "value", "", "", "")
        ),
        "779663"
      )
    ) {
      isErrorOccurred = await this.YeniHaberComponent_802290_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.YeniHaberComponent_802290_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YeniHaberComponent_181721_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.YeniHaberComponent_181721_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  YeniHaberComponent_181721_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "yenihaber_852994_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  YeniHaberComponent_376224_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "yenihaber_852994_value", null, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  YeniHaberComponent_767842_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    ReactSystemFunctions.setValueOf(
      this,
      "yenihaber_341287_value",
      ReactSystemFunctions.value(this, "yenihaber_767842_value", "value"),
      "disablebeforedate"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [798299, 304643] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.YeniHaberPageInit();
    }
  }
}
