import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KContentMenu,
  KContentMenuItem,
  KContentMenuPanel,
  KDrawer,
  KPagination,
  KPanel,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  Tooltip
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { CertificateList_ScreenBase } from "./certificatelist-base";

import { CertificateEdit_Screen } from "../certificateedit/certificateedit";
import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { Profile_Screen } from "../profile/profile";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class CertificateList_Screen extends CertificateList_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              certificatelist_960521_value: "d904a563-d1a7-4126-38a8-08dac24f7806",
              certificatelist_447826_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="certificatelist_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)",
                    lineHeight: "22px"
                  } as any
                }
              >
                <KRow
                  id="153395"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingRight: 44,
                      paddingLeft: 44,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="691322"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Icon
                      id="958546"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.CertificateListComponent_958546_onClick();
                      }}
                      showCursorPointer
                      iconName="menu"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "24px",
                          color: "rgba(99, 100, 102, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>

                  <KCol
                    id="444159"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="certificatelist_960521_value">
                      <Image
                        id="960521"
                        placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                        zoomOnClick={false}
                        imageFit="fit"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            width: "180px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Image>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="457616"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        textAlign: "-webkit-right",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="295188"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          alignItems: "center",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <Label
                        id="423022"
                        value={ReactSystemFunctions.getMyUsername()}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Label>

                      <KContentMenu
                        id="616698"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KContentMenuPanel
                          id="125356"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KPanel
                            id="230215"
                            scrolling="hidden"
                            scrollRadius={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                alignItems: "flex-start",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KRow
                              id="47705"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <KCol
                                id="845370"
                                visibility={this.state.isComp845370Visible}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    alignItems: "center",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="certificatelist_447826_value">
                                  <Image
                                    id="447826"
                                    visibility={this.state.isComp447826Visible}
                                    placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                                    zoomOnClick={false}
                                    imageFit="fill"
                                    style={
                                      {
                                        borderTopLeftRadius: 100,
                                        borderTopRightRadius: 100,
                                        borderBottomRightRadius: 100,
                                        borderBottomLeftRadius: 100,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        width: "48px",
                                        height: "48px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="233719"
                                visibility={this.state.isComp233719Visible}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    alignItems: "center",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Label
                                  id="251064"
                                  visibility={this.state.isComp251064Visible}
                                  value={this.state.GetUsersFirstLetter?.at?.(0)?.firstletters ?? undefined}
                                  style={
                                    {
                                      borderTopLeftRadius: 100,
                                      borderTopRightRadius: 100,
                                      borderBottomRightRadius: 100,
                                      borderBottomLeftRadius: 100,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(26, 72, 170, 1)",
                                      paddingTop: null,
                                      width: "48px",
                                      height: "48px",
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "52px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>
                          </KPanel>
                        </KContentMenuPanel>

                        <KContentMenuItem
                          id="567205"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.CertificateListComponent_567205_onClick();
                          }}
                          showCursorPointer
                          label={ReactSystemFunctions.translate(this.ml, 567205, "label", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></KContentMenuItem>

                        <KContentMenuItem
                          id="933869"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.CertificateListComponent_933869_onClick();
                          }}
                          showCursorPointer
                          label={ReactSystemFunctions.translate(this.ml, 933869, "label", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></KContentMenuItem>
                      </KContentMenu>
                    </HorizontalStack>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="certificatelist_body"
                style={
                  {
                    borderColor: "rgba(255, 255, 255, 1)",
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="846064"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 48,
                      paddingRight: 48,
                      paddingBottom: 48,
                      paddingLeft: 48,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                >
                  <KCol
                    id="429557"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        lineHeight: "22px"
                      } as any
                    }
                  >
                    <KRow
                      id="871443"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopLeftRadius: 12,
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="825360"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <KRow
                          id="398121"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KCol
                            id="601768"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <Label
                              id="240719"
                              value={ReactSystemFunctions.translate(this.ml, 240719, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Roboto",
                                  fontWeight: 300,
                                  fontSize: "18pt",
                                  color: "rgba(0, 0, 0, 1)"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="253378"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                textAlign: "-webkit-right",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <Button
                              id="92815"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.CertificateListComponent_92815_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isCompcertificatelist_92815AuthorizationVisible}
                              visibilityByAuthorization={this.state.isCompcertificatelist_92815AuthorizationVisible}
                              label={ReactSystemFunctions.translate(this.ml, 92815, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  borderBottomRightRadius: 4,
                                  borderBottomLeftRadius: 4,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(22, 193, 243, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 20,
                                  paddingBottom: 8,
                                  paddingLeft: 20,
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(255, 255, 255, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>
                          </KCol>
                        </KRow>

                        <KTable
                          id="713789"
                          kuikaRef={this.certificatelist_713789_value_kuikaTableRef}
                          form={this.props.form}
                          dataSource={this.state.SelectCertificates}
                          size="middle"
                          bordered={true}
                          showHeader={true}
                          loading={false}
                          nodatafoundmessage={ReactSystemFunctions.translate(
                            this.ml,
                            713789,
                            "nodatafoundmessage",
                            this.defaultML
                          )}
                          sorter={false}
                          pagination={false}
                          striped={false}
                          stripedColor="#F5F7FA"
                          style={
                            {
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        >
                          <KTableHeader
                            id="714813"
                            hideOnMobileResolution={false}
                            textDirection="Default"
                            style={
                              {
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(155, 155, 155, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></KTableHeader>

                          <KTableRow
                            id="595879"
                            hoverFontColor="red"
                            hoverBgColor="#F5F5F5"
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(227, 241, 250, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                lineHeight: "22px"
                              } as any
                            }
                          >
                            <KTableColumn
                              id="547175"
                              title={ReactSystemFunctions.translate(this.ml, 547175, "title", this.defaultML)}
                              style={
                                {
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  width: "12vw",
                                  height: "12px",
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="certificatelist_20874_value">
                                <Image
                                  id="20874"
                                  value="[datafield:certificateImage]"
                                  placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                                  zoomOnClick={false}
                                  imageFit="fill"
                                  style={
                                    {
                                      borderTopLeftRadius: 100,
                                      borderTopRightRadius: 100,
                                      borderBottomRightRadius: 100,
                                      borderBottomLeftRadius: 100,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      width: "64px",
                                      height: "64px",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Roboto",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Image>
                              </Form.Item>
                            </KTableColumn>

                            <KTableColumn
                              id="595603"
                              title={ReactSystemFunctions.translate(this.ml, 595603, "title", this.defaultML)}
                              style={
                                {
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <Label
                                id="646814"
                                value="[datafield:name]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: null,
                                    paddingRight: null,
                                    paddingBottom: null,
                                    paddingLeft: null,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "64px"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="318489"
                              title={ReactSystemFunctions.translate(this.ml, 318489, "title", this.defaultML)}
                              style={
                                {
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  width: "12px",
                                  height: "12px",
                                  alignItems: "center",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Roboto",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="831970"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              >
                                <Tooltip
                                  id="485327"
                                  title={ReactSystemFunctions.translate(this.ml, 485327, "title", this.defaultML)}
                                  placement="bottom"
                                  color="white"
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="800595"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.CertificateListComponent_800595_onClick();
                                    }}
                                    showCursorPointer
                                    visibility={this.state.isCompcertificatelist_800595AuthorizationVisible}
                                    visibilityByAuthorization={
                                      this.state.isCompcertificatelist_800595AuthorizationVisible
                                    }
                                    iconName="create"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(74, 74, 74, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </Tooltip>

                                <Tooltip
                                  id="309526"
                                  title={ReactSystemFunctions.translate(this.ml, 309526, "title", this.defaultML)}
                                  placement="bottom"
                                  color="white"
                                  style={
                                    {
                                      borderTopLeftRadius: 4,
                                      borderTopRightRadius: 4,
                                      borderBottomRightRadius: 4,
                                      borderBottomLeftRadius: 4,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(155, 155, 155, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="726267"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.CertificateListComponent_726267_onClick();
                                    }}
                                    showCursorPointer
                                    visibility={this.state.isCompcertificatelist_726267AuthorizationVisible}
                                    visibilityByAuthorization={
                                      this.state.isCompcertificatelist_726267AuthorizationVisible
                                    }
                                    iconName="delete"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(208, 2, 27, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </Tooltip>
                              </HorizontalStack>
                            </KTableColumn>
                          </KTableRow>
                        </KTable>

                        <KPagination
                          id="602474"
                          onChange={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.CertificateListComponent_602474_onChange();
                          }}
                          kuikaRef={this.certificatelist_602474_value_kuikaPaginationRef}
                          total={this.state.CertificatesTotalCount?.at?.(0)?.totalCount ?? undefined}
                          pageSize={20}
                          defaultCurrent={1}
                          current={1}
                          defaultPageSize={10}
                          disabled={false}
                          hideOnSinglePage={true}
                          showLessItems={false}
                          showQuickJumper={false}
                          showSizeChanger={false}
                          showFirstPageButton={false}
                          showLastPageButton={false}
                          showNumbers={true}
                          showText={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 14,
                              textAlign: "-webkit-right"
                            } as any
                          }
                        ></KPagination>
                      </KCol>
                    </KRow>

                    <KRow
                      id="697314"
                      visibility={this.state.isComp697314Visible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    >
                      <KCol
                        id="688155"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      >
                        <Button
                          id="775359"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.CertificateListComponent_775359_onClick();
                          }}
                          showCursorPointer
                          label={ReactSystemFunctions.translate(this.ml, 775359, "label", this.defaultML)}
                          size="middle"
                          loading={false}
                          ghost={false}
                          block={false}
                          htmlType="button"
                          iconPosition="left"
                          danger={false}
                          style={
                            {
                              borderTopLeftRadius: 4,
                              borderTopRightRadius: 4,
                              borderBottomRightRadius: 4,
                              borderBottomLeftRadius: 4,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(22, 193, 243, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 20,
                              paddingBottom: 8,
                              paddingLeft: 20,
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "16px",
                              color: "rgba(255, 255, 255, 1)",
                              lineHeight: "22px"
                            } as any
                          }
                        ></Button>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="325596"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible325596: false }, () => {
                      this.callCallbackFunction(325596);
                    });
                  }}
                  visible={this.state.NavVisible325596}
                  width="520px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible325596 && (
                      <CertificateEdit_Screen
                        onClose={() => {
                          this.setState({ NavVisible325596: false }, () => {
                            this.callCallbackFunction(325596);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="161018"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible161018: false }, () => {
                      this.callCallbackFunction(161018);
                    });
                  }}
                  visible={this.state.NavVisible161018}
                  width="520px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible161018 && (
                      <CertificateEdit_Screen
                        onClose={() => {
                          this.setState({ NavVisible161018: false }, () => {
                            this.callCallbackFunction(161018);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="459156"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible459156: false }, () => {
                      this.callCallbackFunction(459156);
                    });
                  }}
                  visible={this.state.NavVisible459156}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible459156 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible459156: false }, () => {
                            this.callCallbackFunction(459156);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="114695"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible114695: false }, () => {
                      this.callCallbackFunction(114695);
                    });
                  }}
                  visible={this.state.NavVisible114695}
                  width="400px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible114695 && (
                      <Profile_Screen
                        onClose={() => {
                          this.setState({ NavVisible114695: false }, () => {
                            this.callCallbackFunction(114695);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      lineHeight: "22px"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(CertificateList_Screen))))
  )
);
export { tmp as CertificateList_Screen };
//export default tmp;
//export { tmp as CertificateList_Screen };
