import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IApplicationAreasEditcopy_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IApplicationAreasEditcopy_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  SetValueOf: any;
  GetLanguage: string;
  SelectAppliactionAreasById: any[];
  PhotoFromGallery: string;
  ChangeVisibilityOf: string;
  IfThenElse: boolean;
  fourCompValidation: string;
  SaveApplicationAreas: number;
  Notify: boolean;
  isComp568915Visible: "visible" | "hidden";
  isComp208975Visible: "visible" | "hidden";
  isComp747009Visible: "visible" | "hidden";
  isComp3022Visible: "visible" | "hidden";
}

export class ApplicationAreasEditcopy_ScreenBase extends React.PureComponent<
  IApplicationAreasEditcopy_ScreenProps,
  any
> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 880079, PropertyName: "value", Value: "Görsel Ekle", TagName: "Label1_value" },
        { Id: 241619, PropertyName: "value", Value: "İsim", TagName: "Label3_value" },
        { Id: 443801, PropertyName: "value", Value: "İsim", TagName: "Label3_value" },
        { Id: 568915, PropertyName: "label", Value: "Kaydet", TagName: "Button2_label" },
        { Id: 208975, PropertyName: "label", Value: "Kaydet", TagName: "Button3_label" },
        { Id: 747009, PropertyName: "value", Value: "Uygulama Alanı Düzenle", TagName: "Label4_value" },
        { Id: 3022, PropertyName: "value", Value: "Yeni Uygulama Alanı", TagName: "Label2_value" },
        { Id: 931855, PropertyName: "tab", Value: "Türkçe", TagName: "TabItem1_0_tab" },
        { Id: 396750, PropertyName: "tab", Value: "İngilizce", TagName: "TabItem1_1_tab" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "a22bb64f-adb1-4757-9196-fb510895f423",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 747009, PropertyName: "value", Value: "Application Area Edit" },
        { Id: 3022, PropertyName: "value", Value: "New Application Area" },
        { Id: 880079, PropertyName: "value", Value: "Add image" },
        { Id: 931855, PropertyName: "tab", Value: "Turkish" },
        { Id: 241619, PropertyName: "value", Value: "Name" },
        { Id: 814447, PropertyName: "placeholder", Value: "TR" },
        { Id: 396750, PropertyName: "tab", Value: "English" },
        { Id: 443801, PropertyName: "value", Value: "Name" },
        { Id: 235636, PropertyName: "placeholder", Value: "ENG" },
        { Id: 568915, PropertyName: "label", Value: "Save" },
        { Id: 208975, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SetValueOf: "",
      GetLanguage: "",
      SelectAppliactionAreasById: [],
      PhotoFromGallery: "",
      IfThenElse: false,
      fourCompValidation: "",
      SaveApplicationAreas: 0,
      Notify: false,
      isComp568915Visible: "visible",
      isComp208975Visible: "hidden",
      isComp747009Visible: "hidden",
      isComp3022Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("applicationareaseditcopy", "applicationareaseditcopy");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ApplicationAreasEditcopyPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("applicationareaseditcopy", "applicationareaseditcopy");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("applicationareaseditcopy", "applicationareaseditcopy");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ApplicationAreasEditcopyPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      applicationareaseditcopy_332428_value:
        this.state.SelectAppliactionAreasById?.at?.(0)?.applicationAreasImage ?? undefined,
      applicationareaseditcopy_814447_value: this.state.SelectAppliactionAreasById?.at?.(0)?.name ?? undefined,
      applicationareaseditcopy_235636_value: this.state.SelectAppliactionAreasById?.at?.(0)?.nameEN ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ApplicationAreasEditcopyPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "applicationareaseditcopy_28778_value",
      "931855",
      null
    );
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ApplicationAreasEditcopyPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ApplicationAreasEditcopyPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ApplicationAreaID ?? this.props.screenInputs.applicationareaid,
        "Guid"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ApplicationAreasEditcopy/ApplicationAreasEditcopyPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectAppliactionAreasById = result?.data.selectAppliactionAreasById;
    formVars.applicationareaseditcopy_332428_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAppliactionAreasById?.length > 0
        ? stateVars.SelectAppliactionAreasById[0]?.applicationAreasImage
        : null
    );
    formVars.applicationareaseditcopy_814447_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAppliactionAreasById?.length > 0 ? stateVars.SelectAppliactionAreasById[0]?.name : null
    );
    formVars.applicationareaseditcopy_235636_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAppliactionAreasById?.length > 0 ? stateVars.SelectAppliactionAreasById[0]?.nameEN : null
    );
    stateVars.isComp747009Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectAppliactionAreasById?.length > 0 ? stateVars.SelectAppliactionAreasById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3022Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectAppliactionAreasById?.length > 0 ? stateVars.SelectAppliactionAreasById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ApplicationAreasEditcopyPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ApplicationAreasEditcopyPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp747009Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SelectAppliactionAreasById?.length > 0 ? this.state.SelectAppliactionAreasById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3022Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SelectAppliactionAreasById?.length > 0 ? this.state.SelectAppliactionAreasById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.applicationareaseditcopy_332428_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectAppliactionAreasById?.length > 0
        ? this.state.SelectAppliactionAreasById[0]?.applicationAreasImage
        : null
    );

    formVars.applicationareaseditcopy_814447_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectAppliactionAreasById?.length > 0 ? this.state.SelectAppliactionAreasById[0]?.name : null
    );

    formVars.applicationareaseditcopy_235636_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectAppliactionAreasById?.length > 0 ? this.state.SelectAppliactionAreasById[0]?.nameEN : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ApplicationAreasEditcopyComponent_863744_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ApplicationAreasEditcopyComponent_880079_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ApplicationAreasEditcopyComponent_880079_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ApplicationAreasEditcopyComponent_880079_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "applicationareaseditcopy_332428_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ApplicationAreasEditcopyComponent_28778_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp568915Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp208975Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ApplicationAreasEditcopyComponent_28778_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp568915Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp208975Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ApplicationAreasEditcopyComponent_28778_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_28778_value", "value", "", "", "")
        ),
        "931855"
      )
    ) {
      isErrorOccurred = await this.ApplicationAreasEditcopyComponent_28778_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ApplicationAreasEditcopyComponent_28778_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ApplicationAreasEditcopyComponent_568915_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_814447_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "applicationareaseditcopy_814447_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_814447_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("sdfasdfasdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdfasdfa", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ApplicationAreasEditcopy/ApplicationAreasEditcopyComponent_568915_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ApplicationAreasEditcopyComponent_568915_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ApplicationAreasEditcopyComponent_568915_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "applicationareaseditcopy_28778_value",
      "396750",
      null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ApplicationAreasEditcopyComponent_568915_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ApplicationAreasEditcopyComponent_568915_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_332428_value", "value", "", "", "")
        ),
        "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
      ),
      message: "This field is required",
      formName: "applicationareaseditcopy_332428_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_814447_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "applicationareaseditcopy_814447_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_235636_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "applicationareaseditcopy_235636_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      applicationAreasImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_332428_value", "value", "", "", "")
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_814447_value", "value", "", "", "")
        ),
        "string"
      ),
      nameEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_235636_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ApplicationAreaID ?? this.props.screenInputs.applicationareaid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ApplicationAreasEditcopy/ApplicationAreasEditcopyComponent_568915_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveApplicationAreas = result?.data.saveApplicationAreas;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ApplicationAreasEditcopyComponent_568915_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ApplicationAreasEditcopyComponent_568915_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "3f9767da_ee80_46be_8faa_4481c3af321b_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ApplicationAreasEditcopyComponent_208975_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_235636_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "applicationareaseditcopy_235636_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_235636_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter3_0: ReactSystemFunctions.convertToTypeByName("asdfasdf", "string"),
      parameter4_0: ReactSystemFunctions.convertToTypeByName("asdfasdfaf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ApplicationAreasEditcopy/ApplicationAreasEditcopyComponent_208975_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.fourCompValidation = result?.data.fourCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ApplicationAreasEditcopyComponent_208975_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ApplicationAreasEditcopyComponent_208975_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "applicationareaseditcopy_28778_value",
      "931855",
      null
    );

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ApplicationAreasEditcopyComponent_208975_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ApplicationAreasEditcopyComponent_208975_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_332428_value", "value", "", "", "")
        ),
        "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
      ),
      message: "This field is required",
      formName: "applicationareaseditcopy_332428_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_814447_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "applicationareaseditcopy_814447_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_235636_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "applicationareaseditcopy_235636_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      applicationAreasImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_332428_value", "value", "", "", "")
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_814447_value", "value", "", "", "")
        ),
        "string"
      ),
      nameEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "applicationareaseditcopy_235636_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ApplicationAreaID ?? this.props.screenInputs.applicationareaid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ApplicationAreasEditcopy/ApplicationAreasEditcopyComponent_208975_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveApplicationAreas = result?.data.saveApplicationAreas;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ApplicationAreasEditcopyComponent_208975_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ApplicationAreasEditcopyComponent_208975_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "8345e68a_f2f3_9c09_f23c_b556055b5047_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
