import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProductsView_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProductsView_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  SelectProductsById: any[];
  GetSelectedProductApplicationArea: any[];
}

export class ProductsView_ScreenBase extends React.PureComponent<IProductsView_ScreenProps, any> {
  productsview_453684_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 20726, PropertyName: "value", Value: "Saklama Koşulları", TagName: "Label0_0_value" },
        { Id: 448679, PropertyName: "value", Value: "Uygulama Alanları", TagName: "Label0_0_value" },
        { Id: 427504, PropertyName: "value", Value: "Özellikler", TagName: "Label0_0_value" },
        { Id: 228946, PropertyName: "value", Value: "Ürün İçerikleri", TagName: "Label0_0_value" },
        { Id: 696701, PropertyName: "value", Value: "Ambalajlama", TagName: "Label0_0_value" },
        { Id: 91174, PropertyName: "value", Value: "Ürün", TagName: "Label16_value" },
        { Id: 982248, PropertyName: "value", Value: "Uygulama Alanları", TagName: "Label12_0_value" },
        {
          Id: 453684,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 228086, PropertyName: "title", Value: "İsim", TagName: "TableColumn1_1_title" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "0679a9f3-e250-42f9-8fb8-3d98d69bb50c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 23827, PropertyName: "value", Value: "/" },
        { Id: 20726, PropertyName: "value", Value: "Storage Conditions" },
        { Id: 448679, PropertyName: "value", Value: "Application" },
        { Id: 427504, PropertyName: "value", Value: "Properties" },
        { Id: 228946, PropertyName: "value", Value: "Products Contents" },
        { Id: 696701, PropertyName: "value", Value: "Packaging" },
        { Id: 982248, PropertyName: "value", Value: "Application Areas" },
        { Id: 453684, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 228086, PropertyName: "title", Value: "Name" },
        { Id: 736843, PropertyName: "value", Value: "[datafield:name_label]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.productsview_453684_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      SelectProductsById: [],
      GetSelectedProductApplicationArea: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("productsview", "productsview");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProductsViewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("productsview", "productsview");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("productsview", "productsview");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProductsViewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      productsview_654610_value: this.state.SelectProductsById?.at?.(0)?.productImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ProductsViewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsViewPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductsViewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProductListID ?? this.props.screenInputs.productlistid,
        "Guid"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      productID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProductListID ?? this.props.screenInputs.productlistid,
        "Guid"
      ),
      current_1: ReactSystemFunctions.convertToTypeByName(1, "number"),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(20, "number"),
      language_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductsView/ProductsViewPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectProductsById = result?.data.selectProductsById;
    formVars.productsview_397256_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.productGroupName : null
    );
    formVars.productsview_91174_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.name : null
    );
    formVars.productsview_654610_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.productImage : null
    );
    formVars.productsview_482158_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.storageConditions_Label : null
    );
    formVars.productsview_958926_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.application_Label : null
    );
    formVars.productsview_882891_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.properties_Label : null
    );
    formVars.productsview_715366_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.productContents_Label : null
    );
    formVars.productsview_744998_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.packaging_Label : null
    );
    stateVars.GetSelectedProductApplicationArea = result?.data.getSelectedProductApplicationArea;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsViewPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductsViewPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.productsview_397256_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.productGroupName : null
    );

    formVars.productsview_91174_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.name : null
    );

    formVars.productsview_654610_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.productImage : null
    );

    formVars.productsview_482158_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.storageConditions_Label : null
    );

    formVars.productsview_958926_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.application_Label : null
    );

    formVars.productsview_882891_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.properties_Label : null
    );

    formVars.productsview_715366_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.productContents_Label : null
    );

    formVars.productsview_744998_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.packaging_Label : null
    );

    stateVars.dataSource_453684 = this.state.GetSelectedProductApplicationArea;
    formVars.productsview_131557_value = ReactSystemFunctions.toString(
      this,
      this.state.GetSelectedProductApplicationArea?.length > 0
        ? this.state.GetSelectedProductApplicationArea[0]?.applicationAreasImage
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProductsViewComponent_949701_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductsViewComponent_736843_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
