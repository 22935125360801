import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IApplicationAreasView_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IApplicationAreasView_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GetLanguage: string;
  SelectAppliactionAreasById: any[];
}

export class ApplicationAreasView_ScreenBase extends React.PureComponent<IApplicationAreasView_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [{ Id: 446927, PropertyName: "value", Value: "Uygulama Alanı", TagName: "Label2_value" }]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "5a3f8412-d7ba-4dc3-9b0c-fb57c2e88280",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [{ Id: 446927, PropertyName: "value", Value: "Application Area" }]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetLanguage: "",
      SelectAppliactionAreasById: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("applicationareasview", "applicationareasview");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ApplicationAreasViewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("applicationareasview", "applicationareasview");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("applicationareasview", "applicationareasview");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ApplicationAreasViewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      applicationareasview_316326_value:
        this.state.SelectAppliactionAreasById?.at?.(0)?.applicationAreasImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ApplicationAreasViewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ApplicationAreasViewPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ApplicationAreasViewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ApplicationAreaID ?? this.props.screenInputs.applicationareaid,
        "Guid"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ApplicationAreasView/ApplicationAreasViewPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectAppliactionAreasById = result?.data.selectAppliactionAreasById;
    formVars.applicationareasview_316326_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAppliactionAreasById?.length > 0
        ? stateVars.SelectAppliactionAreasById[0]?.applicationAreasImage
        : null
    );
    formVars.applicationareasview_397389_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectAppliactionAreasById?.length > 0 ? stateVars.SelectAppliactionAreasById[0]?.name_Label : null
    );
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ApplicationAreasViewPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ApplicationAreasViewPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.applicationareasview_316326_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectAppliactionAreasById?.length > 0
        ? this.state.SelectAppliactionAreasById[0]?.applicationAreasImage
        : null
    );

    formVars.applicationareasview_397389_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectAppliactionAreasById?.length > 0 ? this.state.SelectAppliactionAreasById[0]?.name_Label : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ApplicationAreasViewComponent_681184_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
