import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProductsEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProductsEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  SetValueOf: any;
  GetLanguage: string;
  SelectProductsById: any[];
  SelectApplicationAreasForProduct: any[];
  getAllProductGroup: any[];
  PhotoFromGallery: string;
  ChangeVisibilityOf: string;
  IfThenElse: boolean;
  RemoveApplicationAreaFromProduct: number;
  AddApplicationAreaToProductAsDraft: number;
  eightCompValidation: string;
  SaveProducts: number;
  UpdateDraftApplicationAreasForProduct: number;
  Notify: boolean;
  isComp975696Visible: "visible" | "hidden";
  isComp370097Visible: "visible" | "hidden";
  isComp869047Visible: "visible" | "hidden";
  isComp745381Visible: "visible" | "hidden";
}

export class ProductsEdit_ScreenBase extends React.PureComponent<IProductsEdit_ScreenProps, any> {
  productsedit_489749_value_kuikaSelectBoxRef: React.RefObject<any>;
  productsedit_513133_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 500450, PropertyName: "value", Value: "Görsel Ekle", TagName: "Label7_value" },
        { Id: 886392, PropertyName: "value", Value: "İsim", TagName: "Label2_value" },
        { Id: 88472, PropertyName: "value", Value: "Uygulama Alanları", TagName: "Label3_value" },
        { Id: 679811, PropertyName: "value", Value: "Özellikleri", TagName: "Label4_0_value" },
        { Id: 638186, PropertyName: "value", Value: "Ambalajlama", TagName: "Label3_0_value" },
        { Id: 574214, PropertyName: "value", Value: "Saklama Koşulları", TagName: "Label7_0_value" },
        { Id: 387383, PropertyName: "value", Value: "Ürün İçeriği", TagName: "Label8_0_value" },
        { Id: 239165, PropertyName: "value", Value: "Uygulama Alanları", TagName: "Label9_0_value" },
        { Id: 651943, PropertyName: "value", Value: "Özellikleri", TagName: "Label10_0_value" },
        { Id: 952669, PropertyName: "value", Value: "Paketleme", TagName: "Label11_0_value" },
        { Id: 506731, PropertyName: "value", Value: "Saklama Koşulları", TagName: "Label12_0_value" },
        { Id: 294743, PropertyName: "value", Value: "Ürün İçeriği", TagName: "Label13_0_value" },
        { Id: 651579, PropertyName: "value", Value: "Uygulama Alanları", TagName: "Label8_value" },
        { Id: 975696, PropertyName: "label", Value: "Kaydet", TagName: "Button1_label" },
        { Id: 370097, PropertyName: "label", Value: "Kaydet", TagName: "Button2_label" },
        { Id: 869047, PropertyName: "value", Value: "Ürün Düzenle", TagName: "Label15_value" },
        {
          Id: 513133,
          PropertyName: "nodatafoundmessage",
          Value: "Veri bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 96688, PropertyName: "title", Value: "İsim", TagName: "TableColumn0_1_title" },
        { Id: 745381, PropertyName: "value", Value: "Yeni Ürün", TagName: "Label1_value" },
        { Id: 645200, PropertyName: "value", Value: "Ürün Grubu", TagName: "Label0_0_value" },
        { Id: 42906, PropertyName: "tab", Value: "Türkçe", TagName: "TabItem1_0_tab" },
        { Id: 748297, PropertyName: "tab", Value: "İngilizce", TagName: "TabItem1_1_tab" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "dc84215c-11de-4152-b508-8b5a7b7f7c0e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 869047, PropertyName: "value", Value: "Product Edit" },
        { Id: 745381, PropertyName: "value", Value: "New Product" },
        { Id: 500450, PropertyName: "value", Value: "Add image" },
        { Id: 42906, PropertyName: "tab", Value: "Turkish" },
        { Id: 886392, PropertyName: "value", Value: "Name" },
        { Id: 645200, PropertyName: "value", Value: "Product Group" },
        { Id: 88472, PropertyName: "value", Value: "Application" },
        { Id: 361851, PropertyName: "placeholder", Value: "TR" },
        { Id: 679811, PropertyName: "value", Value: "Properties" },
        { Id: 139465, PropertyName: "placeholder", Value: "TR" },
        { Id: 638186, PropertyName: "value", Value: "Packaging" },
        { Id: 338498, PropertyName: "placeholder", Value: "TR" },
        { Id: 574214, PropertyName: "value", Value: "Storage Conditions" },
        { Id: 609119, PropertyName: "placeholder", Value: "TR" },
        { Id: 387383, PropertyName: "value", Value: "Product Contents" },
        { Id: 747691, PropertyName: "placeholder", Value: "TR" },
        { Id: 748297, PropertyName: "tab", Value: "English" },
        { Id: 239165, PropertyName: "value", Value: "Application" },
        { Id: 92905, PropertyName: "placeholder", Value: "ENG" },
        { Id: 651943, PropertyName: "value", Value: "Properties" },
        { Id: 169148, PropertyName: "placeholder", Value: "ENG" },
        { Id: 952669, PropertyName: "value", Value: "Packaging" },
        { Id: 614778, PropertyName: "placeholder", Value: "ENG" },
        { Id: 506731, PropertyName: "value", Value: "Storage Conditions" },
        { Id: 896797, PropertyName: "placeholder", Value: "ENG" },
        { Id: 294743, PropertyName: "value", Value: "Product Contents" },
        { Id: 439405, PropertyName: "placeholder", Value: "ENG" },
        { Id: 651579, PropertyName: "value", Value: "Application Areas" },
        { Id: 513133, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 96688, PropertyName: "title", Value: "Name" },
        { Id: 736766, PropertyName: "value", Value: "[datafield:name_label]" },
        { Id: 975696, PropertyName: "label", Value: "Save" },
        { Id: 370097, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.productsedit_489749_value_kuikaSelectBoxRef = React.createRef();
    this.productsedit_513133_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SetValueOf: "",
      GetLanguage: "",
      SelectProductsById: [],
      SelectApplicationAreasForProduct: [],
      getAllProductGroup: [],
      PhotoFromGallery: "",
      IfThenElse: false,
      eightCompValidation: "",
      SaveProducts: 0,
      UpdateDraftApplicationAreasForProduct: 0,
      Notify: false,
      isComp975696Visible: "visible",
      isComp370097Visible: "hidden",
      isComp869047Visible: "hidden",
      isComp745381Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("productsedit", "productsedit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProductsEditPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("productsedit", "productsedit");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("productsedit", "productsedit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProductsEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      productsedit_688132_value: this.state.SelectProductsById?.at?.(0)?.productImage ?? undefined,
      productsedit_841985_value: this.state.SelectProductsById?.at?.(0)?.name ?? undefined,
      productsedit_489749_value: this.state.SelectProductsById?.at?.(0)?.productsGroupID ?? undefined,
      productsedit_361851_value: this.state.SelectProductsById?.at?.(0)?.application ?? undefined,
      productsedit_139465_value: this.state.SelectProductsById?.at?.(0)?.properties ?? undefined,
      productsedit_338498_value: this.state.SelectProductsById?.at?.(0)?.packaging ?? undefined,
      productsedit_609119_value: this.state.SelectProductsById?.at?.(0)?.storageConditions ?? undefined,
      productsedit_747691_value: this.state.SelectProductsById?.at?.(0)?.productContents ?? undefined,
      productsedit_92905_value: this.state.SelectProductsById?.at?.(0)?.applicationEN ?? undefined,
      productsedit_169148_value: this.state.SelectProductsById?.at?.(0)?.propertiesEN ?? undefined,
      productsedit_614778_value: this.state.SelectProductsById?.at?.(0)?.packagingEN ?? undefined,
      productsedit_896797_value: this.state.SelectProductsById?.at?.(0)?.storageConditionsEN ?? undefined,
      productsedit_439405_value: this.state.SelectProductsById?.at?.(0)?.productContentsEN ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ProductsEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productsedit_936816_value", "42906", null);
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsEditPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductsEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productID ?? this.props.screenInputs.productid,
        "Guid"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      productID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productID ?? this.props.screenInputs.productid,
        "Guid"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(1, "number"),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(20, "number"),
      language_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductsEdit/ProductsEditPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectProductsById = result?.data.selectProductsById;
    formVars.productsedit_688132_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.productImage : null
    );
    formVars.productsedit_841985_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.name : null
    );
    formVars.productsedit_489749_value =
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.productsGroupID : null;
    formVars.productsedit_489749_options = stateVars.getAllProductGroup;
    formVars.productsedit_361851_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.application : null
    );
    formVars.productsedit_139465_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.properties : null
    );
    formVars.productsedit_338498_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.packaging : null
    );
    formVars.productsedit_609119_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.storageConditions : null
    );
    formVars.productsedit_747691_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.productContents : null
    );
    formVars.productsedit_92905_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.applicationEN : null
    );
    formVars.productsedit_169148_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.propertiesEN : null
    );
    formVars.productsedit_614778_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.packagingEN : null
    );
    formVars.productsedit_896797_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.storageConditionsEN : null
    );
    formVars.productsedit_439405_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.productContentsEN : null
    );
    stateVars.isComp869047Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp745381Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SelectApplicationAreasForProduct = result?.data.selectApplicationAreasForProduct;

    stateVars.getAllProductGroup = result?.data.getAllProductGroup;

    formVars.productsedit_489749_value =
      stateVars.SelectProductsById?.length > 0 ? stateVars.SelectProductsById[0]?.productsGroupID : null;
    formVars.productsedit_489749_options = stateVars.getAllProductGroup;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsEditPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductsEditPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp869047Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp745381Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.id : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.productsedit_688132_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.productImage : null
    );

    formVars.productsedit_841985_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.name : null
    );

    formVars.productsedit_489749_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.productsGroupID : null
    );

    stateVars.dataSource_489749 = this.state.getAllProductGroup;
    formVars.productsedit_361851_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.application : null
    );

    formVars.productsedit_139465_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.properties : null
    );

    formVars.productsedit_338498_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.packaging : null
    );

    formVars.productsedit_609119_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.storageConditions : null
    );

    formVars.productsedit_747691_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.productContents : null
    );

    formVars.productsedit_92905_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.applicationEN : null
    );

    formVars.productsedit_169148_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.propertiesEN : null
    );

    formVars.productsedit_614778_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.packagingEN : null
    );

    formVars.productsedit_896797_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.storageConditionsEN : null
    );

    formVars.productsedit_439405_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectProductsById?.length > 0 ? this.state.SelectProductsById[0]?.productContentsEN : null
    );

    stateVars.dataSource_513133 = this.state.SelectApplicationAreasForProduct;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProductsEditComponent_208974_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductsEditComponent_500450_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(true, "");

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsEditComponent_500450_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductsEditComponent_500450_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "productsedit_688132_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ProductsEditComponent_936816_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp975696Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp370097Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProductsEditComponent_936816_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp975696Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp370097Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ProductsEditComponent_936816_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productsedit_936816_value", "value", "", "", "")
        ),
        "42906"
      )
    ) {
      isErrorOccurred = await this.ProductsEditComponent_936816_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ProductsEditComponent_936816_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductsEditComponent_495190_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      productID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productID ?? this.props.screenInputs.productid,
        "Guid"
      ),
      applicationAreasID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "productsedit_513133_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductsEdit/ProductsEditComponent_495190_onClick_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RemoveApplicationAreaFromProduct = result?.data.removeApplicationAreaFromProduct;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  ProductsEditComponent_495190_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      applicationAreasID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "productsedit_513133_value", "id"),
        "Guid"
      ),
      productID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productID ?? this.props.screenInputs.productid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductsEdit/ProductsEditComponent_495190_onClick_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AddApplicationAreaToProductAsDraft = result?.data.addApplicationAreaToProductAsDraft;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  ProductsEditComponent_495190_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "productsedit_513133_value", "isSelected"), "1")
    ) {
      isErrorOccurred = await this.ProductsEditComponent_495190_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ProductsEditComponent_495190_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsEditComponent_495190_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductsEditComponent_495190_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      productID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productID ?? this.props.screenInputs.productid,
        "Guid"
      ),
      currentPage_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      pageSize_0: ReactSystemFunctions.convertToTypeByName(20, "number"),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductsEdit/ProductsEditComponent_495190_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectApplicationAreasForProduct = result?.data.selectApplicationAreasForProduct;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsEditComponent_495190_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductsEditComponent_495190_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_513133 = this.state.SelectApplicationAreasForProduct;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductsEditComponent_975696_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_361851_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_361851_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_139465_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_139465_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_338498_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_338498_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_609119_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_609119_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_747691_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_747691_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_841985_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_841985_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_489749_value", "value", "getAllProductGroup", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_489749_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_361851_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_139465_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_338498_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_609119_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_747691_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_841985_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_489749_value", "value", "getAllProductGroup", "id", "id")
        ),
        "string"
      ),
      parameter8_0: ReactSystemFunctions.convertToTypeByName("fasdfasdfasdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductsEdit/ProductsEditComponent_975696_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.eightCompValidation = result?.data.eightCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsEditComponent_975696_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductsEditComponent_975696_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productsedit_936816_value", "748297", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsEditComponent_975696_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductsEditComponent_975696_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_361851_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_361851_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_841985_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_841985_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_338498_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_338498_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_747691_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_747691_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_489749_value", "value", "getAllProductGroup", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_489749_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_139465_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_139465_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_609119_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_609119_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productsedit_688132_value", "value", "", "", "")
        ),
        "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
      ),
      message: "This field is required",
      formName: "productsedit_688132_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_92905_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_92905_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_614778_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_614778_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_439405_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_439405_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_169148_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_169148_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_896797_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_896797_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      application_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_361851_value", "value", "", "", "")
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_841985_value", "value", "", "", "")
        ),
        "string"
      ),
      packaging_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_338498_value", "value", "", "", "")
        ),
        "string"
      ),
      productContents_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_747691_value", "value", "", "", "")
        ),
        "string"
      ),
      productsGroupID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productsedit_489749_value", "value", "getAllProductGroup", "id", "id")
        ),
        "Guid"
      ),
      properties_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_139465_value", "value", "", "", "")
        ),
        "string"
      ),
      storageConditions_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_609119_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productID ?? this.props.screenInputs.productid,
        "Guid"
      ),
      productImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_688132_value", "value", "", "", "")
        ),
        "string"
      ),
      applicationEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_92905_value", "value", "", "", "")
        ),
        "string"
      ),
      packagingEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_614778_value", "value", "", "", "")
        ),
        "string"
      ),
      productContentsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_439405_value", "value", "", "", "")
        ),
        "string"
      ),
      propertiesEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_169148_value", "value", "", "", "")
        ),
        "string"
      ),
      storageConditionsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_896797_value", "value", "", "", "")
        ),
        "string"
      ),
      productID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productID ?? this.props.screenInputs.productid,
        "Guid"
      ),
      applicationAreasID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.SelectApplicationAreasForProduct?.length > 0
            ? this.state.SelectApplicationAreasForProduct[0]?.id
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductsEdit/ProductsEditComponent_975696_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveProducts = result?.data.saveProducts;
    stateVars.UpdateDraftApplicationAreasForProduct = result?.data.updateDraftApplicationAreasForProduct;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsEditComponent_975696_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductsEditComponent_975696_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "34094487_aa8a_8d64_53c7_3062f05d70c9_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProductsEditComponent_370097_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_92905_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_92905_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_169148_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_169148_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_614778_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_614778_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_896797_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_896797_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_439405_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_439405_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_841985_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_841985_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      parameter1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_92905_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_169148_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_614778_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_896797_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_439405_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_841985_value", "value", "", "", "")
        ),
        "string"
      ),
      parameter7_0: ReactSystemFunctions.convertToTypeByName("dfasdfasdfasd", "string"),
      parameter8_0: ReactSystemFunctions.convertToTypeByName("fasdfasdfasdf", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProductsEdit/ProductsEditComponent_370097_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.eightCompValidation = result?.data.eightCompValidation;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsEditComponent_370097_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductsEditComponent_370097_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "productsedit_936816_value", "748297", null);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsEditComponent_370097_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProductsEditComponent_370097_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_361851_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_361851_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_841985_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_841985_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_338498_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_338498_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_747691_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_747691_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_489749_value", "value", "getAllProductGroup", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_489749_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_139465_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_139465_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_609119_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_609119_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productsedit_688132_value", "value", "", "", "")
        ),
        "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
      ),
      message: "This field is required",
      formName: "productsedit_688132_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_92905_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_92905_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_614778_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_614778_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_439405_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_439405_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_169148_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_169148_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_896797_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "productsedit_896797_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      application_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_361851_value", "value", "", "", "")
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_841985_value", "value", "", "", "")
        ),
        "string"
      ),
      packaging_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_338498_value", "value", "", "", "")
        ),
        "string"
      ),
      productContents_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_747691_value", "value", "", "", "")
        ),
        "string"
      ),
      productsGroupID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "productsedit_489749_value", "value", "getAllProductGroup", "id", "id")
        ),
        "Guid"
      ),
      properties_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_139465_value", "value", "", "", "")
        ),
        "string"
      ),
      storageConditions_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_609119_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productID ?? this.props.screenInputs.productid,
        "Guid"
      ),
      productImage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_688132_value", "value", "", "", "")
        ),
        "string"
      ),
      applicationEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_92905_value", "value", "", "", "")
        ),
        "string"
      ),
      packagingEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_614778_value", "value", "", "", "")
        ),
        "string"
      ),
      productContentsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_439405_value", "value", "", "", "")
        ),
        "string"
      ),
      propertiesEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_169148_value", "value", "", "", "")
        ),
        "string"
      ),
      storageConditionsEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "productsedit_896797_value", "value", "", "", "")
        ),
        "string"
      ),
      productID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.productID ?? this.props.screenInputs.productid,
        "Guid"
      ),
      applicationAreasID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.SelectApplicationAreasForProduct?.length > 0
            ? this.state.SelectApplicationAreasForProduct[0]?.id
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProductsEdit/ProductsEditComponent_370097_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveProducts = result?.data.saveProducts;
    stateVars.UpdateDraftApplicationAreasForProduct = result?.data.updateDraftApplicationAreasForProduct;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ProductsEditComponent_370097_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProductsEditComponent_370097_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "33c3a2c6_ee7a_a7f6_472a_8e4dfdeec936_notify",
        this.defaultML,
        "Kaydedildi"
      ),
      "success",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
