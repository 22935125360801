import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOrderSearch_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOrderSearch_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  Logout: boolean;
  goToSignInActivity: any;
  GetLanguage: string;
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  spGetCustomerNamesSB: any[];
  spGetCardCodesSB: any[];
  GetOrderListWithAllFilter: any[];
  checkCustomerExistence: any[];
  customerCurrentsAutoInsert: any[];
  Notify: boolean;
  IfThenElse: boolean;
  isComp12867Visible: "visible" | "hidden";
  isComp339355Visible: "visible" | "hidden";
  isComp656275Visible: "visible" | "hidden";
  isComp414264Visible: "visible" | "hidden";
  isCompordersearch_671814AuthorizationVisible: "visible" | "hidden";
}

export class OrderSearch_ScreenBase extends React.PureComponent<IOrderSearch_ScreenProps, any> {
  ordersearch_373243_value_kuikaSelectBoxRef: React.RefObject<any>;
  ordersearch_787961_value_kuikaSelectBoxRef: React.RefObject<any>;
  ordersearch_159287_value_kuikaDateRef: React.RefObject<any>;
  ordersearch_129003_value_kuikaDateRef: React.RefObject<any>;
  ordersearch_823955_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 321043, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem0_1_label" },
        { Id: 485923, PropertyName: "label", Value: "Çıkış Yap", TagName: "ContentMenuItem0_2_label" },
        { Id: 456316, PropertyName: "value", Value: "Sipariş Bul", TagName: "Label2_0_value" },
        { Id: 572932, PropertyName: "placeholder", Value: "Sipariş No", TagName: "tiOrderNumber_placeholder" },
        { Id: 373243, PropertyName: "placeholder", Value: "Müşteri Ünvan", TagName: "sb_CustomerName_placeholder" },
        { Id: 787961, PropertyName: "placeholder", Value: "Cari Kart No", TagName: "sb_CardCode_placeholder" },
        { Id: 474658, PropertyName: "label", Value: "Siparişleri Getir", TagName: "Button2_label" },
        { Id: 199629, PropertyName: "value", Value: "Müşteri", TagName: "Label2_0_value" },
        { Id: 384042, PropertyName: "value", Value: "Sipariş No", TagName: "Label2_0_value" },
        { Id: 605935, PropertyName: "value", Value: "Cari Kart No", TagName: "Label2_0_value" },
        { Id: 582155, PropertyName: "value", Value: "Sipariş Tarihi", TagName: "Label2_0_value" },
        {
          Id: 823955,
          PropertyName: "nodatafoundmessage",
          Value: "Veri Bulunamadı.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 159287, PropertyName: "placeholder", Value: "Sipariş Tarihi Seçiniz...", TagName: "Date1_placeholder" },
        { Id: 426889, PropertyName: "value", Value: "Ürün Kodu", TagName: "Label3_value" },
        { Id: 805107, PropertyName: "value", Value: "Açıklama", TagName: "Label3_value" },
        {
          Id: 129003,
          PropertyName: "placeholder",
          Value: "Başlangıç Tarihi Seçiniz...",
          TagName: "dtEndDate_placeholder"
        },
        { Id: 262222, PropertyName: "value", Value: "Sadece Açık Siparişler", TagName: "Label16_value" },
        { Id: 135592, PropertyName: "value", Value: "Durum", TagName: "Label4_value" },
        { Id: 248631, PropertyName: "value", Value: "Para Birimi", TagName: "Label5_value" },
        {
          Id: 27857446,
          PropertyName: "73fd7fce-1fdc-0222-769a-23ad6bd34e21_confirmation",
          Value:
            "Sistemde Müşteri / Cari Kod tanımlı değil! Siparişle ilgili işlem yapabilmek için müşteri ve cari kartının tanımlı olması gerekmektedir. Eklemek İstiyor musunuz?",
          TagName: "customerCurrentsAutoInsert_confirmation",
          IsConfirmation: true
        },
        {
          Id: 65031159,
          PropertyName: "a183b07e-a2c9-e47f-85a4-0a9606a055e8_notify",
          Value: "Müşteri başarıyla eklendi.",
          TagName: "Notify_notify",
          IsNotify: true
        }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "dffda05f-4a2a-49bf-9e18-f609a17aa40f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 321043, PropertyName: "label", Value: "Profile" },
        { Id: 485923, PropertyName: "label", Value: "Logout" },
        { Id: 456316, PropertyName: "value", Value: "Search Order" },
        { Id: 373243, PropertyName: "placeholder", Value: "Customer Name" },
        { Id: 787961, PropertyName: "placeholder", Value: "Customer Card Code" },
        { Id: 159287, PropertyName: "placeholder", Value: "Select Start Date..." },
        { Id: 129003, PropertyName: "placeholder", Value: "Select End Date..." },
        { Id: 572932, PropertyName: "placeholder", Value: "Order Number" },
        { Id: 262222, PropertyName: "value", Value: "Orders with open status" },
        { Id: 474658, PropertyName: "label", Value: "Get Orders" },
        { Id: 199629, PropertyName: "value", Value: "Customer" },
        { Id: 384042, PropertyName: "value", Value: "Order #" },
        { Id: 605935, PropertyName: "value", Value: "Card #" },
        { Id: 582155, PropertyName: "value", Value: "Order Date" },
        { Id: 135592, PropertyName: "value", Value: "Status" },
        { Id: 248631, PropertyName: "value", Value: "Currency" },
        { Id: 426889, PropertyName: "value", Value: "Product Code" },
        { Id: 805107, PropertyName: "value", Value: "Description" },
        { Id: 823955, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 669777, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 654596, PropertyName: "value", Value: "[datafield:docnum]" },
        { Id: 315937, PropertyName: "value", Value: "[datafield:cardcode]" },
        { Id: 511810, PropertyName: "value", Value: "[datafield:docdate]" },
        { Id: 797517, PropertyName: "value", Value: "[datafield:docstatus]" },
        { Id: 696219, PropertyName: "value", Value: "[datafield:doccurrency]" },
        { Id: 373467, PropertyName: "value", Value: "[datafield:itemcode]" },
        { Id: 314948, PropertyName: "value", Value: "[datafield:dscription]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.ordersearch_373243_value_kuikaSelectBoxRef = React.createRef();
    this.ordersearch_787961_value_kuikaSelectBoxRef = React.createRef();
    this.ordersearch_159287_value_kuikaDateRef = React.createRef();
    this.ordersearch_129003_value_kuikaDateRef = React.createRef();
    this.ordersearch_823955_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      GetLanguage: "",
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      spGetCustomerNamesSB: [],
      spGetCardCodesSB: [],
      GetOrderListWithAllFilter: [],
      checkCustomerExistence: [],
      IfThenElse: false,
      isComp12867Visible: "hidden",
      isComp339355Visible: "hidden",
      isComp656275Visible: "hidden",
      isComp414264Visible: "hidden",
      isCompordersearch_671814AuthorizationVisible: "visible"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ordersearch", "ordersearch");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.OrderSearchPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompordersearch_671814AuthorizationVisible", [
      "0fdec2aa-b5dc-5448-07ae-5ba723374e32",
      "60bd931a-6d67-3a1d-df1f-e103a12471bb",
      "67a9ee96-bf0b-29e0-2dff-a17f004e4660",
      "Administrator",
      "Sale",
      "Logistic"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("ordersearch", "ordersearch");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Administrator", "Sale", "Logistic"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("ordersearch", "ordersearch");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.OrderSearchPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      ordersearch_339355_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined,
      ordersearch_128554_value: true
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  OrderSearchPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrderSearchPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  OrderSearchPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      companyId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersearch_373243_value", "value", "spGetCustomerNamesSB", "id", "id")
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "OrderSearch/OrderSearchPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.ordersearch_414264_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.ordersearch_339355_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp12867Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp339355Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp656275Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414264Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.spGetCustomerNamesSB = result?.data.spGetCustomerNamesSB;

    formVars.ordersearch_373243_options = stateVars.spGetCustomerNamesSB;
    stateVars.spGetCardCodesSB = result?.data.spGetCardCodesSB;

    formVars.ordersearch_787961_options = stateVars.spGetCardCodesSB;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrderSearchPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrderSearchPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp12867Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.ordersearch_339355_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp339355Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp656275Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.ordersearch_414264_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp414264Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_373243 = this.state.spGetCustomerNamesSB;

    stateVars.dataSource_787961 = this.state.spGetCardCodesSB;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  OrderSearchComponent_401583_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrderSearch",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "505387",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderSearchComponent_321043_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrderSearch",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "124263",
      null,
      "right",
      null,
      "520px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderSearchComponent_485923_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderSearchComponent_373243_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      companyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersearch_373243_value", "value", "spGetCustomerNamesSB", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "OrderSearch/OrderSearchComponent_373243_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetCardCodesSB = result?.data.spGetCardCodesSB;

    formVars.ordersearch_787961_options = stateVars.spGetCardCodesSB;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrderSearchComponent_373243_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrderSearchComponent_373243_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_787961 = this.state.spGetCardCodesSB;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderSearchComponent_474658_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OrderNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersearch_572932_value", "value", "", "", "")
        ),
        "string"
      ),
      CustomerCardCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "ordersearch_787961_value",
            "value",
            "spGetCardCodesSB",
            "currentNo",
            "currentNo"
          )
        ),
        "string"
      ),
      CustomerCardName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      isOpen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersearch_128554_value", "value", "", "", "")
        ),
        "boolean"
      ),
      startDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersearch_159287_value", "value", "", "", "")
        ),
        "Date"
      ),
      endDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "ordersearch_129003_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "OrderSearch/OrderSearchComponent_474658_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetOrderListWithAllFilter = result?.data.getOrderListWithAllFilter;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrderSearchComponent_474658_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrderSearchComponent_474658_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_823955 = this.state.GetOrderListWithAllFilter;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrderSearchComponent_671814_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "cardcode"),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "OrderSearch/OrderSearchComponent_671814_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.checkCustomerExistence = result?.data.checkCustomerExistence;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrderSearchComponent_671814_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrderSearchComponent_671814_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "ordersearch_823955_value", "docnum")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "ordersearch_823955_value", "cardcode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrderSearch",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  OrderSearchComponent_671814_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.OrderSearchComponent_671814_onClick_else11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrderSearchComponent_671814_onClick_else11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CardName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "cardname"),
        "string"
      ),
      address_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "shiptoaddress"),
        "string"
      ),
      addressCity_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "states"),
        "string"
      ),
      addressCountry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "country"),
        "string"
      ),
      postalCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "zipcodes"),
        "string"
      ),
      countryCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "countrys"),
        "string"
      ),
      currentNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "cardcode"),
        "string"
      ),
      currencyCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "doccurrency"),
        "string"
      ),
      createdBy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetUserCurrentId?.length > 0 ? this.state.GetUserCurrentId[0]?.id : null
        ),
        "Guid"
      ),
      currentNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "cardcode"),
        "string"
      )
    };

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "73fd7fce_1fdc_0222_769a_23ad6bd34e21_confirmation",
        this.defaultML,
        "Customer / Current Code is not defined in the system! In order to process the order, the customer and client cards will be added automatically. Do you want to continue?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "OrderSearch/OrderSearchComponent_671814_onClick_else11_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.customerCurrentsAutoInsert = result?.data.customerCurrentsAutoInsert;
      stateVars.checkCustomerExistence = result?.data.checkCustomerExistence;
      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.OrderSearchComponent_671814_onClick_else12_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrderSearchComponent_671814_onClick_else12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.checkCustomerExistence?.length > 0 ? this.state.checkCustomerExistence[0]?.isCurrentExists : null
        ),
        "1"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "a183b07e_a2c9_e47f_85a4_0a9606a055e8_notify",
          this.defaultML,
          "Customer successfully added."
        ),
        "success",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.OrderSearchComponent_671814_onClick_else13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.OrderSearchComponent_671814_onClick_else13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  OrderSearchComponent_671814_onClick_else13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.checkCustomerExistence?.length > 0 ? this.state.checkCustomerExistence[0]?.isCurrentExists : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "OrderProcess",
        "orderNo",
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "docnum")
      );
      KuikaAppManager.addToPageInputVariables(
        "OrderProcess",
        "cardNo",
        ReactSystemFunctions.value(this, "ordersearch_823955_value", "cardcode")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "OrderSearch",
        "OrderProcess",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  OrderSearchComponent_671814_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.checkCustomerExistence?.length > 0 ? this.state.checkCustomerExistence[0]?.isCurrentExists : null
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.OrderSearchComponent_671814_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.OrderSearchComponent_671814_onClick_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [505387, 124263] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.OrderSearchPageInit();
    }
  }
}
