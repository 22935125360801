const screenInputData = {
  CurrenciesEdit: [{"name":"currencyId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	UserView: [{"name":"userId","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	CompanyUserList: [{"name":"userType","type":"Int32","cardinality":1,"isExpandedPanel":"false"},{"name":"role","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"companyId","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	UserEdit: [{"name":"userById","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"userType","type":"Int32","cardinality":1,"isExpandedPanel":"false"},{"name":"role","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"companyId","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	questionEdit: [{"name":"surveyId","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	DetailsOfCustomerOrder: [{"name":"orderNo","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"cardNo","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"VehicleId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	RequestVehicle: [{"name":"vehicleId","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"orderNo","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"cardNo","type":"String","cardinality":1,"isExpandedPanel":null}],
	PreviewInvoice: [{"name":"vehicleRequestId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	OrderProcess: [{"name":"orderNo","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"cardNo","type":"String","cardinality":1,"isExpandedPanel":null}],
	PreviewDocument: [{"name":"vehicleRequestId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"docType","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SurveySave: [{"name":"surveyId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CompanyProfileCustomer: [{"name":"companyId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"userType","type":"Int32","cardinality":1,"isExpandedPanel":null}],
	ApplicationAreasEdit: [{"name":"ApplicationAreaID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	rateSurveyQuestion: [{"name":"surveyId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"questionNo","type":"Int32","cardinality":1,"isExpandedPanel":null},{"name":"lang","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"rowNumber","type":"String","cardinality":1,"isExpandedPanel":null}],
	CompanyCurrentList: [{"name":"userType","type":"Int32","cardinality":1,"isExpandedPanel":"false"},{"name":"role","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"companyId","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	CompanyView: [{"name":"companyId","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	DashboardCustomer: [{"name":"st","type":"Int32","cardinality":1,"isExpandedPanel":null}],
	UserList: [{"name":"userType","type":"Int32","cardinality":1,"isExpandedPanel":"false"},{"name":"headerText","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"role","type":"String","cardinality":1,"isExpandedPanel":"false"}],
	CertificateEdit: [{"name":"CertificateID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	RequestedVehicleView: [{"name":"orderNo","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"vehicleId","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"cardCode","type":"String","cardinality":1,"isExpandedPanel":null}],
	CompanyEdit: [{"name":"companyId","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"companyType","type":"Int32","cardinality":1,"isExpandedPanel":"false"}],
	ApplicationAreasEditcopy: [{"name":"ApplicationAreaID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	SurveyDetails: [{"name":"surveyId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CustomerUserAuthorizeCurrent: [{"name":"userId","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"companyType","type":"String","cardinality":1,"isExpandedPanel":null}],
	CurrentEdit: [{"name":"CurrentId","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"CompanyId","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	HaberDetay: [{"name":"newsID","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"content","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"image","type":"Image","cardinality":1,"isExpandedPanel":"false"},{"name":"title","type":"String","cardinality":1,"isExpandedPanel":"false"}],
	AssignedVehicleView: [{"name":"vehicleId","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"orderNo","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"cardNo","type":"String","cardinality":1,"isExpandedPanel":null}],
	ApplicationAreasView: [{"name":"ApplicationAreaID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	ProductGroupView: [{"name":"productGroupID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	BrokerEdit: [{"name":"brokerId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	AddVehicleLocation: [{"name":"vehicleId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"orderNo","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"cardNo","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"custRefNo","type":"String","cardinality":1,"isExpandedPanel":null}],
	AssignVehicle: [{"name":"orderNo","type":"String","cardinality":1,"isExpandedPanel":"false"},{"name":"vehicleId","type":"Guid","cardinality":1,"isExpandedPanel":"false"},{"name":"cardCode","type":"String","cardinality":1,"isExpandedPanel":null}],
	ProductGroup: [{"name":"catalogueId","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	OrdersInProgress: [{"name":"st","type":"String","cardinality":1,"isExpandedPanel":null}],
	CompanyProfileEdit: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	LocationsOfCustomerOrder: [{"name":"VehicleId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	YeniHaber: [{"name":"isNew","type":"Boolean","cardinality":1,"isExpandedPanel":"false"},{"name":"newsID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	CurrentView: [{"name":"currentId","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	ProductsEdit: [{"name":"productID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	DocumentsOfCustomerOrder: [{"name":"VehicleId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"isAbroad","type":"Boolean","cardinality":1,"isExpandedPanel":null}],
	ProductGroupEdit: [{"name":"productGroupID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	ProductsView: [{"name":"ProductListID","type":"Guid","cardinality":1,"isExpandedPanel":"false"}]
};

export class ScreenInputHelper {
  public static getScreenInputValue(screenName: string, inputName: string, value: string): any {
    switch (this.getScreenInputType(screenName, inputName)) {
      case "String":
        return value;
      case "Boolean":
        return value.toLowerCase() === "true";
      // TODO
    }

    return value;
  }

  private static getScreenInputType(screenName: string, inputName: string): string {
    const data = screenInputData[screenName]?.find((x) => x.Name === inputName);
    return data?.TypeName ? data.TypeName : "";
  }
}
