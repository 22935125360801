import { FormInstance } from "antd/lib/form";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashboardAdmin_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashboardAdmin_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  SetLanguage: string;
  GetLanguage: string;
  Logout: boolean;
  goToSignInActivity: any;
  GetUsersFirstLetter: any[];
  GetUserCurrentId: any[];
  spGetUserById: any[];
  getCountOfOrdersByVehicleStatus: any[];
  getCountOfOrdersInProgress: any[];
  spGetOrdersInProgress: any[];
  isComp978011Visible: "visible" | "hidden";
  isComp513832Visible: "visible" | "hidden";
  isComp504101Visible: "visible" | "hidden";
  isComp550182Visible: "visible" | "hidden";
  isComp168246Visible: "visible" | "hidden";
  isComp10124Visible: "visible" | "hidden";
  isComp238797Visible: "visible" | "hidden";
  isComp793926Visible: "visible" | "hidden";
  isComp757917Visible: "visible" | "hidden";
}

export class DashboardAdmin_ScreenBase extends React.PureComponent<IDashboardAdmin_ScreenProps, any> {
  dashboardadmin_52693_value_kuikaGalleryViewRef: React.RefObject<any>;
  dashboardadmin_898077_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 513345, PropertyName: "value", Value: "Şimdiye Kadar", TagName: "Label7_value" },
        { Id: 963903, PropertyName: "value", Value: "Talep Edilen", TagName: "Label2_value" },
        { Id: 109585, PropertyName: "value", Value: "Onay Bekleyen", TagName: "Label4_value" },
        { Id: 74138, PropertyName: "value", Value: "Son Siparişler", TagName: "Label8_value" },
        { Id: 458284, PropertyName: "value", Value: "Tümü", TagName: "Label9_value" },
        { Id: 581009, PropertyName: "label", Value: "Profil", TagName: "ContentMenuItem2_1_label" },
        { Id: 333614, PropertyName: "label", Value: "Çıkış", TagName: "ContentMenuItem2_2_label" },
        { Id: 439233, PropertyName: "value", Value: "Toplam", TagName: "Label2_2_value" },
        { Id: 481964, PropertyName: "value", Value: "Kayıt", TagName: "Label2_4_value" },
        { Id: 398673, PropertyName: "value", Value: "Onaylanan", TagName: "Label7_1_value" },
        { Id: 836845, PropertyName: "value", Value: "Yüklenen", TagName: "Label7_1_value" },
        { Id: 861513, PropertyName: "value", Value: "Yola Çıkan", TagName: "Label7_1_value" },
        { Id: 239852, PropertyName: "value", Value: "Teslim Edilen", TagName: "Label7_1_value" }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "1680d759-ab32-4ac4-897f-59574b7f704d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 978011, PropertyName: "value", Value: "TR" },
        { Id: 513832, PropertyName: "value", Value: "TR" },
        { Id: 603913, PropertyName: "value", Value: "|" },
        { Id: 504101, PropertyName: "value", Value: "EN" },
        { Id: 550182, PropertyName: "value", Value: "EN" },
        { Id: 581009, PropertyName: "label", Value: "Profile" },
        { Id: 333614, PropertyName: "label", Value: "Logout" },
        { Id: 513345, PropertyName: "value", Value: "Up to Now" },
        { Id: 963903, PropertyName: "value", Value: "Requested" },
        { Id: 109585, PropertyName: "value", Value: "Waiting for Approval" },
        { Id: 398673, PropertyName: "value", Value: "Approved" },
        { Id: 836845, PropertyName: "value", Value: "Loaded" },
        { Id: 861513, PropertyName: "value", Value: "Out of Delivery" },
        { Id: 239852, PropertyName: "value", Value: "Delivered" },
        { Id: 74138, PropertyName: "value", Value: "Recent Orders" },
        { Id: 439233, PropertyName: "value", Value: "Total" },
        { Id: 481964, PropertyName: "value", Value: "Records" },
        { Id: 458284, PropertyName: "value", Value: "All" },
        { Id: 52693, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 884433, PropertyName: "value", Value: "[datafield:cardname]" },
        { Id: 448780, PropertyName: "value", Value: "[datafield:cardcode]" },
        { Id: 209668, PropertyName: "value", Value: "[datafield:ordernumber]" },
        { Id: 154008, PropertyName: "value", Value: "[datafield:orderdate]" },
        { Id: 831230, PropertyName: "value", Value: "[datafield:orderstatusdesc]" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardadmin_52693_value_kuikaGalleryViewRef = React.createRef();
    this.dashboardadmin_898077_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      SetLanguage: "",
      GetLanguage: "",
      Logout: false,
      goToSignInActivity: "",
      GetUsersFirstLetter: [],
      GetUserCurrentId: [],
      spGetUserById: [],
      getCountOfOrdersByVehicleStatus: [],
      getCountOfOrdersInProgress: [],
      spGetOrdersInProgress: [],
      isComp978011Visible: "hidden",
      isComp513832Visible: "hidden",
      isComp504101Visible: "hidden",
      isComp550182Visible: "hidden",
      isComp168246Visible: "hidden",
      isComp10124Visible: "hidden",
      isComp238797Visible: "hidden",
      isComp793926Visible: "hidden",
      isComp757917Visible: "hidden"
    };

    this.CheckPageAuthorization();
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardadmin", "dashboardadmin");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashboardAdminPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardadmin", "dashboardadmin");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async () => {
    KuikaAppManager.showSpinner(this);
    await UserService.GetUserInfoForce();
    const permittedRoleNames: string[] = ["Administrator"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      this.setState({ isPageVisible: false });
      KuikaAppManager.hideSpinner(this);
      return;
    }
    this.setState({ isPageVisible: true });
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardadmin", "dashboardadmin");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashboardAdminPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboardadmin_10124_value: this.state.spGetUserById?.at?.(0)?.profileImage ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DashboardAdminPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp978011Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp513832Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp504101Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp550182Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardAdminPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashboardAdminPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      ),
      customsAgentId_3: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      logisticSupplierId_3: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      distributorID_3: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      search_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      search_5: ReactSystemFunctions.convertToTypeByName("", "string"),
      pageCount_5: ReactSystemFunctions.convertToTypeByName(10, "number"),
      currentPage_5: ReactSystemFunctions.convertToTypeByName(1, "number")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DashboardAdmin/DashboardAdminPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFirstLetter = result?.data.getUsersFirstLetter;
    formVars.dashboardadmin_793926_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetUsersFirstLetter?.length > 0 ? stateVars.GetUsersFirstLetter[0]?.firstletters : null
    );
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.spGetUserById = result?.data.spGetUserById;
    formVars.dashboardadmin_10124_value = ReactSystemFunctions.toString(
      this,
      stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
    );
    stateVars.isComp168246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp238797Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp793926Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.spGetUserById?.length > 0 ? stateVars.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getCountOfOrdersByVehicleStatus = result?.data.getCountOfOrdersByVehicleStatus;
    formVars.dashboardadmin_980780_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.requested
        : null
    );
    formVars.dashboardadmin_731637_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.waitingForApproval
        : null
    );
    formVars.dashboardadmin_99660_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.approved
        : null
    );
    formVars.dashboardadmin_919422_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.loaded
        : null
    );
    formVars.dashboardadmin_979586_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.outOfDelivery
        : null
    );
    formVars.dashboardadmin_31535_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersByVehicleStatus?.length > 0
        ? stateVars.getCountOfOrdersByVehicleStatus[0]?.delivered
        : null
    );
    stateVars.getCountOfOrdersInProgress = result?.data.getCountOfOrdersInProgress;
    formVars.dashboardadmin_832758_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersInProgress?.length > 0 ? stateVars.getCountOfOrdersInProgress[0]?.countOfRecords : null
    );
    stateVars.spGetOrdersInProgress = result?.data.spGetOrdersInProgress;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardAdminPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardAdminPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp978011Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp513832Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp504101Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp550182Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp168246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardadmin_10124_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
    );

    stateVars.isComp10124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp238797Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardadmin_793926_value = ReactSystemFunctions.toString(
      this,
      this.state.GetUsersFirstLetter?.length > 0 ? this.state.GetUsersFirstLetter[0]?.firstletters : null
    );

    stateVars.isComp793926Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          this.state.spGetUserById?.length > 0 ? this.state.spGetUserById[0]?.profileImage : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardadmin_980780_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.requested
        : null
    );

    formVars.dashboardadmin_731637_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.waitingForApproval
        : null
    );

    formVars.dashboardadmin_99660_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.approved
        : null
    );

    formVars.dashboardadmin_919422_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.loaded
        : null
    );

    formVars.dashboardadmin_979586_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.outOfDelivery
        : null
    );

    formVars.dashboardadmin_31535_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersByVehicleStatus?.length > 0
        ? this.state.getCountOfOrdersByVehicleStatus[0]?.delivered
        : null
    );

    formVars.dashboardadmin_832758_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersInProgress?.length > 0
        ? this.state.getCountOfOrdersInProgress[0]?.countOfRecords
        : null
    );

    stateVars.dataSource_52693 = this.state.spGetOrdersInProgress;
    formVars.dashboardadmin_884433_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.cardName : null
    );

    formVars.dashboardadmin_448780_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.cardCode : null
    );

    formVars.dashboardadmin_209668_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderNumber : null
    );

    formVars.dashboardadmin_154008_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderDate : null
    );

    formVars.dashboardadmin_831230_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderStatusDesc : null
    );

    formVars.dashboardadmin_898077_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfOrdersInProgress?.length > 0
        ? this.state.getCountOfOrdersInProgress[0]?.countOfRecords
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashboardAdminComponent_844448_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardAdmin",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "889963",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_978011_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp978011Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp513832Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp504101Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp550182Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_504101_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "en_US");
    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp978011Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp513832Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp504101Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp550182Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_581009_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardAdmin",
      "Profile",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "697468",
      null,
      "right",
      null,
      "400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_333614_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_524756_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "0");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardAdmin",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_536251_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "20");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardAdmin",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_266273_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "30");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardAdmin",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_560785_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "40");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardAdmin",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_904357_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "50");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardAdmin",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_444898_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", "60");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardAdmin",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_458284_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardAdmin",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_783194_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("OrdersInProgress", "st", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardAdmin",
      "OrdersInProgress",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_147727_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "orderNo",
      ReactSystemFunctions.value(this, "dashboardadmin_52693_value", "orderNumber")
    );
    KuikaAppManager.addToPageInputVariables(
      "OrderProcess",
      "cardNo",
      ReactSystemFunctions.value(this, "dashboardadmin_52693_value", "cardCode")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DashboardAdmin",
      "OrderProcess",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardAdminComponent_898077_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      search_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      search_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      pageCount_1: ReactSystemFunctions.convertToTypeByName(10, "number"),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(1, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardAdmin/DashboardAdminComponent_898077_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getCountOfOrdersInProgress = result?.data.getCountOfOrdersInProgress;
    formVars.dashboardadmin_832758_value = ReactSystemFunctions.toString(
      this,
      stateVars.getCountOfOrdersInProgress?.length > 0 ? stateVars.getCountOfOrdersInProgress[0]?.countOfRecords : null
    );
    stateVars.spGetOrdersInProgress = result?.data.spGetOrdersInProgress;

    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.DashboardAdminComponent_898077_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardAdminComponent_898077_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.dashboardadmin_832758_value = ReactSystemFunctions.toString(
      this,
      this.state.getCountOfOrdersInProgress?.length > 0
        ? this.state.getCountOfOrdersInProgress[0]?.countOfRecords
        : null
    );

    stateVars.dataSource_52693 = this.state.spGetOrdersInProgress;
    formVars.dashboardadmin_884433_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.cardName : null
    );

    formVars.dashboardadmin_448780_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.cardCode : null
    );

    formVars.dashboardadmin_209668_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderNumber : null
    );

    formVars.dashboardadmin_154008_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderDate : null
    );

    formVars.dashboardadmin_831230_value = ReactSystemFunctions.toString(
      this,
      this.state.spGetOrdersInProgress?.length > 0 ? this.state.spGetOrdersInProgress[0]?.orderStatusDesc : null
    );

    formVars.dashboardadmin_898077_total = ReactSystemFunctions.value(
      this,
      this.state.getCountOfOrdersInProgress?.length > 0
        ? this.state.getCountOfOrdersInProgress[0]?.countOfRecords
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [889963, 697468, , , , , , , ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashboardAdminPageInit();
    }
  }
}
