import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IContactForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IContactForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  SelectCompanyProfileByKey: any[];
  GetUserCurrentId: any[];
  GetAppUsersById: any[];
  WriteToSessionStorage: any;
  ReadFromSessionStorage: string;
  SendMail: boolean;
  Notify: boolean;
}

export class ContactForm_ScreenBase extends React.PureComponent<IContactForm_ScreenProps, any> {
  ml = [
    {
      Id: "f7725e49-8505-8950-e5c0-b88af892838d",
      Name: "tr_TR",
      ShortName: "tr_TR",
      IsDefault: true,
      LanguagePhrases: [
        { Id: 924163, PropertyName: "value", Value: "İletişim Formu", TagName: "Label5_value" },
        { Id: 15838, PropertyName: "value", Value: "Konu", TagName: "Label6_value" },
        { Id: 961136, PropertyName: "value", Value: "Mesaj", TagName: "Label7_value" },
        { Id: 49298, PropertyName: "label", Value: "Gönder", TagName: "Button1_label" },
        { Id: 683832, PropertyName: "placeholder", Value: "Konuyu yazınız...", TagName: "TextInput1_placeholder" },
        { Id: 37850, PropertyName: "placeholder", Value: "Mesajınızı yazınız...", TagName: "TextArea1_placeholder" },
        {
          Id: 92947611,
          PropertyName: "18011cbd-b28f-826c-e608-be17d4f77182_confirmation",
          Value: "Mesajınız gönderilecek, devam etmek istiyor musunuz?",
          TagName: "WriteToSessionStorage_confirmation",
          IsConfirmation: true
        },
        {
          Id: 41269189,
          PropertyName: "4f39aa7d-0107-aaad-be20-1238c2c5ba01_notify",
          Value: "Mesajınız başarıyla gönderildi.",
          TagName: "Notify_notify",
          IsNotify: true
        },
        {
          Id: 453949,
          PropertyName: "value",
          Value: "İstediğiniz konuda görüş öneri ve sorularınız buradan iletebilirsiniz.",
          TagName: "Label0_0_value"
        }
      ]
    },
    { Id: "7d1131b4-81a0-5219-f969-0ea89d44ba2e", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "24ef6029-f177-4f14-a58e-2b80d4dcf96d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 510488, PropertyName: "value", Value: "Contact Form" },
        { Id: 924163, PropertyName: "value", Value: "Contact Form" },
        {
          Id: 453949,
          PropertyName: "value",
          Value: "You can send your opinions, suggestions or questions on any subject you want here."
        },
        { Id: 15838, PropertyName: "value", Value: "Subject" },
        { Id: 683832, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 961136, PropertyName: "value", Value: "Message" },
        { Id: 37850, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 49298, PropertyName: "label", Value: "Send" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SelectCompanyProfileByKey: [],
      GetUserCurrentId: [],
      GetAppUsersById: [],
      WriteToSessionStorage: "",
      ReadFromSessionStorage: "",
      SendMail: false,
      Notify: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("contactform", "contactform");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ContactFormPageInit();
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("contactform", "contactform");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("49298");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("contactform", "contactform");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ContactFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  ContactFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName("797EA3AE-EC81-6963-5C2B-0E312BCD0D66", "Guid"),
      email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ContactForm/ContactFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectCompanyProfileByKey = result?.data.selectCompanyProfileByKey;
    stateVars.GetUserCurrentId = result?.data.getUserCurrentId;
    stateVars.GetAppUsersById = result?.data.getAppUsersById;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  ContactFormComponent_377026_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ContactFormComponent_49298_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    const result = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "18011cbd_b28f_826c_e608_be17d4f77182_confirmation",
        this.defaultML,
        "Your form are going to be sent, do you want to continue ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    });

    if (result.isConfirmed) {
      KuikaAppManager.showSpinner(this);

      stateVars.WriteToSessionStorage = await ReactSystemFunctions.writeToSessionStorage("AAAA", "senmailmessage");
      stateVars.ReadFromSessionStorage = await ReactSystemFunctions.readFromSessionStorage("AAAA");

      this.props.form.setFieldsValue(formVars);
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          await this.ContactFormComponent_49298_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ContactFormComponent_49298_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contactform_683832_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "contactform_683832_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "contactform_37850_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "contactform_37850_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("e28645a8-ee10-4dad-a695-d4f65ac18fdc", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.SelectCompanyProfileByKey?.length > 0 ? this.state.SelectCompanyProfileByKey[0]?.email : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("Mobil Uygulam Kullanıcı Mesajı", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      usermailaddress_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetAppUsersById?.length > 0 ? this.state.GetAppUsersById[0]?.email : null
        ),
        "string"
      ),
      cfSubject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contactform_683832_value", "value", "", "", "")
        ),
        "string"
      ),
      cfMessage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "contactform_37850_value", "value", "", "", "")
        ),
        "string"
      ),
      name_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetAppUsersById?.length > 0 ? this.state.GetAppUsersById[0]?.name : null
        ),
        "string"
      ),
      surname_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          this.state.GetAppUsersById?.length > 0 ? this.state.GetAppUsersById[0]?.surname : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ContactForm/ContactFormComponent_49298_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        await this.ContactFormComponent_49298_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ContactFormComponent_49298_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "4f39aa7d_0107_aaad_be20_1238c2c5ba01_notify",
        this.defaultML,
        "Your Message hase been successfully sent."
      ),
      "default",
      "bottom-right",
      0
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
